// Modules
import React from 'react';
import Table from './../Table/Table';
// Components
import { CopyButton, GoBack } from '../Utilities/Components';
// Helpers - Formatters
import { formatDateTime } from '../Utilities/Formatters';

const columnBuilder = [
  {
    Header: 'Funding Date',
    accessor: 'FundingDate',
    minWidth: 60,
    Cell: ({ row: { original } }) => formatDateTime(original.FundingDate),
    sortMethod: 'Date',
  },
  {
    Header: 'Account',
    accessor: 'Account',

    style: { textAlign: 'left' },
    Cell: ({ row: { original } }) => (
      <>
        <CopyButton value={original?.Account} />
        <strong>{original.Account}</strong>
      </>
    ),
    sortMethod: 'Account',
  },
  {
    Header: 'Credit',
    accessor: 'Credit',
    minWidth: 30,
    sortMethod: 'Credit',
  },
  {
    Header: 'Source',
    accessor: 'Source',
    minWidth: 130,
    style: { textAlign: 'left' },
    Cell: ({ row: { original } }) => (
      <>
        <CopyButton value={original?.Source} />
        <strong>{original.Source}</strong>
      </>
    ),
    sortMethod: 'Source',
  },
];

const FundingHistory = props => {
  const { params } = props.match;
  return (
    <>
      <GoBack
        history={props.history}
        forcePush={true}
        defaultPath="/accounting/funding"
        defaultName="Funding Wallet"
      />
      <Table
        history={props.history}
        columns={columnBuilder}
        filter={true}
        endpoint={`accounting/funding/history/${params.hash}`}
        tablename={`${params.hash} Wallet Funding History`}
      />
    </>
  );
};

export default FundingHistory;
