//Modules
import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
// Components
import { Loading, LoadingButton } from "../Utilities/Components";
import { FormSelect } from "../Forms/FormInputs";
// PropTypes
import { reqFuncProp, strProp } from "../Utilities/PropTypes";
// Configs
import { API } from "../Config/cfg.js";

class SelectGroup extends Component {
  state = {
    groups: null,
    loading: true,
    errors: {},
  };

  componentDidMount = () => {
    this.getGroups();
  };

  getGroups = () => {
    API.get("/groups?all=true")
      .then((res) => {
        this.setState({
          groups: res.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  };

  onSubmit = (values) => {
    // PASSES GROUP HASH TO PARENT COMPONENT
    this.props.event(values.parentGroup, "group");
  };

  render() {
    const { groups, loading, errors } = this.state;

    if (loading) {
      return <Loading errors={errors} />;
    }
    return (
      <div className="columns">
        <div className="column is-6">
          <Formik
            initialValues={{
              parentGroup: "",
            }}
            validate={(values) => {
              let errors = {};
              if (!values.parentGroup) {
                errors.parentGroup = "is required";
              }
              return errors;
            }}
            onSubmit={(values) => {
              this.onSubmit(values);
            }}
            children={({ errors, touched, isSubmitting }) => (
              <Form>
                <h2 className="form-title">Select Group</h2>
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="Parent Group"
                  name="parentGroup"
                  placeholder="Select Group"
                  options={
                    <React.Fragment>
                      {groups.Data.map((item) => (
                        <option key={item.Hash} value={item.Hash}>
                          {item.Name}
                        </option>
                      ))}
                    </React.Fragment>
                  }
                />
                <LoadingButton loading={isSubmitting} />
              </Form>
            )}
          />
        </div>
        <div className="column is-6">
          <h2 className="form-title">New Group</h2>
          <br />
          <p>
            Need to create a new group?{" "}
            <Link to="/groups/create-group">Click here</Link>
          </p>
        </div>
      </div>
    );
  }
}

SelectGroup.propTypes = {
  form: strProp,
  event: reqFuncProp,
};

export default SelectGroup;
