import React from 'react';
// Components
import { GenericModal } from '../../../Utilities/Components';

const PEPModal = ({ isOpen, putPEP, isSubmitting, closeModal }) => {
  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={() => {
        closeModal('isPEPOpen');
      }}
      headerMsg="Create PEP"
      viewMsg="Are you sure you want to mark this user as a Politically Exposed Person?"
      btnText="Submit"
      btnAction={async () => {
        await putPEP();
      }}
      isSubmitting={isSubmitting}
    />
  );
};

export default PEPModal;
