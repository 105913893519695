const ctrKeys = {
  'Account.Hash': {
    Label: 'Account Hash',
    Type: 'string',
  },
  Code: {
    Label: 'Code',
    Type: 'string',
  },
  'Country.Code': {
    Label: 'Country',
    Type: 'string',
    Options: [
      {
        Display: 'United States',
        Value: 'US',
      },
      {
        Display: 'Canada',
        Value: 'CA',
      },
    ],
  },
  CreatedAt: {
    Label: 'Create Date',
    Type: 'daterange',
  },
  DueDate: {
    Label: 'Due Date',
    Type: 'daterange',
  },
  Activation: {
    Label: 'Activation',
    Type: 'daterange',
  },
  Filed: {
    Label: 'Filed',
    Type: 'bool',
    Options: [
      {
        Display: 'True',
        Value: true,
      },
      {
        Display: 'False',
        Value: false,
      },
    ],
  },
  'Account.FirstName': {
    Label: 'First Name',
    Type: 'string',
  },
  'Account.LastName': {
    Label: 'Last Name',
    Type: 'string',
  },
  UpdatedAt: {
    Label: 'Last Update Date',
    Type: 'daterange',
  },
};
export default ctrKeys;
