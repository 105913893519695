import { formatPrice, formatPercent } from "./index";

export default function formatGroupCommissions(commissions) {
  let result = [];
  let groupedByType = groupBy(commissions, "CommissionType");

  const formatTitle = (commissionName, commissionValue, type) => {
    if (type === "Percent") {
      return commissionName.replace("%", formatPercent(commissionValue));
    } else if (type === "Fiat") {
      return `${commissionName} ${formatPrice(commissionValue)}`;
    }
    return commissionName;
  };

  // switch based on type to groupby fiat/percent
  for (const key in groupedByType) {
    let groupByCommValue = [];
    let commType = "";
    let commTitle = "";
    switch (key) {
      // Fiat Only
      // Flat Fiat, Monthly Flat Fiat
      case "1":
        commType = "Fiat";
        commTitle = "Flat";
        break;
      case "10":
        commType = "Fiat";
        commTitle = "Mo. Flat";
        break;
      // Percent Only
      // Gross Commission Flat %, Gross Commission Tiered Plus %, Cash Total Flat %, Cash Total Tiered Plus %
      case "2":
        commType = "Percent";
        commTitle = "Gross Flat %";
        break;
      case "4":
        commType = "Percent";
        commTitle = "Gross Tiered Plus %";
        break;
      case "6":
        commType = "Percent";
        commTitle = "Cash Flat %";
        break;
      case "8":
        commType = "Percent";
        commTitle = "Cash Tiered Plus %";
        break;
      // other
      // No Commission, Gross Commission Tiered, Gross Commission Greater Of Fiat or %,  Cash Total Tiered, Cash Total Greater Of Fiat or %
      case "3":
        commTitle = "Gross Tiered";
        break;
      case "5":
        commTitle = "Gross $ or %";
        break;
      case "7":
        commTitle = "Cash Tiered";
        break;
      case "9":
        commTitle = "Cash $ or %";
        break;
      default:
        commTitle = "No Commission Payout";
        break;
    }

    if (commType) {
      groupByCommValue = groupBy(commissions, commType);
    }

    if (Object.keys(groupByCommValue).length > 0) {
      for (const commValue in groupByCommValue) {
        if (commValue !== "undefined") {
          result.push(
            `${formatTitle(commTitle, commValue, commType)}(${
              groupByCommValue[commValue].length
            })`
          );
        }
      }
    } else {
      result.push(`${commTitle}(${groupedByType[key].length})`);
    }
  }
  return result.join(", ");
}

const groupBy = function (objArr, key) {
  // group by commission type
  return objArr.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
