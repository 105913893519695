//Modules
import React from "react";
import { Link } from "react-router-dom";
// Components
import { ListGroup, ListGroupItem } from "../../Utilities/Components";
// Helpers - Formatters
import { formatDate } from "../../Utilities/Formatters";
import { isEmpty } from "lodash";

const AccountDetails = ({ data }) => {
  const { Account } = data;

  if (isEmpty(data)) {
    return false;
  }

  return (
    <ListGroup title="Account Details">
      <ListGroupItem
        name="Name"
        value={<Link to={`/accounts/${Account.Hash}`}>{Account.FullName}</Link>}
      />
      <ListGroupItem
        name="DOB"
        value={formatDate(Account.DOB, undefined, true)}
      />

      <React.Fragment>
        <React.Fragment>
          <ListGroupItem
            name="Account Opened"
            value={formatDate(Account.CreatedAt)}
          />
        </React.Fragment>
      </React.Fragment>
    </ListGroup>
  );
};

export default AccountDetails;
