// Modules
import React, { Component } from "react";
// TX Components
import Overview from "./Overview";
import TabsContainer from "./TabsContainer";
// Components
import {
  GoBack,
  Header,
  Loading,
  ToastError,
  ToastSuccess,
} from "../../Utilities/Components";
// Configs
import { API } from "../../Config/cfg.js";

class SingleTransaction extends Component {
  state = {
    data: {},
    loading: true,
    callAPI: false,
    errors: {},
  };

  componentDidMount() {
    this.getTransaction();
  }

  getTransaction = () => {
    const { hash } = this.props.match.params;
    API.get(`/transactions/${hash}`)
      .then((res) => {
        this.setState({
          data: res.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          errors: error.response.data,
        });
      });
  };

  putComplianceWalletBan = async (values) => {
    await API.put(`/compliance/wallet-bans`, values)
      .then((res) => {
        ToastSuccess("Wallet Address Has Been Banned");
      })
      .catch((err) => {
        ToastError(err);
      });
  };

  postTransaction = (values, msg) => {
    const { hash } = this.props.match.params;
    API.post(`/transactions/${hash}`, values)
      .then((res) => {
        ToastSuccess(msg);
        this.getTransaction();
        this.setState({ callAPI: true });
      })
      .catch((error) => {
        this.setState({ callAPI: true });
        ToastError(error);
      });
  };

  render() {
    const { loading, data, errors, callAPI } = this.state;
    const { history } = this.props;

    if (loading) {
      return <Loading errors={errors} />;
    }

    return (
      <React.Fragment>
        <GoBack
          history={this.props.history}
          defaultPath="/transactions"
          defaultName="Transactions"
        />
        <Header
          code={data.InvoiceCode}
          title={"- " + data.Product.Description}
        />
        <Overview
          data={data}
          location={this.props.location}
          postTransaction={this.postTransaction}
          putCompliance={this.putComplianceWalletBan}
        />
        <TabsContainer data={data} callAPI={callAPI} history={history} />
      </React.Fragment>
    );
  }
}

export default SingleTransaction;
