/* eslint no-console: ["error", { allow: [ "error"] }] */

// Modules
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
//Component
import CancelLink from './CancelLink';
import {
  LoadingButton,
  ToastError,
  ToastSuccess,
} from '../../Utilities/Components';
import { FormInput, FormSelect } from '../../Forms/FormInputs';
// PropTypes
import { reqFuncProp, reqStrProp } from '../../Utilities/PropTypes';
// Configs
import { API } from '../../Config/cfg.js';

class LocationDetails extends Component {
  state = {
    creditedBanks: [],
    groups: [],
  };

  componentDidMount() {
    this.getCreditedBanks();
    this.getGroups();
  }

  getCreditedBanks = () => {
    API.get('/accounting/credit-accounts')
      .then(res => {
        this.setState({ creditedBanks: res.data });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getGroups = () => {
    API.get('/groups?all=true')
      .then(res => {
        this.setState({ groups: res.data.Data });
      })
      .catch(error => {
        console.error(error);
      });
  };

  putLocation = req => {
    API.put('/locations', req)
      .then(res => {
        this.props.event(res.data.Hash, 'location');
        ToastSuccess(
          `You successfully created the new ${res.data.BusinessName} location`
        );
      })
      .catch(error => {
        ToastError(error);
      });
  };

  render() {
    const { addressHash, groupHash } = this.props;
    const { creditedBanks, groups } = this.state;

    return (
      <Formik
        initialValues={{
          AddressHash: addressHash,
          GroupHash: groupHash,
          legalEntity: '',
          BusinessName: '',
          PhoneNumber: '',
          Neighborhood: '',
          CrossStreet: '',
          CreditAccountHash: '',
          GMBURL: '',
          MainContact: '',
          ComplianceContact: '',
          MaintenanceContact: '',
          CashOperatorGroupHash: '',
        }}
        validate={values => {
          let errors = {};
          if (!values.legalEntity) {
            errors.legalEntity = 'is required';
          }
          if (!values.BusinessName) {
            errors.BusinessName = 'is required';
          }
          if (!values.PhoneNumber) {
            errors.PhoneNumber = 'is required';
          }
          if (!values.CreditAccountHash) {
            errors.CreditAccountHash = 'is required';
          }
          if (!values.CrossStreet) {
            errors.CrossStreet = 'is required';
          }
          if (!values.CashOperatorGroupHash) {
            errors.CashOperatorGroupHash = 'is required';
          }

          return errors;
        }}
        onSubmit={(values, actions) => {
          this.putLocation(values);
        }}
        children={({ errors, touched, isSubmitting }) => (
          <Form>
            <h2 className="form-title">Business Details</h2>
            <div className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Legal Entity"
                  name="legalEntity"
                  type="text"
                  placeholder="Enter Legal Entity Name"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Business Name"
                  name="BusinessName"
                  type="text"
                  placeholder="Enter Business Name"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Phone Number"
                  name="PhoneNumber"
                  type="text"
                  placeholder="Enter Phone Number"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Cross Street"
                  name="CrossStreet"
                  type="text"
                  placeholder="Enter Cross Street"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Neighborhoods"
                  name="Neighborhood"
                  type="text"
                  placeholder="Enter Neighborhoods"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Google My Business URL"
                  name="GMBURL"
                  type="text"
                  placeholder="Enter Google Business URL"
                />
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Main Contact"
                  name="MainContact"
                  type="text"
                  placeholder="Enter Main Contact"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Compliance Contact"
                  name="ComplianceContact"
                  type="text"
                  placeholder="Enter Compliance Contact"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Maintenance Contact"
                  name="MaintenanceContact"
                  type="text"
                  placeholder="Enter Maintenance Contact"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-4">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="Bank Account"
                  name="CreditAccountHash"
                  placeholder="Select DigitalMint Bank"
                  options={
                    <React.Fragment>
                      {creditedBanks.map(item => (
                        <option key={item.Hash} value={item.Hash}>
                          {item.Name} - {item.AccountNumber}
                        </option>
                      ))}
                    </React.Fragment>
                  }
                />
              </div>
              <div className="column is-4">
                <FormSelect
                  name="CashOperatorGroupHash"
                  label="Cash Management"
                  placeholder="Please select a Group"
                  options={
                    <React.Fragment>
                      {groups.map(item => (
                        <option key={item.Hash} value={item.Hash}>
                          {item.Name}
                        </option>
                      ))}
                    </React.Fragment>
                  }
                />
              </div>
            </div>
            <LoadingButton loading={isSubmitting} />
            <CancelLink />
          </Form>
        )}
      />
    );
  }
}

LocationDetails.propTypes = {
  addressHash: reqStrProp,
  event: reqFuncProp,
  groupHash: reqStrProp,
};

export default LocationDetails;
