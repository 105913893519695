import Data from './Data';

export default class GroupCommissionsData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      ACHNet: { type: 'boolean' },
      CommissionType: { type: 'number' },
      CommissionTypeName: { type: 'string' },
      PayableTo: { type: 'string' },
      PaymentType: { type: 'string' },
      Percent: { type: 'number' },
      Retired: { type: 'boolean' },
    };
  }
}
