const subpoenaKeys = {
  SubpoenaNumber: {
    Label: 'Subpoena Number',
    Type: 'string',
  },
  Completed: {
    Label: 'Completed',
    Type: 'bool',
    Options: [
      {
        Display: 'True',
        Value: true,
      },
      {
        Display: 'False',
        Value: false,
      },
    ],
  },
  'Country.Code': {
    Label: 'Country',
    Type: 'string',
    Options: [
      {
        Display: 'United States',
        Value: 'US',
      },
      {
        Display: 'Canada',
        Value: 'CA',
      },
    ],
  },
  DueDate: {
    Label: 'Due Date',
    Type: 'date',
  },
};

export default subpoenaKeys;
