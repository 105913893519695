// Modules
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
// Components
import { FormInput, FormSelect } from "../Forms/FormInputs";
import { LoadingButton } from "../Utilities/Components";
// PropTypes
import { reqFuncProp } from "../Utilities/PropTypes";

const DocUploadForm = ({ putDocument, type }) => {
  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    if (type === "Accounts") {
      setTypeOptions(accountDocTypes);
    } else if (type === "Locations") {
      setTypeOptions(locationDocTypes);
    } else if (type === "Groups") {
      setTypeOptions(locationDocTypes);
    } else {
      setTypeOptions(docTypes);
    }
    // eslint-disable-next-line
  }, []);

  const docTypes = [
    { name: "Transactions", value: 0 },
    { name: "Signed PDF", value: 1 },
    { name: "Unsigned PDF", value: 2 },
    { name: "Supporting Documents", value: 3 },
    { name: "Customer Info", value: 4 },
    { name: "Transaction Info", value: 5 },
    { name: "Subpoena Request", value: 6 },
    { name: "Signed Subpoena", value: 7 },
    { name: "Blockchain Forensics", value: 8 },
    { name: "Other", value: 9 },
  ];
  const accountDocTypes = [
    { name: "Compliance", value: 0 },
    { name: "Underwriting", value: 1 },
    { name: "Sales", value: 2 },
    { name: "Accounting", value: 3 },
    { name: "Customer Support", value: 4 },
  ];

  const locationDocTypes = [
    { name: "Signed Application", value: 5 },
    { name: "Supporting Documents", value: 6 },
    { name: "Entity Info", value: 7 },
    { name: "Owner Info", value: 8 },
    { name: "Bank Statements", value: 9 },
    { name: "MSB Registration", value: 10 },
    { name: "Business Licenses", value: 11 },
    { name: "Background Check - owner", value: 12 },
    { name: "Background Check - entity", value: 13 },
    { name: "Signed Contract", value: 14 },
    { name: "Invoices", value: 15 },
    { name: "Legal notice/demand", value: 16 },
    { name: "Legal communication", value: 17 },
    { name: "Signed Agreement", value: 18 },
    { name: "Signed Amendment", value: 19 },
    { name: "ACH", value: 20 },
    { name: "Broker Referral Agreement", value: 21 },
    { name: "Other", value: 22 },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        Name: "",
        Type: "",
      }}
      validate={(values) => {
        let errors = {};
        if (!values.Name) {
          errors.Name = "is required";
        }
        if (!values.Type) {
          errors.Type = "is required";
        }
        return errors;
      }}
      onSubmit={(values, actions) => {
        putDocument(values, actions);
      }}
      children={({ errors, touched, isSubmitting }) => (
        <Form className="form-outline">
          <h2 className="form-title">Define Document</h2>
          <section className="columns">
            <div className="column">
              <FormInput
                errors={errors}
                touched={touched}
                label="Document Name"
                name="Name"
                type="text"
                placeholder="Enter Document Name"
              />
            </div>
            <div className="column">
              <FormSelect
                errors={errors}
                touched={touched}
                label="Document Type"
                name="Type"
                placeholder="Select Document Type"
                options={typeOptions.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                ))}
              />
            </div>
          </section>

          <LoadingButton loading={isSubmitting} btnText="Upload Document" />
        </Form>
      )}
    />
  );
};

DocUploadForm.propTypes = {
  putDocument: reqFuncProp,
};

export default DocUploadForm;
