import React from 'react';
// Components
import { GenericModal } from '../../../Utilities/Components';

const ResetPinModal = ({
  isOpen,
  postResetPin,
  hash,
  isSubmitting,
  closeModal,
}) => {
  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={() => {
        closeModal('isResetOpen');
      }}
      headerMsg="Warning: Resetting Pin"
      viewMsg="This action will RESET the current PIN for this account. Please make sure the customer is aware."
      btnText="Reset Pin"
      btnAction={async () => {
        await postResetPin(hash);
      }}
      isSubmitting={isSubmitting}
    />
  );
};

export default ResetPinModal;
