import React from 'react';
import Header from './Header';
import BrokenLeaf from '../../Icons/BrokenLeaf';
import './../../Dashboards/NoMatch.scss';
import * as Sentry from '@sentry/react';

export const FallbackComponent = () => {
  return (
    <div className="columns no-match">
      <div className="column">
        <div className="has-text-centered">
          <BrokenLeaf />
          <Header title="There was a problem loading this page" />
        </div>
      </div>
    </div>
  );
};

export default function ({ children }) {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
}
