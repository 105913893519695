import React from "react";
import { FormSelect, FormInput } from "../Forms/FormInputs";
import { reqObjProp } from "../Utilities/PropTypes";

const GlobalRates = ({ feeRates }) => (
  <React.Fragment>
    <h2 className="form-title">Markup Rates</h2>
    <div className="columns is-multiline">
      <div className="column">
        <FormSelect
          label="Markup Rate 1"
          name="MarkupRate1"
          placeholder="Select Markup Rate"
          options={feeRates}
        />
      </div>
      <div className="column">
        <FormInput
          label="Markup Threshold 1"
          name="MarkupThreshold1"
          type="number"
        />
      </div>
    </div>
    <div className="columns is-multiline">
      <div className="column">
        <FormSelect
          label="Markup Rate 2"
          name="MarkupRate2"
          placeholder="Select Markup rate"
          options={feeRates}
        />
      </div>
      <div className="column">
        <FormInput
          label="Markup Threshold 2"
          name="MarkupThreshold2"
          type="number"
        />
      </div>
    </div>
    <div className="columns is-multiline">
      <div className="column">
        <FormSelect
          label="Markup Rate 3"
          name="MarkupRate3"
          placeholder="Select Markup Rate"
          options={feeRates}
        />
      </div>
      <div className="column">
        <FormInput
          label="Markup Threshold 3"
          name="MarkupThreshold3"
          type="number"
        />
      </div>
    </div>
    <div className="columns is-multiline">
      <div className="column">
        <FormSelect
          label="Markup Rate 4"
          name="MarkupRate4"
          placeholder="Select Markup Rate"
          options={feeRates}
        />
      </div>
      <div className="column">
        <FormInput
          label="Markup Threshold 4"
          name="MarkupThreshold4"
          type="number"
        />
      </div>
    </div>
    <div className="columns is-multiline">
      <div className="column">
        <FormSelect
          label="Markup Rate 5"
          name="MarkupRate5"
          placeholder="Select Markup Rate"
          options={feeRates}
        />
      </div>
      <div className="column">
        <FormInput
          label="Markup Threshold 5"
          name="MarkupThreshold5"
          type="number"
        />
      </div>
    </div>
    <h2 className="form-title">Markdown Rates</h2>
    <div className="columns is-multiline">
      <div className="column">
        <FormSelect
          label="Markdown Rate 1"
          name="MarkdownRate1"
          placeholder="Select Markdown Rate"
          options={feeRates}
        />
      </div>
      <div className="column">
        <FormInput
          label="Markdown Threshold 1"
          name="MarkdownThreshold1"
          type="number"
        />
      </div>
    </div>
    <div className="columns is-multiline">
      <div className="column">
        <FormSelect
          label="Markdown Rate 2"
          name="MarkdownRate2"
          placeholder="Select Markdown Rate"
          options={feeRates}
        />
      </div>
      <div className="column">
        <FormInput
          label="Markdown Threshold 2"
          name="MarkdownThreshold2"
          type="number"
        />
      </div>
    </div>
    <div className="columns is-multiline">
      <div className="column">
        <FormSelect
          label="Markdown Rate 3"
          name="MarkdownRate3"
          placeholder="Select Markdown Rate"
          options={feeRates}
        />
      </div>
      <div className="column">
        <FormInput
          label="Markdown Threshold 3"
          name="MarkdownThreshold3"
          type="number"
        />
      </div>
    </div>
    <div className="columns is-multiline">
      <div className="column">
        <FormSelect
          label="Markdown Rate 4"
          name="MarkdownRate4"
          placeholder="Select Markdown Rate"
          options={feeRates}
        />
      </div>
      <div className="column">
        <FormInput
          label="Markdown Threshold 4"
          name="MarkdownThreshold4"
          type="number"
        />
      </div>
    </div>
    <div className="columns is-multiline">
      <div className="column">
        <FormSelect
          label="Markdown Rate 5"
          name="MarkdownRate5"
          placeholder="Select Markdown Rate"
          options={feeRates}
        />
      </div>
      <div className="column">
        <FormInput
          label="Markdown Threshold 5"
          name="MarkdownThreshold5"
          type="number"
        />
      </div>
    </div>
  </React.Fragment>
);

GlobalRates.propTypes = {
  feeRates: reqObjProp
};

export default GlobalRates;
