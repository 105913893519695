// Modules
import React, { useEffect, useState } from 'react';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
// Subpoena Components
import SubpoenaActivity from './Tabs/SubpoenaActivity';
import SubpoenaAccountDetails from './Tabs/SubpoenaAccountDetails';
import Documents from './Tabs/Documents';
import LinkedAccounts from './Tabs/LinkedAccounts';
// Components
import {
  GoBack,
  Header,
  Loading,
  ToastError,
  ToastSuccess,
} from '../../Utilities/Components';
import ActivityForm from './ActivityForm';
// Helpers - Formatters
import { getDefaultTab, setDefaultTab } from '../../Utilities/Formatters';
// Icons
import { ICONS } from './../../Icons/IconsPath';
// Configs
import { API } from '../../Config/cfg.js';

const SingleSubpoena = props => {
  const [data, setData] = useState();
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [triggerAPI, setTriggerAPI] = useState(false);
  const [docHash, setDocHash] = useState();
  const [editing, setEditing] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    getSubpoena();
    // eslint-disable-next-line
  }, [editing]);

  const getSubpoena = () => {
    const { match } = props;
    const {
      params: { hash },
    } = match;

    API.get(`/compliance/subpoenas/${hash}`)
      .then(res => {
        setData(res.data);
      })
      .catch(error => {
        setErrors(error.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteDocument = () => {
    const { Hash } = data;
    const payload = {
      DocumentHash: docHash,
    };
    API.delete(`/compliance/subpoenas/${Hash}/documents`, { data: payload })
      .then(res => {
        ToastSuccess(`Document has been deleted`);
        setModalIsOpen(false);
        setTriggerAPI(true);
      })
      .catch(error => {
        ToastError(error);
      });
  };

  const postSubpoena = values => {
    const { Hash } = data;
    const { QCReviewAccountHash, Completed, InvestigationHash } = values;
    let req = {
      QCReviewAccountHash,
      InvestigationHash,
      Completed,
    };

    if (!req.InvestigationHash) {
      delete req.InvestigationHash;
    }
    setSubmitting(true);
    API.post(`/compliance/subpoenas/${Hash}`, req)
      .then(() => {
        setEditing(false);
      })
      .catch(error => {
        ToastError(error);
      })
      .finally(() => setSubmitting(false));
  };

  const openModal = docHash => {
    setModalIsOpen(true);
    setDocHash(docHash);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setDocHash('');
  };

  if (loading) {
    return <Loading errors={errors} />;
  }

  const { WorkedByAccount, Hash, SubpoenaNumber } = data;

  return (
    <>
      <GoBack
        history={props.history}
        defaultPath="/compliance/subpoenas"
        defaultName="Subpoena"
      />
      <div className="columns is-vcentered">
        <div className="column">
          <Header icon={ICONS.FILE} title={SubpoenaNumber} />
        </div>
        {WorkedByAccount && (
          <Header code="Worked by: " title={WorkedByAccount.FullName} />
        )}
      </div>
      <div className="columns">
        <SubpoenaActivity data={data} setEditing={setEditing} />
        <SubpoenaAccountDetails data={data} />
      </div>

      <ActivityForm
        data={data}
        postSubpoena={postSubpoena}
        isSubmitting={isSubmitting}
        editing={editing}
        setEditing={setEditing}
      />

      <Tabs
        className="tabs-container"
        defaultIndex={getDefaultTab('accounts', 0)}
        onSelect={index => setDefaultTab('accounts', index)}
      >
        <TabList className="columns tab-list">
          <Tab className="column tab">Linked Accounts</Tab>
          <Tab className="column tab">Documents</Tab>
        </TabList>

        <TabPanel>
          <LinkedAccounts hash={Hash} />
        </TabPanel>

        <TabPanel>
          <Documents
            hash={Hash}
            deleteDocument={deleteDocument}
            callAPI={triggerAPI}
            openModal={openModal}
            closeModal={closeModal}
            modalIsOpen={modalIsOpen}
          />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default SingleSubpoena;
