// Modules
import React from "react";
import { Link } from "react-router-dom";
// Components
import Table from "../../Table/Table";
import { formatPrice } from "../../Utilities/Formatters";

const MonthlyEarnings = () => {
  return (
    <Table
      columns={columnBuilder()}
      endpoint="accounting/reports/commissions"
      tablename={`Monthly Earnings`}
      size={20}
    />
  );
};

export default MonthlyEarnings;

const columnBuilder = () => {
  return [
    {
      Header: "Month",
      accessor: "Month",
      sortMethod: "Month",
    },
    {
      Header: "Cash Volume",
      accessor: "CashVolume",
      Cell: ({ row: { original } }) => formatPrice(original.CashVolume),
      sortMethod: "CashVolume",
    },
    {
      Header: "Gross Earnings",
      accessor: "GrossEarnings",
      Cell: ({ row: { original } }) => formatPrice(original.GrossEarnings),
      sortMethod: "GrossEarnings",
    },
    {
      Header: "Commissions Earned",
      accessor: "CommissionsPaid",
      Cell: ({ row: { original } }) => formatPrice(original.CommissionsPaid),
      sortMethod: "CommissionsPaid",
    },
    {
      Header: "View Earnings",
      accessor: "View",
      Cell: ({ row: { original } }) => (
        <Link to={`/accounting/reports/commissions/${original.Month}`}>
          View Per Location Breakdown
        </Link>
      ),
    },
  ];
};
