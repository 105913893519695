// Modules
import React from "react";
import { Link } from "react-router-dom";
// Components
import { EditableForm } from "../../Forms/EditableForm";
import {
  EditableInput,
  EditableCheckbox,
  EditableSelect,
} from "../../Forms/EditableInputs";
// Helpers - Formatters
import { formatPrice } from "../../Utilities/Formatters";
import {
  reqObjProp,
  reqBoolProp,
  reqFuncProp,
} from "./../../Utilities/PropTypes";
// Permissions
import { PLATFORMS_EDIT } from "./../../Permissions/Permissions";

const PlatformOverview = ({
  data,
  editing,
  toggleEdit,
  postPlatforms,
  isSubmitting,
  groups,
}) => {
  const callPostPlatforms = (val, actions) => {
    const { Hash } = data;
    let req = {
      Active: val.Active,
      Available: val.Available,
      GroupHash: val.GroupHash,
      Note: val.Note,
      DeviceIdentifier: val.DeviceIdentifier,
      PlatformType: { ThirdParty: val.ThirdParty },
    };
    postPlatforms(Hash, req, "editingPlatform", actions);
  };

  const {
    DeviceIdentifier,
    PlatformType,
    Active,
    Available,
    Note,
    Slug,
    Location,
    NoteCount,
    NoteCapacity,
    NoteCapacityRatio,
    Group,
    CashIn,
    PlatformType: { ThirdParty },
  } = data;

  return (
    <EditableForm
      callBack={callPostPlatforms}
      setValues={{
        DeviceIdentifier,
        Active,
        Available,
        ThirdParty,
        Location: Location ? Location.BusinessName : "-",
        PlatformName: PlatformType.Name,
        PlatformModel: PlatformType.Model,
        NoteCount: `${NoteCount}/${NoteCapacity}`,
        GroupHash: Group.Hash,
        Note,
        NoteCapacityRatio,
        Slug,
        CashIn: CashIn ? formatPrice(CashIn) : "-",
      }}
      customValidations={(values) => {
        let errors = {};
        return errors;
      }}
      editing={editing}
      permission={PLATFORMS_EDIT}
      triggerEdit={toggleEdit}
      editType="editingPlatform"
      sectionTitle="Platform Details"
      isSubmitting={isSubmitting}
    >
      <div className="columns">
        <div className="column">
          <EditableInput
            name="PlatformName"
            label="Platform Name"
            currentValue={
              PlatformType.Model
                ? PlatformType.Name + " " + PlatformType.Model
                : PlatformType.Name
            }
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="Location"
            label="Location"
            currentValue={
              Location ? (
                <Link to={`/locations/${Location.Hash}`}>
                  {Location.BusinessName}
                </Link>
              ) : (
                "-"
              )
            }
            disabled
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="DeviceIdentifier"
            label="Device Identifier"
            currentValue={DeviceIdentifier}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableSelect
            name="GroupHash"
            label="Group"
            placeholder="Please select a Group"
            currentValue={
              <Link to={`/groups/${Group.Hash}`}>{Group.Name}</Link>
            }
            data={groups}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="Slug"
            label="Slug"
            currentValue={Slug}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="CashIn"
            label="Cash In"
            currentValue={CashIn ? formatPrice(CashIn) : "-"}
            editing={editing}
            disabled
          />
        </div>
      </div>
      {PlatformType.Name === "Kiosk" && (
        <div className="columns">
          <div className="column">
            <EditableInput
              name="NoteCount"
              label="Note Count"
              currentValue={`${NoteCount}/${NoteCapacity}`}
              editing={editing}
              disabled
            />
          </div>
          <div className="column">
            <EditableInput
              name="NoteCapacityRatio"
              label="Note Ratio"
              currentValue={(NoteCapacityRatio * 100).toFixed(0) + "%"}
              editing={editing}
              disabled
            />
          </div>
        </div>
      )}
      <div className="columns">
        <div className="column is-half">
          <EditableInput
            name="Note"
            label="DevOps Note"
            currentValue={Note}
            editing={editing}
          />
        </div>
        <div className="column is-6">
          <EditableCheckbox
            name="Active"
            label="Active"
            currentValue={Active}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-6">
          <EditableCheckbox
            name="ThirdParty"
            label="Third Party"
            disabled={true}
            currentValue={ThirdParty}
          />
        </div>
        <div className="column is-half">
          <EditableCheckbox
            name="Available"
            label="Online"
            currentValue={Available}
            editing={editing}
            disabled
          />
        </div>
      </div>
    </EditableForm>
  );
};

PlatformOverview.propTypes = {
  data: reqObjProp,
  editing: reqBoolProp,
  postPlatforms: reqFuncProp,
  toggleEdit: reqFuncProp,
};

export default PlatformOverview;
