const formatNotificationData = (data) => {
  let rows = [];
  for (const Type in data) {
    // for each user subscribed to notification
    data[Type].forEach((contact) => {
      // check if they are already added to rows for table
      const index = rows.indexOf(
        rows.find((row) => row.Email === contact.Email)
      );
      if (index > -1) {
        // add Type related to row (email)
        rows[index].Types.push(Type);
      } else {
        // make a new row for table
        rows.push({
          Email: contact.Email,
          Name: contact.Name,
          Types: [Type],
        });
      }
    });
  }
  return rows;
};

export default formatNotificationData;
