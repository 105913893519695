import React from 'react';
import { withRouter, Link } from 'react-router-dom';
// Permissions
import Can from '../Permissions/Can';

const TableActions = ({ actions }) => {
  return (
    <React.Fragment>
      {actions.map((action, i) => (
        <div key={`${action.params}_${i}`} className="column is-narrow">
          {action.permission ? (
            <Can do={action.permission}>
              {' '}
              <Link
                key={action.params}
                className="btn secondary-btn-outline action"
                to={{
                  pathname: action.link,
                  hash: action.params,
                  state: action.state,
                }}
              >
                {action.name}
              </Link>
            </Can>
          ) : (
            <Link
              key={action.params}
              className="btn secondary-btn-outline action"
              to={{
                pathname: action.link,
                hash: action.params,
                state: action.state,
              }}
            >
              {action.name}
            </Link>
          )}
        </div>
      ))}
    </React.Fragment>
  );
};

export default withRouter(TableActions);
