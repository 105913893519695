// Modules
import React from "react";
// Components
import { GenericForm, FormikContext } from "../../Forms/Form";
import { FormSelect, FormInput } from "../../Forms/FormInputs";

const CreateNotification = ({ hash, history }) => {
  return (
    <>
      <GenericForm
        endpoint={`groups/${hash}/notifications`}
        setValues={{
          Email: "",
          Type: "",
          Name: "", // optional
        }}
        formTitle="Create Notification"
        goBack={{
          show: true,
          setHistory: history,
          setDefaultPath: `/groups/${hash}`,
          setDefaultName: "Group",
        }}
        toastMsg="Notification has been created"
        customValidations={(values) => {
          let errors = {};
          if (!values.Email) {
            errors.Email = "is required";
          }
          if (!values.Type) {
            errors.Type = "is required";
          }
          return errors;
        }}
      >
        <FormikContext.Consumer>
          {({ errors, touched }) => (
            <div className="columns">
              <div className="column is-3">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Email"
                  name="Email"
                  placeholder="Enter Email"
                  type="text"
                />
              </div>
              <div className="column is-3">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="Type"
                  name="Type"
                  placeholder="Select a Type"
                  options={
                    <>
                      {[
                        { Name: "Maintenance Mode Entered", Value: 0 },
                        { Name: "Maintenance Mode Exited", Value: 1 },
                        { Name: "Cash Collected", Value: 2 },
                        { Name: "Kiosk Locked", Value: 3 },
                        { Name: "Powered Off", Value: 4 },
                        { Name: "Powered On", Value: 5 },
                        { Name: "Rebooted", Value: 6 },
                        { Name: "Unavailable", Value: 7 },
                        { Name: "Available", Value: 8 },
                        { Name: "Cash Deposited", Value: 9 },
                        { Name: "Cash Cassette Removed", Value: 10 },
                        { Name: "Cash Cassette Inserted", Value: 11 },
                        { Name: "Transaction Initiated", Value: 12 },
                        { Name: "Transaction Executed", Value: 13 },
                        { Name: "Created", Value: 14 },
                        { Name: "Activated", Value: 15 },
                        { Name: "Dectivated", Value: 16 },
                        { Name: "Installed", Value: 17 },
                        { Name: "Screen Capture", Value: 18 },
                      ].map((item) => (
                        <option key={item.Name} value={item.Value}>
                          {item.Name}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>
              <div className="column is-3">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Name"
                  name="Name"
                  placeholder="Enter Name (optional)"
                  type="text"
                />
              </div>
            </div>
          )}
        </FormikContext.Consumer>
      </GenericForm>
    </>
  );
};

export default CreateNotification;
