import React from "react";
import { Link } from "react-router-dom";
// Helpers
import { capitalizeFirstLetter } from "../../Utilities/Formatters";

const columnBuilder = [
  {
    Header: "Name",
    accessor: "Name",
    sortMethod: "Name",
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/accounting/ach/${original.Hash}`}>
        {original.Name}
      </Link>
    ),
  },
  {
    Header: "Payor Name",
    accessor: "PayorName",
    sortMethod: "PayorName",
    Cell: ({ row: { original } }) => original.PayorName,
  },
  {
    Header: "Account Number",
    accessor: "AccountNumber",
    sortMethod: "AccountNumber",
    width: 150,
  },
  {
    Header: "Routing Number",
    accessor: "RoutingNumber",
    sortMethod: "RoutingNumber",
    width: 150,
  },
  {
    Header: "ACH Code",
    accessor: "TransactionACHCode",
    Cell: ({ row: { original } }) =>
      original.TransactionACHCodeString
        ? capitalizeFirstLetter(original.TransactionACHCodeString)
        : "-",
  },
];

export default columnBuilder;
