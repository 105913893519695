// Modules
import React from 'react';
// Components
import { EditableForm } from '../../Forms/EditableForm';
import { EditableInput, EditableSelect } from '../../Forms/EditableInputs';
// Helpers - Formatters
import {
  formatPercent,
  formatPrice,
  formatRates,
} from '../../Utilities/Formatters';
import {
  reqBoolProp,
  reqFuncProp,
  reqObjProp,
} from '../../Utilities/PropTypes';
// Permissions
import { GROUPS_EDIT } from '../../Permissions/Permissions';

const GroupSettings = ({
  editing,
  data,
  postGroup,
  toggleEdit,
  isSubmitting,
}) => {
  const { Name, Settings, InheritedSettings } = data;
  const {
    DailyTransactionLimit,
    LargeTransactionLimit,
    MonthlyTransactionLimit1,
    MonthlyTransactionLimit2,
    MarkdownRate,
    MarkupRate,
    PerTransactionLimit,
    FirstTimeTransactionFreeze,
  } = Settings;

  const callPostGroup = (values, actions) => {
    const req = createPayload(values);

    // Sends req to POST call in parent
    postGroup('groups', data.Hash, null, req, 'editingSettings', actions);
  };

  const triggerEdit = () => {
    toggleEdit('editingSettings');
  };

  const checkField = (key, field) => {
    if (
      field === 'unset' ||
      (key !== 'Name' && key !== 'ParentHash' && field === '')
    ) {
      return undefined;
    } else if (typeof field === 'number') {
      return parseFloat(field);
    }
    return field;
  };

  const createPayload = values => {
    const payload = {};
    for (let [key, value] of Object.entries(values)) {
      const payloadValue = checkField(key, value);
      payload[key] = payloadValue;
    }

    if (payload.Name === 'DigitalMint Managers') {
      payload.ParentHash = undefined;
    }

    return payload;
  };

  return (
    <EditableForm
      callBack={callPostGroup}
      setValues={{
        Name,
        DailyTransactionLimit,
        LargeTransactionLimit,
        MonthlyTransactionLimit1,
        MonthlyTransactionLimit2,
        MarkdownRate,
        MarkupRate,
        PerTransactionLimit,
        FirstTimeTransactionFreeze,
        // Inherited
        InheritedMonthlyTransactionLimit1:
          InheritedSettings.MonthlyTransactionLimit1,
        InheritedMonthlyTransactionLimit2:
          InheritedSettings.MonthlyTransactionLimit2,
        InheritedDailyTransactionLimit: InheritedSettings.DailyTransactionLimit,
        InheritedLargeTransactionLimit: InheritedSettings.LargeTransactionLimit,
        InheritedMarkdownRate: InheritedSettings.MarkdownRate,
        InheritedMarkupRate: InheritedSettings.MarkupRate,
        InheritedPerTransactionLimit: InheritedSettings.PerTransactionLimit,
        InheritedFirstTimeTransactionFreeze:
          InheritedSettings.FirstTimeTransactionFreeze,
      }}
      customValidations={values => {
        let errors = {};
        return errors;
      }}
      editing={editing}
      permission={GROUPS_EDIT}
      triggerEdit={triggerEdit}
      editType="editingSettings"
      sectionTitle="Limits"
      isSubmitting={isSubmitting}
      testID="GroupSettings"
    >
      <div className="columns">
        <div className="column">
          <EditableInput
            name="InheritedDailyTransactionLimit"
            label="Tier 1 Daily Limit"
            type="number"
            currentValue={formatPrice(InheritedSettings.DailyTransactionLimit)}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="DailyTransactionLimit"
            label="Custom Tier 1 Daily Limit"
            type="number"
            currentValue={formatPrice(DailyTransactionLimit)}
            editing={editing}
            testID="DailyTransactionLimit"
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableInput
            name="InheritedPerTransactionLimit"
            label="Per Transaction Limit"
            type="number"
            currentValue={formatPrice(InheritedSettings.PerTransactionLimit)}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="PerTransactionLimit"
            label="Custom Per Transaction Limit"
            type="number"
            currentValue={formatPrice(PerTransactionLimit)}
            editing={editing}
            testID="PerTransactionLimit"
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableInput
            name="InheritedLargeTransactionLimit"
            label="Tier 2 Daily Limit"
            type="number"
            currentValue={formatPrice(InheritedSettings.LargeTransactionLimit)}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="LargeTransactionLimit"
            label="Custom Tier 2 Daily Limit"
            type="number"
            currentValue={formatPrice(LargeTransactionLimit)}
            editing={editing}
            testID="LargeTransactionLimit"
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableInput
            name="InheritedMonthlyTransactionLimit1"
            label="Tier 1 Monthly Limit"
            type="number"
            currentValue={formatPrice(
              InheritedSettings.MonthlyTransactionLimit1
            )}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="MonthlyTransactionLimit1"
            label="Custom Tier 1 Monthly Limit"
            type="number"
            currentValue={formatPrice(
              InheritedSettings.MonthlyTransactionLimit1
            )}
            editing={editing}
            testID="MonthlyTransactionLimit1"
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableInput
            name="InheritedMonthlyTransactionLimit2"
            label="Tier 2 Monthly Limit"
            type="number"
            currentValue={formatPrice(
              InheritedSettings.MonthlyTransactionLimit2
            )}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="MonthlyTransactionLimit2"
            label="Custom Tier 2 Monthly Limit"
            type="number"
            currentValue={formatPrice(
              InheritedSettings.MonthlyTransactionLimit2
            )}
            editing={editing}
            testID="MonthlyTransactionLimit2"
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableSelect
            name="InheritedMarkupRate"
            label="Markup Rate"
            placeholder="Please select a Markup Rate"
            currentValue={formatPercent(InheritedSettings.MarkupRate)}
            data={formatRates(1, 40)}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableSelect
            name="MarkupRate"
            label="Custom Markup Rate"
            placeholder="Please select a Markup Rate"
            currentValue={formatPercent(MarkupRate)}
            data={formatRates(1, 40)}
            editing={editing}
            testID="MarkupRate"
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableSelect
            name="InheritedMarkdownRate"
            label="Markdown Rate"
            placeholder="Please select a Markdown Rate"
            currentValue={formatPercent(InheritedSettings.MarkdownRate)}
            data={formatRates(1, 40)}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableSelect
            name="MarkdownRate"
            label="Custom Markdown Rate"
            placeholder="Please select a Markdown Rate"
            currentValue={formatPercent(MarkdownRate)}
            data={formatRates(1, 40)}
            editing={editing}
            testID="MarkdownRate"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="InheritedFirstTimeTransactionFreeze"
            label="First Time Transaction Freeze"
            type="number"
            currentValue={formatPrice(
              InheritedSettings.FirstTimeTransactionFreeze
            )}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="FirstTimeTransactionFreeze"
            label="Custom First Time Transaction Freeze"
            type="number"
            currentValue={formatPrice(FirstTimeTransactionFreeze)}
            editing={editing}
            testID="FirstTimeTransactionFreeze"
          />
        </div>
      </div>
    </EditableForm>
  );
};

GroupSettings.defaultProps = {
  postGroup: () => {},
  toggleEdit: () => {},
  editing: false,
  data: {},
};

GroupSettings.propTypes = {
  postGroup: reqFuncProp,
  toggleEdit: reqFuncProp,
  editing: reqBoolProp,
  data: reqObjProp,
};

export default GroupSettings;
