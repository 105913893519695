// Modules
import React from "react";
import { Link } from "react-router-dom";
// Components
import Table from "./../../../Table/Table";
import {
  TradeTypePill,
  TxState,
  PlatformPill,
} from "./../../../Utilities/Components/TablePills";
// Helpers - Formatters
import { formatPrice, formatDateTime } from "../../../Utilities/Formatters";

const AccountTransactions = ({ hash }) => {
  return (
    <Table
      columns={columnBuilder}
      endpoint={`accounts/${hash}/transactions`}
      nested={true}
      tablename="Account Transactions"
      downloadable={true}
    />
  );
};

export default AccountTransactions;

const columnBuilder = [
  {
    Header: "Invoice #",
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/transactions/${original.Hash}`}>
        {original.InvoiceCode}
      </Link>
    ),
    sortMethod: "Invoice",
  },
  {
    Header: "CreatedAt",
    width: 100,
    Cell: ({ row: { original } }) => formatDateTime(original.CreatedAt),
    sortMethod: "CreatedAt",
  },
  {
    Header: "City & State",
    Cell: ({ row: { original } }) => {
      return original.Location.Address ? (
        <>
          {original.Location.Address.City},{" "}
          {original.Location.Address.StateCode}
        </>
      ) : (
        "-"
      );
    },
    sortMethod: "City",
  },
  {
    Header: "Location",
    style: { textAlign: "left " },
    Cell: ({ row: { original } }) =>
      original.Location.Address ? (
        <Link
          className="table-pill"
          to={`/locations/${original.Location.Hash}`}
        >
          {original.Location.BusinessName}
        </Link>
      ) : (
        "-"
      ),
    sortMethod: "Location",
  },
  {
    Header: "Coin Amount",
    Cell: ({ row: { original } }) => {
      return original.Product ? (
        <>
          <p>{original.BaseQuantity}</p>
          <p>{TradeTypePill(original.Product.Symbol)}</p>
        </>
      ) : null;
    },
    sortMethod: "CoinAmount",
  },
  {
    Header: "Fiat Amount",
    Cell: ({ row: { original } }) => formatPrice(original.Collected),
    sortMethod: "FiatAmount",
  },
  {
    Header: "Platform",
    Cell: ({ row: { original } }) =>
      original.Platform
        ? PlatformPill(
            `${original.Platform.PlatformType.Name} ${original.Platform.PlatformType.Model}`
          )
        : null,
    sortMethod: "Platform",
  },
  {
    Header: "Status",
    accessor: "StateDesc",
    Cell: ({ row: { original } }) =>
      TxState(original.State, original.StateDesc),
    sortMethod: "Status",
  },
];
