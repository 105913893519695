const fundingKeys = {
  Account: {
    Label: "Account",
    Type: "string",
  },
  Credit: {
    Label: "Credit",
    Type: "number",
  },
  FundingDate: {
    Label: "Funding Date",
    Type: "daterange",
  },
  Source: {
    Label: "Source",
    Type: "string",
  },
};

export default fundingKeys;
