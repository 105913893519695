// Modules
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
// Components
import {
  GoBack,
  Header,
  LoadingButton,
  ToastError,
} from "../../Utilities/Components";
import { FormInput, FormSelect } from "../../Forms/FormInputs";
import { ToastSuccess } from "../../Utilities/Components";
// Config
import { API } from "../../Config/cfg.js";

const DECISIONS = [
  { label: "False Positive", value: 0 },
  { label: "Confirmed", value: 1 },
];

const CreatePEP = ({ history }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    API.get("/countries")
      .then((res) => setCountries(res.data.Data))
      .catch((err) => {
        ToastError(err.response.data.Message);
      });
  };

  const putPEP = (values, actions) => {
    if (!values.AccountHash) {
      delete values.AccountHash;
    }
    if (values.Decision) {
      values.Decision = parseInt(values.Decision);
    } else {
      delete values.Decision;
    }

    API.put("/compliance/peps", values)
      .then((res) => {
        ToastSuccess(`You successfully created a new PEP`);
        history.push("/compliance/peps");
        actions.resetForm();
      })
      .catch((error) => {
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      <GoBack
        history={history}
        defaultPath="/compliance/peps"
        defaultName="PEPs"
      />
      <div id="formContainer">
        <Header title="Create PEP" />
        <div className="form-outline form-create">
          <Formik
            initialValues={{
              FirstName: "",
              LastName: "",
              Narrative: "",
              Decision: "",
              AccountHash: "",
              CountryHash: "",
            }}
            validate={(values) => {
              let errors = {};
              if (!values.FirstName) {
                errors.FirstName = "is required";
              }
              if (!values.LastName) {
                errors.LastName = "is required";
              }
              if (!values.CountryHash) {
                errors.CountryHash = "is required";
              }
              return errors;
            }}
            onSubmit={(values, actions) => {
              putPEP(values, actions);
            }}
            children={({ errors, touched, isSubmitting, values }) => (
              <Form>
                <div className="columns">
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="First Name"
                      name="FirstName"
                      type="text"
                      placeholder="John"
                    />
                  </div>
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Last Name"
                      name="LastName"
                      type="text"
                      placeholder="Doe"
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <FormSelect
                      errors={errors}
                      touched={touched}
                      label="Decision"
                      name="Decision"
                      placeholder="Select a Decision"
                      options={
                        <>
                          {DECISIONS.map((item, i) => (
                            <option key={item.label} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </div>
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Account Hash"
                      name="AccountHash"
                      type="text"
                      placeholder="Account Hash"
                    />
                  </div>
                  <div className="column">
                    <FormSelect
                      errors={errors}
                      touched={touched}
                      label="Country of Origin"
                      name="CountryHash"
                      placeholder="Select A Country"
                      options={
                        <>
                          {countries.map((item) => (
                            <option key={item.Hash} value={item.Hash}>
                              {item.Name}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Narrative"
                      name="Narrative"
                      type="text"
                      component="textarea"
                      placeholder="Enter Narrative"
                    />
                  </div>
                </div>

                <div>
                  <LoadingButton loading={isSubmitting} />
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreatePEP;
