import React from 'react';
import { Field, ErrorMessage } from 'formik';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './FormInputs.scss';

const FormInput = ({
  autoFocus,
  component,
  defaultValue,
  disabled,
  errors,
  id,
  label,
  max,
  min,
  name,
  placeholder,
  readOnly,
  step,
  touched,
  type,
  testID,
}) => {
  const errorClass = {
    'input-error':
      name === 'freeformMsg'
        ? errors && errors[name]
        : errors && errors[name] && touched && touched[name],
  };

  return (
    <div className="form-input-component" data-testid={testID}>
      <label
        htmlFor={id || name}
        className={classnames('item-label', errorClass)}
      >
        {label}
        <ErrorMessage name={name} component="span" className="form-error" />
      </label>
      <Field
        autoFocus={autoFocus}
        className={classnames(errorClass)}
        component={component}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id || name}
        max={max}
        min={min}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        step={step}
        type={type}
        data-testid={`${testID}-input`}
      />
    </div>
  );
};

FormInput.defaultProps = {
  autoFocus: false,
  component: 'input',
  disabled: false,
  readOnly: false,
  testID: 'xFormInput',
  type: 'text',
};

FormInput.propTypes = {
  autoFocus: PropTypes.bool,
  component: PropTypes.string,
  defaultValue: PropTypes.string,
  errors: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  testID: PropTypes.string,
  touched: PropTypes.object,
};

export default FormInput;
