// Modules
import React from "react";
// Components
import Table from "../../Table/Table";
import columnBuilder from "./columnBuilder";
import { COMPLIANCE_BAN } from "./../../Permissions/Permissions";

const Bans = (props) => (
  <Table
    history={props.history}
    columns={columnBuilder}
    endpoint="compliance/bans"
    filter={true}
    tablename="Pending Account Bans"
    actions={[
      {
        name: "Ban Wallet Address",
        link: "/compliance/wallet-bans",
        permission: COMPLIANCE_BAN,
      },
    ]}
  />
);

export default Bans;
