import React, { useEffect, useState } from 'react';
// Configs
import { API } from '../Config/cfg.js';
// Components
import { ToastError } from '../Utilities/Components';
import { LoadingSVG } from '../Utilities/Components/Loading/Loading';
// Helpers - Formatters
import { formatPrice, formatNumber } from '../Utilities/Formatters';

const TransactionMetaData = ({ filter }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Listens for changes to the table's filter
  useEffect(() => {
    setLoading(true);
    API.get(`/transactions-stats${filter}`)
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(error => {
        ToastError(error?.response?.data);
      });
  }, [filter]);

  const displayTitle = (data, type = 'number') => {
    if (type === 'price') {
      return <h2>{data ? formatPrice(data) : 0}</h2>;
    } else {
      return <h2>{data ? formatNumber(data) : 0}</h2>;
    }
  };

  if (loading) {
    return (
      <div style={{ height: 138.398 }}>
        <LoadingSVG />
      </div>
    );
  }

  return (
    <div className="columns is-centered is-vcentered metadata-container">
      <div className="column">
        <div className="form-outline form-create">
          {displayTitle(data?.GrossDollarAmount, 'price')}
          <h4 className="item-label">Gross Cash Volume</h4>
        </div>
      </div>
      <div className="column">
        <div className="form-outline form-create">
          {displayTitle(data?.UniqueCustomers)}
          <h4 className="item-label">Unique Customers</h4>
        </div>
      </div>
      <div className="column">
        <div className="form-outline form-create">
          {displayTitle(data?.TotalTransactions)}
          <h4 className="item-label">Total Transactions</h4>
        </div>
      </div>
      <div className="column">
        <div className="form-outline form-create">
          {displayTitle(data?.SettledTransactions)}
          <h4 className="item-label">Settled Transactions</h4>
        </div>
      </div>
      <div className="column">
        <div className="form-outline form-create">
          {displayTitle(data?.CanceledTransactions)}
          <h4 className="item-label">Canceled Transactions</h4>
        </div>
      </div>
    </div>
  );
};

export default TransactionMetaData;
