// Modules
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import Table from '../../Table/Table';
import { CommissionType } from '../../Utilities/Components/TablePills';
import { GoBack } from '../../Utilities/Components';
// Helpers - Formatters
import { formatPrice, formatPercent } from '../../Utilities/Formatters';

const GroupMonthlyEarnings = ({ location, history }) => {
  // Passing URL path from previous table so people can change the MM/YYYY params in the url
  const url = location.pathname.substring(1);
  const date = url.substring(31, 38);

  return (
    <>
      <GoBack
        history={history}
        defaultPath="/accounting/commissions"
        defaultName="Accounting"
      />
      <Table
        history={history}
        columns={columnBuilder}
        downloadable={true}
        endpoint={`${url}`}
        filter={true}
        tablename={`Earnings By Month - ${date}`}
      />
    </>
  );
};

export default GroupMonthlyEarnings;

const columnBuilder = [
  {
    Header: 'Location',
    accessor: 'Location.Address.StateCode',
    minWidth: 210,
    Cell: ({ row: { original } }) =>
      original?.LocationGroupCommission?.Location?.Address ? (
        <Link
          to={`/locations/${original.LocationGroupCommission.Location.Hash}`}
        >
          {original.LocationGroupCommission.Location.Address.StateCode},{' '}
          {original.LocationGroupCommission.Location.Address.City},
          {original.LocationGroupCommission.Location.Address.Street}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    Header: 'Type',
    minWidth: 255,
    accessor: 'LocationGroupCommission.GroupCommission.CommissionTypeName',
    Cell: ({ row: { original } }) =>
      original?.LocationGroupCommission?.GroupCommission
        ? CommissionType(
            original.LocationGroupCommission.GroupCommission.CommissionType,
            original.LocationGroupCommission.GroupCommission.CommissionTypeName
          )
        : '-',
  },
  {
    Header: 'Percent',
    maxWidth: 90,
    Cell: ({ row: { original } }) =>
      original?.LocationGroupCommission?.GroupCommission?.Percent
        ? formatPercent(
            original.LocationGroupCommission.GroupCommission.Percent
          )
        : '-',
  },
  {
    Header: 'Fiat',
    maxWidth: 90,
    Cell: ({ row: { original } }) =>
      original?.LocationGroupCommission?.GroupCommission?.Fiat
        ? formatPrice(original.LocationGroupCommission.GroupCommission.Fiat)
        : '-',
    sortMethod: 'Fiat',
  },
  {
    Header: 'Cash Volume',
    maxWidth: 90,
    accessor: 'CashVolume',
    Cell: ({ row: { original } }) => formatPrice(original.CashVolume),
    sortMethod: 'CashVolume',
  },
  {
    Header: 'Gross Earnings',
    maxWidth: 90,
    accessor: 'GrossRevenue',
    Cell: ({ row: { original } }) => formatPrice(original.GrossRevenue),
    sortMethod: 'GrossRevenue',
  },
  {
    Header: 'Commissions Paid',
    maxWidth: 90,
    accessor: 'PayoutAmount',
    Cell: ({ row: { original } }) => formatPrice(original.PayoutAmount),
    sortMethod: 'PayoutAmount',
  },
  {
    Header: 'Group',
    minWidth: 200,
    accessor: 'LocationGroupCommission.Location.Group.Name',
    Cell: ({ row: { original } }) =>
      original?.LocationGroupCommission?.Location?.Group ? (
        <Link
          to={`/groups/${original.LocationGroupCommission.Location.Group.Hash}`}
        >
          {original.LocationGroupCommission.Location.Group.Name}
        </Link>
      ) : (
        '-'
      ),
    sortMethod: 'GroupOwner',
  },
  {
    Header: 'Payee',
    minWidth: 200,
    accessor: 'LocationGroupCommission.GroupCommission.DebitAccount.PayorName',
    Cell: ({ row: { original } }) =>
      original?.LocationGroupCommission?.GroupCommission?.DebitAccount ? (
        <Link
          to={`/accounting/ach/${original.LocationGroupCommission.GroupCommission.DebitAccount.Hash}`}
        >
          {
            original.LocationGroupCommission.GroupCommission.DebitAccount
              .PayorName
          }
        </Link>
      ) : (
        '-'
      ),
    sortMethod: 'GroupPayee',
  },
];
