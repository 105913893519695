// Modules
import React from "react";
// Components
import { ListGroup, ListGroupItem } from "../../../Utilities/Components";
// Helpers - Formatters
import { formatDateTime } from "../../../Utilities/Formatters";
// Icons
import Icon from "../../../Icons/Icon";
import { ICONS } from "../../../Icons/IconsPath";

const SubpoenaActivity = ({ data, setEditing }) => {
  const {
    Completed,
    ComplianceInvestigation,
    Country,
    CreatedAt,
    DueDate,
    UpdatedAt,
    QCReviewAccount,
    RequestingAgency,
  } = data;
  return (
    <div className="column">
      <ListGroup title="Subpoena Activity" editable>
        <ListGroupItem
          name="Completed"
          value={
            <Icon
              icon={Completed ? ICONS.ACTIVE : ICONS.INACTIVE}
              rightMargin="5"
              size="18"
              color={Completed ? "#009245" : "#e71d36"}
            />
          }
        />
        <ListGroupItem name="Created At" value={formatDateTime(CreatedAt)} />
        <ListGroupItem name="Country Of Origin" value={Country?.Code} />
        <ListGroupItem
          name="Investigation"
          value={ComplianceInvestigation ? ComplianceInvestigation.Code : "-"}
        />
        <ListGroupItem
          name="QC Reviewer"
          value={QCReviewAccount ? QCReviewAccount.FullName : "-"}
        />

        <ListGroupItem name="Due Date" value={formatDateTime(DueDate)} />
        <ListGroupItem name="Updated At" value={formatDateTime(UpdatedAt)} />
        <ListGroupItem name="Requesting Agency" value={RequestingAgency} />
        <ListGroupItem
          name="Edit Activity"
          value={
            <button
              onClick={() => setEditing(true)}
              className="btn primary-btn-outline subpoena-activity"
            >
              <Icon icon={ICONS.EDIT} className="icon" size="16" />
            </button>
          }
        />
      </ListGroup>
    </div>
  );
};

export default SubpoenaActivity;
