import React from 'react';

const SelectFilterValue = ({
  valueLabel,
  filterKey,
  setFilterValue,
  grouped,
  filterOptions,
}) => {
  if (grouped) {
    return (
      <React.Fragment>
        {valueLabel}
        <select
          id="valueSelect"
          onChange={e => setFilterValue(e, true)}
          disabled={!filterKey ? true : false}
          defaultValue=""
        >
          <option value="" key="placeholder" disabled>
            ...
          </option>
          {filterOptions.map((section, index) => (
            <optgroup key={index} label={section.Group}>
              {section.Values.map((filter, index) => (
                <option key={index} value={JSON.stringify(filter)}>
                  {filter.Display}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {valueLabel}
        <select
          id="valueSelect"
          onChange={e => setFilterValue(e, true)}
          disabled={!filterKey ? true : false}
          defaultValue=""
        >
          <option value="" key="placeholder" disabled>
            ...
          </option>
          {filterOptions.map((filter, index) => (
            <option key={index} value={JSON.stringify(filter)}>
              {filter.Display}
            </option>
          ))}
        </select>
      </React.Fragment>
    );
  }
};

export default SelectFilterValue;
