// Modules
import React from "react";
import columnBuilder from "./columnBuilder";
// Components
import Table from "./../Table/Table";
// Permissions
import { LOCATIONS_CREATE } from "./../Permissions/Permissions";

const Locations = (props) => {
  return (
    <Table
      history={props.history}
      location={props.location}
      columns={columnBuilder}
      endpoint="locations"
      filter={true}
      tablename="Locations"
      downloadable={true}
      actions={[
        {
          name: "Create New Location",
          link: "/locations/create-location",
          permission: LOCATIONS_CREATE,
        },
      ]}
      customize
    />
  );
};

export default Locations;
