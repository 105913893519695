export const formatLabel = label => (label ? `${label}: ` : '');

export const getOptionLabel = (type, label) => {
  const OPTION_LABEL = {
    CashCourierHash: 'CashCourier',
    DebitAccountHash: 'DebitAccount',
  };

  return OPTION_LABEL[type] || label;
};
