// Modules
import React, { Component } from "react";
import { Link } from "react-router-dom";
// Components
import { ToastError } from "../Utilities/Components";

class SearchBox extends Component {
  state = {
    searchTerm: "",
    searchType: "accounts",
  };

  setSearchValue = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  setSearchType = (event) => {
    this.setState({ searchType: event.target.value });
  };

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.clickElement.click();
    }
  }

  validateInputs = (e) => {
    const { searchTerm, searchType } = this.state;

    if (searchTerm.length === 0 && searchType.length === 0) {
      e.preventDefault();
      ToastError("Please select a search term and type");
    } else if (searchTerm.length === 0) {
      e.preventDefault();
      ToastError("Please select a search term");
    } else if (searchType.length === 0) {
      e.preventDefault();
      ToastError("Please select a search type");
    }

    if (searchTerm.length > 0 && searchType.length > 0) {
      document.getElementById("searchForm").reset();
      this.setState({ searchTerm: "", searchType: "accounts" });
    }
  };

  render() {
    const { searchTerm, searchType } = this.state;
    let options = [
      {
        Hash: "accounts",
        Name: "Accounts",
      },
      {
        Hash: "transactions",
        Name: "Transactions",
      },
      {
        Hash: "locations",
        Name: "Locations",
      },
      {
        Hash: "platforms",
        Name: "Platforms",
      },
      {
        Hash: "groups",
        Name: "Groups",
      },
    ];
    return (
      <React.Fragment>
        <form
          id="searchForm"
          className="nav-form columns is-multiline"
          // Prevents the page from refreshed when ENTER is clicked
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <input
            type="search"
            name="search"
            className="search-box column is-6 is-12-mobile"
            placeholder="Search by name, phone, city, txID"
            onInput={this.setSearchValue}
            onKeyPress={(e) => this.handleKeyPress(e)}
          />
          <select
            className="column is-3 is-7-mobile"
            onChange={this.setSearchType}
            defaultValue=""
          >
            {options.map((item) => (
              <option key={item.Hash} value={item.Hash}>
                {item.Name}
              </option>
            ))}
          </select>
          <Link
            // ref to this node so that handleKeyPress can see it
            ref={(node) => (this.clickElement = node)}
            onClick={this.validateInputs}
            to={{
              pathname: `/search-results/${searchType}`,
              state: { searchTerm: searchTerm, searchType: searchType },
            }}
            className={`btn primary-btn ${!searchTerm && "disabled-link"}`}
          >
            Search
          </Link>
        </form>
      </React.Fragment>
    );
  }
}

export default SearchBox;
