const getDefaultTab = (from, setIndex) => {
  let tab = localStorage.getItem(`${from}Tab`);
  if (typeof setIndex === "undefined") {
    return setIndex;
  }

  if (tab) {
    return parseInt(tab);
  } else {
    return 0;
  }
};

const setDefaultTab = (from, index) => {
  localStorage.setItem(`${from}Tab`, index);
};

const clearDefaultTab = (index) => {
  const storage = localStorage;
  for (let key in storage) {
    if (key.includes("Tab")) {
      localStorage.removeItem(key);
    }
  }
};

export { clearDefaultTab, getDefaultTab, setDefaultTab };
