import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
// Account Components
import GroupsTable from "./Tabs/GroupsTable";
import AccountAPIKeys from "./Tabs/AccountAPIKeys";
// Components
import { GoBack, Header } from "../../Utilities/Components";
// Helpers - Formatters
import { formatDateTime } from "../../Utilities/Formatters";
import { reqObjProp } from "./../../Utilities/PropTypes";
// Icons
import { ICONS } from "../../Icons/IconsPath";

const SingleServiceAccount = ({ data, history }) => {
  const { Account } = data;
  return (
    <React.Fragment>
      <GoBack
        history={history}
        defaultPath="/accounts"
        defaultName="Accounts"
      />
      <div className="columns form-outline form-create columns is-multiline">
        <div className="column is-narrow">
          <Header icon={ICONS.SETTINGS} title={Account.Email} />
          <p>
            <span className="item-label">Compliance Operator:</span>{" "}
            {Account.ComplianceOperator.Name}
          </p>
          <p>
            <span className="item-label">Preferred:</span>{" "}
            {Account.PreferredPhoneNumber.Phone}
          </p>
          <p>
            <span className="item-label">Created:</span>{" "}
            {formatDateTime(Account.CreatedAt)}
          </p>
        </div>
      </div>
      <br />
      <Tabs className=" tabs-container nested-tabs">
        <TabList className="columns tab-list">
          <Tab className="column tab">API Keys</Tab>
          <Tab className="column tab">Groups</Tab>
        </TabList>

        <TabPanel>
          <AccountAPIKeys accountHash={Account.Hash} />
        </TabPanel>
        <TabPanel>
          <GroupsTable accountHash={Account.Hash} />
        </TabPanel>
      </Tabs>
    </React.Fragment>
  );
};

SingleServiceAccount.propTypes = {
  data: reqObjProp,
  history: reqObjProp,
};

export default SingleServiceAccount;
