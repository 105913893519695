/* eslint no-console: ["error", { allow: [ "error"] }] */

// Modules
import React, { Component } from 'react';
// Product Components
import ProductOverview from './ProductOverview';
import Asset from './../../Asset/Asset';
// Components
import {
  GoBack,
  Header,
  Loading,
  ToastError,
  ToastSuccess,
} from '../../Utilities/Components';
// Images
import Bitcoin from './../../images/bitcoin.svg';
import Ethereum from './../../images/ethereum.svg';
import Litecoin from './../../images/litecoin.svg';
import BitcoinCash from './../../images/bitcoin_cash.svg';
// Configs
import { API } from '../../Config/cfg.js';

class SingleProduct extends Component {
  state = {
    data: {},
    loading: true,
    editingProduct: false,
  };

  componentDidMount() {
    this.getProductEndpoints();
  }

  toggleEdit = type => {
    this.setState({ [type]: !this.state[type] });
  };

  postProductEndpoints = (data, editing) => {
    const { hash } = this.props.match.params;
    let payload = {
      ProductHash: data.ProductHash,
      Buy: data.Buy,
      Sell: data.Sell,
    };
    API.post(`/platforms/${hash}/products`, payload)
      .then(res => {
        this.setState({ [editing]: false });
        ToastSuccess('Changes to location details have been saved');
        this.getProductEndpoints(res.data.Hash);
      })
      .catch(error => {
        this.setState({ editing: true });
        ToastError(error);
      });
  };

  getProductEndpoints = newHash => {
    const { hash } = this.props.match.params;
    const { productHash } = this.props.location.state;
    const pathname = `/platforms/${hash}/products?hash=${
      newHash || productHash
    }`;
    API.get(pathname)
      .then(res => {
        const data = res.data.Data.filter(
          currency => currency.Hash === productHash
        )[0];
        this.setState({
          data,
          loading: false,
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  setCurrencyType = data => {
    let type = '';
    switch (data) {
      case 'BTCUSD':
        type = Bitcoin;
        break;
      case 'LTCUSD':
        type = Litecoin;
        break;
      case 'ETHUSD':
        type = Ethereum;
        break;
      case 'BCHUSD':
        type = BitcoinCash;
        break;
      default:
        type = '';
        break;
    }
    return type;
  };

  render() {
    const { data, editingProduct, loading } = this.state;
    const { hash } = this.props.match.params;

    if (loading) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        <GoBack
          history={this.props.history}
          defaultPath={`/platforms/${hash}`}
          defaultName="Platform"
        />
        <Header title={data.Description} />
        <div className="columns">
          <div className="column">
            <Asset
              icon={this.setCurrencyType(data.Symbol)}
              info={data.Description}
              width="260"
            />
          </div>
          <div className="column is-7">
            <ProductOverview
              data={data}
              editing={editingProduct}
              postProductEndpoints={this.postProductEndpoints}
              toggleEdit={this.toggleEdit}
            />
          </div>
        </div>
        <div />
      </React.Fragment>
    );
  }
}

export default SingleProduct;
