/* COMPONENT OVERVIEW
- Takes props from parent 
- Renders Account overview data
- Calls postAccount function prop & sends request to parent
*/
// Modules
import React from "react";
// Components
import { EditableForm } from "../../Forms/EditableForm";
import { EditableInput, EditableSelect } from "../../Forms/EditableInputs";
// Helpers - Formatters
import { formatDate } from "../../Utilities/Formatters";
import {
  accountProps,
  reqBoolProp,
  reqFuncProp,
} from "../../Utilities/PropTypes";
// Permissions
import { ACCOUNT_EDIT } from "../../Permissions/Permissions";

const Overview = ({ data, editing, toggleEdit, postAccount }) => {
  const callPostAccount = (req, actions) => {
    // Sends req to POST call in parent
    postAccount("accounts", data.Hash, null, req, "editingOverview", actions);
  };

  const { DOB, Email, Username, FirstName, LastName, MiddleName, Gender, SSN } =
    data;
  return (
    <EditableForm
      callBack={callPostAccount}
      setValues={{
        DOB: formatDate(DOB, "YYYY-MM-DD", true),
        Email,
        Username,
        Gender,
        FirstName,
        LastName,
        MiddleName,
        SSN,
      }}
      customValidations={(values) => {
        let errors = {};
        if (!values.FirstName) {
          errors.FirstName = "is required";
        }
        if (!values.LastName) {
          errors.LastName = "is required";
        }
        return errors;
      }}
      editing={editing}
      triggerEdit={toggleEdit}
      permission={ACCOUNT_EDIT}
      editType="editingOverview"
      sectionTitle="Personal Details"
    >
      <div className="columns">
        <div className="column">
          <EditableInput
            name="FirstName"
            label="First Name"
            type="text"
            currentValue={FirstName}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="LastName"
            label="Last Name"
            type="text"
            currentValue={LastName}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="MiddleName"
            label="Middle Name"
            type="text"
            currentValue={MiddleName}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="Username"
            label="Username"
            type="text"
            currentValue={Username}
            editing={editing}
            disabled
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="Email"
            label="Email"
            type="text"
            currentValue={Email}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="DOB"
            label="DOB"
            type="date"
            currentValue={formatDate(DOB, undefined, true)}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="SSN"
            label="National ID Number"
            type="text"
            currentValue={SSN}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableSelect
            name="Gender"
            label="Gender"
            placeholder="Please select a Gender"
            currentValue={Gender && Gender.toUpperCase()}
            data={[
              { Name: "-", Hash: "" },
              { Name: "Male", Hash: "m" },
              { Name: "Female", Hash: "f" },
            ]}
            editing={editing}
          />
        </div>
      </div>
    </EditableForm>
  );
};

Overview.propTypes = {
  postAccount: reqFuncProp,
  toggleEdit: reqFuncProp,
  editing: reqBoolProp,
  data: accountProps,
};

export default Overview;
