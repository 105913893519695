/* COMPONENT OVERVIEW
- Dashboard
  - Renders routing for entire app
  - Renders users dashboard based on permissions
  - Renders navigation 
  - Renders footer 
*/
// Modules
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  accountProps,
  reqFuncProp,
  sessionProps,
} from '../Utilities/PropTypes';
// Components
import Footer from './../Footer/Footer';
import Icon from '../Icons/Icon';
import NavBar from '../NavBar/NavBar';
import SideNavbar from './../NavBar/SideNavbar';
import HiddenNavbar from '../NavBar/HiddenNavbar';
import MobileNavbar from '../NavBar/MobileNavbar';
import Routes from './PrivateRoutes';
import { RouteLinks } from './RouteLinks';
// Styles
import './Dashboard.scss';

const Dashboard = ({ logout, data }) => {
  const [hideSidebar, setHideSidebar] = useState(false);

  const openSidebar = () => {
    setHideSidebar(false);
  };

  const navLinkComponent = (link, event, hideIcon, isChild) => {
    return (
      <div key={link.path}>
        <NavLink
          activeClassName="is-active"
          className={isChild ? 'child-links' : 'links'}
          to={link.path}
          onClick={event}
          exact={link.exact}
        >
          {!hideIcon && (
            <Icon icon={link.icon} color="#f9f6f2" size="17" rightMargin="15" />
          )}
          {link.name}
        </NavLink>
      </div>
    );
  };

  const navIconComponent = (link, event, hideIcon, isChild) => {
    return (
      <div key={link.path}>
        <NavLink
          key={link.path}
          activeClassName="is-active"
          className={isChild ? 'child-links' : 'hidden-links'}
          to={link.path}
          onClick={event}
          exact
        >
          <div className="columns">
            <div className="column has-text-centered">
              {!hideIcon && <Icon icon={link.icon} color="#f9f6f2" size="22" />}
              <p className="hidden-text">{link.name}</p>
            </div>
          </div>
        </NavLink>
      </div>
    );
  };

  return (
    <>
      <MobileNavbar
        data={data}
        logout={logout}
        routes={RouteLinks}
        navComponent={navLinkComponent}
      />
      <div
        data-testid="dashboard-component"
        id={!hideSidebar ? 'dashboardContainer' : 'hiddenSidebarDashboard'}
      >
        {hideSidebar ? (
          <HiddenNavbar
            routes={RouteLinks}
            navComponent={navIconComponent}
            toggle={() => setHideSidebar(!hideSidebar)}
            openSidebar={() => openSidebar()}
          />
        ) : (
          <SideNavbar
            routes={RouteLinks}
            navComponent={navLinkComponent}
            toggle={() => setHideSidebar(!hideSidebar)}
          />
        )}
        <div id="contentContainer">
          <NavBar data={data} logout={logout} />
          <Routes />
          <Footer />
        </div>
      </div>
    </>
  );
};

Dashboard.propTypes = {
  logout: reqFuncProp,
  data: accountProps,
  session: sessionProps,
};

export default Dashboard;
