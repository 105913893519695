import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from '../Utilities/Components';
import { checkPermission, findDefaultRoute } from './CheckPermission';
import { PermissionsContext } from './PermissionContext';
import PropTypes from 'prop-types';

const PermissionedRoute = ({
  component: Component,
  endpoint,
  hash,
  location,
  path,
  permission,
  search,
  type,
  ...rest
}) => {
  const checkRedirect = (props, userPermissionsProps) => {
    return checkPermission(userPermissionsProps, permission) === true ? (
      <ErrorBoundary>
        <Component
          hash={hash && props.location.hash.slice(1)}
          endpoint={endpoint && endpoint}
          {...props}
        />
      </ErrorBoundary>
    ) : (
      <Redirect
        to={
          path === '/'
            ? findDefaultRoute(userPermissionsProps)
            : '/not-permissioned'
        }
      />
    );
  };

  return (
    <PermissionsContext.Consumer>
      {userPermissionsProps => (
        <Route
          {...rest}
          location={{ ...location, search, defaultFilter: !!search }}
          children={props => checkRedirect(props, userPermissionsProps)}
        />
      )}
    </PermissionsContext.Consumer>
  );
};

PermissionedRoute.defaultProps = {
  path: '/',
};

PermissionedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  endpoint: PropTypes.string,
  hash: PropTypes.bool,
  path: PropTypes.string,
  permission: PropTypes.string,
  search: PropTypes.string,
  type: PropTypes.string,
};

export default PermissionedRoute;
