import React, { useState, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { capitalize } from "lodash";
// Components
import { GenericForm, FormikContext } from "../../../Forms/Form";
import { FormInput, FormSelect } from "../../../Forms/FormInputs";
import { ToastError } from "../../../Utilities/Components";
// Data
import StateLists from "../../../Utilities/Data/StatesList";
import ProvinceList from "../../../Utilities/Data/ProvinceList";
// Configs
import { API } from "../../../Config/cfg.js";

const AddAddress = ({ hash, history, errors, touched, endpoint, type }) => {
  /*
   * TODO:
   * Gateway accepts CountryCode not CountryHash for this form
   * change CountryCode to CountryHash and value.Code to value.Hash
   */

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({});
  const isGroupForm = history.location?.pathname.includes("/groups/");

  const setEndpoint = () => {
    if (isGroupForm) {
      return `groups/${hash}/address`;
    } else {
      return `accounts/${hash}/address`;
    }
  };

  useEffect(() => {
    API.get("/countries")
      .then((res) => {
        setCountries(res.data.Data);
      })
      .catch((error) => {
        ToastError(error.response.data.Message);
      });
  }, []);

  return (
    <GenericForm
      endpoint={setEndpoint()}
      postReq={isGroupForm}
      setValues={{
        Street: "",
        Street2: "",
        City: "",
        ZIP: "",
        StateCode: "",
        CountryHash: "",
        Preferred: false,
      }}
      customValidations={(values) => {
        let errors = {};
        if (!values.Street) {
          errors.Street = "is required";
        }
        if (!values.City) {
          errors.City = "is required";
        }
        if (!values.ZIP) {
          errors.ZIP = "is required";
        }
        if (!values.StateCode) {
          errors.StateCode = "is required";
        }
        if (!values.CountryHash) {
          errors.CountryHash = "is required";
        }
        return errors;
      }}
      formTitle={null}
      subTitle="Add New Address"
      goBack={{
        show: history ? true : false,
        setHistory: history ? history : null,
        setDefaultPath: history ? `${type}` : null,
        setDefaultName: history ? capitalize(type) : null,
      }}
      toastMsg="Address has been successfully added"
    >
      <FormikContext.Consumer>
        {({ setFieldValue }) => {
          return (
            <div className="margin-lg">
              <div className="columns">
                <div className="column">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    name="Street"
                    label="Street Address"
                    type="text"
                    placeholder="2125 Chestnut Street"
                  />
                </div>
                <div className="column">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    name="Street2"
                    label="Apt, Ste, Unit"
                    type="text"
                    placeholder="Apt. 1B"
                  />
                </div>
                <div className="column">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    name="City"
                    label="City"
                    type="text"
                    placeholder="City"
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    name="ZIP"
                    label="Postal Code"
                    type="text"
                    placeholder="Postal Code"
                  />
                </div>
                <div className="column">
                  <>
                    <label
                      className={`item-label ${
                        errors?.Country && touched?.Country && "input-error"
                      }`}
                    >
                      Country:{" "}
                      <ErrorMessage
                        name="CountryHash"
                        component="span"
                        className="form-error"
                      />
                    </label>
                    <Field
                      component="select"
                      name="CountryHash"
                      onChange={(e) => {
                        const countryMatch = countries.find(
                          (country) => country.Hash === e.target.value
                        );
                        setFieldValue("CountryHash", countryMatch.Hash);
                        setCountry(countryMatch.Code);
                      }}
                    >
                      <option className="placeholder" disabled value="">
                        Select a Country
                      </option>
                      {countries.map((c) => (
                        <option key={c.Hash} value={c.Hash}>
                          {c.Name}
                        </option>
                      ))}
                    </Field>
                  </>
                </div>
                <div className="column">
                  <FormSelect
                    errors={errors}
                    touched={touched}
                    label="State"
                    name="StateCode"
                    type="text"
                    placeholder="State"
                    options={
                      <>
                        {(country === "CA" ? ProvinceList : StateLists).map(
                          (item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          )
                        )}
                      </>
                    }
                  />
                </div>
              </div>

              <div className="columns">
                {!isGroupForm && (
                  <div className="column is-1 has-text-centered">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      name="Preferred"
                      label="Preferred"
                      type="checkbox"
                    />
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

export default AddAddress;
