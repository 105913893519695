import React from "react";

const EDDSurveyAnswers = ({ SurveyAnswers }) => {
  return (
    <div className="column">
      <h2>Survey Answers</h2>
      <div className="form-outline form-create">
        {SurveyAnswers ? (
          <div className="columns is-multiline">
            {SurveyAnswers.map((a) => (
              <div className="column is-6">
                <div className="columns is-vcentered">
                  <div className="column is-7">
                    <p style={{ color: "#6e7071" }}>{a.Prompt}</p>
                  </div>
                  <div className="column">
                    <p style={{ textAlign: "center" }}>{a.Answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="column">
            <p>No Survey Responses Available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EDDSurveyAnswers;
