import React from 'react';
import { Field, ErrorMessage } from 'formik';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './FormInputs.scss';

const FormSelect = ({
  disabled,
  errors,
  id,
  label,
  name,
  options,
  placeholder,
  testID,
  touched,
  onInput,
}) => {
  const errorClass = {
    'input-error': errors && errors[name] && touched && touched[name],
  };

  return (
    <div className="form-input-component" data-testid={testID}>
      <label
        htmlFor={id || name}
        className={classnames('item-label', errorClass)}
      >
        {label}
        <ErrorMessage name={name} component="span" className="form-error" />
      </label>
      <Field
        className={classnames(errorClass)}
        component="select"
        data-testid={`${testID}-select`}
        disabled={disabled}
        id={id || name}
        name={name}
        onInput={onInput}
      >
        {placeholder && (
          <option className="placeholder" disabled value="">
            {placeholder}
          </option>
        )}
        {options}
      </Field>
    </div>
  );
};

FormSelect.defaultProps = {
  testID: 'xFormSelect',
  disabled: false,
};

FormSelect.propTypes = {
  errors: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onInput: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  testID: PropTypes.string,
  touched: PropTypes.object,
};

export default FormSelect;
