/* eslint no-console: ["error", { allow: [ "error"] }] */

// Modules
import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
// Platform Components
import CredentialWizard from './CredentialWizard';
// Components
import {
  GenericModal,
  GoBack,
  Header,
  LoadingButton,
  ToastError,
  ToastSuccess,
} from '../Utilities/Components';
import { FormInput, FormSelect } from '../Forms/FormInputs';
import Product from './Product';
// PropTypes
import { funcProp } from '../Utilities/PropTypes';
// Configs
import { API } from '../Config/cfg.js';

const CreatePlatform = ({ history }) => {
  const [platforms, setPlatforms] = useState([]);
  const [groups, setGroups] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectableProducts, setSelectableProducts] = useState([]);
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [dataURIs, setDataURIs] = useState([]);
  const [shortcode, setShortcode] = useState('');
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [isShortCodeModalOpen, setIsShortCodeModalOpen] = useState(false);

  useEffect(() => {
    getPlatforms();
    getProducts();
    getGroups();
    getCountries();
  }, []);

  useEffect(() => {
    const filteredProducts = products.filter(p => {
      if (
        country === p.Symbol.substring(p.Symbol.length - 3, p.Symbol.length - 1)
      ) {
        return p;
      }
      return false;
    });

    setSelectableProducts(filteredProducts);
  }, [country, products]);

  const getPlatforms = () => {
    API.get('/platform-types')
      .then(res => {
        setPlatforms(res.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const getProducts = () => {
    API.get('/products')
      .then(res => {
        setProducts(res.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const getGroups = () => {
    API.get('/groups?all=true')
      .then(res => {
        setGroups(res.data.Data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const getCountries = () => {
    API.get('/countries')
      .then(res => {
        setCountries(res.data.Data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const putEndpoints = (req, actions) => {
    API.put(`/platforms`, req)
      .then(res => {
        if (res.data.PlatformType?.Name === 'ATM') {
          setShortcode(res.data.Shortcode);
          setIsShortCodeModalOpen(true);
        } else if (res.data.PlatformType?.Name.includes('iPad')) {
          setDataURIs([
            res.data.DataURIA,
            res.data.DataURIB,
            res.data.DataURIC,
            res.data.DataURID,
          ]);
          setIsWizardOpen(true);
        } else if (res.data.PlatformType?.Name === 'Kiosk') {
          setDataURIs([res.data.DataURIA]);
          setIsWizardOpen(true);
        } else if (history && history.location.key) {
          ToastSuccess(`You successfully created options for this location`);
          history.goBack();
        }
      })
      .catch(error => {
        console.error(error);
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  const buildReq = (values, actions) => {
    let req = {
      PlatformTypeHash: values.platformHash,
      DeviceIdentifier: values.deviceIdentifier,
      ProductHashes: values.productHashes,
      Buy: values.Buy,
      Sell: values.Sell,
      Note: values.note,
      GroupHash: values.groupHash,
    };

    putEndpoints(req, actions);
  };

  const closeShortCodeModal = () => {
    setIsShortCodeModalOpen(false);
    history.goBack();
  };

  return (
    <>
      <GoBack
        history={history}
        defaultPath="/platforms"
        defaultName="Platforms"
      />
      <Header title="Create New Platform" />

      <div className="form-outline form-create">
        <Formik
          initialValues={{
            groupHash: '',
            platformHash: '',
            Buy: true,
            Sell: false,
            deviceIdentifier: undefined,
            note: '',
            productHashes: [],
          }}
          validate={values => {
            let errors = {};
            if (!values.platformHash) {
              errors.platformHash = 'is required';
            }
            if (!values.deviceIdentifier) {
              errors.deviceIdentifier = 'is required';
            }
            if (!values.groupHash) {
              errors.groupHash = 'is required';
            }
            return errors;
          }}
          onSubmit={(values, actions) => {
            buildReq(values, actions);
          }}
          children={({
            errors,
            touched,
            isSubmitting,
            values,
            setFieldValue,
          }) => (
            <>
              <Form>
                <h2 className="form-title">Platform Details</h2>
                <div className="columns is-vcentered">
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Device Identifier"
                      name="deviceIdentifier"
                      type="text"
                      placeholder="Enter Device Identifier"
                    />
                  </div>
                  <div className="column">
                    <FormSelect
                      errors={errors}
                      touched={touched}
                      label="Group"
                      name="groupHash"
                      defaultValue=""
                      options={
                        <>
                          <option value="" disabled>
                            Select Group
                          </option>
                          {groups.map(item => (
                            <option key={item.Hash} value={item.Hash}>
                              {item.Name}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </div>
                  <div className="column">
                    <FormSelect
                      errors={errors}
                      touched={touched}
                      label="Platform Type"
                      name="platformHash"
                      defaultValue=""
                      options={
                        <>
                          <option value="" disabled>
                            Select Platform Type
                          </option>
                          {platforms.map(item => (
                            <option key={item.Hash} value={item.Hash}>
                              {item.Name}{' '}
                              {item.Model ? `- ${item.Model}` : null}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </div>
                </div>
                <h2 className="form-title">Purchasing Options</h2>
                <div className="columns has-text-centered">
                  <div className="column is-1">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Buy"
                      name="Buy"
                      type="checkbox"
                      disabled
                    />
                  </div>
                  <div className="column is-1">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Sell"
                      name="Sell"
                      type="checkbox"
                    />
                  </div>
                </div>
                <h2 className="form-title">Platform Products</h2>
                <div className="columns">
                  <div className="column is-6">
                    <label className="item-label">Country</label>
                    <select
                      onChange={e => {
                        setFieldValue('productHashes', []);
                        setCountry(e.target.value);
                      }}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countries.map(item => (
                        <option key={item.Hash} value={item.Code}>
                          {item.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="columns">
                  {selectableProducts.map(p => (
                    <Product
                      key={p.Hash}
                      Symbol={p.Symbol}
                      Hash={p.Hash}
                      data={p}
                    />
                  ))}
                </div>
                <h2 className="form-title">Notes</h2>
                <div className="column is-6 is-paddingless">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    name="note"
                    type="text"
                    label="Platform Notes"
                    component="textarea"
                    placeholder="Enter Any Notes (optional)"
                  />
                </div>
                <LoadingButton loading={isSubmitting} />
              </Form>
            </>
          )}
        />
      </div>
      <CredentialWizard
        dataURIs={dataURIs}
        open={isWizardOpen}
        closeModal={() => setIsWizardOpen(true)}
        history={history}
      />
      <GenericModal
        modalIsOpen={isShortCodeModalOpen}
        importantData={[{ label: 'Short Code', value: shortcode }]}
        closeModal={e => {
          e.preventDefault();
          closeShortCodeModal();
        }}
        headerMsg="Enter ATM Short Code"
        viewMsg="Please use the following code to set up your ATM."
        btnText="Close"
        btnAction={() => closeShortCodeModal()}
        hideClose={true}
      />
    </>
  );
};

CreatePlatform.propTypes = {
  event: funcProp,
};

export default CreatePlatform;
