// Modules
import React from "react";
// Components
import { EditableForm } from "../../Forms/EditableForm";
import {
  EditableInput,
  EditableSelect,
  EditableCheckbox,
} from "../../Forms/EditableInputs";
import { OrButton } from "./../../Utilities/Components";
// Helpers - Formatters
import { formatDate } from "../../Utilities/Formatters";
import { objProp, reqBoolProp, reqFuncProp } from "../../Utilities/PropTypes";
// Permissions
import { ACCOUNT_EDIT } from "../../Permissions/Permissions";

const IDs = [
  { Name: "Driver's License", Hash: 0 },
  { Name: "State/Provincial ID", Hash: 1 },
  { Name: "Passport", Hash: 2 },
];

const UpdateID = ({ data, editing, toggleEdit, postAccount }) => {
  const callPostAccount = (req) => {
    postAccount("compliance/ids", data.Hash, null, req, "editingIDs");
  };

  //sets the correct ID Type for form initiation
  const setIDType = (data) => {
    const type = IDs.filter((id) => id.Name === data)[0];
    return type && type.Hash;
  };

  const {
    Issue,
    Expiry,
    DocumentSerial,
    BarcodeData,
    Type,
    FirstName,
    LastName,
    DOB,
    Hash,
    Lifetime,
  } = data;

  return (
    <EditableForm
      callBack={callPostAccount}
      setValues={{
        FirstName,
        LastName,
        DOB: formatDate(DOB, "YYYY-MM-DD", true),
        Issue: formatDate(Issue, "YYYY-MM-DD", true),
        Expiry: formatDate(Expiry, "YYYY-MM-DD", true),
        DocumentSerial,
        BarcodeData,
        Type: setIDType(Type),
        Lifetime,
      }}
      customValidations={(values) => {
        let errors = {};
        return errors;
      }}
      editing={editing}
      permission={ACCOUNT_EDIT}
      triggerEdit={toggleEdit}
      editType="editingIDs"
      sectionTitle="ID Details"
    >
      <div className="columns">
        <div className="column">
          <EditableInput
            name="FirstName"
            label="First Name"
            type="text"
            currentValue={FirstName}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="LastName"
            label="Last Name"
            type="text"
            currentValue={LastName}
            editing={editing}
            disabled
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="BarcodeData"
            label="Barcode Data"
            type="text"
            currentValue={BarcodeData ? "Scanned & Collected" : ""}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="DOB"
            label="Date of Birth"
            type="date"
            currentValue={formatDate(DOB, undefined, true, "")}
            editing={editing}
            disabled
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableSelect
            name="Type"
            label="ID Type"
            placeholder="Select ID Type"
            currentValue={Type}
            data={IDs}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="DocumentSerial"
            label="Document Serial"
            type="text"
            currentValue={DocumentSerial}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="Issue"
            label="Issue Date"
            type="date"
            currentValue={formatDate(Issue, undefined, true)}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="Expiry"
            label="Expiry Date"
            type="date"
            currentValue={formatDate(Expiry, undefined, true)}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-6">
          <EditableCheckbox
            name="Lifetime"
            label="Lifetime"
            type="text"
            currentValue={Lifetime}
            editing={editing}
          />
        </div>
      </div>
      {editing && (
        <OrButton
          path={{
            pathname: "/accounts/deny-id",
            hash: Hash,
            state: { account: data },
          }}
          name="Deny ID"
          btnColor="red"
        />
      )}
    </EditableForm>
  );
};

UpdateID.propTypes = {
  data: objProp,
  editing: reqBoolProp,
  toggleEdit: reqFuncProp,
  postAccount: reqFuncProp,
};

export default UpdateID;
