import React from "react";
// Components
import { FormInput } from "../Forms/FormInputs";
import { GenericForm, FormikContext } from "../Forms/Form";

const CreateLocationNote = ({ history, hash }) => {
  return (
    <GenericForm
      endpoint={`locations/${hash}/note`}
      setValues={{
        Note: "",
      }}
      formTitle="Create Note"
      subTitle={null}
      goBack={{
        show: true,
        setHistory: history,
        setDefaultPath: `/locations/${hash}`,
        setDefaultName: "Locations",
      }}
      customValidations={(values) => {
        let errors = {};
        if (!values.Note) {
          errors.Note = "is required";
        }
        return errors;
      }}
      toastMsg="Location note has been successfully created"
    >
      <FormikContext.Consumer>
        {(props) => (
          <div className="columns">
            <div className="column is-6">
              <FormInput
                errors={props.errors}
                touched={props.touched}
                label="Message"
                name="Note"
                component="textarea"
                type="text"
                placeholder="Enter Note..."
              />
            </div>
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

export default CreateLocationNote;
