export const BanReasonList = [
  { name: "Illicit Activity", value: 0 },
  { name: "Aggressive Towards Customer Service", value: 1 },
  { name: "Aggressive Towards Agents", value: 2 },
  { name: "Victim Of Fraud", value: 3 },
  { name: "Unknown Account Rep", value: 4 },
  { name: "Compliance Reasons", value: 5 },
  { name: "Enhanced Due Diligence", value: 6 },
  { name: "Identification Issues", value: 7 },
  { name: "Teller Referral", value: 8 },
  { name: "Banned on Import", value: 10 },
  { name: "OFAC Sanctioned Wallet", value: 11 },
  { name: "Other", value: 9 },
];
