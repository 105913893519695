import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// PropTypes
import { strProp, numProp } from '../PropTypes';

const ListGroupItem = ({ name, value, toolTip }) => {
  return (
    <>
      <div
        className="table-row"
        data-tooltip-content={toolTip}
        data-tooltip-id="item"
      >
        <div className="table-cell cell-label">{name}</div>
        {toolTip && <ReactTooltip place="bottom" effect="solid" id="item" />}
        <div className="table-cell cell-label-right">{value}</div>
      </div>
    </>
  );
};

ListGroupItem.defaultProps = {
  value: '-',
};

ListGroupItem.propTypes = {
  value: PropTypes.oneOfType([strProp, numProp, PropTypes.node]),
  name: strProp,
};

export default ListGroupItem;
