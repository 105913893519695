// Modules
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import LoadingButton from './LoadingButton';
import PropTypes from 'prop-types';

// modal css
const customStyles = {
  content: {
    width: window.innerWidth > 750 ? '600px' : '85vw',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#f9f6f2',
    borderRadius: '.5em',
  },
  header: {
    margin: '-20px -20px 0 -20px',
    color: '#fff',
    padding: '10px 20px 10px 20px',
    background: '#272a39',
  },
  msgContainer: {
    padding: '25px',
    fontStyle: 'italic',
    marginBottom: '10px',
  },
  emptyContainer: {
    margin: '25px',
  },
  importantData: {
    margin: '10px',
  },
  label: {
    color: '#486667',
  },
  code: {
    fontWeight: 'bold',
  },
  confirm: {
    display: 'inline-block',
    color: '#fff',
    background: '#009245',
    fontSize: '15px',
    padding: '15px',
    margin: '20px 10px 20px 0px',
    borderRadius: '.5em',
    border: 'none',
    ':hover': {
      cursor: 'pointer',
    },
  },
  cancel: {
    background: 'none',
    border: 'none',
    color: '#e71d36',
    cursor: 'pointer',
  },
};

const GenericModal = ({
  btnAction,
  btnMsg,
  btnText,
  closeModal,
  headerMsg,
  modalIsOpen,
  importantData,
  viewMsg,
  isSubmitting,
  children,
  hideClose,
}) => {
  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel="Example Modal"
      data-testid="modal"
      ariaHideApp={false}
    >
      <h1 style={customStyles.header}>{headerMsg}</h1>
      <div
        style={
          !(viewMsg && importantData)
            ? customStyles.emptyContainer
            : customStyles.msgContainer
        }
      >
        <p>{viewMsg}</p>
        {importantData.map(data => {
          return (
            <p key={data.value} style={customStyles.importantData}>
              <span style={customStyles.label}>{data.label}:</span>
              <code style={customStyles.code}>{data.value}</code>
            </p>
          );
        })}
      </div>
      {children}
      <p>{btnMsg}</p>

      <div className="column">
        <LoadingButton
          style={customStyles.confirm}
          loading={isSubmitting}
          btnText={btnText}
          btnAction={btnAction}
        />
        {'   '}
        {!hideClose && (
          <button style={customStyles.cancel} onClick={closeModal}>
            Close
          </button>
        )}
      </div>
    </Modal>
  );
};

GenericModal.defaultProps = {
  closeModal: () => {},
  importantData: [],
  btnText: '',
};

GenericModal.propTypes = {
  btnAction: PropTypes.func.isRequired,
  btnMsg: PropTypes.string,
  btnText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  closeModal: PropTypes.func,
  headerMsg: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  importantData: PropTypes.array,
  viewMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default GenericModal;
