// Modules
import React from "react";
// Components
import { GenericForm, FormikContext } from "../../../Forms/Form";
import { FormInput } from "../../../Forms/FormInputs";
import { reqStrProp } from "../../../Utilities/PropTypes";

const ChangePassword = ({ hash }) => {
  return (
    <GenericForm
      endpoint={`accounts/${hash}`}
      postReq={true}
      setValues={{
        OldPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
      }}
      customValidations={(values) => {
        let errors = {};
        if (!values.OldPassword) {
          errors.OldPassword = "is required";
        }
        if (!values.NewPassword) {
          errors.NewPassword = "is required";
        }
        if (!values.ConfirmPassword) {
          errors.ConfirmPassword = "is required";
        }
        if (values.NewPassword !== values.ConfirmPassword) {
          errors.ConfirmPassword = "does not match";
        }
        return errors;
      }}
      formTitle={null}
      subTitle="Change Password"
      goBack={{
        show: false,
        setHistory: null,
      }}
      toastMsg="Password has been successfully changed"
    >
      <FormikContext.Consumer>
        {(props) => (
          <div className="columns">
            <div className="column">
              <FormInput
                errors={props.errors}
                touched={props.touched}
                label="Existing Password"
                name="OldPassword"
                type="password"
                placeholder="Enter Existing Password"
              />
            </div>
            <div className="column">
              <FormInput
                errors={props.errors}
                touched={props.touched}
                label="New Password"
                name="NewPassword"
                type="password"
                placeholder="Enter New Password"
              />
            </div>
            <div className="column">
              <FormInput
                errors={props.errors}
                touched={props.touched}
                label="Confirm New Password"
                name="ConfirmPassword"
                type="password"
                placeholder="Confirm New Password"
              />
            </div>
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

ChangePassword.propTypes = {
  hash: reqStrProp,
};

export default ChangePassword;
