const formatFeePrice = (data) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  // undefined prices should collapse to 0 if they are to be displayed
  if (data === undefined) {
    data = 0;
  }
  if (data >= 0) {
    if (data >= 0.01) {
      return formatter.format(data);
    } else {
      return "<" + formatter.format(0.01);
    }
  } else {
    return data;
  }
};

export default formatFeePrice;
