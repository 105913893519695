// Modules
import React from "react";
import columnBuilder from "./columnBuilder";
// Components
import Table from "./../Table/Table";
// Permissions
import { PLATFORMS_CREATE, PLATFORMS_VIEW } from "../Permissions/Permissions";

const Platforms = (props) => {
  return (
    <Table
      history={props.history}
      location={props.location}
      columns={columnBuilder}
      endpoint="platforms"
      filter={true}
      tablename="Platforms"
      downloadable={true}
      actions={[
        {
          link: "/platform/create",
          name: "Create New Platform",
          permission: PLATFORMS_CREATE,
        },
        {
          link: "/platforms/screenshots",
          name: "Screenshots",
          permission: PLATFORMS_VIEW,
        },
      ]}
      customize
    />
  );
};

export default Platforms;
