import React, { useEffect, useState } from 'react';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
// Permissions
import { COMPLIANCE_EDDS_ACTION } from '../../Permissions/Permissions';
import Can from '../../Permissions/Can';
// Components
import EDDSurveyAnswers from './Tabs/EDDSurveyAnswers';
import EDDNarrative from './Tabs/EDDNarrative';
import Documents from './Tabs/Documents';
import { ToastSuccess, ToastError } from '../../Utilities/Components';
// Config
import { API } from '../../Config/cfg';
// Ult
import { formatDateTime } from '../../Utilities/Formatters';

const TabsContainer = ({
  data,
  showDecision,
  deleteEDD,
  reviewDecision,
  history,
}) => {
  const {
    Active,
    DecisionDate,
    Narrative,
    SurveyAnswers,
    ReviewAccount,
    Hash,
  } = data;

  const [docHash, setDocHash] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [callAPI, setCallAPI] = useState(false);

  useEffect(() => {
    if (callAPI) {
      setCallAPI(false);
    }
  }, [callAPI]);

  const openModal = rowHash => {
    setModalIsOpen(true);
    setDocHash(rowHash);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setDocHash('');
    setCallAPI(true);
  };

  const deleteDocument = () => {
    API.delete(`/compliance/edds/${Hash}/documents/${docHash}`)
      .then(res => {
        ToastSuccess(`Document has been deleted`);
        setModalIsOpen(false);
        setDocHash('');
        setCallAPI(true);
      })
      .catch(error => {
        ToastError(error);
      });
  };

  return (
    <Tabs className="tabs-container">
      <TabList className="columns tab-list">
        <Tab className="column tab">Narrative</Tab>
        <Tab className="column tab">Survey Responses</Tab>
        <Tab className="column tab">Documents</Tab>
      </TabList>

      <TabPanel>
        {Narrative && !showDecision ? (
          <React.Fragment>
            <div className="space-between">
              <h2>Narrative</h2>
              <div>
                {Active && (
                  <>
                    <button
                      type="button"
                      className="btn primary-btn marginRight"
                      onClick={reviewDecision}
                    >
                      Reopen
                    </button>
                    <button
                      type="button"
                      className="btn red"
                      onClick={deleteEDD}
                    >
                      Close
                    </button>
                  </>
                )}
              </div>
            </div>
            <div id="narrative">
              <p>
                <span className="item-label">Worked by:</span>{' '}
                {ReviewAccount
                  ? `${ReviewAccount.FirstName} ${ReviewAccount.LastName}`
                  : '-'}
              </p>

              <p>
                <span className="item-label">Updated At:</span>{' '}
                {formatDateTime(DecisionDate)}
              </p>
              <p className="narrative">{Narrative}</p>
            </div>
          </React.Fragment>
        ) : (
          <Can do={COMPLIANCE_EDDS_ACTION}>
            <EDDNarrative data={data} history={history} />
          </Can>
        )}
      </TabPanel>
      <TabPanel>
        <EDDSurveyAnswers SurveyAnswers={SurveyAnswers} />
      </TabPanel>
      <TabPanel>
        <Documents
          hash={Hash}
          deleteDocument={deleteDocument}
          callAPI={callAPI}
          openModal={openModal}
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
        />
      </TabPanel>
    </Tabs>
  );
};

export default TabsContainer;
