// Modules
import React from "react";
import { Link } from "react-router-dom";
// Components"
import Table from "../../Table/Table";
import {
  formatAccountStatus,
  StatusPill,
} from "../../Utilities/Components/TablePills";
// Helpers - Formatters
import { formatDateTime, formatPrice } from "../../Utilities/Formatters";

const GroupMembers = ({ hash }) => {
  return (
    <Table
      columns={columnBuilder()}
      endpoint={`groups/${hash}/members`}
      tablename="Members"
      nested={true}
    />
  );
};

const columnBuilder = () => [
  {
    Header: "Status",
    accessor: "ApprovalTier",
    Cell: ({ row: { original } }) =>
      StatusPill(original.Status, formatAccountStatus(original.Status)),
    sortMethod: "Status",
  },
  {
    Header: "Full Name",
    accessor: "FullName",
    style: { textAlign: "left" },
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/accounts/${original.Hash}`}>
        {original.FullName}
      </Link>
    ),
    minWidth: 200,
    sortMethod: "LastName",
  },
  {
    Header: "Email",
    accessor: "Email",
    minWidth: 200,
    Cell: ({ row: { original } }) => original.Email || "-",
  },
  {
    Header: "Phone",
    accessor: "PreferredPhoneNumber.Phone",
    minWidth: 100,
    sortMethod: "Phone",
  },
  {
    Header: "Total Cash In",
    accessor: "TotalCashIn",
    Cell: ({ row: { original } }) => formatPrice(original.TotalCashIn),
    sortMethod: "TotalCashIn",
  },
  {
    Header: "City",
    accessor: "Addresses[0].City",
    Cell: ({ row: { original } }) => {
      return original["Addresses[0].City"]
        ? original["Addresses[0].City"]
        : "-";
    },
    sortMethod: "City",
  },
  {
    Header: "State",
    accessor: "Addresses[0].StateCode",
    Cell: ({ row: { original } }) => {
      return original["Addresses[0].StateCode"]
        ? original["Addresses[0].StateCode"]
        : "-";
    },
    sortMethod: "State",
  },
  {
    Header: "Last Seen Date",
    accessor: "MostRecentActivity",
    Cell: ({ row: { original } }) =>
      formatDateTime(original.MostRecentActivity),
    width: 100,
    sortMethod: "LastSeenDate",
  },
];

export default GroupMembers;
