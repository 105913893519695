import React, { useRef } from 'react';
import GoogleMap from 'google-maps-react-markers';
import DigitalMintLogo from './../images/logo_white.svg';
import PropTypes from 'prop-types';
import './GeoLocation.scss';
import { mapStyles } from '../Styles/MapStyles';

export const Marker = ({ City, StateCode, Street, ZIP }) => {
  // lat & lng props required for the marker code
  // to apply to children, isRequired, but not
  // used here
  return (
    <div className="location-data">
      <img
        alt="DigitalMint Location"
        className="marker"
        height="30"
        src={DigitalMintLogo}
        width="30"
      />
      {Street}
      <br />
      {City}, {ZIP}, {StateCode}
    </div>
  );
};

Marker.propTypes = {
  City: PropTypes.string.isRequired,
  StateCode: PropTypes.string.isRequired,
  Street: PropTypes.string.isRequired,
  ZIP: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

const GeoLocation = ({ address }) => {
  const mapRef = useRef();

  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map;
  };

  return (
    <GoogleMap
      apiKey={process.env.REACT_APP_GOOGLE_KEY}
      defaultCenter={{
        lat: address.Latitude,
        lng: address.Longitude,
      }}
      defaultZoom={14}
      options={{ ...mapStyles }}
      onGoogleApiLoaded={onGoogleApiLoaded}
    >
      <Marker
        City={address.City}
        StateCode={address.StateCode}
        Street={address.Street}
        ZIP={address.ZIP}
        lat={address.Latitude}
        lng={address.Longitude}
      />
    </GoogleMap>
  );
};

GeoLocation.propTypes = {
  address: PropTypes.shape({
    Active: PropTypes.bool,
    City: PropTypes.string.isRequired,
    Hash: PropTypes.string,
    Latitude: PropTypes.number.isRequired,
    Longitude: PropTypes.number.isRequired,
    Preferred: PropTypes.bool,
    StateCode: PropTypes.string.isRequired,
    Street: PropTypes.string.isRequired,
    ZIP: PropTypes.string.isRequired,
  }).isRequired,
};

export default GeoLocation;
