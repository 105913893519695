import React, { useState, useEffect, useRef } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

const Dropdown = ({ title, children, className, tip, small }) => {
  const dropdownRef = useRef(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    window.addEventListener('click.onCloseDropdown', onCloseDropdown);

    return () => {
      window.removeEventListener('click.onCloseDropdown', onCloseDropdown);
    };
  }, []);

  const onCloseDropdown = () => {
    setActive(false);
  };

  return (
    <div
      data-tooltip-content={tip.message}
      data-tooltip-id={tip.id}
      className="column is-narrow dropdown-container is-paddingless"
    >
      <button
        className={`btn primary-btn-outline ${className || ''}`}
        onClick={event => {
          event.stopPropagation();

          setActive(!active);
        }}
        data-for={tip.id}
        data-testid="toggle"
      >
        {title}
      </button>
      {active && (
        <div
          ref={dropdownRef}
          data-testid="content"
          className={`dropdown-content ${active ? 'active' : 'inactive'} ${
            small ? 'small' : ''
          }`}
        >
          {children}
        </div>
      )}
      <ReactTooltip id={tip.id} place={tip.place} effect="solid" />
    </div>
  );
};

Dropdown.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  tip: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    place: PropTypes.string,
  }).isRequired,
};

export default Dropdown;
