/* eslint no-console: ["error", { allow: [ "error"] }] */

import React, { useState, useEffect } from 'react';
import { EditBtn } from '../../Utilities/Components';
import AddressForm from './AddressForm';
import { ToastWarning } from '../../Utilities/Components';
import { API } from '../../Config/cfg.js';
import { isEmpty } from 'lodash';
// Icons
import { GROUPS_EDIT } from '../../Permissions/Permissions';

const Addresses = ({
  permission,
  address,
  toggleEdit,
  editing,
  handleAddress,
  handlePreferredAddress,
  parentHash,
  showCountries,
}) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (showCountries) {
      API.get('/countries')
        .then(res => {
          setCountries(res.data.Data);
        })
        .catch(error => {
          console.error(error);
          // ToastError(error.response.data.Message);
        });
    }
  }, [showCountries]);

  const onSubmit = (data, actions, addressHash) => {
    let req = {
      City: data.City,
      Latitude: data.Latitude,
      Longitude: data.Longitude,
      StateCode: data.StateCode,
      Street: data.Street,
      Street2: data.Street2,
      ZIP: data.ZIP,
      CountryHash: data.CountryHash,
    };

    handleAddress(
      'addresses',
      addressHash,
      null,
      req,
      'editingAddress',
      actions
    );
  };

  return (
    <React.Fragment>
      <EditBtn
        editing={editing}
        triggerEdit={e => {
          // needed or else you will go back to home
          e.preventDefault();
          if (isEmpty(address)) {
            ToastWarning('Please add a new address first');
          } else {
            toggleEdit('editingAddress');
          }
        }}
        sectionTitle="Address"
        permission={GROUPS_EDIT}
        link={
          isEmpty(address)
            ? {
                pathname: '/groups/create-address',
                hash: parentHash,
                state: {
                  type: 'groups',
                },
              }
            : false
        }
      />

      {address ? (
        <AddressForm
          onSubmit={onSubmit}
          address={address}
          editing={editing}
          showCountries={showCountries}
          countries={countries}
        />
      ) : (
        <AddressForm
          nullAddress={true}
          onSubmit={onSubmit}
          address={{}}
          editing={editing}
          showCountries={showCountries}
          countries={countries}
        />
      )}
    </React.Fragment>
  );
};

export default Addresses;
