import Data from './Data';
import OperatingCountryData from './OperatingCountry';
import ComplianceOperatorData from './ComplianceOperator';
import AddressData from './Address';
import DefaultWalletData from './DefaultWallet';
import PhoneNumberData from './PhoneNumber';

export default class AccountData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      AnnualIncome: { type: 'number' },
      ApprovalTier: { type: 'number' },
      BenefitOfBTC: { type: 'string' },
      CreatedAt: { type: 'string' },
      DigitalmintTradingAccountID: { type: 'number' },
      DocumentSerial: { type: 'string' },
      Email: { type: 'string' },
      Employer: { type: 'string' },
      FPPFunding: { type: 'string' },
      FPPJob: { type: 'string' },
      FPPRemittance: { type: 'string' },
      FirstName: { type: 'string' },
      FullName: { type: 'string' },
      HasPassword: { type: 'boolean' },
      JobTitle: { type: 'string' },
      LastName: { type: 'string' },
      Limits: { type: 'object' },
      MonthlyExpectedVolume: { type: 'number' },
      MostRecentActivity: { type: 'string' },
      OTPEnabled: { type: 'boolean' },
      Occupation: { type: 'string' },
      OperatingCountryID: { type: 'number' },
      ReasonForExpectedVolume: { type: 'string' },
      ServiceAccount: { type: 'boolean' },
      SourceOfFunds: { type: 'string' },
      Status: { type: 'number' },
      TotalCashIn: { type: 'number' },
      TotalCashOut: { type: 'number' },
      UseOfFunds: { type: 'string' },
    };
  }

  hasOne() {
    return {
      ComplianceOperator: ComplianceOperatorData,
      OperatingCountry: OperatingCountryData,
      PreferredPhoneNumber: PhoneNumberData,
    };
  }

  hasMany() {
    return {
      Addresses: AddressData,
      DefaultWallets: DefaultWalletData,
      PhoneNumbers: PhoneNumberData,
    };
  }
}
