const locationKeys = {
  Active: {
    Label: "Active",
    Type: "bool",
    Options: [
      {
        Display: "True",
        Value: true,
      },
      {
        Display: "False",
        Value: false,
      },
    ],
  },
  Agent: {
    Label: "Agent",
    Type: "bool",
    Options: [
      {
        Display: "True",
        Value: true,
      },
      {
        Display: "False",
        Value: false,
      },
    ],
  },
  BusinessName: {
    Label: "Business Name",
    Type: "string",
  },
  "CashCourier.Name": {
    Label: "Cash Courier",
    Type: "string",
  },
  "Address.City": {
    Label: "City",
    Type: "string",
  },
  Code: {
    Label: "Code",
    Type: "string",
  },
  // Do not operate in other countires (yet)
  // "Address.CountryCode": {
  //   Label: "Country",
  //   Type: "string",
  //   Options: [
  //     {
  //       Display: "US",
  //       Value: "US",
  //     },
  //   ],
  // },
  "CreditAccount.Name": {
    Label: "Credit Account",
    Type: "string",
  },
  CrossStreet: {
    Label: "Cross Street",
    Type: "string",
  },
  "DebitAccount.Name": {
    Label: "Debit Account",
    Type: "string",
  },
  Decommissioned: {
    Label: "Decommissioned",
    Type: "bool",
    Options: [
      {
        Display: "True",
        Value: true,
      },
      {
        Display: "False",
        Value: false,
      },
    ],
  },
  "Group.Name": {
    Label: "Group Name",
    Type: "string",
  },
  InstallationDate: {
    Label: "Installation Date",
    Type: "daterange",
  },
  UpdatedAt: {
    Label: "Last Update Date",
    Type: "daterange",
  },
  LegalEntity: {
    Label: "Legal Entity",
    Type: "string",
  },
  Neighborhood: {
    Label: "Neighborhood",
    Type: "string",
  },
  PhoneNumber: {
    Label: "Phone Number",
    Type: "string",
  },
  "Address.StateCode": {
    Label: "State Code",
    Type: "string",
    Options: [
      {
        Display: "AL",
        Value: "AL",
      },
      {
        Display: "AK",
        Value: "AK",
      },
      {
        Display: "AZ",
        Value: "AZ",
      },
      {
        Display: "AR",
        Value: "AR",
      },
      {
        Display: "CA",
        Value: "CA",
      },
      {
        Display: "CO",
        Value: "CO",
      },
      {
        Display: "CT",
        Value: "CT",
      },
      {
        Display: "DE",
        Value: "DE",
      },
      {
        Display: "DC",
        Value: "DC",
      },
      {
        Display: "FL",
        Value: "FL",
      },
      {
        Display: "GA",
        Value: "GA",
      },
      {
        Display: "HI",
        Value: "HI",
      },
      {
        Display: "ID",
        Value: "ID",
      },
      {
        Display: "IL",
        Value: "IL",
      },
      {
        Display: "IN",
        Value: "IN",
      },
      {
        Display: "IA",
        Value: "IA",
      },
      {
        Display: "KS",
        Value: "KS",
      },
      {
        Display: "KY",
        Value: "KY",
      },
      {
        Display: "LA",
        Value: "LA",
      },
      {
        Display: "ME",
        Value: "ME",
      },
      {
        Display: "MH",
        Value: "NH",
      },
      {
        Display: "MD",
        Value: "MD",
      },
      {
        Display: "MA",
        Value: "MA",
      },
      {
        Display: "MI",
        Value: "MI",
      },
      {
        Display: "MN",
        Value: "MN",
      },
      {
        Display: "MS",
        Value: "MS",
      },
      {
        Display: "MO",
        Value: "MO",
      },
      {
        Display: "MT",
        Value: "MT",
      },
      {
        Display: "NE",
        Value: "NE",
      },
      {
        Display: "NV",
        Value: "NV",
      },
      {
        Display: "NH",
        Value: "NH",
      },
      {
        Display: "NJ",
        Value: "NJ",
      },
      {
        Display: "NM",
        Value: "NM",
      },
      {
        Display: "NY",
        Value: "NY",
      },
      {
        Display: "NC",
        Value: "NC",
      },
      {
        Display: "ND",
        Value: "ND",
      },
      {
        Display: "OH",
        Value: "OH",
      },
      {
        Display: "OK",
        Value: "OK",
      },
      {
        Display: "OR",
        Value: "OR",
      },
      {
        Display: "PA",
        Value: "PA",
      },
      {
        Display: "PR",
        Value: "PR",
      },
      {
        Display: "RI",
        Value: "RI",
      },
      {
        Display: "SC",
        Value: "SC",
      },
      {
        Display: "SD",
        Value: "SD",
      },
      {
        Display: "TN",
        Value: "TN",
      },
      {
        Display: "TX",
        Value: "TX",
      },
      {
        Display: "UT",
        Value: "UT",
      },
      {
        Display: "VT",
        Value: "VT",
      },
      {
        Display: "VI",
        Value: "VI",
      },
      {
        Display: "VA",
        Value: "VA",
      },
      {
        Display: "WA",
        Value: "WA",
      },
      {
        Display: "WV",
        Value: "WV",
      },
      {
        Display: "WI",
        Value: "WI",
      },
      {
        Display: "WY",
        Value: "WY",
      },
    ],
  },
  "Address.Street": {
    Label: "Street Address",
    Type: "string",
  },
  "Address.ZIP": {
    Label: "Postal Code",
    Type: "string",
  },
};
export default locationKeys;
