import Data from './Data';

export default class CommissionEmailsData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      Active: { type: 'boolean' },
      Email: { type: 'string' },
    };
  }
}
