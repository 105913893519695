import React from 'react';
import Login from './Login';
import AltLogo from '../images/dm.svg';
import PropTypes from 'prop-types';

// TODO:
// Commenting out the Tabs and all related code
// until we reopen Sign Up functionality
const AuthTabs = ({ history, location, props, match }) => {
  // const [disable, setDisable] = useState(false);

  return (
    <>
      <img className="logo" src={AltLogo} alt="logo" />
      <Login login={props.login} OTPEnabled={props.OTPEnabled} />
      {/* <Tabs defaultIndex={isInvitation ? 1 : 0} className="tabs-container">
          <TabList className="columns tab-list">
            <Tab className="column tab">Login</Tab>
            <Tab className="column tab">Sign Up</Tab>
          </TabList>
          <TabPanel>
            <Login login={login} />
          </TabPanel>
          <TabPanel>
            <SignUp
              putAccount={props.putAccount}
              history={history}
              disable={disable}
              invitation={invitation}
            />
          </TabPanel>
        </Tabs> */}
    </>
  );
};

AuthTabs.defaultProps = {
  props: {},
};

AuthTabs.propTypes = {
  props: PropTypes.shape({
    OTPEnabled: PropTypes.bool,
    login: PropTypes.func.isRequired,
    putAccount: PropTypes.func,
  }).isRequired,
};

export default AuthTabs;
