// Modules
import React from "react";
// Components
import Table from "../../Table/Table";
// Helpers - Formatters
import { formatDateTime } from "../../Utilities/Formatters";
import { reqStrProp } from "./../../Utilities/PropTypes";

const EventEndpoints = ({ hash }) => {
  return (
    <Table
      columns={columnBuilder}
      endpoint={`platforms/${hash}/events`}
      nested={true}
      tablename="Events"
    />
  );
};

EventEndpoints.propTypes = {
  hash: reqStrProp,
};

export default EventEndpoints;

const columnBuilder = [
  {
    Header: "Event",
    accessor: "Text",
  },
  {
    Header: "Time",
    accessor: "Timestamp",
    Cell: ({ row: { original } }) => formatDateTime(original.Timestamp),
  },
  {
    Header: "Note",
    accessor: "Note",
    Cell: ({ row: { original } }) => (original.Note ? original.Note : "-"),
  },
];
