import Data from './Data';

export default class ProductData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      Description: { type: 'string' },
      Live: { type: 'boolean', defaultValue: false },
      Markup: { type: 'string' },
      Symbol: { type: 'string' },
    };
  }
}
