import React from "react";
import { Link } from "react-router-dom";
// Components
import Table from "../../Table/Table";
import Icon from "../../Icons/Icon";
import { ICONS } from "../../Icons/IconsPath";
import { reqObjProp } from "./../../Utilities/PropTypes";
import { TradeTypePill } from "./../../Utilities/Components/TablePills";
// Permissions
import { PLATFORMS_EDIT } from "../../Permissions/Permissions";

const ProductEndpoints = props => {
  const {
    data: { Hash }
  } = props;
  return (
    <React.Fragment>
      <Table
        columns={columnBuilder(Hash)}
        endpoint={`platforms/${Hash}/products`}
        nested={true}
        tablename="Products"
        actions={[
          {
            link: "/products/create",
            name: "Add New Product",
            params: Hash,
            permission: PLATFORMS_EDIT
          }
        ]}
      />
    </React.Fragment>
  );
};

ProductEndpoints.propTypes = {
  data: reqObjProp
};

export default ProductEndpoints;

const columnBuilder = platformHash => [
  {
    Header: "Product",
    accessor: "Product.Symbol",
    Cell: ({ row: { original } }) => (
      <Link
        className="link"
        to={{
          pathname: `/platform/${platformHash}/products`,
          state: { productHash: original.Hash }
        }}
      >
        {TradeTypePill(original.Symbol)}
      </Link>
    )
  },
  {
    Header: "Description",
    accessor: "Description"
  },
  {
    Header: "Buy",
    accessor: "Buy",
    Cell: ({ row: { original } }) =>
      original.Buy ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
    maxWidth: 85
  },
  {
    Header: "Sell",
    accessor: "Sell",
    Cell: ({ row: { original } }) =>
      original.Sell ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
    maxWidth: 85
  }
];
