//Modules
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// Group Components
import SelectGroup from "../Groups/SelectGroup";
// Components
import { GoBack, Header } from "../Utilities/Components";
import AddressForm from "./Forms/Address";
import LocationDetails from "./Forms/LocationDetails";

class CreateLocation extends Component {
  state = {
    stepCount: 1,
    addressHash: undefined,
    groupHash: undefined,
    locationHash: undefined,
  };

  handleSubmit = (hash, type) => {
    let setHash;
    switch (type) {
      case "group":
        setHash = "groupHash";
        break;
      case "address":
        setHash = "addressHash";
        break;
      default:
        setHash = "locationHash";
        break;
    }
    this.setState({
      stepCount: this.state.stepCount + 1,
      [setHash]: hash,
    });
  };

  render() {
    const { stepCount, addressHash, groupHash, locationHash } = this.state;
    const stepTitle = stepCount < 4 && `- Step ${stepCount} of 3`;

    return (
      <div id="formContainer">
        <GoBack
          history={this.props.history}
          defaultPath="/locations"
          defaultName="Locations"
        />
        <div className="columns is-vcentered">
          <div className="column ">
            <Header title={`Create a New Location ${stepTitle}`} />
          </div>
        </div>
        <div className="form-outline form-create">
          {stepCount > 0 && stepCount < 2 && (
            <SelectGroup event={this.handleSubmit} form="Location-Form" />
          )}
          {stepCount > 1 && stepCount < 3 && (
            <AddressForm event={this.handleSubmit} />
          )}
          {stepCount > 2 && stepCount < 4 && (
            <LocationDetails
              addressHash={addressHash}
              groupHash={groupHash}
              event={this.handleSubmit}
            />
          )}
          {stepCount > 3 && <Redirect to={`/locations/${locationHash}`} />}
        </div>
      </div>
    );
  }
}

export default CreateLocation;
