// Modules
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import { CopyButton } from '../Utilities/Components';
import {
  TradeTypePill,
  TxState,
  PlatformPill,
} from '../Utilities/Components/TablePills';
// Helpers - Formatters
import {
  formatPrice,
  formatDateTime,
  formatPercent,
} from '../Utilities/Formatters';
// Permissions
import { checkPermission } from '../Permissions/CheckPermission';
import { ACCOUNT_VIEW } from '../Permissions/Permissions';

const columnBuilder = (userPermissions, endpoint) => {
  let columns = [
    {
      Header: 'Invoice # & Platform',
      accessor: 'Hash',
      minWidth: 155,
      Cell: ({ row: { original } }) => (
        <>
          <Link className="table-pill" to={`/transactions/${original.Hash}`}>
            {original.InvoiceCode}
          </Link>
          <br />
          {original.Platform
            ? PlatformPill(
                `${original.Platform.PlatformType.Name} ${original.Platform.PlatformType.Model}`
              )
            : '-'}
        </>
      ),
      sortMethod: 'InvoiceCode',
      default: true,
    },
    {
      Header: 'Group',
      accessor: 'Group',
      Cell: ({ row }) => {
        const { original } = row;
        if (endpoint === '/groups') {
          return original.Group.Name;
        }
        return (
          <Link className="table-pill" to={`/groups/${original.Group.Hash}`}>
            {original.Group.Name}
          </Link>
        );
      },
      width: 100,
      sortMethod: 'Group',
      default: true,
    },
    {
      Header: 'Date',
      accessor: 'CreatedAt',
      Cell: ({ row: { original } }) => formatDateTime(original.CreatedAt),
      width: 100,
      sortMethod: 'Date',
      default: true,
    },
    {
      Header: 'Customer',
      accessor: 'Account.Hash',
      style: { textAlign: 'left' },
      minWidth: 140,
      Cell: ({ row: { original } }) => (
        <Link className="table-pill" to={`/accounts/${original.Account.Hash}`}>
          {original.Account.FullName}
        </Link>
      ),
      sortMethod: 'Customer',
      show: checkPermission(userPermissions, ACCOUNT_VIEW),
      default: true,
    },
    {
      Header: 'Status',
      accessor: 'StateDesc',
      minWidth: 200,
      Cell: ({ row: { original } }) =>
        TxState(original.State, original.StateDesc),
      sortMethod: 'Status',
      default: true,
    },
    {
      Header: 'Location',
      accessor: 'Address.StateCode',
      minWidth: 210,
      Cell: ({ row: { original } }) => (
        <>
          {original.Location ? (
            <Link
              className="table-pill"
              to={`/locations/${original.Location.Hash}`}
            >
              {original.Location.Address.StateCode},{' '}
              {original.Location.Address.City},{' '}
              {original.Location.Address.Street}
            </Link>
          ) : (
            '-'
          )}
        </>
      ),
      sortMethod: 'CityState',
      default: true,
    },
    {
      Header: 'Coin Amount',
      accessor: 'Product.Symbol',
      Cell: ({ row: { original } }) => {
        return (
          <>
            <p>{original.BaseQuantity}</p>
            <p>{TradeTypePill(original.Product.Symbol)}</p>
          </>
        );
      },
      sortMethod: 'CoinAmount',
      default: true,
    },
    {
      Header: 'Fiat Amount',
      accessor: 'Collected',
      Cell: ({ row: { original } }) => formatPrice(original.Collected),
      sortMethod: 'FiatAmount',
      default: true,
    },
    {
      Header: 'Quote Price',
      accessor: 'QuotePrice',
      Cell: ({ row: { original } }) => formatPrice(original.QuotePrice),
    },
    {
      Header: 'Exchange Price',
      accessor: 'ExchangePrice',
      Cell: ({ row: { original } }) => formatPrice(original.ExchangePrice),
    },
    {
      Header: 'Markup',
      accessor: 'Markup',
      Cell: ({ row: { original } }) =>
        original.Markup ? formatPercent(original.Markup) : '-',
    },
    {
      Header: 'Withdrawal Address',
      accessor: 'WithdrawalAddress',
      style: { textAlign: 'left' },
      Cell: ({ row: { original } }) => (
        <>
          <CopyButton value={original.WithdrawalAddress} />
          <strong>{original.WithdrawalAddress}</strong>
        </>
      ),
      minWidth: 300,
    },
    {
      Header: 'Mining Fee',
      accessor: 'MiningFee',
      Cell: ({ row: { original } }) => original.MiningFee || '-',
    },
    {
      Header: 'Gross Commission',
      accessor: 'GrossCommission',
      Cell: ({ row: { original } }) =>
        original.GrossCommission
          ? formatPercent(original.GrossCommission)
          : '-',
    },
    {
      Header: 'Settlement Date',
      accessor: 'SettlementDate',
      Cell: ({ row: { original } }) => formatDateTime(original.SettlementDate),
      width: 100,
    },
    {
      Header: 'Chainalysis ID',
      accessor: 'ChainalysisReferenceID',
      Cell: ({ row: { original } }) =>
        original.ChainalysisReferenceID ? original.ChainalysisReferenceID : '-',
    },
    {
      Header: 'Platform Slug',
      accessor: 'PlatformSlug',
      Cell: ({ row: { original } }) =>
        original.PlatformSlug ? original.PlatformSlug : '-',
    },
    {
      Header: 'Payment Method',
      accessor: 'PaymentMethod',
      Cell: ({ row: { original } }) =>
        original.PaymentMethod ? original.PaymentMethod : '-',
    },
  ];
  return columns;
};

export default columnBuilder;
