import React from 'react';
import { ToastContainer } from 'react-toastify';
import Auth from './Authentication/Auth';
import './App.scss';

const App = () => {
  return (
    <div id="siteContainer">
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com/"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Lato|Open+Sans"
        rel="stylesheet"
      />
      <Auth />
      <ToastContainer newestOnTop autoClose={4000} hideProgressBar={true} />
    </div>
  );
};
export default App;
