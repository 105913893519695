import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { EditableForm } from '../../Forms/EditableForm';
import { EditableInput, EditableSelect } from '../../Forms/EditableInputs';
import { ToastError } from '../../Utilities/Components';
import { formatPrice } from '../../Utilities/Formatters';
import { ACCOUNT_EDIT } from '../../Permissions/Permissions';
import { API } from '../../Config/cfg.js';
import PropTypes from 'prop-types';

const Source = [
  { Name: 'Income', Hash: 'Income' },
  { Name: 'Inheritance', Hash: 'Inheritance' },
  { Name: 'Prior Investments', Hash: 'Prior Investments' },
  { Name: 'Savings', Hash: 'Savings' },
  { Name: 'Loan', Hash: 'Loan' },
  { Name: 'Insurance', Hash: 'Insurance' },
  { Name: 'Other', Hash: 'Other' },
];

const Use = [
  { Name: 'Personal Remittance', Hash: 'Personal Remittance' },
  { Name: 'Purchase', Hash: 'Purchase' },
  { Name: 'Investment', Hash: 'Investment' },
  { Name: 'Business Operations', Hash: 'Business Operations' },
  { Name: 'Currency Conversion', Hash: 'Currency Conversion' },
  { Name: 'Bank Account Alternative', Hash: 'Bank Account Alternative' },
  { Name: 'Other', Hash: 'Other' },
];

const Compliance = ({
  data,
  editing,
  toggleEdit,
  postAccount,
  isSubmitting,
}) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries();
  }, []);

  const callPostAccount = (req, actions) => {
    req.CountryHash = JSON.parse(req.OperatingCountry).Hash;

    postAccount('accounts', data.Hash, null, req, 'editingCompliance', actions);
  };

  const getCountries = async () => {
    try {
      const { data } = await API.get(`/countries`);

      setCountries(data.Data);
    } catch (error) {
      ToastError(error);
    }
  };

  const {
    Employer,
    JobTitle,
    Occupation,
    AnnualIncome,
    SourceOfFunds,
    UseOfFunds,
    BenefitOfBTC,
    FPPRemittance,
    FPPFunding,
    FPPJob,
    MonthlyExpectedVolume,
    ReasonForExpectedVolume,
    DigitalmintTradingAccountID,
    OperatingCountry,
  } = data;

  return (
    <EditableForm
      callBack={callPostAccount}
      setValues={{
        Employer,
        Occupation,
        SourceOfFunds,
        JobTitle,
        AnnualIncome,
        UseOfFunds,
        BenefitOfBTC,
        FPPRemittance,
        FPPFunding,
        FPPJob,
        MonthlyExpectedVolume,
        ReasonForExpectedVolume,
        DigitalmintTradingAccountID,
        OperatingCountry: JSON.stringify(OperatingCountry),
      }}
      customValidations={values => {
        const errors = {};

        return errors;
      }}
      editing={editing}
      permission={ACCOUNT_EDIT}
      triggerEdit={toggleEdit}
      editType="editingCompliance"
      sectionTitle="Compliance Details"
      isSubmitting={isSubmitting}
    >
      <div className="columns">
        <div className="column">
          <EditableInput
            name="Employer"
            label="Employer"
            type="text"
            currentValue={Employer}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="AnnualIncome"
            label="Annual Income"
            type="number"
            currentValue={formatPrice(AnnualIncome)}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="JobTitle"
            label="Job Title"
            type="text"
            currentValue={JobTitle}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="Occupation"
            label="Occupation"
            type="text"
            currentValue={Occupation}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableSelect
            name="UseOfFunds"
            label="Use Of Funds"
            placeholder="Select Use of Funds"
            currentValue={UseOfFunds}
            data={Use}
            editing={editing}
          />
        </div>

        <div className="column">
          <EditableSelect
            name="SourceOfFunds"
            label="Source Of Funds"
            placeholder="Select Source of Funds"
            currentValue={SourceOfFunds}
            data={Source}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="BenefitOfBTC"
            label="Benefit of BTC"
            type="text"
            currentValue={BenefitOfBTC}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="FPPRemittance"
            label="FPP Remittance"
            type="text"
            currentValue={FPPRemittance}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="FPPFunding"
            label="FPP Funding"
            type="text"
            currentValue={FPPFunding}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="FPPJob"
            label="FPP Job"
            type="text"
            currentValue={FPPJob}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="ReasonForExpectedVolume"
            label="Reason for Expected Volume"
            type="text"
            currentValue={ReasonForExpectedVolume}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="MonthlyExpectedVolume"
            label="Monthly Expected Volume"
            type="text"
            currentValue={MonthlyExpectedVolume}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="DigitalmintTradingAccountID"
            label="Digitalmint Trading ID"
            type="text"
            currentValue={DigitalmintTradingAccountID}
            editing={editing}
            disabled={DigitalmintTradingAccountID ? true : false}
          />
        </div>
        <div className="column">
          <div className="form-row">
            {editing ? (
              <>
                <label className="item-label">Operating Country: </label>
                <Field as="select" name="OperatingCountry" className="form-row">
                  <option value="" disabled>
                    Select a Country
                  </option>
                  {countries.map(c => (
                    <option key={c.Code} value={JSON.stringify(c)}>
                      {c.Name}
                    </option>
                  ))}
                </Field>
              </>
            ) : (
              <>
                <label className="item-label">Operating Country: </label>
                <p>{OperatingCountry.Name}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </EditableForm>
  );
};

Compliance.propTypes = {
  postAccount: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    DOB: PropTypes.string,
    Email: PropTypes.string,
    FullName: PropTypes.string,
    Hash: PropTypes.string,
    Addresses: PropTypes.arrayOf(
      PropTypes.shape({
        Active: PropTypes.bool.isRequired,
        City: PropTypes.string.isRequired,
        Hash: PropTypes.string.isRequired,
        Latitude: PropTypes.number.isRequired,
        Longitude: PropTypes.number.isRequired,
        Preferred: PropTypes.bool.isRequired,
        StateCode: PropTypes.string.isRequired,
        Street: PropTypes.string.isRequired,
        ZIP: PropTypes.string.isRequired,
      }).isRequired
    ),
    PhoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        Active: PropTypes.bool.isRequired,
        Hash: PropTypes.string.isRequired,
        Phone: PropTypes.string.isRequired,
        Preferred: PropTypes.bool.isRequired,
      })
    ),
  }),
};

export default Compliance;
