// Modules
import React from "react";
import { locationProps } from "../../Utilities/PropTypes";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
// Components
import LocationEvents from "./Tabs/LocationEvents";
import LocationTransactions from "./Tabs/LocationTransactions";
import LocationPlatforms from "./Tabs/LocationPlatforms";
import Commissions from "./Tabs/Commissions";
import Documents from "./Tabs/Documents";
import NotesTable from "./../../Notes/NotesTable";
// Helpers - Formatters
import { getDefaultTab, setDefaultTab } from "../../Utilities/Formatters";
// Permissions
import { PermissionsContext } from "../../Permissions/PermissionContext";
import TabCan from "../../Permissions/TabCan";
import {
  ACCOUNTING_COMMISS_VIEW,
  LOCATIONS_EDIT,
  LOCATIONS_DOCUMENTS_VIEW,
  TX_VIEW,
} from "../../Permissions/Permissions";

const TabsContainer = (props) => {
  return (
    <PermissionsContext.Consumer>
      {(userPermiss) => (
        <Tabs
          className="tabs-container"
          defaultIndex={getDefaultTab("location", 0)}
          onSelect={(index) => setDefaultTab("location", index)}
        >
          <TabList className="columns tab-list">
            {TabCan(userPermiss, TX_VIEW) ? (
              <Tab className="column tab">Transactions</Tab>
            ) : null}
            {TabCan(userPermiss, LOCATIONS_EDIT) ? (
              <Tab className="column tab">Notes</Tab>
            ) : null}
            <Tab className="column tab">Platforms</Tab>
            <Tab className="column tab">Events</Tab>
            {TabCan(userPermiss, ACCOUNTING_COMMISS_VIEW) ? (
              <Tab className="column tab">Commissions</Tab>
            ) : null}
            {TabCan(userPermiss, LOCATIONS_DOCUMENTS_VIEW) ? (
              <Tab className="column tab">Documents</Tab>
            ) : null}
          </TabList>
          {TabCan(userPermiss, TX_VIEW) ? (
            <TabPanel>
              <LocationTransactions hash={props.hash} />
            </TabPanel>
          ) : null}
          {TabCan(userPermiss, LOCATIONS_EDIT) ? (
            <TabPanel>
              <NotesTable
                locationHash={props.hash}
                history={props.history}
                table="Location Notes"
              />
            </TabPanel>
          ) : null}

          <TabPanel>
            <LocationPlatforms {...props} />
          </TabPanel>
          <TabPanel>
            <LocationEvents history={props.history} hash={props.hash} />
          </TabPanel>
          {TabCan(userPermiss, ACCOUNTING_COMMISS_VIEW) ? (
            <TabPanel>
              <Commissions hash={props.hash} getLocation={props.getLocation} />
            </TabPanel>
          ) : null}
          {TabCan(userPermiss, LOCATIONS_DOCUMENTS_VIEW) ? (
            <TabPanel>
              <Documents hash={props.hash} />
            </TabPanel>
          ) : null}
        </Tabs>
      )}
    </PermissionsContext.Consumer>
  );
};

TabsContainer.propTypes = {
  data: locationProps,
};

export default TabsContainer;
