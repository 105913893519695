// Modules
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import { PlatformPill } from '../Utilities/Components/TablePills';
import { ICONS } from '../Icons/IconsPath';
import Icon from '../Icons/Icon';
// Helpers - Formatters
import {
  formatDate,
  formatDateTime,
  formatLocationsCashCourierCell,
  formatPrice,
} from '../Utilities/Formatters';

const columnBuilder = [
  {
    Header: 'Code',
    accessor: 'Code',
    Cell: ({ row: { original } }) => original.Code,
    maxWidth: 60,
    sortMethod: 'Code',
    default: true,
  },
  {
    Header: 'Location',
    accessor: 'Address.StateCode',
    minWidth: 210,
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/locations/${original.Hash}`}>
        {original.Address.StateCode}, {original.Address.City},{' '}
        {original.Address.Street}
      </Link>
    ),
    sortMethod: 'CityState',
    default: true,
  },
  {
    Header: 'Cash Collection',
    accessor: 'CashCourier',
    maxWidth: 150,
    sortMethod: 'CashCourier',
    Cell: ({ row }) => formatLocationsCashCourierCell(row),
    default: true,
  },
  {
    Header: 'Cash At Location',
    accessor: 'CashAtLocation',
    maxWidth: 150,
    Cell: ({ row: { original } }) => {
      return (
        <span>
          {original.PlatformTypes &&
          original.PlatformTypes.filter(i => i.Name === 'Kiosk').length > 0
            ? formatPrice(original.CashAtLocation)
            : '-'}
          <br />
          {original.PlatformTypes &&
            original.PlatformTypes.length > 0 &&
            PlatformPill(
              `${original.PlatformTypes[0].Name} ${original.PlatformTypes[0].Model}`
            )}
        </span>
      );
    },
    sortMethod: 'CashAtLocation',
    default: true,
  },
  {
    Header: 'Installation Date',
    accessor: 'InstallationDate',
    Cell: ({ row: { original } }) => formatDate(original.InstallationDate),
    maxWidth: 125,
    sortMethod: 'InstallationDate',
    default: true,
  },
  {
    Header: 'Active',
    accessor: 'Active',
    Cell: ({ row: { original } }) =>
      original.Active ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
    maxWidth: 50,
    sortMethod: 'Active',
    default: true,
  },
  {
    Header: 'Last Activity',
    accessor: 'LastEvent',
    maxWidth: 100,
    Cell: ({ row: { original } }) => formatDateTime(original.LastEvent),
    sortMethod: 'LastActivity',
    default: true,
  },
  {
    Header: 'Group Name',
    accessor: 'Group.Name',
    width: 175,
    style: { textAlign: 'left ' },
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/groups/${original.Group.Hash}`}>
        {original.Group.Name}
      </Link>
    ),
    sortMethod: 'GroupName',
  },
  {
    Header: 'Business Name',
    accessor: 'BusinessName',
  },
  {
    Header: 'Phone',
    accessor: 'PhoneNumber',
  },
  {
    Header: 'ACH',
    accessors: 'ACHPull',
    Cell: ({ row: { original } }) =>
      original.ACHPull ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
  },
  {
    Header: 'Decommissioned',
    accessor: 'Decommissioned',
    Cell: ({ row: { original } }) =>
      original.Decommissioned ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
  },
  {
    Header: 'HIDTA',
    accessor: 'HIDTA',
    Cell: ({ row: { original } }) =>
      original.HIDTA ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
  },
  {
    Header: 'HIFCA',
    accessor: 'HIFCA',
    Cell: ({ row: { original } }) =>
      original.HIFCA ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
  },
  {
    Header: 'Main Contact',
    accessor: 'MainContact',
  },
  {
    Header: 'Compliance Contact',
    accessor: 'ComplianceContact',
  },
  {
    Header: 'Marketing Token',
    accessor: 'MarketingToken',
  },
  {
    Header: 'Branch',
    accessor: 'Branch',
    Cell: ({ row: { original } }) =>
      original.Branch ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
  },
  {
    Header: 'Agent',
    accessor: 'Agent',
  },
  {
    Header: 'Platform',
    accessor: 'Platform',
    Cell: ({ row: { original } }) =>
      original.PlatformType ? (
        <Link className="link" to={`/platforms/${original.Hash}`}>
          {PlatformPill(original.PlatformType)}
        </Link>
      ) : (
        '-'
      ),
    width: 150,
  },
  {
    Header: 'Weekly Avg. Accounts',
    accessor: 'LocationTransactionStatistics.WeeklyAvgAccounts',
  },
  {
    Header: 'Weekly Avg. Collected',
    accessor: 'LocationTransactionStatistics.WeeklyAvgCollected',
    Cell: ({ row: { original } }) =>
      formatPrice(original.LocationTransactionStatistics?.WeeklyAvgCollected) ||
      '-',
  },
  {
    Header: 'Prior Month Volume',
    sortMethod: 'PriorMonthVolume',
    accessor: 'LocationTransactionStatistics.VolumePriorMonth',
    Cell: ({ row: { original } }) =>
      formatPrice(
        original.LocationTransactionStatistics?.VolumePriorMonth || '-'
      ),
  },
  {
    Header: 'Current Month Value',
    accessor: 'LocationTransactionStatistics.VolumeCurrentMonth',
    Cell: ({ row: { original } }) =>
      formatPrice(original.LocationTransactionStatistics?.VolumeCurrentMonth) ||
      '-',
  },
];

export default columnBuilder;
