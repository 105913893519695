//Modules
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import { ListGroup, ListGroupItem } from '../../Utilities/Components';
import { ReviewPhasePill } from '../../Utilities/Components/TablePills';
// Helpers - Formatters
import { formatDate } from '../../Utilities/Formatters';
import { isEmpty } from 'lodash';

const EDDReviewDetails = ({ data }) => {
  const {
    Country,
    ReviewPhase,
    Decision,
    SegmentText,
    InitiationType,
    Activation,
    DueDate,
    CreatedAt,
    Parent,
    ReferralTypeText,
  } = data;

  if (isEmpty(data)) {
    return false;
  }

  return (
    <ListGroup title="Review Details">
      <React.Fragment>
        <React.Fragment>
          <ListGroupItem name="Decision" value={Decision ?? '-'} />
          <ListGroupItem name="Segment" value={SegmentText ?? '-'} />
          <ListGroupItem
            name="Review Phase"
            value={ReviewPhase ? ReviewPhasePill(ReviewPhase) : '-'}
          />
          <ListGroupItem name="Initiation Type" value={InitiationType} />
          <ListGroupItem name="Initiation Reason" value={ReferralTypeText} />
          <ListGroupItem name="Created At" value={formatDate(CreatedAt)} />
          <ListGroupItem name="Country Of Origin" value={Country?.Code} />
          <ListGroupItem name="DueDate" value={formatDate(DueDate)} />
          <ListGroupItem name="Activation" value={formatDate(Activation)} />
          <ListGroupItem
            name="Parent Review"
            value={
              Parent ? (
                <Link to={`/compliance/edds/${Parent.Hash}`}>
                  {Parent.Code}
                </Link>
              ) : (
                '-'
              )
            }
          />
        </React.Fragment>
      </React.Fragment>
    </ListGroup>
  );
};

export default EDDReviewDetails;
