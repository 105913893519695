// Modules
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// Components
import Table from '../../Table/Table';
import { CommissionType } from '../../Utilities/Components/TablePills';
import DeleteCommissionModal from './Commissions/DeleteCommissionModal';
// Helpers - Formatters
import { formatPrice, formatPercent } from '../../Utilities/Formatters';
// Permissions
import Can from '../../Permissions/Can';
import { ACCOUNTING_COMMISS_EDIT } from '../../Permissions/Permissions';

const GroupCommissions = ({ hash, name }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [commission, setCommission] = useState({});
  const [triggerAPI, setTrigger] = useState(false);

  useEffect(() => {
    if (triggerAPI) {
      setTrigger(false);
    }
  }, [triggerAPI]);

  const openDeleteModal = row => {
    setCommission(row);
    setModalOpen(true);
  };

  const closeDeleteModal = () => {
    setModalOpen(false);
    setTrigger(true);
  };

  return (
    <>
      <Table
        columns={columnBuilder(openDeleteModal)}
        endpoint={`groups/${hash}/commissions`}
        tablename="Commissions"
        nested={true}
        dataFilter={data => {
          if (data) {
            return data.filter(row => !row.Retired);
          }
          return [];
        }}
        actions={[
          {
            name: `Add New Commission`,
            link: `/groups/add-group-commissions`,
            params: hash,
            permission: ACCOUNTING_COMMISS_EDIT,
            state: {
              editing: false,
              groupName: name,
            },
          },
        ]}
        triggerAPI={triggerAPI}
      />
      <DeleteCommissionModal
        isOpen={modalOpen}
        commission={commission}
        closeModal={() => closeDeleteModal()}
      />
    </>
  );
};

const columnBuilder = openDeleteModal => [
  {
    Header: 'Type',
    accessor: 'Type',
    Cell: ({ row: { original } }) =>
      CommissionType(original.CommissionType, original.CommissionTypeName),
  },
  {
    Header: 'Payee',
    accessor: 'PayableTo',
    style: { textAlign: 'left' },
    Cell: ({ row: { original } }) =>
      original.DebitAccount ? (
        <Link
          className="table-pill"
          to={`/accounting/ach/${original.DebitAccount.Hash}`}
        >
          {original.PayableTo}
        </Link>
      ) : (
        <p className="has-text-centered">-</p>
      ),
  },

  {
    Header: 'Percent',
    Cell: ({ row: { original } }) =>
      original.Percent ? formatPercent(original.Percent) : '-',
  },
  {
    Header: 'Fiat',
    Cell: ({ row: { original } }) =>
      original.Fiat ? formatPrice(original.Fiat) : '-',
  },
  {
    Header: 'Action',
    Cell: ({ row: { original } }) => (
      <Can do={ACCOUNTING_COMMISS_EDIT}>
        <button
          className="column-btn remove"
          onClick={() => openDeleteModal(original)}
        >
          Retire
        </button>
      </Can>
    ),
  },
];

export default GroupCommissions;
