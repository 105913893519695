const accountNotesKeys = {
  Timestamp: {
    Label: 'Date',
    Type: 'daterange',
  },
  Note: {
    Label: 'Note',
    Type: 'string',
  },
  Source: {
    Label: 'Source',
    Type: 'string',
  },
  Type: {
    Label: 'Type',
    Type: 'int',
    Options: [
      {
        Display: 'Case Opened',
        Value: 15,
      },
      {
        Display: 'Email Message',
        Value: 3,
      },
      {
        Display: 'In-Person Meeting',
        Value: 10,
      },
      {
        Display: 'Inbound Call',
        Value: 4,
      },
      {
        Display: 'Inbound Email',
        Value: 8,
      },
      {
        Display: 'Inbound Text',
        Value: 7,
      },
      {
        Display: 'Internal Note',
        Value: 0,
      },
      {
        Display: 'Left Voicemail',
        Value: 11,
      },
      {
        Display: 'Outbound Call',
        Value: 5,
      },
      {
        Display: 'Outbound Email',
        Value: 9,
      },
      {
        Display: 'Outbound Text',
        Value: 6,
      },
      {
        Display: 'SMS',
        Value: 2,
      },
      {
        Display: 'System Note',
        Value: 1,
      },
      {
        Display: 'Zendesk',
        Value: 13,
      },
    ],
  },
};

export default accountNotesKeys;
