// Modules
import React from "react";
// Components
import { ListGroup, ListGroupItem } from "../../../Utilities/Components";
// Helpers - Formatters
import { formatPrice } from "../../../Utilities/Formatters";

const AlertingTransaction = ({ props }) => {
  return (
    <ListGroup title="Transaction Details">
      <ListGroupItem
        name="Total Aggregate Value"
        value={formatPrice(props.TotalAlertingValue)}
      />
      <ListGroupItem
        name="Total Alerting Transactions"
        value={props.TotalAlertingTransactions}
      />
      <ListGroupItem name="Total Alert Days" value={props.TotalAlertDays} />
    </ListGroup>
  );
};

export default AlertingTransaction;
