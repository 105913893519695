const accountKeys = {
  Active: {
    Label: "Active",
    Type: "bool",
    Options: [
      {
        Display: "True",
        Value: true,
      },
      {
        Display: "False",
        Value: false,
      },
    ],
  },
  Banned: {
    Label: "Banned",
    Type: "bool",
    Options: [
      {
        Display: "True",
        Value: true,
      },
      {
        Display: "False",
        Value: false,
      },
    ],
  },
  DOB: {
    Label: "Birthday",
    Type: "date",
  },
  "Addresses.City": {
    Label: "City",
    Type: "string",
  },
  "Addresses.CountryCode": {
    Label: "Country",
    Type: "string",
    Options: [
      {
        Display: "US",
        Value: "US",
      },
    ],
  },
  CreatedAt: {
    Label: "Create Date",
    Type: "daterange",
  },
  DocumentSerial: {
    Label: "Document Serial #",
    Type: "string",
  },
  Email: {
    Label: "E-mail",
    Type: "string",
  },
  FirstName: {
    Label: "First Name",
    Type: "string",
  },
  LastName: {
    Label: "Last Name",
    Type: "string",
  },
  "AccountTransactionStatistics.MostRecentActivity": {
    Label: "Last Seen Date",
    Type: "daterange",
  },
  MiddleName: {
    Label: "Middle Name",
    Type: "string",
  },
  "PhoneNumbers.Phone": {
    Label: "Phone Number",
    Type: "string",
  },
  "Addresses.StateCode": {
    Label: "State Code",
    Type: "string",
    Options: [
      {
        Display: "AL",
        Value: "AL",
      },
      {
        Display: "AK",
        Value: "AK",
      },
      {
        Display: "AZ",
        Value: "AZ",
      },
      {
        Display: "AR",
        Value: "AR",
      },
      {
        Display: "CA",
        Value: "CA",
      },
      {
        Display: "CO",
        Value: "CO",
      },
      {
        Display: "CT",
        Value: "CT",
      },
      {
        Display: "DE",
        Value: "DE",
      },
      {
        Display: "DC",
        Value: "DC",
      },
      {
        Display: "FL",
        Value: "FL",
      },
      {
        Display: "GA",
        Value: "GA",
      },
      {
        Display: "HI",
        Value: "HI",
      },
      {
        Display: "ID",
        Value: "ID",
      },
      {
        Display: "IL",
        Value: "IL",
      },
      {
        Display: "IN",
        Value: "IN",
      },
      {
        Display: "IA",
        Value: "IA",
      },
      {
        Display: "KS",
        Value: "KS",
      },
      {
        Display: "KY",
        Value: "KY",
      },
      {
        Display: "LA",
        Value: "LA",
      },
      {
        Display: "ME",
        Value: "ME",
      },
      {
        Display: "MH",
        Value: "NH",
      },
      {
        Display: "MD",
        Value: "MD",
      },
      {
        Display: "MA",
        Value: "MA",
      },
      {
        Display: "MI",
        Value: "MI",
      },
      {
        Display: "MN",
        Value: "MN",
      },
      {
        Display: "MS",
        Value: "MS",
      },
      {
        Display: "MO",
        Value: "MO",
      },
      {
        Display: "MT",
        Value: "MT",
      },
      {
        Display: "NE",
        Value: "NE",
      },
      {
        Display: "NV",
        Value: "NV",
      },
      {
        Display: "NH",
        Value: "NH",
      },
      {
        Display: "NJ",
        Value: "NJ",
      },
      {
        Display: "NM",
        Value: "NM",
      },
      {
        Display: "NY",
        Value: "NY",
      },
      {
        Display: "NC",
        Value: "NC",
      },
      {
        Display: "ND",
        Value: "ND",
      },
      {
        Display: "OH",
        Value: "OH",
      },
      {
        Display: "OK",
        Value: "OK",
      },
      {
        Display: "OR",
        Value: "OR",
      },
      {
        Display: "PA",
        Value: "PA",
      },
      {
        Display: "PR",
        Value: "PR",
      },
      {
        Display: "RI",
        Value: "RI",
      },
      {
        Display: "SC",
        Value: "SC",
      },
      {
        Display: "SD",
        Value: "SD",
      },
      {
        Display: "TN",
        Value: "TN",
      },
      {
        Display: "TX",
        Value: "TX",
      },
      {
        Display: "UT",
        Value: "UT",
      },
      {
        Display: "VT",
        Value: "VT",
      },
      {
        Display: "VI",
        Value: "VI",
      },
      {
        Display: "VA",
        Value: "VA",
      },
      {
        Display: "WA",
        Value: "WA",
      },
      {
        Display: "WV",
        Value: "WV",
      },
      {
        Display: "WI",
        Value: "WI",
      },
      {
        Display: "WY",
        Value: "WY",
      },
      {
        Display: "Puerto Rico",
        Value: "PR",
      },
      {
        Display: "Guam",
        Value: "GU",
      },
      {
        Display: "Virgin Islands",
        Value: "VI",
      },
      {
        Display: "America Samoa",
        Value: "AS",
      },
      {
        Display: "Northern Mariana Islands",
        Value: "MP",
      },
    ],
  },
  Status: {
    Label: "Status",
    Type: "int",
    Options: [
      {
        Display: "Unapproved",
        Value: 0,
      },
      {
        Display: "Approved",
        Value: 1,
      },
      {
        Display: "Approved for Higher Limits",
        Value: 2,
      },
      {
        Display: "Approved for OTC Limits",
        Value: 3,
      },
      {
        Display: "Service Account",
        Value: 10,
      },
      {
        Display: "Banned",
        Value: 11,
      },
      {
        Display: "Employee",
        Value: 12,
      },
    ],
  },
  "Addresses.Street": {
    Label: "Street Address",
    Type: "string",
  },
  TotalCashInAmount: {
    Label: "Total Cash In",
    Type: "decimal",
  },
  "Addresses.ZIP": {
    Label: "ZIP Code",
    Type: "string",
  },
};

export default accountKeys;
