import React, { Component } from "react";

/* Currently no hooks support for getDerivedStateFromError,
so we must use components */
class CellBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <p className="has-text-centered">-</p>;
    }

    return children;
  }
}

export default CellBoundary;
