const achKeys = {
  AccountNumber: {
    Label: "Account Number",
    Type: "string",
  },
  Name: {
    Label: "Name",
    Type: "string",
  },
  PayorName: {
    Label: "Payor Name",
    Type: "string",
  },
  RoutingNumber: {
    Label: "Routing Number",
    Type: "number",
  },
};

export default achKeys;
