import React, { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import { cleanUrl } from '@digitalmint/api-service';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: true,
    tunnel: cleanUrl(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/proxy/sentry`
    ),
    // true = 100%
    // false = 0%
    // 1.0 = 100%
    // 0.5 = 50%
    // ... etc.
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

// Strict mode is good for debugging in development, but ends up mounting
// all components twice, causing some issues. Turning it off might create
// problems, just be aware of it.
// https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode
root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
