import { Link } from 'react-router-dom';
import { formatDateTime } from '../../Utilities/Formatters';
import { capitalize } from 'lodash';

const columnBuilder = [
  {
    Header: 'Code',
    accessor: 'Code',
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/customer-refunds/${original.Hash}`}>
        {original.Code}
      </Link>
    ),
  },
  {
    Header: 'Account',
    accessor: 'Account',
    Cell: ({ row: { original } }) => (
      <Link to={`/accounts/${original.Account.Hash}`}>
        {original.Account.FullName}
      </Link>
    ),
  },
  {
    Header: 'Transactions',
    accessor: 'Transactions',
    minWidth: 155,
    Cell: ({
      row: {
        original: { Transactions },
      },
    }) => {
      return Transactions.map((tx, i) => (
        <Link className="table-pill" to={`/transactions/${tx.Hash}`}>
          {tx.InvoiceCode}
        </Link>
      ));
    },
  },
  {
    Header: 'Status',
    accessor: 'Status',
    Cell: ({ row: { original } }) => capitalize(original.Status),
    sortMethod: 'Status',
  },
  {
    Header: 'Created At',
    accessor: 'CreatedAt',
    Cell: ({ row: { original } }) => formatDateTime(original.CreatedAt),
    sortMethod: 'CreatedAt',
  },
  {
    Header: 'Updated At',
    accessor: 'UpdatedAt',
    Cell: ({ row: { original } }) => formatDateTime(original.UpdatedAt),
    sortMethod: 'UpdatedAt',
  },
];

export default columnBuilder;
