const formatLocationsCashCourierCell = (row) => {
  const { original } = row;
  if (original.CashCourier) {
    return original.CashCourier.Name;
  } else if (original.DebitAccount) {
    return (
      original.DebitAccount.Name + " " + original.DebitAccount.RoutingNumber
    );
  }
  return "-";
};

export default formatLocationsCashCourierCell;
