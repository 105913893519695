import { useState, useEffect } from "react";

// Decrement a given time every second, turning on/off will reset to given time
export default function useRefresh(on, time) {
  const [autoRefresh, setAutoRefresh] = useState(on);
  const [refreshTime, setRefreshTime] = useState(time);

  useEffect(() => {
    let interval = null;
    if (autoRefresh) {
      if (refreshTime > 0) {
        interval = setInterval(() => {
          setRefreshTime((refreshTime) => refreshTime - 1);
        }, 1000);
      } else {
        setRefreshTime(time);
      }
    } else if (!autoRefresh && refreshTime !== 0) {
      clearInterval(interval);
      setAutoRefresh(false);
      setRefreshTime(time);
    }

    // componentWillUnmount
    return () => clearInterval(interval);
  }, [autoRefresh, refreshTime, time]);

  const toggleRefresh = (on, time) => {
    setAutoRefresh(on);
    setRefreshTime(time);
  };

  return [autoRefresh, refreshTime, toggleRefresh];
}
