import React from "react";
// Components
import { Header } from "../../Utilities/Components";
// Helpers - Formatters
import { formatDateTime, formatActivityType } from "../../Utilities/Formatters";
import { objProp } from "./../../Utilities/PropTypes";
// Permissions
import Can from "../../Permissions/Can";
import { COMPLIANCE_INVEST_REVIEW } from "../../Permissions/Permissions";

const DecideNarrative = ({ data, postDecision }) => {
  const {
    Narrative,
    AuthorAccount,
    DispositionText,
    NarrativeUpdatedAt,
    ActivityType,
  } = data;

  return (
    <React.Fragment>
      <div className="space-between">
        <Header title="Narrative" />
        <Can do={COMPLIANCE_INVEST_REVIEW}>
          <div>
            <button
              type="button"
              className="btn primary-btn marginRight"
              onClick={() => postDecision(true, "approved")}
            >
              Approve Investigation
            </button>
            <button
              type="button"
              className="btn red"
              onClick={() => postDecision(false, "denied")}
            >
              Deny Investigation
            </button>
          </div>
        </Can>
      </div>

      <div id="narrative">
        <p>
          <span className="item-label">Worked by:</span>{" "}
          {AuthorAccount.FirstName} {AuthorAccount.LastName}
        </p>
        <p>
          <span className="item-label">Updated At:</span>{" "}
          {formatDateTime(NarrativeUpdatedAt)}
        </p>
        <p>
          <span className="item-label">Disposition:</span> {DispositionText}
        </p>
        {ActivityType !== undefined ? (
          <p>
            <span className="item-label">Activity Type:</span>{" "}
            {formatActivityType(ActivityType)}
          </p>
        ) : null}
        <p className="narrative">{Narrative}</p>
      </div>
    </React.Fragment>
  );
};

DecideNarrative.propTypes = {
  data: objProp,
};

export default DecideNarrative;
