// TODO: Replace in favor of ApiService in src/services/Api
// - useApi hook in most places
// - ApiService.fetch in others

import axios from 'axios';
// API configs
const BRAVO = {
  URL: process.env.REACT_APP_API_URL,
  NAMESPACE: process.env.REACT_APP_API_VERSION,
};

const baseURL = `${BRAVO.URL}/${BRAVO.NAMESPACE}`.replace(
  /(https?:\/\/)|(\/)+/g,
  '$1$2'
);
const version = '0.0.0';
// allows cross site access control requests
axios.defaults.withCredentials = true;
// Sets up axios api calls
const API = axios.create({ baseURL });

export { API, version };
