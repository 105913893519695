import React, { useState, useEffect, useContext } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Icon from 'src/Icons/Icon';
import { FUNDING_VIEW } from 'src/Permissions/Permissions';
import { PermissionsContext } from 'src/Permissions/PermissionContext';
import { checkPermission } from 'src/Permissions/CheckPermission';
import { ToastError } from 'src/Utilities/Components';
import { useApi } from 'src/Services/Api';
import COIN_ICONS from 'src/Utilities/Data/CoinIcons';
import { formatCoin } from '@digitalmint/utilities/formatters';

export function WalletBalanceItem({ Coin, Balance, Fee }) {
  return (
    <div className="column is-narrow" data-testid="Coin">
      <div
        className="balance"
        data-tooltip-content={`${Coin} Wallet Balance`}
        data-tooltip-id={`${Coin}-balance`}
      >
        <Icon
          icon={COIN_ICONS[Coin].icon}
          color={COIN_ICONS[Coin].color}
          size="30"
        />
        <p className="balance-amount">{formatCoin(Balance)}</p>
        <ReactTooltip id={`${Coin}-balance`} place="bottom" effect="solid" />
      </div>
      <div
        className="balance"
        data-tooltip-content={`${Coin} Mining Fee`}
        data-tooltip-id="mining"
      >
        <p className="balance-amount">{formatCoin(Fee)}</p>
        <ReactTooltip id="mining" place="bottom" effect="solid" />
      </div>
    </div>
  );
}

export default function WalletBalances() {
  const [balances, setBalances] = useState({});
  const userPermissions = useContext(PermissionsContext);

  useEffect(() => {
    if (checkPermission(userPermissions, FUNDING_VIEW)) {
      handleBalances();
    }
  }, []);

  const [handleBalances, isBalancesLoading] = useApi({
    method: 'get',
    url: `/accounting/funding/balances`,
    onSuccess: data => {
      setBalances(data.Coins);
    },
    onError: error => {
      ToastError(error);
    },
  });

  if (isBalancesLoading) return false;

  return (
    <div className="columns is-mobile is-centered">
      {Object.entries(balances).map(([Coin, { Balance, Fee }], key) => {
        // NOTE: Only supporting the coins found in icons for now.
        // For future coins, either add the coin to the COIN_ICONS object,
        // or consider using a generic icon for coins that need to be supported
        // but don't have icons yet.
        if (!COIN_ICONS[Coin]) return false;

        return (
          <WalletBalanceItem
            key={key}
            Balance={Balance}
            Coin={Coin}
            Fee={Fee}
          />
        );
      })}
    </div>
  );
}
