const formatAccountPillStatus = (status) => {
  let attr, text;
  switch (status) {
    case 1:
      text = "Tier 1";
      attr = "status-tier1";
      break;
    case 2:
      text = "Tier 2";
      attr = "status-tier2";
      break;
    case 3:
      text = "Tier 3";
      attr = "status-tier3";
      break;
    default:
      text = null;
      attr = null;
  }
  return { attr, text };
};

export default formatAccountPillStatus;
