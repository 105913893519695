/* COMPONENT OVERVIEW
- Takes props from parent
- Renders Location overview data
- Calls postLocation function prop & sends request to parent
*/
import React from 'react';
import { Link } from 'react-router-dom';
import { EditableForm } from '../../Forms/EditableForm';
import { Contacts } from '../../Utilities/Components';
import {
  EditableCheckbox,
  EditableInput,
  EditableLink,
  EditableSelect,
} from '../../Forms/EditableInputs';
import { formatDate } from '../../Utilities/Formatters';
import { LOCATIONS_EDIT } from '../../Permissions/Permissions';
import PropTypes from 'prop-types';

const Overview = ({
  data,
  editing,
  groups,
  toggleEdit,
  postLocation,
  isSubmitting,
}) => {
  const callPostLocation = (values, actions) => {
    // Adding to form obj for backend requirements
    let req = {
      Active: values.Active,
      Agent: values.Agent,
      LegalEntity: values.LegalEntity,
      BusinessName: values.BusinessName,
      CrossStreet: values.CrossStreet,
      GMBURL: values.GMBURL,
      GroupHash: values.Group,
      CashOperatorGroupHash: values.CashOperatingGroupHash,
      Phone: values.PhoneNumber,
      MainContact: values.MainContact,
      MaintenanceContact: values.MaintenanceContact,
      MarketingToken: values.MarketingToken,
      Neighborhood: values.Neighborhood,
      ShowBillCounterScreen: values.ShowBillCounterScreen,
      GoogleMapsReviewURL: values.GoogleMapsReviewURL,
    };

    // add to form if no date object exist
    if (!!values.InstallationDate) {
      req.InstallationDate = formatDate(values.InstallationDate, '', true);
    }
    // remove from form if no valid date is set when editing
    if (values.InstallationDate === '-') {
      delete req.InstallationDate;
    }

    //Sends req to POST call in parent
    postLocation('locations', data.Hash, null, req, 'editingOverview', actions);
  };

  const {
    Active,
    Agent,
    ShowBillCounterScreen,
    BusinessName,
    CrossStreet,
    LegalEntity,
    Group,
    CashOperatingGroup,
    GMBURL,
    GoogleMapsReviewURL,
    MainContact,
    MaintenanceContact,
    MarketingToken,
    Neighborhood,
    PlatformType,
    PhoneNumber,
    InstallationDate,
  } = data;

  return (
    <EditableForm
      callBack={callPostLocation}
      setValues={{
        Active,
        Agent,
        LegalEntity,
        ShowBillCounterScreen,
        BusinessName,
        CrossStreet,
        GroupHash: Group.Hash,
        CashOperatingGroupHash: CashOperatingGroup.Hash,
        GMBURL,
        MainContact,
        MarketingToken,
        MaintenanceContact,
        Neighborhood,
        PlatformType: PlatformType ? PlatformType : '-',
        PhoneNumber,
        InstallationDate: formatDate(InstallationDate, 'YYYY-MM-DD'),
        GoogleMapsReviewURL,
      }}
      customValidations={() => {
        let errors = {};
        return errors;
      }}
      editing={editing}
      permission={LOCATIONS_EDIT}
      triggerEdit={toggleEdit}
      editType="editingOverview"
      sectionTitle="Location Details"
      isSubmitting={isSubmitting}
    >
      <div className="columns">
        <div className="column">
          <EditableInput
            name="LegalEntity"
            label="Legal Entity"
            type="text"
            currentValue={LegalEntity}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="BusinessName"
            label="Location Name"
            type="text"
            currentValue={BusinessName}
            editing={editing}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableInput
            name="PhoneNumber"
            label="Phone"
            type="text"
            currentValue={<a href={`tel:${PhoneNumber}`}>{PhoneNumber}</a>}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableSelect
            name="Group"
            label="Operating Group"
            placeholder="Please select a Group"
            currentValue={
              <Link to={`/groups/${Group.Hash}`}>{Group.Name}</Link>
            }
            selected={Group.Hash}
            data={groups}
            editing={editing}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableCheckbox
            name="Active"
            label="Active"
            type="text"
            currentValue={Active}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="PlatformType"
            label="Platform"
            type="text"
            currentValue={PlatformType}
            editing={editing}
            disabled
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableInput
            name="MainContact"
            label="Location Partner"
            type="text"
            currentValue={<Contacts contact={MainContact} />}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="MaintenanceContact"
            label="Maintenance Contact"
            type="text"
            currentValue={<Contacts contact={MaintenanceContact} />}
            editing={editing}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableInput
            name="CrossStreet"
            label="Cross Street"
            type="text"
            currentValue={CrossStreet}
            editing={editing}
          />
        </div>
        <div className="column is-6">
          <EditableInput
            name="Neighborhood"
            label="Neighborhoods"
            type="text"
            currentValue={Neighborhood}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableLink
            name="GMBURL"
            label="GMB Listing"
            type="text"
            link={GMBURL}
            linkName="Google My Business"
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="InstallationDate"
            label="Installed"
            type="date"
            currentValue={formatDate(InstallationDate)}
            editing={editing}
            disabled={!!InstallationDate}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="MarketingToken"
            label="Marketing Token"
            type="text"
            currentValue={MarketingToken}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableCheckbox
            name="ShowBillCounterScreen"
            label="Teller Bill Counter"
            type="text"
            currentValue={ShowBillCounterScreen}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="GoogleMapsReviewURL"
            label="Google Review URL"
            type="text"
            currentValue={GoogleMapsReviewURL}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableSelect
            name="CashOperatingGroupHash"
            label="Location Cash Management"
            placeholder="Please select a Group"
            type="text"
            data={groups}
            currentValue={
              <Link to={`/groups/${CashOperatingGroup.Hash}`}>
                {CashOperatingGroup.Name}
              </Link>
            }
            selected={CashOperatingGroup.Hash}
            editing={editing}
          />
        </div>
      </div>
    </EditableForm>
  );
};

Overview.propTypes = {
  editing: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  postLocation: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  data: PropTypes.shape({
    Active: PropTypes.bool.isRequired,
    BusinessName: PropTypes.PropTypes.string.isRequired,
    Address: PropTypes.shape({
      Active: PropTypes.bool.isRequired,
      City: PropTypes.string.isRequired,
      Hash: PropTypes.string.isRequired,
      Latitude: PropTypes.number,
      Longitude: PropTypes.number,
      Preferred: PropTypes.bool.isRequired,
      StateCode: PropTypes.string.isRequired,
      Street: PropTypes.string.isRequired,
      ZIP: PropTypes.string.isRequired,
    }),
    Code: PropTypes.PropTypes.string.isRequired,
    ComplianceContact: PropTypes.string,
    CreditAccount: PropTypes.object.isRequired,
    DebitAccount: PropTypes.object,
    Decommissioned: PropTypes.bool.isRequired,
    FridayCloseAt: PropTypes.string,
    FridayOpen: PropTypes.bool.isRequired,
    FridayOpenAt: PropTypes.string,
    Group: PropTypes.object.isRequired,
    HIDTA: PropTypes.bool,
    Hash: PropTypes.string,
    InstallationDate: PropTypes.string,
    MaintenanceContact: PropTypes.string,
    MondayCloseAt: PropTypes.string,
    MondayOpen: PropTypes.bool.isRequired,
    MondayOpenAt: PropTypes.string,
    PhoneNumber: PropTypes.PropTypes.string.isRequired,
    Platform: PropTypes.string,
    SaturdayCloseAt: PropTypes.string,
    SaturdayOpen: PropTypes.bool.isRequired,
    SaturdayOpenAt: PropTypes.string,
    SundayCloseAt: PropTypes.string,
    SundayOpen: PropTypes.bool.isRequired,
    SundayOpenAt: PropTypes.string,
    ThursdayCloseAt: PropTypes.string,
    ThursdayOpen: PropTypes.bool.isRequired,
    ThursdayOpenAt: PropTypes.string,
    TuesdayCloseAt: PropTypes.string,
    TuesdayOpen: PropTypes.bool.isRequired,
    TuesdayOpenAt: PropTypes.string,
    WednesdayCloseAt: PropTypes.string,
    WednesdayOpen: PropTypes.bool.isRequired,
    WednesdayOpenAt: PropTypes.string,
  }),
};

export default Overview;
