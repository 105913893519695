import React from "react";
// Helpers - Formatters
import { formatPrice, formatNumber } from "../../Utilities/Formatters";

const LocationMetrics = ({ stats }) => {
  const displayTitle = (data, type = "number") => {
    if (type === "price") {
      return <h2>{data ? formatPrice(data) : "$0.00"}</h2>;
    } else {
      return <h2>{data ? formatNumber(data) : 0}</h2>;
    }
  };
  return (
    <div className="performance-metrics">
      <div className="columns metadata-container is-variable is-1">
        <div className="column">
          <div className="form-outline form-create long-text">
            {displayTitle(stats.WeeklyAvgCollected, "price")}
            <h4 className="item-label">10-Week Avg. Cash Collected</h4>
          </div>
          <div className="form-outline form-create long-text">
            {displayTitle(stats.VolumePriorMonth, "price")}
            <h4 className="item-label">Prior Month Volume</h4>
          </div>
        </div>
        <div className="column">
          <div className="form-outline form-create long-text">
            {displayTitle(stats.WeeklyAvgAccounts, "number")}
            <h4 className="item-label">10-Week Avg. Accounts</h4>
          </div>
          <div className="form-outline form-create long-text">
            {displayTitle(stats.VolumeCurrentMonth, "price")}
            <h4 className="item-label">Current Month Volume</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationMetrics;
