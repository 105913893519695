import React from 'react';
import { Form, Formik } from 'formik';
// Components
import { FormInput, FormSelect } from '../../../Forms/FormInputs';
import { GenericModal } from '../../../Utilities/Components';
// Data
import { BanReasonList } from '../../../Utilities/Data/BanReasonList';

const BanAccountModal = ({ isOpen, putAccount, account, closeModal }) => {
  return (
    <Formik
      initialValues={{
        Narrative: '',
        Reason: '',
      }}
      validate={values => {
        let errors = {};
        if (!values.Narrative) {
          errors.Narrative = 'is required';
        }
        if (!values.Reason) {
          errors.Reason = 'is required';
        }
        return errors;
      }}
      onSubmit={async values => {
        await putAccount({
          Narrative: values.Narrative,
          Reason: parseInt(values.Reason),
        });
        values.Narrative = '';
        values.Reason = '';
      }}
      children={({ touched, errors, handleSubmit, isSubmitting }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={() => {
            closeModal('isAccountBanOpen');
          }}
          viewMsg={account}
          headerMsg="Ban Account"
          btnText="Submit"
          btnAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <Form className="columns form-outline is-centered">
            <div className="column is-11">
              <FormInput
                errors={errors}
                touched={touched}
                label="Narrative"
                name="Narrative"
                component="textarea"
                placeholder="Enter Narrative..."
              />
              <FormSelect
                label="Reason"
                name="Reason"
                type="text"
                errors={errors}
                touched={touched}
                placeholder="Select Reason for Banning"
                options={
                  <React.Fragment>
                    {BanReasonList.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </React.Fragment>
                }
              />
            </div>
          </Form>
        </GenericModal>
      )}
    />
  );
};

export default BanAccountModal;
