
// Modules
import React from "react";
// Components
import { HoursList } from "../../Utilities/Data/Hours";
import { EditableSelect, EditableCheckbox } from "../../Forms/EditableInputs";
import { reqBoolProp, reqStrProp, strProp } from "../../Utilities/PropTypes";



const SetHour = ({ editing, day, dayOpen, dayOpen24, dayOpenAt, dayCloseAt }) => {
    const setCurrentValue = (day, dayHour) => {
        if (day) {
            return dayHour;
        } else {
            return "Closed";
        }
    };
    return <div className="columns hours">
        <div className="column is-3">
            <EditableCheckbox
                name={`${day}Open`}
                label={editing ? day.slice(0, 2) : day}
                type="text"
                currentValue={dayOpen}
                editing={editing}
            />
        </div>
        {editing && (
            <div className="column is-3">
                <EditableCheckbox
                    name={`${day}Open24`}
                    label="24 Hours"
                    type="text"
                    currentValue={dayOpen24}
                    editing={editing}
                />
            </div>
        )}
        {!editing && dayOpen24 ?
            <React.Fragment>
                <div className="column">
                    <div className="form-row">
                        <label className="item-label">From:</label>
                        <p>24 Hours</p>
                        <label className="item-label">{" "}</label>
                        <p>{" "}</p>
                    </div>
                </div>
            </React.Fragment>
            :
            <React.Fragment>
                <div className="column">
                    <EditableSelect
                        name={`${day}OpenAt`}
                        label="From"
                        placeholder="Please select a time"
                        currentValue={setCurrentValue(dayOpen, dayOpenAt)}
                        data={HoursList}
                        editing={editing}
                    />
                </div>
                <div className="column">
                    <EditableSelect
                        name={`${day}CloseAt`}
                        label="To"
                        placeholder="Please select a time"
                        currentValue={setCurrentValue(dayOpen, dayCloseAt)}
                        data={HoursList}
                        editing={editing}
                    />
                </div>
            </React.Fragment>
        }
    </div>
}


SetHour.propTypes = {
    editing: reqBoolProp,
    day: reqStrProp,
    dayOpen: reqBoolProp,
    dayOpen24: reqBoolProp,
    dayOpenAt: strProp,
    dayCloseAt: strProp,
};

export default SetHour;