import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { isEmpty, capitalize, inRange } from 'lodash';
import { Link } from 'react-router-dom';
import { API } from '../../Config/cfg.js';
import {
  GoBack,
  Header,
  Loading,
  GenericModal,
  ToastSuccess,
} from '../../Utilities/Components';
import { FormInput, FormSelect } from '../../Forms/FormInputs';
import { ListGroup, ListGroupItem } from '../../Utilities/Components';
import Icon from '../../Icons/Icon';
import { ICONS } from '../../Icons/IconsPath';
import { formatDate } from '../../Utilities/Formatters';
import REFUND_METHODS from '../../Utilities/Data/RefundMethods';

const SingleRefund = ({ match, history }) => {
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [refund, setRefund] = useState({});
  const [refundStates, setRefundStates] = useState([]);
  const [sendMethods, setSendMethods] = useState([]);

  useEffect(() => {
    if (match?.params?.hash) {
      setIsLoading(true);
      try {
        getRefund();
        getRefundStates();
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const getRefund = async () => {
    try {
      const { data } = await API.get(`/customer-refunds/${match.params.hash}`);
      setRefund(data);
    } catch (error) {
      setError(error);
    }
  };

  const getRefundStates = async () => {
    try {
      const { data } = await API.get('/customer-refunds/status');
      setRefundStates(data.Statuses);
      setSendMethods(data.SendMethods);
    } catch (error) {
      setError(error);
    }
  };

  const patchRefund = async (values, actions) => {
    if (values.IssueDate) {
      const issueDate = new Date(values.IssueDate);
      values.IssueDate = issueDate.toISOString();
    } else {
      delete values.IssueDate;
    }

    if (values.ContactDate) {
      const contactDate = new Date(values.ContactDate);
      values.ContactDate = contactDate.toISOString();
    } else {
      delete values.ContactDate;
    }

    if (!values.CheckIdentifier) delete values.CheckIdentifier;
    if (values.PaymentMethod)
      values.PaymentMethod = parseInt(values.PaymentMethod);

    try {
      await API.patch(`/customer-refunds/${match.params.hash}`, values);

      ToastSuccess('Refund has been updated');
    } catch (error) {
      setError(error);
    } finally {
      actions.setSubmitting(false);
      setEditing(false);
    }
  };

  if (isLoading) return <Loading errors={error} />;

  if (isEmpty(refund)) {
    return (
      <>
        <GoBack
          history={history}
          defaultPath="/customer-refunds"
          defaultName="Refunds"
        />
        <div>
          <div className="column">
            <Header icon={ICONS.FILE} title="Refund Not Found" />
          </div>
          <div className="columns">
            <div className="column is-6">
              {error ? (
                <>
                  <p data-testid="error-message">
                    <em>An error has occurred while searching for a refund.</em>
                  </p>
                  <p data-testid="error-reason">
                    <strong>
                      Reason:{' '}
                      <span className="error-message">
                        {error.message} ({error.response.data.Message})
                      </span>
                    </strong>
                  </p>
                </>
              ) : (
                <p data-testid="no-match">
                  <em>
                    No refund was found for hash{' '}
                    <strong>{match.params.hash}</strong>
                  </em>
                </p>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <GoBack
          history={history}
          defaultPath="/customer-refunds"
          defaultName="Refunds"
        />
        <div>
          <div className="column">
            <Header
              icon={ICONS.FILE}
              title={`Refund for ${refund.Account.FullName}`}
            />
          </div>
          <div className="columns">
            <div className="column is-6">
              <ListGroup title="Customer Refund">
                <ListGroupItem name="Refund Code" value={refund.Code} />
                <ListGroupItem
                  name="Account Hash"
                  value={
                    <Link to={`/accounts/${refund.Account.Hash}`}>
                      {refund.Account.FullName}
                    </Link>
                  }
                />
                <ListGroupItem
                  name="Address"
                  value={
                    <>
                      {refund.Address.Street}, {refund.Address.City},{' '}
                      {refund.Address.StateCode} {refund.Address.Zip}{' '}
                      {refund.Address.CountryCode}
                    </>
                  }
                />

                {refund.Transactions.map((tx, i) => {
                  return (
                    <ListGroupItem
                      key={i}
                      name={`Transaction ${i > 0 ? i + 1 : ''}`}
                      value={
                        <Link to={`/transactions/${tx.Hash}`}>
                          {tx.InvoiceCode}
                        </Link>
                      }
                    />
                  );
                })}

                <ListGroupItem
                  name="Contact Date"
                  value={formatDate(refund.ContactDate)}
                />
                <ListGroupItem
                  name="Issue Date"
                  value={formatDate(refund.IssueDate)}
                />
                <ListGroupItem name="Status" value={capitalize(refund.State)} />
                <ListGroupItem
                  name="Payment Method"
                  value={
                    REFUND_METHODS.find(
                      ({ value }) => value === refund.PaymentMethod
                    ).label
                  }
                />
                <ListGroupItem
                  name="Send Method"
                  value={sendMethods.find(
                    (method, i) => i === refund.SendMethod
                  )}
                />
                <ListGroupItem
                  name="Check Identifier"
                  value={refund.CheckIdentifier}
                />
                <ListGroupItem
                  name="Edit Refund"
                  value={
                    <button
                      onClick={() => setEditing(true)}
                      className="btn primary-btn-outline subpoena-activity"
                      data-testid="OpenRefundBtn"
                    >
                      <Icon icon={ICONS.EDIT} className="icon" size="16" />
                    </button>
                  }
                />
              </ListGroup>
            </div>
          </div>
          <Formik
            initialValues={{
              ContactDate: formatDate(refund.ContactDate, 'YYYY-MM-DD'),
              IssueDate: formatDate(refund.IssueDate, 'YYYY-MM-DD'),
              State: refund.State,
              PaymentMethod: refund.PaymentMethod,
              CheckIdentifier: refund.CheckIdentifier,
              RefundRate: refund.RefundRate,
              SendMethod: refund.SendMethod,
            }}
            validate={values => {
              let errors = {};
              if (!inRange(values.RefundRate, 0.0, 100.01)) {
                errors.RefundRate = 'is invalid percentage';
              }
              return errors;
            }}
            onSubmit={(values, actions) => patchRefund(values, actions)}
            enableReinitialize={true}
            children={({
              values,
              touched,
              errors,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <GenericModal
                modalIsOpen={editing}
                headerMsg="Edit Refund"
                btnAction={handleSubmit}
                btnText="Submit"
                isSubmitting={isSubmitting}
                closeModal={e => {
                  e.preventDefault();
                  setEditing(false);
                }}
              >
                <Form className="form-outline">
                  <section className="columns">
                    <div className="column">
                      <FormInput
                        name="ContactDate"
                        label="Contact Date"
                        errors={errors}
                        touched={touched}
                        type="date"
                        testID="ContactDate"
                      />
                    </div>
                    <div className="column">
                      <FormInput
                        name="IssueDate"
                        label="Issue Date"
                        errors={errors}
                        touched={touched}
                        type="date"
                        testID="IssueDate"
                      />
                    </div>
                  </section>
                  <section className="columns">
                    <div className="column">
                      <FormInput
                        name="RefundRate"
                        label="Refund Rate (%)"
                        type="number"
                        min="0"
                        max="100"
                        testID="RefundRate"
                      />
                    </div>
                    <div className="column">
                      <FormSelect
                        label="Status"
                        name="State"
                        placeholder="Select Status of Refund"
                        options={refundStates.map((item, i) => (
                          <option key={item} value={item}>
                            {capitalize(item)}
                          </option>
                        ))}
                        errors={errors}
                        touched={touched}
                        testID="State"
                      />
                    </div>
                  </section>
                  <section className="columns">
                    <div className="column is-4">
                      <FormSelect
                        label="Payment Method"
                        name="PaymentMethod"
                        placeholder="Select Refund Payment Method"
                        options={REFUND_METHODS.map(item => (
                          <option key={item.label} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                        errors={errors}
                        touched={touched}
                        type="number"
                        onChange={e =>
                          setFieldValue(
                            'PaymentMethod',
                            parseInt(e.target.value)
                          )
                        }
                        testID="PaymentMethod"
                      />
                    </div>
                    <div className="column is-4">
                      <FormSelect
                        label="Send Method"
                        name="SendMethod"
                        placeholder="Select Send Method"
                        options={sendMethods.map(item => (
                          <option key={item} value={item}>
                            {capitalize(item)}
                          </option>
                        ))}
                        errors={errors}
                        touched={touched}
                        testID="SendMethod"
                      />
                    </div>
                    {values.PaymentMethod === '4' && (
                      <div className="column">
                        <FormInput
                          name="CheckIdentifier"
                          label="Check Identifier"
                          errors={errors}
                          touched={touched}
                          type="text"
                          testID="CheckIdentifier"
                        />
                      </div>
                    )}
                  </section>
                </Form>
              </GenericModal>
            )}
          />
        </div>
      </>
    );
  }
};

export default SingleRefund;
