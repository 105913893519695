import { Decimal } from "decimal.js";

// convert a percentage to decimal
const percentToDecimal = (data) => {
  if (data >= 0) {
    let decimal = new Decimal(data).dividedBy(100).toString();
    return decimal;
  } else {
    return data;
  }
};

export default percentToDecimal;
