import React from "react";
// Components
import { EditBtn } from "../../Utilities/Components";
// Helpers - Formatters
import {
  formatPrice,
  formatFeePrice,
  formatPercent,
} from "../../Utilities/Formatters";
import { reqObjProp } from "../../Utilities/PropTypes";

const ProductDetails = (data) => {
  const {
    BaseRefPrice,
    SalePrice,
    MiningFee,
    Markup,
    Markdown,
    BaseQuantity,
    Collected,
    PaymentMethod,
  } = data.data;

  return (
    <React.Fragment>
      <EditBtn
        editing={null}
        triggerEdit={null}
        sectionTitle="Cash Breakdown"
      />
      <div className="form-outline inline-edit-form">
        <div className="columns">
          <div className="column form-row">
            <label className="item-label">Amount Collected: </label>
            <p>{formatPrice(Collected)}</p>
          </div>
          <div className="column form-row">
            <label className="item-label">Coin Units: </label>
            <p>{BaseQuantity}</p>
          </div>
        </div>

        <div className="columns">
          <div className="column form-row">
            <label className="item-label">Spot Price:</label>
            <p>{formatPrice(BaseRefPrice)}</p>
          </div>
          <div className="column form-row">
            <label className="item-label">Customer Price: </label>
            <p>{formatPrice(SalePrice)}</p>
          </div>
        </div>

        <div className="columns">
          <div className="column form-row">
            {Markdown ? (
              <React.Fragment>
                <label className="item-label">Markdown:</label>
                <p>{Markdown ? formatPercent(Markdown) : "-"}</p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <label className="item-label">Markup:</label>
                <p>{Markup ? formatPercent(Markup) : "-"}</p>
              </React.Fragment>
            )}
          </div>
          <div className="column form-row">
            <label className="item-label">Fiat Mining Fee: </label>
            <p>{MiningFee ? formatFeePrice(MiningFee * BaseRefPrice) : "-"}</p>
          </div>
        </div>

        <div className="columns">
          <div className="column form-row">
            <label className="item-label">Mining Fee: </label>
            <p>{MiningFee ? MiningFee : "-"}</p>
          </div>
          <div className="column form-row">
            <label className="item-label">Funding Source: </label>
            <p>{PaymentMethod ? PaymentMethod : "-"}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ProductDetails.propTypes = {
  data: reqObjProp,
};

export default ProductDetails;
