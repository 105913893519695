// Modules
import React, { Component } from "react";
import { Link } from "react-router-dom";
// Components
import UpdateID from "./UpdateID";
import { EditBtn, EmptyState } from "../../Utilities/Components";
import {
  accountProps,
  reqBoolProp,
  reqFuncProp,
} from "../../Utilities/PropTypes";
// Icons
import Icon from "../../Icons/Icon";
import { ICONS } from "../../Icons/IconsPath";

class ListIDs extends Component {
  state = {
    hidden: true,
  };

  toggle = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  render() {
    const { data, editing, postAccount, toggleEdit } = this.props;
    const { IDs, Account } = data;

    const { hidden } = this.state;
    return (
      <React.Fragment>
        {IDs.length > 0 && (
          <UpdateID
            data={IDs[0]}
            editing={editing}
            postAccount={postAccount}
            toggleEdit={toggleEdit}
          />
        )}
        {!hidden && (
          <React.Fragment>
            <h3>Additional IDs</h3>
            {IDs.slice(1).map((id) => (
              <UpdateID
                key={id.Hash}
                data={id}
                editing={editing}
                postAccount={postAccount}
                toggleEdit={toggleEdit}
              />
            ))}
          </React.Fragment>
        )}

        {IDs.length === 0 && (
          <React.Fragment>
            <EditBtn
              editing={null}
              triggerEdit={null}
              sectionTitle="ID Details"
            />
            <EmptyState msg="No IDs uploaded" />
            <Link
              id="addPhoneBtn"
              className="btn primary-btn column is-one-quarter-desktop has-text-centered"
              to={{
                pathname: "/accounts/upload-image",
                hash: Account.Hash,
                state: { account: Account },
              }}
            >
              Upload New ID
            </Link>
          </React.Fragment>
        )}

        {IDs.slice(1).length >= 1 && (
          <button onClick={this.toggle} className="toggle-button">
            <Icon
              icon={hidden ? ICONS.DOWNARROW : ICONS.UPARROW}
              color="#ffffff"
              size="20"
            />
          </button>
        )}
      </React.Fragment>
    );
  }
}

ListIDs.propTypes = {
  postAccount: reqFuncProp,
  editing: reqBoolProp,
  data: accountProps,
};

export default ListIDs;
