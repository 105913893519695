/* COMPONENT OVERVIEW
- Takes props from parent 
- Renders Account phone data
- Calls postAccount function prop & sends request to parent
*/
// Modules
import React from "react";
import { Formik, Form, Field } from "formik";
// Components
import { reqBoolProp, reqFuncProp, objProp } from "../../Utilities/PropTypes";
// Icons
import Icon from "../../Icons/Icon";
import { ICONS } from "../../Icons/IconsPath";

const PhoneForm = ({ editing, data, callBackFunc, phoneSubmitting }) => {
  const { Active, Hash, Phone, Preferred } = data;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        Active,
        Preferred,
        Phone,
      }}
      children={() => (
        <Form className="columns">
          <div className="column">
            <div className="form-row">
              {editing ? (
                <React.Fragment>
                  <label className="item-label">Phone:</label>
                  <p>{Phone}</p>
                  <label className="item-label">Preferred:</label>
                  <Field
                    name="Preferred"
                    type="checkbox"
                    checked={Preferred}
                    onClick={() => {
                      callBackFunc(
                        Hash,
                        Phone,
                        Active ? false : true,
                        Preferred ? false : true
                      );
                    }}
                    disabled={phoneSubmitting}
                  />
                  <label className="item-label">Active:</label>
                  <Field
                    name={`Active`}
                    type="checkbox"
                    checked={Active}
                    onClick={() => {
                      callBackFunc(
                        Hash,
                        Phone,
                        Active ? false : true,
                        Preferred ? true : false
                      );
                    }}
                    disabled={phoneSubmitting}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <label className="item-label">Phone:</label>
                  <p>{Phone}</p>
                  <label className="item-label">Preferred:</label>
                  <p>
                    {Preferred ? (
                      <Icon
                        icon={ICONS.ACTIVE}
                        rightMargin="5"
                        size="18"
                        color="#009245"
                      />
                    ) : (
                      <Icon
                        icon={ICONS.INACTIVE}
                        rightMargin="5"
                        size="15"
                        color="#e71d36"
                      />
                    )}
                  </p>
                  <label className="item-label">Active:</label>
                  <p>
                    {Active ? (
                      <Icon
                        icon={ICONS.ACTIVE}
                        rightMargin="5"
                        size="18"
                        color="#009245"
                      />
                    ) : (
                      <Icon
                        icon={ICONS.INACTIVE}
                        rightMargin="5"
                        size="15"
                        color="#e71d36"
                      />
                    )}
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
        </Form>
      )}
    />
  );
};

PhoneForm.propTypes = {
  callBackFunc: reqFuncProp,
  editing: reqBoolProp,
  data: objProp,
};

export default PhoneForm;
