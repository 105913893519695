// Modules
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
// Components
import Table from '../Table/Table';
import {
  GenericModal,
  GoBack,
  ToastError,
  ToastSuccess,
} from '../Utilities/Components';
// Helpers - Formatters
import { formatDate } from '../Utilities/Formatters';
import { objProp, strProp, arrProp } from '../Utilities/PropTypes';
// Configs
import { API } from '../Config/cfg.js';

const SubpoenaLinkedAccountsTable = props => {
  const { history } = props;
  //Grabs hash from params and remove # from string
  const subpoenaHash = history.location.hash.replace('#', '');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccountHash, setSelectedAccountHash] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const openModal = (accountHash, accountFullName) => {
    setModalOpen(true);
    setSelectedAccountHash(accountHash);
    setSelectedAccount(accountFullName);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const linkAccount = () => {
    setSubmitting(true);
    const url = `compliance/subpoenas/${subpoenaHash}/accounts`;
    const payload = { AccountHash: selectedAccountHash };
    API.put(url, payload)
      .then(res => {
        ToastSuccess('Account has been linked successfully');
        setModalOpen(false);
        history.goBack();
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <GoBack
        history={history}
        defaultPath={`/compliance/subpoenas/${subpoenaHash}`}
        defaultName="Subpoenas"
      />
      <Table
        history={props.history}
        columns={columnBuilder(openModal)}
        endpoint="accounts"
        filter={true}
        tablename="Link Account to Subpoena"
        nested
      />
      <GenericModal
        modalIsOpen={modalOpen}
        headerMsg="ATTENTION: Linking Account"
        btnMsg={`By clicking 'Link Account' ${selectedAccount}'s account will be added to this subpoena.`}
        btnText="Link Account"
        btnAction={linkAccount}
        closeModal={closeModal}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

SubpoenaLinkedAccountsTable.propTypes = {
  searchData: PropTypes.oneOfType([objProp, arrProp]),
  searchQuery: strProp,
};

const columnBuilder = openModal => [
  {
    Header: 'Customer Name',
    accessor: 'FullName',
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/accounts/${original.Hash}`}>
        {original.FullName}
      </Link>
    ),
  },
  {
    Header: 'Address',
    accessor: 'Address',
    Cell: ({ row: { original } }) =>
      `${original.Addresses[0].Street} ${original.Addresses[0].City}, ${original.Addresses[0].StateCode}`,
  },
  {
    Header: 'Document Serial',
    accessor: 'DocumentSerial',
    Cell: ({ row: { original } }) =>
      original.DocumentSerial ? original.DocumentSerial : '-',
  },
  {
    Header: 'DOB',
    accessor: 'DOB',
    Cell: ({ row: { original } }) => formatDate(original.DOB),
  },
  {
    Header: 'Phone',
    accessor: 'Phone',
    Cell: ({ row: { original } }) => original.PhoneNumbers[0].Phone,
  },
  {
    Header: 'Link to Subpoena',
    accessor: 'DocumentSerials',
    Cell: ({ row: { original } }) => (
      <button
        className="column-btn create"
        onClick={() => openModal(original.Hash, original.FullName)}
      >
        Link Account
      </button>
    ),
  },
];

export default SubpoenaLinkedAccountsTable;
