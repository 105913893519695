import React from "react";
import Can from "./../Permissions/Can";
import NavDropdown from "./NavDropdown";

const NavLinks = ({ routes, navComponent, onClick }) => {
  return routes.map(link => {
    if (link.children) {
      return (
        <NavDropdown
          key={link.path}
          title={link.name}
          link={link}
          routes={routes}
          navComponent={navComponent}
          onClick={onClick}
        />
      );
    } else if (link.permission) {
      return (
        <Can key={link.path} do={link.permission} onClick={onClick}>
          {navComponent(link, onClick)}
        </Can>
      );
    } else {
      return navComponent(link, onClick);
    }
  });
};

export default NavLinks;
