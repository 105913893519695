import React from "react";
import Icon from "../Icons/Icon";
import { ICONS } from "../Icons/IconsPath";

export default function Pagination({
  canPreviousPage,
  canNextPage,
  currentPage,
  pageOptions,
  nextPage,
  previousPage,
  setPageSize,
  setPage,
  autoRefresh,
  setAutoRefresh,
  setTablePageSize,
  state: { pageIndex, pageSize },
  tableLoading,
}) {
  const pageCounter = () => {
    const index = pageIndex + 1 > pageOptions.length ? "1" : currentPage + 1;
    return `${index} of ${pageOptions.length}`;
  };

  const canPaginate = (value) => {
    let direction = value === 1 ? canNextPage : canPreviousPage;
    return !direction || pageIndex + 1 > pageOptions.length || tableLoading;
  };

  return (
    <div className="-pagination">
      <div className="-previous">
        <button
          className="-btn"
          onClick={() => {
            if (autoRefresh) {
              setAutoRefresh(false, 0);
            }
            previousPage();
            return setPage(pageIndex - 1);
          }}
          disabled={canPaginate(-1)}
        >
          <Icon icon={ICONS.LEFTARROW} color="#fff" size="17" />
        </button>
      </div>
      <div className="-center">
        <span>
          Page <strong>{pageCounter()}</strong>
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setTablePageSize(Number(e.target.value));
          }}
        >
          {[25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className="-next">
        <button
          className="-btn"
          onClick={() => {
            if (autoRefresh) {
              setAutoRefresh(false, 0);
            }
            nextPage();
            return setPage(pageIndex + 1);
          }}
          disabled={canPaginate(1)}
        >
          <Icon icon={ICONS.RIGHTARROW} color="#fff" size="17" />
        </button>
      </div>
    </div>
  );
}
