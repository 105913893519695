// Modules
import React, { Component } from 'react';
// Components
import { Loading } from '../Utilities/Components';
import { FormInput, FormSelect } from '../Forms/FormInputs';
import { GenericForm, FormikContext } from '../Forms/Form';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// Configs
import { API } from '../Config/cfg.js';

class EmployeeInvite extends Component {
  state = {
    errors: {},
    groupList: undefined,
    loading: true,
  };

  componentDidMount() {
    this.getGroups();
  }

  getGroups = () => {
    API.get(`/groups?all=true`)
      .then(res => {
        this.setState({
          groupList: res.data.Data,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          errors: error,
        });
      });
  };

  render() {
    const { errors, groupList, loading } = this.state;
    const { history } = this.props;

    if (loading) {
      return <Loading errors={errors} />;
    }

    return (
      <GenericForm
        endpoint="invitations/employee"
        setValues={{
          FirstName: '',
          LastName: '',
          Email: '',
          PhoneNumber: '',
          GroupHash: '',
          PortalAccess: true,
        }}
        formTitle="Create Employee Invite"
        subTitle="Invitee Details"
        goBack={{
          show: true,
          setHistory: history,
          setDefaultPath: '/accounts',
          setDefaultName: 'Accounts',
        }}
        toastMsg="A new employee account has been created"
        customValidations={values => {
          let errors = {};
          for (let [key, value] of Object.entries(values)) {
            if (!value) {
              if (key === 'PortalAccess') {
                continue;
              } else {
                errors[key] = 'is required';
              }
            }
          }
          return errors;
        }}
      >
        <FormikContext.Consumer>
          {props => (
            <div>
              <div className="columns">
                <div className="column">
                  <FormSelect
                    label="Parent Group"
                    name="GroupHash"
                    errors={props.errors}
                    touched={props.touched}
                    placeholder="Select Parent Group"
                    options={
                      <React.Fragment>
                        {groupList.map(item => (
                          <option key={item.Hash} value={item.Hash}>
                            {item.Name}
                          </option>
                        ))}
                      </React.Fragment>
                    }
                  />
                </div>
                <div className="column">
                  <FormInput
                    label="First Name"
                    name="FirstName"
                    type="text"
                    placeholder="John"
                    errors={props.errors}
                    touched={props.touched}
                  />
                </div>
                <div className="column">
                  <FormInput
                    label="Last Name"
                    name="LastName"
                    type="text"
                    placeholder="Doe"
                    errors={props.errors}
                    touched={props.touched}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <FormInput
                    label="Email"
                    name="Email"
                    type="email"
                    placeholder="example@digitalmint.io"
                    errors={props.errors}
                    touched={props.touched}
                  />
                </div>
                <div className="column">
                  <FormInput
                    label="Phone Number"
                    name="PhoneNumber"
                    type="phone"
                    placeholder="1234567890"
                    errors={props.errors}
                    touched={props.touched}
                  />
                </div>
                <div
                  className="column"
                  data-tip="Grants portal login access & e-mails a new account invitation"
                  data-for="portalaccess"
                >
                  <FormInput
                    label="Portal Login Access"
                    name="PortalAccess"
                    type="checkbox"
                    errors={props.errors}
                    touched={props.touched}
                  />
                  <ReactTooltip
                    id="portalaccess"
                    place="bottom"
                    effect="solid"
                  />
                </div>
              </div>
            </div>
          )}
        </FormikContext.Consumer>
      </GenericForm>
    );
  }
}

export default EmployeeInvite;
