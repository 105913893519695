import React from "react";
import { Link } from "react-router-dom";
import Linkify from "react-linkify";
// Components
import Table from "./../Table/Table";
import { setMsgType } from "./NoteType";
// Helpers - Formatters
import { formatDateTime } from "../Utilities/Formatters";
// Permissions
import {
  ACCOUNT_EDIT,
  TX_EDIT,
  LOCATIONS_EDIT,
} from "./../Permissions/Permissions";

const NotesTable = ({
  history,
  table,
  txHash = null,
  accountHash,
  locationHash,
}) => {
  let endpoint = `accounts/${accountHash}`;
  let action = {
    name: "Create Note",
    link: "/create/account-note",
    params: accountHash,
    permission: ACCOUNT_EDIT,
  };

  if (locationHash) {
    endpoint = `locations/${locationHash}`;
    action = {
      name: "Create Note",
      link: "/create/location-note",
      params: locationHash,
      permission: LOCATIONS_EDIT,
    };
  }

  if (txHash) {
    endpoint = `transactions/${txHash}`;
    action = {
      name: "Create Note",
      link: "/create/tx-note",
      params: accountHash,
      permission: TX_EDIT,
      state: {
        TransactionHash: txHash,
      },
    };
  }
  return (
    <Table
      table={table}
      history={history}
      filter={true}
      columns={columnBuilder}
      tablename={table}
      nested={true}
      endpoint={`${endpoint}/notes`}
      actions={[action]}
    />
  );
};

const columnBuilder = [
  {
    Header: "Date",
    width: 100,
    Cell: ({ row: { original } }) => formatDateTime(original.Timestamp),
    sortMethod: "Timestamp",
  },
  {
    Header: "Author",
    width: 100,
    Cell: ({ row: { original } }) => {
      return (
        <div>
          <div style={{ fontWeight: "bold" }}>{original.Source}</div>
          <i>
            {original.ComplianceOperator
              ? original.ComplianceOperator.Name
              : "-"}
          </i>
        </div>
      );
    },
    sortMethod: "Source",
  },
  {
    minWidth: 50,
    Header: "References",
    Cell: ({ row: { original } }) => {
      var tx =
        original.Transaction != null ? (
          <div>
            <Link to={`/transactions/${original.Transaction.Hash}`}>
              Transaction {original.Transaction.InvoiceCode}
            </Link>
          </div>
        ) : (
          ""
        );
      var inv = original.ComplianceInvestigation ? (
        <div>
          <Link
            to={`/compliance/investigations/${original.ComplianceInvestigation.Hash}`}
          >
            Investigation {original.ComplianceInvestigation.Code}
          </Link>
        </div>
      ) : (
        ""
      );
      if (tx || inv) {
        return (
          <span>
            <div>{tx}</div>
            <div>{inv}</div>
          </span>
        );
      } else {
        return "-";
      }
    },
  },
  {
    width: 100,
    Header: "Type",
    Cell: ({ row: { original } }) => setMsgType(original.Type),
    sortMethod: "Type",
  },
  {
    minWidth: 360,
    Header: "Note",
    Cell: ({ row: { original } }) => (
      <Linkify>
        <div style={{ whiteSpace: "pre-line" }}>{original.Note}</div>
      </Linkify>
    ),
    sortMethod: "Note",
  },
];

export default NotesTable;
