// Modules
import React from "react";
import columnBuilder from "./columnBuilder";
// Components
import Table from "./../Table/Table";
// Helpers - Formatters
import { formatGroupCommissions } from "../Utilities/Formatters";
// Permissions
import { GROUPS_CREATE } from "./../Permissions/Permissions";

const Groups = (props) => {
  return (
    <Table
      history={props.history}
      columns={columnBuilder(formatGroupCommissions)}
      endpoint="groups"
      filter={true}
      tablename="Groups"
      actions={[
        {
          name: "Create New Group",
          link: "/groups/create-group",
          permission: GROUPS_CREATE,
        },
      ]}
      customize
    />
  );
};

export default Groups;
