// Modules
import React from "react";
// Components
import { FormInput, FormSelect } from "../Forms/FormInputs";
import { GenericForm, FormikContext } from "../Forms/Form";
import { RatesOptions } from "../Utilities/Components";

const CustomerInvite = ({ history }) => {
  const rates = <RatesOptions from={4} to={24} />;
  return (
    <GenericForm
      endpoint="invitations/customer"
      setValues={{
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        DOB: "",
        Rates: "",
      }}
      formTitle="Create Customer Invite"
      subTitle="Invitee Details"
      goBack={{
        show: true,
        setHistory: history,
        setDefaultPath: "/accounts",
        setDefaultName: "Accounts",
      }}
      toastMsg="A new customer invite has been sent"
    >
      <FormikContext.Consumer>
        {(props) => (
          <>
            <div className="columns">
              <div className="column">
                <FormInput
                  label="First Name"
                  name="FirstName"
                  type="text"
                  placeholder="John"
                  errors={props.errors}
                  touched={props.touched}
                />
              </div>
              <div className="column">
                <FormInput
                  label="Last Name"
                  name="LastName"
                  type="text"
                  placeholder="Doe"
                  errors={props.errors}
                  touched={props.touched}
                />
              </div>
              <div className="column">
                <FormInput
                  errors={props.errors}
                  touched={props.touched}
                  label="Date of Birth"
                  name="DOB"
                  type="date"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <FormInput
                  label="Phone Number"
                  name="PhoneNumber"
                  type="phone"
                  placeholder="1234567890"
                  errors={props.errors}
                  touched={props.touched}
                />
              </div>
              <div className="column">
                <FormSelect
                  label="Markup Rate"
                  name="Rates"
                  placeholder="Select Markup Rate"
                  options={rates}
                  errors={props.errors}
                  touched={props.touched}
                />
              </div>
              <div className="column"></div>
            </div>
          </>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

export default CustomerInvite;
