import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { API } from '../../Config/cfg.js';
import {
  GoBack,
  Header,
  Loading,
  GenericModal,
  ToastSuccess,
} from '../../Utilities/Components';
import { FormInput, FormSelect } from '../../Forms/FormInputs';
import { ListGroup, ListGroupItem } from '../../Utilities/Components';
import Icon from '../../Icons/Icon';
import { ICONS } from '../../Icons/IconsPath';
import { formatDate } from '../../Utilities/Formatters';

const DECISIONS = [
  { label: 'False Positive', value: 0 },
  { label: 'Confirmed', value: 1 },
];

const SinglePEP = ({
  match: {
    params: { hash },
  },
  history,
}) => {
  const [data, setData] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!data) {
      API.get(`/compliance/peps/${hash}`)
        .then(res => {
          setData(res.data);
        })
        .catch(error => setErrors(error.response.data))
        .finally(() => {
          setLoading(false);
          setIsSubmitting(false);
        });
    }
  }, [data, hash]);

  const postPEP = values => {
    setIsSubmitting(true);
    values.Decision = parseInt(values.Decision);
    API.post(`/compliance/peps/${hash}`, values)
      .then(() => {
        history.push('/compliance/peps');
        ToastSuccess('Decision is has been submitted');
      })
      .catch(error => setErrors(error.response.data))
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (loading) {
    return <Loading errors={errors} />;
  }

  return (
    <>
      <GoBack
        history={history}
        defaultPath="/compliance/peps"
        defaultName="PEPs"
      />
      <div>
        <div className="column">
          <Header icon={ICONS.FILE} title={data?.Code} />
        </div>
        <>
          <div className="columns">
            <div className="column is-6">
              <ListGroup title="Politically Exposed Person">
                <ListGroupItem name="First Name" value={data?.FirstName} />
                <ListGroupItem name="Last Name" value={data?.LastName} />
                <ListGroupItem
                  name="Created At"
                  value={formatDate(data?.CreatedAt)}
                />
                <ListGroupItem name="Decision" value={data?.Decision} />
                <ListGroupItem
                  name="Country Of Origin"
                  value={data?.Country.Name}
                />
                <ListGroupItem name="Narrative" value={data?.Narrative} />
                <ListGroupItem
                  name="Edit Decision"
                  value={
                    <button
                      onClick={() => setEditing(true)}
                      className="btn primary-btn-outline subpoena-activity"
                    >
                      <Icon icon={ICONS.EDIT} className="icon" size="16" />
                    </button>
                  }
                />
              </ListGroup>
            </div>
          </div>
        </>
      </div>
      <Formik
        initialValues={{
          Decision: '',
          Narrative: '',
        }}
        onSubmit={async values => {
          await postPEP(values);
        }}
        children={({ values, touched, errors, handleSubmit }) => (
          <GenericModal
            modalIsOpen={editing}
            headerMsg="Edit Decision"
            btnAction={() => postPEP(values)}
            validate={values => {
              let errors = {};

              if (!values.Decision) {
                errors.Decision = 'is required';
              }

              return errors;
            }}
            btnText="Submit"
            isSubmitting={isSubmitting}
            closeModal={() => {
              setEditing(false);
            }}
          >
            <Form className="form-outline">
              <div className="columns is-vcentered">
                <div className="column">
                  <FormSelect
                    errors={errors}
                    touched={touched}
                    label="Decision"
                    name="Decision"
                    placeholder="Select a Decision"
                    options={
                      <>
                        {DECISIONS.map((item, i) => (
                          <option key={item.label} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </>
                    }
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    label="Narrative"
                    name="Narrative"
                    type="text"
                    component="textarea"
                    placeholder="Enter Narrative"
                  />
                </div>
              </div>
            </Form>
          </GenericModal>
        )}
      ></Formik>
    </>
  );
};

export default SinglePEP;
