// Modules
import React from 'react';
// Components
import { Contacts } from '../../Utilities/Components';
import { EditableForm } from '../../Forms/EditableForm';
import { EditableCheckbox, EditableInput } from '../../Forms/EditableInputs';
// PropTypes
import { reqFuncProp, reqObjProp, boolProp } from '../../Utilities/PropTypes';
// Permissions
import { LOCATIONS_EDIT } from '../../Permissions/Permissions';

const Compliance = ({
  data,
  editing,
  toggleEdit,
  postLocation,
  isSubmitting,
}) => {
  const callPostLocation = (req, actions) => {
    // Sends req to POST call in parent
    postLocation(
      'locations',
      data.Hash,
      null,
      req,
      'editingCompliance',
      actions
    );
  };

  const { ComplianceContact, HIDTA, HIFCA } = data;

  return (
    <EditableForm
      callBack={callPostLocation}
      setValues={{
        ComplianceContact,
        HIDTA,
        HIFCA,
      }}
      customValidations={values => {
        const errors = {};

        return errors;
      }}
      editing={editing}
      permission={LOCATIONS_EDIT}
      triggerEdit={toggleEdit}
      editType="editingCompliance"
      sectionTitle="Details"
      isSubmitting={isSubmitting}
    >
      <div className="columns">
        <div className="column">
          <EditableCheckbox
            name="HIDTA"
            label="HIDTA"
            type="text"
            currentValue={HIDTA}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableCheckbox
            name="HIFCA"
            label="HIFCA"
            type="text"
            currentValue={HIFCA}
            editing={editing}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6">
          <EditableInput
            name="ComplianceContact"
            label="Compliance Contact"
            type="text"
            currentValue={<Contacts contact={ComplianceContact} />}
            editing={editing}
          />
        </div>
      </div>
    </EditableForm>
  );
};

Compliance.propTypes = {
  postLocation: reqFuncProp,
  data: reqObjProp,
  editing: boolProp,
};

export default Compliance;
