// Modules
import React from "react";
import { PropTypes } from "prop-types";
// Components
import Table from "../Table/Table";
import transactionsColumnBuilder from "../Transactions/columnBuilder";
// Helpers - Formatters
import { capitalizeFirstLetter } from "../Utilities/Formatters";
import { objProp, strProp, arrProp } from "../Utilities/PropTypes";
// Permissions
import { PermissionsContext } from "../Permissions/PermissionContext";

const TransactionResultsTable = (props) => {
  const {
    location: { state },
  } = props;
  return (
    <PermissionsContext.Consumer>
      {(userPermiss) => (
        <Table
          columns={transactionsColumnBuilder(userPermiss, "/transactions")}
          endpoint={`search?q=${encodeURI(state.searchTerm)}&t=${
            state.searchType
          }`}
          tablename={`${capitalizeFirstLetter(state.searchType)} results for "${
            state.searchTerm
          }"`}
        />
      )}
    </PermissionsContext.Consumer>
  );
};

TransactionResultsTable.propTypes = {
  searchData: PropTypes.oneOfType([objProp, arrProp]),
  searchQuery: strProp,
};

export default TransactionResultsTable;
