// Modules
import React from "react";
// Components
import { GenericForm, FormikContext } from "../../../Forms/Form";
import { FormInput, FormSelect } from "../../../Forms/FormInputs";
import { reqObjProp, reqStrProp } from "../../../Utilities/PropTypes";
// Data
import StateLists from "../../../Utilities/Data/StatesList";

const paymentOpts = [
  { name: "ACH", value: "ACH" },
  { name: "Check", value: "Check" },
];

const AddCommissionPayment = (props) => {
  const { location, hash, history } = props;
  const { account } = location.state;
  return (
    <GenericForm
      endpoint={`group/${hash}`}
      postReq={true}
      setValues={{
        PaymentType: "ACH",
        RoutingNumber: undefined,
        AccountNumber: undefined,
        PayableTo: undefined,
        Street: undefined,
        Street2: undefined,
        City: undefined,
        StateCode: undefined,
        Zip: undefined,
      }}
      formTitle="Add Group Commissions"
      subTitle={`${account.Name}'s Commission Settings`}
      goBack={{
        show: true,
        setHistory: history,
        setDefaultPath: "/accounts",
        setDefaultName: "Accounts",
      }}
      customValidations={(values) => {
        let errors = {};
        if (!values.PaymentType) {
          errors.PaymentType = "is required";
        }
        if (!values.PayableTo) {
          errors.PayableTo = "is required";
        }
        if (values.PaymentType === "ACH" && !values.RoutingNumber) {
          errors.RoutingNumber = "is required";
        }
        if (values.PaymentType === "ACH" && !values.AccountNumber) {
          errors.AccountNumber = "is required";
        }

        if (values.PaymentType === "Check" && !values.City) {
          errors.City = "is required";
        }
        if (values.PaymentType === "Check" && !values.StateCode) {
          errors.StateCode = "is required";
        }
        if (values.PaymentType === "Check" && !values.Zip) {
          errors.Zip = "is required";
        }
        if (values.PaymentType === "Check" && !values.Street) {
          errors.Street = "is required";
        }
        return errors;
      }}
      toastMsg="Commission settings saved"
    >
      <FormikContext.Consumer>
        {(props) => (
          <div className="columns">
            <div className="column is-4">
              <FormSelect
                label="Payment Type"
                name="PaymentType"
                type="text"
                errors={props.errors}
                touched={props.touched}
                placeholder="Select Payment Type"
                options={
                  <React.Fragment>
                    {paymentOpts.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </React.Fragment>
                }
              />

              {props.values.PaymentType === "ACH" ? (
                <AchInputs values={props} />
              ) : (
                <CheckInputs values={props} />
              )}
            </div>
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

const AchInputs = ({ values }) => (
  <React.Fragment>
    <FormInput
      label="Payable To"
      name="PayableTo"
      type="text"
      placeholder="John Doe"
      errors={values.errors}
      touched={values.touched}
    />
    <FormInput
      label="Routing Number"
      name="RoutingNumber"
      type="number"
      component="input"
      placeholder="011103093"
      errors={values.errors}
      touched={values.touched}
    />
    <FormInput
      label="Account Number"
      name="AccountNumber"
      type="number"
      component="input"
      placeholder="091000022"
      errors={values.errors}
      touched={values.touched}
    />
  </React.Fragment>
);

const CheckInputs = ({ values }) => (
  <React.Fragment>
    <FormInput
      label="Payable To"
      name="PayableTo"
      type="text"
      placeholder="John Doe"
      errors={values.errors}
      touched={values.touched}
    />
    <FormInput
      label="Street Address"
      name="Street"
      type="text"
      placeholder="Enter Address Street"
      errors={values.errors}
      touched={values.touched}
    />

    <FormInput
      label="Street Address 2"
      name="Street2"
      type="text"
      placeholder="Enter Address Street (optional)"
    />

    <FormInput
      label="City"
      name="City"
      type="text"
      placeholder="Enter City"
      errors={values.errors}
      touched={values.touched}
    />

    <FormSelect
      label="State"
      name="StateCode"
      errors={values.errors}
      touched={values.touched}
      placeholder="State"
      options={StateLists.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    />
    <FormInput
      label="Postal Code"
      name="Zip"
      type="number"
      placeholder="00000"
      errors={values.errors}
      touched={values.touched}
    />
  </React.Fragment>
);

AddCommissionPayment.propTypes = {
  hash: reqStrProp,
  location: reqObjProp,
};

export default AddCommissionPayment;
