export const InvestigationActivityTypes = [
  { value: 0, display: "Legacy SAR Filing" },
  { value: 1, display: "Unknown Source of Funds" },
  { value: 2, display: "Unknown Source of Funds - Money Mule" },
  { value: 3, display: "Scam Victim - Relationship" },
  { value: 4, display: "Scam Victim - Investment" },
  { value: 5, display: "Scam Victim - Government" },
  { value: 6, display: "Illicit Activity - Dark Web Marketplace" },
  { value: 7, display: "Fraudulent ID Presented" },
  { value: 8, display: "Structuring" },
  { value: 9, display: "Negative News Detected" },
  { value: 10, display: "Transactions Out of Pattern for Customer" },
  { value: 11, display: "Use of Multiple Accounts to Evade Limit or Ban" },
  { value: 12, display: "Continuation of Prior Activity" },
  { value: 14, display: "Unregistered MSB Activity" },
  { value: 15, display: "Potential Human Trafficking" },
  { value: 16, display: "Ransomware Settlement" },
  { value: 17, display: "Potential Terrorist Financing" },
  { value: 13, display: "Other" },
];
