// Modules
import React from "react";
// Components
import { GenericForm, FormikContext } from "../Forms/Form";
import { FormInput } from "../Forms/FormInputs";

const ServiceAccount = (props) => {
  return (
    <GenericForm
      endpoint="accounts"
      setValues={{
        Email: "",
        PhoneNumber: "",
      }}
      formTitle="Create Service Account"
      subTitle={null}
      goBack={{
        show: true,
        setHistory: props.history,
        setDefaultPath: "/accounts",
        setDefaultName: "Accounts",
      }}
      toastMsg="Service Account has been successfully created"
    >
      <FormikContext.Consumer>
        {(props) => (
          <div className="columns">
            <div className="column">
              <FormInput
                errors={props.errors}
                touched={props.touched}
                label="Email"
                name="Email"
                type="text"
                placeholder="example@digitalmint.io"
              />
            </div>
            <div className="column">
              <FormInput
                errors={props.errors}
                touched={props.touched}
                label="Phone Number"
                name="PhoneNumber"
                type="phone"
                placeholder="1234567890"
              />
            </div>
            <div className="column"></div>
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

export default ServiceAccount;
