import React from "react";

const TextFilterValue = ({
  type,
  valueLabel,
  filterValue,
  filterKey,
  setFilterValue
}) => {
  return (
    <React.Fragment>
      {valueLabel}
      <input
        type={type !== "string" ? type : "text"}
        value={filterValue}
        onChange={setFilterValue}
        disabled={!filterKey ? true : false}
      />
    </React.Fragment>
  );
};

export default TextFilterValue;
