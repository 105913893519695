// Modules
import React from "react";
// Components
import { GenericForm, FormikContext } from "../Forms/Form";
import { FormInput } from "../Forms/FormInputs";
import { reqStrProp, reqObjProp } from "../Utilities/PropTypes";

const DenyID = ({ location, hash, history }) => {
  const { account } = location.state;
  return (
    <React.Fragment>
      <GenericForm
        endpoint={`compliance/ids/${hash}/invalidate`}
        postReq={true}
        setValues={{
          Note: ""
        }}
        formTitle="Deny Account ID"
        subTitle={`${account.FirstName} ${account.LastName}`}
        goBack={{
          show: true,
          setHistory: history,
          setDefaultPath: "/accounts",
          setDefaultName: "Accounts"
        }}
        toastMsg="Account has been successfully banned"
      >
        <div className="columns">
          <p className="column is-2">
            <span className="item-label">ID Type: </span>
            {account.Type ? account.Type : "-"}
          </p>
          <p className="column">
            <span className="item-label">ID Serial: </span>
            {account.DocumentSerial}
          </p>
        </div>
        <FormikContext.Consumer>
          {props => (
            <div className="columns">
              <div className="column is-5">
                <FormInput
                  label="Note"
                  name="Note"
                  component="textarea"
                  placeholder="Enter Denial Explanation..."
                  errors={props.errors}
                  touched={props.touched}
                />
              </div>
            </div>
          )}
        </FormikContext.Consumer>
      </GenericForm>
    </React.Fragment>
  );
};

DenyID.propTypes = {
  hash: reqStrProp,
  location: reqObjProp
};

export default DenyID;
