// Modules
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// Components
import Table from './../../../Table/Table';
import {
  GenericModal,
  ToastError,
  ToastSuccess,
} from '../../../Utilities/Components';
// Helpers - Formatters
import { formatDate } from '../../../Utilities/Formatters';
// Permissions
import { COMPLIANCE_INVEST_ACTION } from '../../../Permissions/Permissions';
// Configs
import { API } from '../../../Config/cfg.js';

const LinkedAccounts = ({ hash }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccountHash, setSelectedAccountHash] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [callAPI, setCallAPI] = useState(false);

  const openModal = (accountHash, accountFullName) => {
    setModalOpen(true);
    setSelectedAccountHash(accountHash);
    setSelectedAccount(accountFullName);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const removeLinkAccount = () => {
    setSubmitting(true);
    const url = `compliance/investigations/${hash}/accounts`;
    const payload = { AccountHash: selectedAccountHash };
    API.delete(url, { data: payload })
      .then(res => {
        ToastSuccess('Account has been removed successfully');
        setModalOpen(false);
        setCallAPI(true);
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Table
        columns={columnBuilder(openModal)}
        endpoint={`compliance/investigations/${hash}/accounts`}
        tablename="Linked Accounts"
        triggerAPI={callAPI}
        nested
        actions={[
          {
            name: 'Link New Account',
            link: '/investigations/linked-accounts',
            params: hash,
            state: { fromLocation: 'Investigation' },
            permission: COMPLIANCE_INVEST_ACTION,
          },
        ]}
      />

      <GenericModal
        modalIsOpen={modalOpen}
        headerMsg="ATTENTION: Removing Account"
        btnMsg={`By clicking 'Remove Account' ${selectedAccount}'s account will be removed from this investigation.`}
        btnText="Remove Account"
        btnAction={removeLinkAccount}
        closeModal={closeModal}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const columnBuilder = openModal => [
  {
    Header: 'Customer Name',
    accessor: 'FullName',
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/accounts/${original.Hash}`}>
        {original.FullName}
      </Link>
    ),
  },
  {
    Header: 'Address',
    accessor: 'Address',
    Cell: ({ row: { original } }) =>
      original.Addresses
        ? `${original.Addresses[0].Street} ${original.Addresses[0].City}, ${original.Addresses[0].StateCode}`
        : '-',
  },
  {
    Header: 'Document Serial',
    accessor: 'DocumentSerial',
    Cell: ({ row: { original } }) =>
      original.DocumentSerial ? original.DocumentSerial : '-',
  },
  {
    Header: 'DOB',
    accessor: 'DOB',
    Cell: ({ row: { original } }) => formatDate(original.DOB),
  },
  {
    Header: 'Phone',
    accessor: 'Phone',
    Cell: ({ row: { original } }) => original.PhoneNumbers[0].Phone,
  },
  {
    Header: 'Remove Account',
    accessor: 'Remove Account',
    Cell: ({ row: { original } }) => (
      <button
        className="column-btn remove"
        onClick={() => openModal(original.Hash, original.FullName)}
      >
        Remove
      </button>
    ),
  },
];

export default LinkedAccounts;
