const alertKeys = {
  "Account.Hash": {
    Label: "Account Hash",
    Type: "string",
  },
  "Country.Code": {
    Label: "Country",
    Type: "string",
    Options: [
      {
        Display: "United States",
        Value: "US",
      },
      {
        Display: "Canada",
        Value: "CA",
      },
    ],
  },
  CreatedAt: {
    Label: "Date Generated",
    Type: "daterange",
  },
  Disposition: {
    Label: "Disposition",
    Type: "int",
    Options: [
      {
        Display: "90 Day Review",
        Value: 1,
      },
      {
        Display: "Case Review In Progress",
        Value: 4,
      },
      {
        Display: "Data Quality",
        Value: 3,
      },
      {
        Display: "Escalated",
        Value: 2,
      },
      {
        Display: "False Positive",
        Value: 0,
      },
    ],
  },
  DueDate: {
    Label: "Due Date",
    Type: "daterange",
  },
  "Account.FirstName": {
    Label: "First Name",
    Type: "string",
  },
  "Account.LastName": {
    Label: "Last Name",
    Type: "string",
  },
  UpdatedAt: {
    Label: "Last Update Date",
    Type: "daterange",
  },
  Scenario: {
    Label: "Scenario",
    Type: "int",
    Options: [
      {
        Display: "Volume",
        Value: 0,
      },
      {
        Display: "Structuring",
        Value: 1,
      },
      {
        Display: "Spike",
        Value: 2,
      },
      {
        Display: "Excessive",
        Value: 3,
      },
      {
        Display: "Structuring Monthly",
        Value: 4,
      },
      {
        Display: "OTC over $10K",
        Value: 5,
      },
      {
        Display: "OTC and BTM Customer",
        Value: 6,
      },
    ],
  },
  "AssignedTo.FullName": {
    Label: "Assigned To",
    Type: "string",
  },
  Status: {
    Label: "Status",
    Type: "int",
    Options: [
      {
        Display: "Closed",
        Value: 0,
      },
      {
        Display: "Open",
        Value: 1,
      },
      {
        Display: "Pending 90 Day Review",
        Value: 2,
      },
    ],
  },
};
export default alertKeys;
