// Modules
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// Components
import Table from './../../../Table/Table';
import {
  GenericModal,
  ToastError,
  ToastSuccess,
} from '../../../Utilities/Components';
// Helpers - Formatters
import { formatDate } from '../../../Utilities/Formatters';
// Icons
import Icon from '../../../Icons/Icon';
import { ICONS } from '../../../Icons/IconsPath';
// Permissions
import { COMPLIANCE_SUBPOENA_ACTION } from '../../../Permissions/Permissions';
// Configs
import { API } from '../../../Config/cfg.js';

const LinkedAccounts = ({ hash }) => {
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [selectedAccountHash, setSelectedAccountHash] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [selectedSubpoenaHash, setSelectedSubpoenaHash] = useState();
  const [sarModalOpen, setSarModalOpen] = useState(false);
  const [sarFiled, setSarFiled] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [callAPI, setCallAPI] = useState(false);

  const openAccountModal = (subpoenaHash, accountHash, accountFullName) => {
    setAccountModalOpen(true);
    setSelectedSubpoenaHash(subpoenaHash);
    setSelectedAccountHash(accountHash);
    setSelectedAccount(accountFullName);
  };

  const closeAccountModal = e => {
    e.preventDefault();
    setAccountModalOpen(false);
  };

  const removeLinkAccount = () => {
    setSubmitting(true);
    const url = `compliance/subpoenas/${hash}/accounts`;
    const payload = {
      AccountHash: selectedAccountHash,
      SubpoenaAccountHash: selectedSubpoenaHash,
    };
    API.delete(url, { data: payload })
      .then(res => {
        ToastSuccess('Account has been removed successfully');
        setAccountModalOpen(false);
        // NOT IDEAL, but given the deadline and resources this is a quick fix
        setTimeout(() => {
          setCallAPI(true);
        }, 1500);
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const updateSar = () => {
    setSubmitting(true);
    const url = `compliance/subpoenas/${hash}/accounts`;
    const payload = {
      AccountHash: selectedAccountHash,
      SubpoenaAccountHash: selectedSubpoenaHash,
      SARFiled: sarFiled === true ? false : true,
    };
    API.post(url, payload)
      .then(res => {
        ToastSuccess('Account SAR Filed status has been updated successfully');
        setSarModalOpen(false);
        // NOT IDEAL, but given the deadline and resources this is a quick fix
        setTimeout(() => {
          setCallAPI(true);
        }, 1500);
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const openSARModal = (
    subpoenaHash,
    accountHash,
    accountFullName,
    SARFiled
  ) => {
    setSarModalOpen(true);
    setSelectedSubpoenaHash(subpoenaHash);
    setSelectedAccountHash(accountHash);
    setSelectedAccount(accountFullName);
    setSarFiled(SARFiled);
  };

  const closeSARModal = e => {
    e.preventDefault();
    setSarModalOpen(false);
  };

  return (
    <>
      <Table
        columns={columnBuilder(openAccountModal, openSARModal)}
        endpoint={`compliance/subpoenas/${hash}/accounts`}
        tablename="Linked Accounts"
        triggerAPI={callAPI}
        nested
        actions={[
          {
            name: 'Link New Account',
            link: '/subpoenas/linked-accounts',
            params: hash,
            state: { fromLocation: 'Subpoenas' },
            permission: COMPLIANCE_SUBPOENA_ACTION,
          },
        ]}
      />

      <GenericModal
        modalIsOpen={accountModalOpen}
        headerMsg="ATTENTION: Removing Account"
        btnMsg={`By clicking 'Remove Account' ${selectedAccount}'s account will be removed from this subpoena.`}
        btnText="Remove Account"
        btnAction={removeLinkAccount}
        closeModal={closeAccountModal}
        isSubmitting={isSubmitting}
      />

      <GenericModal
        modalIsOpen={sarModalOpen}
        headerMsg="ATTENTION: Updating SAR Status"
        btnMsg={`By clicking 'Update SAR' ${selectedAccount}'s account SAR status will be ${
          sarFiled === true ? false : true
        }.`}
        btnText="Update SAR"
        btnAction={updateSar}
        closeModal={closeSARModal}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const columnBuilder = (openAccountModal, openSARModal) => [
  {
    Header: 'Customer Name',
    accessor: 'FullName',
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/accounts/${original.Account.Hash}`}>
        {original.Account.FullName}
      </Link>
    ),
  },
  {
    Header: 'Address',
    accessor: 'Address',
    Cell: ({ row: { original } }) =>
      `${original.Account.Addresses[0].Street} ${original.Account.Addresses[0].City}, ${original.Account.Addresses[0].StateCode}`,
  },
  {
    Header: 'Document Serial',
    accessor: 'DocumentSerial',
    Cell: ({ row: { original } }) =>
      original.DocumentSerial ? original.Account.DocumentSerial : '-',
  },
  {
    Header: 'DOB',
    accessor: 'DOB',
    Cell: ({ row: { original } }) => formatDate(original.Account.DOB),
  },
  {
    Header: 'Phone',
    accessor: 'Phone',
    Cell: ({ row: { original } }) => original.Account.PhoneNumbers[0].Phone,
  },
  {
    Header: 'SAR Filed',
    accessor: 'SARFiled',
    Cell: ({ row: { original } }) => (
      <Icon
        icon={original.SARFiled ? ICONS.ACTIVE : ICONS.INACTIVE}
        rightMargin="5"
        size="18"
        color={original.SARFiled ? '#009245' : '#e71d36'}
      />
    ),
  },
  {
    Header: 'Update SAR Status',
    accessor: 'SARFiled Status',
    Cell: ({ row: { original } }) => (
      <button
        className="column-btn create"
        onClick={() =>
          openSARModal(
            original.Hash,
            original.Account.Hash,
            original.Account.FullName,
            original.SARFiled
          )
        }
      >
        Update
      </button>
    ),
  },
  {
    Header: 'Remove Account',
    accessor: 'Remove Account',
    Cell: ({ row: { original } }) => (
      <button
        className="column-btn remove"
        onClick={() =>
          openAccountModal(
            original.Hash,
            original.Account.Hash,
            original.Account.FullName
          )
        }
      >
        Remove
      </button>
    ),
  },
];

export default LinkedAccounts;
