import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import {
  EditableInput,
  EditableSelect,
  EditableLink,
} from '../../Forms/EditableInputs';
import { LoadingButton } from '../../Utilities/Components';
import { EditableStates } from '../../Utilities/Data/StatesList';
import { EditableProvinces } from '../../Utilities/Data/ProvinceList';
import PropTypes from 'prop-types';

const AddressesForm = ({
  address,
  editing,
  postAccount,
  postPreferredAddress,
  countries,
}) => {
  const [country, setCountry] = useState('');
  const {
    City,
    Hash,
    Latitude,
    Longitude,
    Preferred,
    StateCode,
    Street,
    Street2,
    ZIP,
  } = address;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          CountryHash: address?.Country?.Hash || '',
          City,
          Latitude,
          Longitude,
          StateCode,
          Street,
          Street2,
          Preferred,
          ZIP,
        }}
        onSubmit={(values, actions) => {
          postAccount(values, actions, Hash);
        }}
        children={({ isSubmitting, setFieldValue }) => (
          <Form
            className={classnames(
              'form-outline inline-edit-form',
              {
                editing,
              },
              { 'form-padding': !Preferred }
            )}
          >
            <div className="columns">
              <div className="column">
                <EditableLink
                  name="Street"
                  label="Street"
                  type="text"
                  link={`https://www.google.com/maps/search/?api=1&query=${Street}+${City}+${StateCode}`}
                  linkName={Street}
                  editing={editing}
                  disabled={!Preferred}
                />
              </div>
              <div className="column">
                <EditableInput
                  name="Street2"
                  label="Apt, Ste, Unit"
                  type="text"
                  currentValue={Street2}
                  editing={editing}
                  disabled={!Preferred}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="form-row">
                  {editing ? (
                    <>
                      <label className="item-label">Country: </label>
                      <Field
                        as="select"
                        name="CountryHash"
                        className="form-row"
                        onChange={e => {
                          const countryMatch = countries.find(
                            country => country.Hash === e.target.value
                          );
                          setFieldValue('CountryHash', countryMatch.Hash);
                          setCountry(countryMatch.Code);
                        }}
                      >
                        <option value="" disabled>
                          Select a Country
                        </option>
                        {countries.map(c => (
                          <option key={c.Hash} value={c.Hash}>
                            {c.Name}
                          </option>
                        ))}
                      </Field>
                    </>
                  ) : (
                    <>
                      <label className="item-label">Country: </label>
                      <p>{address?.Country?.Name || '-'}</p>
                    </>
                  )}
                </div>
              </div>
              <div className="column">
                {country === 'CA' ? (
                  <EditableSelect
                    name="StateCode"
                    label="State/Province"
                    placeholder="Select a Province"
                    currentValue={StateCode}
                    data={EditableProvinces}
                    editing={editing}
                  />
                ) : (
                  <EditableSelect
                    name="StateCode"
                    label="State/Province"
                    placeholder="Select a State"
                    currentValue={StateCode}
                    data={EditableStates}
                    editing={editing}
                  />
                )}
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <EditableInput
                  name="City"
                  label="City"
                  type="text"
                  currentValue={City}
                  editing={editing}
                  disabled={!Preferred}
                />
              </div>
              <div className="column">
                <EditableInput
                  name="ZIP"
                  label="ZIP"
                  type="text"
                  currentValue={ZIP}
                  editing={editing}
                  disabled={!Preferred}
                />
              </div>
            </div>
            {editing && (
              <div className="columns">
                <div className="column is-half">
                  <div className="justify">
                    <label className="item-label" htmlFor="Preferred">
                      Preferred:
                    </label>

                    <Field
                      name="Preferred"
                      type="checkbox"
                      onClick={() => !Preferred && postPreferredAddress(Hash)}
                      disabled={Preferred}
                    />
                  </div>
                </div>
              </div>
            )}
            {editing && Preferred ? (
              <LoadingButton loading={isSubmitting} btnText="Update" />
            ) : null}
          </Form>
        )}
      />
    </>
  );
};

AddressesForm.propTypes = {
  postAccount: PropTypes.func.isRequired,
  postPreferredAddress: PropTypes.func,
  editing: PropTypes.bool.isRequired,
  address: PropTypes.shape({
    Active: PropTypes.bool,
    City: PropTypes.string,
    Hash: PropTypes.string,
    Latitude: PropTypes.number,
    Longitude: PropTypes.number,
    Preferred: PropTypes.bool,
    StateCode: PropTypes.string,
    Street: PropTypes.string,
    ZIP: PropTypes.string,
  }).isRequired,
};

export default AddressesForm;
