import React from 'react';
import { Formik, Form } from 'formik';
import {
  GoBack,
  Header,
  LoadingButton,
  ToastError,
  ToastSuccess,
} from './../Utilities/Components';
import { buildFormReq } from './FormHelpers';
import { API } from '../Config/cfg.js';
import PropTypes from 'prop-types';

const FormikContext = React.createContext({});

const GenericForm = ({
  accountSettings,
  callBack,
  children,
  customValidations,
  deleteReq,
  endpoint,
  formTitle,
  getData,
  goBack,
  overrideSubmit,
  passwordReload,
  postReq,
  redirect,
  setValues,
  showModal,
  subTitle,
  toastMsg,
}) => {
  const apiCall = async (values, actions) => {
    const data = buildFormReq(values);

    const setMethodCall = () => {
      if (postReq) {
        return 'post';
      }

      if (deleteReq) {
        return 'delete';
      }

      return 'put';
    };

    const url = accountSettings
      ? `/${endpoint}/${values.Hash}`
      : `/${endpoint}`;

    const method = setMethodCall();

    try {
      const response = await API({
        method,
        url,
        data,
      });

      // case for forms inside modals
      if (showModal) {
        return showModal(response);
      }

      // case for reset password
      if (passwordReload) {
        goBack.setHistory.push('/');

        window.location.reload();

        return;
      }

      // case for another api call using response data
      if (callBack) {
        await callBack(response.data, method, url);
      }

      // case for forms that need redirection
      // When routes are copied and paste into a new
      // tab this prevents the app from sending them
      // to a blank screen
      if (goBack.setHistory?.location.key) {
        if (redirect) {
          ToastSuccess(toastMsg);

          goBack.setHistory.push(redirect);

          return;
        }

        goBack.setHistory.goBack();
      }

      // case for retreiving data after submit
      if (getData) {
        getData();
      }

      ToastSuccess(toastMsg);

      actions.resetForm();
    } catch (error) {
      ToastError(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div id="formContainer">
      {goBack.show && (
        <GoBack
          history={goBack.setHistory}
          defaultPath={goBack.setDefaultPath}
          defaultName={goBack.setDefaultName}
        />
      )}
      {formTitle && <Header code={null} title={formTitle} />}
      <div className="form-outline form-create">
        {subTitle && <h2 className="form-title">{subTitle}</h2>}
        <Formik
          enableReinitialize
          initialValues={setValues}
          validate={values => {
            if (customValidations) {
              return customValidations(values);
            } else {
              let errors = {};

              for (let [key] of Object.entries(values)) {
                if (!values[key]) {
                  errors[key] = 'is required';
                }
              }

              return errors;
            }
          }}
          onSubmit={(values, actions) => {
            apiCall(values, actions);
          }}
          children={({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              {/* renders form inputs and passes formik props */}
              <FormikContext.Provider
                value={{ values, errors, touched, setFieldValue }}
              >
                {children}
              </FormikContext.Provider>
              {!overrideSubmit && (
                <LoadingButton loading={isSubmitting} testID="loadingButton" />
              )}
            </Form>
          )}
        />
      </div>
    </div>
  );
};

GenericForm.defaultProps = {
  accountSettings: false,
  deleteReq: false,
  overrideSubmit: false,
  passwordReload: false,
  postReq: false,
};

GenericForm.propTypes = {
  accountSettings: PropTypes.bool,
  callBack: PropTypes.func,
  customValidations: PropTypes.func,
  deleteReq: PropTypes.bool,
  endpoint: PropTypes.string.isRequired,
  formTitle: PropTypes.string,
  getData: PropTypes.func,
  goBack: PropTypes.shape({
    setHistory: PropTypes.object,
    show: PropTypes.bool,
    setDefaultPath: PropTypes.string,
    setDefaultName: PropTypes.string,
  }).isRequired,
  overrideSubmit: PropTypes.bool,
  passwordReload: PropTypes.bool,
  postReq: PropTypes.bool,
  redirect: PropTypes.string,
  setValues: PropTypes.object.isRequired,
  showModal: PropTypes.func,
  subTitle: PropTypes.string,
  toastMsg: PropTypes.string,
};

export { GenericForm, FormikContext };
