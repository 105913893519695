import React from 'react';
import { Form, Formik } from 'formik';
// Components
import { FormInput } from '../../../Forms/FormInputs';
import { GenericModal } from '../../../Utilities/Components';

const MergeAccountModal = ({ isOpen, putAccount, account, closeModal }) => {
  return (
    <Formik
      initialValues={{
        AccountFromHash: '',
        AccountToHash: '',
      }}
      validate={values => {
        let errors = {};
        if (!values.AccountFromHash) {
          errors.AccountFromHash = 'is required';
        }
        if (!values.AccountToHash) {
          errors.AccountToHash = 'is required';
        }
        return errors;
      }}
      onSubmit={async values => {
        await putAccount({
          AccountFromHash: values.AccountFromHash,
          AccountToHash: values.AccountToHash,
        });
        values.AccountFromHash = '';
        values.AccountToHash = '';
      }}
      children={({ touched, errors, handleSubmit, isSubmitting }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={() => {
            closeModal('isMergeAccountOpen');
          }}
          viewMsg={account}
          headerMsg="Merge Account"
          btnText="Submit"
          btnAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <Form className="form-outline is-centered">
            <div className="column is-10">
              <FormInput
                errors={errors}
                touched={touched}
                label="Account From Hash"
                name="AccountFromHash"
                placeholder="Enter Account From Hash..."
              />
            </div>
            <div className="column is-10">
              <FormInput
                errors={errors}
                touched={touched}
                label="Account To Hash"
                name="AccountToHash"
                placeholder="Enter Account To Hash..."
              />
            </div>
          </Form>
        </GenericModal>
      )}
    />
  );
};

export default MergeAccountModal;
