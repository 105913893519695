import React from 'react';

const LoadingButton = ({
  btnAction,
  btnText,
  className,
  disabled,
  loading,
  loadingText,
  style,
  testID,
  type,
}) => {
  return (
    <button
      className={className || 'btn primary-btn'}
      type={type}
      style={style}
      disabled={loading || disabled}
      onClick={btnAction}
      data-testid={testID}
    >
      {loading && (
        <i className="fa fa-sync fa-spin" style={{ marginRight: '5px' }} />
      )}
      {loading && <span>{loadingText || 'Loading'}</span>}
      {!loading && <span>{btnText || 'Submit'}</span>}
    </button>
  );
};

LoadingButton.defaultProps = {
  testID: 'xLoadingButton',
  type: 'submit',
};

export default LoadingButton;
