import React, { useEffect, useState } from 'react';
import IDFormDeny from './IDFormDeny';
import IDApprovalForm from './IDApprovalForm';
import { Header, Loading, ToastError } from '../Utilities/Components';
import { API } from '../Config/cfg.js';
import PropTypes from 'prop-types';

const IDReview = ({ hash, history }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getReview();
  }, []);

  const getReview = async () => {
    try {
      const { data } = await API.get(`/compliance/reviews/${hash}`);

      setData(data);
    } catch (error) {
      setErrors(error);
      ToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading errors={errors} />;
  } else {
    return (
      <section data-testid="IDReview">
        <Header code={null} title={`Account ID Review`} />
        <div className="columns">
          <div className="column">
            <IDApprovalForm
              data={data}
              endpoint={`compliance/reviews/actions/${hash}/approve`}
              history={history}
            />
          </div>
          <div className="column">
            <IDFormDeny
              endPoint={`compliance/reviews/actions/${hash}/deny-id`}
              history={history}
            />
          </div>
        </div>
      </section>
    );
  }
};

IDReview.propTypes = {
  hash: PropTypes.string.isRequired,
  history: PropTypes.object,
};

export default IDReview;
