// Modules
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Components
import Table from '../../../Table/Table';
import {
  AddPlatformModal,
  EditCommissionModal,
  DeleteCommissionModal,
} from './../../Modals';
import { CommissionType } from '../../../Utilities/Components/TablePills';
// Helpers - Formatters
import {
  formatPrice,
  formatDate,
  formatPercent,
} from '../../../Utilities/Formatters';
import { ToastError, ToastSuccess } from '../../../Utilities/Components';
// Permissions
import Can from '../../../Permissions/Can';
import {
  ACCOUNTING_COMMISS_CREATE,
  ACCOUNTING_COMMISS_EDIT,
} from '../../../Permissions/Permissions';
// Configs
import { API } from '../../../Config/cfg.js';
import moment from 'moment';

class Commissions extends Component {
  state = {
    callAPI: false,
    editModalIsOpen: false,
    platformModalIsOpen: false,
    deleteModalIsOpen: false,
    commissionData: {},
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.callAPI === true) {
      this.setState({ callAPI: false });
    }
  }

  postCommission = data => {
    const { commissionData } = this.state;

    API.post(`/location-commissions/${commissionData.Hash}`, data)
      .then(() => {
        ToastSuccess(`Commission has been updated`);
        this.setState({
          commissionData: {},
          callAPI: true,
          editModalIsOpen: false,
        });
      })
      .catch(error => {
        ToastError(error);
      });
  };

  deleteCommission = () => {
    const { commissionData } = this.state;
    API.delete(`/location-commissions/${commissionData.Hash}`)
      .then(() => {
        ToastSuccess('Commission has been removed');
        this.setState({
          commissionData: {},
          deleteModalIsOpen: false,
          callAPI: true,
        });
      })
      .catch(error => {
        ToastError(error);
      });
  };

  addPlatform = data => {
    const { commissionData } = this.state;
    API.patch(`/location-commissions/${commissionData.Hash}`, {
      PlatformHash: data.PlatformHash,
    })
      .then(() => {
        ToastSuccess(`Commission has been updated`);
        this.setState({
          commissionData: {},
          callAPI: true,
          platformModalIsOpen: false,
        });
      })
      .catch(error => {
        ToastError(error);
      });
  };

  openEditModal = data => {
    this.setState({
      editModalIsOpen: true,
      commissionData: data,
    });
  };

  openDeleteModal = data => {
    this.setState({
      deleteModalIsOpen: true,
      commissionData: data,
    });
  };

  openPlatformModal = data => {
    this.setState({
      platformModalIsOpen: true,
      commissionData: data,
    });
  };

  closeEditModal = () => {
    this.setState({ editModalIsOpen: false });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModalIsOpen: false });
  };

  closePlatformModal = () => {
    this.setState({ platformModalIsOpen: false });
  };

  render() {
    const { hash } = this.props;
    const { callAPI, platformModalIsOpen, editModalIsOpen, deleteModalIsOpen } =
      this.state;

    return (
      <React.Fragment>
        <Table
          columns={columnBuilder(
            this.openEditModal,
            this.openDeleteModal,
            this.openPlatformModal
          )}
          endpoint={`locations/${hash}/commissions`}
          nested={true}
          tablename="Commissions"
          triggerAPI={callAPI}
          actions={[
            {
              name: 'Add New Commission Payment',
              link: '/locations/add-commissions',
              params: hash,
              permission: ACCOUNTING_COMMISS_CREATE,
            },
          ]}
        />
        {platformModalIsOpen && (
          <AddPlatformModal
            isOpen={platformModalIsOpen}
            addPlatform={this.addPlatform}
            locationHash={hash}
            closeModal={this.closePlatformModal}
          />
        )}
        {editModalIsOpen && (
          <EditCommissionModal
            isOpen={editModalIsOpen}
            location={this.state.commissionData}
            postCommission={this.postCommission}
            closeModal={this.closeEditModal}
          />
        )}
        <DeleteCommissionModal
          isOpen={deleteModalIsOpen}
          location={this.state.commissionData}
          deleteCommission={this.deleteCommission}
          closeModal={this.closeDeleteModal}
        />
      </React.Fragment>
    );
  }
}

export default Commissions;

const columnBuilder = (openEditModal, openDeleteModal, openPlatformModal) => [
  {
    Header: 'Activation Date',
    accessor: 'ActivationDate',
    maxWidth: 120,
    Cell: ({ row: { original } }) => formatDate(original.ActivationDate),
    sortMethod: 'ActivationDate',
  },
  {
    Header: 'Expiration Date',
    accessor: 'Expiry',
    maxWidth: 120,
    Cell: ({ row: { original } }) => formatDate(original?.Expiry) || '-',
    sortMethod: 'Expiry',
  },
  {
    Header: 'Group',
    accessor: 'GroupCommission.Group.Hash',
    style: { textAlign: 'left ' },
    Cell: ({ row: { original } }) =>
      original.GroupCommission && (
        <Link
          className="table-pill"
          to={`/groups/${original.GroupCommission.Group.Hash}`}
        >
          {original.GroupCommission.Group.Name}
        </Link>
      ),
  },
  {
    Header: 'Payee',
    accessor: 'GroupCommission.PayableTo',
    style: { textAlign: 'left ' },
    Cell: ({ row: { original } }) =>
      original.GroupCommission && original.GroupCommission.DebitAccount ? (
        <Link
          className="table-pill"
          to={`/accounting/ach/${original.GroupCommission.DebitAccount.Hash}`}
        >
          {original.GroupCommission.PayableTo}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    Header: 'Type',
    accessor: 'Type',
    minWidth: 200,
    Cell: ({ row: { original } }) =>
      CommissionType(
        original.GroupCommission.CommissionType,
        original.GroupCommission.CommissionTypeName
      ),
  },
  {
    Header: 'Percent',
    Cell: ({ row: { original } }) =>
      original.GroupCommission.Percent
        ? formatPercent(original.GroupCommission.Percent)
        : '-',
  },
  {
    Header: 'Fiat',
    Cell: ({ row: { original } }) =>
      original.GroupCommission.Fiat
        ? formatPrice(original.GroupCommission.Fiat)
        : '-',
  },
  {
    Header: 'Platform',
    accessor: 'DeviceIdentifier',
    maxWidth: 120,
    Cell: ({ row: { original } }) =>
      original.Platform?.DeviceIdentifier ? (
        original.Platform.DeviceIdentifier
      ) : (
        <>
          <Can do={ACCOUNTING_COMMISS_EDIT}>
            <button
              className="column-btn create"
              onClick={() => openPlatformModal(original)}
            >
              Add Platform
            </button>
          </Can>
        </>
      ),
    sortMethod: 'DeviceIdentifier',
  },
  {
    Header: 'Action',
    Cell: ({ row: { original } }) =>
      !original.Expiry ||
      moment(original.Expiry).isAfter(moment.now(), 'day') ? (
        <div>
          <Can do={ACCOUNTING_COMMISS_EDIT}>
            <button
              className="column-btn create"
              onClick={() => openEditModal(original)}
            >
              Replace
            </button>
          </Can>
        </div>
      ) : (
        <p className="expired">Expired</p>
      ),
  },
  {
    Header: 'Delete',
    Cell: ({ row: { original } }) =>
      !original.Expiry ||
      moment(original.Expiry).isAfter(moment.now(), 'day') ? (
        <div>
          <Can do={ACCOUNTING_COMMISS_EDIT}>
            <button
              className="column-btn remove"
              onClick={() => openDeleteModal(original)}
            >
              Remove
            </button>
          </Can>
        </div>
      ) : (
        <p className="expired">Expired</p>
      ),
  },
];
