import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
// Components
import { FormInput, FormSelect } from './../../Forms/FormInputs';
import { GenericModal, ToastError } from '../../Utilities/Components';
// Helpers - Formatters
import { formatDate, formatPercent } from '../../Utilities/Formatters';
// Config
import { API } from '../../Config/cfg.js';

const EditCommissionModal = ({
  isOpen,
  postCommission,
  location,
  closeModal,
}) => {
  const [commissions, setCommissions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupHash, setGroupHash] = useState('');
  const { ActivationDate } = location;

  useEffect(() => {
    API.get('/groups?all=true').then(res => {
      setGroups(res.data.Data);
    });
  }, []);

  useEffect(() => {
    if (groupHash) {
      API.get(`/groups/${groupHash}/commissions`)
        .then(res => {
          setCommissions(res.data.Data);
        })
        .catch(error => {
          ToastError('Could not update commission');
        });
    }
  }, [groupHash]);

  const handleGroupSelect = e => {
    setGroupHash(e.target.value);
  };

  // validate whether the activation date is before the expiration date
  const validateDate = date => {
    return moment(date).isBefore(ActivationDate);
  };

  // validate the Inputs for Commisison values
  const validateCommissionValues = values => {
    let errors = {};
    if (validateDate(values.Expiry)) {
      errors.Expiry = 'date cannot be before the activation date';
    }
    if (
      values.Percent &&
      values.Percent.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/)
    ) {
      errors.Percent = 'must be a percentage';
    }
    if (!values.GroupCommissionHash) {
      errors.GroupCommissionHash = 'is required';
    }
    return errors;
  };

  const formatCommissionOption = item => {
    const { CommissionTypeName, PaymentType, Fiat, Percent, ACHNet } = item;

    const commArr = [CommissionTypeName, PaymentType];

    if (Fiat) {
      commArr.push(Fiat);
    }
    if (Percent) {
      commArr.push(formatPercent(Percent));
    }
    if (ACHNet) {
      commArr.push('ACHNet');
    }

    return commArr.join(', ');
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        GroupCommissionHash: '',
        ActivationDate: formatDate(location.ActivationDate, 'YYYY-MM-DD', true),
        Expiry: formatDate(location.Expiry, 'YYYY-MM-DD', true),
      }}
      validate={values => validateCommissionValues(values)}
      onSubmit={async values => {
        if (values.Expiry === '-') {
          delete values.Expiry;
        }
        await postCommission(values);
      }}
      children={({ touched, errors, handleSubmit, isSubmitting }) => {
        return (
          <GenericModal
            modalIsOpen={isOpen}
            closeModal={() => {
              closeModal('isEditModalOpen');
            }}
            headerMsg="Edit Commissions"
            btnText="Submit"
            btnAction={handleSubmit}
            isSubmitting={isSubmitting}
          >
            <div className="form-outline">
              <div className="columns">
                <div className="column">
                  <label className="item-label">Group Name</label>
                  <select onChange={handleGroupSelect} defaultValue="">
                    <option value="" disabled>
                      Select Group
                    </option>
                    {groups.map(item => {
                      if (item.Hash === location.GroupCommission.Group.Hash) {
                        return (
                          <option key={item.Hash} value={item.Hash}>
                            {item.Name}
                          </option>
                        );
                      }
                      return (
                        <option key={item.Hash} value={item.Hash}>
                          {item.Name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="column">
                  <FormSelect
                    errors={errors}
                    touched={touched}
                    label="Commission"
                    name="GroupCommissionHash"
                    placeholder="Select Commission"
                    options={
                      <>
                        {commissions.map(item => (
                          <option key={item.Hash} value={item.Hash}>
                            {formatCommissionOption(item)}
                          </option>
                        ))}
                      </>
                    }
                    disabled={!commissions.length || !groupHash}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    label="Expiration Date"
                    name="Expiry"
                    type="date"
                    placeholder="Expiration Date"
                  />
                </div>
                <div className="column">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    label="Activation Date"
                    name="ActivationDate"
                    type="date"
                    placeholder="Activation Date"
                  />
                </div>
              </div>
            </div>
          </GenericModal>
        );
      }}
    />
  );
};

export default EditCommissionModal;
