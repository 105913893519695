export default [
  {
    display: 'No Commission Payout',
    selectBy: 'none',
    value: 0,
  },
  {
    display: 'Flat $',
    selectBy: 'Fiat',
    value: 1,
  },
  {
    display: 'Gross Commission Flat %',
    selectBy: 'Percent',
    value: 2,
  },
  {
    display: 'Gross Commission Tiered',
    selectBy: 'Tiered',
    value: 3,
  },
  {
    display: 'Gross Commission Tiered + %',
    selectBy: 'Percent',
    value: 4,
  },
  {
    display: 'Gross Commission Monthly Minimum $ or %',
    selectBy: 'Both',
    value: 5,
  },
  {
    display: 'Total Cash Flat %',
    selectBy: 'Percent',
    value: 6,
  },
  {
    display: 'Total Cash Tiered',
    selectBy: 'Tiered',
    value: 7,
  },
  {
    display: 'Total Cash Tiered + %',
    selectBy: 'Percent',
    value: 8,
  },
  {
    display: 'Total Cash Minimum Monthly $ or %',
    selectBy: 'Both',
    value: 9,
  },
  {
    display: 'Monthly Flat Fiat $',
    selectBy: 'Fiat',
    value: 10,
  },
];
