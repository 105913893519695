// Modules
import React, { useState, useEffect } from "react";
// EDD Components
import AccountDetails from "./AccountDetails";
import EDDReviewDetails from "./../EDDs/EDDReviewDetails";
import TabsContainer from "./TabsContainer";
// Components
import {
  GoBack,
  Header,
  Loading,
  ToastError,
  ToastSuccess,
} from "../../Utilities/Components";
// Icons
import { ICONS } from "../../Icons/IconsPath";
// Configs
import { API } from "../../Config/cfg.js";

const SingleEDD = ({ history, match: { params } }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({});
  const [showDecision, setShowDecision] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    // get EDD data
    (async () => {
      const { data, error } = await API.get(`/compliance/edds/${params.hash}`);
      if (!!data) {
        setData(data);
      } else if (error) {
        setError(error);
      }

      setIsLoading(false);
    })();
  }, [params.hash]);

  const deleteEDD = async () => {
    const { hash } = params;

    const { error } = await API.delete(`/compliance/edds/${hash}`);

    if (error) {
      ToastError(error);
    } else {
      history.push("/compliance/edds");
      ToastSuccess("EDD has been closed");
    }
  };

  if (isLoading) {
    return <Loading errors={error} />;
  }

  return (
    <React.Fragment>
      <GoBack
        history={history}
        defaultPath="/compliance/edds"
        defaultName="EDDs"
      />

      <div className="columns is-vcentered">
        <div className="column is-narrow">
          <Header icon={ICONS.WARNING} title={data?.Code} />

          {data?.AuthorAccount && (
            <Header code="Worked by: " title={data.AuthorAccount?.FullName} />
          )}

          {data?.AssignedTo && !data?.AuthorAccount && (
            <Header code="Assigned to: " title={data.AssignedTo?.FullName} />
          )}
        </div>
      </div>
      <div className="alert-container">
        <div className="columns">
          <div className="column">
            <AccountDetails data={data} />
          </div>
          <div className="column">
            <EDDReviewDetails data={data} />
          </div>
        </div>
        <br />
      </div>
      <TabsContainer
        history={history}
        data={data}
        reviewDecision={() => setShowDecision(!showDecision)}
        deleteEDD={() => deleteEDD()}
        showDecision={showDecision}
      />
    </React.Fragment>
  );
};

export default SingleEDD;
