import React from 'react';
import { checkPermission } from './CheckPermission';
import { PermissionsContext } from '../Permissions/PermissionContext';
import PropTypes from 'prop-types';

const Can = props => {
  return (
    <PermissionsContext.Consumer>
      {userPermissionsProps =>
        checkPermission(userPermissionsProps, props.do) && props.children
      }
    </PermissionsContext.Consumer>
  );
};

Can.propTypes = {
  do: PropTypes.string.isRequired,
};

export default Can;
