// Modules
import React from "react";
// Components
import { EditableForm } from "../../Forms/EditableForm";
import SetHour from "./../Forms/SetHour";
import {
  reqBoolProp,
  reqFuncProp,
  locationProps
} from "../../Utilities/PropTypes";
import { LOCATIONS_EDIT } from "../../Permissions/Permissions";

const Hours = ({ data, editing, toggleEdit, postLocation, isSubmitting }) => {
  const callPostLocation = (req, actions) => {
    // Sends req to POST call in parent
    postLocation("locations", data.Hash, "hours", req, "editingHours", actions);
  };

  const {
    BusinessName,
    MondayOpen,
    MondayOpen24,
    MondayOpenAt,
    MondayCloseAt,
    TuesdayOpen,
    TuesdayOpen24,
    TuesdayOpenAt,
    TuesdayCloseAt,
    WednesdayOpen,
    WednesdayOpen24,
    WednesdayOpenAt,
    WednesdayCloseAt,
    ThursdayOpen,
    ThursdayOpen24,
    ThursdayOpenAt,
    ThursdayCloseAt,
    FridayOpen,
    FridayOpen24,
    FridayOpenAt,
    FridayCloseAt,
    SaturdayOpen,
    SaturdayOpen24,
    SaturdayOpenAt,
    SaturdayCloseAt,
    SundayOpen,
    SundayOpen24,
    SundayOpenAt,
    SundayCloseAt
  } = data;

  const copyText = `
    ${BusinessName} Hours
    Mon.: ${
      MondayOpen
        ? MondayOpen24
          ? "24 Hours"
          : `${MondayOpenAt} - ${MondayCloseAt}`
        : "Closed"
    }
    Tue.: ${
      TuesdayOpen
        ? TuesdayOpen24
          ? "24 Hours"
          : `${TuesdayOpenAt} - ${TuesdayCloseAt}`
        : "Closed"
    }
    Wed.: ${
      WednesdayOpen
        ? WednesdayOpen24
          ? "24 Hours"
          : `${WednesdayOpenAt} - ${WednesdayCloseAt}`
        : "Closed"
    }
    Thu.: ${
      ThursdayOpen
        ? ThursdayOpen24
          ? "24 Hours"
          : `${ThursdayOpenAt} - ${ThursdayCloseAt}`
        : "Closed"
    }
    Fri.: ${
      FridayOpen
        ? FridayOpen24
          ? "24 Hours"
          : `${FridayOpenAt} - ${FridayCloseAt}`
        : "Closed"
    }
    Sat.: ${
      SaturdayOpen
        ? SaturdayOpen24
          ? "24 Hours"
          : `${SaturdayOpenAt} - ${SaturdayCloseAt}`
        : "Closed"
    }
    Sun.: ${
      SundayOpen
        ? SundayOpen24
          ? "24 Hours"
          : `${SundayOpenAt} - ${SundayCloseAt}`
        : "Closed"
    }
  `;

  return (
    <EditableForm
      callBack={callPostLocation}
      setValues={{
        MondayOpen,
        MondayOpen24,
        MondayOpenAt,
        MondayCloseAt,
        TuesdayOpen,
        TuesdayOpen24,
        TuesdayOpenAt,
        TuesdayCloseAt,
        WednesdayOpen,
        WednesdayOpen24,
        WednesdayOpenAt,
        WednesdayCloseAt,
        ThursdayOpen,
        ThursdayOpen24,
        ThursdayOpenAt,
        ThursdayCloseAt,
        FridayOpen,
        FridayOpen24,
        FridayOpenAt,
        FridayCloseAt,
        SaturdayOpen,
        SaturdayOpen24,
        SaturdayOpenAt,
        SaturdayCloseAt,
        SundayOpen,
        SundayOpen24,
        SundayOpenAt,
        SundayCloseAt
      }}
      customValidations={values => {
        let errors = {};
        return errors;
      }}
      editing={editing}
      permission={LOCATIONS_EDIT}
      triggerEdit={toggleEdit}
      editType="editingHours"
      sectionTitle="Current Hours"
      isSubmitting={isSubmitting}
      copy
      copyText={copyText}
    >
      <SetHour
        editing={editing}
        day="Monday"
        dayOpen={MondayOpen}
        dayOpen24={MondayOpen24}
        dayCloseAt={MondayCloseAt}
        dayOpenAt={MondayOpenAt}
      />
      <SetHour
        editing={editing}
        day="Tuesday"
        dayOpen={TuesdayOpen}
        dayOpen24={TuesdayOpen24}
        dayCloseAt={TuesdayCloseAt}
        dayOpenAt={TuesdayOpenAt}
      />
      <SetHour
        editing={editing}
        day="Wednesday"
        dayOpen={WednesdayOpen}
        dayOpen24={WednesdayOpen24}
        dayCloseAt={WednesdayCloseAt}
        dayOpenAt={WednesdayOpenAt}
      />
      <SetHour
        editing={editing}
        day="Thursday"
        dayOpen={ThursdayOpen}
        dayOpen24={ThursdayOpen24}
        dayCloseAt={ThursdayCloseAt}
        dayOpenAt={ThursdayOpenAt}
      />
      <SetHour
        editing={editing}
        day="Friday"
        dayOpen={FridayOpen}
        dayOpen24={FridayOpen24}
        dayCloseAt={FridayCloseAt}
        dayOpenAt={FridayOpenAt}
      />
      <SetHour
        editing={editing}
        day="Saturday"
        dayOpen={SaturdayOpen}
        dayOpen24={SaturdayOpen24}
        dayCloseAt={SaturdayCloseAt}
        dayOpenAt={SaturdayOpenAt}
      />
      <SetHour
        editing={editing}
        day="Sunday"
        dayOpen={SundayOpen}
        dayOpen24={SundayOpen24}
        dayCloseAt={SundayCloseAt}
        dayOpenAt={SundayOpenAt}
      />
    </EditableForm>
  );
};

Hours.propTypes = {
  editing: reqBoolProp,
  postLocation: reqFuncProp,
  toggleEdit: reqFuncProp,
  data: locationProps
};

export default Hours;
