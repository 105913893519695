import Data from './Data';
import PlatformTypeData from './PlatformType';

export default class SessionData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      ApplicationVersion: { type: 'string' },
      AuthMethod: { type: 'number' },
    };
  }

  hasOne() {
    return {
      PlatformType: PlatformTypeData,
    };
  }

  computed() {
    return {
      isLoggedIn({ Hash }) {
        return !!Hash;
      },
    };
  }
}
