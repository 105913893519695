import React from 'react';
import DualListBox from 'react-dual-listbox';
import StateLists from '../../Utilities/Data/StatesList';
import PropTypes from 'prop-types';

const StatesListBox = ({ editing, id, onChange, states, selected, testID }) => {
  const formatOptions = () => {
    return StateLists.map(({ value, label }) => {
      const match = states?.find(({ StateCode }) => StateCode === value);

      if (match && match.ReadOnly) {
        return { value, label, disabled: true };
      }

      return { value, label };
    });
  };

  const formatList = () => {
    return selected.reduce((list, StateCode) => {
      const state = StateLists.find(({ value }) => value === StateCode);

      list.push(state.label);

      return list;
    }, []);
  };

  return (
    <div id="listBox" data-testid={testID}>
      {editing ? (
        <DualListBox
          id={id}
          onChange={onChange}
          selected={selected}
          options={formatOptions(states)}
        />
      ) : (
        <ul>
          {formatList().map((state, index) => (
            <li key={index}>{state}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

StatesListBox.defaultProps = {
  editing: false,
  id: 'States',
  onChange: () => {},
  selected: [],
  states: [],
  testID: 'xStatesListBox',
};

StatesListBox.propTypes = {
  editing: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  selected: PropTypes.arrayOf(PropTypes.string),
  testID: PropTypes.string,
};

export default StatesListBox;
