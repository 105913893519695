// Modules
import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import { strProp, arrProp, numProp } from './../Utilities/PropTypes';
import { Carousel } from 'react-responsive-carousel';
// Assets
import dm from './../images/dm_placeholder.svg';
// Styles
import './Asset.scss';

const Asset = ({ images, info, icon, height, width, placeholder, scale }) => {
  return (
    <div className="asset" data-tip={info}>
      {!images || images.length === 0 || images[0].image === undefined ? (
        <div className="asset-container">
          <img
            src={placeholder ? placeholder : icon}
            height={scale ? scale : height}
            width={scale ? scale : width}
            alt=""
            className="asset-img"
          />
        </div>
      ) : (
        <Carousel
          autoPlay
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
        >
          {images.map((img, i) => (
            <a
              href={img.image}
              target="_blank"
              rel="noopener noreferrer"
              key={i}
            >
              <img
                type="image/jpeg"
                className="img-carousel"
                alt=""
                src={img.image}
              />
            </a>
          ))}
        </Carousel>
      )}
      <ReactTooltip place="bottom" effect="solid" />
    </div>
  );
};

Asset.defaultProps = {
  icon: dm,
  height: 80,
  width: 80,
};

Asset.propTypes = {
  images: arrProp,
  icon: strProp,
  info: strProp,
  height: PropTypes.oneOfType([strProp, numProp]),
  width: PropTypes.oneOfType([strProp, numProp]),
  placeholder: strProp,
  scale: strProp,
};

export default Asset;
