import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../Formatters";

const TextBlastRecipient = ({
  Hash,
  CreatedAt,
  DOB,
  FirstName,
  LastName,
  PhoneNumber,
}) => {
  return (
    <div className="columns is-gapless is-marginless">
      <Link className="column is-3" to={`/accounts/${Hash}`}>
        {LastName}, {FirstName}
      </Link>
      <p className="column is-2">{PhoneNumber}</p>
      <p className="column is-2">{formatDate(DOB)}</p>
      <p className="column is-2">{formatDate(CreatedAt)}</p>
    </div>
  );
};

export default TextBlastRecipient;
