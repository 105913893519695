// Modules
import React from "react";
// Components
import Asset from "../../Asset/Asset";
import GeoLocation from "./../../GeoLocation/GeoLocation";
import ProductDetails from "./ProductDetails";
import TxDetails from "./TxDetails";
import TransactionActions from "./TransactionActions";
import { transactionProps } from "../../Utilities/PropTypes";

const Overview = ({ data, postTransaction, putCompliance }) => {
  const { Location, Images } = data;
  return (
    <div className="columns">
      <div className="column">
        {Images.length === 0 ? (
          <div className="map-container-btn">
            <GeoLocation address={Location.Address} />
          </div>
        ) : (
          <div>
            <Asset images={Images.map((img) => ({ image: img.URL }))} />
          </div>
        )}
        <TransactionActions
          data={data}
          postTransaction={postTransaction}
          putCompliance={putCompliance}
        />
      </div>
      <div className="column is-7">
        <TxDetails data={data} />
        <ProductDetails data={data} />
      </div>
    </div>
  );
};

Overview.propTypes = {
  data: transactionProps,
};

export default Overview;
