import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import AchInputs from './AchInputs';
import CheckInputs from './CheckInputs';
import {
  GoBack,
  Header,
  LoadingButton,
  ToastSuccess,
} from 'src/Utilities/Components';
import { FormSelect, FormInput } from 'src/Forms/FormInputs';
import { percentToDecimal } from 'src/Utilities/Formatters';
import { ToastError } from 'src/Utilities/Components';
import ACH_CODES from 'src/Utilities/Data/ACHCodes';
import COMMISSION_TYPES from 'src/Utilities/Data/CommissionTypes';
import PAYMENT_TYPES from 'src/Utilities/Data/PaymentTypes';
import { API } from 'src/Config/cfg';

const AddGroupCommission = ({ location: { state }, hash, history }) => {
  const [loading, setLoading] = useState(false);
  const [paymentTypeCategory, setPaymentTypeCategory] = useState(null);
  const [commissionTypeCategory, setCommissionTypeCategory] = useState(null);
  const [debitAccounts, setDebitAccounts] = useState([]);

  const getDebitAccounts = async () => {
    try {
      const response = await API.get('/accounting/ach?all=true');

      if (response?.data) {
        setDebitAccounts(response.data.Data);
      }
    } catch (error) {
      ToastError(error);
      history.goBack();
    }
  };

  const postGroupCommission = async (values, actions) => {
    let req, endpoint, method;
    setLoading(true);

    method = 'PUT';
    endpoint = `/groups/${hash}/commissions`;
    req = stripFields(values);

    if (commissionTypeCategory === 'none') {
      req.PaymentType = 'ACH';
      req.CommissionType = parseInt(values.CommissionType);
    } else {
      if (req.PaymentType === 'ACH') {
        const selectedAccount = debitAccounts.find(
          i => i.PayorName === req.DebitAccount
        );

        req.AccountNumber = selectedAccount.AccountNumber;
        req.RoutingNumber = selectedAccount.RoutingNumber;

        if (selectedAccount.PayableTo) {
          req.PayableTo = selectedAccount.PayableTo;
        }
      } else if (req.PaymentType === 'ACH2') {
        req = { ...req, PaymentType: 'ACH' };

        if (values.PayableTo) {
          req.PayableTo = values.PayableTo;
        }
      }

      if (values.TransactionACHCode) {
        req = {
          ...req,
          TransactionACHCode: parseInt(values.TransactionACHCode),
        };
      }

      if (values.Percent) {
        req = { ...req, Percent: percentToDecimal(values.Percent) };
      }

      delete req.DebitAccount;
      req = { ...req, CommissionType: parseInt(values.CommissionType) };
    }

    try {
      await API({ method, url: endpoint, data: req });

      if (history && history.location.key) {
        history.goBack();
      }
      ToastSuccess(
        state.editing
          ? 'Commission payment has been successfully updated'
          : 'New commission payment has been successfully created'
      );
      actions.resetForm();
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      ToastError(error);
    } finally {
      setLoading(false);
    }
  };

  const validate = values => {
    let errors = {};

    if (commissionTypeCategory !== 'none') {
      values = stripFields(values);

      // Generically check all required
      for (let [key] of Object.entries(values)) {
        if (
          (values[key] === '' ||
            values[key] === undefined ||
            values[key] === null) &&
          key !== 'Street2' &&
          key !== 'ACHNet'
        ) {
          errors[key] = 'is required';
        }
      }
    }

    return errors;
  };

  const stripFields = values => {
    const payload = Object.assign({}, values);

    if (
      commissionTypeCategory === 'Tiered' ||
      commissionTypeCategory === 'none'
    ) {
      delete payload.Percent;
      delete payload.Fiat;
    } else if (commissionTypeCategory === 'Fiat') {
      delete payload.Percent;
    } else if (commissionTypeCategory === 'Percent') {
      delete payload.Fiat;
    }

    /** TODO No Commission option does not have proper backend validation
     * we send a bunch of empty fields for it to be accepted
     * should get rid of commissionTypeCategory check when fixed
     **/

    if (!paymentTypeCategory && commissionTypeCategory !== 'none') {
      delete payload.AccountNumber;
      delete payload.City;
      delete payload.DebitAccount;
      delete payload.FinancialInstitutionNumber;
      delete payload.RoutingNumber;
      delete payload.StateCode;
      delete payload.Street2;
      delete payload.Street;
      delete payload.TransactionACHCode;
      delete payload.TransitNumber;
      delete payload.Zip;
    } else if (paymentTypeCategory !== 'Check') {
      delete payload.Street;
      delete payload.Street2;
      delete payload.City;
      delete payload.StateCode;
      delete payload.Zip;

      if (paymentTypeCategory === 'ACH') {
        delete payload.FinancialInstitutionNumber;
        delete payload.TransitNumber;
        delete payload.RoutingNumber;
        delete payload.AccountNumber;
        delete payload.PayableTo;
      } else if (paymentTypeCategory === 'ACH2') {
        delete payload.DebitAccount;
      }
    } else if (paymentTypeCategory === 'Check') {
      delete payload.RoutingNumber;
      delete payload.AccountNumber;
      delete payload.DebitAccount;
      delete payload.TransactionACHCode;
      delete payload.TransitNumber;
      delete payload.FinancialInstitutionNumber;
    }

    return payload;
  };

  return (
    <>
      <GoBack
        history={history}
        defaultPath={`/groups/${hash}`}
        defaultName={state.groupName}
        show
      />
      <Header
        code={null}
        title={`${state.editing ? 'Edit' : 'Add'} ${
          state.groupName
        } Commission`}
      />
      <Formik
        initialValues={{
          CommissionType: '',
          Percent: '',
          Fiat: '',
          PaymentType: '',
          PayableTo: '',
          Street: '',
          Street2: '',
          City: '',
          StateCode: '',
          Country: 'US',
          Zip: '',
          RoutingNumber: '',
          AccountNumber: '',
          TransactionACHCode: 1, // Checking (type 1) is default value
          DebitAccount: '',
          TransitNumber: '',
          FinancialInstitutionNumber: '',
        }}
        onSubmit={(values, actions) => {
          postGroupCommission(values, actions);
        }}
        validate={values => validate(values)}
        children={({ values, touched, errors, setFieldValue }) => (
          <Form className={'form-outline form-create'}>
            <h2 className="form-title">Commission Settings</h2>
            <div className="columns is-multiline">
              <div className="column is-3">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="Type"
                  name="CommissionType"
                  type="number"
                  placeholder="Select Type"
                  onInput={({ target }) => {
                    setFieldValue('CommissionType', target.value);

                    const value = parseInt(target.value);

                    setCommissionTypeCategory(COMMISSION_TYPES[value].selectBy);
                  }}
                  options={
                    <>
                      {COMMISSION_TYPES.map(({ display, value }, index) => (
                        <option key={index} value={value}>
                          {display}
                        </option>
                      ))}
                    </>
                  }
                  testID="CommissionType"
                />
              </div>

              {commissionTypeCategory === 'Percent' && (
                <div className="column is-3">
                  <FormInput
                    label="Percent"
                    name="Percent"
                    type="text"
                    placeholder="Percent"
                    errors={errors}
                    touched={touched}
                    testID="Percent"
                  />
                </div>
              )}

              {commissionTypeCategory === 'Fiat' && (
                <div className="column is-3">
                  <FormInput
                    label="Fiat"
                    name="Fiat"
                    type="text"
                    placeholder="Fiat"
                    errors={errors}
                    touched={touched}
                    testID="Fiat"
                  />
                </div>
              )}

              {commissionTypeCategory === 'Both' && (
                <>
                  <div className="column is-4">
                    <FormInput
                      label="Fiat"
                      name="Fiat"
                      type="text"
                      placeholder="Fiat"
                      errors={errors}
                      touched={touched}
                      testID="Fiat"
                    />
                  </div>

                  <div className="column is-4">
                    <FormInput
                      label="Percent"
                      name="Percent"
                      type="text"
                      placeholder="Percent"
                      errors={errors}
                      touched={touched}
                      testID="Percent"
                    />
                  </div>
                </>
              )}
            </div>

            {commissionTypeCategory && commissionTypeCategory !== 'none' && (
              <div className="columns is-multiline">
                <div className="column is-3 form-input-component">
                  <label className="item-label">Payment Type</label>
                  <Field
                    as="select"
                    name="PaymentType"
                    onInput={async ({ target }) => {
                      setFieldValue('PaymentType', target.value);
                      setPaymentTypeCategory(target.value);

                      if (target.value === 'ACH') {
                        await getDebitAccounts();
                      }
                    }}
                    data-testid="PaymentType-select"
                    type="text"
                  >
                    <option value="" disabled>
                      Select a Type
                    </option>
                    {PAYMENT_TYPES.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            )}

            {paymentTypeCategory === 'ACH' && (
              <div className="columns">
                <div className="column is-3">
                  <FormSelect
                    errors={errors}
                    touched={touched}
                    label="Debit Account"
                    name="DebitAccount"
                    type="text"
                    placeholder="Select Debit Account"
                    options={
                      <>
                        {debitAccounts.map(item => {
                          return (
                            <option key={item.Hash} value={item.PayorName}>
                              {item.Name}
                            </option>
                          );
                        })}
                      </>
                    }
                    testID="DebitAccount"
                  />
                </div>
                <div className="column is-3">
                  <FormSelect
                    label="Transaction ACH Code"
                    name="TransactionACHCode"
                    type="number"
                    errors={errors}
                    touched={touched}
                    placeholder="Select Transaction ACH Code"
                    options={
                      <>
                        {ACH_CODES.map(item => (
                          <option key={item.value} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </>
                    }
                    testID="TransactionACHCode"
                  />
                </div>
              </div>
            )}

            {paymentTypeCategory === 'ACH2' && (
              <AchInputs errors={errors} touched={touched} />
            )}

            {paymentTypeCategory === 'Check' && (
              <CheckInputs errors={errors} touched={touched} />
            )}

            <LoadingButton loading={loading} type="submit" testID="Submit" />
          </Form>
        )}
      />
    </>
  );
};

export default AddGroupCommission;
