/* COMPONENT OVERVIEW
- Takes props from parent 
- Renders Account address data
- Calls postLocation function prop & sends request to parent
*/
// Modules
import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
// Components
import { EditableForm } from '../../Forms/EditableForm';
import { EditableInput, EditableSelect } from '../../Forms/EditableInputs';
import { ToastError } from '../../Utilities/Components';
import {
  addressProps,
  reqBoolProp,
  reqFuncProp,
} from '../../Utilities/PropTypes';
// Data
import { EditableStates } from '../../Utilities/Data/StatesList';
import { EditableProvinces } from '../../Utilities/Data/ProvinceList';
// Permissions
import { LOCATIONS_EDIT } from '../../Permissions/Permissions';
import { API } from '../../Config/cfg.js';

const Address = ({
  data,
  editing,
  toggleEdit,
  postLocation,
  isSubmitting,
  name,
}) => {
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    API.get('/countries')
      .then(res => {
        setCountries(res.data.Data);
      })
      .catch(error => {
        ToastError(error.response.data.Message);
      });
  }, []);

  const callPostLocation = (req, actions) => {
    // Sends req to POST call in parent
    if (req.Street2) {
      req.Street2 = req.Street2.toString();
    }

    const Country = countries.find(c => c.Code === country);
    delete req.CountryCode;

    if (Country) {
      req.CountryHash = Country.Hash;
    }

    postLocation('addresses', data.Hash, null, req, 'editingAddress', actions);
  };

  const {
    Active,
    City,
    Latitude,
    Longitude,
    StateCode,
    Country,
    Street,
    Street2,
    ZIP,
  } = data;

  const copyText = `${name}\n${Street}\n${
    Street2 ? Street2 + `\n` : ''
  }${City}, ${StateCode} ${ZIP}`;

  return (
    <EditableForm
      callBack={callPostLocation}
      setValues={{
        Active,
        City,
        CountryHash: Country?.Hash,
        Latitude,
        Longitude,
        StateCode,
        Street,
        Street2,
        Zip: ZIP,
      }}
      customValidations={() => {
        let errors = {};
        return errors;
      }}
      editing={editing}
      permission={LOCATIONS_EDIT}
      triggerEdit={toggleEdit}
      editType="editingAddress"
      sectionTitle="Address"
      isSubmitting={isSubmitting}
      copyText={copyText}
      copy
    >
      <div className="columns">
        <div className="column">
          <EditableInput
            name="Street"
            label="Street"
            type="text"
            currentValue={Street}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="Street2"
            label="Apt, Ste, Unit"
            type="text"
            currentValue={Street2}
            editing={editing}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableInput
            name="City"
            label="City"
            type="text"
            currentValue={City}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="Zip"
            label="Postal Code"
            type="text"
            currentValue={ZIP}
            editing={editing}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <div className="form-row">
            {editing ? (
              <>
                <label className="item-label">Country: </label>
                <Field
                  as="select"
                  name="CountryCode"
                  className="form-row"
                  onChange={e => {
                    const Country = JSON.parse(e.target.value);
                    setCountry(Country.Code);
                  }}
                >
                  <option value="" disabled>
                    Select a Country
                  </option>
                  {countries.map(c => (
                    <option
                      key={c.Hash}
                      value={JSON.stringify(c)}
                      selected={Country.Code === c.Code}
                    >
                      {c.Name}
                    </option>
                  ))}
                </Field>
              </>
            ) : (
              <>
                <label className="item-label">Country: </label>
                <p>{Country.Name}</p>
              </>
            )}
          </div>
        </div>
        <div className="column">
          {country === 'CA' ? (
            <EditableSelect
              name="StateCode"
              label="State/Province"
              placeholder="Select a Province"
              currentValue={StateCode}
              data={EditableProvinces}
              editing={editing}
            />
          ) : (
            <EditableSelect
              name="StateCode"
              label="State/Province"
              placeholder="Select a State"
              currentValue={StateCode}
              data={EditableStates}
              editing={editing}
            />
          )}
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableInput
            name="Latitude"
            label="Latitude"
            type="text"
            currentValue={Latitude}
            editing={editing}
            disabled
          />
        </div>
        <div className="column">
          <EditableInput
            name="Longitude"
            label="Longitude"
            type="text"
            currentValue={Longitude}
            editing={editing}
            disabled
          />
        </div>
      </div>
    </EditableForm>
  );
};

Address.propTypes = {
  editing: reqBoolProp,
  postLocation: reqFuncProp,
  toggleEdit: reqFuncProp,
  data: addressProps,
};

export default Address;
