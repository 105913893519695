const banKeys = {
  "Account.Hash": {
    Label: "Account Hash",
    Type: "string",
  },
  BannedBy: {
    Label: "Banned By",
    Type: "string",
  },
  BanDate: {
    Label: "Ban Date",
    Type: "daterange",
  },
  BanReason: {
    Label: "Ban Reason",
    Type: "int",
    Options: [
      {
        Display: "Aggressive Towards Agents",
        Value: 2,
      },
      {
        Display: "Aggressive Towards Customer Service",
        Value: 1,
      },
      {
        Display: "Banned on Import",
        Value: 10,
      },
      {
        Display: "Compliance Reasons",
        Value: 5,
      },
      {
        Display: "Enhance Due Diligence",
        Value: 6,
      },
      {
        Display: "Identification Issues",
        Value: 7,
      },
      {
        Display: "Illicit Activity",
        Value: 0,
      },
      {
        Display: "Other",
        Value: 9,
      },
      {
        Display: "Teller Referral",
        Value: 8,
      },
      {
        Display: "Unknown Account Rep",
        Value: 4,
      },
      {
        Display: "Victim of Fraud",
        Value: 3,
      },
    ],
  },
  "Country.Code": {
    Label: "Country",
    Type: "string",
    Options: [
      {
        Display: "United States",
        Value: "US",
      },
      {
        Display: "Canada",
        Value: "CA",
      },
    ],
  },
  CreatedAt: {
    Label: "Create Date",
    Type: "daterange",
  },
  "Account.FirstName": {
    Label: "First Name",
    Type: "string",
  },
  "Account.LastName": {
    Label: "Last Name",
    Type: "string",
  },
  UpdatedAt: {
    Label: "Last Update Date",
    Type: "daterange",
  },
  "PhoneNumbers.Phone": {
    Label: "Phone Number",
    Type: "string",
  },
};
export default banKeys;
