// Modules
import React, { Fragment } from "react";
// Components
import { GenericForm, FormikContext } from "../../../Forms/Form";
import { FormSelect } from "../../../Forms/FormInputs";
import { reqArrProp } from "./../../../Utilities/PropTypes";

const PreferredPhone = ({ phoneNumbers }) => {
  let currentPrefered = phoneNumbers.filter(phone => phone.Preferred === true);
  return (
    <GenericForm
      endpoint="phones"
      postReq={true}
      setValues={{
        Preferred: true,
        Hash: ""
      }}
      accountSettings={true}
      formTitle={null}
      subTitle="Current Preferred Phone "
      goBack={{
        show: false,
        setHistory: null
      }}
      toastMsg="New Preferred Address has been successfully set"
    >
      <Fragment>
        <div style={{ marginBottom: "20px" }}>
          <p>{currentPrefered[0].Phone}</p>
        </div>
        <FormikContext.Consumer>
          {props => (
            <Fragment>
              <h2 className="form-title">Change Preferred Phone</h2>
              <FormSelect
                errors={props.errors}
                touched={props.touched}
                label="List of Phone Numbers"
                name="Hash"
                type="select"
                placeholder="Select a Phone Number"
                options={phoneNumbers.map(phone => (
                  <option key={phone.Hash} value={phone.Hash}>
                    {phone.Phone}
                  </option>
                ))}
              />
            </Fragment>
          )}
        </FormikContext.Consumer>
      </Fragment>
    </GenericForm>
  );
};

PreferredPhone.propTypes = {
  phoneNumbers: reqArrProp
};

export default PreferredPhone;
