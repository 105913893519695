import React from "react";
import { Link } from "react-router-dom";
// Components
import { InvestigationDispositionPill } from "../../Utilities/Components/TablePills";
// Helpers - Formatters
import { formatDate } from "../../Utilities/Formatters";
import { InvestigationTypePill } from "../../Utilities/Components/TablePills";
// Permissions
import { COMPLIANCE_INVEST_ACTION } from "../../Permissions/Permissions";
import Can from "../../Permissions/Can";

const columnBuilder = (openModal) => [
  {
    Header: "Investigation #",
    accessor: "Hash",
    Cell: ({ row: { original } }) =>
      original.Hash ? (
        <Link
          className="table-pill"
          to={`/compliance/investigations/${original.Hash}`}
        >
          {original.Code}
        </Link>
      ) : null,
    sortMethod: "InvestigationNumber",
  },
  {
    Header: "Type",
    Cell: ({ row: { original } }) => InvestigationTypePill(original.Type),
  },
  {
    Header: "Customer Name(s)",
    style: { textAlign: "left " },
    accessor: "Account.FullName",
    Cell: ({ row: { original } }) =>
      original.Accounts ? showAllAccounts(original.Accounts) : "-",
    sortMethod: "LastName",
  },
  {
    Header: "Create Date",
    Cell: ({ row: { original } }) => formatDate(original.EscalationDate),
  },

  {
    Header: "Due Date",
    Cell: ({ row: { original } }) => formatDate(original.DueDate),
    sortMethod: "DueDate",
  },
  {
    Header: "Follow-up Due Date",
    Cell: ({ row: { original } }) => formatDate(original.FollowUpDueDate),
  },
  {
    Header: "Disposition",
    Cell: ({ row: { original } }) =>
      original.Disposition >= 0
        ? InvestigationDispositionPill(
            original.Disposition,
            original.DispositionText
          )
        : "-",
  },
  {
    Header: "Assigned To",
    accessor: "AssignedTo.FullName",
    Cell: ({ row: { original } }) =>
      original.AssignedTo ? original.AssignedTo.FullName : "-",
  },
  {
    Header: "Action",
    accessor: "Action",
    Cell: ({ row: { original } }) => (
      <Can do={COMPLIANCE_INVEST_ACTION}>
        <button
          className="column-btn create"
          onClick={() => openModal(original.Hash, original.AssignedTo)}
        >
          {!original.AssignedTo ? "Assign" : "Edit"}
        </button>
      </Can>
    ),
  },
  {
    Header: "Country of Origin",
    accessor: "Country.Code",
  },
];

// Checks for mulitple accounts and displays the first
// account as a link and display # of remaining linked accounts
const showAllAccounts = (data) => {
  let firstAccount = data[0];
  const remainingAccounts = data.length - 1;
  if (data.length > 1) {
    return (
      <>
        <Link className="table-pill" to={`/accounts/${firstAccount.Hash}`}>
          {firstAccount.FullName}
        </Link>
        + {remainingAccounts}
      </>
    );
  } else {
    return (
      <Link className="table-pill" to={`/accounts/${firstAccount.Hash}`}>
        {firstAccount.FullName}
      </Link>
    );
  }
};

export default columnBuilder;
