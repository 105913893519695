// Modules
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Components
import Table from './../../../Table/Table';
import {
  PlatformModelNamePill,
  TradeTypePill,
} from '../../../Utilities/Components/TablePills';
import {
  GenericModal,
  NoteIndicator,
  ToastError,
  ToastSuccess,
} from '../../../Utilities/Components';
// PropTypes
import { reqStrProp } from '../../../Utilities/PropTypes';
// Icons
import Icon from '../../../Icons/Icon';
import { ICONS } from '../../../Icons/IconsPath';
// Permissions
import { LOCATIONS_EDIT } from '../../../Permissions/Permissions';
// Config
import { API } from '../../../Config/cfg.js';

class Platforms extends Component {
  state = {
    platformHash: '',
    modalIsOpen: false,
    callAPI: false,
  };

  openModal = platformHash => {
    this.setState({ modalIsOpen: true, platformHash });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  removePlatform = () => {
    const { platformHash } = this.state;
    const { hash } = this.props;
    const data = {
      PlatformHash: platformHash,
      Remove: true,
    };
    API.post(`locations/${hash}/platforms`, data)
      .then(res => {
        this.setState({
          modalIsOpen: false,
          callAPI: true,
        });
        ToastSuccess(`Platform has been Removed`);
      })
      .catch(err => {
        ToastError(err);
      });
  };

  render() {
    const { callAPI, modalIsOpen } = this.state;
    const { hash } = this.props;

    return (
      <React.Fragment>
        <GenericModal
          modalIsOpen={modalIsOpen}
          closeModal={this.closeModal}
          headerMsg="ATTENTION: Removing Platform"
          viewMsg="This action will deactivate and remove this platform from this location"
          btnMsg="By clicking 'Remove' the current Platform will be deactivated and removed"
          btnText="Remove"
          btnAction={this.removePlatform}
        />
        <Table
          columns={columnBuilder(this.openModal)}
          endpoint={`locations/${hash}/platforms`}
          nested={true}
          tablename="Platforms"
          triggerAPI={callAPI}
          actions={[
            {
              name: 'Add Platform',
              link: '/locations/add-platform',
              params: hash,
              permission: LOCATIONS_EDIT,
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

Platforms.propTypes = {
  hash: reqStrProp,
};

export default Platforms;

const columnBuilder = func => {
  return [
    {
      Header: 'Device Identifier',
      accessor: 'DeviceIdentifier',
      Cell: ({ row: { original } }) => (
        <Link className="table-pill" to={`/platforms/${original.Hash}`}>
          {original.DeviceIdentifier.toUpperCase()}
        </Link>
      ),
    },
    {
      Header: 'Platform',
      accessor: 'PlatformType.Name',
      Cell: ({ row: { original } }) =>
        PlatformModelNamePill(original.PlatformType.Name),
    },
    {
      Header: 'Model',
      accessor: 'PlatformType.Model',
      Cell: ({ row: { original } }) =>
        PlatformModelNamePill(original.PlatformType.Model),
    },

    {
      Header: 'Capacity',
      accessor: 'NoteCapacityRatio',
      Cell: ({ row: { original } }) => {
        if (original.PlatformType.Name === 'Kiosk') {
          return (
            <NoteIndicator
              capacity={original.NoteCapacityRatio * 100}
              noteCapacity={original.NoteCapacity}
              noteCount={original.NoteCount}
            />
          );
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Products',
      accessor: 'Products',
      Cell: ({ row: { original } }) =>
        original.Products
          ? original.Products.split(', ').map(product => TradeTypePill(product))
          : '-',
    },
    {
      Header: 'Active',
      accessor: 'Active',
      Cell: ({ row: { original } }) =>
        original.Active ? (
          <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
        ) : (
          <Icon
            icon={ICONS.INACTIVE}
            size="16"
            color="#ff3560"
            rightMargin="0"
          />
        ),
      maxWidth: 85,
    },
    {
      Header: 'Online',
      accessor: 'Available',
      Cell: ({ row: { original } }) => (
        <Icon
          icon={ICONS.BROADCAST}
          size="15"
          color={original.Available ? '#006994' : 'rgba(0,0,0,.15)'}
          rightMargin="0"
        />
      ),
      maxWidth: 85,
    },
    {
      Header: 'Remove',
      Cell: ({ row: { original } }) => {
        return (
          <button
            className="column-btn remove"
            onClick={() => func(original.Hash)}
          >
            Remove Platform
          </button>
        );
      },
    },
  ];
};
