/* COMPONENT OVERVIEW
- Builds routing for entire app
*/
// Modules
import React from 'react';
import { Route, Switch } from 'react-router-dom';
// Components - Model Index
import AuthTabs from './AuthTabs';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

// class Routes extends Component {
export const PublicRoutes = props => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={routeProps => <AuthTabs {...routeProps} props={props} />}
      />
      {/* Invitation set to SignUp */}
      <Route
        path="/invitations/:hash"
        render={routeProps => <AuthTabs {...routeProps} props={props} />}
      />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route
        path="/reset-password/:hash"
        render={routeProps => <ResetPassword {...routeProps} props={props} />}
      />
      {/* hack for catching all private routes untill better solution */}
      <Route
        path="*"
        render={routeProps => <AuthTabs {...routeProps} props={props} />}
      />
    </Switch>
  );
};

export default PublicRoutes;
