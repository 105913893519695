/* eslint no-console: ["error", { allow: [ "error"] }] */

import React, { Component } from 'react';
// Platform Components
import Asset from './../../Asset/Asset';
import PlatformOverview from './PlatformOverview';
import TabsContainer from './TabsContainer';
// Platform Components
import CredentialWizard from '../CredentialWizard';
import CashCollectionModal from './CashCollectionModal';
// Components
import {
  ButtonAction,
  GenericModal,
  GoBack,
  Header,
  Loading,
  ToastError,
  ToastSuccess,
} from './../../Utilities/Components';
// Images
import Placeholder from './../../images/dm_placeholder.svg';
import {
  Kiosk,
  Genmega,
  Apple,
  Slabb,
  Web,
  DCS,
  Futura,
  Hyosung,
  Maya,
  Zyng,
} from '../../images';
// Icons
import { ICONS } from '../../Icons/IconsPath';
// Configs
import { API } from '../../Config/cfg.js';
// Permissions
import { LOCATIONS_EDIT } from './../../Permissions/Permissions';

class SinglePlatform extends Component {
  state = {
    data: {},
    loading: true,
    isOpen: false,
    isReinitializeOpen: false,
    isRebootIpadOpen: false,
    isAtmOpen: false,
    showQR: false,
    dataURIs: [],
    shortCode: {},
    editingPlatform: false,
    errors: {},
    groups: [],
    collecting: false,
  };

  componentDidMount() {
    this.getEndpoints();
  }

  toggleEdit = type => {
    this.setState({ [type]: !this.state[type] });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  putReinitialize = () => {
    this.setState({ collecting: true });
    API.put(`platforms/${this.state.data.Hash}/initialize`)
      .then(res => {
        ToastSuccess('Kiosk has been reinitialized');
        // Display another modal
        this.setState({
          dataURIs: [res.data.DataURID],
          showQR: true,
        });
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => this.setState({ isReinitializeOpen: false }));
  };

  postPlatforms = (hash, data, editing, actions) => {
    API.post(`/platforms/${hash}`, data)
      .then(() => {
        this.setState({ [editing]: false });
        ToastSuccess('Changes to platform details have been saved');
        this.getEndpoints();
        actions.setSubmitting(false);
      })
      .catch(error => {
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  putEndpointEvent = EventTime => {
    this.setState({ collecting: true });
    const { pathname } = this.props.location;
    API.put(pathname + '/events', {
      EventTime: EventTime['EventTime'],
      Event: 2,
    })
      .then(res => {
        ToastSuccess('Cash has been collected');
        // Updates local state data from updated API response
        this.setState({
          data: res.data,
        });
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => this.setState({ collecting: false, isOpen: false }));
  };

  getEndpoints = () => {
    const { pathname } = this.props.location;
    API.get(pathname)
      .then(res => {
        this.setState({
          data: res.data,
          loading: false,
        });
        this.getGroups();
      })
      .catch(error => {
        this.setState({
          errors: error.response.data,
        });
      });
  };

  getGroups = () => {
    API.get('/groups?all=true')
      .then(res => {
        this.setState({
          groups: res.data.Data,
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  putRebootIpad = () => {
    this.setState({ collecting: true });
    API.put(`platforms/${this.state.data.Hash}/initialize`)
      .then(res => {
        const { data } = res;
        ToastSuccess('iPad has been reinitialized');
        // Display another modal
        this.setState({
          dataURIs: [
            data.DataURIA,
            data.DataURIB,
            data.DataURIC,
            data.DataURID,
          ],
          showQR: true,
        });
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => this.setState({ isRebootIpadOpen: false }));
  };

  putRegistration = () => {
    API.put(`platforms/${this.state.data.Hash}/initialize`)
      .then(res => {
        ToastSuccess('This ATM has been re-registered');
        this.setState({
          shortCode: res.data.Shortcode,
        });
        this.setState({ isAtmOpen: true });
      })
      .catch(err => {
        ToastError(err);
        this.setState({ isAtmOpen: false });
      });
  };

  setPlatformName = data => {
    let name = '';

    const { Model, Name } = data.PlatformType;

    const platform = Model ? Model : Name;

    switch (platform) {
      case 'Legacy':
        name = { icon: Apple };
        break;
      case 'Bravo':
        name = { icon: Apple };
        break;
      case 'DCS POS':
        name = { images: [{ image: DCS }] };
        break;
      case 'Futurakiosk':
        name = { images: [{ image: Futura }] };
        break;
      case 'web':
        name = { icon: Web };
        break;
      case 'Kiosk.com':
        name = { icon: Kiosk };
        break;
      case 'Maya Tech':
        name = { images: [{ image: Maya }] };
        break;
      case 'Slabb':
        name = { icon: Slabb };
        break;
      case 'Genmega.XL':
      case 'Genmega.UK':
      case 'Genmega.CDU':
      case 'Genmega.CARD':
      case 'Genmega.Sidecar':
        name = { icon: Genmega };
        break;
      case 'Hyosung.ATM':
      case 'Hyosung.Sidecar':
        name = { images: [{ image: Hyosung }] };
        break;
      case 'Zyng Tech':
        name = { images: [{ image: Zyng }] };
        break;
      default:
        name = { icon: Placeholder };
        break;
    }

    return name;
  };

  assetDisplay = () => {
    const { data } = this.state;
    return data.LastScreenShotUrl !== null
      ? { images: [{ image: data.LastScreenShotUrl }] }
      : this.setPlatformName(data);
  };

  render() {
    const {
      data,
      dataURIs,
      loading,
      editingPlatform,
      errors,
      groups,
      collecting,
      isOpen,
      isReinitializeOpen,
      isAtmOpen,
      isRebootIpadOpen,
      shortCode,
      showQR,
    } = this.state;
    const { DeviceIdentifier, PlatformType } = data;

    const setModalMessage = () => {
      let setPlatform = PlatformType.Model
        ? `${PlatformType.Name} ${PlatformType.Model}`
        : PlatformType.Name;
      let businessName = data.Location && `at  ${data.Location.BusinessName}`;
      let setCashAmount = Math.ceil(data.NoteCapacityRatio * 100);

      return `By clicking Confirm, you are creating a cash collection event ${businessName} for ${setPlatform} ${DeviceIdentifier} with a cash capacity of $ ${setCashAmount}%`;
    };

    const setReinitializeModalMessage = () => {
      let setPlatform = PlatformType.Model
        ? `${PlatformType.Name} ${PlatformType.Model}`
        : PlatformType.Name;
      let businessName = data.Location && `at  ${data.Location.BusinessName}`;

      return `By clicking Confirm, you are reinitializing the ${setPlatform} ${DeviceIdentifier} ${
        businessName && businessName
      }`;
    };

    if (loading) {
      return <Loading errors={errors} />;
    }

    return (
      <React.Fragment>
        <CashCollectionModal
          isOpen={isOpen}
          closeModal={this.closeModal}
          headerMsg="Confirm Cash Collection"
          viewMsg={setModalMessage()}
          btnText={
            !collecting ? (
              'Confirm'
            ) : (
              <i
                className="fa fa-sync fa-spin"
                style={{ marginRight: '5px' }}
              />
            )
          }
          callBack={this.putEndpointEvent}
        />
        {/* Reinitialize Kiosk Modal */}

        <GenericModal
          modalIsOpen={isReinitializeOpen}
          closeModal={() => {
            this.setState({ isReinitializeOpen: false });
          }}
          headerMsg="Confirm Reinitialize Kiosk"
          viewMsg={setReinitializeModalMessage()}
          btnAction={this.putReinitialize}
        />

        {/* Reinitialize Modal Success Response w/QR code*/}
        <CredentialWizard
          dataURIs={dataURIs && dataURIs}
          open={showQR}
          closeModal={() => this.setState({ showQR: false })}
          history={this.props.history}
        />
        {/* Reinitialize iPad Modal */}
        <GenericModal
          modalIsOpen={isRebootIpadOpen}
          closeModal={() => {
            this.setState({ isRebootIpadOpen: false });
          }}
          headerMsg="Confirm Reinitialize iPad"
          viewMsg={setReinitializeModalMessage()}
          btnAction={this.putRebootIpad}
        />

        {/* Re-register ATM */}
        <GenericModal
          modalIsOpen={isAtmOpen}
          importantData={[{ label: 'Short Code', value: shortCode }]}
          headerMsg="Confirm Re-register ATM"
          viewMsg="Please use the following code to set up your ATM."
          btnText="Close"
          btnAction={() => this.setState({ isAtmOpen: false })}
          hideClose={true}
        />

        <div>
          <GoBack
            history={this.props.history}
            defaultPath={`/platforms`}
            defaultName={`Platforms`}
          />
          <Header
            title={DeviceIdentifier}
            code={
              PlatformType.Model
                ? `${PlatformType.Name} ${PlatformType.Model}`
                : PlatformType.Name
            }
          />
          <div className="columns">
            <div className="column">
              <Asset {...this.assetDisplay()} height={'50%'} width={'50%'} />
              <div className="columns is-centered btn-icons">
                {PlatformType.Name === 'Kiosk' && (
                  <ButtonAction
                    icon={ICONS.USD}
                    toolTip="Emits Cash Collection Event"
                    className="primary-btn-outline"
                    onClick={this.openModal}
                    permission={LOCATIONS_EDIT}
                  />
                )}
                {PlatformType.Name === 'iPad' &&
                  PlatformType.Model !== 'Legacy' && (
                    <ButtonAction
                      icon={ICONS.SETTINGS}
                      toolTip="Reinitialize iPad"
                      className="primary-btn-outline"
                      onClick={() => this.setState({ isRebootIpadOpen: true })}
                      permission={LOCATIONS_EDIT}
                    />
                  )}
                {PlatformType.Name === 'Kiosk' &&
                  PlatformType.Model.includes('.Sidecar') && (
                    <ButtonAction
                      icon={ICONS.SETTINGS}
                      toolTip="Reinitialize Sidecar"
                      className="primary-btn-outline"
                      onClick={() =>
                        this.setState({ isReinitializeOpen: true })
                      }
                      permission={LOCATIONS_EDIT}
                    />
                  )}

                {PlatformType.Name === 'Kiosk' &&
                  !PlatformType.ThirdParty &&
                  !PlatformType.Model.includes('.Sidecar') && (
                    <ButtonAction
                      icon={ICONS.SETTINGS}
                      toolTip="Reinitialize Kiosk"
                      className="primary-btn-outline"
                      onClick={() =>
                        this.setState({ isReinitializeOpen: true })
                      }
                      permission={LOCATIONS_EDIT}
                    />
                  )}

                {data.PlatformType.Name === 'ATM' && (
                  <ButtonAction
                    icon={ICONS.SETTINGS}
                    toolTip="Re-registered ATM"
                    className="primary-btn-outline"
                    onClick={() => {
                      this.putRegistration();
                    }}
                    permission={LOCATIONS_EDIT}
                  />
                )}
              </div>
            </div>
            <div className="column is-7">
              <PlatformOverview
                data={data}
                editing={editingPlatform}
                postPlatforms={this.postPlatforms}
                toggleEdit={this.toggleEdit}
                groups={groups}
              />
            </div>
          </div>
          <br />
        </div>
        <TabsContainer data={data} />
      </React.Fragment>
    );
  }
}

export default SinglePlatform;
