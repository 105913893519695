const investigationKeys = {
  'Accounts.Hash': {
    Label: 'Account Hash',
    Type: 'string',
  },
  ReviewedAndApproved: {
    Label: 'Approved',
    Type: 'bool',
    Options: [
      {
        Display: 'True',
        Value: true,
      },
      {
        Display: 'False',
        Value: false,
      },
    ],
  },
  Code: {
    Label: 'Code',
    Type: 'string',
  },
  'Country.Code': {
    Label: 'Country',
    Type: 'string',
    Options: [
      {
        Display: 'United States',
        Value: 'US',
      },
      {
        Display: 'Canada',
        Value: 'CA',
      },
    ],
  },
  CreatedAt: {
    Label: 'Create Date',
    Type: 'daterange',
  },
  Disposition: {
    Label: 'Disposition',
    Options: [
      {
        Display: 'CNS',
        Value: 0,
      },
      {
        Display: 'SAR Filed',
        Value: 1,
      },
    ],
    Type: 'int',
  },
  DueDate: {
    Label: 'Due Date',
    Type: 'daterange',
  },
  'Accounts.FirstName': {
    Label: 'First Name',
    Type: 'string',
  },
  FollowUpDueDate: {
    Label: 'Follow-up Due Date',
    Type: 'daterange',
  },
  'Accounts.LastName': {
    Label: 'Last Name',
    Type: 'string',
  },
  AssignedTo: {
    Label: 'Assigned To',
    Type: 'string',
  },
  UpdatedAt: {
    Label: 'Last Update Date',
    Type: 'daterange',
  },
};
export default investigationKeys;
