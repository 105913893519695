import formatLowerCase from "./FormatLowerCase";

const formatServiceFrequency = (status) => {
  let Name, Hash, value;
  const formattedStatus = formatLowerCase(status);
  switch (formattedStatus) {
    case "twice a week":
      Name = Hash = "Twice a week";
      value = 1;
      break;
    case "every other week":
      Name = Hash = "Every other week";
      value = 2;
      break;
    case "weekly":
      Name = Hash = "Weekly";
      value = 3;
      break;
    case "monthly":
      Name = Hash = "Monthly";
      value = 4;
      break;
    case "on demand":
      Name = Hash = "On Demand";
      value = 5;
      break;
    default:
      Name = Hash = "";
      value = "";
  }
  return { Name, Hash, value };
};

export default formatServiceFrequency;
