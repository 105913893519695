const RefundMethods = [
  { label: 'Cash', value: 0 },
  { label: 'Debit', value: 1 },
  { label: 'ACH', value: 2 },
  { label: 'Wire Transfer', value: 3 },
  { label: 'Check', value: 4 },
  { label: 'Voucher', value: 5 },
];

export default RefundMethods;
