/* eslint no-console: ["error", { allow: [ "error"] }] */

/* COMPONENT OVERVIEW
- GETs the following data from API
  - Location
  - Groups
  - Debit accounts
  - Cash couriers
- Renders location's data
  - Passes data to children and Renders it in tabular layout
- POST updates to single location's data
*/
// Modules
import React, { Component } from 'react';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
// Location Components
import Compliance from './Compliance';
import GeoLocation from '../../GeoLocation/GeoLocation';
import Hours from './Hours';
import LocationActions from './../SinglePage/LocationActions';
import LocationMetrics from './LocationMetrics';
import LocationAddress from './Address';
import Overview from './Overview';
import TabsContainer from './TabsContainer';
// Components
import {
  GoBack,
  Header,
  Loading,
  ToastError,
  ToastSuccess,
} from '../../Utilities/Components';
// Helpers - Formatters
import { formatDate } from '../../Utilities/Formatters';
import CashMgmt from './CashManagement';
// Configs
import { API } from '../../Config/cfg.js';

class SingleLocation extends Component {
  state = {
    cashCouriers: [],
    creditAccounts: [],
    data: {},
    debitAccounts: [],
    editingAddress: false,
    editingCashMgmt: false,
    editingCompliance: false,
    editingHours: false,
    editingOverview: false,
    editingFrequency: false,
    groups: [],
    loading: true,
    errors: {},
  };

  componentDidMount() {
    this.getLocation();
  }

  getLocation = () => {
    const {
      params: { hash },
    } = this.props.match;

    API.get(`/locations/${hash}`)
      .then(res => {
        this.setState({
          data: res.data,
          loading: false,
        });
      })
      .catch(error => {
        if (error?.response) {
          this.setState({
            errors: error.response.data,
          });
        }
      });
  };

  getGroups() {
    API.get('/groups?all=true')
      .then(res => {
        this.setState({
          groups: res.data.Data,
          loading: false,
        });
        this.getDebitAccounts();
      })
      .catch(error => {
        console.error(error);
      });
  }

  getDebitAccounts() {
    API.get('/accounting/ach?all=true')
      .then(res => {
        this.setState({
          debitAccounts: res.data.Data,
        });
        this.getCashCouriers();
      })
      .catch(error => {
        console.error(error);
      });
  }

  getCashCouriers() {
    API.get('/accounting/cash-couriers')
      .then(res => {
        this.setState({
          cashCouriers: res.data,
        });
        this.getCreditAccounts();
      })
      .catch(error => {
        console.error(error);
      });
  }

  getCreditAccounts = () => {
    API.get('/accounting/credit-accounts')
      .then(res => {
        this.setState({
          creditAccounts: res.data,
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  updateCashManagement = (endpoint, hash, formData, editing, actions) => {
    const frequency = formData.ServiceFrequency;
    let requests = [
      this.postLocationCashManagement(endpoint, hash, formData),
      this.postFrequency(hash, frequency),
    ];
    if (!frequency) {
      requests.pop();
    }
    Promise.all(requests)
      .then(() => {
        this.setState({ [editing]: false });
        ToastSuccess('Changes to location details have been saved');
        this.getLocation();
      })
      .catch(error => {
        ToastError(error);
        this.setState({ editing: true });
      })
      .finally(() => actions && actions.setSubmitting(false));
  };

  postLocation = (endpoint, hash, params, data, editing, actions) => {
    const url = params
      ? `/${endpoint}/${hash}/${params}`
      : `/${endpoint}/${hash}`;

    API.post(url, data)
      .then(res => {
        this.setState({ [editing]: false });
        ToastSuccess('Changes to location details have been saved');
        this.getLocation();
        actions && actions.setSubmitting(false);
      })
      .catch(error => {
        ToastError(error);
        this.setState({ editing: true });
        actions && actions.setSubmitting(false);
      });
  };

  postLocationCashManagement = (endpoint, hash, data) => {
    delete data.ServiceFrequency;
    return API.post(`/${endpoint}/${hash}`, data);
  };

  postFrequency = async (hash, ServiceFrequency) => {
    try {
      await API.post(`/locations/${hash}/service-frequency`, {
        ServiceFrequency,
      });
    } catch (error) {
      ToastError(error);
    }
  };

  decommissionLocation = actions => {
    const {
      params: { hash },
    } = this.props.match;
    API.delete(`/locations/${hash}`)
      .then(res => {
        ToastSuccess(`${this.state.data.BusinessName} is Decommissioned`);
        this.getLocation();
        actions(false);
      })
      .catch(error => {
        ToastError(error);
      });
  };

  toggleEdit = type => {
    this.setState({ [type]: !this.state[type] });
    // should call on editing only
    if (!this.state[type] && this.state.groups.length === 0) {
      this.getGroups();
    }
  };

  render() {
    const { location, history } = this.props;
    const {
      cashCouriers,
      creditAccounts,
      data,
      debitAccounts,
      editingAddress,
      editingCashMgmt,
      editingCompliance,
      editingHours,
      editingOverview,
      groups,
      loading,
      errors,
    } = this.state;

    if (loading) {
      return <Loading errors={errors} />;
    }

    const DecommissionedDate = data.DecommissionedDate && (
      <p className="pill ban">{`Decommissioned Date - ${formatDate(
        data.DecommissionedDate
      )}`}</p>
    );

    const Decommissioned = data.Decommissioned && !data.DecommissionedDate && (
      <p className="pill ban">Decommissioned</p>
    );

    return (
      <React.Fragment>
        <GoBack
          history={history}
          defaultPath="/locations"
          defaultName="Locations"
        />
        <div className="columns">
          <div className="column is-narrow">
            <Header code={data.Code} title={`${data.BusinessName}`} />
          </div>
          <div className="column is-narrow">
            {DecommissionedDate}
            {Decommissioned}
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="map-container-btn">
              <GeoLocation address={data.Address} />
            </div>
            <LocationActions
              decommissionLocation={this.decommissionLocation}
              data={data}
              history={history}
            />
            {data.LocationTransactionStatistics && (
              <LocationMetrics stats={data.LocationTransactionStatistics} />
            )}
          </div>
          <Tabs className="column is-7 tabs-container nested-tabs">
            <TabList className="columns tab-list">
              <Tab className="column tab">Overview</Tab>
              <Tab className="column tab">Hours</Tab>
              <Tab className="column tab">Compliance</Tab>
              <Tab className="column tab">Cash Collection</Tab>
            </TabList>

            <TabPanel>
              <Overview
                data={data}
                editing={editingOverview}
                groups={groups}
                postLocation={this.postLocation}
                toggleEdit={this.toggleEdit}
              />
              <LocationAddress
                name={data.BusinessName}
                data={data.Address}
                editing={editingAddress}
                postLocation={this.postLocation}
                toggleEdit={this.toggleEdit}
              />
            </TabPanel>
            <TabPanel>
              <Hours
                data={data}
                editing={editingHours}
                postLocation={this.postLocation}
                toggleEdit={this.toggleEdit}
              />
            </TabPanel>
            <TabPanel>
              <Compliance
                data={data}
                editing={editingCompliance}
                postLocation={this.postLocation}
                toggleEdit={this.toggleEdit}
              />
            </TabPanel>
            <TabPanel>
              <CashMgmt
                editing={editingCashMgmt}
                cashCouriers={cashCouriers}
                creditAccounts={creditAccounts}
                data={data}
                debitAccounts={debitAccounts}
                postLocation={this.updateCashManagement}
                toggleEdit={this.toggleEdit}
              />
            </TabPanel>
          </Tabs>
        </div>
        <TabsContainer
          hash={data.Hash}
          data={data}
          history={history}
          location={location}
          getLocation={this.getLocation}
        />
      </React.Fragment>
    );
  }
}

export default SingleLocation;
