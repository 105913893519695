// Modules
import React from "react";
import { Link } from "react-router-dom";
// Components
import { PlatformPill } from "../../Utilities/Components/TablePills";
// Helpers - Formatters
import { formatPrice, formatDateTime } from "../../Utilities/Formatters";

const columnBuilder = [
  {
    Header: "Date",
    accessor: "CollectedAt",
    Cell: ({ row: { original } }) => formatDateTime(original.CollectedAt),
    width: 100,
    sortMethod: "Date",
  },
  // location
  {
    Header: "Location",
    accessor: "Location.Address.StateCode",
    minWidth: 210,
    Cell: ({ row: { original } }) => (
      <>
        {original.Location.Address ? (
          <Link
            className="table-pill"
            to={`/locations/${original.Location.Hash}`}
          >
            {original.Location.Address.StateCode},{" "}
            {original.Location.Address.City}, {original.Location.Address.Street}
          </Link>
        ) : (
          "-"
        )}
      </>
    ),
    sortMethod: "CityState",
  },
  // group name
  {
    Header: "Group Name",
    accessor: "Group.Name",
    width: 175,
    style: { textAlign: "left " },
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/groups/${original.Group.Hash}`}>
        {original.Group.Name}
      </Link>
    ),
    sortMethod: "GroupName",
  },
  // amount collected
  {
    Header: "Amount Collected",
    Cell: ({ row: { original } }) => {
      return (
        <React.Fragment>
          <p>{formatPrice(original.AmountCollected)}</p>
        </React.Fragment>
      );
    },
    sortMethod: "AmountCollected",
  },
  {
    Header: "Platform",
    accessor: "Hash",
    minWidth: 155,
    Cell: ({ row: { original } }) => (
      <React.Fragment>
        <Link
          className="table-pill"
          to={`/platforms/${original.Platform.Hash}`}
        >
          {original.Platform.DeviceIdentifier}
        </Link>
        <br />
        {original.Platform.PlatformType
          ? PlatformPill(
              `${original.Platform.PlatformType.Name} ${original.Platform.PlatformType.Model}`
            )
          : "-"}
      </React.Fragment>
    ),
    sortMethod: "DeviceIdentifier",
  },
  // carrier
  {
    Header: "Cash Collection",
    Cell: ({ row: { original } }) => {
      return (
        <React.Fragment>
          <p>{original.Carrier}</p>
        </React.Fragment>
      );
    },
    sortMethod: "Carrier",
  },
  // collection type
  {
    Header: "Collection Type",
    Cell: ({ row: { original } }) => {
      return (
        <React.Fragment>
          <p>{original.CollectionType}</p>
        </React.Fragment>
      );
    },
    sortMethod: "CollectionType",
  },
  // elapsed since last event
  {
    Header: "Elapsed",
    Cell: ({ row: { original } }) => {
      return (
        <React.Fragment>
          <p>{original.ElapsedSinceLastEvent}</p>
        </React.Fragment>
      );
    },
    sortMethod: "ElapsedSinceLastEvent",
  },
];

export default columnBuilder;
