/* eslint no-console: ["error", { allow: [ "error"] }] */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useRefresh, usePrevious } from '../Hooks';
import qs from 'query-string';
import TableView from './TableView';
import ColumnPicker from './ColumnPicker';
import AutoRefresh from './AutoRefresh';
import CSVDownload from './CSVDownload';
import FilterSearch from '../Search/FilterSearch';
import CashCollectionMetaData from '../Accounting/Collection/CashCollectionMetaData';
import TransactionMetaData from './../Transactions/TransactionMetaData';
import EddMetaData from './../Compliance/EDDs/EddMetaData';
import TableActions from './TableActions';
import TableHeader from './TableHeader';
import { Dropdown, Loading, ToastError } from './../Utilities/Components';
import { formatNotificationData } from '../Utilities/Formatters';
import './Table.scss';
import { API } from '../Config/cfg.js';
import PropTypes from 'prop-types';

export default function Table({
  actions,
  columns,
  endpoint,
  filter,
  location = {},
  nested,
  downloadable,
  tablename,
  metadata,
  history,
  triggerAPI,
  dataFilter,
  customize,
}) {
  // Use to capture the filter on page load
  const query = new URLSearchParams(window.location.search);
  const defaultFilter = query.get('f') || '';
  const [currentFilters, setCurrentFilters] = useState(defaultFilter);

  const [apiString, setAPI] = useState('');
  const [data, setData] = useState([]);
  const [allColumns, setAllColumns] = useState([]); // All possible columns from gateway
  const [customColumns, setCustomColumns] = useState([]); // user columns returned from gateway
  const [tableColumns, setColumns] = useState(columns); // columns to render react-table format
  const [responseData, setResponse] = useState({});
  const [tableLoading, setTableLoading] = useState(true);
  const [autoRefresh, refreshTime, setAutoRefresh] = useRefresh(false, 30);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0); // zero based
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(1);
  const [sortingPair, setSortingPair] = useState({
    sortField: '',
    sortOrder: '',
  });
  const [isDefaultFiltered, setIsDefaultFilter] = useState(!!location.search);

  const [errors, setErrors] = useState({});
  const [noDataText, setNoDataText] = useState('Loading...');

  const tableData = useMemo(() => data, [data]);
  const columnData = useMemo(() => tableColumns, [tableColumns]);
  const prevFilters = usePrevious(currentFilters);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    // Make sure to set loading back to false and update messaging
    if (!endpoint) {
      setLoading(false);
      setTableLoading(false);
      setNoDataText('No Data Available');
    }
  }, [endpoint]);

  useEffect(() => {
    // Don't make any api calls if the endpoint is empty
    if (!endpoint) return;

    const { sortField, sortOrder } = sortingPair;
    let query, splitQuery;

    const checkForSortParams = query => {
      const searchParams = new URLSearchParams(query);
      const newSortField = searchParams.get('s');
      const newSortOrder = searchParams.get('o');

      if (newSortOrder && newSortField && filter && !history) {
        setSortingPair({ sortField: newSortField, sortOrder: newSortOrder });
        return true;
      }

      return false;
    };

    if (
      history?.action === 'POP' ||
      history?.location.search.split('f=') ||
      isDefaultFiltered
    ) {
      query = isDefaultFiltered ? location.search : history.location.search;
      splitQuery = query.split('f=');
    }

    const hasSorting = checkForSortParams(query);
    const sharedFilters =
      filter &&
      history &&
      !nested &&
      ((splitQuery && splitQuery[1]) || hasSorting) &&
      splitQuery === currentFilters;

    if (sharedFilters) {
      setAPI(`/${endpoint}${query}`);
    } else if (endpoint.includes('search')) {
      setAPI(
        `/${endpoint}&p=${currentPage}&l=${pageSize}&s=${sortField}&o=${sortOrder}&f=${currentFilters}`
      );
    } else {
      setAPI(
        `/${endpoint}?p=${currentPage}&l=${pageSize}&s=${sortField}&o=${sortOrder}&f=${currentFilters}`
      );
    }
  }, [
    endpoint,
    currentPage,
    pageSize,
    currentFilters,
    filter,
    history,
    sortingPair,
    nested,
    location,
    isDefaultFiltered,
  ]);

  useEffect(() => {
    if (currentFilters !== prevFilters) {
      setCurrentPage(0);
    }
  }, [currentFilters, prevFilters]);

  useEffect(() => {
    if (filter && history) {
      const query = qs.parse(history.location.search);
      setPageSize(query.l ? Number(query.l) : 25);
      setCurrentPage(query.p ? Number(query.p) : 0);
    }
  }, [filter, history]);

  useEffect(() => {
    if (!endpoint) return;

    const callAPI = () => {
      API.get(apiString)
        .then(res => {
          let rows = res.data.Data;
          if (tablename === 'Notifications') {
            rows = formatNotificationData(rows.Notifications);
          }
          if (dataFilter) {
            setData(dataFilter(rows));
          } else {
            setData(rows);
          }
          if (res.data.Data.length === 0) {
            setNoDataText('No Data Available');
          }

          if (customize) {
            if (!res.data.Columns.All) {
              console.error('Could not load custom columns');
            } else {
              setCustomColumns(res.data.Columns.Selected);
              setAllColumns(res.data.Columns.All);
            }
          }
          setResponse(res.data);
          setLoading(false);
          setPageCount(calculatePageCount(res.data, pageSize));
          setIsDefaultFilter(false);
          document.getElementById('searchForm').reset();
        })
        .catch(error => {
          let errorMsg = error.response ? error.response.data.Message : '';

          setData([]);
          setNoDataText(errorMsg || 'No Data Available');
          setLoading(false);
          if (error.response) {
            ToastError(error);
          }
        })
        .finally(() => {
          setTableLoading(false);
          if (!nested && isMounted.current && history) {
            history.push(apiString);
          }
        });
    };

    if (
      ((autoRefresh && refreshTime < 1) ||
        (!autoRefresh && apiString) ||
        triggerAPI) &&
      isMounted.current
    ) {
      setTableLoading(true);
      callAPI();
    }
  }, [
    apiString,
    autoRefresh,
    customize,
    dataFilter,
    endpoint,
    history,
    nested,
    pageSize,
    refreshTime,
    tablename,
    triggerAPI,
  ]);

  useEffect(() => {
    const selectedColumns = [];
    if (customize) {
      if (customColumns?.length < 1) {
        const colHeaders = columns.map(col => col.Header);
        setColumns(columns);
        setAllColumns(colHeaders);
        setCustomColumns(colHeaders);
      } else {
        customColumns.forEach(col => {
          columns.forEach(propCol => {
            if (propCol.Header === col) {
              selectedColumns.push(propCol);
            }
          });
        });
        if (JSON.stringify(selectedColumns) !== JSON.stringify(tableColumns)) {
          setColumns(selectedColumns);
        }
      }
    }
  }, [customColumns, tableColumns, columns, customize]);

  useEffect(() => {
    if (refreshTime < 1) {
      if (autoRefresh) {
        setAutoRefresh(true, 30);
      } else {
        setAutoRefresh(false, 30);
      }
    }

    const filterRemoved =
      prevFilters !== currentFilters && currentFilters.length === 0;
    if ((prevFilters && currentFilters) || (!prevFilters && filterRemoved)) {
      setAutoRefresh(false, 30);
    }
  });

  const sortByColumn = column => {
    const { sortMethod } = column;
    const { sortField, sortOrder } = sortingPair;
    let newSortField = '';
    let newSortOrder = '';

    if ((!sortField && !sortOrder) || sortField !== sortMethod) {
      newSortField = sortMethod;
      newSortOrder = 'asc';
    } else if (sortMethod === sortField && sortOrder === 'asc') {
      newSortField = sortMethod;
      newSortOrder = 'desc';
    }
    setSortingPair({
      sortField: newSortField,
      sortOrder: newSortOrder,
    });

    setAPI(
      `/${endpoint}?p=${currentPage}&l=${pageSize}&s=${newSortField}&o=${newSortOrder}&f=${currentFilters}`
    );
  };

  const calculatePageCount = (data, pageSize) => {
    if (data.TotalResults) {
      return Math.ceil(data.TotalResults / pageSize);
    } else {
      return 1;
    }
  };

  if (loading) {
    return <Loading errors={errors} setErrors={setErrors} />;
  }

  const options = {
    setPageSize,
    setTableLoading,
    sortByColumn,
    sortingPair,
    noDataText,
    tableLoading,
    currentPage,
    autoRefresh,
    setAutoRefresh,
    columns: columnData,
    data: tableData,
    tableSize: pageSize,
    tablePageCount: pageCount,
    setPage: setCurrentPage,
    setTablePageSize: setPageSize,
  };

  const columnTip = {
    id: 'columns',
    message: 'Customize Columns',
    place: 'top',
  };

  return (
    <>
      <TableHeader title={tablename} nested={nested}>
        {/* <Options /> */}
        <AutoRefresh
          autoRefresh={autoRefresh}
          autoRefreshTime={refreshTime}
          setAutoRefresh={setAutoRefresh}
        />
        {actions && <TableActions actions={actions} />}
        {downloadable && (
          <CSVDownload
            params={{
              endpoint,
              page: currentPage,
              pageSize,
              currentFilters,
            }}
          />
        )}
        {customize && (
          <Dropdown title="Columns" tip={columnTip}>
            <ColumnPicker
              endpoint={endpoint}
              columns={columns}
              allColumns={allColumns}
              customColumns={customColumns}
              setCustomColumns={setCustomColumns}
            />
          </Dropdown>
        )}
      </TableHeader>

      {filter && (
        <FilterSearch
          table={tablename}
          pageSize={pageSize}
          filterQuery={
            isDefaultFiltered ? location.search : history.location.search
          }
          setTableFilters={setCurrentFilters}
        />
      )}
      {/** Temporarily Solution MetaData for Transaction */}
      {metadata && endpoint === 'transactions' && (
        <TransactionMetaData
          filter={isDefaultFiltered ? location.search : history.location.search}
        />
      )}
      {metadata && endpoint === 'compliance/edds' && (
        <EddMetaData data={responseData} />
      )}
      {metadata && endpoint === 'accounting/cash' && (
        <CashCollectionMetaData data={responseData} />
      )}
      <TableView options={options} />
    </>
  );
}

Table.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array.isRequired,
  endpoint: PropTypes.string,
  filter: PropTypes.bool,
  size: PropTypes.number,
  tablename: PropTypes.string.isRequired,
};
