import React from "react";
import { EditableForm } from "../../../Forms/EditableForm";
import { EditableInput, EditableSelect } from "../../../Forms/EditableInputs";
import { capitalizeFirstLetter } from "../../../Utilities/Formatters";
import { ACCOUNTING_VIEW } from "../../../Permissions/Permissions";

const Overview = ({
  editing,
  data,
  toggleEdit,
  postACHAccount,
  isSubmitting,
}) => {
  const callPostACHAccount = (req, actions) => {
    postACHAccount(data.Hash, actions, req);
  };
  const {
    Name,
    PayorName,
    AccountNumber,
    RoutingNumber,
    TransactionACHCodeString,
    TransitNumber,
    FinancialInstitutionNumber,
  } = data;

  const ACHCodes = [
    { Hash: 1, Name: "Checking" },
    { Hash: 2, Name: "Savings" },
  ];

  return (
    <EditableForm
      callBack={callPostACHAccount}
      triggerEdit={toggleEdit}
      setValues={{
        Name,
        PayorName,
        AccountNumber,
        RoutingNumber,
        TransactionACHCode: TransactionACHCodeString === "checking" ? 1 : 2,
      }}
      customValidations={(values) => {
        let errors = {};
        if (!values.TransactionACHCode) {
          errors.TransactionACHCode = "is required";
        }
        return errors;
      }}
      editing={editing}
      editType="editingACH"
      sectionTitle="ACH Details"
      isSubmitting={isSubmitting}
      permission={ACCOUNTING_VIEW}
    >
      <div className="columns">
        <div className="column">
          <EditableInput
            name="Name"
            label="Name"
            type="text"
            currentValue={Name}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="PayorName"
            label="Payor Name"
            type="text"
            currentValue={PayorName}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableInput
            name="AccountNumber"
            label="Account Number"
            type="text"
            currentValue={AccountNumber}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="RoutingNumber"
            label="Routing Number"
            type="text"
            currentValue={RoutingNumber}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <EditableSelect
            name="TransactionACHCode"
            label="Transaction ACH Code"
            placeholder="Select ACH Code"
            currentValue={
              TransactionACHCodeString
                ? capitalizeFirstLetter(TransactionACHCodeString)
                : null
            }
            data={ACHCodes}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-4">
          <EditableInput
            label="Transit Number"
            name="TransitNumber"
            type="text"
            placeholder="Transit Number"
            currentValue={TransitNumber}
          />
        </div>
        <div className="column is-4">
          <EditableInput
            label="Financial Institution Number"
            name="FinancialInstitutionNumber"
            type="text"
            placeholder="Financial Institution Number"
            currentValue={FinancialInstitutionNumber}
          />
        </div>
      </div>
    </EditableForm>
  );
};

export default Overview;
