import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { formatLabel, getOptionLabel } from './utils';
import PropTypes from 'prop-types';

const EditableGroupSelect = ({
  currentValue,
  editing,
  id,
  label,
  name,
  optLabel,
  optLabelTwo,
  options,
  optionsTwo,
  placeholder,
  testID,
}) => {
  const dataOptions = (
    <optgroup label={getOptionLabel(optLabel, optLabel)}>
      {options.map((item, index) => (
        <option
          key={index}
          value={optLabel + item.Hash}
          data-testid={`${testID}Option${index}`}
        >
          {item.Name}
        </option>
      ))}
    </optgroup>
  );

  const dataOptionsTwo = (
    <optgroup label={getOptionLabel(optLabelTwo, optLabel)}>
      {optionsTwo.map((item, index) => (
        <option
          key={index}
          value={optLabelTwo + item.Hash}
          data-testid={`${testID}OptionTwo${index}`}
        >
          {item.Name} {item.AccountNumber}
        </option>
      ))}
    </optgroup>
  );

  return (
    <div className="form-row" data-testid={testID}>
      {editing ? (
        <>
          <ErrorMessage name={name} component="div" className="form-error" />
          <label htmlFor={id || name} className="item-label">
            {formatLabel(label)}
          </label>
          <Field
            className="form-row"
            component="select"
            id={id || name}
            name={name}
          >
            <option value="" disabled>
              {placeholder}
            </option>
            {dataOptions}
            {dataOptionsTwo}
          </Field>
        </>
      ) : (
        <>
          <label className="item-label">{formatLabel(label)}</label>
          <p>{currentValue}</p>
        </>
      )}
    </div>
  );
};

EditableGroupSelect.defaultProps = {
  currentValue: '-',
  editing: false,
  options: [],
  optionsTwo: [],
  testID: 'xEditableGroupSelect',
};

EditableGroupSelect.propTypes = {
  currentValue: PropTypes.string,
  editing: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  optLabel: PropTypes.string,
  optLabelTwo: PropTypes.string,
  options: PropTypes.array,
  optionsTwo: PropTypes.array,
  placeholder: PropTypes.string,
  testID: PropTypes.string,
};

export default EditableGroupSelect;
