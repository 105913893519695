const accountReviewsKeys = {
  CreatedAt: {
    Label: "Create Date",
    Type: "daterange",
  },
  UpdatedAt: {
    Label: "Last Update Date",
    Type: "daterange",
  },
  PhoneNumber: {
    Label: "Phone Number",
    Type: "string",
  },
  State: {
    Label: "Review Type",
    Type: "int",
    Options: [
      {
        Display: "Needs ID",
        Value: 0,
      },
      {
        Display: "ID Approval",
        Value: 1,
      },
      {
        Display: "OFAC",
        Value: 3,
      },
      {
        Display: "Over 55 Outreach",
        Value: 6,
      },
      {
        Display: "National ID Number Verification",
        Value: 4,
      },
      {
        Display: "Tier 2 Review",
        Value: 5,
      },
      {
        Display: "Plaid Lightning Verification",
        Value: 7,
      },
      {
        Display: "Third Party ID Verification",
        Value: 8,
      },
      {
        Display: "Selfie Check",
        Value: 10,
      },
      {
        Display: "Document Verification",
        Value: 11,
      },
      {
        Display: "Plaid Risk Check",
        Value: 12,
      },
    ],
  },
};

export default accountReviewsKeys;
