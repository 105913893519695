// Modules
import React from "react";
// Components
import { ListGroup, ListGroupItem } from "../../../Utilities/Components";
// Helpers - Formatters
import { formatDateTime } from "../../../Utilities/Formatters";

const SubpoenaAccountDetails = ({ data }) => {
  const { ContactEmail, ContactName, ContactPhone, DateReceived, DueDate } =
    data;
  return (
    <div className="column">
      <ListGroup title="Account Details">
        <ListGroupItem name="Contact Name" value={ContactName} />
        <ListGroupItem name="Contact Phone" value={ContactPhone} />
        <ListGroupItem name="Contact Email" value={ContactEmail} />
        <ListGroupItem name="Due Date" value={formatDateTime(DueDate)} />
        <ListGroupItem
          name="Date Received"
          value={formatDateTime(DateReceived)}
        />
      </ListGroup>
    </div>
  );
};

export default SubpoenaAccountDetails;
