import React from "react";

const DateRangeFilterValue = ({ to, from, setTo, setFrom, filterKey }) => {
  return (
    <div className="columns">
      <div className="column">
        <p className="item-label">Starting Date</p>
        <input
          type="date"
          value={to}
          onChange={setTo}
          disabled={!filterKey ? true : false}
          placeholder="mm/dd/yyyy"
          pattern="\d\d[- /.]\d\d[- /.]\d\d\d\d"
        />
      </div>
      <div className="column">
        <p className="item-label">Ending Date</p>
        <input
          type="date"
          value={from}
          onChange={setFrom}
          disabled={!filterKey ? true : false}
          placeholder="mm/dd/yyyy"
          pattern="\d\d[- /.]\d\d[- /.]\d\d\d\d"
        />
      </div>
    </div>
  );
};

export default DateRangeFilterValue;
