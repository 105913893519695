import React from 'react';
import { Form, Formik, Field } from 'formik';
// Components
import { FormInput } from '../../../Forms/FormInputs';
import GenericModal from '../../../Utilities/Components/GenericModal';

const NotificationModal = ({ isOpen, submitValues, closeModal, user }) => {
  const notifications = [
    { Name: 'Maintenance Mode Entered', Value: 0 },
    { Name: 'Maintenance Mode Exited', Value: 1 },
    { Name: 'Cash Collected', Value: 2 },
    { Name: 'Kiosk Locked', Value: 3 },
    { Name: 'Powered Off', Value: 4 },
    { Name: 'Powered On', Value: 5 },
    { Name: 'Rebooted', Value: 6 },
    { Name: 'Unavailable', Value: 7 },
    { Name: 'Available', Value: 8 },
    { Name: 'Cash Deposited', Value: 9 },
    { Name: 'Cash Cassette Removed', Value: 10 },
    { Name: 'Cash Cassette Inserted', Value: 11 },
    { Name: 'Transaction Initiated', Value: 12 },
    { Name: 'Transaction Executed', Value: 13 },
    { Name: 'Created', Value: 14 },
    { Name: 'Activated', Value: 15 },
    { Name: 'Dectivated', Value: 16 },
    { Name: 'Installed', Value: 17 },
    { Name: 'Screen Capture', Value: 18 },
  ];

  const notificationRows = () => {
    let recNotifications = [];
    let row = [];

    notifications.forEach(notif => {
      row.push(
        <div key={notif.Name} className="column is-4 has-text-centered">
          <p className="item-label">{notif.Name}</p>
          <Field
            name="Notifications"
            type="checkbox"
            component="input"
            value={notif.Name}
          />
        </div>
      );
      if (row.length % 3 === 0) {
        recNotifications.push(row);
        row = [];
      }
    });

    return recNotifications;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        Email: user.Email,
        Name: user.Name, // optional
        Notifications: user.Types,
      }}
      onSubmit={values => submitValues(values)}
      children={({ touched, errors, handleSubmit, isSubmitting }) => {
        return (
          <GenericModal
            modalIsOpen={isOpen}
            closeModal={closeModal}
            headerMsg="Edit Notifications"
            btnText="Submit"
            btnAction={handleSubmit}
            isSubmitting={isSubmitting}
          >
            <Form className="form-outline is-centered">
              <div className="columns">
                <div className="column">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    type="text"
                    label="Email"
                    name="Email"
                    disabled
                  />
                </div>
                <div className="column">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    type="text"
                    label="Name"
                    name="Name"
                    disabled
                  />
                </div>
              </div>
              {notificationRows().map((notif, i) => {
                return (
                  <div key={i} className="columns is-vcentered is-centered">
                    {notif}
                  </div>
                );
              })}
            </Form>
          </GenericModal>
        );
      }}
    />
  );
};

export default NotificationModal;
