const transactionKeys = {
  TXID: {
    Label: "Blockchain ID",
    Type: "string",
  },
  "Location.BusinessName": {
    Label: "Business Name",
    Type: "string",
  },
  "Location.Address.City": {
    Label: "City",
    Type: "string",
  },
  CreatedAt: {
    Label: "Create Date",
    Type: "daterange",
  },
  DepositAddress: {
    Label: "Deposit Address",
    Type: "string",
  },
  Collected: {
    Label: "Fiat Collected",
    Type: "decimal",
  },
  "Group.Name": {
    Label: "Group",
    Type: "string",
  },
  "Platform.PlatformType.Model": {
    Label: "Hardware Model",
    Type: "string",
    Options: [
      {
        Display: "Legacy",
        Value: "Legacy",
      },
      {
        Display: "Bravo",
        Value: "Bravo",
      },
      {
        Display: "Answers Tier III",
        Value: "Answers Tier III",
      },
      {
        Display: "DCS POS",
        Value: "DCS POS",
      },
      {
        Display: "Maya Tech",
        Value: "Maya Tech",
      },
      {
        Display: "Futurakiosk",
        Value: "Futurakiosk",
      },
      {
        Display: "Genmega.CARD",
        Value: "Genmega.CARD",
      },
      {
        Display: "Hyosung.ATM",
        Value: "Hyosung.ATM",
      },
      {
        Display: "Hyosung.Sidecar",
        Value: "Hyosung.Sidecar",
      },
      {
        Display: "Genmega.CDU",
        Value: "Genmega.CDU",
      },
      {
        Display: "Genmega.Sidecar",
        Value: "Genmega.Sidecar",
      },
      {
        Display: "Genmega.UK",
        Value: "Genmega.UK",
      },
      {
        Display: "Genmega.XL",
        Value: "Genmega.XL",
      },
      {
        Display: "Kiosk.com",
        Value: "Kiosk.com",
      },
      {
        Display: "EZMoney POS",
        Value: "EZMoney POS",
      },
      {
        Display: "Slabb",
        Value: "Slabb",
      },
    ],
  },
  "Product.Symbol": {
    Label: "Product",
    Type: "string",
    Options: [
      {
        Display: "BTC",
        Value: "BTCUSD",
      },
      {
        Display: "LTC",
        Value: "LTCUSD",
      },
    ],
  },
  InvoiceCode: {
    Label: "Invoice Code",
    Type: "string",
  },
  IP: {
    Label: "IP Address",
    Type: "string",
  },
  "Platform.PlatformType.Name": {
    Label: "Platform",
    Type: "string",
    Options: [
      {
        Display: "API",
        Value: "API",
      },
      {
        Display: "iPad",
        Value: "iPad",
      },
      {
        Display: "Kiosk",
        Value: "Kiosk",
      },
      {
        Display: "Website",
        Value: "web",
      },
      {
        Display: "Epay",
        Value: "Epay",
      },
    ],
  },
  "Location.Address.StateCode": {
    Label: "State Code",
    Type: "string",
    Options: [
      {
        Display: "AL",
        Value: "AL",
      },
      {
        Display: "AK",
        Value: "AK",
      },
      {
        Display: "AZ",
        Value: "AZ",
      },
      {
        Display: "AR",
        Value: "AR",
      },
      {
        Display: "CA",
        Value: "CA",
      },
      {
        Display: "CO",
        Value: "CO",
      },
      {
        Display: "CT",
        Value: "CT",
      },
      {
        Display: "DE",
        Value: "DE",
      },
      {
        Display: "DC",
        Value: "DC",
      },
      {
        Display: "FL",
        Value: "FL",
      },
      {
        Display: "GA",
        Value: "GA",
      },
      {
        Display: "HI",
        Value: "HI",
      },
      {
        Display: "ID",
        Value: "ID",
      },
      {
        Display: "IL",
        Value: "IL",
      },
      {
        Display: "IN",
        Value: "IN",
      },
      {
        Display: "IA",
        Value: "IA",
      },
      {
        Display: "KS",
        Value: "KS",
      },
      {
        Display: "KY",
        Value: "KY",
      },
      {
        Display: "LA",
        Value: "LA",
      },
      {
        Display: "ME",
        Value: "ME",
      },
      {
        Display: "MH",
        Value: "NH",
      },
      {
        Display: "MD",
        Value: "MD",
      },
      {
        Display: "MA",
        Value: "MA",
      },
      {
        Display: "MI",
        Value: "MI",
      },
      {
        Display: "MN",
        Value: "MN",
      },
      {
        Display: "MS",
        Value: "MS",
      },
      {
        Display: "MO",
        Value: "MO",
      },
      {
        Display: "MT",
        Value: "MT",
      },
      {
        Display: "NE",
        Value: "NE",
      },
      {
        Display: "NV",
        Value: "NV",
      },
      {
        Display: "NH",
        Value: "NH",
      },
      {
        Display: "NJ",
        Value: "NJ",
      },
      {
        Display: "NM",
        Value: "NM",
      },
      {
        Display: "NY",
        Value: "NY",
      },
      {
        Display: "NC",
        Value: "NC",
      },
      {
        Display: "ND",
        Value: "ND",
      },
      {
        Display: "OH",
        Value: "OH",
      },
      {
        Display: "OK",
        Value: "OK",
      },
      {
        Display: "OR",
        Value: "OR",
      },
      {
        Display: "PA",
        Value: "PA",
      },
      {
        Display: "PR",
        Value: "PR",
      },
      {
        Display: "RI",
        Value: "RI",
      },
      {
        Display: "SC",
        Value: "SC",
      },
      {
        Display: "SD",
        Value: "SD",
      },
      {
        Display: "TN",
        Value: "TN",
      },
      {
        Display: "TX",
        Value: "TX",
      },
      {
        Display: "UT",
        Value: "UT",
      },
      {
        Display: "VT",
        Value: "VT",
      },
      {
        Display: "VI",
        Value: "VI",
      },
      {
        Display: "VA",
        Value: "VA",
      },
      {
        Display: "WA",
        Value: "WA",
      },
      {
        Display: "WV",
        Value: "WV",
      },
      {
        Display: "WI",
        Value: "WI",
      },
      {
        Display: "WY",
        Value: "WY",
      },
    ],
  },
  "Location.Address.Street": {
    Label: "Street Address",
    Type: "string",
  },
  State: {
    Label: "Transaction Status",
    Type: "int",
    Options: [
      {
        Display: "Canceled",
        Value: 420,
      },
      {
        Display: "Customer Banned During Transaction",
        Value: 510,
      },
      {
        Display: "Dispensing Cash",
        Value: 350,
      },
      {
        Display: "Disrupted",
        Value: 500,
      },
      {
        Display: "Executed",
        Value: 300,
      },
      {
        Display: "Executing",
        Value: 400,
      },
      {
        Display: "Frozen",
        Value: 560,
      },
      {
        Display: "Pending Batch",
        Value: 305,
      },
      {
        Display: "Pending Blockchain Confirmation",
        Value: 310,
      },
      {
        Display: "Pending Deposit Wallet Address",
        Value: 250,
      },
      {
        Display: "Pending ID Confirmation",
        Value: 130,
      },
      {
        Display: "Pending ID Info",
        Value: 101,
      },
      {
        Display: "Pending ID Scan",
        Value: 100,
      },
      {
        Display: "Pending Risk Check",
        Value: 190,
      },
      {
        Display: "Pending National ID Number",
        Value: 150,
      },
      {
        Display: "Pending Withdrawal Wallet Address",
        Value: 200,
      },
      {
        Display: "Receiving Cash",
        Value: 210,
      },
      {
        Display: "Receiving Crypto",
        Value: 260,
      },
      {
        Display: "Risk Check Failure",
        Value: 520,
      },
      {
        Display: "Settled",
        Value: 410,
      },
      {
        Display: "Settlement In Flight",
        Value: 307,
      },
    ],
  },
  Side: {
    Label: "Transaction Side",
    Type: "int",
    Options: [
      {
        Display: "Buy",
        Value: 0,
      },
      {
        Display: "Sell",
        Value: 1,
      },
    ],
  },
  WithdrawalAddress: {
    Label: "Withdrawal Address",
    Type: "string",
  },
};
export default transactionKeys;
