import React from "react";
import { Link } from "react-router-dom";
// Components
import { EditBtn } from "../../Utilities/Components";
import { TxState } from "../../Utilities/Components/TablePills";
// Helpers - Formatters
import { formatDateTime } from "../../Utilities/Formatters";
import { reqObjProp } from "../../Utilities/PropTypes";
// Permissions
import { PermissionsContext } from "../../Permissions/PermissionContext";
import { ACCOUNT_VIEW, PLATFORMS_VIEW } from "../../Permissions/Permissions";
import { checkPermission } from "../../Permissions/CheckPermission";

const TxDetails = (data) => {
  const {
    Account,
    Base,
    TXID,
    WithdrawalAddress,
    CreatedAt,
    Location,
    StateDesc,
    State,
    FacilitatorSession,
    Product,
    Platform,
    DigitalmintTradingTransactionID,
  } = data.data;

  const setBlockchainURL = (path, address) => {
    const url = `https://blockchair.com`;
    let type;
    switch (Base.Symbol) {
      case "tbtc":
        type = "bitcoin/testnet";
        break;
      default:
        type = Base.Name;
        break;
    }

    return `${url}/${type}/${path}/${address}`;
  };

  return (
    <PermissionsContext.Consumer>
      {(userPermissions) => (
        <React.Fragment>
          <EditBtn
            editing={null}
            triggerEdit={null}
            sectionTitle="Transaction Overview"
          />
          <div className="form-outline inline-edit-form">
            {checkPermission(userPermissions, ACCOUNT_VIEW) && (
              <div className="columns">
                <div className="column form-row">
                  <label className="item-label">Full Name: </label>
                  <p>
                    <Link to={`/accounts/${Account.Hash}`}>
                      {Account.FullName}
                    </Link>
                  </p>
                </div>
                <div className="column form-row">
                  <label className="item-label">Phone: </label>
                  <p>
                    {Account.PreferredPhoneNumber
                      ? Account.PreferredPhoneNumber.Phone
                      : "-"}
                  </p>
                </div>
              </div>
            )}

            <div className="columns">
              <div className="column form-row">
                <label className="item-label">Location: </label>
                <p>
                  <Link to={`/locations/${Location.Hash}`}>
                    {Location.BusinessName}
                  </Link>
                </p>
              </div>
              <div className="column form-row">
                <label className="item-label">Facilitator:</label>
                <p>
                  <Link to={`/accounts/${FacilitatorSession.Account.Hash}`}>
                    {FacilitatorSession.Account.FullName}
                  </Link>
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column form-row">
                <label className="item-label">Status: </label>
                <p>{TxState(State, StateDesc)}</p>
              </div>
              <div className="column form-row">
                <label className="item-label">Platform: </label>
                {checkPermission(userPermissions, PLATFORMS_VIEW) ? (
                  <p>
                    <Link to={`/platforms/${Platform.Hash}`}>
                      {`${Platform.PlatformType.Name} - ${Platform.PlatformType.Model}`}
                    </Link>
                  </p>
                ) : (
                  <p>{`${Platform.PlatformType.Name} - ${Platform.PlatformType.Model}`}</p>
                )}
              </div>
            </div>

            <div className="columns">
              <div className="column form-row">
                <label className="item-label">Trade Type: </label>
                <p>{Product.Symbol}</p>
              </div>
              <div className="column form-row">
                <label className="item-label">Date Transacted: </label>
                <p>{formatDateTime(CreatedAt)}</p>
              </div>
            </div>
            <div className="columns">
              <div className="column form-row">
                <label className="item-label">Wallet Address: </label>
                <p>
                  {WithdrawalAddress ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={setBlockchainURL("address", WithdrawalAddress)}
                    >
                      {WithdrawalAddress}
                    </a>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
              <div className="column form-row">
                <label className="item-label">Blockchain TXID: </label>
                <p>
                  {TXID ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={setBlockchainURL("transaction", TXID)}
                    >
                      View Details
                    </a>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column form-row">
                <label className="item-label">OTC ID: </label>
                <p>
                  {DigitalmintTradingTransactionID
                    ? DigitalmintTradingTransactionID
                    : "-"}
                </p>
              </div>
              <div className="column form-row"></div>
            </div>
          </div>
        </React.Fragment>
      )}
    </PermissionsContext.Consumer>
  );
};

TxDetails.propTypes = {
  data: reqObjProp,
};

export default TxDetails;
