import React from 'react';
// Components
import { GenericModal } from '../../../Utilities/Components';

const NewEDDSurveyModal = ({
  isOpen,
  putNewEDDSurvey,
  hash,
  isSubmitting,
  closeModal,
}) => {
  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={() => {
        closeModal('isNewEDDSurveyOpen');
      }}
      headerMsg="Send New EDD Survey"
      viewMsg="This action SENDS a new EDD Survey to this account. This action is irreversible."
      btnText="Send New Survey"
      btnAction={async () => {
        await putNewEDDSurvey(hash);
      }}
      isSubmitting={isSubmitting}
    />
  );
};

export default NewEDDSurveyModal;
