// Modules
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// Components
import { ICONS } from '../../Icons/IconsPath';
import Icon from '../../Icons/Icon';
import Table from '../../Table/Table';
import { ReviewPhasePill } from '../../Utilities/Components/TablePills';
import AssignModal from '../../Compliance/Modals/AssignModal';
// Helpers
import { formatDate } from '../../Utilities/Formatters';

const EDD = props => {
  const [isAssignOpen, setAssignOpen] = useState(false);
  const [eddHash, setEddHash] = useState('');
  const [triggerAPI, setTrigger] = useState(false);
  const [assignee, setAssignee] = useState(null);

  useEffect(() => {
    if (triggerAPI) {
      setTrigger(false);
    }
  }, [triggerAPI]);

  const openModal = (hash, employee) => {
    setAssignee(employee);
    setEddHash(hash);
    setAssignOpen(true);
  };

  const closeModal = () => {
    setTrigger(true);
    setAssignOpen(false);
  };

  return (
    <>
      <Table
        history={props.history}
        location={props.location}
        metadata={true}
        columns={columnBuilder(openModal)}
        endpoint="compliance/edds"
        filter={true}
        tablename="High Risk (EDD)"
      />
      <AssignModal
        title="EDD"
        endpoint="compliance/edds"
        hash={eddHash}
        isOpen={isAssignOpen}
        closeModal={closeModal}
        assignee={assignee}
      />
    </>
  );
};

export default EDD;

const columnBuilder = openModal => [
  {
    Header: 'EDD #',
    accessor: 'Code',
    Cell: ({ row: { original } }) =>
      original.Hash ? (
        <Link className="table-pill" to={`/compliance/edds/${original.Hash}`}>
          {original.Code}
        </Link>
      ) : (
        '-'
      ),
    sortMethod: 'Code',
  },
  {
    Header: 'Customer',
    accessor: 'Account.FullName',
    Cell: ({ row: { original } }) =>
      original.Account.FullName && original.Account.Hash ? (
        <Link className="table-pill" to={`/accounts/${original.Account.Hash}`}>
          {original.Account.FullName}
        </Link>
      ) : (
        '-'
      ),
    sortMethod: 'Name',
  },
  {
    Header: 'Phone',
    accessor: 'Account.PreferredPhoneNumber.Phone',
    Cell: ({ row: { original } }) =>
      original.Account.PreferredPhoneNumber
        ? original.Account.PreferredPhoneNumber.Phone
        : '-',
    sortMethod: 'Account.PreferredPhoneNumber.Phone',
  },
  {
    Header: 'Create Date',
    accessor: 'CreatedAt',
    Cell: ({ row: { original } }) =>
      original.CreatedAt ? formatDate(original.CreatedAt) : '-',
    sortMethod: 'CreatedAt',
  },
  {
    Header: 'Activation',
    accessor: 'Activation',
    Cell: ({ row: { original } }) =>
      original.Activation ? formatDate(original.Activation) : '-',
    sortMethod: 'Activation',
  },
  {
    Header: 'Due Date',
    accessor: 'DueDate',
    Cell: ({ row: { original } }) =>
      original.DueDate ? formatDate(original.DueDate) : '-',
    sortMethod: 'DueDate',
  },
  {
    Header: 'Active',
    Cell: ({ row: { original } }) =>
      original.Active ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
    sortMethod: 'Active',
    width: 50,
  },
  {
    Header: 'Segment',
    Cell: ({ row: { original } }) =>
      original.SegmentText ? original.SegmentText : '-',
    sortMethod: 'Segment',
  },

  {
    Header: 'Review Phase',
    accessor: 'ReviewPhase',
    Cell: ({ row: { original } }) =>
      original.ReviewPhase ? ReviewPhasePill(original.ReviewPhase) : '-',
    sortMethod: 'ReviewPhase',
  },
  {
    Header: 'Decision',
    accessor: 'Decision',
    Cell: ({ row: { original } }) =>
      original.DecisionText ? original.DecisionText : '-',
    sortMethod: 'Decision',
  },
  {
    Header: 'Assigned To',
    accessor: 'AssignedTo.FullName',
    Cell: ({ row: { original } }) =>
      original.AssignedTo ? original.AssignedTo.FullName : '-',
  },
  {
    Header: 'Action',
    accessor: 'Action',
    Cell: ({ row: { original } }) => (
      <button
        className="column-btn create"
        onClick={() => openModal(original.Hash, original.AssignedTo)}
      >
        {!original.AssignedTo ? 'Assign' : 'Edit'}
      </button>
    ),
  },
  {
    Header: 'Country of Origin',
    accessor: 'Country.Code',
  },
];
