import React, { useEffect, useState } from 'react';
import Table from '../Table/Table';
import platformsColumnBuilder from '../Platforms/columnBuilder';
import { capitalizeFirstLetter } from '../Utilities/Formatters';
import { PropTypes } from 'prop-types';

const PlatformResultsTable = ({ location }) => {
  const [endpoint, setEndpoint] = useState('');
  const [tablename, setTablename] = useState('Platforms');

  useEffect(() => {
    if (location?.state) {
      const query = encodeURI(location.state.searchTerm);

      setEndpoint(`search?q=${query}&t=${location.state.searchType}`);

      const searchType = capitalizeFirstLetter(location.state.searchType);

      setTablename(`${searchType} results for "${location.state.searchTerm}"`);
    }
  }, [location.state]);

  return (
    <Table
      columns={platformsColumnBuilder}
      endpoint={endpoint}
      tablename={tablename}
    />
  );
};

PlatformResultsTable.propTypes = {
  searchData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  searchQuery: PropTypes.string,
};

export default PlatformResultsTable;
