import { useEffect, useRef } from "react";

// React's implementation of using prevProps or prevState. Not standard (yet)
export default function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
