import React from 'react';
import { Formik, Form } from 'formik';
import { EditBtn, LoadingButton } from '../Utilities/Components';
import { buildFormReq } from './FormHelpers';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const FormikContext = React.createContext({});

const EditableForm = ({
  callBack,
  children,
  copy,
  copyText,
  customValidations,
  editType,
  editing,
  permission,
  sectionTitle,
  setValues,
  testID,
  triggerEdit,
}) => {
  return (
    <section data-testid={testID}>
      <EditBtn
        editing={editing}
        triggerEdit={() => triggerEdit(editType)}
        sectionTitle={sectionTitle}
        permission={permission}
        copy={copy}
        copyText={copyText}
        testID={`${testID}-EditBtn`}
      />
      <Formik
        enableReinitialize
        initialValues={setValues}
        validate={values => {
          if (customValidations) {
            return customValidations(values);
          } else {
            const errors = {};

            for (let [key] of Object.entries(values)) {
              if (!values[key]) {
                errors[key] = 'is required';
              }
            }

            return errors;
          }
        }}
        onSubmit={(values, actions) => {
          const req = buildFormReq(values);

          callBack(req, actions);
        }}
        children={({ isSubmitting }) => (
          <Form
            className={classnames('form-outline inline-edit-form', { editing })}
            data-testid={`${testID}-form`}
          >
            {/* Renders form inputs */}
            {children}
            {editing ? (
              <LoadingButton
                testID={`${testID}-submit`}
                loading={isSubmitting}
                btnText="Update"
              />
            ) : null}
          </Form>
        )}
      />
    </section>
  );
};

EditableForm.defaultProps = {
  copy: false,
  editing: false,
  setValues: {},
  testID: 'xEditableForm',
};

EditableForm.propTypes = {
  callBack: PropTypes.func.isRequired,
  copy: PropTypes.bool,
  copyText: PropTypes.string,
  customValidations: PropTypes.func,
  editType: PropTypes.string,
  editing: PropTypes.bool.isRequired,
  permission: PropTypes.string,
  sectionTitle: PropTypes.string.isRequired,
  setValues: PropTypes.object,
  testID: PropTypes.string,
  triggerEdit: PropTypes.func.isRequired,
};

export { EditableForm, FormikContext };
