// Modules
import React from "react";
// Components
import { EditableForm } from "../../Forms/EditableForm";
import { EditableCheckbox } from "../../Forms/EditableInputs";
import {
  reqObjProp,
  reqBoolProp,
  reqFuncProp
} from "./../../Utilities/PropTypes";
// Permissions
import { PLATFORMS_EDIT } from "./../../Permissions/Permissions";

const ProductOverview = ({
  data,
  editing,
  toggleEdit,
  postProductEndpoints
}) => {
  const callProductEndpoints = req => {
    postProductEndpoints(
      req,
      "editingProduct"
    );
  };

  const { Hash, Buy, Sell } = data
  return (
    <EditableForm
      callBack={callProductEndpoints}
      setValues={{
        Buy,
        Sell,
        ProductHash: Hash,
      }}
      customValidations={values => {
        let errors = {};
        return errors;
      }}
      editing={editing}
      permission={PLATFORMS_EDIT}
      triggerEdit={toggleEdit}
      editType="editingProduct"
      sectionTitle="Personal Details"
    >
      <div className="columns">
        <div className="column">
          <EditableCheckbox
            name="Buy"
            label="Buy"
            currentValue={Buy}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableCheckbox
            name="Sell"
            label="Sell"
            currentValue={Sell}
            editing={editing}
          />
        </div>
      </div>
    </EditableForm>
  );
};

ProductOverview.propTypes = {
  data: reqObjProp,
  editing: reqBoolProp,
  postProductEndpoints: reqFuncProp,
  toggleEdit: reqFuncProp
};

export default ProductOverview;
