import { Decimal } from "decimal.js";

const formatPercent = (data) => {
  if (data >= 0) {
    let percent = new Decimal(data).times(100);
    // display all percentages to 2 decimal points
    if (percent.precision() <= 3) {
      return `${percent.toFixed(2)}%`;
    }
    return `${percent}%`;
  } else {
    return data;
  }
};

export default formatPercent;
