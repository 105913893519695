import React from "react";
import Can from "./../Permissions/Can";

const NavLinks = ({ routes, navComponent, onClick, openSidebar }) => {
  return routes.map((link) => {
    if (link.children) {
      return (
        <Can key={link.path} do={link.permission}>
          {navComponent(link, openSidebar)}
        </Can>
      )
    } else if (link.permission) {
      return (
        <Can key={link.path} do={link.permission} onClick={onClick}>
          {navComponent(link, onClick)}
        </Can>
      );
    } else {
      return navComponent(link, onClick);
    }
  });
};

export default NavLinks;
