const formatRates = (from, to) => {
  let i;
  const array = [{ Name: "Unset", Hash: "unset" }];
  for (i = from; i <= to; i++) {
    // Structure Name/Hash created to fit EditableSelect format
    if (i % 0.25 === 0) {
      array.push({
        Name: (parseFloat(i) * 0.005 * 100).toFixed(1) + "%",
        Hash: parseFloat(i) * 0.005,
      });
    }
  }
  return array;
};

export default formatRates;
