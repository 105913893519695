import Data from './Data';

export default class AddressData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      Active: { type: 'boolean', defaultValue: false },
      City: { type: 'string' },
      Lattitude: { type: 'number' },
      Longitude: { type: 'number' },
      Preferred: { type: 'boolean', defaultValue: false },
      StateCode: { type: 'string' },
      Street2: { type: 'string' },
      Street: { type: 'string' },
      ZIP: { type: 'string' },
    };
  }
}
