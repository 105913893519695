// Modules
import React from 'react';
// Components
import { FormInput, FormSelect } from '../Forms/FormInputs';
import { GenericForm, FormikContext } from '../Forms/Form';

const options = [
  { name: 'Internal Note', value: 0 },
  { name: 'Inbound Call', value: 4 },
  { name: 'Outbound Call', value: 5 },
  { name: 'Outbound Text', value: 6 },
  { name: 'Inbound Text', value: 7 },
  { name: 'Inbound Email', value: 8 },
  { name: 'Outbound Email', value: 9 },
  { name: 'In-Person Meeting', value: 10 },
  { name: 'Left Voicemail', value: 11 },
  { name: 'Case Opened', value: 15 },
];

const CreateNote = ({ history, hash, location }) => {
  const setValues = {
    Note: '',
    Type: '',
  };

  if (location?.state?.TransactionHash) {
    setValues.TransactionHash = location.state.TransactionHash;
  }

  return (
    <GenericForm
      endpoint={`accounts/${hash}/note`}
      setValues={setValues}
      formTitle="Create Note"
      subTitle={null}
      goBack={{
        show: true,
        setHistory: history,
        setDefaultPath: `/accounts/${hash}`,
        setDefaultName: 'Accounts',
      }}
      customValidations={values => {
        let errors = {};
        if (!values.Note) {
          errors.Note = 'is required';
        }
        if (!values.Type) {
          errors.Type = 'is required';
        }
        return errors;
      }}
      toastMsg="Account note has been successfully created"
    >
      <FormikContext.Consumer>
        {props => (
          <div className="columns">
            <div className="column is-6">
              <FormInput
                errors={props.errors}
                touched={props.touched}
                label="Message"
                name="Note"
                component="textarea"
                type="text"
                placeholder="Enter Note..."
              />

              <div className="column is-paddingless">
                <FormSelect
                  errors={props.errors}
                  touched={props.touched}
                  label="Message Type"
                  name="Type"
                  placeholder="Type"
                  options={
                    <React.Fragment>
                      {options.map(type => (
                        <option key={type.value} value={type.value}>
                          {type.name}
                        </option>
                      ))}
                    </React.Fragment>
                  }
                />
              </div>
            </div>
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

export default CreateNote;
