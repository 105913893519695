// Modules
import React from "react";
import columnBuilder from "./columnBuilder";
// Components
import Table from "./../Table/Table";
import { PermissionsContext } from "../Permissions/PermissionContext";

const Transactions = (props) => {
  return (
    <PermissionsContext.Consumer>
      {(userPermiss) => (
        <Table
          history={props.history}
          metadata={true}
          columns={columnBuilder(userPermiss)}
          endpoint="transactions"
          filter={true}
          tablename="Transactions"
          downloadable={true}
          customize
        />
      )}
    </PermissionsContext.Consumer>
  );
};

export default Transactions;
