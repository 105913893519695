import { Api, configureUseApi } from '@digitalmint/api-service';

const ApiService = new Api({
  baseUrl: process.env.REACT_APP_API_URL,
  namespace: process.env.REACT_APP_API_VERSION,
});

const useApi = configureUseApi(ApiService);

export default ApiService;
export { useApi };
