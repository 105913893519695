// Modules
import React, { useState, useEffect } from "react";
import columnBuilder from "./columnBuilder";
// Components
import Table from "../../Table/Table";
import AssignModal from "../../Compliance/Modals/AssignModal";

const Investigations = (props) => {
  const [isAssignOpen, setAssignOpen] = useState(false);
  const [investigationHash, setInvestigationHash] = useState("");
  const [triggerAPI, setTrigger] = useState(false);
  const [assignee, setAssignee] = useState(null);

  useEffect(() => {
    if (triggerAPI) {
      setTrigger(false);
    }
  }, [triggerAPI]);

  const openModal = (hash, employee) => {
    setAssignee(employee);
    setInvestigationHash(hash);
    setAssignOpen(true);
  };

  const closeModal = () => {
    setTrigger(true);
    setAssignOpen(false);
  };

  return (
    <>
      <AssignModal
        title="Investigation"
        endpoint="compliance/investigations"
        hash={investigationHash}
        isOpen={isAssignOpen}
        closeModal={closeModal}
        assignee={assignee}
      />
      <Table
        history={props.history}
        location={props.location}
        columns={columnBuilder(openModal)}
        endpoint="compliance/investigations"
        filter={true}
        tablename="Investigations"
        triggerAPI={triggerAPI}
      />
    </>
  );
};

export default Investigations;
