// Modules
import React from "react";
import columnBuilder from "./columnBuilder";
// Components
import Table from "./../../Table/Table";

const CashLocations = (props) => {
  return (
    <Table
      history={props.history}
      columns={columnBuilder}
      endpoint="accounting/cash-locations"
      filter={true}
      tablename="Cash Locations"
      downloadable={true}
    />
  );
};

export default CashLocations;
