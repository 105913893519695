/* COMPONENT OVERVIEW
- Renders tabular layout
- Passes data to children and Renders it in tab
*/
// Modules
import React from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
// Components
import AccountTransactions from "./Tabs/AccountTransactions";
import GroupsTable from "./Tabs/GroupsTable";
import NotesTable from "./../../Notes/NotesTable";
import AccountAPIKeys from "./Tabs/AccountAPIKeys";
import WalletAddresses from "./Tabs/WalletAddresses";
import Documents from "./Tabs/Documents";
import AccountVouchers from "./Tabs/AccountVouchers";
import Images from "./Tabs/Images";
// Helpers - Formatters
import { getDefaultTab, setDefaultTab } from "../../Utilities/Formatters";
import { reqObjProp } from "../../Utilities/PropTypes";
// Permissions
import { PermissionsContext } from "../../Permissions/PermissionContext";
import TabCan from "../../Permissions/TabCan";
import {
  ACCOUNTS_DOCUMENTS_VIEW,
  KEY_EDIT,
} from "../../Permissions/Permissions";

const TabsContainer = ({ data, history }) => {
  const { Account, Transactions } = data;
  return (
    <PermissionsContext.Consumer>
      {(userPermiss) => (
        <Tabs
          className="tabs-container"
          defaultIndex={getDefaultTab("account", 0)}
          onSelect={(index) => setDefaultTab("account", index)}
        >
          <TabList className="columns tab-list">
            <Tab className="column tab">Transactions</Tab>
            <Tab className="column tab">Wallet</Tab>
            <Tab className="column tab">Photos</Tab>
            <Tab className="column tab">Notes</Tab>
            <Tab className="column tab">Groups</Tab>
            {TabCan(userPermiss, KEY_EDIT) ? (
              <Tab className="column tab">API Keys</Tab>
            ) : null}
            {TabCan(userPermiss, ACCOUNTS_DOCUMENTS_VIEW) ? (
              <Tab className="column tab">Documents</Tab>
            ) : null}
            <Tab className="column tab">Vouchers</Tab>
          </TabList>

          <TabPanel>
            <AccountTransactions hash={Account.Hash} data={Transactions} />
          </TabPanel>

          <TabPanel>
            <WalletAddresses
              hash={Account.Hash}
              history={history}
              data={Transactions}
            />
          </TabPanel>

          <TabPanel>
            <Images hash={Account.Hash} />
          </TabPanel>

          <TabPanel>
            <NotesTable
              accountHash={Account.Hash}
              history={history}
              table="Account Notes"
            />
          </TabPanel>

          <TabPanel>
            <GroupsTable accountHash={Account.Hash} />
          </TabPanel>

          {TabCan(userPermiss, KEY_EDIT) ? (
            <TabPanel>
              <AccountAPIKeys accountHash={Account.Hash} />
            </TabPanel>
          ) : null}

          {TabCan(userPermiss, ACCOUNTS_DOCUMENTS_VIEW) ? (
            <TabPanel>
              <Documents
                accountHash={Account.Hash}
                accountName={Account.FullName}
              />
            </TabPanel>
          ) : null}

          <TabPanel>
            <AccountVouchers hash={Account.Hash} />
          </TabPanel>
        </Tabs>
      )}
    </PermissionsContext.Consumer>
  );
};

TabsContainer.propTypes = {
  data: reqObjProp,
};

export default TabsContainer;
