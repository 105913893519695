// Modules
import React from "react";
import columnBuilder from "./columnBuilder";
// Components
import Table from "../../Table/Table";

const CTRs = props => {
  return (
    <Table
      history={props.history}
      columns={columnBuilder}
      endpoint="compliance/ctrs"
      filter={true}
      tablename="CTRs"
    />
  );
};

export default CTRs;
