// Modules
import React from "react";
import { Link } from "react-router-dom";
// Components
import Table from "../../Table/Table";
// Helpers - Formatters
import { formatDate } from "../../Utilities/Formatters";
// Icons
import Icon from "../../Icons/Icon";
import { ICONS } from "../../Icons/IconsPath";
// Permissions
import { COMPLIANCE_SUBPOENA_ACTION } from "../../Permissions/Permissions";

const Subpoenas = (props) => {
  return (
    <Table
      history={props.history}
      columns={columnBuilder()}
      endpoint="compliance/subpoenas"
      filter={true}
      tablename="Subpoenas"
      actions={[
        {
          name: "Create New Subpoena",
          link: "/compliance/create-subpoena",
          permission: COMPLIANCE_SUBPOENA_ACTION,
        },
      ]}
    />
  );
};

const columnBuilder = () => [
  {
    Header: "Number",
    accessor: "SubpoenaNumber",
    Cell: ({ row: { original } }) => (
      <Link
        className="table-pill"
        to={`/compliance/subpoenas/${original.Hash}`}
      >
        {original.SubpoenaNumber}
      </Link>
    ),
    sortMethod: "SubpoenaNumber",
    maxWidth: 100,
  },
  {
    Header: "Agency",
    accessor: "RequestingAgency",
    Cell: ({ row: { original } }) => original.RequestingAgency,
    sortMethod: "RequestingAgency",
  },
  {
    Header: "Requested On",
    accessor: "DateReceived",
    Cell: ({ row: { original } }) => formatDate(original.DateReceived),
    sortMethod: "DateReceived",
  },
  {
    Header: "Next Due Date",
    accessor: "DueDate",
    Cell: ({ row: { original } }) => formatDate(original.DiscoveryDate),
    sortMethod: "DueDate",
  },
  {
    Header: "Completed",
    Cell: ({ row: { original } }) => (
      <Icon
        icon={original.Completed ? ICONS.ACTIVE : ICONS.INACTIVE}
        rightMargin="5"
        size="18"
        color={original.Completed ? "#009245" : "#e71d36"}
      />
    ),
    sortMethod: "Completed",
    maxWidth: 75,
  },
  {
    Header: "Country of Origin",
    accessor: "Country.Code",
  },
];

export default Subpoenas;
