import React from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'formik';
import { GenericForm, FormikContext } from '../Forms/Form';
import { FormInput, FormSelect } from '../Forms/FormInputs';
import IDImages from './IDImages';
import GenderOptions from '../Utilities/Data/GenderOptions';
import { formatDate } from '../Utilities/Formatters';
import StateLists from '../Utilities/Data/StatesList';
import PropTypes from 'prop-types';

const typeOfID = [
  { Name: "Driver's License", Hash: 0 },
  { Name: 'State/Provincial ID', Hash: 1 },
  { Name: 'Passport', Hash: 2 },
];

const IDApprovalForm = ({ data, endpoint, history }) => {
  const { Account, ComplianceID } = data;

  return (
    <GenericForm
      endpoint={endpoint}
      postReq={true}
      setValues={{
        FirstName: data && Account ? Account.FirstName : null,
        LastName: data && Account ? Account.LastName : null,
        DOB:
          data && Account
            ? formatDate(Account.DOB, 'YYYY-MM-DD', true, '')
            : null,
        Gender: ComplianceID.Gender || '',
        Issue: null,
        Expiry:
          data && ComplianceID
            ? formatDate(ComplianceID.Expiry, 'YYYY-MM-DD', true, '')
            : null,
        Type: '',
        Street: null,
        Street2: null, // optional
        City: null,
        StateCode: '',
        Zip: null,
        CountryCode: 'US',
        DocumentSerial: ComplianceID ? ComplianceID.DocumentSerial : null,
        Lifetime: false, // optional
        Middle: '', // optional
        Suffix: '', // optional
      }}
      customValidations={values => {
        let errors = {};

        for (let [key, value] of Object.entries(values)) {
          if (
            !value &&
            key !== 'Street2' &&
            key !== 'Lifetime' &&
            key !== 'Middle' &&
            key !== 'Suffix'
          ) {
            errors[key] = 'is required';
          }
        }
        // remove validation on expiry if Lifetime is true
        if (values.Lifetime) {
          delete errors.Expiry;
        }
        return errors;
      }}
      formTitle=""
      subTitle={<Link to={Account.Hash}>{Account.FullName}</Link>}
      goBack={{
        show: false,
        setHistory: history,
        setDefaultPath: `/compliance/pending-reviews`,
        setDefaultName: 'Pending Accounts',
      }}
      redirect={`/accounts/${Account.Hash}`}
      toastMsg="ID data uploaded to Account"
    >
      <FormikContext.Consumer>
        {({ errors, touched, values }) => (
          <>
            <IDImages data={ComplianceID} />
            <h2 className="form-title">Add ID Data</h2>
            <section className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="First Name"
                  name="FirstName"
                  type="text"
                  placeholder="Enter First Name"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Middle Name"
                  name="Middle"
                  type="text"
                  placeholder="Enter Middle Name (optional)"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Last Name"
                  name="LastName"
                  type="text"
                  placeholder="Enter Last Name"
                />
              </div>
            </section>
            <section className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Date of Birth"
                  name="DOB"
                  type="date"
                  placeholder="Enter Date of Birth"
                />
              </div>
              <div className="column">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="Gender"
                  name="Gender"
                  placeholder="Select Gender"
                  options={
                    <>
                      {GenderOptions.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>
            </section>

            <section className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Street Address"
                  name="Street"
                  type="text"
                  placeholder="Enter Street Address"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Street Address Line 2"
                  name="Street2"
                  type="text"
                  placeholder="Apt, Suite, etc"
                />
              </div>
            </section>

            <section className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="City"
                  name="City"
                  type="text"
                  placeholder="Enter City"
                />
              </div>
              <div className="column">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="State"
                  name="StateCode"
                  placeholder="Select State"
                  options={StateLists.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Postal Code"
                  name="Zip"
                  type="number"
                  placeholder="Enter Postal Code"
                />
              </div>
            </section>

            <section className="columns">
              <div className="column">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="ID Type"
                  name="Type"
                  placeholder="Type of ID"
                  options={typeOfID.map(item => (
                    <option key={item.Hash} value={item.Hash}>
                      {item.Name}
                    </option>
                  ))}
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Document Serial"
                  name="DocumentSerial"
                  type="text"
                  placeholder="Enter Document Serial"
                />
              </div>
            </section>
            <section className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Issue Date"
                  name="Issue"
                  type="date"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Expiry Date"
                  name="Expiry"
                  type="date"
                  disabled={values.Lifetime}
                />
              </div>
            </section>
            <section className="columns">
              <div className="column is-1 has-text-centered">
                <label className="item-label">Lifetime</label>
                <Field name="Lifetime" type="checkbox" />
              </div>
            </section>
          </>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

IDApprovalForm.propTypes = {
  endpoint: PropTypes.string.isRequired,
  data: PropTypes.object,
  history: PropTypes.object,
};

export default IDApprovalForm;
