import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

export const EditableLink = ({
  disabled,
  editing,
  id,
  label,
  link,
  linkName,
  name,
  testID,
  type,
}) => {
  return (
    <div className="form-row" data-testid={testID}>
      {editing ? (
        <>
          <ErrorMessage name={name} component="div" className="form-error" />
          <label htmlFor={id || name} className="item-label">
            {label}
          </label>
          <Field id={id || name} type={type} name={name} disabled={disabled} />
        </>
      ) : (
        <>
          <label className="item-label">{label}</label>
          <p>
            {link ? (
              <a target="_blank" rel="noopener noreferrer" href={link}>
                {linkName}
              </a>
            ) : (
              '-'
            )}
          </p>
        </>
      )}
    </div>
  );
};

EditableLink.defaultProps = {
  disabled: false,
  editing: false,
  testID: 'xEditableInput',
  type: 'text',
};

EditableLink.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  linkName: PropTypes.string,
  name: PropTypes.string,
  testID: PropTypes.string,
  type: PropTypes.string,
};

export default EditableLink;
