const parseToHyperLink = (str) => {
  let links = [];
  let names = [];
  let code = "";
  let contacts = str.split(" ");
  const areaCode = /^\(?([0-9]{3})\)$/;
  const main = /^\(?([0-9]{3})[-. ]?([0-9]{4})$/;
  const email = /\S+@\S+\.\S+/;
  contacts.forEach((contact) => {
    if (contact.match(areaCode)) code += contact + " ";
    if (contact.match(main)) code += contact;
    if (isValidPhone(contact)) {
      links.push({
        contact: contact,
        href: `tel:${contact}`,
      });
    } else if (contact.match(email)) {
      links.push({
        contact: contact,
        href: `mailto:${contact}`,
      });
    } else {
      names.push(contact);
    }
  });

  if (isValidPhone(code)) {
    links.push({
      contact: code,
      href: `tel:${code}`,
    });
  }

  links.push(
    names
      .join(" ")
      .replace(/[0-9,\-,(,)]/g, " ")
      .trim()
  );
  return links;
};

function isValidPhone(p) {
  var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
  var digits = p.replace(/\D/g, "");
  return phoneRe.test(digits);
}

export default parseToHyperLink;
