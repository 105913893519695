import React from 'react';
import { FormInput, FormSelect } from '../../../Forms/FormInputs';

const transactionACHCodes = [
  {
    name: 'Checking',
    value: 1,
  },
  {
    name: 'Savings',
    value: 2,
  },
];

const AchInputs = ({ touched, errors }) => (
  <>
    <div className="columns">
      <div className="column is-4">
        <FormInput
          label="Payable To"
          name="PayableTo"
          type="text"
          placeholder="John Doe"
          errors={errors}
          touched={touched}
          testID="PayableTo"
        />
      </div>
      <div className="column is-4">
        <FormInput
          label="Routing Number"
          name="RoutingNumber"
          type="text"
          component="input"
          placeholder="011103093"
          errors={errors}
          touched={touched}
          testID="RoutingNumber"
        />
      </div>
    </div>
    <div className="columns">
      <div className="column is-4">
        <FormInput
          label="Account Number"
          name="AccountNumber"
          type="text"
          component="input"
          placeholder="091000022"
          errors={errors}
          touched={touched}
          testID="AccountNumber"
        />
      </div>
      <div className="column is-4">
        <FormSelect
          label="Transaction ACH Code"
          name="TransactionACHCode"
          type="text"
          errors={errors}
          touched={touched}
          placeholder="Select Transaction ACH Code"
          options={
            <>
              {transactionACHCodes.map(item => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </>
          }
          testID="TransactionACHCode"
        />
      </div>
    </div>
    <div className="columns">
      <div className="column is-4">
        <FormInput
          label="Transit Number"
          name="TransitNumber"
          type="text"
          component="input"
          placeholder="Transit Number"
          errors={errors}
          touched={touched}
          testID="TransitNumber"
        />
      </div>
      <div className="column is-4">
        <FormInput
          label="Financial Institution Number"
          name="FinancialInstitutionNumber"
          type="text"
          component="input"
          placeholder="Financial Institution Number"
          errors={errors}
          touched={touched}
          testID="FinancialInstitutionNumber"
        />
      </div>
    </div>
  </>
);

export default AchInputs;
