const platformKeys = {
  Active: {
    Label: "Active",
    Type: "bool",
    Options: [
      {
        Display: "True",
        Value: true,
      },
      {
        Display: "False",
        Value: false,
      },
    ],
  },
  "Location.BusinessName": {
    Label: "Business Name",
    Type: "string",
  },
  CreatedAt: {
    Label: "Create Date",
    Type: "daterange",
  },
  "Location.CrossStreet": {
    Label: "Cross Street",
    Type: "string",
  },
  DeviceIdentifier: {
    Label: "Device Identifier",
    Type: "string",
  },
  "Group.Name": {
    Label: "Group Name",
    Type: "string",
  },
  "PlatformType.Model": {
    Label: "Hardware Model",
    Type: "string",
    Options: [
      {
        Display: "Maya Tech",
        Value: "Maya Tech",
      },
      {
        Display: "Futurakiosk",
        Value: "Futurakiosk",
      },
      {
        Display: "Legacy",
        Value: "Legacy",
      },
      {
        Display: "Bravo",
        Value: "Bravo",
      },
      {
        Display: "Answers Tier III",
        Value: "Answers Tier III",
      },
      {
        Display: "DCS POS",
        Value: "DCS POS",
      },
      {
        Display: "Genmega.CARD",
        Value: "Genmega.CARD",
      },
      {
        Display: "Genmega.CDU",
        Value: "Genmega.CDU",
      },
      {
        Display: "Genmega.Sidecar",
        Value: "Genmega.Sidecar",
      },
      {
        Display: "Genmega.UK",
        Value: "Genmega.UK",
      },
      {
        Display: "Genmega.XL",
        Value: "Genmega.XL",
      },
      {
        Display: "Hyosung.ATM",
        Value: "Hyosung.ATM",
      },
      {
        Display: "Hyosung.Sidecar",
        Value: "Hyosung.Sidecar",
      },
      {
        Display: "Kiosk.com",
        Value: "Kiosk.com",
      },
      {
        Display: "EZMoney POS",
        Value: "EZMoney POS",
      },
      {
        Display: "Slabb",
        Value: "Slabb",
      },
    ],
  },
  "LastEvent.Timestamp": {
    Label: "Last Update Date",
    Type: "daterange",
  },
  "Location.Neighborhood": {
    Label: "Neighborhood",
    Type: "string",
  },
  "PlatformType.Name": {
    Label: "Platform",
    Type: "string",
    Options: [
      {
        Display: "iPad",
        Value: "iPad",
      },
      {
        Display: "API",
        Value: "API",
      },
      {
        Display: "Kiosk",
        Value: "Kiosk",
      },
      {
        Display: "Website",
        Value: "web",
      },
      {
        Display: "Payment Processor",
        Value: "Payment Processor",
      },
    ],
  },
  Available: {
    Label: "Online",
    Type: "bool",
    Options: [
      {
        Display: "True",
        Value: true,
      },
      {
        Display: "False",
        Value: false,
      },
    ],
  },
  "PlatformTypes.ThirdParty": {
    Label: "Third Party",
    Type: "string",
    Options: [
      {
        Display: "True",
        Value: true,
      },
      {
        Display: "False",
        Value: false,
      },
    ],
  },
  Slug: {
    Label: "Slug",
    Type: "string",
  },
  "Location.Address.StateCode": {
    Label: "State Code",
    Type: "string",
    Options: [
      {
        Display: "AL",
        Value: "AL",
      },
      {
        Display: "AK",
        Value: "AK",
      },
      {
        Display: "AZ",
        Value: "AZ",
      },
      {
        Display: "AR",
        Value: "AR",
      },
      {
        Display: "CA",
        Value: "CA",
      },
      {
        Display: "CO",
        Value: "CO",
      },
      {
        Display: "CT",
        Value: "CT",
      },
      {
        Display: "DE",
        Value: "DE",
      },
      {
        Display: "DC",
        Value: "DC",
      },
      {
        Display: "FL",
        Value: "FL",
      },
      {
        Display: "GA",
        Value: "GA",
      },
      {
        Display: "HI",
        Value: "HI",
      },
      {
        Display: "ID",
        Value: "ID",
      },
      {
        Display: "IL",
        Value: "IL",
      },
      {
        Display: "IN",
        Value: "IN",
      },
      {
        Display: "IA",
        Value: "IA",
      },
      {
        Display: "KS",
        Value: "KS",
      },
      {
        Display: "KY",
        Value: "KY",
      },
      {
        Display: "LA",
        Value: "LA",
      },
      {
        Display: "ME",
        Value: "ME",
      },
      {
        Display: "MH",
        Value: "NH",
      },
      {
        Display: "MD",
        Value: "MD",
      },
      {
        Display: "MA",
        Value: "MA",
      },
      {
        Display: "MI",
        Value: "MI",
      },
      {
        Display: "MN",
        Value: "MN",
      },
      {
        Display: "MS",
        Value: "MS",
      },
      {
        Display: "MO",
        Value: "MO",
      },
      {
        Display: "MT",
        Value: "MT",
      },
      {
        Display: "NE",
        Value: "NE",
      },
      {
        Display: "NV",
        Value: "NV",
      },
      {
        Display: "NH",
        Value: "NH",
      },
      {
        Display: "NJ",
        Value: "NJ",
      },
      {
        Display: "NM",
        Value: "NM",
      },
      {
        Display: "NY",
        Value: "NY",
      },
      {
        Display: "NC",
        Value: "NC",
      },
      {
        Display: "ND",
        Value: "ND",
      },
      {
        Display: "OH",
        Value: "OH",
      },
      {
        Display: "OK",
        Value: "OK",
      },
      {
        Display: "OR",
        Value: "OR",
      },
      {
        Display: "PA",
        Value: "PA",
      },
      {
        Display: "PR",
        Value: "PR",
      },
      {
        Display: "RI",
        Value: "RI",
      },
      {
        Display: "SC",
        Value: "SC",
      },
      {
        Display: "SD",
        Value: "SD",
      },
      {
        Display: "TN",
        Value: "TN",
      },
      {
        Display: "TX",
        Value: "TX",
      },
      {
        Display: "UT",
        Value: "UT",
      },
      {
        Display: "VT",
        Value: "VT",
      },
      {
        Display: "VI",
        Value: "VI",
      },
      {
        Display: "VA",
        Value: "VA",
      },
      {
        Display: "WA",
        Value: "WA",
      },
      {
        Display: "WV",
        Value: "WV",
      },
      {
        Display: "WI",
        Value: "WI",
      },
      {
        Display: "WY",
        Value: "WY",
      },
    ],
  },
};
export default platformKeys;
