import Data from './Data';
import ProductData from './Product';

export default class DefaultWalletData extends Data {
  properties() {
    return {
      ID: { type: 'number' },
      AccountID: { type: 'number' },
      CreatedAt: { type: 'string' },
      Hash: { type: 'string' },
      ProductID: { type: 'number' },
      UpdatedAt: { type: 'string' },
      WithdrawalAddress: { type: 'string' },
    };
  }

  hasOne() {
    return {
      Product: ProductData,
    };
  }
}
