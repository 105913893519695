// Modules
import React from 'react';
// Components
import Table from '../../Table/Table';
import columnBuilder from './columnBuilder';

const CustomerRefunds = ({ history, location }) => {
  return (
    <Table
      history={history}
      location={location}
      columns={columnBuilder}
      endpoint="customer-refunds"
      tablename="Customer Refunds"
      actions={[
        {
          link: '/customer-refunds/create-refund',
          name: 'Create Refund',
        },
      ]}
    />
  );
};

export default CustomerRefunds;
