/* eslint no-console: ["error", { allow: [ "error"] }] */

// Modules
import React, { Component } from 'react';
import moment from 'moment';
// Components
import { Loading } from '../Utilities/Components';
import { GenericForm, FormikContext } from '../Forms/Form';
import { FormSelect, FormInput } from '../Forms/FormInputs';
// Helpers - Formatters
import { formatDate, formatPercent } from '../Utilities/Formatters';
// Configs
import { API } from '../Config/cfg.js';

class AddLocationCommission extends Component {
  state = {
    selectedGroupHash: '',
    loading: true,
    commissions: [],
    groups: [],
  };

  componentDidMount() {
    this.getGroups();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { selectedGroupHash } = this.state;
    if (prevState.selectedGroupHash !== selectedGroupHash) {
      this.getGroupCommissions(selectedGroupHash);
    }
  };

  getGroups = () => {
    API.get('/groups?all=true').then(res => {
      this.setState({
        groups: res.data.Data,
        loading: false,
      });
    });
  };

  getGroupCommissions = hash => {
    API.get(`/groups/${hash}/commissions`)
      .then(res => {
        this.setState({
          commissions: res.data.Data,
          loading: false,
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleGroupSelect = e => {
    this.setState({ selectedGroupHash: e.target.value });
  };

  displayInput = type => {
    let setInput;
    switch (type) {
      case '0':
      case '9':
        setInput = 'fiat';
        break;
      case '1':
      case '5':
        setInput = 'percent';
        break;
      case '2':
      case '6':
        setInput = null;
        break;
      case '3':
      case '7':
        setInput = 'percent';
        break;
      case '4':
      case '8':
        setInput = 'both';
        break;
      default:
        setInput = null;
        break;
    }
    return setInput;
  };

  displayAchNet(type) {
    if (type === '1' || type === '0' || type === '5') {
      return true;
    }
    return false;
  }

  formatCommissionOption = item => {
    const { CommissionTypeName, PaymentType, Fiat, Percent, ACHNet } = item;

    const commArr = [CommissionTypeName, PaymentType];

    if (Fiat) {
      commArr.push(Fiat);
    }
    if (Percent) {
      commArr.push(formatPercent(Percent));
    }
    if (ACHNet) {
      commArr.push('ACHNet');
    }

    return commArr.join(', ');
  };

  render() {
    const { loading, commissions, groups } = this.state;
    const { history, hash } = this.props;

    if (loading) {
      return <Loading />;
    }
    return (
      <GenericForm
        endpoint={`locations/${hash}/commissions`}
        setValues={{
          GroupCommissionHash: '',
          ActivationDate: '',
          Expiry: '',
        }}
        customValidations={values => {
          let errors = {};
          if (!values.GroupCommissionHash) {
            errors.GroupCommissionHash = 'is required';
          }
          if (!values.ActivationDate) {
            errors.ActivationDate = 'is required';
          }
          if (!values.Expiry) {
            delete values.Expiry;
          } else if (moment(values.Expiry).isBefore(values.ActivationDate)) {
            errors.Expiry = 'Invalid date';
          } else if (
            moment(values.ActivationDate).isBefore(moment().format())
          ) {
            errors.ActivationDate = 'has already taken place';
          }
          return errors;
        }}
        formTitle="Add Location Commission"
        subTitle="Add Commission"
        goBack={{
          show: true,
          setHistory: history,
          setDefaultPath: '/locations',
          setDefaultName: 'Locations',
        }}
        toastMsg="New commission payment has been successfully created"
      >
        <FormikContext.Consumer>
          {props => {
            if (props) {
              props.setValues = {
                ActivationDate: formatDate(
                  props.values.ActivationDate,
                  'YYYY/DD/MM',
                  true
                ),
              };
            }

            return (
              <>
                <div className="columns is-multiline">
                  <div className="column is-3">
                    <label className="item-label">Group Name</label>
                    <select onChange={this.handleGroupSelect} defaultValue="">
                      <option value="" disabled>
                        Select Group
                      </option>
                      {groups.map(item => (
                        <option key={item.Hash} value={item.Hash}>
                          {item.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="column is-3">
                    <FormSelect
                      errors={props.errors}
                      touched={props.touched}
                      label="Commission"
                      name="GroupCommissionHash"
                      placeholder="Select Commission"
                      options={
                        <>
                          {commissions.map(item => (
                            <option key={item.Hash} value={item.Hash}>
                              {this.formatCommissionOption(item)}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </div>
                  <div className="column is-3">
                    <FormInput
                      errors={props.errors}
                      touched={props.touched}
                      label="Expiration Date"
                      name="Expiry"
                      type="date"
                      placeholder="Expiration Date"
                    />
                  </div>

                  <div className="column is-3">
                    <FormInput
                      errors={props.errors}
                      touched={props.touched}
                      label="Activation Date"
                      name="ActivationDate"
                      type="date"
                      placeholder="Activation Date"
                    />
                  </div>
                </div>
              </>
            );
          }}
        </FormikContext.Consumer>
      </GenericForm>
    );
  }
}

export default AddLocationCommission;
