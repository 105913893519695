import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { formatLabel } from './utils';
import PropTypes from 'prop-types';

const EditableInput = ({
  currentValue,
  disabled,
  editing,
  id,
  label,
  name,
  styles,
  testID,
  type,
}) => {
  return (
    <div className={`form-row ${styles}`} data-testid={testID}>
      {editing ? (
        <div className="form-row column">
          <label
            htmlFor={id || name}
            className="item-label"
            data-testid={`${testID}-label`}
          >
            {formatLabel(label)}
            <ErrorMessage name={name} component="span" className="form-error" />
          </label>
          <Field
            id={id || name}
            type={type}
            name={name}
            data-testid={`${testID}-input`}
            disabled={disabled}
          />
        </div>
      ) : (
        <>
          <label className="item-label" data-testid={`${testID}-label`}>
            {formatLabel(label)}
          </label>
          <div className="p-style" data-testid={`${testID}-value`}>
            {currentValue}
          </div>
        </>
      )}
    </div>
  );
};

EditableInput.defaultProps = {
  currentValue: '-',
  disabled: false,
  editing: false,
  testID: 'xEditableInput',
  type: 'text',
};

EditableInput.propTypes = {
  currentValue: PropTypes.any,
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  styles: PropTypes.string,
  testID: PropTypes.string,
  type: PropTypes.string,
};

export default EditableInput;
