// Modules
import React from "react";
// Components
import { GenericForm, FormikContext } from "../../Forms/Form";
import { FormInput, FormSelect } from "../../Forms/FormInputs";
import { reqStrProp, reqObjProp } from "./../../Utilities/PropTypes";

const options = [
  { name: "False Positive", value: 0 },
  { name: "Escalated", value: 2 },
  { name: "90 Day Review", value: 1 },
  { name: "Data Quality", value: 3 },
  { name: "Case Review In Progress", value: 4 }
];

const AlertNarrative = ({ endPoint, history }) => {
  return (
    <GenericForm
      endpoint={endPoint}
      postReq={true}
      setValues={{
        Disposition: "",
        Narrative: ""
      }}
      formTitle="Create Narrative"
      subTitle={null}
      goBack={{
        show: false,
        setHistory: history
      }}
      toastMsg="Narrative has been successfully created"
    >
      <FormikContext.Consumer>
        {props => (
          <div className="columns">
            <div className="column is-6">
              <FormInput
                label="Narrative"
                name="Narrative"
                component="textarea"
                type="text"
                placeholder="Enter Narrative..."
                errors={props.errors}
                touched={props.touched}
              />
              <FormSelect
                label="Disposition"
                name="Disposition"
                errors={props.errors}
                touched={props.touched}
                placeholder="Select Disposition Type"
                options={
                  <React.Fragment>
                    {options.map(type => (
                      <option key={type.value} value={type.value}>
                        {type.name}
                      </option>
                    ))}
                  </React.Fragment>
                }
              />
            </div>
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

AlertNarrative.propTypes = {
  endPoint: reqStrProp,
  history: reqObjProp
};

export default AlertNarrative;
