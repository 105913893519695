// Modules
import React, { Component } from 'react';
// Data
import accountKeys from './JSON/accounts';
import achKeys from './JSON/ach_keys';
import accountNotesKeys from './JSON/account_notes';
import alertKeys from './JSON/compliance_alerts';
import banKeys from './JSON/compliance_bans';
import ctrKeys from './JSON/compliance_ctrs';
import eddKeys from './JSON/compliance_edds';
import subpoenaKeys from './JSON/compliance_subpoenas';
import investigationKeys from './JSON/compliance_investigations';
import transactionKeys from './JSON/transactions';
import locationKeys from './JSON/locations';
import locationEventKeys from './JSON/location_events';
import groupKeys from './JSON/groups';
import platformKeys from './JSON/platforms';
import accountReviewsKeys from './JSON/account_reviews';
import fundingKeys from './JSON/funding';
import cashCollection from './JSON/cash_collection';
import commissionKeys from './JSON/commissions';
// Search Components
import TextFilterValue from './TextFilterValue';
import SelectFilterValue from './SelectFilterValue';
import DateRangeFilterValue from './DateRangeFilterValue';
import OperatorFilterValue from './OperatorFilterValue';
// Components
import { ToastError } from '../Utilities/Components';
// Helpers - Formatters
import { formatLowerCase } from '../Utilities/Formatters';
// Styles
import './FilterSearch.scss';
import { reqFuncProp, reqStrProp } from '../Utilities/PropTypes';

class FilterSearch extends Component {
  state = {
    filterKey: '',
    filterKeyLabel: '',
    filterValue: '',
    filterValueDisplay: '',
    filterDateRange1: '',
    filterDateRange2: '',
    filterType: '',
    filterKeys: [],
    filterOptions: [],
    operator: '=',
    removedFilter: false,
    selectedFilters: [],
  };

  componentDidMount() {
    const tableKeys = this.setFilter();
    if (this.props.filterQuery.split('f=')[1]) {
      this.generateQueryStringFilters(tableKeys);
    }
  }

  componentDidUpdate() {
    const { setTableFilters } = this.props;
    let filterReq = this.buildFilterReq();
    setTableFilters(filterReq);
  }

  equalArrCheck = (a, b) => {
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    const sortedA = a.sort((a, b) => (a.Label > b.Label ? 1 : -1));
    const sortedB = b.sort((a, b) => (a.Label > b.Label ? 1 : -1));

    for (var i = 0; i < sortedA.length; ++i) {
      if (sortedA[i] !== sortedB[i]) return false;
    }
    return true;
  };

  // Construct standard filter objects from query parameters
  generateQueryStringFilters = keys => {
    const { filterQuery } = this.props;

    // Breakdown filter parameter into 2D Array [[key, value]]
    const fIndex = filterQuery.indexOf('f=');
    const filterString = filterQuery.substring(fIndex + 2, filterQuery.length);
    const filters = filterString.split('%26');
    const filterPairs = filters.map(filter => {
      const [Operator] = filter.match(
        /(:|!=|(%3E|%3C)(=|%3D)|(%3E)|(%3C)|=)/gi
      );
      let obj = { Operator };
      const filterArr = filter.split(Operator);
      filterArr.forEach((el, i) => {
        if (i === 0) {
          obj.Option = el;
        } else {
          obj.Label = el;
        }
      });
      return obj;
    });

    const mappedFilters = this.mapQueryToFilter(filterPairs, keys);
    this.setState({ selectedFilters: mappedFilters });
  };

  mapQueryToFilter = (queries, keys) => {
    return queries.map(pair => {
      let obj = {
        Operator: pair.Operator,
      };
      obj.label = keys[pair.Option] ? keys[pair.Option].Label : pair.Option;
      let optionDetails;
      // Check for an array of options
      if (pair.Option === 'Permissions.Key') {
        // Directly compare values
        const groupMatches = keys[pair.Option].Options.map(group => {
          return group.Values.filter(el => pair.Label === el.Value);
        });
        optionDetails = groupMatches.find(match => match[0])[0];
        obj.display = optionDetails?.Display;
        obj.value = optionDetails?.Value;
      } else if (keys[pair.Option].Options) {
        optionDetails = keys[pair.Option].Options.find(el => {
          if (parseInt(pair.Label) === 'NaN') {
            return formatLowerCase(el.Display) === formatLowerCase(pair.Label);
          } else {
            return formatLowerCase(el.Value) === formatLowerCase(pair.Label);
          }
        });
        obj.display = optionDetails?.Display;
        obj.value = optionDetails?.Value;
      } else {
        obj.value = decodeURI(pair.Label);
      }
      obj.apiValue = pair.Option;
      return obj;
    });
  };

  buildFilterReq = () => {
    const { selectedFilters, operator } = this.state;
    let reqStr = '';
    selectedFilters.forEach(filter => {
      const value = encodeURIComponent(filter.value);
      if (reqStr.includes(filter.apiValue)) return;
      reqStr.length >= 1
        ? (reqStr += `%26${filter.apiValue}${
            filter.Operator || operator
          }${value}`)
        : (reqStr = `${filter.apiValue}${filter.Operator || operator}${value}`);
    });

    return reqStr;
  };

  setFilter = () => {
    let keys;
    switch (this.props.table) {
      case 'Transactions':
        keys = transactionKeys;
        break;
      case 'Cash Locations':
      case 'Locations':
        keys = locationKeys;
        break;
      case 'Link Account to Investigation':
      case 'Link Account to Subpoena':
      case 'Accounts':
        keys = accountKeys;
        break;
      case 'ACH':
        keys = achKeys;
        break;
      case 'Transaction Notes':
      case 'Account Notes':
      case 'Location Notes':
        keys = accountNotesKeys;
        break;
      case 'Events':
        keys = locationEventKeys;
        break;
      case 'Groups':
        keys = groupKeys;
        break;
      case 'Alerts':
        keys = alertKeys;
        break;
      case 'CTRs':
        keys = ctrKeys;
        break;
      case 'High Risk (EDD)':
        keys = eddKeys;
        break;
      case 'Subpoenas':
        keys = subpoenaKeys;
        break;
      case 'Investigations':
        keys = investigationKeys;
        break;
      case 'Platforms':
        keys = platformKeys;
        break;
      case 'Pending Accounts':
        keys = accountReviewsKeys;
        break;
      case 'BTC Wallet Funding History':
      case 'TBTC Wallet Funding History':
        keys = fundingKeys;
        break;
      case 'Cash Collection':
        keys = cashCollection;
        break;
      case 'Pending Account Bans':
        keys = banKeys;
        break;
      default:
        break;
    }

    // Dynamic table name
    if (this.props.table?.includes('Earnings By Month')) {
      keys = commissionKeys;
    }

    this.setKeysDropdown(keys);
    return keys;
  };

  setKeysDropdown = keys => {
    let arrOfObj = Object.entries(keys);
    let objKeys = [];

    arrOfObj.forEach(obj => {
      let opts = obj[1].Options ? obj[1].Options : null;
      objKeys.push({
        apiValue: obj[0],
        label: obj[1].Label,
        type: obj[1].Type,
        opts: opts,
      });
    });

    this.setState({
      filterKeys: objKeys,
    });
  };

  setFilterKey = event => {
    let value = JSON.parse(event.target.value);
    this.setState({
      filterKey: value.apiValue,
      filterKeyLabel: value.label,
      filterType: value.type,
      filterValue: '',
      filterOptions: value.opts,
    });
  };

  setFilterValue = (event, dropDown) => {
    // Hacky but prevents 5 + switch cases to define the display text
    // Passing TRUE for drop down input so the display value shows instead of the apiValue
    if (dropDown) {
      let value = JSON.parse(event.target.value);
      this.setState({
        filterValue: value.Value,
        filterValueDisplay: value?.Display,
      });
    } else {
      this.setState({
        filterValue: event.target.value,
      });
    }
  };

  setOperator = event => {
    const operator = JSON.parse(event.target.value);
    this.setState({ operator: operator.Value });
  };

  setFilterDateRangeTo = event => {
    this.setState({ filterDateRange1: event.target.value });
  };

  setFilterDateRangeFrom = event => {
    if (this.state.filterDateRange1.length === 0) {
      ToastError('Please select a starting date first');
      return;
    }

    // replace is for Safari due to lack of input[type="date"] support
    const formattedRange1 = this.state.filterDateRange1.replace(
      /(\d\d)\/(\d\d)\/(\d{4})/,
      '$3-$1-$2'
    );
    const formattedRange2 = event.target.value.replace(
      /(\d\d)\/(\d\d)\/(\d{4})/,
      '$3-$1-$2'
    );
    this.setState({
      filterDateRange2: event.target.value,
      // Sets filter for POST req
      filterValue: `${formattedRange1}|${formattedRange2}`,
    });
  };

  addFilter = event => {
    event.preventDefault();
    const {
      filterKey,
      filterKeyLabel,
      filterValue,
      filterValueDisplay,
      selectedFilters,
      operator,
    } = this.state;
    let validated = this.validateFilters(filterKey, filterValue);
    let newFilter;

    if (validated) {
      newFilter = {
        label: filterKeyLabel,
        display: filterValueDisplay ? filterValueDisplay : null,
        value: filterValue,
        apiValue: filterKey,
        Operator: operator,
      };
    }

    this.setState({
      selectedFilters: newFilter
        ? [...selectedFilters, newFilter]
        : selectedFilters,
      removedFilter: false,
      filterKey: '',
      filterKeyLabel: '',
      filterDateRange1: '',
      filterDateRange2: '',
      filterValue: '',
      filterValueDisplay: '',
      filterType: '',
      operator: '=',
    });
    this.resetFilters();
  };

  validateFilters = (filterKey, filterValue) => {
    const { selectedFilters } = this.state;
    if (filterKey === '') {
      ToastError('Please select a filter key');
      return false;
    } else if (filterValue === '') {
      ToastError('Please select a filter value');
      return false;
    } else if (selectedFilters.find(entry => filterKey === entry.apiValue)) {
      ToastError('Only 1 filter per category allowed');
      return false;
    } else {
      return true;
    }
  };

  resetFilters = () => {
    let keySelect = document.getElementById('keySelect');
    let valueSelect = document.getElementById('valueSelect');
    let operatorSelect = document.getElementById('operatorSelect');

    keySelect.selectedIndex = 0;
    if (operatorSelect) {
      operatorSelect.selectedIndex = 0;
    }
    if (valueSelect) {
      valueSelect.selectedIndex = 0;
    }
  };

  removeFilter = e => {
    const { selectedFilters } = this.state;
    const filters = selectedFilters.filter(hash => {
      return (
        hash.value.toString() !==
          e.target.getAttribute('data-text').toString() ||
        hash.apiValue !== e.target.getAttribute('data-key')
      );
    });
    this.setState({
      selectedFilters: filters,
      removedFilter: true,
    });
  };

  filterEqualityCheck = () => {
    const { filterKey } = this.state;
    switch (filterKey) {
      case 'ReviewedAndApproved':
      case 'Disposition':
      case 'Location.DebitAccount.Name':
      case 'RoutingNumber':
        return true;
      default:
        return false;
    }
  };

  needsOperator = () => {
    const { filterType, filterKeyLabel } = this.state;
    let valid;

    switch (filterKeyLabel) {
      case 'Approved':
      case 'Debit Account':
        return true;
      case 'Event':
      case 'Transaction Status':
        return false;
      default:
        valid = true;
    }

    switch (filterType) {
      case 'daterange':
      case 'string':
      case 'bool':
        valid = false;
        break;
      default:
        valid = true;
    }

    return valid;
  };

  render() {
    const {
      filterKey,
      filterKeyLabel,
      filterValue,
      filterDateRange1,
      filterDateRange2,
      filterType,
      filterKeys,
      filterOptions,
      selectedFilters,
    } = this.state;

    const valueLabel = <p className="item-label">Filter Value</p>;

    return (
      <React.Fragment>
        <div id="filterSearch">
          <form className="form-outline form-create" onSubmit={this.addFilter}>
            <div className="columns is-vcentered">
              <div className="column">
                <p className="item-label">Filter Key</p>
                <select
                  id="keySelect"
                  onChange={this.setFilterKey}
                  defaultValue=""
                >
                  <option value="" key="placeholder" disabled>
                    Select a filter
                  </option>
                  {filterKeys.map(item => (
                    <option key={item.apiValue} value={JSON.stringify(item)}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              {this.needsOperator() && (
                <div className="column is-3">
                  <OperatorFilterValue
                    setOperator={this.setOperator}
                    equalityOnly={this.filterEqualityCheck()}
                  />
                </div>
              )}
              <div className="column">
                {filterOptions ? (
                  <SelectFilterValue
                    filterOptions={filterOptions}
                    valueLabel={valueLabel}
                    filterKey={filterKey}
                    setFilterValue={this.setFilterValue}
                    grouped={filterKeyLabel === 'Permissions' ? true : false}
                  />
                ) : filterType === 'daterange' ? (
                  <DateRangeFilterValue
                    filterKey={filterKey}
                    to={filterDateRange1}
                    from={filterDateRange2}
                    setTo={this.setFilterDateRangeTo}
                    setFrom={this.setFilterDateRangeFrom}
                  />
                ) : (
                  <TextFilterValue
                    valueLabel={valueLabel}
                    type={filterType}
                    filterValue={filterValue}
                    setFilterValue={this.setFilterValue}
                    filterKey={filterKey}
                  />
                )}
              </div>
              <div className="column is-narrow">
                <button
                  id="filterBtn"
                  className="btn primary-btn"
                  disabled={!filterKey}
                >
                  Add Search Filter
                </button>
              </div>
            </div>
            {selectedFilters ? (
              <div className="columns is-multiline is-vcentered">
                {selectedFilters.map(filter => {
                  return (
                    <div
                      className="column is-4"
                      key={filter.label + filter.display}
                    >
                      <button
                        key={filter.label + filter.display}
                        onClick={e => this.removeFilter(e)}
                        className="selected-filter flatten"
                        data-key={filter.apiValue}
                        data-text={filter.value}
                      >
                        {filter.label} {decodeURI(filter.Operator) || '-'}{' '}
                        {filter.display ? filter.display : filter.value}
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </form>
        </div>
      </React.Fragment>
    );
  }
}

FilterSearch.propTypes = {
  setTableFilters: reqFuncProp,
  table: reqStrProp,
};

export default FilterSearch;
