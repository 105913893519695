// Modules
import React from 'react';
import { Link } from 'react-router-dom';
import { EditBtn, EmptyState } from '../../Utilities/Components';
import PropTypes from 'prop-types';

const ChildrenGroups = ({ data }) => (
  <React.Fragment>
    {data ? (
      <React.Fragment>
        <div className="edit-actions-override">
          <EditBtn sectionTitle="Children Groups" />
        </div>
        {data.map(child => {
          return (
            <div key={child.Hash}>
              <Link to={`/groups/${child.Hash}`}>{child.Name}</Link>
            </div>
          );
        })}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="edit-actions-override">
          <EditBtn sectionTitle="Children Groups" />
        </div>
        <EmptyState msg="No child groups" />
      </React.Fragment>
    )}
  </React.Fragment>
);

ChildrenGroups.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default ChildrenGroups;
