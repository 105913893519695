/* COMPONENT OVERVIEW
- Renders navbar
*/
// Modules
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import SearchBox from '../Search/SearchBox';
import DropdownMenu from './DropdownMenu';
import WalletBalances from './WalletBalances';
// Styles
import './Navbar.scss';
import { AccountContext } from '../Authentication/AccountContext';

const NavBar = ({ logout }) => (
  <AccountContext.Consumer>
    {({ account }) => (
      <div className="columns head-navbar is-vcentered">
        <div className="column">
          <SearchBox />
        </div>
        <div className="column">
          <WalletBalances />
        </div>
        {!account?.OTPEnabled && (
          <div className="column is-hidden-mobile is-narrow">
            <Link
              className="btn primary-btn-outline two-factor"
              to={`/settings`}
            >
              Set Up 2FA
            </Link>
          </div>
        )}
        <div>
          <DropdownMenu Account={account} logout={logout} />
        </div>
      </div>
    )}
  </AccountContext.Consumer>
);

export default NavBar;
