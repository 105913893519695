// Modules
import {
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  shape,
  string,
} from 'prop-types';

// Required primitive types
export const reqArrProp = array.isRequired;
export const reqBoolProp = bool.isRequired;
export const reqFuncProp = func.isRequired;
export const reqNumProp = number.isRequired;
export const reqObjProp = object.isRequired;
export const reqStrProp = string.isRequired;

// Primitive types
export const arrProp = array;
export const boolProp = bool;
export const funcProp = func;
export const numProp = number;
export const objProp = object;
export const strProp = string;

export const Platform = shape({
  Hash: reqStrProp,
  Name: reqStrProp,
});

// Permissions types needs to be built once we're setting them in the UI
// type: PropTypes.oneOf(['user','admin'])
export const Permissions = arrayOf(
  shape({
    Description: reqStrProp,
    Hash: reqStrProp,
    Key: reqStrProp,
    Name: reqStrProp,
  })
).isRequired;

export const addressProps = shape({
  Active: reqBoolProp,
  City: reqStrProp,
  Hash: reqStrProp,
  Latitude: reqNumProp,
  Longitude: reqNumProp,
  Preferred: reqBoolProp,
  StateCode: reqStrProp,
  Street: reqStrProp,
  ZIP: reqStrProp,
}).isRequired;

export const addressesProps = arrayOf(addressProps);

export const phoneProps = shape({
  Active: reqBoolProp,
  Hash: reqStrProp,
  Phone: reqStrProp,
  Preferred: reqBoolProp,
});

export const phonesProps = arrayOf(phoneProps);

export const accountProps = shape({
  FirstName: strProp,
  LastName: strProp,
  DOB: strProp,
  Email: strProp,
  FullName: strProp,
  Hash: strProp,
  Addresses: addressesProps,
  PhoneNumbers: phonesProps,
});

export const errorProps = shape({
  Code: numProp,
  Message: strProp,
  Status: strProp,
});

export const sessionProps = shape({
  Hash: reqStrProp,
  Platform: Platform,
});

export const transactionProps = shape({
  Account: accountProps,
  AuthorizationSession: reqObjProp, //build structure for these if/when needed
  Base: reqObjProp, //build structure for these if/when needed
  BaseQuantity: reqNumProp,
  BaseRefPrice: reqNumProp,
  Collected: reqNumProp,
  CreatedAt: reqStrProp,
  DeviceID: reqStrProp,
  Group: reqObjProp, //build structure for these if/when needed
  Event: reqNumProp,
  EventDesc: reqStrProp,
  ExchangePrice: numProp,
  TXID: strProp,
  InvoiceCode: reqStrProp,
  FacilitatorSession: reqObjProp, //build structure for these if/when needed
  Hash: reqStrProp,
  Images: arrProp,
  Side: reqStrProp,
  CancelReason: strProp,
  IP: reqStrProp,
  Quote: reqObjProp, //build structure for these if/when needed
  QuoteQuantity: reqNumProp,
  QuoteRefPrice: reqNumProp,
  SalePrice: reqNumProp,
  ServiceFee: numProp,
  State: reqNumProp,
  StateDesc: reqStrProp,
  UpdatedAt: reqStrProp,
  Updates: arrProp,
  WithdrawalAddress: strProp,
  PaymentMethod: strProp,
});

export const locationProps = shape({
  Active: reqBoolProp,
  BusinessName: reqStrProp,
  Address: reqObjProp,
  Code: reqStrProp,
  ComplianceContact: strProp,
  CreditAccount: reqObjProp,
  DebitAccount: objProp,
  Decommissioned: reqBoolProp,
  FridayCloseAt: string,
  FridayOpen: reqBoolProp,
  FridayOpenAt: string,
  Group: reqObjProp,
  HIDTA: boolProp,
  Hash: strProp,
  InstallationDate: strProp,
  MaintenanceContact: strProp,
  MondayCloseAt: string,
  MondayOpen: reqBoolProp,
  MondayOpenAt: string,
  PhoneNumber: reqStrProp,
  Platform: strProp,
  SaturdayCloseAt: string,
  SaturdayOpen: reqBoolProp,
  SaturdayOpenAt: string,
  SundayCloseAt: string,
  SundayOpen: reqBoolProp,
  SundayOpenAt: string,
  ThursdayCloseAt: string,
  ThursdayOpen: reqBoolProp,
  ThursdayOpenAt: string,
  TuesdayCloseAt: string,
  TuesdayOpen: reqBoolProp,
  TuesdayOpenAt: string,
  WednesdayCloseAt: string,
  WednesdayOpen: reqBoolProp,
  WednesdayOpenAt: string,
}).isRequired;

export const tableViewProps = shape({
  columns: reqArrProp,
  data: reqArrProp,
  tableSize: reqNumProp,
  tablePageCount: reqNumProp,
  setPage: reqFuncProp,
  setTablePageSize: reqFuncProp,
  sortByColumn: reqFuncProp,
  sortingPair: objProp,
  noDataText: strProp,
  tableLoading: reqBoolProp,
});
