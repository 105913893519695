import React from "react";
import { reqNumProp } from "../PropTypes";

const RatesOptions = ({ i, from, to, intervals = 0.005 }) => {
  const reactArray = [];
  for (i = from; i <= to; i++) {
    if (i % 0.25 === 0) {
      reactArray.push(
        React.createElement(
          "option",
          { key: parseFloat(i) * intervals, value: parseFloat(i) * intervals },
          (parseFloat(i) * intervals * 100).toFixed(2) + "%"
        )
      );
    }
  }
  return reactArray;
};

RatesOptions.propTypes = {
  from: reqNumProp,
  to: reqNumProp,
};

export default RatesOptions;
