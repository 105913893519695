import React from 'react';
import { GenericForm, FormikContext } from '../../Forms/Form';
import { FormInput, FormSelect } from '../../Forms/FormInputs';
import { InvestigationActivityTypes } from './../../Utilities/Data/InvestigationActivityTypes';
import PropTypes from 'prop-types';

const Narrative = ({ data, endPoint, history }) => {
  return (
    <div className="column">
      <GenericForm
        endpoint={endPoint}
        postReq={true}
        setValues={{
          Disposition: '',
          Narrative: '',
          ReviewPeriodStart: data?.ReviewPeriodStart?.substr(0, 10),
          ReviewPeriodEnd: data?.ReviewPeriodEnd?.substr(0, 10),
          SARAmount: undefined,
          ActivityType: undefined,
        }}
        customValidations={values => {
          let errors = {};
          for (let [key, value] of Object.entries(values)) {
            if (!value) {
              if (key === 'SARAmount' && values.Disposition === '0') {
                continue;
              } else if (key === 'ActivityType' && values.Disposition === '0') {
                continue;
              } else {
                errors[key] = 'is required';
              }
            }
          }
          return errors;
        }}
        formTitle="Create Narrative"
        subTitle={null}
        goBack={{
          show: false,
          setHistory: history,
        }}
        toastMsg="Disposition has been successfully added"
      >
        <FormikContext.Consumer>
          {props => (
            <React.Fragment>
              <h2 className="form-title">Update Investigation</h2>
              <div className="columns">
                <div className="column">
                  <FormInput
                    errors={props.errors}
                    touched={props.touched}
                    label="SAR Amount"
                    name="SARAmount"
                    type="number"
                    placeholder="Enter SAR Amount"
                  />
                </div>
                <div className="column">
                  <FormSelect
                    errors={props.errors}
                    touched={props.touched}
                    label="Disposition"
                    name="Disposition"
                    type="text"
                    placeholder="Select Disposition Type"
                    options={
                      <React.Fragment>
                        <option value="0">CNS</option>
                        <option value="1">SAR Filed</option>
                        <option value="2">CNS: STR</option>
                      </React.Fragment>
                    }
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <FormInput
                    errors={props.errors}
                    touched={props.touched}
                    label="Review Period Start"
                    name="ReviewPeriodStart"
                    type="date"
                    placeholder="Enter Review Period"
                  />
                </div>
                <div className="column">
                  <FormInput
                    errors={props.errors}
                    touched={props.touched}
                    label="Review Period End"
                    name="ReviewPeriodEnd"
                    type="date"
                    placeholder="Enter Review Period"
                  />
                </div>

                <div className="column">
                  <FormSelect
                    errors={props.errors}
                    touched={props.touched}
                    label="Activity Type"
                    name="ActivityType"
                    type="text"
                    placeholder="Select Activity Type"
                    disabled={props.values.Disposition === '0'}
                    options={
                      <React.Fragment>
                        {InvestigationActivityTypes.map(item => (
                          <option key={item.value} value={item.value}>
                            {item.display}
                          </option>
                        ))}
                      </React.Fragment>
                    }
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-6">
                  <FormInput
                    errors={props.errors}
                    touched={props.touched}
                    label="Narrative"
                    name="Narrative"
                    type="textarea"
                    component="textarea"
                    placeholder="Type Narrative"
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </FormikContext.Consumer>
      </GenericForm>
    </div>
  );
};

Narrative.propTypes = {
  data: PropTypes.shape({
    ReviewPeriodEnd: PropTypes.any,
    ReviewPeriodStart: PropTypes.any,
  }),
};

export default Narrative;
