import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useApi } from 'src/Services/Api';
import {
  LoadingButton,
  ToastError,
  ToastSuccess,
} from 'src/Utilities/Components';
import { formatGoogleMapsAddress } from 'src/Utilities/Formatters';
import PropTypes from 'prop-types';

const Address = ({ event }) => {
  const [payload, setPayload] = useState(null);
  const countryHashes = useRef([]);

  const [handleGetCountries] = useApi({
    method: 'get',
    url: 'countries',
    onError: message => ToastError(message),
    onSuccess: data => {
      countryHashes.current = data?.Data || [];
    },
  });

  const [handleSubmit, isSubmitLoading] = useApi({
    method: 'put',
    url: 'addresses',
    onError: message => ToastError(message),
    onSuccess: data => {
      ToastSuccess(`You successfully created a new address`);
      event(data.Hash, 'address');
    },
  });

  useEffect(() => {
    handleGetCountries();
  }, []);

  return (
    <>
      <h2 className="form-title">Address Details</h2>
      <div className="columns">
        <div className="column is-6">
          <Autocomplete
            apiKey={process.env.REACT_APP_GOOGLE_KEY}
            onPlaceSelected={place => {
              const data = formatGoogleMapsAddress(
                place,
                countryHashes.current
              );
              setPayload(data);
            }}
            options={{
              types: ['geocode', 'establishment'],
            }}
          />
        </div>
        <LoadingButton
          loading={isSubmitLoading}
          disabled={!payload}
          testID="address-submit"
          btnAction={async () => {
            await handleSubmit(payload);
          }}
        />
      </div>
    </>
  );
};

Address.propTypes = {
  event: PropTypes.func.isRequired,
};

export default Address;
