import { getFormattedDate } from '../../Utilities/Formatters';

export default function (data) {
  if (!data) return;

  const reqObj = {};

  for (let [key, value] of Object.entries(data)) {
    const isNum = num => num % 1 === 0;
    const isBool = typeof value === 'boolean';
    const isEmpty = value === '';

    if (isNaN(value) || isBool) {
      reqObj[key] = value;
    } else if (isEmpty) {
      // Set empty string as undefined
      // to remove from the request
      // when the body is stringified
      // by JSON. Empty strings cause
      // errors in the backend sometimes.
      reqObj[key] = undefined;
    } else if (convertToStr(key)) {
      if (key === 'Percent') {
        reqObj[key] = value && `${value.toString()}%`;
      } else {
        reqObj[key] = value && value.toString();
      }
    } else if (convertDate(key)) {
      reqObj[key] = value && getFormattedDate(value);
    } else {
      if (isNum(value)) {
        reqObj[key] = parseInt(value);
      } else {
        reqObj[key] = parseFloat(value);
      }
    }
  }
  return reqObj;
}

export const convertToStr = key => {
  const keys = [
    'PhoneNumber',
    'AccountNumber',
    'RoutingNumber',
    'Zip',
    'ZIP',
    'NumbersToNotify',
    'Payout',
    'DocumentSerial',
    'Password',
    'OldPassword',
    'NewPassword',
    'Fiat',
    'Percent',
    'DeviceIdentifier',
    'SSN',
    'Username',
  ];

  let convert = false;
  for (let i = 0; keys.length > i; i++) {
    if (key === keys[i]) {
      convert = true;
    }
  }

  return convert;
};

export const convertDate = key => {
  if (key === 'ReviewPeriodStart' || key === 'ReviewPeriodEnd') {
    return true;
  } else {
    return false;
  }
};
