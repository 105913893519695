import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import {
  EditableInput,
  EditableSelect,
  EditableLink,
} from 'src/Forms/EditableInputs';
import { LoadingButton } from 'src/Utilities/Components';
import { EditableStates } from 'src/Utilities/Data/StatesList';
import { EditableProvinces } from 'src/Utilities/Data/ProvinceList';

const COUNTRY = {
  CA: 'CA',
  US: 'US',
};

const EDITABLE_OPTIONS_TEXT = {
  CA: 'Select a Province',
  US: 'Select a State',
};

const AddressesForm = ({ address, editing, onSubmit, countries }) => {
  const [country, setCountry] = useState(COUNTRY.US);
  const [countryEditableOptions, setCountryEditableOptions] =
    useState(EditableStates);
  const [selectEditableOptionText, setSelectEditableOptionsText] = useState(
    EDITABLE_OPTIONS_TEXT
  );

  useEffect(() => {
    if (country === COUNTRY.CA) {
      setCountryEditableOptions(EditableProvinces);
    } else {
      setCountryEditableOptions(EditableStates);
    }
  }, [country]);

  useEffect(() => {
    if (country === COUNTRY.CA) {
      setSelectEditableOptionsText(EDITABLE_OPTIONS_TEXT.CA);
    } else {
      setSelectEditableOptionsText(EDITABLE_OPTIONS_TEXT.US);
    }
  }, [country]);

  const {
    City,
    Hash,
    Latitude,
    Longitude,
    Preferred,
    StateCode,
    Street,
    Street2,
    ZIP,
  } = address;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          City,
          CountryHash: address.Country?.Hash,
          Latitude,
          Longitude,
          StateCode,
          Street,
          Street2,
          ZIP,
        }}
        onSubmit={(values, actions) => {
          onSubmit(values, actions, Hash);
        }}
        children={({ isSubmitting, setFieldValue }) => (
          <Form
            className={classnames(
              'form-outline inline-edit-form',
              { editing },
              { 'form-padding': !Preferred }
            )}
          >
            <div className="columns">
              <div className="column">
                <EditableLink
                  name="Street"
                  label="Street"
                  type="text"
                  link={`https://www.google.com/maps/search/?api=1&query=${Street}+${City}+${StateCode}`}
                  linkName={Street}
                  editing={editing}
                />
              </div>
              <div className="column">
                <EditableInput
                  name="Street2"
                  label="Apt, Ste, Unit"
                  type="text"
                  currentValue={Street2}
                  editing={editing}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="form-row">
                  {editing ? (
                    <>
                      <label className="item-label">Country </label>
                      <Field
                        as="select"
                        name="CountryHash"
                        className="form-row"
                        onChange={e => {
                          const countryMatch = countries.find(
                            country => country.Hash === e.target.value
                          );
                          setFieldValue('CountryHash', countryMatch.Hash);
                          setCountry(countryMatch.Code);
                        }}
                      >
                        <option value="" disabled>
                          Select a Country
                        </option>
                        {countries.map(c => (
                          <option key={c.Hash} value={c.Hash}>
                            {c.Name}
                          </option>
                        ))}
                      </Field>
                    </>
                  ) : (
                    <>
                      <label className="item-label">Country: </label>
                      <p>{address.Country?.Name || '-'}</p>
                    </>
                  )}
                </div>
              </div>
              <div className="column">
                <EditableSelect
                  name="StateCode"
                  label="State/Province"
                  placeholder={selectEditableOptionText}
                  currentValue={StateCode}
                  data={countryEditableOptions}
                  editing={editing}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column ">
                <EditableInput
                  name="City"
                  label="City"
                  type="text"
                  currentValue={City}
                  editing={editing}
                />
              </div>
              <div className="column is-half">
                <EditableInput
                  name="ZIP"
                  label="Postal Code"
                  type="text"
                  currentValue={ZIP}
                  editing={editing}
                />
              </div>
            </div>
            {editing && (
              <LoadingButton loading={isSubmitting} btnText="Update" />
            )}
          </Form>
        )}
      />
    </>
  );
};

export default AddressesForm;
