import React from 'react';
import { Header } from 'src/Utilities/Components';
import { ICONS } from 'src/Icons/IconsPath';
import { cleanUrl } from '@digitalmint/api-service';

const STORAGE_URL = cleanUrl(
  `${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_VERSION}`
);

const documents = [
  {
    name: 'Definitions.pdf',
    link: `${STORAGE_URL}/compliance/Definitions.pdf`,
  },
  {
    name: 'Scenarios.pdf',
    link: `${STORAGE_URL}/compliance/Scenarios.pdf`,
  },
];

const Documents = () => {
  return (
    <React.Fragment>
      <Header title="Documents" icon={ICONS.FILE} />
      <div className="form-outline form-create">
        <ul>
          {documents.map(document => (
            <li key={document.name}>
              <a target="_blank" rel="noopener noreferrer" href={document.link}>
                {document.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Documents;
