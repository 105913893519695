import React, { Component } from "react";
// ACH Components
import Overview from "./Overview";
import Asset from "./../../../Asset/Asset";
import TabsContainer from "./TabsContainer";
// Components
import {
  GoBack,
  Header,
  ToastError,
  ToastSuccess,
} from "../../../Utilities/Components";
// Configs
import { API } from "../../../Config/cfg.js";

export default class SingleACH extends Component {
  state = {
    data: {},
    editingACH: false,
  };

  componentDidMount() {
    this.getDebitAccount();
  }

  getDebitAccount = () => {
    const {
      params: { hash },
    } = this.props.match;

    API.get(`/accounting/ach/${hash}`)
      .then((res) => {
        this.setState({
          data: res.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          errors: error.response.data,
        });
      });
  };

  postACHAccount = (hash, actions, req) => {
    API.post(`/accounting/ach/${hash}`, req)
      .then((res) => {
        this.setState({ editingACH: false });
        ToastSuccess("Changes to ACH Account have been saved");
        this.getDebitAccount();
        actions && actions.setSubmitting(false);
      })
      .catch((error) => {
        ToastError(error);
        this.setState({ editingACH: true });
        actions && actions.setSubmitting(false);
      });
  };

  toggleEdit = (type) => {
    this.setState({ [type]: !this.state[type] });
  };

  render() {
    const { history, match } = this.props;
    const { data, editingACH } = this.state;

    return (
      <>
        <GoBack
          history={history}
          defaultPath="/accounting/ach"
          defaultName="ACH Accounts"
        />
        <Header title={data.Name} />
        <div className="columns">
          <div className="column">
            <Asset />
          </div>
          <div className="column is-7">
            <Overview
              editing={editingACH}
              data={data}
              toggleEdit={this.toggleEdit}
              postACHAccount={this.postACHAccount}
            />
          </div>
        </div>
        <TabsContainer history={history} hash={match.params.hash} />
      </>
    );
  }
}
