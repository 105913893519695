/* COMPONENT OVERVIEW
- Takes props from parent 
- Renders Account address data
- Calls postAccount function prop & sends request to parent
*/
// Modules
import React, { useState, useEffect } from 'react';
import {
  addressesProps,
  reqBoolProp,
  reqFuncProp,
} from '../../Utilities/PropTypes';
// Components
import { EditBtn, ToastError } from '../../Utilities/Components';
import AddressForm from './AddressForm';
// Icons
import Icon from '../../Icons/Icon';
import { ICONS } from '../../Icons/IconsPath';
// Permissions
import { ACCOUNT_EDIT } from '../../Permissions/Permissions';
import { API } from '../../Config/cfg';

const Address = ({
  editing,
  data,
  postAccount,
  postPreferredAddress,
  accountHash,
  toggleEdit,
}) => {
  const [hidden, setHidden] = useState(true);
  const preferred = data.filter(d => d.Preferred)[0];
  const nonPreferred = data.filter(d => !d.Preferred);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    API.get('/countries')
      .then(res => {
        setCountries(res.data.Data);
      })
      .catch(error => {
        ToastError(error.response.data.Message);
      });
  }, []);

  const triggerEdit = e => {
    e.preventDefault();
    toggleEdit('editingAddress');
  };

  const toggle = () => {
    setHidden(!hidden);
  };

  const callPostAccount = (data, actions, hash) => {
    let req = {
      CountryHash: data.CountryHash,
      City: data.City,
      Latitude: data.Latitude,
      Longitude: data.Longitude,
      StateCode: data.StateCode,
      Street: data.Street,
      Street2: data.Street2,
      ZIP: data.ZIP,
    };
    // Sends req to POST call in parent
    postAccount('addresses', hash, null, req, 'editingAddress', actions);

    setHidden(true);
  };

  return (
    <>
      <EditBtn
        editing={editing}
        triggerEdit={triggerEdit}
        sectionTitle="Addresses"
        permission={ACCOUNT_EDIT}
        link={{
          pathname: '/accounts/create-address',
          hash: accountHash,
          hasAddress: Object.keys(data).length === 0,
          state: {
            type: 'accounts',
          },
        }}
      />
      {preferred && (
        <AddressForm
          postAccount={callPostAccount}
          address={preferred}
          editing={editing}
          countries={countries}
        />
      )}
      {Object.keys(data).length === 0 && (
        <AddressForm
          nullAddress={true}
          postAccount={callPostAccount}
          address={{}}
          editing={editing}
          countries={countries}
        />
      )}
      {nonPreferred && !hidden && (
        <>
          <h3>Non-preferred</h3>
          {nonPreferred.map(address => (
            <AddressForm
              key={address.Hash}
              postAccount={callPostAccount}
              address={address}
              editing={editing}
              postPreferredAddress={postPreferredAddress}
              countries={countries}
            />
          ))}
        </>
      )}
      {nonPreferred.length >= 1 && (
        <button onClick={() => toggle()} className="toggle-button btn-icon">
          <Icon
            icon={hidden ? ICONS.DOWNARROW : ICONS.UPARROW}
            color="#ffffff"
            className="icon"
            size="20"
          />
        </button>
      )}
    </>
  );
};

Address.propTypes = {
  postAccount: reqFuncProp,
  toggleEdit: reqFuncProp,
  editing: reqBoolProp,
  data: addressesProps,
};

export default Address;
