import React from 'react';
import { Form, Formik } from 'formik';
// Components
import { FormInput, FormSelect } from '../../../Forms/FormInputs';
import { GenericModal } from '../../../Utilities/Components';
import { InvestigationActivityTypes } from '../../../Utilities/Data/InvestigationActivityTypes';

const OpenInvestigationModal = ({
  isOpen,
  putAccount,
  account,
  hash,
  closeModal,
}) => {
  return (
    <Formik
      initialValues={{
        Note: '',
        ReferralType: '',
      }}
      validate={values => {
        let errors = {};
        if (!values.Note) {
          errors.Note = 'is required';
        }
        if (!values.ReferralType) {
          errors.ReferralType = 'is required';
        }
        return errors;
      }}
      onSubmit={async values =>
        await putAccount({
          Note: values.Note,
          AccountHash: hash,
          ReferalType: parseInt(values.ReferralType),
        })
      }
      children={({
        touched,
        errors,
        handleSubmit,
        setErrors,
        isSubmitting,
      }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={() => {
            setErrors({});
            closeModal('isInvestigationOpen');
          }}
          viewMsg={account}
          headerMsg="Open Compliance Investigation"
          btnText="Submit"
          btnAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <Form className="form-outline">
            <div className="columns is-centered">
              <div className="column is-11">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Note"
                  name="Note"
                  component="textarea"
                  placeholder="Enter Note..."
                />
              </div>
            </div>
            <div className="columns is-centered">
              <div className="column is-11">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="Referral Reason"
                  name="ReferralType"
                  type="text"
                  placeholder="Select Reason"
                  options={
                    <>
                      {InvestigationActivityTypes.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.display}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </Form>
        </GenericModal>
      )}
    />
  );
};

export default OpenInvestigationModal;
