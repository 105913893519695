import React from 'react';
// Components
import { GenericModal } from '../../../Utilities/Components';
import { Form, Formik } from 'formik';
import { FormInput } from './../../../Forms/FormInputs';

export const TwoFactorModal = ({
  isOpen,
  postAccount,
  header,
  closeModal,
  isEnabled,
  qrCode,
  message,
}) => {
  return (
    <Formik
      initialValues={{
        OTP: '',
      }}
      validate={values => {
        let errors = {};
        if (!values.OTP) {
          errors.OTP = 'is required';
        }
        if (values.OTP.length < 6) {
          errors.OTP = 'must be 6 numbers';
        }
        if (values.OTP.length >= 7) {
          errors.OTP = "can't exceed 6 numbers";
        }
        if (!/^\d+$/.test(values.OTP)) {
          errors.OTP = 'characters must be all numbers';
        }

        return errors;
      }}
      onSubmit={async values => {
        let req = { OTP: values.OTP };
        isEnabled ? (req.Confirm = true) : (req.Remove = true);
        await postAccount(req);
        values.OTP = '';
      }}
      children={({ touched, errors, handleSubmit, isSubmitting }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={closeModal}
          headerMsg={header}
          btnText="Submit"
          btnAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <React.Fragment>
            {isEnabled && (
              <div className="columns is-centered">
                <div className="column is-11">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={qrCode} alt="" height="2250" width="225" />
                  </div>
                </div>
              </div>
            )}
            <div className="columns is-centered">
              <div className="column is-11">{message}</div>
            </div>
            <Form className="columns form-outline is-centered">
              <div className="column is-11">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Two-Factor Authentication"
                  name="OTP"
                  placeholder="Enter One-Time Passcode..."
                  autoFocus={true}
                />
              </div>
            </Form>
          </React.Fragment>
        </GenericModal>
      )}
    />
  );
};
