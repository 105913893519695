// Modules
import React, { useEffect, useState } from "react";
// Components
import { GenericForm, FormikContext } from "../../../Forms/Form";
import { FormInput, FormSelect } from "../../../Forms/FormInputs";
import { reqObjProp } from "../../../Utilities/PropTypes";
import Decisions from "../../../Utilities/Data/Decisions";

const EDDNarrative = ({ history, data }) => {
  const { Decision, Hash, Narrative, ReviewCycle } = data;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(Decisions);
  }, [ReviewCycle]);

  return (
    <GenericForm
      endpoint={
        Decision
          ? `compliance/edds/${Hash}`
          : `compliance/edds/${Hash}/decision`
      }
      postReq={true}
      setValues={{
        Decision: Decision ? Decision : "",
        Narrative: Narrative ? Narrative.toString() : "",
      }}
      formTitle="Create Narrative"
      subTitle={null}
      goBack={{
        show: false,
        setHistory: history,
      }}
      toastMsg="Narrative has been successfully created"
    >
      <FormikContext.Consumer>
        {(props) => (
          <div className="columns">
            <div className="column is-6">
              <FormInput
                label="Narrative"
                name="Narrative"
                component="textarea"
                type="text"
                placeholder="Enter Narrative..."
                errors={props.errors}
                touched={props.touched}
              />
              <FormSelect
                label="Decision"
                name="Decision"
                errors={props.errors}
                touched={props.touched}
                placeholder="Select Decision Type"
                disabled={Decision && true}
                options={
                  <React.Fragment>
                    {options.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.name}
                      </option>
                    ))}
                  </React.Fragment>
                }
              />
            </div>
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

EDDNarrative.propTypes = {
  data: reqObjProp,
  history: reqObjProp,
};

export default EDDNarrative;
