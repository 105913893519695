/* eslint-disable no-console */

// Modules
import React, { Component } from 'react';
import {
  reqBoolProp,
  reqFuncProp,
  reqObjProp,
} from '../../Utilities/PropTypes';
// Components
import { EditBtn, Loading, LoadingButton } from '../../Utilities/Components';
import { EditableListBox } from '../../Forms/EditableInputs';
// Helpers - Formatters
import { formatOptions } from '../../Utilities/Formatters';
// Permissions
import { GROUPS_EDIT } from '../../Permissions/Permissions';
// Config
import { API } from '../../Config/cfg.js';

class GroupPermissions extends Component {
  state = {
    loading: true,
    permissionsList: [],
    permissionHashes: this.props?.data?.Permissions
      ? this.props.data.Permissions.map(({ Hash }) => Hash)
      : [],
  };

  componentDidMount() {
    this.getPermissions();
  }

  triggerEdit = e => {
    e.preventDefault();
    this.props.toggleEdit('editingPermissions');
  };

  callPostGroup = () => {
    this.setState({ isSubmiting: true });
    const { permissionHashes } = this.state;
    let req = {
      permissionHashes,
    };

    // // Sends req to POST call in parent
    this.props.postGroup(
      'groups',
      this.props.data.Hash,
      null,
      req,
      'editingPermissions'
    );
  };

  getPermissions = () => {
    const url = `/permissions?categories=true`;
    API.get(url)
      .then(res => {
        this.setState({ permissionsList: res.data, loading: false });
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleChange = permissionHashes => {
    this.setState({ permissionHashes });
  };

  buildPermissionsBox = () => {
    let boxOptions = [];
    const {
      accounting,
      accounts,
      compliance,
      groups,
      funding,
      keys,
      locations,
      platforms,
      settings,
      tx,
      vouchers,
    } = this.state.permissionsList;
    boxOptions = [
      {
        label: 'Accounting',
        options: formatOptions(accounting),
      },
      {
        label: 'Accounts',
        options: formatOptions(accounts),
      },
      {
        label: 'Compliance',
        options: formatOptions(compliance),
      },
      {
        label: 'Funding',
        options: formatOptions(funding),
      },
      {
        label: 'Groups',
        options: formatOptions(groups),
      },
      {
        label: 'Keys',
        options: formatOptions(keys),
      },
      {
        label: 'Locations',
        options: formatOptions(locations),
      },
      {
        label: 'Platforms',
        options: formatOptions(platforms),
      },
      {
        label: 'Settings',
        options: formatOptions(settings),
      },
      {
        label: 'Transactions',
        options: formatOptions(tx),
      },
      {
        label: 'Vouchers',
        options: formatOptions(vouchers),
      },
    ];
    return boxOptions;
  };

  render() {
    let listBoxOptions = [];
    const { loading, permissionHashes } = this.state;
    const { editing, data, isSubmitting } = this.props;
    const { Permissions } = data;

    if (loading) {
      return <Loading />;
    }

    if (!loading) {
      listBoxOptions = this.buildPermissionsBox();
    }

    return (
      <React.Fragment>
        <div className="edit-actions-override">
          <EditBtn
            editing={editing}
            permission={GROUPS_EDIT}
            triggerEdit={this.triggerEdit}
            sectionTitle="Permissions"
          />
        </div>
        <div
          className={`form-outline inline-edit-form ${
            editing ? 'editing' : ''
          }`}
        >
          <EditableListBox
            currentValue={Permissions}
            editing={editing}
            emptyMsg="No permissions set"
            id="permissions"
            onChange={this.handleChange}
            options={listBoxOptions}
            selected={permissionHashes}
            type="permissions"
          />

          {editing && (
            <LoadingButton
              loading={isSubmitting}
              btnAction={this.callPostGroup}
              btnText="Update"
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

GroupPermissions.propTypes = {
  postGroup: reqFuncProp,
  toggleEdit: reqFuncProp,
  editing: reqBoolProp,
  data: reqObjProp,
};

export default GroupPermissions;
