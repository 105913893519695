import React from 'react';
import { Formik, Form, Field } from 'formik';
import { LoadingButton } from '../Utilities/Components';
import { API } from '../Config/cfg';
import PropTypes from 'prop-types';

const ColumnPicker = ({
  endpoint,
  columns,
  allColumns,
  customColumns,
  setCustomColumns,
  setActive,
}) => {
  // keep columns in default order
  const sortColumns = selectedColumns => {
    const orderedColumns = [];
    columns.forEach(col => {
      if (selectedColumns.includes(col.Header)) {
        orderedColumns.push(col.Header);
      }
    });
    return orderedColumns;
  };

  const groupArrays = () => {
    let groups = [];
    let row = [];

    allColumns.forEach(acol => {
      columns.forEach(bcol => {
        if (acol === bcol.Header) {
          row.push(
            <div key={bcol.accessor} className="column ml-3">
              <Field
                key={bcol.Header}
                name="checked"
                type="checkbox"
                value={bcol.Header}
              />
              <label className="column-option">{bcol.Header}</label>
            </div>
          );
        }
      });
      if (row.length % 3 === 0) {
        groups.push(row);
        row = [];
      } else {
        groups.push(row);
      }
    });
    const filteredGroups = groups.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    return filteredGroups;
  };

  const postColumns = values => {
    const req = sortColumns(values.checked);
    API.post(`/columns/${endpoint}`, req)
      .then(() => {
        setCustomColumns(req);
        setActive(false);
      })
      .catch(err => console.error(err));
  };

  const returnDefaultColumns = () => {
    return columns.map(col => {
      if (col.default) {
        return col.Header;
      }
      return null;
    });
  };

  return (
    <Formik
      initialValues={{ checked: [...customColumns] }}
      onSubmit={values => postColumns(values)}
    >
      {({ handleSubmit, setValues, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div className="dropdown-header">
            <h3>Columns</h3>
          </div>
          <div className="column-scroll">
            {groupArrays().map((row, i) => (
              <div key={i} className="columns is-vcentered is-centered">
                {row}
              </div>
            ))}
          </div>
          <div className="column">
            <div className="columns is-centered ">
              <LoadingButton
                btnText="Apply"
                className="column is-5 btn primary-btn"
                type="submit"
                loading={isSubmitting}
              />
              <button
                className="column is-5 btn secondary-btn-outline"
                onClick={() =>
                  setValues({
                    checked: returnDefaultColumns(),
                  })
                }
                type="button"
              >
                Reset
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ColumnPicker.defaultProps = {
  columns: [],
  allColumns: [],
  customColumns: [],
  setCustomColumns: () => {},
  setActive: () => {},
};

ColumnPicker.propTypes = {
  endpoint: PropTypes.string,
  columns: PropTypes.array,
  allColumns: PropTypes.array,
  customColumns: PropTypes.array,
  setCustomColumns: PropTypes.func,
  setActive: PropTypes.func,
};

export default ColumnPicker;
