// Modules
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Components
import Table from "../../Table/Table";
import AssignModal from "../../Compliance/Modals/AssignModal";
import {
  AlertPill,
  AlertDispositionPill,
  ScenarioPill,
} from "./../../Utilities/Components/TablePills";
// Helpers - Formatters
import { formatDate } from "../../Utilities/Formatters";
// Permissions
import { COMPLIANCE_ALERTS_ACTION } from "../../Permissions/Permissions";
import Can from "../../Permissions/Can";

const Alerts = (props) => {
  const [isAssignOpen, setAssignOpen] = useState(false);
  const [alertHash, setAlertHash] = useState("");
  const [triggerAPI, setTrigger] = useState(false);
  const [assignee, setAssignee] = useState(null);

  useEffect(() => {
    if (triggerAPI) {
      setTrigger(false);
    }
  }, [triggerAPI]);

  const openModal = (hash, employee) => {
    setAssignee(employee);
    setAlertHash(hash);
    setAssignOpen(true);
  };

  const closeModal = () => {
    setAssignOpen(false);
    setTrigger(true);
  };

  return (
    <>
      <AssignModal
        title="Alert"
        endpoint="compliance/alerts"
        hash={alertHash}
        isOpen={isAssignOpen}
        closeModal={closeModal}
        assignee={assignee}
      />
      <Table
        history={props.history}
        columns={columnBuilder(openModal)}
        endpoint="compliance/alerts"
        filter={true}
        tablename="Alerts"
        triggerAPI={triggerAPI}
      />
    </>
  );
};

export default Alerts;

const columnBuilder = (openModal) => [
  {
    Header: "Number",
    accessor: "Hash",
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/compliance/alerts/${original.Hash}`}>
        {original.Code}
      </Link>
    ),
    sortMethod: "AlertNumber",
    maxWidth: 100,
  },
  {
    Header: "Scenario",
    accessor: "Scenario",
    Cell: ({ row: { original } }) =>
      original.Scenario ? ScenarioPill(original.Scenario) : null,
    sortMethod: "Scenario",
  },
  {
    Header: "Status",
    accessor: "StatusText",
    Cell: ({ row: { original } }) =>
      AlertPill(original.Status, original.StatusText),
    sortMethod: "Status",
    maxWidth: 100,
  },
  {
    Header: "Customer Name",
    accessor: "Account.FullName",
    style: { textAlign: "left " },
    minWidth: 200,
    Cell: ({ row: { original } }) =>
      original.Account ? (
        <Link className="table-pill" to={`/accounts/${original.Account.Hash}`}>
          {original.Account.FullName}
        </Link>
      ) : null,
    sortMethod: "LastName",
  },
  {
    Header: "Discovered",
    Cell: ({ row: { original } }) => formatDate(original.DiscoveryDate),
    sortMethod: "Discovered",
    maxWidth: 75,
  },
  {
    Header: "Due Date",
    Cell: ({ row: { original } }) => formatDate(original.DueDate),
    sortMethod: "DueDate",
    maxWidth: 75,
  },
  {
    Header: "Disposition",
    accessor: "DispositionText",
    minWidth: 200,
    Cell: ({ row: { original } }) =>
      original.Disposition >= 0
        ? AlertDispositionPill(original.Disposition, original.DispositionText)
        : "-",
    sortMethod: "Disposition",
  },
  {
    Header: "Assigned To",
    accessor: "AssignedTo.FullName",
    Cell: ({ row: { original } }) =>
      original.AssignedTo ? original.AssignedTo.FullName : "-",
  },
  {
    Header: "Action",
    accessor: "Action",
    Cell: ({ row: { original } }) => (
      <Can do={COMPLIANCE_ALERTS_ACTION}>
        <button
          className="column-btn create"
          onClick={() => openModal(original.Hash, original.AssignedTo)}
        >
          {!original.AssignedTo ? "Assign" : "Edit"}
        </button>
      </Can>
    ),
  },
  {
    Header: "Country of Origin",
    accessor: "Country.Code",
  },
];
