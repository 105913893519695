import React from "react";
import { Field } from "formik";
import { reqObjProp, funcProp } from "prop-types";

const Product = ({ Hash, Symbol }) => {
  return (
    <div className="column is-2 has-text-centered">
      <p className="item-label">{Symbol}</p>
      <Field
        name="productHashes"
        type="checkbox"
        component="input"
        value={Hash}
      />
    </div>
  );
};

Product.propTypes = {
  data: reqObjProp,
  clickHandler: funcProp,
};

export default Product;
