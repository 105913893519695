import React from 'react';
import { ToastSuccess } from './index';
import PropTypes from 'prop-types';

const CopyButton = ({ value, className }) => {
  const copy = async () => {
    await navigator.clipboard.writeText(value);

    ToastSuccess('Text copied!');
  };

  if (!value) return false;

  return (
    <button
      className={className ? className : 'flatten'}
      onClick={copy}
      data-testid="CopyButton"
    >
      <i className="far fa-clone" />
      <span className="is-sr-only">Copy to Clipboard</span>
    </button>
  );
};

CopyButton.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
};

export default CopyButton;
