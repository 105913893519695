import React from 'react';
import {
  useTable,
  usePagination,
  useFlexLayout,
  useResizeColumns,
} from 'react-table';
import Pagination from './Pagination';
import LoadingComponent from './LoadingComponent';
import { tableViewProps } from '../Utilities/PropTypes';
import CellBoundary from './CellBoundary';

export default function TableView({ options }) {
  const {
    columns,
    data,
    tableSize,
    tablePageCount,
    setPage,
    setTableLoading,
    setTablePageSize,
    sortByColumn,
    sortingPair: { sortOrder, sortField },
    tableLoading,
    currentPage,
    noDataText,
    autoRefresh,
    setAutoRefresh,
  } = options;

  // Imported columns array will override these settings
  const defaultColumn = {
    minWidth: 30,
    width: 150,
    maxWidth: 400,
  };

  const table = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: currentPage,
        pageSize: tableSize,
      },
      manualPagination: true,
      pageCount: tablePageCount,
    },
    useFlexLayout,
    useResizeColumns,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = table;

  const pageProps = {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setTableLoading,
    setTablePageSize,
    setPage,
    page,
    state: { pageIndex, pageSize },
    tableLoading,
    currentPage,
    autoRefresh,
    setAutoRefresh,
  };

  return (
    <>
      <div className="ReactTable -striped -highlight">
        <LoadingComponent loading={tableLoading} />
        <div className="pagination-top">
          <Pagination {...pageProps} />
        </div>
        <div className={tableLoading ? 'fade-out' : 'fade-in'}>
          <div {...getTableProps()} className="rt-table">
            <div className="rt-thead -header">
              {headerGroups.map(headerGroup => (
                <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                  {headerGroup.headers.map(column => (
                    <div
                      {...column.getHeaderProps()}
                      className={`rt-th ${
                        column.sortMethod === sortField ? sortOrder : ''
                      }`}
                    >
                      <button
                        className="clean-btn"
                        onClick={() => {
                          if (column.sortMethod) {
                            sortByColumn(column);
                            setPage(0); // updates query string with correct page
                            gotoPage(0); // updates useTable page indexing
                          }
                        }}
                      >
                        {column.render('Header')}
                        <div
                          {...column.getResizerProps()}
                          className="resizer"
                        />
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="rt-tbody">
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="rt-tr-group">
                    <div className={`rt-tr ${i % 2 === 0 ? '-odd' : ''}`}>
                      {row.cells.map(cell => {
                        return (
                          <div
                            {...cell.getCellProps({
                              style: cell.column.style,
                            })}
                            className="rt-td"
                          >
                            <CellBoundary>{cell.render('Cell')}</CellBoundary>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {data.length === 0 ? (
                <div className="noData">
                  <div className="rt-noData">{noDataText}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="pagination-bottom">
          <Pagination {...pageProps} />
        </div>
      </div>
    </>
  );
}

TableView.propTypes = {
  options: tableViewProps,
};
