import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { capitalize, inRange } from 'lodash';
import { FormInput, FormSelect } from '../../Forms/FormInputs';
import {
  LoadingButton,
  ToastError,
  ToastSuccess,
} from '../../Utilities/Components';
import REFUND_METHODS from '../../Utilities/Data/RefundMethods';
import { API } from '../../Config/cfg';

const CreateRefund = ({ history }) => {
  const [refundStates, setRefundStates] = useState([]);
  const [sendMethods, setSendMethods] = useState([]);

  useEffect(() => {
    getRefundStates();
  }, []);

  const getRefundStates = async () => {
    try {
      const { data } = await API.get('/customer-refunds/status');
      setRefundStates(data.Statuses);
      setSendMethods(data.SendMethods);
    } catch (error) {
      ToastError(error);
    }
  };

  const parseTextarea = text => {
    // separate by comma
    let entries = text.replace(/\s/g, '');
    entries = entries.split(',');
    entries = entries.filter(e => e !== '');
    return entries;
  };

  return (
    <Formik
      initialValues={{
        AddressHash: '',
        TransactionHashes: '',
        ContactDate: '',
        IssueDate: '',
        State: '',
        PaymentMethod: '',
        CheckIdentifier: '',
        SendMethod: '',
        RefundRate: 0,
      }}
      validate={values => {
        const errors = {};
        if (!values.TransactionHashes) {
          errors.TransactionHashes = 'is required';
        }
        if (!inRange(values.RefundRate, 0.0, 100.01)) {
          errors.RefundRate = 'is invalid percentage';
        }
        return errors;
      }}
      onSubmit={async (values, actions) => {
        for (const key in values) {
          if (!values[key]) {
            delete values[key];
          } else if (key.includes('Date')) {
            const date = new Date(values[key]);
            values[key] = date.toISOString();
          }
        }

        if (values.PaymentMethod)
          values.PaymentMethod = parseInt(values.PaymentMethod);

        values.TransactionHashes = parseTextarea(values.TransactionHashes);

        try {
          await API.put('/customer-refunds', values);
          ToastSuccess('Customer Refund Created');
          history.goBack();
        } catch (err) {
          ToastError(err);
        } finally {
          actions.setSubmitting(false);
        }
      }}
      enableReinitialize={true}
    >
      {({ errors, touched, isSubmitting, values, setFieldValue }) => (
        <Form className="form-outline form-create">
          <h2 className="form-title">Create Refund</h2>
          <section className="columns">
            <div className="column is-6">
              <FormInput
                name="TransactionHashes"
                label="Transaction Hash (Separated by commas)"
                errors={errors}
                touched={touched}
                type="string"
                component="textarea"
                testID="TransactionHashes"
              />
            </div>
          </section>
          <section className="columns">
            <div className="column is-3">
              <FormInput
                name="ContactDate"
                label="Contact Date"
                errors={errors}
                touched={touched}
                type="date"
                testID="ContactDate"
              />
            </div>
            <div className="column is-3">
              <FormInput
                name="IssueDate"
                label="Issue Date"
                errors={errors}
                touched={touched}
                type="date"
                testID="IssueDate"
              />
            </div>
          </section>

          <section className="columns">
            <div className="column is-3">
              <FormSelect
                label="Status"
                name="State"
                placeholder="Select Status of Refund"
                options={refundStates.map(item => (
                  <option key={item} value={item}>
                    {capitalize(item)}
                  </option>
                ))}
                errors={errors}
                touched={touched}
                testID="State"
              />
            </div>
            <div className="column is-3">
              <FormSelect
                label="Send Method"
                name="SendMethod"
                placeholder="Select Send Method"
                options={sendMethods.map(item => (
                  <option key={item} value={item}>
                    {capitalize(item)}
                  </option>
                ))}
                errors={errors}
                touched={touched}
                testID="SendMethod"
              />
            </div>
          </section>

          <section className="columns">
            <div className="column is-3">
              <FormInput
                name="RefundRate"
                label="Refund Rate (%)"
                type="number"
                min="0"
                max="100"
                testID="RefundRate"
              />
            </div>
            <div className="column is-3">
              <FormSelect
                label="Payment Method"
                name="PaymentMethod"
                placeholder="Select Refund Payment Method"
                options={REFUND_METHODS.map(item => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
                type="number"
                errors={errors}
                touched={touched}
                onChange={e =>
                  setFieldValue('PaymentMethod', parseInt(e.target.value))
                }
                testID="PaymentMethod"
              />
            </div>
            {values.PaymentMethod === '4' && (
              <div className="column is-3">
                <FormInput
                  name="CheckIdentifier"
                  label="Check Identifier"
                  errors={errors}
                  touched={touched}
                  type="text"
                  testID="CheckIdentifier"
                />
              </div>
            )}
          </section>
          <LoadingButton loading={isSubmitting} testID="Submit" />
        </Form>
      )}
    </Formik>
  );
};

export default CreateRefund;
