// Modules
import React from "react";
// Components
import { GenericForm, FormikContext } from "../Forms/Form";
import { FormInput, FormSelect } from "../Forms/FormInputs";
import { reqStrProp, objProp } from "./../Utilities/PropTypes";

const IDFormDeny = ({ endPoint, history }) => {
  const reasons = [
    {
      value: 0,
      text: "Blurry or Not Clear",
    },
    {
      value: 1,
      text: "ID Expired",
    },
    {
      value: 2,
      text: "Customer Underage",
    },
    {
      value: 3,
      text: "Unacceptable ID Type",
    },
    {
      value: 4,
      text: "Foreign ID",
    },
    {
      value: 5,
      text: "Customer Provided Back of ID",
    },
    {
      value: 6,
      text: "Customer Didn't Provide Any ID",
    },
    {
      value: 7,
      text: "Address Verification",
    },
  ];

  return (
    <GenericForm
      endpoint={endPoint}
      postReq={true}
      setValues={{
        Note: "",
        DenyReason: "",
      }}
      customValidations={(values) => {
        let errors = {};
        if (!values.DenyReason) {
          errors.DenyReason = "is required";
        }
        return errors;
      }}
      formTitle={null}
      subTitle="Deny ID"
      goBack={{
        show: false,
        setHistory: history,
      }}
      toastMsg="ID has been denied"
    >
      <FormikContext.Consumer>
        {(props) => (
          <React.Fragment>
            <section className="columns">
              <div className="column is-5">
                <FormSelect
                  label="Reason"
                  name="DenyReason"
                  placeholder="Denial Reason"
                  errors={props.errors}
                  touched={props.touched}
                  options={
                    <React.Fragment>
                      {reasons.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </React.Fragment>
                  }
                />
              </div>
            </section>
            <section className="columns is-marginless">
              <div className="column is-paddingless">
                <FormInput
                  errors={props.errors}
                  touched={props.touched}
                  label="Denial Note"
                  name="Note"
                  type="text"
                  component="textarea"
                  placeholder="Enter Denial Explanation..."
                />
              </div>
            </section>
          </React.Fragment>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

IDFormDeny.propTypes = {
  endPoint: reqStrProp,
  data: objProp,
  history: objProp,
};

export default IDFormDeny;
