/* COMPONENT OVERVIEW
- Takes props from parent 
- Renders Account phone data
- Calls postAccount function prop & sends request to parent
*/
// Modules
import React, { useState } from "react";
// Components
import PhoneForm from "./PhoneForm";
import { EditBtn, ToastError } from "../../Utilities/Components";
import {
  accountProps,
  reqBoolProp,
  reqFuncProp,
} from "../../Utilities/PropTypes";
// Permissions
import { ACCOUNT_EDIT } from "../../Permissions/Permissions";

const Phones = ({ editing, data, toggleEdit, postAccount }) => {
  const [phoneSubmitting, setPhoneSubmitting] = useState(false);

  const triggerEdit = (e) => {
    e.preventDefault();
    toggleEdit("editingPhones");
  };

  const callPostAccount = async (hash, Phone, Active, Preferred) => {
    setPhoneSubmitting(true);
    let req = {
      Phone,
      Active,
      Preferred,
    };
    // Sends req to POST call in parent
    await postAccount("phones", hash, null, req, "editingPhones")
      .catch(() => {
        ToastError("Could not update phone number");
      })
      .finally(() => {
        setPhoneSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      <EditBtn
        editing={editing}
        triggerEdit={triggerEdit}
        sectionTitle="Phones"
        permission={ACCOUNT_EDIT}
        link={{
          pathname: "/accounts/create-phone",
          hash: data.Hash,
          state: { account: data },
        }}
      />
      <div className="form-outline inline-edit-form">
        {data.PhoneNumbers ? (
          data.PhoneNumbers.map((phone) => {
            return (
              <PhoneForm
                key={phone.Hash}
                data={phone}
                callBackFunc={callPostAccount}
                editing={editing}
                phoneSubmitting={phoneSubmitting}
              />
            );
          })
        ) : (
          <p>No phone numbers found</p>
        )}
      </div>
    </React.Fragment>
  );
};

Phones.propTypes = {
  postAccount: reqFuncProp,
  toggleEdit: reqFuncProp,
  editing: reqBoolProp,
  data: accountProps,
};

export default Phones;
