import React, { useEffect, useState } from 'react';
import { EditBtn, LoadingButton } from '../../Utilities/Components';
import { EditableStatesListBox } from '../../Forms/EditableInputs';
import { GROUPS_EDIT } from '../../Permissions/Permissions';

const OperatingStates = ({
  states,
  editing,
  postGroup,
  data,
  toggleEdit,
  isSubmitting,
}) => {
  const [selectedStates, setSelectedStates] = useState();

  useEffect(() => {
    // Sets selected states if API returns property/value for states
    if (states?.length) {
      setSelectedStates(states.map(({ StateCode }) => StateCode));
    }
  }, []);

  const callPostGroup = () => {
    const req = {
      OperatingStates: selectedStates,
    };

    // Sends req to POST call in parent
    postGroup('groups', data.Hash, null, req, 'editingStates');
  };

  const triggerEdit = event => {
    // TODO: move this out or into the button
    event.preventDefault();

    toggleEdit('editingStates');
  };

  return (
    <>
      <div className="edit-actions-override">
        <EditBtn
          editing={editing}
          permission={GROUPS_EDIT}
          triggerEdit={triggerEdit}
          sectionTitle="Active States"
        />
      </div>
      <div
        className={`form-outline inline-edit-form operating-states ${
          editing ? 'editing' : ''
        }`}
      >
        <EditableStatesListBox
          currentValue={selectedStates}
          onChange={setSelectedStates}
          selected={selectedStates}
          states={states}
          editing={editing}
        />

        {editing ? (
          <LoadingButton
            loading={isSubmitting}
            btnText="Submit"
            btnAction={() => callPostGroup()}
          />
        ) : null}
      </div>
    </>
  );
};

export default OperatingStates;
