// Modules
import React from "react";
// Components
import { GenericForm, FormikContext } from "../../../Forms/Form";
import { FormInput } from "../../../Forms/FormInputs";

const AddEmailRecipient = ({ hash, endpoint, location, history }) => {
  const setHeader = () => {
    if (endpoint === "accounting-emails") {
      return "Accounting Email Recipient";
    } else if (endpoint === "commission-emails") {
      return "Commission Email Recipient";
    }
  };

  return (
    <GenericForm
      endpoint={`groups/${hash}/${endpoint}`}
      setValues={{
        Email: "",
        Active: null,
      }}
      formTitle={`Add ${setHeader()}`}
      subTitle={null}
      goBack={{
        show: true,
        setHistory: history,
        setDefaultPath: `/groups/${hash}`,
        setDefaultName: "Groups",
      }}
      customValidations={(values) => {
        let errors = {};
        if (!values.Email) {
          errors.Email = "is required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
        ) {
          errors.Email = "is invalid";
        }
        return errors;
      }}
      toastMsg="New email recipient has been added successfully"
    >
      <FormikContext.Consumer>
        {(props) => (
          <div className="columns">
            <div className="column is-4">
              <FormInput
                errors={props.errors}
                touched={props.touched}
                name="Email"
                label="Email"
                type="email"
                placeholder="example@digitalmint.io"
              />
            </div>
            {endpoint === "commission-emails" && (
              <div className="column is-2">
                <FormInput
                  label="Active"
                  name="Active"
                  type="checkbox"
                  errors={props.errors}
                  touched={props.touched}
                />
              </div>
            )}
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

export default AddEmailRecipient;
