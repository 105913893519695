// Modules
import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// Investigation Components
import SARData from './Tabs/SARData';
import InvestigationNarrative from './Narrative';
import DecideNarrative from './DecideNarrative';
import Notes from './Tabs/Notes';
import AssociatedAlerts from './Tabs/AssociatedAlerts';
import Documents from './Tabs/Documents';
import LinkedAccounts from './Tabs/LinkedAccounts';
// Components
import {
  GoBack,
  Header,
  Loading,
  ToastError,
  ToastSuccess,
} from '../../Utilities/Components';
// Icons
import { ICONS } from './../../Icons/IconsPath';
// Styles
import './Investigations.scss';
// Helpers - Formatters
import { getDefaultTab, setDefaultTab } from '../../Utilities/Formatters';
// Permissions
import Can from '../../Permissions/Can';
import { COMPLIANCE_INVEST_ACTION } from '../../Permissions/Permissions';
// Configs
import { API } from '../../Config/cfg.js';

class SingleInvestigation extends Component {
  state = {
    data: {},
    loading: true,
    editing: false,
    errors: {},
    docHash: '',
    callAPI: false,
    modalIsOpen: false,
  };

  triggerEdit = e => {
    e.preventDefault();
    this.setState({ editing: !this.state.editing });
  };

  componentDidMount() {
    this.getInvestigation();
  }

  getInvestigation = () => {
    const { match } = this.props;
    const {
      params: { hash },
    } = match;

    API.get(`/compliance/investigations/${hash}`)
      .then(res => {
        this.setState({
          data: res.data,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({ errors: error.response.data });
      });
  };

  postDecision = (bool, decision) => {
    const { match, history } = this.props;
    const {
      params: { hash },
    } = match;

    API.post(`/compliance/investigations/${hash}/review`, {
      Approved: bool,
    })
      .then(res => {
        bool ? history.push('/compliance') : this.getInvestigation();
        ToastSuccess(`Investigation hash been ${decision}`);
      })
      .catch(error => {
        ToastError(error);
      });
  };

  deleteDocument = hash => {
    const { docHash } = this.state;
    // Updating endpoint based on https://gitlab.com/digitalmint/bravo-web/-/issues/939#updated-endpoint
    API.delete(`/compliance/documents/${docHash}`)
      .then(res => {
        ToastSuccess(`Document has been deleted`);
        this.setState({
          modalIsOpen: false,
          banHash: '',
          callAPI: true,
        });
      })
      .catch(error => {
        ToastError(error);
      });
  };

  openModal = docHash => {
    this.setState({ modalIsOpen: true, docHash });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, docHash: '' });
  };

  render() {
    const { loading, data, errors, callAPI, modalIsOpen } = this.state;
    const { history } = this.props;

    const {
      AuthorAccount,
      AssignedTo,
      Country,
      Disposition,
      DispositionText,
      SARAmount,
      ReviewPeriodEnd,
      ReviewPeriodStart,
      ActivityTypeText,
      Code,
      Source,
      Hash,
      ParentAlert,
      ReferalTypeText,
    } = data;

    if (loading) {
      return <Loading errors={errors} />;
    }

    return (
      <React.Fragment>
        <GoBack
          history={this.props.history}
          defaultPath="/compliance/investigations"
          defaultName="Investigations"
        />
        <div className="columns is-vcentered">
          <div className="column">
            <Header icon={ICONS.FILE} title={Code} />
          </div>
          {AuthorAccount && (
            <Header code="Worked by: " title={AuthorAccount.FullName} />
          )}

          {AssignedTo && !AuthorAccount && (
            <Header code="Assigned to: " title={AssignedTo.FullName} />
          )}
        </div>

        <React.Fragment>
          <div className="columns">
            <div className="column is-6">
              <SARData
                ReviewPeriodEnd={ReviewPeriodEnd}
                ReviewPeriodStart={ReviewPeriodStart}
                ActivityType={ActivityTypeText}
                Source={Source}
                Disposition={Disposition}
                DispositionText={DispositionText}
                SARAmount={SARAmount}
                Location={Location}
                ReferralText={ReferalTypeText}
                Country={Country}
              />
            </div>
            {ParentAlert && (
              <div className="column is-6">
                <AssociatedAlerts ParentAlert={ParentAlert} />
              </div>
            )}
          </div>

          {Disposition === undefined ? (
            <Can do={COMPLIANCE_INVEST_ACTION}>
              <InvestigationNarrative
                data={data}
                history={history}
                endPoint={`compliance/investigations/${Hash}`}
              />
            </Can>
          ) : (
            <DecideNarrative data={data} postDecision={this.postDecision} />
          )}

          <Tabs
            className="tabs-container"
            defaultIndex={getDefaultTab('account', 0)}
            onSelect={index => setDefaultTab('account', index)}
          >
            <TabList className="columns tab-list">
              <Tab className="column tab">Linked Accounts</Tab>
              <Tab className="column tab">Notes</Tab>
              <Tab className="column tab">Documents</Tab>
            </TabList>

            <TabPanel>
              <LinkedAccounts hash={Hash} />
            </TabPanel>
            <TabPanel>
              <Notes hash={Hash} history={history} />
            </TabPanel>

            <TabPanel>
              <Documents
                hash={Hash}
                deleteDocument={this.deleteDocument}
                callAPI={callAPI}
                openModal={this.openModal}
                closeModal={this.closeModal}
                modalIsOpen={modalIsOpen}
              />
            </TabPanel>
          </Tabs>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default SingleInvestigation;
