/* COMPONENT OVERVIEW
- Builds left nav links
- Houses svg paths for link icons
*/
// Icons
import { ICONS } from '../Icons/IconsPath';
// Permissions
import {
  ACCOUNT_VIEW,
  ACCOUNTING_ACCOUNTS,
  ACCOUNTING_GROSS_COMMISS,
  COMPLIANCE_VIEW,
  COMPLIANCE_EDDS_VIEW,
  COMPLIANCE_PEP_VIEW,
  COMPLIANCE_APPROVE,
  GROUPS_VIEW,
  LOCATIONS_VIEW,
  PLATFORMS_VIEW,
  SETTINGS_COMPLIANCE_EDIT,
  SETTINGS_BUSINESS_EDIT,
  TX_VIEW,
  VOUCHERS_VIEW,
  FUNDING_VIEW,
  // ACCOUNT_CONTACT_FREEFORM,
  ACCOUNTING_VIEW,
} from '../Permissions/Permissions';

export const RouteLinks = [
  {
    path: '/transactions',
    name: 'Transactions',
    icon: ICONS.TRANSACTION,
    permission: TX_VIEW,
  },
  {
    path: '/accounts',
    name: 'Accounts',
    icon: ICONS.USER,
    permission: ACCOUNT_VIEW,
  },
  {
    path: '/compliance/pending-reviews',
    name: 'Pending Accounts',
    icon: ICONS.FILE,
    permission: COMPLIANCE_APPROVE,
  },
  {
    path: '/locations',
    name: 'Locations',
    icon: ICONS.LOCATION,
    permission: LOCATIONS_VIEW,
  },

  {
    path: '/platforms',
    name: 'Platforms',
    icon: ICONS.IPAD,
    permission: PLATFORMS_VIEW,
  },
  {
    path: '/compliance',
    name: 'Compliance',
    icon: ICONS.COMPLIANCE,
    permission: COMPLIANCE_VIEW,
    children: [
      {
        path: '/compliance/bans',
        name: 'Bans',
        icon: ICONS.COMPLIANCE,
        permission: COMPLIANCE_VIEW,
      },
      {
        path: '/compliance/alerts',
        name: 'Alerts',
        icon: ICONS.COMPLIANCE,
        permission: COMPLIANCE_VIEW,
      },
      {
        path: '/compliance/investigations',
        name: 'Investigations',
        icon: ICONS.COMPLIANCE,
        permission: COMPLIANCE_VIEW,
      },
      {
        path: '/compliance/ctrs',
        name: 'CTRs',
        icon: ICONS.COMPLIANCE,
        permission: COMPLIANCE_VIEW,
      },
      {
        path: '/compliance/edds',
        name: 'High Risk (EDD)',
        icon: ICONS.COMPLIANCE,
        permission: COMPLIANCE_EDDS_VIEW,
      },
      {
        path: '/compliance/subpoenas',
        name: 'Subpoenas',
        icon: ICONS.COMPLIANCE,
        permission: COMPLIANCE_VIEW,
      },
      {
        path: '/compliance/peps',
        name: 'PEPs',
        icon: ICONS.COMPLIANCE,
        permission: COMPLIANCE_PEP_VIEW,
      },
    ],
  },
  {
    path: '/groups',
    name: 'Groups',
    icon: ICONS.GROUPS,
    permission: GROUPS_VIEW,
  },
  {
    path: '/business-settings',
    name: 'Business Settings',
    icon: ICONS.SETTINGS,
    permission: SETTINGS_BUSINESS_EDIT,
    children: [
      {
        path: '/accounting/ach',
        name: 'ACH',
        icon: ICONS.USD,
        permission: ACCOUNTING_ACCOUNTS,
      },
      {
        path: '/accounting/funding',
        name: 'Funding Accounts',
        icon: ICONS.BTC,
        permission: FUNDING_VIEW,
      },
      {
        path: '/business-settings',
        name: 'Business Settings',
        icon: ICONS.SETTINGS,
        permission: SETTINGS_BUSINESS_EDIT,
      },
      {
        path: '/terms-of-service',
        name: 'Terms of Service',
        icon: ICONS.SETTINGS,
        permission: SETTINGS_BUSINESS_EDIT,
      },
      {
        path: '/customer-refunds',
        name: 'Customer Refunds',
        icon: ICONS.SETTINGS,
        permission: ACCOUNTING_VIEW,
      },
    ],
  },
  {
    path: '/compliance-settings',
    name: 'Compliance Settings',
    icon: ICONS.SETTINGS,
    permission: SETTINGS_COMPLIANCE_EDIT,
  },
  {
    path: '/accounting/commissions',
    name: 'Accounting',
    icon: ICONS.ACCOUNTING,
    permission: ACCOUNTING_VIEW,
    children: [
      {
        path: '/accounting/commissions',
        name: 'Commissions',
        icon: ICONS.ACCOUNTING,
        permission: ACCOUNTING_GROSS_COMMISS,
        exact: true,
      },
      {
        path: '/accounting/cash',
        name: 'Cash Collection',
        icon: ICONS.USD,
        permission: ACCOUNTING_VIEW,
      },
      {
        path: '/accounting/cash-locations',
        name: 'Cash Locations',
        icon: ICONS.LOCATION,
        permission: ACCOUNTING_VIEW,
      },
    ],
  },
  // TODO: Removed from prod until text blaster is compliant
  // {
  //   path: '/text-blast',
  //   name: 'Text Blast',
  //   icon: ICONS.SMS,
  //   permission: ACCOUNT_CONTACT_FREEFORM,
  // },
  {
    path: '/vouchers',
    name: 'Vouchers',
    icon: ICONS.FILE,
    permission: VOUCHERS_VIEW,
  },
];
