import { InvestigationActivityTypes } from "../Data/InvestigationActivityTypes";

const formatActivityType = (data) => {
  var str = "N/A";
  InvestigationActivityTypes.forEach((t) => {
    if (t.value === data) {
      str = t.display;
      return;
    }
  });
  return str;
};

export default formatActivityType;
