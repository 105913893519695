// Modules
import React from "react";
import { Link } from "react-router-dom";
// Components
import Table from "./../../../Table/Table";
// Helpers - Formatters
import { formatPrice, formatPercent } from "../../../Utilities/Formatters";

const TransactionCommissions = ({ hash }) => {
  return (
    <Table
      columns={columnBuilder}
      endpoint={`transactions/${hash}/commissions`}
      nested={true}
      tablename="Transaction Commissions"
    />
  );
};

export default TransactionCommissions;

const columnBuilder = [
  {
    Header: "Group",
    accessor: "Group",
    style: { textAlign: "left " },
    Cell: ({ row: { original } }) => (
      <Link
        className="table-pill"
        to={`/groups/${original.LocationGroupCommission.GroupCommission.Group.Hash}`}
      >
        {original.LocationGroupCommission.GroupCommission.Group.Name}
      </Link>
    ),
  },
  {
    Header: "Gross Commission",
    accessor: "GrossCommission",
    Cell: ({ row: { original } }) => formatPrice(original.GrossCommission),
  },
  {
    Header: "Payout %",
    accessor: "Payout",
    Cell: ({ row: { original } }) => formatPercent(original.Payout),
  },
  {
    Header: "Earnings",
    accessor: "Earnings",
    Cell: ({ row: { original } }) => formatPrice(original.Earnings),
  },
];
