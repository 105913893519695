// checks if the required permission is available from the user
export const checkPermission = (userPermissions = [], reqPermission) => {
  if (!reqPermission) return false;

  const permission = userPermissions.find(permission => {
    if (permission.split('.')[1] === '*') {
      return permission.split('.')[0] === reqPermission.split('.')[0];
    } else {
      return permission === reqPermission;
    }
  });

  return !!permission;
};

// returns first valid sidebar route
export const findDefaultRoute = (userPermissions = []) => {
  let defaultRoute = '/';

  const route = userPermissions.find(permission => {
    const route = permission.split('.')[1];

    return route === '*' || route === 'view';
  });

  if (route) {
    defaultRoute += route.split('.')[0];
  }

  return defaultRoute;
};
