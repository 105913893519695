// Modules
import React from "react";
import columnBuilder from "./../../Locations/columnBuilder";
// Components
import Table from "./../../Table/Table";
// Permissions

const GroupLocations = ({ hash }) => {
  return (
    <Table
      columns={columnBuilder}
      downloadable={true}
      endpoint={`groups/${hash}/locations`}
      tablename="Locations"
      nested={true}
    />
  );
};

export default GroupLocations;
