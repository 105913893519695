import React from 'react';
// Components
import { GenericModal } from '../../../Utilities/Components';

const TwoFactorModal = ({
  isOpen,
  postAccount,
  hash,
  isSubmitting,
  closeModal,
}) => {
  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={() => {
        closeModal('isRemoveTwoFactorOpen');
      }}
      headerMsg="Warning: Removing Two-Factor Authenticaion"
      viewMsg="This action REMOVES the Two-Factor Authentication (2FA) from this account. Please make sure the customer is aware anyone with their password will be able to login as them."
      btnText="Remove 2FA"
      btnAction={async e => {
        await postAccount(hash);
        e.preventDefault();
      }}
      isSubmitting={isSubmitting}
    />
  );
};

export default TwoFactorModal;
