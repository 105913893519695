import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { formatLabel } from './utils';
import PropTypes from 'prop-types';

const EditableSelect = ({
  currentValue,
  data,
  disabled,
  editing,
  id,
  label,
  name,
  placeholder,
  selected,
  testID,
}) => {
  return (
    <div data-testid={testID} className="form-row">
      {editing ? (
        <>
          <ErrorMessage name={name} component="div" className="form-error" />
          <label htmlFor={id || name} className="item-label">
            {formatLabel(label)}
          </label>
          <Field
            className="form-row"
            component="select"
            disabled={disabled}
            id={id || name}
            name={name}
            data-testid={`${testID}-select`}
          >
            <option value="" disabled>
              {placeholder}
            </option>
            {data.map(item => {
              if (item.Hash === selected) {
                return (
                  <option key={item.Hash} value={item.Hash} selected>
                    {name === 'CreditAccount'
                      ? `${item.Name} *${item.AccountNumber}`
                      : item.Name}
                  </option>
                );
              }

              return (
                <option key={item.Hash} value={item.Hash}>
                  {name === 'CreditAccount'
                    ? `${item.Name} *${item.AccountNumber}`
                    : item.Name}
                </option>
              );
            })}
          </Field>
        </>
      ) : (
        <>
          <label className="item-label">{formatLabel(label)}</label>
          <p>{currentValue}</p>
        </>
      )}
    </div>
  );
};

EditableSelect.defaultProps = {
  currentValue: '-',
  disabled: false,
  editing: false,
  testID: 'xEditableSelect',
};

EditableSelect.propTypes = {
  currentValue: PropTypes.any,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  selected: PropTypes.string,
  testID: PropTypes.string,
};

export default EditableSelect;
