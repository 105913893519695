import React from "react";
// Components
import Header from "./Header";
// Icons
import Icon from "./../../Icons/Icon";
import { ICONS } from "./../../Icons/IconsPath";
import BrokenLeaf from "../../Icons/BrokenLeaf";
// PropTypes
import { errorProps } from "./../PropTypes";
// Styles
import "./../../Dashboards/NoMatch.scss";

const InternalServerError = ({ error }) => {
  return (
    <div>
      <div className="columns  is-vcentered" style={{ height: "70vh" }}>
        <div className="column">
          <div className="has-text-centered">
            <Icon icon={ICONS.WARNING} color="#272a39" size="60" />
            <h1 className="has-text-centered">
              {error.Code + ": " + error.Status}
            </h1>
            <p className="has-text-centered">{error.Message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

InternalServerError.propTypes = {
  error: errorProps,
};

const BadRequestError = ({ error }) => {
  return (
    <div className="columns no-match">
      <div className="column">
        <div className="has-text-centered">
          <BrokenLeaf />
          <Header title={error.Code + ": " + error.Status} />
          <p className="has-text-centered">{error.Message}</p>
        </div>
      </div>
    </div>
  );
};

BadRequestError.propTypes = {
  error: errorProps,
};

const NotFoundError = () => {
  return (
    <div className="columns no-match">
      <div className="column">
        <div className="has-text-centered">
          <BrokenLeaf />
          <Header title="404: Page Not Found" />
          <p className="has-text-centered" />
        </div>
      </div>
    </div>
  );
};

const NotPermissioned = () => {
  return (
    <div className="columns no-match">
      <div className="column">
        <div className="has-text-centered">
          <BrokenLeaf />
          <Header title="You're not permissioned to view this" />
        </div>
      </div>
    </div>
  );
};

export { InternalServerError, BadRequestError, NotFoundError, NotPermissioned };
