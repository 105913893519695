import React from "react";
// Components
import { ListGroup, ListGroupItem } from "../../../Utilities/Components";
// Helpers - Formatters
import {
  formatPrice,
  formatDate,
  formatActivityType,
} from "../../../Utilities/Formatters";
// Helpers - Data
import { strProp, numProp } from "./../../../Utilities/PropTypes";

const SARData = (props) => {
  const {
    Country,
    Disposition,
    DispositionText,
    Source,
    ReviewPeriodStart,
    ReviewPeriodEnd,
    ActivityType,
    ReferralText,
    SARAmount,
  } = props;

  return (
    <ListGroup title="Suspicious Activity Report">
      <ListGroupItem name="Disposition No." value={Disposition} />
      <ListGroupItem name="Disposition" value={DispositionText} />
      <ListGroupItem name="Source" value={Source} />
      <ListGroupItem name="Referral Reason" value={ReferralText} />
      <ListGroupItem
        name="Review Period Start"
        value={formatDate(ReviewPeriodStart)}
      />
      <ListGroupItem
        name="Review Period End"
        value={formatDate(ReviewPeriodEnd)}
      />
      <ListGroupItem
        name="Activity Type"
        value={formatActivityType(ActivityType)}
      />
      <ListGroupItem name="SAR Amount" value={formatPrice(SARAmount)} />
      <ListGroupItem name="Location" value={null} />
      <ListGroupItem name="Country of Origin" value={Country?.Code} />
    </ListGroup>
  );
};

SARData.propTypes = {
  Disposition: numProp,
  DispositionText: strProp,
  Source: strProp,
  SARAmount: numProp,
  ReviewPeriodEnd: strProp,
  ReviewPeriodStart: strProp,
  ActivityType: numProp,
};

export default SARData;
