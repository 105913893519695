import React from 'react';
import { Link } from 'react-router-dom';
// Helpers - Formatters
import { formatPercent, formatPrice } from '../Utilities/Formatters';
import Icon from '../Icons/Icon';
import { ICONS } from '../Icons/IconsPath';

const columnBuilder = formatCommission => [
  {
    Header: 'Hash',
    accessor: 'Hash',
  },
  {
    Header: 'Name',
    accessor: 'Name',
    style: { textAlign: 'left' },
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/groups/${original.Hash}`}>
        {original.Name}
      </Link>
    ),
    minWidth: 200,
    default: true,
  },
  {
    Header: 'Parent',
    accessor: 'Parent.Hash',
    Cell: ({ row: { original } }) =>
      original.Parent ? (
        <Link className="table-pill" to={`/groups/${original.Parent.Hash}`}>
          {original.Parent.Name}
        </Link>
      ) : (
        '-'
      ),
    minWidth: 200,
  },
  {
    Header: 'Markup',
    accessor: 'Settings.MarkupRate',
    Cell: ({ row: { original } }) =>
      formatPercent(original.InheritedSettings.MarkupRate),
    width: 75,
    default: true,
  },
  {
    Header: 'Markdown',
    accessor: 'Settings.MarkdownRate',
    Cell: ({ row: { original } }) =>
      formatPercent(original.InheritedSettings.MarkdownRate),
    width: 75,
    default: true,
  },
  {
    Header: 'Daily Limit',
    accessor: 'Settings.DailyTransactionLimit',
    Cell: ({ row: { original } }) =>
      formatPrice(original.InheritedSettings.DailyTransactionLimit),
    default: true,
  },
  {
    Header: 'Transaction Limit',
    accessor: 'Settings.PerTransactionLimit',
    Cell: ({ row: { original } }) =>
      formatPrice(original.InheritedSettings.PerTransactionLimit),
    default: true,
  },
  {
    Header: 'Monthly Limit',
    accessor: 'Settings.InheritedSettings.MonthlyTransactionLimit1',
    Cell: ({ row: { original } }) =>
      formatPrice(original.InheritedSettings.MonthlyTransactionLimit1),
    minWidth: 175,
  },
  {
    Header: 'Tier 2 Daily Limit',
    accessor: 'Settings.InheritedSettings.LargeTransactionLimit',
    Cell: ({ row: { original } }) =>
      formatPrice(original.InheritedSettings.LargeTransactionLimit),
    minWidth: 200,
  },
  {
    Header: 'Tier 2 Monthly Limit',
    accessor: 'Settings.InheritedSettings.MonthlyTransactionLimit2',
    Cell: ({ row: { original } }) =>
      formatPrice(original.InheritedSettings.MonthlyTransactionLimit2),
    minWidth: 200,
  },
  {
    Header: 'Member Count',
    accessor: 'MemberCount',
  },
  {
    Header: 'Commissions',
    accessor: 'GroupCommissions.length',
    Cell: ({ row: { original } }) => {
      return original.GroupCommissions
        ? formatCommission(
            original.GroupCommissions.filter(com => !com.Retired)
          )
        : '-';
    },
  },
  {
    Header: 'Weekly Avg. Accounts',
    accessor: 'GroupTransactionStatistics.WeeklyAvgAccounts',
  },
  {
    Header: 'Weekly Avg. Collected',
    accessor: 'GroupTransactionStatistics.WeeklyAvgCollected',
    Cell: ({ row: { original } }) =>
      formatPrice(original?.GroupTransactionStatistics?.WeeklyAvgCollected),
  },
  {
    Header: 'Prior Month Volume',
    sortMethod: 'PriorMonthVolume',
    accessor: 'GroupTransactionStatistics.VolumePriorMonth',
    Cell: ({ row: { original } }) =>
      formatPrice(original?.GroupTransactionStatistics?.VolumePriorMonth),
  },
  {
    Header: 'Current Month Value',
    accessor: 'GroupTransactionStatistics.VolumeCurrentMonth',
    Cell: ({ row: { original } }) =>
      formatPrice(original?.GroupTransactionStatistics?.VolumeCurrentMonth),
  },
  {
    Header: 'ACH Emails',
    accessor: 'ACHEmails',
    Cell: ({ row: { original } }) =>
      original?.ACHEmails ? original.ACHEmails[0].Email : '-',
  },
  {
    Header: 'Has Commission Emails',
    accessor: 'HasCommissionEmail',
    Cell: ({ row: { original } }) =>
      original.HasCommissionEmail ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
    maxWidth: 85,
  },
];

export default columnBuilder;
