const FIELD_MAP = {
  street_number: {
    key: 'Street',
    value: 'long_name',
  },
  route: {
    key: 'Street',
    value: 'long_name',
  },
  subpremise: {
    key: 'Street2',
    value: 'long_name',
  },
  administrative_area_level_1: {
    key: 'StateCode',
    value: 'short_name',
  },
  country: {
    key: 'CountryHash',
    value: 'short_name',
  },
  locality: {
    key: 'City',
    value: 'long_name',
  },
  postal_code: {
    key: 'Zip',
    value: 'long_name',
  },
};

export default function (data = {}, countryHashes = []) {
  return data?.address_components?.reduce((address, component) => {
    const field = FIELD_MAP[component.types[0]];

    if (field) {
      if (address[field.key]) {
        address[field.key] += ` ${component[field.value]}`;
      } else if (field.key === 'CountryHash') {
        const Country = countryHashes?.find(({ Code }) => {
          return component[field.value] === Code;
        });

        if (Country) address[field.key] = Country.Hash;
      } else {
        address[field.key] = component[field.value];
      }
    }
    return address;
  }, {});
}
