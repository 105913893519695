import React from 'react';
import { Form, Formik } from 'formik';
// Components
import { FormInput, FormSelect } from '../../../Forms/FormInputs';
import { GenericModal } from '../../../Utilities/Components';
import { EDDActivityTypes } from '../../../Utilities/Data/EDDActivityTypes';

const EDDModal = ({ isOpen, closeModal, putAccount }) => {
  return (
    <Formik
      initialValues={{
        Note: '',
        ReferralType: '',
      }}
      onSubmit={async values => {
        await putAccount({
          Note: values.Note,
          ReferralType: parseInt(values.ReferralType),
        });
        values.Note = '';
      }}
      validate={values => {
        let errors = {};
        if (!values.Note) {
          errors.Note = 'is required';
        }
        if (!values.ReferralType) {
          errors.ReferralType = 'is required';
        }
        return errors;
      }}
      children={({ touched, errors, handleSubmit, isSubmitting }) => (
        <GenericModal
          modalIsOpen={isOpen}
          headerMsg="Initiate EDD Review"
          btnAction={handleSubmit}
          btnText="Submit"
          isSubmitting={isSubmitting}
          closeModal={() => {
            closeModal('initializeEDD');
          }}
        >
          <Form className="form-outline">
            <div className="columns is-centered">
              <div className="column is-11">
                <FormInput
                  errors={errors}
                  touched={touched}
                  component="textarea"
                  label="Note"
                  type="textarea"
                  name="Note"
                  placeholder="Enter Note..."
                />
              </div>
            </div>
            <div className="columns is-centered">
              <div className="column is-11">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="Reason for Initiating EDD Review"
                  name="ReferralType"
                  type="text"
                  placeholder="Select Reason"
                  options={
                    <>
                      {EDDActivityTypes.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.display}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </Form>
        </GenericModal>
      )}
    ></Formik>
  );
};

export default EDDModal;
