// Modules
import React from "react";
import { Link } from "react-router-dom";
// Helpers - Formatters
import { formatDateTime } from "../../../Utilities/Formatters";

const Image = ({ image }) => (
  <div className="column is-3">
    <div className="card">
      <div className="card-image">
        <a href={image.URL} target="_blank" rel="noopener noreferrer">
          <img src={image.URL} alt="" />
        </a>
      </div>
      <div className="content">
        <span>
          Device ID:{" "}
          <Link to={`/platforms/${image.Platform.Hash}`}>
            {image.Platform.DeviceIdentifier}
          </Link>{" "}
        </span>
        <p>{formatDateTime(image.CreatedAt)}</p>
      </div>
    </div>
  </div>
);

export default Image;
