import React from "react";
import { Link } from "react-router-dom";
// Components
import { ListGroup, ListGroupItem } from "../../../Utilities/Components";
// PropTypes
import { objProp } from "./../../../Utilities/PropTypes";

const AssociatedAlerts = ({ ParentAlert }) => {
  return (
    <ListGroup title="Alert History">
      <ListGroupItem name="Alert Number" value={ParentAlert.Code} />
      <ListGroupItem
        name="Related Escalation"
        value={
          <Link to={`/compliance/alerts/${ParentAlert.Hash}`}>View Alert</Link>
        }
      />
    </ListGroup>
  );
};

AssociatedAlerts.propTypes = {
  ParentAlert: objProp,
};

export default AssociatedAlerts;
