// Modules
import React from 'react';
// Components
import Table from './../../../Table/Table';
import { GenericModal } from '../../../Utilities/Components';
import { DocumentPill } from '../../../Utilities/Components/TablePills';
// Permissions
import { COMPLIANCE_SUBPOENA_ACTION } from '../../../Permissions/Permissions';

const Documents = props => {
  const { hash, deleteDocument, modalIsOpen, callAPI, openModal, closeModal } =
    props;

  return (
    <>
      <Table
        columns={columnBuilder(openModal)}
        endpoint={`compliance/subpoenas/${hash}/documents`}
        tablename="Documents"
        triggerAPI={callAPI}
        nested
        actions={[
          {
            name: 'Upload New Document',
            link: '/compliance/document-upload',
            params: hash,
            state: { fromLocation: 'Subpoena' },
            permission: COMPLIANCE_SUBPOENA_ACTION,
          },
        ]}
      />
      <GenericModal
        modalIsOpen={modalIsOpen}
        headerMsg="ATTENTION: Deleting Document"
        viewMsg="This action will delete the document from this subpoena"
        btnMsg="By clicking 'Delete Document' the current document on this subpoena will be deleted."
        btnText="Delete Document"
        btnAction={deleteDocument}
        closeModal={closeModal}
      />
    </>
  );
};

export default Documents;

const columnBuilder = openModal => [
  {
    Header: 'Name',
    accessor: 'Name',
    Cell: ({ row: { original } }) => (
      <a href={original.URL} target="_blank" rel="noopener noreferrer">
        {original.Name}
      </a>
    ),
  },
  {
    Header: 'Type',
    accessor: 'Type',
    Cell: ({ row: { original } }) =>
      DocumentPill(original.Type, original.TypeText),
  },
  {
    Header: 'Remove Document',
    accessor: 'Hash',
    Cell: ({ row: { original } }) => (
      <button
        className="column-btn remove"
        onClick={() => openModal(original.Hash)}
      >
        Delete
      </button>
    ),
  },
];
