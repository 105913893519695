import Data from './Data';

export default class ACHEmailsData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      Email: { type: 'string' },
    };
  }
}
