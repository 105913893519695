// Modules
import React, { useState } from "react";
// Components
import { GenericModal } from "../Utilities/Components";

const CredentialWizard = ({ dataURIs, open, closeModal, history }) => {
  const [uriIndex, setIndex] = useState(0);
  const [showQR, setShow] = useState(false);
  const setBtnText = !showQR ? "I Understand" : "Close";

  const getButton = () => {
    const endReached = uriIndex === dataURIs.length - 1;
    let style = "primary-btn-outline";
    if (endReached) {
      style = "primary-btn disabled";
    }
    return (
      <div>
        <button
          className={`btn ${style}`}
          onClick={() => setIndex(uriIndex + 1)}
          disabled={endReached}
        >
          Next Code
        </button>
      </div>
    );
  };

  const setBtnAction = () => {
    if (showQR) {
      return () => history.goBack();
    }
    return () => setShow(true);
  };

  return (
    <GenericModal
      modalIsOpen={open}
      closeModal={closeModal}
      viewMsg="Please have your device ready to scan credentials. You will only be able to see these once."
      headerMsg="Initialization Credentials"
      btnText={setBtnText}
      btnAction={setBtnAction()}
      isSubmitting={null}
      hideClose
    >
      {showQR && (
        <div id="siteContainer" className="columns is-centered">
          <div className="column has-text-centered is-paddingless">
            <h2>QR Code {String.fromCharCode(uriIndex + 65)}</h2>
            <img
              src={dataURIs[uriIndex]}
              width="200"
              height="200"
              alt="Credential QR Code"
            />
            {getButton()}
            <div>
              {uriIndex + 1} of {dataURIs.length}
            </div>
          </div>
        </div>
      )}
    </GenericModal>
  );
};

export default CredentialWizard;
