import React from 'react';

const Footer = () => (
  <div id="footer">
    <p>
      Copyright &copy; {new Date().getFullYear()} Red Leaf Chicago, LLC dba{' '}
      <a
        href="https://www.digitalmint.io/"
        target="_blank"
        rel="noopener noreferrer"
      >
        DigitalMint
      </a>
      <small className="version-number">
        Version: {process.env.REACT_APP_APP_VERSION || '0.0.0'}
      </small>
    </p>
  </div>
);

export default Footer;
