const groupKeys = {
  CreatedAt: {
    Label: "Create Date",
    Type: "daterange",
  },
  Name: {
    Label: "Group Name",
    Type: "string",
  },
  UpdatedAt: {
    Label: "Last Update Date",
    Type: "daterange",
  },
  "Permissions.Key": {
    Label: "Permissions",
    Type: "string",
    Options: [
      {
        Group: "Account",
        Values: [
          {
            Display: "Auto Confirm Phone Numbers",
            Value: "accounts.confirm.phone",
          },
          {
            Display: "Contact Customer",
            Value: "accounts.contact",
          },
          {
            Display: "Edit Accounts",
            Value: "accounts.edit",
          },
          {
            Display: "Enroll User Accounts",
            Value: "accounts.enroll",
          },
          {
            Display: "Full Account Control",
            Value: "accounts.*",
          },
          {
            Display: "Invite Accounts",
            Value: "accounts.invite",
          },
          {
            Display: "View Account Data",
            Value: "accounts.view",
          },
        ],
      },
      {
        Group: "Accounting",
        Values: [
          {
            Display: "Create Commissions",
            Value: "accounting.commissions.create",
          },
          {
            Display: "Create Immediate Commissions",
            Value: "accounting.commissions.immediate-payment",
          },
          {
            Display: "Edit Accounting Report Configuration",
            Value: "accounting.reports.edit",
          },
          {
            Display: "Edit Location Commissions",
            Value: "accounting.commissions.edit",
          },
          { Display: "Full Accounting Control", Value: "accounting.*" },
          {
            Display: "Manage Accounting Reports",
            Value: "accounting.reports.*",
          },
          { Display: "Manage Commissions", Value: "accounting.commissions.*" },
          {
            Display: "Manage Credit & Debit Accounts",
            Value: "accounting.accounts.*",
          },
          {
            Display: "View Account Details And Pages",
            Value: "accounting.view",
          },
          {
            Display: "View Accounting Reports",
            Value: "accounting.reports.view",
          },
          { Display: "View Commissions", Value: "accounting.commissions.view" },
          {
            Display: "View Credit & Debit Accounts",
            Value: "accounting.accounts.view",
          },
          {
            Display: "View Gross Commissions",
            Value: "accounting.commissions.gross.view",
          },
        ],
      },
      {
        Group: "API Keys",
        Values: [
          {
            Display: "Create Any Platform API Key",
            Value: "keys.create.*",
          },
          {
            Display: "Create Generic API Key",
            Value: "keys.create.api",
          },
          {
            Display: "Create Kiosk API Key",
            Value: "keys.create.kiosk",
          },
          {
            Display: "Edit API Key",
            Value: "keys.edit",
          },
          {
            Display: "Full API Key Control",
            Value: "keys.*",
          },
          {
            Display: "View API Keys",
            Value: "keys.view",
          },
        ],
      },
      {
        Group: "Compliance",
        Values: [
          {
            Display: "Approve Customers",
            Value: "compliance.approve",
          },
          {
            Display: "Ban Customers",
            Value: "compliance.ban",
          },
          {
            Display: "Full Compliance Control",
            Value: "compliance.*",
          },
          {
            Display: "Manage Compliance Alerts",
            Value: "compliance.alerts.*",
          },
          {
            Display: "Manage Compliance CTRs",
            Value: "compliance.ctrs.*",
          },
          {
            Display: "Manage Compliance Investigations",
            Value: "compliance.investigations.*",
          },
          {
            Display: "Open Compliance Investigations",
            Value: "compliance.investigations.open",
          },
          {
            Display: "Perform Compliance Alert Actions",
            Value: "compliance.alerts.action",
          },
          {
            Display: "Perform Compliance Investigation Actions",
            Value: "compliance.investigations.action",
          },
          {
            Display: "Qc Review Investigations",
            Value: "compliance.investigations.review",
          },
          {
            Display: "Review Compliance Holds",
            Value: "compliance.hold.review",
          },
          {
            Display: "Review Customer Bans",
            Value: "compliance.ban.review",
          },
          {
            Display: "Upload Compliance CTR Documents",
            Value: "compliance.ctrs.upload",
          },
          {
            Display: "View Compliance",
            Value: "compliance.view",
          },
          {
            Display: "View Compliance Alerts",
            Value: "compliance.alerts.view",
          },
          {
            Display: "View Compliance CTRs",
            Value: "compliance.ctrs.view",
          },
          {
            Display: "View Compliance Investigations",
            Value: "compliance.investigations.view",
          },
        ],
      },
      {
        Group: "Funding",
        Values: [
          {
            Display: "Full Funding Control",
            Value: "funding.*",
          },
          {
            Display: "Transmit Funds",
            Value: "funding.transmit",
          },
          {
            Display: "View Balances & Funding Information",
            Value: "funding.view",
          },
        ],
      },
      {
        Group: "Groups",
        Values: [
          {
            Display: "Assign Group Permissions",
            Value: "groups.permissions",
          },
          {
            Display: "Create Groups",
            Value: "groups.create",
          },
          {
            Display: "Edit Groups",
            Value: "groups.edit",
          },
          {
            Display: "Full Group Control",
            Value: "groups.*",
          },
          {
            Display: "View Groups",
            Value: "groups.view",
          },
        ],
      },
      {
        Group: "Locations",
        Values: [
          {
            Display: "Add & Remove Location Platforms",
            Value: "locations.platforms.*",
          },
          {
            Display: "Create Location",
            Value: "locations.create",
          },
          {
            Display: "Edit Location",
            Value: "locations.edit",
          },
          {
            Display: "Full Location Control",
            Value: "locations.*",
          },
          {
            Display: "View Locations",
            Value: "locations.view",
          },
        ],
      },
      {
        Group: "Settings",
        Values: [
          {
            Display: "Edit Business Settings",
            Value: "settings.business.edit",
          },
          {
            Display: "Edit Compliance Settings",
            Value: "settings.compliance.edit",
          },
          {
            Display: "View Settings",
            Value: "settings.view",
          },
        ],
      },
      {
        Group: "Transactions",
        Values: [
          {
            Display: "Cancel A Transaction",
            Value: "tx.cancel",
          },
          {
            Display: "Collect Transaction Payment",
            Value: "tx.collect.payment",
          },
          {
            Display: "Collect Transaction Settlement Delivery Information",
            Value: "tx.collect.delivery",
          },
          {
            Value: "tx.greet.recognize.visual",
            Display:
              "Create New Sessions By Visually Identifying Customers Via Teller",
          },
          {
            Display: "Edit Transaction Details",
            Value: "tx.edit",
          },
          {
            Display: "Execute Transactions",
            Value: "tx.execute",
          },
          {
            Display: "Force Settle Transactions",
            Value: "tx.force-settle",
          },
          {
            Display: "Freeze and Unfreeze A Transaction",
            Value: "tx.freeze",
          },
          {
            Display: "Full Transaction Control",
            Value: "tx.*",
          },
          {
            Display: "Greet Customers To Transact",
            Value: "tx.greet",
          },
          {
            Display: "Immediately Settle Transactions",
            Value: "settle.immediate",
          },
          {
            Display: "Initiate Transactions",
            Value: "tx.initiate",
          },
          {
            Display: "Onboard New Customers During A Transaction",
            Value: "tx.greet.onboard",
          },
          {
            Display: "View Transactions",
            Value: "tx.view",
          },
        ],
      },
      {
        Group: "Vouchers",
        Values: [
          {
            Display: "Full Voucher Control",
            Value: "vouchers.*",
          },
          {
            Display: "View Vouchers",
            Value: "vouchers.view",
          },
          {
            Display: "Create Vouchers",
            Value: "vouchers.create",
          },
          {
            Display: "Void Vouchers",
            Value: "vouchers.void",
          },
        ],
      },
      {
        Group: "Vouchers",
        Values: [
          {
            Display: "Full Voucher Control",
            Value: "vouchers.*",
          },
          {
            Display: "View Vouchers",
            Value: "vouchers.view",
          },
          {
            Display: "Create Vouchers",
            Value: "vouchers.create",
          },
          {
            Display: "Void Vouchers",
            Value: "vouchers.void",
          },
        ],
      },
      {
        Group: "Vouchers",
        Values: [
          {
            Display: "Full Voucher Control",
            Value: "vouchers.*",
          },
          {
            Display: "View Vouchers",
            Value: "vouchers.view",
          },
          {
            Display: "Create Vouchers",
            Value: "vouchers.create",
          },
          {
            Display: "Void Vouchers",
            Value: "vouchers.void",
          },
        ],
      },
      {
        Group: "Vouchers",
        Values: [
          {
            Display: "Full Voucher Control",
            Value: "vouchers.*",
          },
          {
            Display: "Create Vouchers",
            Value: "vouchers.create",
          },
          {
            Display: "View Vouchers",
            Value: "vouchers.view",
          },
          {
            Display: "Void Vouchers",
            Value: "vouchers.void",
          },
        ],
      },
    ],
  },
};
export default groupKeys;
