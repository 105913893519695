// Helpers
import { checkPermission } from "./CheckPermission";

// Workaround for not being able to use <Can> component due to react-tabs restrictions
// for conditionally rendering tabs you have to return NULL. <Can> always returns Context.Consumer
const TabCan = (userPermiss, permission) => {
  return checkPermission(userPermiss, permission) ? true : false;
};

export default TabCan;
