/* eslint-disable no-console */

/* COMPONENT OVERVIEW
- Auth
  - Can Create a new account
  - Can login an account user
  - Renders Login or SignUp
- Login
  - Renders login form
- SignUp
  - Renders signup form
- Account
  - Renders a User's account after correct Login or SignUp
*/
import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import PublicRoutes from 'src/Authentication/PublicRoutes';
import { useApi } from 'src/Services/Api';
import Dashboard from 'src/Dashboards/Dashboard';
import {
  Loader,
  ScrollToTop,
  ToastError,
  ToastWarning,
} from 'src/Utilities/Components';
import './Auth.scss';
import { AccountContext } from 'src/Authentication/AccountContext';
import { AccountData, GroupData, SessionData } from 'src/Data';
import { PermissionsContext } from 'src/Permissions/PermissionContext';
import extractPermissionKeysFromGroups from 'src/Permissions/extractPermissionKeysFromGroups';
import { clearDefaultTab, formatLowerCase } from 'src/Utilities/Formatters';
import * as Sentry from '@sentry/react';

const DEFAULT_VALUE = {};

const Auth = () => {
  // TODO: isLoaded for not showing the login screen on handleAuth
  const [Account, setAccount] = useState(DEFAULT_VALUE);
  const [Groups, setGroups] = useState(DEFAULT_VALUE);
  const [Session, setSession] = useState(DEFAULT_VALUE);

  const [handleAuth, isAuthLoading] = useApi({
    method: 'get',
    url: 'auth',
    onSuccess: data => {
      const Account = AccountData.create(data.Account);
      const Groups = GroupData.createList(data.Groups);
      const Session = SessionData.create(data.Session);

      Sentry.setUser({ email: Account.Email });

      setAccount(Account);
      setGroups(Groups);
      setSession(Session);

      clearDefaultTab();
    },
  });

  const [handleLogin, isLoginLoading] = useApi({
    method: 'post',
    url: 'auth',
    onError: (message, status) => {
      if (status === 401) {
        ToastError('Invalid username/password, please try again.');
      } else {
        ToastError('Could not communicate with server, please try again.');
      }
    },
    onSuccess: (data, status) => {
      if (status === 200) {
        const Account = AccountData.create(data.Account);
        const Groups = GroupData.createList(data.Groups);
        const Session = SessionData.create(data.Session);

        Sentry.setUser({ email: Account.Email });

        setAccount(Account);
        setGroups(Groups);
        setSession(Session);

        if (!Account.OTPEnabled) {
          ToastWarning('Two-Factor Authentication Not Enabled');
        }
      } else if (status === 240) {
        setAccount({ OTPEnabled: true });
        ToastWarning('Please enter your one-time password.');
      }

      clearDefaultTab();
    },
  });

  const [handleAccount] = useApi({
    method: 'put',
    url: 'account',
    onError: message => ToastError(message),
    onSuccess: data => {
      // TODO: Does this return the whole account or partial account?
      const Account = AccountData.create(data);

      Sentry.setUser({ email: Account.Email });

      setAccount(Account);
    },
  });

  const [handleLogout] = useApi({
    method: 'post',
    url: 'auth/logout',
    onError: message => ToastError(message),
    onSuccess: () => {
      setAccount(DEFAULT_VALUE);
      setSession(DEFAULT_VALUE);
      setGroups(DEFAULT_VALUE);

      Sentry.setUser(null);

      cookie.remove('digital-mint-session-token');
    },
  });

  // Check for active session
  useEffect(() => {
    handleAuth();
  }, []);

  return (
    <>
      <Loader
        isLoading={isAuthLoading || isLoginLoading}
        animate={true}
        overlay={true}
      />
      <ScrollToTop>
        {Session.isLoggedIn ? (
          <PermissionsContext.Provider
            value={extractPermissionKeysFromGroups(Groups)}
          >
            <AccountContext.Provider
              value={{
                account: Account,
                setAccount,
              }}
            >
              <Dashboard
                data={Account}
                session={Session}
                logout={async history => {
                  const { success } = await handleLogout();

                  // TODO: Refactor history to use navigation hooks
                  // instad of passing as as an argument
                  if (success) history.push('/');
                }}
              />
            </AccountContext.Provider>
          </PermissionsContext.Provider>
        ) : (
          <div className="auth-container mt-6">
            <PublicRoutes
              OTPEnabled={Account.OTPEnabled}
              login={async (Email, Password, OTP, actions) => {
                const body = {
                  Account: formatLowerCase(Email),
                  Password,
                };

                if (!!OTP) body.OTP = OTP;

                const { error } = await handleLogin(body);

                if (error) {
                  actions.resetForm({});
                }

                // TODO: actions will be refactored out
                // when adopting react router forms
                actions.setSubmitting(false);
              }}
              putAccount={async (body, history) => {
                await handleAccount();

                // TODO: Refactor history to use navigation hooks
                // instad of passing as as an argument
                history.push('/');
              }}
            />
          </div>
        )}
      </ScrollToTop>
    </>
  );
};

export default Auth;
