// Modules
import React from "react";
import columnBuilder from "./columnBuilder";
// Components
import Table from "../../Table/Table";

const CashCollection = (props) => {
  return (
    <Table
      history={props.history}
      columns={columnBuilder}
      endpoint="accounting/cash"
      filter={true}
      tablename="Cash Collection"
      downloadable={true}
      metadata={true}
    />
  );
};

export default CashCollection;
