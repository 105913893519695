import React from 'react';
import { Form, Formik } from 'formik';
import moment from 'moment';
// Components
import { FormInput } from '../../Forms/FormInputs';
import { GenericModal } from '../../Utilities/Components';

const currentDate = moment().format('YYYY-MM-DD');
const currentTime = moment().format('HH:mm');

const CashCollectionModal = ({
  btnText,
  isOpen,
  callBack,
  closeModal,
  viewMsg,
}) => {
  return (
    <Formik
      initialValues={{
        EventTime: currentDate,
        Timestamp: currentTime,
      }}
      validate={values => {
        const errors = {};

        if (!values.EventTime) {
          errors.EventTime = 'is required';
        }

        if (!values.Timestamp) {
          errors.Timestamp = 'is required';
        }

        return errors;
      }}
      onSubmit={async values => {
        const time = `${values.EventTime} ${values.Timestamp}`;

        await callBack({
          EventTime: moment(time).utc().format(),
        });
      }}
      children={({
        touched,
        errors,
        handleSubmit,
        setErrors,
        isSubmitting,
      }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={() => {
            setErrors({});
            closeModal();
          }}
          viewMsg={viewMsg}
          headerMsg="Confirm Cash Collection"
          btnText={btnText}
          btnAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <Form className="form-outline">
            <div className="columns is-centered">
              <div className="column is-6">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Date"
                  name="EventTime"
                  type="date"
                />
              </div>
              <div className="column is-6">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Time"
                  name="Timestamp"
                  type="time"
                />
              </div>
            </div>
          </Form>
        </GenericModal>
      )}
    />
  );
};

export default CashCollectionModal;
