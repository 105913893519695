// Modules
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Components
import Table from './../../../Table/Table';
import {
  GenericModal,
  ToastError,
  ToastSuccess,
} from '../../../Utilities/Components';
// Helpers - Formatters
import { formatPercent, formatPrice } from '../../../Utilities/Formatters';
// Permissions
import Can from '../../../Permissions/Can';
import { ACCOUNT_EDIT } from '../../../Permissions/Permissions';
// Configs
import { API } from '../../../Config/cfg.js';

class GroupsTable extends Component {
  state = {
    groupHash: '',
    groupName: '',
    callAPI: false,
    modalIsOpen: false,
    isSubmitting: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.callAPI === true) {
      this.setState({ callAPI: false });
    }
  }

  openModal = (groupHash, groupName) => {
    this.setState({ modalIsOpen: true, groupHash, groupName });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  removeGroup = () => {
    this.setState({ isSubmitting: true });
    const { groupHash } = this.state;
    const { accountHash } = this.props;
    let req = {
      GroupHash: groupHash,
    };
    API.post(`/accounts/${accountHash}/groups`, req)
      .then(res => {
        this.setState({
          modalIsOpen: false,
          groupHash: '',
          callAPI: true,
        });
        ToastSuccess(`Group has been removed from account`);
        this.setState({ isSubmitting: false });
      })
      .catch(error => {
        ToastError(error);
      });
  };

  render() {
    let { callAPI, modalIsOpen, groupName, isSubmitting } = this.state;
    const { accountHash } = this.props;
    return (
      <React.Fragment>
        <Table
          columns={columnBuilder(this.openModal)}
          endpoint={`accounts/${accountHash}/groups`}
          nested={true}
          tablename="Account Groups"
          triggerAPI={callAPI}
          actions={[
            {
              name: 'Add to New Group',
              link: '/accounts/add-to-group',
              params: accountHash,
              permission: ACCOUNT_EDIT,
            },
          ]}
        />
        <GenericModal
          modalIsOpen={modalIsOpen}
          headerMsg="ATTENTION: Removing Group"
          viewMsg={`This action will remove ${groupName} from this account.`}
          btnMsg={`By clicking 'Remove Group' ${groupName} will be removed from this account`}
          btnText="Remove Group"
          btnAction={this.removeGroup}
          closeModal={() => {
            this.closeModal();
          }}
          isSubmitting={isSubmitting}
        />
      </React.Fragment>
    );
  }
}

export default GroupsTable;

const columnBuilder = openModal => [
  {
    Header: 'Name',
    accessor: 'Name',
    style: { textAlign: 'left' },
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/groups/${original.Hash}`}>
        {original.Name}
      </Link>
    ),
  },
  {
    Header: 'Markup',
    accessor: 'Settings.MarkupRate',
    Cell: ({ row: { original } }) =>
      original.InheritedSettings
        ? formatPercent(original.InheritedSettings.MarkupRate)
        : null,
  },
  {
    Header: 'Markdown',
    accessor: 'Settings.MarkdownRate',
    Cell: ({ row: { original } }) =>
      original.InheritedSettings
        ? formatPercent(original.InheritedSettings.MarkdownRate)
        : null,
  },
  {
    Header: 'Daily Transaction Limit',
    accessor: 'Settings.DailyTransactionLimit',
    Cell: ({ row: { original } }) =>
      original.InheritedSettings
        ? formatPrice(original.InheritedSettings.DailyTransactionLimit)
        : null,
  },
  {
    Header: 'Per Transaction Limit',
    accessor: 'Settings.PerTransactionLimit',
    Cell: ({ row: { original } }) =>
      original.InheritedSettings
        ? formatPrice(original.InheritedSettings.PerTransactionLimit)
        : null,
  },
  {
    Header: 'Action',
    accessor: 'Hash',
    Cell: ({ row: { original } }) => (
      <Can do={ACCOUNT_EDIT}>
        <button
          className="column-btn remove"
          onClick={() => openModal(original.Hash, original.Name)}
        >
          Remove Group
        </button>
      </Can>
    ),
  },
];
