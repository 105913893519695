const formatPlatformsCashCourierCell = (row) => {
  const { original } = row;
  if (original.Location) {
    const { Location } = original;
    if (Location.CashCourier) {
      return Location.CashCourier.Name;
    } else if (Location.DebitAccount) {
      return (
        Location.DebitAccount.Name + " " + Location.DebitAccount.RoutingNumber
      );
    }
  }
  return "-";
};

export default formatPlatformsCashCourierCell;
