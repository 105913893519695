const locationEventKeys = {
  Timestamp: {
    Label: "Create Date",
    Type: "daterange",
  },
  "&Event": {
    Label: "Event",
    Type: "int",
    Options: [
      {
        Display: "Maintenance Mode Entered",
        Value: 0,
      },
      {
        Display: "Maintenance Mode Exited",
        Value: 1,
      },
      {
        Display: "Cash Collected",
        Value: 2,
      },
      {
        Display: "Kiosk Locked",
        Value: 3,
      },
      {
        Display: "Powered Off",
        Value: 4,
      },
      {
        Display: "Powered On",
        Value: 5,
      },
      {
        Display: "Rebooted",
        Value: 6,
      },
      {
        Display: "Unavailable",
        Value: 7,
      },
      {
        Display: "Available",
        Value: 8,
      },
      {
        Display: "Cash Deposited",
        Value: 9,
      },
      {
        Display: "Cash Cassette Removed",
        Value: 10,
      },
      {
        Display: "Cash Cassette Inserted",
        Value: 11,
      },
      {
        Display: "Transaction Initiated",
        Value: 12,
      },
      {
        Display: "Transaction Executed",
        Value: 13,
      },
      {
        Display: "Created",
        Value: 14,
      },
      {
        Display: "Activated",
        Value: 15,
      },
      {
        Display: "Deactivated",
        Value: 16,
      },
      {
        Display: "Installed",
        Value: 17,
      },
    ],
  },
};

export default locationEventKeys;
