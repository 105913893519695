// Modules
import React from "react";
import { Link } from "react-router-dom";
// Components
import Table from "./../../../Table/Table";
import {
  TradeTypePill,
  TxState,
} from "../../../Utilities/Components/TablePills";
// Helpers - Formatters
import { formatPrice, formatDateTime } from "../../../Utilities/Formatters";
import { reqStrProp } from "../../../Utilities/PropTypes";
// Permissions
import { PermissionsContext } from "../../../Permissions/PermissionContext";
import { checkPermission } from "../../../Permissions/CheckPermission";
import { ACCOUNT_VIEW } from "../../../Permissions/Permissions";

const LocationTransactions = ({ hash }) => {
  return (
    <PermissionsContext.Consumer>
      {(userPermiss) => (
        <Table
          columns={columnBuilder(userPermiss)}
          downloadable={true}
          endpoint={`locations/${hash}/transactions`}
          nested={true}
          tablename="Location Transactions"
        />
      )}
    </PermissionsContext.Consumer>
  );
};

LocationTransactions.propTypes = {
  hash: reqStrProp,
};

export default LocationTransactions;

const columnBuilder = (userPermissions) => {
  return [
    {
      Header: "Status",
      accessor: "StateDesc",
      Cell: ({ row: { original } }) =>
        TxState(original.State, original.StateDesc),
    },
    {
      Header: "Invoice #",
      Cell: ({ row: { original } }) => (
        <Link className="table-pill" to={`/transactions/${original.Hash}`}>
          {original.InvoiceCode}
        </Link>
      ),
    },
    {
      Header: "Date",
      width: 100,
      Cell: ({ row: { original } }) => formatDateTime(original.CreatedAt),
    },

    {
      Header: "Customer",
      style: { textAlign: "left " },
      Cell: ({ row: { original } }) => (
        <Link className="table-pill" to={`/accounts/${original.Account.Hash}`}>
          {original.Account.FullName}
        </Link>
      ),
      show: checkPermission(userPermissions, ACCOUNT_VIEW),
    },
    {
      Header: "Coin Amount",
      Cell: ({ row: { original } }) => {
        return (
          <React.Fragment>
            <p>{original.BaseQuantity}</p>
            <p>{TradeTypePill(original.Product.Symbol)}</p>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Fiat Amount",
      Cell: ({ row: { original } }) => formatPrice(original.Collected),
    },
  ];
};
