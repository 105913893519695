import React, { useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import classnames from 'classnames';
import { API } from '../Config/cfg.js';
import Markdown from 'markdown-to-jsx';
// import { SimpleMdeReact } from 'react-simplemde-editor';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import {
  GenericModal,
  Header,
  Loading,
  ToastError,
  ToastSuccess,
} from './../Utilities/Components';
import { formatDateTime } from '../Utilities/Formatters';

const TermsEditor = () => {
  const [data, setData] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchTos();
  }, []);

  const fetchTos = async () => {
    try {
      const { data } = await API.get('/agreements/tos');

      setData(data);
    } catch (error) {
      ToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateTos = async () => {
    setIsSubmitting(true);

    try {
      await API.put('/agreements/tos', { Body: data.Body });

      setIsModalOpen(false);
      setIsEditing(false);
      ToastSuccess('Terms of Service successfully updated');
    } catch (error) {
      ToastError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const setBody = Body => setData({ ...data, Body });

  const editorOptions = useMemo(() => {
    return {
      previewRender: plainText => {
        return renderToString(<Markdown>{plainText}</Markdown>);
      },
      showIcons: ['undo'],
      sideBySideFullscreen: false,
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <GenericModal
          modalIsOpen={isModalOpen}
          headerMsg="Edit Terms of Service"
          btnAction={updateTos}
          isSubmitting={isSubmitting}
          btnText="Update"
          closeModal={() => {
            setIsModalOpen(false);
          }}
        >
          This action UPDATES the current Terms of Service. Your changes will be
          made for all DigitalMint online properties. Are you sure you want to
          do this?
        </GenericModal>
        <div className="space-between">
          <Header title="Terms of Service" />

          <button
            type="button"
            className={classnames('btn primary-btn', {
              red: isEditing,
            })}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? 'Stop Editing' : 'Edit Terms'}
          </button>
        </div>

        <p>
          Updated By: <span className="item-label">{data.Author}</span>
        </p>
        <p className="mb-3">
          Updated At:{' '}
          <span className="item-label">{formatDateTime(data.CreatedAt)}</span>
        </p>

        {isEditing ? (
          <div className="mt-5">
            <SimpleMDE
              options={editorOptions}
              value={data.Body}
              onChange={setBody}
            />

            <button
              className="btn primary-btn"
              type="submit"
              onClick={() => setIsModalOpen(true)}
            >
              Update
            </button>
          </div>
        ) : (
          <div className="has-background-white p-6 mt-5">
            <Markdown>{data.Body}</Markdown>
          </div>
        )}
      </>
    );
  }
};

export default TermsEditor;
