import React from 'react';
import Icon from '../../Icons/Icon';
import PropTypes from 'prop-types';

const Header = ({ code, icon, testID, title }) => {
  return (
    <div className="columns is-vcentered is-mobile">
      {icon && (
        <Icon
          icon={icon}
          color="#486667"
          size="35"
          className="column is-narrow"
          rightMargin="12"
          leftMargin="12"
        />
      )}
      {code ? (
        <h1 data-testid={testID}>
          <span style={{ color: '#486667', marginLeft: '10px' }}>{code}</span>{' '}
          {title}
        </h1>
      ) : (
        <h1 data-testid={testID} className="column is-three-quarters-mobile">
          {title}
        </h1>
      )}
    </div>
  );
};

Header.defaultProps = {
  testID: 'xHeader',
};

Header.propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  testID: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default Header;
