import React from "react";

const AutoRefresh = ({ autoRefresh, autoRefreshTime, setAutoRefresh }) => {
  return (
    <React.Fragment>
      <label className="column is-narrow">
        {autoRefresh ? (
          <p>
            Auto refresh in <strong>{autoRefreshTime}s</strong>
          </p>
        ) : (
          <p>Auto refresh disabled</p>
        )}
      </label>
      <span className="column is-narrow">
        <label className="switch">
          <input
            type="checkbox"
            onChange={() => setAutoRefresh(!autoRefresh, 30)}
            checked={autoRefresh}
          />
          <span className="slider round"></span>
        </label>
      </span>
    </React.Fragment>
  );
};

export default AutoRefresh;
