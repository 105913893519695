import Data from './Data';
import ACHEmailsData from './ACHEmails';
import AddressData from './Address';
import CommissionEmailsData from './CommissionEmails';
import ComplianceOperatorData from './ComplianceOperator';
import GroupCommissionsData from './GroupCommissions';
import PermissionsData from './Permissions';
import PhoneNumberData from './PhoneNumber';

export default class GroupData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      Children: { type: 'array' },
      GroupTransactionStatistics: { type: 'object' },
      InheritedSettings: { type: 'object' },
      MemberCount: { type: 'number' },
      Name: { type: 'string' },
      OperatingStates: { type: 'array' },
      Parent: { type: 'object' },
      Settings: { type: 'object' },
      TaxpayerID: { type: 'string' },
    };
  }
  hasOne() {
    return {
      Address: AddressData,
      ComplianceOperator: ComplianceOperatorData,
      PhoneNumber: PhoneNumberData,
    };
  }

  hasMany() {
    return {
      ACHEmails: ACHEmailsData,
      CommissionEmails: CommissionEmailsData,
      GroupCommissions: GroupCommissionsData,
      Permissions: PermissionsData,
    };
  }
}
