import React, { Component } from 'react';
import Can from './../Permissions/Can';
import Icon from '../Icons/Icon';
import { ICONS } from '../Icons/IconsPath';

class NavDropdown extends Component {
  state = {
    open: false,
  };

  toggleList = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { open } = this.state;
    const { link, onClick, navComponent, title } = this.props;
    return (
      <React.Fragment>
        {/* The header should be permissioned, but not link somewhere. */}
        <Can key={link.path} do={link.permission}>
          <button className="links" onClick={() => this.toggleList()}>
            <Icon icon={link.icon} color="#f9f6f2" size="17" rightMargin="15" />
            {title}

            <p className="column has-text-right is-paddingless is-inline">
              {open ? (
                <Icon
                  icon={ICONS.UPARROW}
                  color="#f9f6f2"
                  size="16"
                  leftMargin="5"
                />
              ) : (
                <Icon
                  icon={ICONS.DOWNARROW}
                  color="#f9f6f2"
                  size="16"
                  leftMargin="5"
                />
              )}
            </p>
          </button>
        </Can>
        {open &&
          link.children.map(child => (
            <Can key={child.path} do={child.permission} onClick={onClick}>
              {navComponent(child, onClick, true, true)}
            </Can>
          ))}
      </React.Fragment>
    );
  }
}

export default NavDropdown;
