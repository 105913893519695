import React from 'react';
import columnBuilder from './columnBuilder';
import Table from './../Table/Table';
import { ACCOUNT_INVITE } from './../Permissions/Permissions';

const Accounts = ({ history }) => {
  return (
    <Table
      history={history}
      columns={columnBuilder}
      endpoint="accounts"
      filter={true}
      tablename="Accounts"
      downloadable={true}
      actions={[
        {
          name: 'Create Employee Invite',
          link: '/accounts/employee-invite',
          permission: ACCOUNT_INVITE,
        },
        {
          name: 'Create Service Account',
          link: '/accounts/service',
          permission: ACCOUNT_INVITE,
        },
      ]}
    />
  );
};

export default Accounts;
