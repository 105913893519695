import Data from './Data';

export default class PermissionsData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      Key: { type: 'string' },
      Name: { type: 'string' },
      Description: { type: 'string' },
    };
  }
}
