import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import NavLinks from './NavLinks';
// Styles
import './MobileNavbar.css';
import Icon from '../Icons/Icon';
import { ICONS } from '../Icons/IconsPath';
// Images
import dm from './../images/dm-alternative.svg';

class MobileNavbar extends Component {
  state = {
    isOpen: false,
    isSettingsOpen: false,
  };
  onDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSettingsDropdown = () => {
    this.setState({ isSettingsOpen: !this.state.isSettingsOpen });
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { routes, navComponent, logout, data, history } = this.props;

    const { isOpen, isSettingsOpen } = this.state;
    return (
      <React.Fragment>
        <div className="is-hidden-desktop is-hidden-tablet column mobile-navbar is-vcentered">
          <div className="menu-bar">
            <img
              className="mobile-nav-logo"
              src={dm}
              alt=""
              width={30}
              height={30}
            />
            <button className="menu-icon" onClick={this.onDropdown}>
              <Icon icon={ICONS.MENU} color="#fff" size="28" />
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="is-hidden-desktop is-hidden-tablet column mobile-navbar-menu">
            <button className="profile" onClick={this.onSettingsDropdown}>
              <div>
                <h3>
                  {data.FirstName} {data.LastName}
                </h3>
                <p>{data.Email}</p>
              </div>
              <Icon
                icon={isSettingsOpen ? ICONS.UPARROW : ICONS.DOWNARROW}
                color="#fff"
                size="15"
              />
            </button>
            {isSettingsOpen && (
              <ul className="column">
                <li>
                  <NavLink to={`/accounts/${data.Hash}`} onClick={this.onClose}>
                    View My Account
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/settings`} onClick={this.onClose}>
                    Account Settings
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/documents`} onClick={this.onClose}>
                    Documents
                  </NavLink>
                </li>
              </ul>
            )}
            <NavLinks
              routes={routes}
              navComponent={navComponent}
              onClick={this.onClose}
            />
            <button
              className="btn-navbar mt-4"
              onClick={() => {
                logout(history);
              }}
            >
              <p>Logout</p>
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(MobileNavbar);
