// Modules
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
// Doc Components
import DocUploadForm from './DocUploadForm';
// Components
import {
  GenericModal,
  GoBack,
  Header,
  ToastError,
  ToastSuccess,
} from '../Utilities/Components';
// Icons
import Icon from '../Icons/Icon';
import { ICONS } from '../Icons/IconsPath';
// Conifig
import { API } from '../Config/cfg.js';
// Styles
import './upload.scss';

class DocUpload extends Component {
  state = {
    document: [],
    uploadLoading: false,
    error: null,
    modalIsOpen: false,
  };

  onDocDrop = document => {
    if (document && document[0]) {
      if (document[0].size < 5242880) {
        this.parseToBase64(document[0]);
      } else {
        ToastError(`File Size cannot exceed 5mb`);
      }
    } else {
      ToastError('No document found');
    }
  };

  parseToBase64 = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ document: reader.result });
    };
    reader.onerror = () => ToastError('Could not upload document');
  };

  putDocument = (values, actions) => {
    const { fromLocation } = this.props.location.state;
    if (fromLocation === 'Investigation') {
      this.putInvestigationDocument(values, actions);
    } else if (fromLocation === 'Accounts') {
      this.putAccountDocument(values, actions);
    } else if (fromLocation === 'Subpoena') {
      this.putSubpoenaDoc(values, actions);
    } else if (fromLocation === 'Locations') {
      this.putLocationDoc(values, actions);
    } else if (fromLocation === 'Groups') {
      this.putGroupDoc(values, actions);
    } else if (fromLocation === 'EDD') {
      this.putEDDDoc(values, actions);
    }
  };

  putInvestigationDocument = (values, actions) => {
    const { hash, history } = this.props;
    const { document } = this.state;
    this.setState({ error: null, uploadLoading: true });
    let req = {
      Name: values.Name,
      Type: parseInt(values.Type),
      Data: document,
    };

    API.put(`/compliance/investigations/${hash}/upload`, req)
      .then(res => {
        if (history && history.location.key) {
          history.goBack();
        }
        ToastSuccess(`Investigation Document Uploaded`);
        this.setState({ uploadLoading: false });
      })
      .catch(error => {
        this.setState({ error: error.response.data });
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  putAccountDocument = (values, actions) => {
    const { hash, history } = this.props;
    const { document } = this.state;
    this.setState({ error: null, uploadLoading: true });
    let req = {
      Name: values.Name,
      Type: parseInt(values.Type),
      Data: document,
    };

    API.put(`/accounts/${hash}/documents`, req)
      .then(res => {
        if (history && history.location.key) {
          history.goBack();
        }
        ToastSuccess(`Account Document Uploaded`);
        this.setState({ uploadLoading: false });
      })
      .catch(error => {
        this.setState({ error: error.response.data });
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  postCTRDoc = () => {
    const { hash, history } = this.props;
    const { document } = this.state;
    this.setState({
      error: undefined,
      uploadLoading: true,
    });
    let req = {
      Data: document,
    };

    if (document.length === 0) {
      return ToastError(`A document must be uploaded before submitting`);
    }

    API.post(`/compliance/ctrs/${hash}`, req)
      .then(res => {
        if (history && history.location.key) {
          history.goBack();
        }
        ToastSuccess(`CTR Document Uploaded`);
        this.setState({ modalIsOpen: false, uploadLoading: false });
      })
      .catch(error => {
        this.setState({ error: error.response.data });
        ToastError(error);
      });
  };

  putSubpoenaDoc = (values, actions) => {
    // PUT to /compliance/subpoenas/:uuid/documents
    const { hash, history } = this.props;
    const { document } = this.state;
    this.setState({ error: null, uploadLoading: true });
    let req = {
      Name: values.Name,
      Type: parseInt(values.Type),
      Data: document,
    };

    API.put(`/compliance/subpoenas/${hash}/documents`, req)
      .then(res => {
        if (history && history.location.key) {
          history.goBack();
        }
        ToastSuccess(`Subpoenas Document Uploaded`);
        this.setState({ uploadLoading: false });
      })
      .catch(error => {
        this.setState({ error: error.response.data });
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  putEDDDoc = (values, actions) => {
    const { hash, history } = this.props;
    const { document } = this.state;
    this.setState({ error: null, uploadLoading: true });
    let req = {
      Name: values.Name,
      Type: parseInt(values.Type),
      Data: document,
    };

    API.put(`/compliance/edds/${hash}/upload`, req)
      .then(res => {
        if (history && history.location.key) {
          history.goBack();
        }
        ToastSuccess(`EDD Document Uploaded`);
        this.setState({ uploadLoading: false });
      })
      .catch(error => {
        this.setState({ error: error.response.data });
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  putLocationDoc = (values, actions) => {
    const { hash, history } = this.props;
    const { document } = this.state;
    this.setState({ error: null, uploadLoading: true });
    let req = {
      Name: values.Name,
      Type: parseInt(values.Type),
      Data: document,
    };

    API.put(`/locations/${hash}/documents`, req)
      .then(res => {
        if (history && history.location.key) {
          history.goBack();
        }
        ToastSuccess(`Location Document Uploaded`);
        this.setState({ uploadLoading: false });
      })
      .catch(error => {
        this.setState({ error: error.response.data });
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  putGroupDoc = (values, actions) => {
    const { hash, history } = this.props;
    const { document } = this.state;
    this.setState({ error: null, uploadLoading: true });
    let req = {
      Name: values.Name,
      Type: parseInt(values.Type),
      Data: document,
    };

    API.put(`/groups/${hash}/documents`, req)
      .then(res => {
        if (history && history.location.key) {
          history.goBack();
        }
        ToastSuccess(`Groups Document Uploaded`);
        this.setState({ uploadLoading: false });
      })
      .catch(error => {
        this.setState({ error: error.response.data });
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  title = fromLocation => {
    const { accountName } = this.props.location.state;
    switch (fromLocation) {
      case 'CTR':
        return '- CTR';
      case 'Investigation':
        return '- Investigation';
      case 'EDD':
        return '- EDD';
      case 'Accounts':
        return `- ${accountName}`;
      case 'Locations':
        return `- Location`;
      case 'Groups':
        return `- Group`;
      default:
        return '- Subpoena';
    }
  };

  render() {
    const { document, uploadLoading, error, modalIsOpen } = this.state;
    const { hash, location } = this.props;
    const { fromLocation } = location.state;

    const defaultPath =
      fromLocation === 'Accounts' ? `/accounts/${hash}` : '/compliance';
    const defaultName = fromLocation === 'Accounts' ? 'Account' : 'Compliance';
    const title = this.title(fromLocation);

    return (
      <>
        <GoBack
          history={this.props.history}
          defaultPath={defaultPath}
          defaultName={defaultName}
        />
        <Header code="Document Upload" title={title} />
        <div className="form-outline form-create">
          <div className="columns ">
            <section className="column">
              <Dropzone
                accept="application/pdf, text/csv"
                className="dropzone"
                onDrop={this.onDocDrop}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone-container" {...getRootProps()}>
                    <input className="dropzone-input" {...getInputProps()} />
                    <div className="display">
                      {document.length >= 1 ? (
                        <div>
                          {uploadLoading && !error && (
                            <div className="lds-dual-ring" />
                          )}
                          <Icon
                            icon={ICONS.FILE}
                            size="50"
                            color="#009245"
                            rightMargin="0"
                          />
                          <p style={{ marginTop: 10 }}>
                            Your document is ready to be uploaded
                          </p>
                        </div>
                      ) : (
                        <div>
                          <Icon
                            icon={ICONS.UPLOAD}
                            size="30"
                            color="#009245"
                            rightMargin="0"
                          />
                          <p>Upload Document</p>
                          <p>
                            <small>File limit 5 MB (.pdf, .csv)</small>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </section>
            {fromLocation === 'CTR' ? (
              <React.Fragment>
                <GenericModal
                  isSubmitting={uploadLoading}
                  modalIsOpen={modalIsOpen}
                  closeModal={this.closeModal}
                  headerMsg="ATTENTION: Confirm CTR Document"
                  viewMsg="By uploading this CTR document you are confirming that it has already been uploaded to the correct government sites."
                  btnMsg="By clicking 'Upload Document' the document will be stored in DigitalMint's servers for record keeping."
                  btnText="Upload Document"
                  btnAction={this.postCTRDoc}
                />

                <div className="column" style={{ marginTop: '10%' }}>
                  <button
                    type="submit"
                    className="btn primary-btn"
                    onClick={this.openModal}
                  >
                    Upload Document
                  </button>
                </div>
              </React.Fragment>
            ) : (
              <div className="column">
                <DocUploadForm
                  putDocument={this.putDocument}
                  type={fromLocation}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default DocUpload;
