// Modules
import React from "react";
// Components
import { ListGroup, ListGroupItem } from "../../../Utilities/Components";
// Helpers - Formatters
import { formatDate } from "../../../Utilities/Formatters";

const AlertDetails = ({ props }) => {
  return (
    <ListGroup title="Alert Details">
      <ListGroupItem name="Alert Number" value={props.Code} />
      <ListGroupItem name="Scenario" value={props.Scenario} />
      <ListGroupItem
        name="Alert Date"
        value={formatDate(props.DiscoveryDate)}
      />
      <ListGroupItem name="Country of Origin" value={props.Country?.Code} />
    </ListGroup>
  );
};

export default AlertDetails;
