// Modules
import React, { Component } from "react";

import { Link } from "react-router-dom";
// Components
import { GenericForm, FormikContext } from "../Forms/Form";
import { FormSelect } from "../Forms/FormInputs";
import { Loading } from "../Utilities/Components";
// Configs
import { API } from "../Config/cfg.js";

class AddToGroup extends Component {
  state = {
    data: {},
    errors: {},
    loading: true,
  };

  componentDidMount() {
    this.getGroups();
  }

  getGroups = () => {
    API.get("/groups?all=true")
      .then((res) => {
        this.setState({
          data: res.data.Data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          errors: error.response.data,
        });
      });
  };

  render() {
    const { hash, history } = this.props;
    const { data, errors, loading } = this.state;

    if (loading) {
      return <Loading errors={errors} />;
    }
    return (
      <GenericForm
        endpoint={`accounts/${hash}/groups`}
        setValues={{
          GroupHash: "",
        }}
        formTitle="Add to New Group"
        subTitle={null}
        goBack={{
          show: true,
          setHistory: history,
          setDefaultPath: "/accounts",
          setDefaultName: "Accounts",
        }}
        toastMsg="Account has been successfully added to group "
      >
        <div className="columns">
          <FormikContext.Consumer>
            {(props) => (
              <div className="column is-4 ">
                <FormSelect
                  errors={props.errors}
                  touched={props.touched}
                  label="Group"
                  name="GroupHash"
                  placeholder="Select a Group"
                  options={
                    <React.Fragment>
                      {data.map((item) => (
                        <option key={item.Hash} value={item.Hash}>
                          {item.Name}
                        </option>
                      ))}
                    </React.Fragment>
                  }
                />
              </div>
            )}
          </FormikContext.Consumer>
          <div className="column">
            <br />
            <p>
              Need to create a new group?{" "}
              <Link to="/groups/create-group">Click here</Link>
            </p>
          </div>
        </div>
      </GenericForm>
    );
  }
}

export default AddToGroup;
