import React from "react";
import { Form } from "formik";
// Components
import { FormInput, FormSelect } from "../../../Forms/FormInputs";
import Can from "../../../Permissions/Can";
import {
  ACCOUNT_CONTACT_FREEFORM,
  ACCOUNT_CONTACT,
} from "../../../Permissions/Permissions";

const CONTACT_EMAILS = [
  "support@digitalmint.io",
  "accounting@digitalmint.io",
  "notifications@digitalmint.io",
];

export default function ContactForms({
  values,
  errors,
  touched,
  templates,
  types,
}) {
  return (
    <Form className="columns form-outline is-centered">
      <div className="column is-11">
        <FormSelect
          errors={errors}
          touched={touched}
          label="Notification Type"
          name="type"
          options={
            <>
              {types.map((item) => (
                <Can
                  do={
                    item.hash === "custom"
                      ? ACCOUNT_CONTACT_FREEFORM
                      : ACCOUNT_CONTACT
                  }
                >
                  <option key={item.hash} value={item.hash}>
                    {item.name}
                  </option>
                </Can>
              ))}
            </>
          }
        />

        {values.type === "sms" && (
          <>
            <FormSelect
              errors={errors}
              touched={touched}
              label="SMS Message"
              name="smsMsg"
              options={
                <>
                  <option value="" key="placeholder">
                    Select sms message
                  </option>
                  {templates.map((item) => (
                    <option key={item.Hash} value={item.Message}>
                      {item.Name}
                    </option>
                  ))}
                </>
              }
            />
            <FormInput
              errors={errors}
              touched={touched}
              label="Message Preview"
              type="text"
              name="smsMsg"
              component="textarea"
              placeholder="Select a template to view"
              readOnly
            />
          </>
        )}

        {values.type === "email" && (
          <>
            <FormSelect
              errors={errors}
              touched={touched}
              label="From"
              name="emailFrom"
              options={
                <>
                  <option value="" key="placeholder">
                    Select Email
                  </option>
                  {CONTACT_EMAILS.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </>
              }
            />
            <FormInput
              errors={errors}
              touched={touched}
              label="Subject"
              name="subject"
              type="text"
              placeholder="Subject"
            />
            <FormInput
              errors={errors}
              touched={touched}
              label="Email Message"
              type="text"
              name="emailMsg"
              component="textarea"
              placeholder="Enter message to customer..."
            />
          </>
        )}

        {values.type === "custom" && (
          <FormInput
            errors={errors}
            touched={touched}
            label={`Freeform Message ${`character count: ${
              values.freeformMsg.split("").length
            }`}`}
            type="text"
            name="freeformMsg"
            component="textarea"
            placeholder="Enter freeform sms message.."
          />
        )}

        <p>
          By clicking "Submit {values.type.toUpperCase()}" you are confirming
          that the message above is the correct and you want to send it to this
          customer.
        </p>
      </div>
    </Form>
  );
}
