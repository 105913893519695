// Modules
import React, { Component } from "react";
// Settings Components
import Rates from "./GlobalRates";
// Components
import { GenericForm } from "../Forms/Form";
import { FormInput, FormSelect } from "../Forms/FormInputs";
import { Loading, RatesOptions } from "./../Utilities/Components";
// Configs
import { API } from "../Config/cfg.js";

class BusinessSettings extends Component {
  state = {
    loading: true,
    settings: {},
    errors: {},
  };

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    API.get("/settings")
      .then((res) => {
        this.setState({
          settings: res.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          errors: error.response.data,
        });
      });
  };

  render() {
    const feeRateOptions = <RatesOptions from={4} to={24} />;
    const spotOptions = <RatesOptions from={0} to={60} intervals={0.0025} />;
    const { loading, errors, settings } = this.state;
    const {
      AccountingEmail,
      CustomerSupportNumber,
      NumbersToNotify,
      SpotInflationRate,
      MarkupRate1,
      MarkupThreshold1,
      MarkupRate2,
      MarkupThreshold2,
      MarkupRate3,
      MarkupThreshold3,
      MarkupRate4,
      MarkupThreshold4,
      MarkupRate5,
      MarkupThreshold5,
      MarkdownRate1,
      MarkdownThreshold1,
      MarkdownRate2,
      MarkdownThreshold2,
      MarkdownRate3,
      MarkdownThreshold3,
      MarkdownRate4,
      MarkdownThreshold4,
      MarkdownRate5,
      MarkdownThreshold5,
    } = settings;

    if (loading) {
      return <Loading errors={errors} />;
    }

    return (
      <GenericForm
        endpoint="settings"
        postReq={true}
        getData={this.getSettings}
        setValues={{
          AccountingEmail,
          CustomerSupportNumber,
          NumbersToNotify,
          SpotInflationRate,
          MarkupRate1,
          MarkupThreshold1,
          MarkupRate2,
          MarkupThreshold2,
          MarkupRate3,
          MarkupThreshold3,
          MarkupRate4,
          MarkupThreshold4,
          MarkupRate5,
          MarkupThreshold5,
          MarkdownRate1,
          MarkdownThreshold1,
          MarkdownRate2,
          MarkdownThreshold2,
          MarkdownRate3,
          MarkdownThreshold3,
          MarkdownRate4,
          MarkdownThreshold4,
          MarkdownRate5,
          MarkdownThreshold5,
        }}
        customValidations={(values) => {
          let errors = {};
          return errors;
        }}
        goBack={{
          show: false,
          setHistory: null,
        }}
        formTitle="Business Settings"
        subTitle={null}
        toastMsg="Business settings has been updated"
      >
        <h2 className="form-title">Transaction Settings</h2>
        <div className="columns">
          <div className="column">
            <FormInput
              label="Accounting Email"
              name="AccountingEmail"
              type="email"
              placeholder="Email"
            />
          </div>
          <div className="column">
            <FormInput
              label="Customer Support Number"
              name="CustomerSupportNumber"
              type="text"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-6">
            <FormInput
              label="Numbers To Notify"
              name="NumbersToNotify"
              type="text"
              placeholder="Enter Numbers to Notify"
            />
          </div>
          <div className="column is-6">
            <FormSelect
              label="Spot Volatility Rate"
              name="SpotInflationRate"
              placeholder="Select spot inflation rate"
              options={spotOptions}
            />
          </div>
        </div>
        <Rates feeRates={feeRateOptions} />
      </GenericForm>
    );
  }
}

export default BusinessSettings;
