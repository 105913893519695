import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// Components
import { Form, Formik } from 'formik';
import { FormInput } from '../../Forms/FormInputs';
import { GenericModal } from '../../Utilities/Components';

class OverturnHoldModal extends Component {
  state = { selectedHold: undefined };

  setRouteHash = (type, hash, accountHash) => {
    let routeHash;
    if (type === 1 || type === 5 || type === 6) {
      routeHash = hash;
    } else {
      routeHash = accountHash;
    }
    return routeHash;
  };

  setPathname = Type => {
    switch (Type) {
      case 1:
        return '/accounts/review-id';
      case 5:
      case 6:
      case 7:
        return `/accounts/anti-fraud-review`;
      default:
        return '/accounts/upload-image';
    }
  };

  buttonAction = callback => {
    const { selectedHold } = this.state;
    if (
      selectedHold &&
      (selectedHold.Reason === 3 ||
        selectedHold.Reason === 4 ||
        selectedHold.Reason === 7 ||
        selectedHold.Reason === 8)
    ) {
      callback();
    } else {
      this.pushHistory();
    }
  };

  pushHistory = () => {
    const { selectedHold } = this.state;
    const { hash, history } = this.props;
    history.push({
      pathname: this.setPathname(selectedHold.Reason),
      hash: this.setRouteHash(
        selectedHold.Reason,
        selectedHold.ReviewHash,
        hash
      ),
      state: {
        TypeText: selectedHold.ReasonText,
        type: selectedHold.Reason,
      },
    });
  };

  render() {
    const { selectedHold } = this.state;
    const { isOpen, putAccount, holds, closeModal } = this.props;
    return (
      <Formik
        initialValues={{
          Narrative: '',
          Type: '',
        }}
        onSubmit={async values => {
          switch (selectedHold.Reason) {
            case 3:
              values.Type = 'OFAC';
              break;
            case 4:
              values.Type = 'SSN';
              break;
            case 7:
              values.Type = 'PLAID';
              break;
            case 8:
              values.Type = 'TPIV';
              break;
            default:
              values.Type = null;
          }
          await putAccount(values);
        }}
        children={({ touched, errors, handleSubmit, isSubmitting }) => (
          <GenericModal
            modalIsOpen={isOpen}
            closeModal={() => {
              closeModal('isOverturnHoldOpen');
            }}
            viewMsg={'Select a hold to resolve'}
            headerMsg="Overturn Hold"
            btnText={'Submit'}
            btnAction={() => this.buttonAction(handleSubmit)}
            isSubmitting={isSubmitting}
          >
            <div className="columns form-outline is-centered">
              <div className="column is-11">
                <select
                  onChange={e => {
                    const selectedHold = JSON.parse(e.target.value);
                    this.setState({ selectedHold });
                  }}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select a Hold
                  </option>
                  {holds &&
                    holds.map(item => (
                      <option
                        key={item.ReviewHash}
                        value={JSON.stringify(item)}
                      >
                        {item.ReasonText}
                      </option>
                    ))}
                </select>
                {selectedHold &&
                (selectedHold.Reason === 3 ||
                  selectedHold.Reason === 4 ||
                  selectedHold.Reason === 7 ||
                  selectedHold.Reason === 8) ? (
                  <Form>
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Narrative"
                      name="Narrative"
                      component="textarea"
                      placeholder="Enter Narrative..."
                    />
                  </Form>
                ) : null}
              </div>
            </div>
          </GenericModal>
        )}
      />
    );
  }
}

export default withRouter(OverturnHoldModal);
