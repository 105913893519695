// Modules
import React from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
// Components
import NotesTable from "./../../Notes/NotesTable";
import TxCommissions from "./Tabs/TxCommissions";
import TransactionHistory from "./Tabs/TransactionHistory";
// Helpers - Formatters
import { getDefaultTab, setDefaultTab } from "../../Utilities/Formatters";
// Permissions
import { ACCOUNTING_GROSS_COMMISS } from "../../Permissions/Permissions";
import { PermissionsContext } from "../../Permissions/PermissionContext";
import TabCan from "../../Permissions/TabCan";

const TabsContainer = (props) => {
  const { Account, Hash } = props.data;
  return (
    <PermissionsContext.Consumer>
      {(userPermiss) => (
        <Tabs
          className="tabs-container"
          defaultIndex={getDefaultTab("tx", 0)}
          onSelect={(index) => setDefaultTab("tx", index)}
        >
          <TabList className="columns tab-list">
            <Tab className="column tab">History</Tab>
            <Tab className="column tab">Notes</Tab>
            {TabCan(userPermiss, ACCOUNTING_GROSS_COMMISS) ? (
              <Tab className="column tab">Commissions</Tab>
            ) : null}
          </TabList>

          <TabPanel>
            <TransactionHistory hash={Hash} callAPI={props.callAPI} />
          </TabPanel>
          <TabPanel>
            <NotesTable
              history={props.history}
              txHash={Hash}
              accountHash={Account.Hash}
              table="Transaction Notes"
            />
          </TabPanel>
          {TabCan(userPermiss, ACCOUNTING_GROSS_COMMISS) ? (
            <TabPanel>
              <TxCommissions hash={Hash} />
            </TabPanel>
          ) : null}
        </Tabs>
      )}
    </PermissionsContext.Consumer>
  );
};

export default TabsContainer;
