import React from 'react';
import { Form, Formik } from 'formik';
// Components
import { FormInput } from '../../../Forms/FormInputs';
import { GenericModal } from '../../../Utilities/Components';

const RemoveBanModal = ({ isOpen, putAccount, account, closeModal }) => {
  return (
    <Formik
      initialValues={{
        Narrative: '',
      }}
      validate={values => {
        let errors = {};
        if (!values.Narrative) {
          errors.Narrative = 'is required';
        }
        return errors;
      }}
      onSubmit={async values => {
        await putAccount({ Narrative: values.Narrative });
        values.Narrative = '';
      }}
      children={({ touched, errors, handleSubmit, isSubmitting }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={() => {
            closeModal('isRemoveBanOpen');
          }}
          viewMsg={account}
          headerMsg="Remove Account Ban"
          btnText="Submit"
          btnAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <Form className="columns form-outline is-centered">
            <div className="column is-11">
              <FormInput
                errors={errors}
                touched={touched}
                label="Narrative"
                name="Narrative"
                component="textarea"
                placeholder="Enter Narrative..."
              />
            </div>
          </Form>
        </GenericModal>
      )}
    />
  );
};

export default RemoveBanModal;
