import React from 'react';
// Components
import { GenericModal } from '../../../Utilities/Components';

const RefreshPinModal = ({
  newPin,
  username,
  isOpen,
  postRefreshPin,
  hash,
  isSubmitting,
  closeModal,
}) => {
  const btnText = newPin ? 'Done' : 'Refresh Pin';
  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={() => {
        closeModal('isRefreshOpen');
      }}
      headerMsg="Warning: Refreshing Pin"
      viewMsg={
        newPin ? (
          <React.Fragment>
            <div class="columns is-centered">
              <div class="column">
                <p>
                  This action returns a new PIN and the teller's username.
                  Please make sure to give the teller their new PIN.
                </p>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column has-text-centered">
                New PIN: <code style={{ fontSize: 20 }}>{newPin}</code>
              </div>
              <div class="column has-text-centered">
                Username: <code style={{ fontSize: 20 }}>{username}</code>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div class="columns is-centered">
            <div class="column">
              This action will REFRESH the teller account, you will be provided
              a new pin as well as a username if one does not already exist.
            </div>
          </div>
        )
      }
      btnText={btnText}
      btnAction={async () => {
        if (newPin) {
          closeModal('isRefreshOpen');
        } else {
          await postRefreshPin(hash);
        }
      }}
      isSubmitting={isSubmitting}
    />
  );
};

export default RefreshPinModal;
