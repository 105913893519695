import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Dropdown from './../Utilities/Components/Dropdown';
import Can from './../Permissions/Can';
import Icon from './../Icons/Icon';
import { ICONS } from './../Icons/IconsPath';
import { ACCOUNT_VIEW } from '../Permissions/Permissions';
import PropTypes from 'prop-types';

const DropdownMenu = ({ Account, logout, history, location }) => {
  const avatarData =
    Account && Account?.FirstName && Account?.LastName ? (
      `${Account.FirstName.substr(0, 1)}${Account.LastName.substr(0, 1)}`
    ) : (
      <Icon icon={ICONS.USER} color="#ffffff" size="20" />
    );

  const tip = {
    id: 'profile',
    message: 'Profile',
    place: 'bottom',
  };

  const toggleDropdown = () => {
    const ev = new Event('click.onCloseDropdown');

    window.dispatchEvent(ev);
  };

  if (!Account) return false;

  return (
    <>
      <Dropdown tip={tip} className="profile" title={avatarData} small>
        <div className="menu" data-for="profile" data-tip="Profile">
          <div className="menu-header">
            <h2>
              {Account.FirstName && Account.LastName
                ? `${Account.FirstName} ${Account.LastName}`
                : 'Service Account'}
            </h2>
            <p>{Account.Email}</p>
          </div>

          <div className="menu-options">
            <Link
              to={{
                pathname: `/accounts/${Account.Hash}`,
                key: { location: location.key },
              }}
              className="menu-item"
              data-testid="account"
              onClick={toggleDropdown}
            >
              <Can do={ACCOUNT_VIEW}>
                <Icon icon={ICONS.USER} color="#272a39" size="20" />
                <p>Account</p>
              </Can>
            </Link>
            <Link
              to="/settings"
              className="menu-item"
              data-testid="settings"
              onClick={toggleDropdown}
            >
              <Icon icon={ICONS.SETTINGS} color="#272a39" size="20" />
              <p>Settings</p>
            </Link>
            <Can do={ACCOUNT_VIEW}>
              <Link
                to="/documents"
                className="menu-item"
                data-testid="documents"
                onClick={toggleDropdown}
              >
                <Icon icon={ICONS.FILE} color="#272a39" size="20" />
                <p>Documents</p>
              </Link>
            </Can>
          </div>
          <button
            className="btn primary-btn menu-logout"
            data-testid="logout"
            onClick={() => {
              logout(history);
              toggleDropdown();
            }}
          >
            Logout
          </button>
        </div>
      </Dropdown>
    </>
  );
};

DropdownMenu.defaultProps = {
  Account: {},
  logout: () => {},
};

DropdownMenu.propTypes = {
  Account: PropTypes.object,
  logout: PropTypes.func.isRequired,
};

// TODO: Remove this dependency when upgrading react router
export default withRouter(DropdownMenu);
