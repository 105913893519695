import React, { useState, useEffect } from 'react';
import FundingAddress from './FundingAddress';
import { Header, Loading, ToastError } from 'src/Utilities/Components';
import { ICONS } from 'src/Icons/IconsPath';
import { useApi } from 'src/Services/Api';

export default function FundingWallet() {
  const [coins, setCoins] = useState([]);

  useEffect(() => {
    handleBalances();
  }, []);

  const [handleBalances, isLoading] = useApi({
    method: 'get',
    url: '/accounting/funding/balances',
    onSuccess: data => {
      setCoins(data.Coins);
    },
    onError: error => {
      ToastError(error);
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header icon={ICONS.USD} title={'Funding Wallet'} />
      <div className="columns is-multiline">
        {Object.entries(coins).map(([coin, info]) => {
          return <FundingAddress key={coin} Symbol={coin} {...info} />;
        })}
      </div>
    </>
  );
}
