import React from "react";

const EddMetaData = ({ data }) => {
  return (
    <div className="columns is-centered is-vcentered metadata-container">
      <div className="column">
        <div className="form-outline form-create">
          {data.OpenReviews}
          <h4 className="item-label">Open Reviews</h4>
        </div>
      </div>
      <div className="column">
        <div className="form-outline form-create">
          {data.UpcomingActivations}
          <h4 className="item-label">Upcoming Reviews</h4>
        </div>
      </div>
    </div>
  );
};

export default EddMetaData;
