// Modules
import React from "react";
import columnBuilder from "./../../Transactions/columnBuilder";
// Components
import Table from "./../../Table/Table";
// Permissions
import { PermissionsContext } from "../../Permissions/PermissionContext";

const GroupTransactions = ({ hash }) => {
  return (
    <PermissionsContext.Consumer>
      {(userPermiss) => (
        <Table
          columns={columnBuilder(userPermiss, "/groups")}
          downloadable={true}
          endpoint={`groups/${hash}/transactions`}
          tablename="Transactions"
          nested={true}
        />
      )}
    </PermissionsContext.Consumer>
  );
};

export default GroupTransactions;
