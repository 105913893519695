import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ToastError, CopyButton } from 'src/Utilities/Components';
import Icon from 'src/Icons/Icon';
import { useApi } from 'src/Services/Api';
import COIN_ICONS from 'src/Utilities/Data/CoinIcons';

const FundingAddress = ({
  Symbol,
  Balance,
  UnconfirmedBalance,
  Fee,
  TransactionCount,
}) => {
  const [address, setAddress] = useState('');
  const icon = useMemo(() => COIN_ICONS[Symbol].icon, [Symbol]);
  const iconColor = useMemo(() => COIN_ICONS[Symbol].color, [Symbol]);

  useEffect(() => {
    handleAddress();
  }, []);

  const [handleAddress] = useApi({
    method: 'get',
    url: `/accounting/funding/address/${Symbol}`,
    onSuccess: data => {
      setAddress(data.Address);
    },
    onError: error => {
      ToastError(error);
    },
  });

  const convertSymbol = name => {
    if (name !== null && name.endsWith('USD')) {
      return name.replace('USD', '');
    }
    return name;
  };

  return (
    <div className="column is-half">
      <div className="form-outline form-create">
        <div className="columns">
          <div className="column is-5">
            <div className="columns is-mobile is-vcentered">
              <div className="has-text-centered">
                <Icon icon={icon} color={iconColor} size="32" />
              </div>
              <h3 className="column" data-testid="Symbol">
                {Symbol}
              </h3>
            </div>
          </div>
          <div className="column">
            <div className="columns is-vcentered">
              <span className="column item-label is-paddingless">Balance</span>
              <div className="column is-paddingless" data-testid="Balance">
                {Balance}
              </div>
            </div>
            <div className="columns is-vcentered">
              <span className="column item-label is-paddingless">
                Unconfirmed Balance
              </span>
              <div
                className="column is-paddingless"
                data-testid="UnconfirmedBalance"
              >
                {UnconfirmedBalance}
              </div>
            </div>
            <div className="columns is-vcentered">
              <span className="column item-label is-paddingless">
                Transaction Count
              </span>
              <div
                className="column is-paddingless"
                data-testid="TransactionCount"
              >
                {TransactionCount}
              </div>
            </div>
            <div className="columns is-vcentered">
              <span className="column item-label is-paddingless">Fee</span>
              <div className="column is-paddingless" data-testid="Fee">
                {Fee}
              </div>
            </div>
          </div>
        </div>
        <div key={address}>
          <strong>
            <p data-testid="Address">{address || 'Could Not Find Address'}</p>
            <CopyButton value={address} />
          </strong>
        </div>
        <div>
          <Link to={`/accounting/funding/history/${convertSymbol(Symbol)}`}>
            View Funding History
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FundingAddress;
