import React from 'react';
import Image from './Image';
import InfiniteLoader from '../../Utilities/Components/InfiniteLoader';

const Images = ({ hash }) => {
  return (
    <InfiniteLoader
      url={`/platforms/${hash}/images`}
      emptyMessage="No photos available"
      limitBy={8}
    >
      {data => data?.map(image => <Image key={image.Hash} image={image} />)}
    </InfiniteLoader>
  );
};

export default Images;
