// Modules
import React from "react";
// Components
import { GenericForm, FormikContext } from "../../Forms/Form";
import { FormInput, FormSelect } from "../../Forms/FormInputs";
import { BanReasonList } from "./../../Utilities/Data/BanReasonList";

const symbols = [{ name: "BTCUSD", value: "BTCUSD" }];

const BanWalletAddress = (props) => {
  return (
    <GenericForm
      endpoint="compliance/wallet-bans"
      setValues={{
        CoinAddress: "",
        Reason: "",
        Symbol: "BTCUSD",
      }}
      formTitle="Ban Wallet Address"
      subTitle={null}
      goBack={{
        show: true,
        setHistory: props.history,
        setDefaultPath: "/compliance/bans",
        setDefaultName: "Pending Account Bans",
      }}
      toastMsg="Wallet Address has been Banned"
    >
      <FormikContext.Consumer>
        {(props) => {
          if (props) {
            props.setValues = {
              Reason: parseInt(props.values.Reason),
            };
          }
          return (
            <div className="columns">
              <div className="column">
                <FormInput
                  errors={props.errors}
                  touched={props.touched}
                  label="Wallet Address"
                  name="CoinAddress"
                  placeholder="1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2"
                />
              </div>
              <div className="column">
                <FormSelect
                  label="Reason"
                  name="Reason"
                  type="text"
                  errors={props.errors}
                  touched={props.touched}
                  placeholder="Select Reason for Banning Address"
                  options={
                    <React.Fragment>
                      {BanReasonList.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </React.Fragment>
                  }
                />
              </div>

              <div className="column">
                <FormSelect
                  label="Coin Type"
                  name="Symbol"
                  type="text"
                  errors={props.errors}
                  touched={props.touched}
                  placeholder="Symbol"
                  disabled
                  options={
                    <React.Fragment>
                      {symbols.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </React.Fragment>
                  }
                />
              </div>
            </div>
          );
        }}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

export default BanWalletAddress;
