import React from 'react';
import { ErrorMessage, Formik, Form, Field } from 'formik';
// Components
import { GenericModal } from '../Utilities/Components';

const templates = [
  {
    type: 'Back Online',
    text: 'DigitalMint User: Our {{ PLATFORM }} in {{ BUSINESS NAME }}	on {{ CROSS STREET }} in {{ CITY }} is back online after a brief outage! See you soon: {{ LINK }}',
  },
  {
    type: 'Close Location',
    text: 'DigitalMint User: Our {{ PLATFORM }} in {{ BUSINESS NAME }} on {{ CROSS STREET }} in {{ CITY }} is permanently closed. Please visit our nearest location: {{ LINK }}',
  },
  {
    type: 'New Location',
    text: 'DigitalMint User: We opened a new {{ PLATFORM TYPE }} in {{ BUSINESS NAME }} on {{ CROSS STREET }} in {{ CITY }}! Get directions and view hours here: {{ LINK }}',
  },
  {
    type: 'Google Review',
    text: "Thanks for choosing DigitalMint to purchase Bitcoin! We'd really appreciate it if you left us a quick review on Google: {{ LINK }}",
  },
];

const MessageModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Formik
      initialValues={{
        type: '', // in order to keep consistent error styling, we add a key here. This value is unimportant.
        message: '',
      }}
      validate={values => {
        let errors = {};
        if (!values.type) {
          errors.type = 'is required';
        }
        if (values.message.match(/{{(.*?)}}/)) {
          errors.message = 'needs more information';
        } else if (values.message.split('').length >= 160) {
          errors.message = `can't exceed 160 characters`;
        }
        return errors;
      }}
      validateOnChange={false}
      onSubmit={(values, formikBag) => {
        onSubmit(values.message);
        formikBag.resetForm();
      }}
      children={({
        touched,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={onClose}
          headerMsg="Send Text Blast"
          btnText="Submit"
          btnAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <Form className="columns form-outline is-centered">
            <div className="column">
              <label
                className={`item-label ${errors.type ? 'input-error' : ''}`}
              >
                Type{' '}
                <ErrorMessage
                  name="type"
                  component="span"
                  className="form-error"
                />
              </label>
              <select
                onChange={e => {
                  setFieldValue('type', e.target.value);
                  setFieldValue('message', e.target.value);
                }}
                defaultValue=""
              >
                <option value="" disabled>
                  Select a Type
                </option>
                {templates.map(item => {
                  return (
                    <option key={item.type} value={item.text}>
                      {item.type}
                    </option>
                  );
                })}
              </select>

              <label
                className={`item-label ${errors.message ? 'input-error' : ''}`}
              >
                Message{' '}
                <ErrorMessage
                  name="message"
                  component="span"
                  className="form-error"
                />
              </label>
              <Field
                errors={errors}
                touched={touched}
                name="message"
                as="textarea"
              />
            </div>
          </Form>
        </GenericModal>
      )}
    />
  );
};

export default MessageModal;
