// Modules
import React, { Fragment } from "react";
// Components
import { GenericForm, FormikContext } from "../../../Forms/Form";
import { FormSelect } from "../../../Forms/FormInputs";
import { addressesProps } from "./../../../Utilities/PropTypes";

const PreferredAddress = ({ addresses }) => {
  let currentPrefered = addresses.filter(address => address.Preferred === true);
  return (
    <GenericForm
      endpoint="addresses"
      postReq={true}
      setValues={{
        Preferred: true,
        Hash: ""
      }}
      accountSettings={true}
      formTitle={null}
      subTitle="Current Preferred Address "
      goBack={{
        show: false,
        setHistory: null
      }}
      toastMsg="New Preferred Address has been successfully set"
    >
      <Fragment>
        <div style={{ marginBottom: "20px" }}>
          <p>{currentPrefered[0].Street}</p>
          <p>
            {`${currentPrefered[0].City}
            ${currentPrefered[0].StateCode},
            ${currentPrefered[0].ZIP}`}
          </p>
        </div>
        <FormikContext.Consumer>
          {props => (
            <Fragment>
              <h2 className="form-title">Change Preferred Address</h2>
              <FormSelect
                errors={props.errors}
                touched={props.touched}
                label="List of Addresses"
                name="Hash"
                type="select"
                placeholder="Select an Address"
                options={
                  <Fragment>
                    {addresses.map(address => (
                      <option key={address.Hash} value={address.Hash}>
                        {`${address.Street} ${address.City}, ${
                          address.StateCode
                        } ${address.ZIP}`}
                      </option>
                    ))}
                  </Fragment>
                }
              />
            </Fragment>
          )}
        </FormikContext.Consumer>
      </Fragment>
    </GenericForm>
  );
};

PreferredAddress.propTypes = {
  addresses: addressesProps
};

export default PreferredAddress;
