const commissionKeys = {
  'LocationGroupCommission.Location.BusinessName': {
    Label: 'Location',
    Type: 'string',
  },
  'LocationGroupCommission.Location.Group.Name': {
    Label: 'Owner',
    Type: 'string',
  },
  'LocationGroupCommission.GroupCommission.DebitAccount.PayorName': {
    Label: 'Payee',
    Type: 'string',
  },
  CashVolume: {
    Label: 'Cash Volume',
    Type: 'decimal',
  },
  PayoutAmount: {
    Label: 'Commission Paid',
    Type: 'decimal',
  },
  GrossRevenue: {
    Label: 'Earnings',
    Type: 'decimal',
  },
  Fiat: {
    Label: 'Fiat',
    Type: 'decimal',
  },
  Type: {
    Label: 'Type',
    Type: 'int',
    Options: [
      {
        Display: 'No Commission Payout',
        Value: 0,
      },
      {
        Display: 'Flat Fiat',
        Value: 1,
      },
      {
        Display: 'Gross Commission Flat %',
        Value: 2,
      },
      {
        Display: 'Gross Commission Tiered',
        Value: 3,
      },
      {
        Display: 'Gross Commission Tiered Plus %',
        Value: 4,
      },
      {
        Display: 'Gross Commission Greater Of Fiat or %',
        Value: 5,
      },
      {
        Display: 'Cash Total Flat %',
        Value: 6,
      },
      {
        Display: 'Cash Total Tiered',
        Value: 7,
      },
      {
        Display: 'Cash Total Tiered Plus %',
        Value: 8,
      },
      {
        Display: 'Cash Total Greater Of Fiat or %',
        Value: 9,
      },
      {
        Display: 'Monthly Flat Fiat',
        Value: '10',
      },
    ],
  },
};

export default commissionKeys;
