import React from 'react';
import { objProp } from '../Utilities/PropTypes';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// Components
import IDImage from './IDImage';

const styles = {
  container: {
    alignItems: 'flex-end',
  },
};

const IDImages = ({ data }) => {
  return (
    <div className="columns is-centered" style={styles.container}>
      {!data.FirstURL && !data.SecondURL ? (
        <IDImage uploadError />
      ) : (
        <>
          <IDImage url={data.FirstURL && data.FirstURL} label="Front View" />
          <IDImage url={data.SecondURL && data.SecondURL} label="Back View" />
          <ReactTooltip place="bottom" effect="solid" />
        </>
      )}
    </div>
  );
};

export default IDImages;

IDImages.propTypes = {
  data: objProp,
};
