/* eslint no-console: ["error", { allow: [ "error"] }] */

import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
// Components
import { FormSelect } from './../../Forms/FormInputs';
import { GenericModal } from '../../Utilities/Components';
// Config
import { API } from '../../Config/cfg.js';

const AddPlatformModal = ({
  isOpen,
  addPlatform,
  locationHash,
  closeModal,
}) => {
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    API.get(`/locations/${locationHash}/platforms`)
      .then(res => {
        setPlatforms(res.data.Data);
      })
      .catch(error => {
        console.error(error.response.data);
      });
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        PlatformHash: '',
      }}
      onSubmit={async values => {
        await addPlatform(values);
      }}
      children={({ touched, errors, handleSubmit, isSubmitting }) => {
        return (
          <GenericModal
            modalIsOpen={isOpen}
            closeModal={() => {
              closeModal('platformModalIsOpen');
            }}
            headerMsg="Add Platform"
            btnText="Submit"
            btnAction={handleSubmit}
            isSubmitting={isSubmitting}
          >
            <div className="form-outline">
              <div className="columns">
                <div className="column is-6">
                  <FormSelect
                    errors={errors}
                    touched={touched}
                    label="Platform"
                    name="PlatformHash"
                    placeholder="Select Platform"
                    options={
                      <>
                        {platforms
                          ? platforms.map(item => (
                              <option key={item.Hash} value={item.Hash}>
                                {item.DeviceIdentifier}
                              </option>
                            ))
                          : []}
                      </>
                    }
                    disabled={!platforms.length}
                  />
                </div>
              </div>
            </div>
          </GenericModal>
        );
      }}
    />
  );
};

export default AddPlatformModal;
