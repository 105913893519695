import React, { useState, useEffect } from 'react';
// Components
import { GenericModal } from '../../Utilities/Components';

const DeleteCommissionModal = ({ isOpen, deleteCommission, closeModal }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setSubmitting(false);
    }
  }, [isOpen]);

  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={closeModal}
      headerMsg="Delete/Expire Commissions"
      viewMsg="This action will DELETE/EXPIRE this commission from this location."
      btnText="Submit"
      btnAction={() => {
        deleteCommission();
        setSubmitting(true);
      }}
      isSubmitting={isSubmitting}
    />
  );
};

export default DeleteCommissionModal;
