export const HoursList = [
  { Name: '12:00 AM', Hash: '12:00 AM' },
  { Name: '12:15 AM', Hash: '12:15 AM' },
  { Name: '12:30 AM', Hash: '12:30 AM' },
  { Name: '12:45 AM', Hash: '12:45 AM' },
  { Name: '01:00 AM', Hash: '01:00 AM' },
  { Name: '01:15 AM', Hash: '01:15 AM' },
  { Name: '01:30 AM', Hash: '01:30 AM' },
  { Name: '01:45 AM', Hash: '01:45 AM' },
  { Name: '02:00 AM', Hash: '02:00 AM' },
  { Name: '02:15 AM', Hash: '02:15 AM' },
  { Name: '02:30 AM', Hash: '02:30 AM' },
  { Name: '02:45 AM', Hash: '02:45 AM' },
  { Name: '03:00 AM', Hash: '03:00 AM' },
  { Name: '03:15 AM', Hash: '03:15 AM' },
  { Name: '03:30 AM', Hash: '03:30 AM' },
  { Name: '03:45 AM', Hash: '03:45 AM' },
  { Name: '04:00 AM', Hash: '04:00 AM' },
  { Name: '04:15 AM', Hash: '04:15 AM' },
  { Name: '04:30 AM', Hash: '04:30 AM' },
  { Name: '04:45 AM', Hash: '04:45 AM' },
  { Name: '05:00 AM', Hash: '05:00 AM' },
  { Name: '05:15 AM', Hash: '05:15 AM' },
  { Name: '05:30 AM', Hash: '05:30 AM' },
  { Name: '05:45 AM', Hash: '05:45 AM' },
  { Name: '06:00 AM', Hash: '06:00 AM' },
  { Name: '06:15 AM', Hash: '06:15 AM' },
  { Name: '06:30 AM', Hash: '06:30 AM' },
  { Name: '06:45 AM', Hash: '06:45 AM' },
  { Name: '07:00 AM', Hash: '07:00 AM' },
  { Name: '07:15 AM', Hash: '07:15 AM' },
  { Name: '07:30 AM', Hash: '07:30 AM' },
  { Name: '07:45 AM', Hash: '07:45 AM' },
  { Name: '08:00 AM', Hash: '08:00 AM' },
  { Name: '08:15 AM', Hash: '08:15 AM' },
  { Name: '08:30 AM', Hash: '08:30 AM' },
  { Name: '08:45 AM', Hash: '08:45 AM' },
  { Name: '09:00 AM', Hash: '09:00 AM' },
  { Name: '09:15 AM', Hash: '09:15 AM' },
  { Name: '09:30 AM', Hash: '09:30 AM' },
  { Name: '09:45 AM', Hash: '09:45 AM' },
  { Name: '10:00 AM', Hash: '10:00 AM' },
  { Name: '10:15 AM', Hash: '10:15 AM' },
  { Name: '10:30 AM', Hash: '10:30 AM' },
  { Name: '10:45 AM', Hash: '10:45 AM' },
  { Name: '11:00 AM', Hash: '11:00 AM' },
  { Name: '11:15 AM', Hash: '11:15 AM' },
  { Name: '11:30 AM', Hash: '11:30 AM' },
  { Name: '11:45 AM', Hash: '11:45 AM' },
  { Name: '12:00 PM', Hash: '12:00 PM' },
  { Name: '12:15 PM', Hash: '12:15 PM' },
  { Name: '12:30 PM', Hash: '12:30 PM' },
  { Name: '12:45 PM', Hash: '12:45 PM' },
  { Name: '01:00 PM', Hash: '01:00 PM' },
  { Name: '01:15 PM', Hash: '01:15 PM' },
  { Name: '01:30 PM', Hash: '01:30 PM' },
  { Name: '01:45 PM', Hash: '01:45 PM' },
  { Name: '02:00 PM', Hash: '02:00 PM' },
  { Name: '02:15 PM', Hash: '02:15 PM' },
  { Name: '02:30 PM', Hash: '02:30 PM' },
  { Name: '02:45 PM', Hash: '02:45 PM' },
  { Name: '03:00 PM', Hash: '03:00 PM' },
  { Name: '03:15 PM', Hash: '03:15 PM' },
  { Name: '03:30 PM', Hash: '03:30 PM' },
  { Name: '03:45 PM', Hash: '03:45 PM' },
  { Name: '04:00 PM', Hash: '04:00 PM' },
  { Name: '04:15 PM', Hash: '04:15 PM' },
  { Name: '04:30 PM', Hash: '04:30 PM' },
  { Name: '04:45 PM', Hash: '04:45 PM' },
  { Name: '05:00 PM', Hash: '05:00 PM' },
  { Name: '05:15 PM', Hash: '05:15 PM' },
  { Name: '05:30 PM', Hash: '05:30 PM' },
  { Name: '05:45 PM', Hash: '05:45 PM' },
  { Name: '06:00 PM', Hash: '06:00 PM' },
  { Name: '06:15 PM', Hash: '06:15 PM' },
  { Name: '06:30 PM', Hash: '06:30 PM' },
  { Name: '06:45 PM', Hash: '06:45 PM' },
  { Name: '07:00 PM', Hash: '07:00 PM' },
  { Name: '07:15 PM', Hash: '07:15 PM' },
  { Name: '07:30 PM', Hash: '07:30 PM' },
  { Name: '07:45 PM', Hash: '07:45 PM' },
  { Name: '08:00 PM', Hash: '08:00 PM' },
  { Name: '08:15 PM', Hash: '08:15 PM' },
  { Name: '08:30 PM', Hash: '08:30 PM' },
  { Name: '08:45 PM', Hash: '08:45 PM' },
  { Name: '09:00 PM', Hash: '09:00 PM' },
  { Name: '09:15 PM', Hash: '09:15 PM' },
  { Name: '09:30 PM', Hash: '09:30 PM' },
  { Name: '09:45 PM', Hash: '09:45 PM' },
  { Name: '10:00 PM', Hash: '10:00 PM' },
  { Name: '10:15 PM', Hash: '10:15 PM' },
  { Name: '10:30 PM', Hash: '10:30 PM' },
  { Name: '10:45 PM', Hash: '10:45 PM' },
  { Name: '11:00 PM', Hash: '11:00 PM' },
  { Name: '11:15 PM', Hash: '11:15 PM' },
  { Name: '11:30 PM', Hash: '11:30 PM' },
  { Name: '11:45 PM', Hash: '11:45 PM' },
];
