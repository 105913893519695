//Modules
import React from "react";
// Components
import { ListGroup, ListGroupItem } from "../../../Utilities/Components";
// Helpers - Formatters
import { formatPrice } from "../../../Utilities/Formatters";
import { accountProps } from "../../../Utilities/PropTypes";

const AccountActivity = ({ props }) => {
  return (
    <ListGroup title="Account Activity">
      <ListGroupItem
        name="Total Activity"
        value={formatPrice(props.TotalActivity)}
      />
      <ListGroupItem
        name="Monthly Avg. Activity"
        value={formatPrice(props.MonthlyAverageActivity)}
      />
      <ListGroupItem name="Total Alerts" value={props.TotalAlerts} />
      <ListGroupItem
        name="Total Transactions"
        value={props.TotalTransactions}
      />
      <ListGroupItem name="Total CTRs" value={props.TotalCTRs} />
      <ListGroupItem name="Prior SARs" value={props.PriorSARs} />
    </ListGroup>
  );
};

AccountActivity.propTypes = {
  data: accountProps,
};

export default AccountActivity;
