import React from 'react';
import { reqStrProp } from './../PropTypes';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { formatLowerCase } from '../../Utilities/Formatters';

export const UrgentApproval = type => {
  let attr;

  let tooltipText;
  switch (true) {
    case type === 1:
    case type === 3:
      attr = 'high';
      tooltipText = 'High Priority';
      break;
    case type === 5:
    case type === 6:
    case type === 7:
      attr = 'netrual';
      tooltipText = 'Neutral';
      break;
    default:
      attr = 'low';
      tooltipText = 'Low Priority';
  }

  return (
    <div className="priority" data-for="priority" data-tip={tooltipText}>
      <div className={`${attr} priority-pill`}></div>
      <div
        className={`${
          (attr === 'netrual' || attr === 'high') && attr
        } priority-pill`}
      ></div>
      <div className={`${attr === 'high' && attr} priority-pill`}></div>
      <ReactTooltip id="priority" effect="solid" />
    </div>
  );
};

export const ApprovalPill = (type, desc) => {
  let attr;
  switch (true) {
    case type === 0:
      attr = 'approval-id';
      break;
    case type === 1:
      attr = 'approval-id';
      break;
    case type === 2:
      attr = 'approval-full-id';
      break;
    case type === 3:
      attr = 'approval-ofac';
      break;
    case type === 4:
      attr = 'approval-ssn';
      break;
    case type === 5:
      attr = 'approval-tier2-review';
      break;
    case type === 6:
      attr = 'approval-over55';
      break;
    case type === 7:
      attr = 'approval-plaid-lightning';
      break;
    case type === 8:
      attr = 'approval-tpiv';
      break;
    case type === 10:
      attr = 'approval-selfie';
      break;
    case type === 11:
      attr = 'approval-document-verify';
      break;
    case type === 12:
      attr = 'approval-plaid-risk';
      break;
    default:
      attr = 'approval-id';
      break;
  }
  return <span className={`table-pill ${attr}`}>{desc}</span>;
};

ApprovalPill.propType = {
  type: reqStrProp,
  desc: reqStrProp,
};

export const TradeTypePill = (symbol, key) => {
  let type;
  let attr;
  switch (symbol) {
    case 'BTCUSD':
      attr = 'trade-btcusd';
      type = '₿';
      break;
    case 'LTCUSD':
      attr = 'trade-ltcusd';
      type = 'Ł';
      break;
    case 'ETHUSD':
      attr = 'trade-ethusd';
      type = 'Ξ';
      break;
    case 'BCHUSD':
      attr = 'trade-bchusd';
      type = '₿';
      break;
    case 'TBTCUSD':
      attr = 'trade-test-btcusd';
      type = '₿';
      break;
    case 'TLTCUSD':
      attr = 'trade-test-ltcusd';
      type = 'Ł';
      break;
    case 'TETHUSD':
      attr = 'trade-test-ethusd';
      type = 'Ξ';
      break;
    case 'TBCHUSD':
      attr = 'trade-test-bchusd';
      type = '₿';
      break;
    default:
      attr = 'table-pill';
      type = '';
      break;
  }
  return (
    <React.Fragment key={symbol}>
      <span className={`table-pill ${attr}`}>{`${type} ${symbol}`}</span>
      <br />
    </React.Fragment>
  );
};

TradeTypePill.propType = {
  symbol: reqStrProp,
};

export const PlatformPill = name => {
  let attr;
  switch (name) {
    case 'iPad Legacy':
      attr = 'name-ipad-legacy';
      break;
    case 'iPad Bravo':
      attr = 'name-ipad-bravo';
      break;
    case 'web Unknown':
      attr = 'name-web';
      break;
    case 'Kiosk Kiosk.com':
      attr = 'name-kiosk';
      break;
    case 'Kiosk Slabb':
      attr = 'name-slabb';
      break;
    case 'Kiosk Genmega.XL':
      attr = 'model-genmega-xl';
      break;
    case 'Kiosk Genmega1':
      attr = 'model-genmega-1';
      break;
    case 'Kiosk Genmega2':
      attr = 'model-genmega-2';
      break;
    case 'Kiosk Genmega.UK':
      attr = 'model-genmega-uk';
      break;
    case 'Kiosk Genmega.CDU':
      attr = 'model-genmega-cdu';
      break;
    case 'Kiosk Hyosung.Sidecar':
      attr = 'model-hyosung';
      break;
    case 'ATM Hyosung.ATM':
      attr = 'model-hyosung';
      break;
    case 'Kiosk Genmega.Sidecar':
      attr = 'model-genmega-sidecar';
      break;
    case 'Kiosk Genmega.CARD':
      attr = 'model-genmega-card';
      break;
    case 'Kiosk Maya Tech':
      attr = 'model-maya-tech';
      break;
    case 'Kiosk Futurakiosk':
      attr = 'model-futurakiosk';
      break;
    case 'API DCS POS':
      attr = 'name-api-dcs';
      break;
    case 'API Unknown':
      attr = 'name-api-unknown';
      break;
    case 'API EZMoney POS':
      attr = 'name-api-secure';
      break;
    case 'API Answers Tier III':
      attr = 'name-api-tier3';
      break;
    case 'Payment Processor':
      attr = 'name-payment-proccessor';
      break;
    default:
      attr = 'name-api-unknown';
      break;
  }
  return <span className={`table-pill ${attr}`}>{name}</span>;
};

export const ReviewPhasePill = name => {
  let attr;
  switch (name) {
    case 'initial review':
      attr = 'alert-open';
      break;
    case 'followup review':
      attr = 'alert-pending';
      break;
    case 'final review':
      attr = 'alert-closed';
      break;
    case '':
      attr = '';
      break;
    default:
      attr = '';
  }
  return <span className={`table-pill ${attr}`}>{name}</span>;
};

export const PlatformModelNamePill = model => {
  let attr;
  switch (model) {
    case 'iPad':
      attr = 'name-ipad';
      break;
    case 'Legacy':
      attr = 'name-ipad-legacy';
      break;
    case 'Bravo':
      attr = 'name-ipad-bravo';
      break;
    case 'Customer Staging iPad':
      attr = 'name-ipad-customer-staging';
      break;
    case 'Teller Staging iPad':
      attr = 'name-ipad-teller-staging';
      break;
    case 'web':
      attr = 'name-web';
      break;
    case 'Kiosk':
      attr = 'name-kiosk';
      break;
    case 'Air 2':
      attr = 'model-air-2';
      break;
    case 'Unknown':
      attr = 'model-unknown';
      break;
    case 'Kiosk.com':
      attr = 'model-kiosk';
      break;
    case 'Slabb':
      attr = 'model-slabb';
      break;
    case 'EZMoney POS':
      attr = 'name-api-secure';
      break;
    case 'Answer Tier III':
      attr = 'name-api-tier3';
      break;
    case 'DCS POS':
      attr = 'name-api-dcs';
      break;
    case 'API':
      attr = 'name-api';
      break;
    case 'GenmegaXL':
      attr = 'model-genmega-xl';
      break;
    case 'Genmega1':
      attr = 'model-genmega-1';
      break;
    case 'Genmega2':
      attr = 'model-genmega-2';
      break;
    case 'Genmega.UK':
      attr = 'model-genmega-uk';
      break;
    case 'Genmega.CDU':
      attr = 'model-genmega-cdu';
      break;
    case 'Genmega.CARD':
      attr = 'model-genmega-card';
      break;
    case 'Genmega.Sidecar':
      attr = 'model-genmega-sidecar';
      break;
    case 'Hyosung.Sidecar':
      attr = 'model-hyosung';
      break;
    case 'Hyosung.ATM':
      attr = 'model-hyosung';
      break;
    case 'Maya Tech':
      attr = 'model-maya-tech';
      break;
    case 'Easy2u':
      attr = 'model-easy2u';
      break;
    case 'Futurakiosk':
      attr = 'model-futurakiosk';
      break;
    default:
      attr = '';
      break;
  }
  return <span className={`table-pill ${attr}`}>{model}</span>;
};

PlatformPill.propType = {
  name: reqStrProp,
};

export const TxState = (state, desc) => {
  let attr = '';
  switch (true) {
    case state >= 500 && state < 600:
      attr = 'status-500';
      break;
    case state === 400:
      attr = 'status-400';
      break;
    case state === 410:
      attr = 'status-410';
      break;
    case state === 420:
      attr = 'status-420';
      break;
    case state === 310:
      attr = 'status-310';
      break;
    case state >= 300 && state < 400:
      attr = 'status-300';
      break;
    case state >= 200 && state < 300:
      attr = 'status-200';
      break;
    case state >= 100 && state < 200:
      attr = 'status-100';
      break;
    default:
      attr = 'tx-unknown';
      break;
  }

  return (
    <span data-for="state" className={`table-pill ${attr}`}>
      {desc}
    </span>
  );
};

TxState.propType = {
  state: reqStrProp,
  desc: reqStrProp,
};

export const SeverityPill = type => {
  let text;
  let attr;

  switch (type) {
    case 0:
      attr = 'severity-info';
      text = 'Info';
      break;
    case 1:
      attr = 'severity-warn';
      text = 'Warning';
      break;
    case 2:
      attr = 'severity-error';
      text = 'Error';
      break;
    case 3:
      attr = 'severity-critical';
      text = 'Critical';
      break;
    default:
      attr = '';
      text = '-';
      break;
  }

  return <span className={`table-pill ${attr}`}>{text}</span>;
};

export const AlertPill = (type, text) => {
  let attr;

  switch (type) {
    case 0:
      attr = 'alert-closed';
      break;
    case 1:
      attr = 'alert-open';
      break;
    case 2:
      attr = 'alert-pending';
      break;
    default:
      attr = '';
      break;
  }

  return <span className={`table-pill ${attr}`}>{text}</span>;
};

export const AlertDispositionPill = (type, text) => {
  let attr;
  switch (type) {
    case 0:
      attr = 'disposition-false-positive';
      break;
    case 1:
      attr = 'disposition-90-day';
      break;
    case 2:
      attr = 'disposition-escalated';
      break;
    case 3:
      attr = 'disposition-data-quality';
      break;
    case 4:
      attr = 'disposition-apollo';
      break;
    default:
      attr = '';
      break;
  }

  return <span className={`table-pill ${attr}`}>{text}</span>;
};

export const InvestigationDispositionPill = (type, text) => {
  let attr;
  switch (type) {
    case 0:
      attr = 'disposition-cns';
      break;
    case 1:
      attr = 'disposition-sar-filed';
      break;
    case 2:
      attr = 'disposition-str';
      break;
    default:
      attr = '';
      break;
  }

  return <span className={`table-pill ${attr}`}>{text}</span>;
};

export const InvestigationTypePill = text => {
  let attr;
  switch (text) {
    case 'referral':
      attr = 'investigation-referral';
      break;
    case 'escalation':
      attr = 'investigation-escalation';
      break;
    default:
      attr = 'investigation-follow-up';
      break;
  }

  return <span className={`table-pill ${attr}`}>{text}</span>;
};

export const DocumentPill = (type, text) => {
  let attr;
  switch (type) {
    case 0:
      attr = 'document-transactions';
      break;
    case 1:
      attr = 'document-signed-pdf';
      break;
    case 2:
      attr = 'document-unsigned-pdf';
      break;
    case 3:
    case 11:
    case 12:
    case 13:
      attr = 'document-supporting-docs';
      break;
    case 4:
      attr = 'document-customer-info';
      break;
    case 5:
      attr = 'document-transaction-info';
      break;
    case 6:
      attr = 'document-subpoena-request';
      break;
    case 7:
      attr = 'document-signed-subpoena';
      break;
    case 8:
      attr = 'document-blockchain-forensics';
      break;
    case 9:
      attr = 'document-other';
      break;
    case 15:
      attr = 'document-invoices';
      break;
    case 14:
    case 16:
    case 17:
      attr = 'document-legal';
      break;
    case 18:
    case 19:
      attr = 'document-signed';
      break;
    default:
      attr = 'document-other';
      break;
  }

  return <span className={`table-pill ${attr}`}>{text}</span>;
};

export const formatAccountStatus = status => {
  switch (status) {
    case 0:
      return 'Unapproved';
    case 1:
      return 'Tier 1';
    case 2:
      return 'Tier 2';
    case 3:
      return 'Tier 3';
    case 10:
      return 'Service Account';
    case 11:
      return 'Banned';
    case 12:
      return 'Employee';
    default:
      return 'Unapproved';
  }
};

export const StatusPill = (type, desc) => {
  let attr;
  switch (true) {
    case type === 0:
      attr = 'status-unapproved';
      break;
    case type === 1:
      attr = 'status-tier1';
      break;
    case type === 2:
      attr = 'status-tier2';
      break;
    case type === 3:
      attr = 'status-tier3';
      break;
    case type === 10:
      attr = 'status-svc-account';
      break;
    case type === 11:
      attr = 'status-banned';
      break;
    case type === 12:
      attr = 'status-employee';
      break;
    default:
      attr = 'status-unapproved';
      break;
  }
  return <span className={`table-pill ${attr}`}>{desc}</span>;
};

StatusPill.propType = {
  type: reqStrProp,
  desc: reqStrProp,
};

export const ScenarioPill = desc => {
  let attr;
  switch (formatLowerCase(desc)) {
    case 'volume':
      attr = 'alert-volume';
      break;
    case 'structuring weekly':
      attr = 'alert-weekly';
      break;
    case 'structuring monthly':
      attr = 'alert-monthly';
      break;
    case 'spike':
      attr = 'alert-spike';
      break;
    case 'excessive':
      attr = 'alert-excessive';
      break;
    case 'OTC over 10k':
      attr = 'alert-otc-10k';
      break;
    case 'OTC and Cash Transaction Customer':
      attr = 'alert-otc-cash';
      break;
    default:
      // What would this be? There is no index 3
      desc = 'Unknown';
      attr = 'unknown';
      break;
  }
  return <span className={`table-pill ${attr}`}>{desc}</span>;
};

ScenarioPill.propType = {
  desc: reqStrProp,
};

export const CommissionType = (type, desc) => {
  let attr;
  switch (type) {
    case 1:
      attr = 'fiat';
      break;
    case 2:
      attr = 'gross';
      break;
    case 3:
      attr = 'gross';
      break;
    case 4:
      attr = 'gross';
      break;
    case 5:
      attr = 'gross';
      break;
    case 6:
      attr = 'cash';
      break;
    case 7:
      attr = 'cash';
      break;
    case 8:
      attr = 'cash';
      break;
    case 9:
      attr = 'cash';
      break;
    case 10:
      attr = 'fiat';
      break;
    default:
      attr = 'unknown';
      break;
  }
  return <span className={`table-pill ${attr}`}>{desc}</span>;
};

CommissionType.propType = {
  desc: reqStrProp,
};
