// Components
import React from "react";
import { FormInput } from "../Forms/FormInputs";
// Components
import { GenericForm, FormikContext } from "../Forms/Form";
// Assets
import AltLogo from "../images/dm.svg";

const ResetPassword = ({ match, history }) => {
  const { hash } = match.params;
  return (
    <React.Fragment>
      <img
        className="logo"
        src={AltLogo}
        alt="logo"
        style={{ marginBottom: "30px" }}
      />
      <GenericForm
        endpoint="account/password/reset"
        postReq={true}
        passwordReload={true}
        setValues={{
          Token: hash,
          Password: "",
        }}
        customValidations={(values) => {
          let errors = {};
          if (!values.Password) {
            errors.Password = "is required";
          }
          if (!values.ConfirmPassword) {
            errors.ConfirmPassword = "is required";
          }
          if (values.Password !== values.ConfirmPassword) {
            errors.ConfirmPassword = "does not match";
          }
          return errors;
        }}
        formTitle="Reset Password"
        subTitle="Set and confirm new password"
        goBack={{
          show: true,
          setHistory: history,
          setDefaultPath: "/",
          setDefaultName: "Login",
        }}
        toastMsg="Password has been successfully reset"
      >
        <FormikContext.Consumer>
          {(props) => (
            <React.Fragment>
              <FormInput
                errors={props.errors}
                touched={props.touched}
                label="Password"
                type="password"
                name="Password"
                placeholder="Enter Password"
              />
              <FormInput
                errors={props.errors}
                touched={props.touched}
                label="Confirm"
                type="password"
                name="ConfirmPassword"
                placeholder="Confirm Password"
              />
            </React.Fragment>
          )}
        </FormikContext.Consumer>
      </GenericForm>
    </React.Fragment>
  );
};

export default ResetPassword;
