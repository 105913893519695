import React from "react";

const OperatorFilterValue = ({ setOperator, equalityOnly }) => {
  let coperators = [
    { Display: "Equals", Value: "=" },
    { Display: "Greater Than", Value: ">" },
    { Display: "Greater Than or Equal To", Value: ">=" },
    { Display: "Less Than", Value: "<" },
    { Display: "Less Than or Equal To", Value: "<=" },
    { Display: "Not Equal", Value: "!=" },
  ];

  if (equalityOnly) {
    coperators = coperators.filter(
      (i) => !(i.Value.includes(">") || i.Value.includes("<"))
    );
  }

  return (
    <>
      <p className="item-label">Range</p>
      <select
        id="operatorSelect"
        onChange={(e) => setOperator(e)}
        defaultValue=""
      >
        {coperators.map((filter) => (
          <option key={filter.Display} value={JSON.stringify(filter)}>
            {filter.Display}
          </option>
        ))}
      </select>
    </>
  );
};

export default OperatorFilterValue;
