import React from "react";

const TableHeader = ({ title, nested, children }) => {
  return (
    <div className="columns table-actions is-vcentered">
      {nested ? (
        <h2 className="column">{title}</h2>
      ) : (
        <h1 className="column">{title}</h1>
      )}
      {children}
    </div>
  );
};

export default TableHeader;
