/* eslint no-console: ["error", { allow: [ "error"] }] */

// Modules
import React, { Component } from 'react';

// Components
import { GenericForm } from '../Forms/Form';
import { FormInput, FormSelect } from '../Forms/FormInputs';
import { ToastError } from '../Utilities/Components';
import { reqStrProp } from '../Utilities/PropTypes';
// Configs
import { API } from '../Config/cfg.js';

class CreateProducts extends Component {
  state = {
    products: [],
    locationEndpoints: {},
  };

  componentDidMount = () => {
    this.getProducts();
  };

  getProducts = () => {
    API.get('/products')
      .then(res => {
        this.setState({
          products: res.data,
        });
        this.getEndpoints();
      })
      .catch(error => {
        ToastError(error, this.props.history);
      });
  };

  getEndpoints = () => {
    const { hash } = this.props;
    API.get(`/platforms/${hash}`)
      .then(res => {
        this.setState({
          locationEndpoints: res.data,
        });
      })
      .catch(error => {
        console.error(error.response);
      });
  };

  render() {
    const { hash, history } = this.props;
    const { products, locationEndpoints } = this.state;
    const { PlatformType, DeviceIdentifier, Active } = locationEndpoints;
    return (
      <GenericForm
        endpoint={`platforms/${hash}/products`}
        setValues={{
          ProductHash: '',
          Buy: true,
          Sell: false,
        }}
        customValidations={values => {
          let errors = {};
          return errors;
        }}
        goBack={{
          show: true,
          setHistory: history,
          setDefaultPath: `/platforms/${hash}`,
          setDefaultName: 'Platform',
        }}
        formTitle="Add New Product"
        subTitle="Platform Details"
        toastMsg={`Product has been added to ${DeviceIdentifier}`}
      >
        <div className="columns">
          <div className="column is-narrow">
            <p>
              <span className="item-label">Active: </span>
              {Active ? 'true' : 'false'}
            </p>
          </div>
          {PlatformType && (
            <div className="column is-narrow">
              <p>
                <span className="item-label">Platform: </span>
                {PlatformType.Model
                  ? `${PlatformType.Name} ${PlatformType.Model}`
                  : PlatformType.Name}
              </p>
            </div>
          )}
          <div className="column is-narrow">
            <p>
              <span className="item-label">Device ID: </span>
              {DeviceIdentifier}
            </p>
          </div>
        </div>
        <h2 className="form-title">Choose a Product</h2>
        <div className="columns">
          <div className="column is-3">
            <FormSelect
              label="Product"
              name="ProductHash"
              type="text"
              placeholder="Select Product"
              options={products.map(item => (
                <option key={item.Hash} value={item.Hash}>
                  {`${item.Symbol} ${item.Description}`}
                </option>
              ))}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-1">
            <FormInput label="Buy" name="Buy" type="checkbox" />
          </div>
          <div className="column is-1">
            <FormInput label="Sell" name="Sell" type="checkbox" />
          </div>
        </div>
      </GenericForm>
    );
  }
}

CreateProducts.propTypes = {
  hash: reqStrProp,
};

export default CreateProducts;
