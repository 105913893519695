// Modules
import React, { Component } from "react";
// Alert Components
import AccountDetails from "./Tabs/AccountDetails";
import AlertDetails from "./Tabs/AlertDetails";
import AlertingTransaction from "./Tabs/AlertingTransaction";
import AlertNarrative from "./AlertNarrative";
import AccountActivity from "./Tabs/AccountActivity";
// Components
import { GoBack, Header, Loading } from "../../Utilities/Components";
// Helpers - Formatters
import { formatDateTime } from "../../Utilities/Formatters";
// Icons
import { ICONS } from "./../../Icons/IconsPath";
// Permissions
import Can from "../../Permissions/Can";
import { COMPLIANCE_ALERTS_ACTION } from "../../Permissions/Permissions";
// Styles
import "./Alerts.scss";
// Configs
import { API } from "../../Config/cfg.js";

class SingleAlert extends Component {
  state = {
    data: {},
    loading: true,
    errors: {},
  };

  componentDidMount() {
    this.getAlert();
  }

  getAlert = () => {
    const {
      params: { hash },
    } = this.props.match;
    API.get(`/compliance/alerts/${hash}`)
      .then((res) => {
        this.setState({
          data: res.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  };

  render() {
    const { history } = this.props;
    const { loading, data, errors } = this.state;
    const {
      Account,
      AuthorAccount,
      AssignedTo,
      Code,
      DispositionText,
      Hash,
      Narrative,
      NarrativeUpdatedAt,
    } = data;

    if (loading) {
      return <Loading errors={errors} />;
    }

    return (
      <React.Fragment>
        <GoBack
          history={this.props.history}
          defaultPath="/compliance/alerts"
          defaultName="Alerts"
        />

        <div className="columns is-vcentered">
          <div className="column">
            <Header icon={ICONS.WARNING} title={Code} />
          </div>

          {AuthorAccount && (
            <Header code="Worked by: " title={AuthorAccount.FullName} />
          )}

          {AssignedTo && !AuthorAccount && (
            <Header code="Assigned to: " title={AssignedTo.FullName} />
          )}
        </div>
        <div className="alert-container">
          <div className="columns">
            <div className="column">
              <AccountActivity props={data} />
            </div>
            <div className="column">
              <AccountDetails data={Account} />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <AlertingTransaction props={data} />
            </div>
            <div className="column">
              <AlertDetails props={data} />
            </div>
          </div>
        </div>
        {Narrative ? (
          <React.Fragment>
            <h2>Narrative</h2>
            <div id="narrative">
              <p>
                <span className="item-label">Worked by:</span>{" "}
                {AuthorAccount.FirstName} {AuthorAccount.LastName}
              </p>
              <p>
                <span className="item-label">Updated at:</span>{" "}
                {formatDateTime(NarrativeUpdatedAt)}
              </p>
              <p>
                <span className="item-label">Reason:</span> {DispositionText}
              </p>
              <p className="narrative">{Narrative}</p>
            </div>
          </React.Fragment>
        ) : (
          <Can do={COMPLIANCE_ALERTS_ACTION}>
            <AlertNarrative
              endPoint={`compliance/alerts/${Hash}`}
              history={history}
            />
          </Can>
        )}
      </React.Fragment>
    );
  }
}

export default SingleAlert;
