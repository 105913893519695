import React from 'react';
import Can from '../../Permissions/Can';
import Icon from '../../Icons/Icon';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const ButtonAction = ({
  permission,
  className,
  onClick,
  toolTip,
  icon,
  size,
  text,
}) => {
  const btn = (
    <span
      data-tooltip-id="tip"
      data-tooltip-content={toolTip}
      style={{ position: 'relative' }}
    >
      <button
        className={classnames('btn', icon && 'btn-icon', className)}
        onClick={onClick}
        data-testid={`${toolTip}-ButtonAction`}
      >
        {icon ? <Icon icon={icon} className="icon" size={size} /> : text}
      </button>

      <ReactTooltip
        id="tip"
        place="bottom"
        effect="solid"
        data-testid={toolTip}
      />
    </span>
  );
  return permission ? <Can do={permission}>{btn}</Can> : btn;
};

ButtonAction.defaultProps = {
  size: 21,
};

ButtonAction.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.string,
  size: PropTypes.number,
  text: PropTypes.string,
  toolTip: PropTypes.string,
  type: PropTypes.string,
};

export default ButtonAction;
