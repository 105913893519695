/* COMPONENT OVERVIEW
- Builds object structure for react-table
*/
import React from 'react';
import { Link } from 'react-router-dom';
import {
  formatAccountStatus,
  StatusPill,
} from '../Utilities/Components/TablePills';
import {
  formatDateTime,
  formatDate,
  formatPrice,
} from '../Utilities/Formatters';

const columnBuilder = [
  {
    Header: 'Status',
    accessor: 'ApprovalTier',
    Cell: ({ row: { original } }) => {
      return original
        ? StatusPill(original.Status, formatAccountStatus(original.Status))
        : null;
    },
    sortMethod: 'Status',
    default: true,
  },
  {
    Header: 'Full Name',
    accessor: 'FullName',
    style: { textAlign: 'left' },
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/accounts/${original.Hash}`}>
        {original.FullName}
      </Link>
    ),
    minWidth: 215,
    sortMethod: 'LastName',
    default: true,
  },
  {
    Header: 'First Name',
    accessor: 'FirstName',
  },
  {
    Header: 'Last Name',
    accessor: 'LastName',
  },
  {
    Header: 'DOB',
    accessor: 'DOB',
    Cell: ({ row: { original } }) => formatDate(original.DOB),
  },
  {
    Header: 'Created At',
    accessor: 'CreatedAt',
    Cell: ({ row: { original } }) => formatDateTime(original.CreatedAt),
    sortMethod: 'CreatedAt',
  },
  {
    Header: 'Employer',
    accessor: 'Employer',
    Cell: ({ row: { original } }) => original.Employer || '-',
  },
  {
    Header: 'Occupation',
    accessor: 'Occupation',
    Cell: ({ row: { original } }) => original.Occupation || '-',
  },
  {
    Header: 'Annual Income',
    accessor: 'AnnualIncome',
    Cell: ({ row: { original } }) => formatPrice(original.AnnualIncome),
  },
  {
    Header: 'Gender',
    accessor: 'Gender',
    Cell: ({ row: { original } }) => (original.Gender || '-').toUpperCase(),
  },
  {
    Header: 'Phone',
    accessor: 'PreferredPhoneNumber.Phone',
    sortMethod: 'Phone',
    default: true,
  },
  {
    Header: 'Total Cash In',
    accessor: 'TotalCashIn',
    Cell: ({ row: { original } }) => formatPrice(original.TotalCashIn),
    sortMethod: 'TotalCashIn',
    default: true,
  },
  {
    Header: 'City',
    accessor: 'Addresses[0].City',
    Cell: ({ row: { original } }) => {
      return original?.Addresses[0]?.City || '-';
    },
    sortMethod: 'City',
    default: true,
  },
  {
    Header: 'State',
    accessor: 'Addresses[0].StateCode',
    Cell: ({ row: { original } }) => {
      return original?.Addresses[0]?.StateCode || '-';
    },
    sortMethod: 'State',
    default: true,
  },
  {
    Header: 'Last Seen Date',
    accessor: 'MostRecentActivity',
    Cell: ({ row: { original } }) =>
      formatDateTime(original.MostRecentActivity),
    sortMethod: 'LastSeenDate',
    default: true,
  },
];

export default columnBuilder;
