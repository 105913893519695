import React from "react";
import { Link } from "react-router-dom";
// Helpers - Formatters
import { formatPrice, formatDate } from "../../Utilities/Formatters";
import { reqStrProp, reqBoolProp } from "./../../Utilities/PropTypes";
// Permissions
import Can from "./../../Permissions/Can";
import { COMPLIANCE_CTR_UPLOAD } from "../../Permissions/Permissions";

const displayUpload = (filed, hash, URL) => {
  if (filed) {
    return (
      <a href={URL} target="_blank" rel="noopener noreferrer">
        View CTR
      </a>
    );
  } else {
    return (
      <Can do={COMPLIANCE_CTR_UPLOAD}>
        <Link
          key={hash}
          to={{
            pathname: "/compliance/document-upload",
            hash: hash,
            state: { fromLocation: "CTR" },
          }}
        >
          Upload Document
        </Link>
      </Can>
    );
  }
};

displayUpload.propTypes = {
  hash: reqStrProp,
  URL: reqStrProp,
  filed: reqBoolProp,
};

const columnBuilder = [
  {
    Header: "CTR #",
    accessor: "Code",
    Cell: ({ row: { original } }) => (
      <>{original.Code ? <p>{original.Code}</p> : "-"}</>
    ),
    sortMethod: "CtrNumber",
  },
  {
    Header: "Customer",
    accessor: "Account.FullName",
    style: { textAlign: "left " },
    Cell: ({ row: { original } }) => (
      <>
        {original.Account ? (
          <Link to={`/accounts/${original.Account.Hash}`}>
            {original.Account.FullName}
          </Link>
        ) : (
          "-"
        )}
      </>
    ),
    sortMethod: "CustomerName",
  },
  {
    Header: "Amount of CTR",
    Cell: ({ row: { original } }) => formatPrice(original.Volume),
    sortMethod: "AmountOfCtr",
  },
  {
    Header: "Date of CTR",
    Cell: ({ row: { original } }) => (
      <>{original.TransactionDay ? original.TransactionDay : "-"}</>
    ),
    sortMethod: "CreatedAt",
  },
  {
    Header: "Location",
    style: { textAlign: "left " },
    Cell: ({ row: { original } }) => (
      <>
        {original.Locations ? (
          <Link to={`/locations/${original.Locations[0].Hash}`}>
            {original.Locations[0].BusinessName}
          </Link>
        ) : (
          "-"
        )}
      </>
    ),
    sortMethod: "Location",
  },
  {
    Header: "Due Date",
    Cell: ({ row: { original } }) => (
      <>{original.DueDate ? formatDate(original.DueDate) : "-"}</>
    ),
    sortMethod: "DueDate",
  },

  {
    Header: "Filed",
    accessor: "Filed",
    Cell: ({ row: { original } }) =>
      displayUpload(original.Filed, original.Hash, original.URL),
    sortMethod: "Filed",
  },
  {
    Header: "Country of Origin",
    accessor: "Country.Code",
  },
];

export default columnBuilder;
