// Modules
import React from "react";
// Components
import Table from "../../Table/Table";
import { COMPLIANCE_PEP_ACTION } from "../../Permissions/Permissions";
import columnBuilder from "./columnBuilder";

// Politically Exposed Persons
const PEPs = (props) => {
  return (
    <Table
      history={props.history}
      columns={columnBuilder}
      endpoint="compliance/peps"
      tablename="Politically Exposed Persons"
      actions={[
        {
          name: "Create PEP",
          link: "/compliance/create-pep",
          permission: COMPLIANCE_PEP_ACTION,
        },
      ]}
    />
  );
};

export default PEPs;
