/* COMPONENT OVERVIEW
- Takes props from parent
- Renders edit or cancel link
*/
import React from 'react';
import { Link } from 'react-router-dom';
import CopyButton from './CopyButton';
import Icon from '../../Icons/Icon';
import { ICONS } from '../../Icons/IconsPath';
import Can from '../../Permissions/Can';
import PropTypes from 'prop-types';

const EditBtn = ({
  copy,
  copyText,
  editing,
  link,
  permission,
  sectionTitle,
  testID,
  triggerEdit,
}) => {
  return (
    <div
      className="columns edit-actions is-vcentered is-mobile"
      data-testid={testID}
    >
      <h3
        className="column is-10-desktop is-three-quarters-mobile"
        data-testid={`${testID}-title`}
      >
        {sectionTitle}
      </h3>
      {editing ? (
        <>
          {!link && <div className="column is-1 offset" />}
          {link && link.pathname && (
            <>
              <div className="column is-1 offset">
                <Link to={link}>
                  <span className="btn primary-btn-outline add-btn">
                    <i className="fa fa-plus"></i>
                  </span>
                </Link>
              </div>
            </>
          )}
          <button
            onClick={triggerEdit}
            className="btn red-btn-outline cancel-btn"
            data-testid={`${testID}-cancel`}
          >
            <Icon icon={ICONS.INACTIVE} className="icon" size="14" />
          </button>
        </>
      ) : (
        <>
          {copy && copyText ? (
            <div className="column is-1 offset">
              <span className="btn primary-btn-outline copy-btn">
                <CopyButton
                  testID={`${testID}-copy`}
                  className="icon flatten"
                  value={copyText}
                />
              </span>
            </div>
          ) : (
            <>{!link && <div className="column is-1 offset" />}</>
          )}
          {permission && link && link.pathname && (
            <Can do={permission}>
              <div className="column column is-1 offset">
                <Link to={link}>
                  <span className="btn primary-btn-outline add-btn">
                    <i className="fa fa-plus"></i>
                  </span>
                </Link>
              </div>
            </Can>
          )}
          {permission && (
            <Can do={permission}>
              <button
                onClick={triggerEdit}
                className={
                  !!editing
                    ? 'disable-edit-btn'
                    : 'btn primary-btn-outline edit-btn'
                }
                data-testid={`${testID}-edit`}
              >
                <Icon icon={ICONS.EDIT} className="icon" size="17" />
              </button>
            </Can>
          )}
        </>
      )}
    </div>
  );
};

EditBtn.defaultProps = {
  editing: false,
  copy: false,
  testID: 'xEditBtn',
};

EditBtn.propTypes = {
  copy: PropTypes.bool,
  copyText: PropTypes.string,
  editing: PropTypes.bool,
  link: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  permission: PropTypes.string,
  sectionTitle: PropTypes.string,
  testID: PropTypes.string,
  triggerEdit: PropTypes.func,
};

export default EditBtn;
