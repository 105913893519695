import React from "react";

export default function LoadingComponent({ loading }) {
  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          opacity: 1,
          fontSize: "1rem",
        }}
      >
        <div />
        <div>Loading...</div>
      </div>
    );
  }
  return null;
}
