// Modules
import React from "react";
// Components
import Table from "../../../Table/Table";
import columnBuilder from "../../../Vouchers/columnBuilder";

const AccountVouchers = ({ hash }) => {
  return (
    <Table
      columns={columnBuilder}
      endpoint={`accounts/${hash}/vouchers`}
      nested={true}
      tablename="Account Vouchers"
    />
  );
};

export default AccountVouchers;
