//Modules
import React, { Component } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
// Components
import {
  GoBack,
  Header,
  Loading,
  LoadingButton,
  ToastError,
  ToastSuccess,
} from "../Utilities/Components";
// Configs
import { API } from "../Config/cfg.js";

const reactSelectStyles = {
  control: (base) => ({
    ...base,
    height: 34,
    outline: "none",
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #ced1dd",
    backgroundColor: "#fff",
    color: state.isSelected ? "#6e7071" : "#1e202b",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menuList: (base) => ({
    ...base,
    color: "#1e202b",
  }),
  placeholder: (base) => ({
    ...base,
    paddingBottom: 10,
    color: "#1e202b",
  }),
  valueContainer: (base) => ({
    ...base,
    height: 34,
  }),
};

class AddPlatform extends Component {
  state = {
    platforms: null,
    loading: true,
    isSubmitting: false,
    PlatformHash: null,
    errors: {},
  };

  componentDidMount = () => {
    this.getPlatforms();
  };

  getPlatforms = () => {
    API.get("/platforms?installed=false")
      .then((res) => {
        this.setState({
          platforms: res.data.Data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  };

  putPlatform = () => {
    this.setState({ isSubmitting: true });
    const { hash, history } = this.props;
    const { PlatformHash } = this.state;

    API.put(`locations/${hash}/platforms`, { PlatformHash })
      .then((res) => {
        ToastSuccess("Platform has been Added");
        this.setState({ isSubmitting: false });
        history.push(`/locations/${hash}`);
      })
      .catch((error) => {
        ToastError(error);
      });
  };

  render() {
    const { platforms, PlatformHash, isSubmitting, loading, errors } =
      this.state;

    if (loading) {
      return <Loading errors={errors} />;
    }

    const selectOptions = () => {
      let array = [];
      if (platforms) {
        platforms.map((item) =>
          array.push({
            value: item.Hash,
            label: `${item.PlatformType.Name} ${item.DeviceIdentifier}`,
          })
        );
      }
      return array;
    };

    return (
      <>
        <GoBack
          history={this.props.history}
          defaultPath="/locations"
          defaultName="Locations"
        />
        <Header title="Add Existing Platform" />
        <div className="form-outline form-create">
          <div className="columns">
            <div className="column is-6">
              <h2 className="form-title">Select Platform</h2>
              <Select
                options={selectOptions()}
                onChange={(opt) => this.setState({ PlatformHash: opt.value })}
                styles={reactSelectStyles}
                menuShouldScrollIntoView={false}
              />
            </div>
            <div className="column is-6">
              <h2 className="form-title">New Platform</h2>
              <br />
              <p>
                Need to create a new platform?{" "}
                <Link to="/platform/create">Click here</Link>
              </p>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <LoadingButton
                loading={isSubmitting}
                disabled={PlatformHash === null}
                btnAction={() => this.putPlatform()}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddPlatform;
