import { toast } from 'react-toastify';

const ToastError = error => {
  // Work around for inconsistent error messages
  if (typeof error === 'string') {
    return toast.error(error);
  } else {
    const { Status, Message } = error.response.data;
    toast.error(`Error: ${Message ? Message : Status}`);
  }
};

export default ToastError;
