/* eslint no-console: ["error", { allow: [ "error"] }] */

// Modules
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// Components
import { EditableForm } from '../../Forms/EditableForm';
import { EditableInput, EditableSelect } from '../../Forms/EditableInputs';
import { reqBoolProp, reqFuncProp } from '../../Utilities/PropTypes';
import { Loading } from '../../Utilities/Components';
// Permissions
import { GROUPS_EDIT } from '../../Permissions/Permissions';
// Config
import { API } from '../../Config/cfg.js';

const GroupContact = ({
  data,
  editing,
  hash,
  toggleEdit,
  postGroup,
  isSubmitting,
}) => {
  const [groupsList, setGroupsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const { Name, Parent } = data;

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = () => {
    API.get('/groups?all=true')
      .then(res => {
        setGroupsList(res.data);
        setLoading(false);
      })
      .catch(error => {
        setErrors(error.response.data);
        console.error(error);
      });
  };

  const callPostGroup = (req, actions) => {
    // Sends req to POST call in parent
    postGroup('groups', hash, null, req, 'editingContact', actions);
  };

  if (loading) {
    return <Loading errors={errors} />;
  }

  return (
    <EditableForm
      callBack={callPostGroup}
      setValues={{
        Name: data?.Name,
        ParentHash: data?.Parent ? data?.Parent.Hash : '',
        PhoneNumber: data?.PhoneNumber,
        TaxpayerID: data?.TaxpayerID,
      }}
      customValidations={values => {
        let errors = {};
        return errors;
      }}
      editing={editing}
      permission={GROUPS_EDIT}
      triggerEdit={toggleEdit}
      editType="editingContact"
      sectionTitle="Contact"
      isSubmitting={isSubmitting}
    >
      <div className="columns">
        <div className="column">
          <EditableInput
            name="Name"
            label="Name"
            type="text"
            currentValue={data?.Name}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableSelect
            name="ParentHash"
            label="Parent Group"
            placeholder="Please select a Parent Group"
            currentValue={
              Parent ? (
                <Link to={`/groups/${data?.Parent.Hash}`}>
                  {data?.Parent.Name}
                </Link>
              ) : (
                '-'
              )
            }
            data={groupsList.Data}
            editing={editing}
            disabled={Name === 'DigitalMint Managers'}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <EditableInput
            name="PhoneNumber"
            label="Phone Number"
            type="text"
            currentValue={data?.PhoneNumber}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="TaxpayerID"
            label="Taxpayer ID"
            type="text"
            currentValue={data?.TaxpayerID}
            editing={editing}
          />
        </div>
      </div>
    </EditableForm>
  );
};

GroupContact.propTypes = {
  editing: reqBoolProp,
  postGroup: reqFuncProp,
  toggleEdit: reqFuncProp,
};

export default GroupContact;
