// Modules
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Components
import { GenericForm, FormikContext } from "../Forms/Form";
import { FormInput } from "../Forms/FormInputs";
import { ToastError } from "../Utilities/Components";
// Configs
import { API } from "../Config/cfg.js";

const AntiFraudReview = ({ hash, history, location: { state } }) => {
  const [phone, setPhone] = useState("");
  const [accountHash, setAccountHash] = useState("");
  const [fullName, setFullName] = useState("");
  const [externalId, setExternalId] = useState("");

  useEffect(() => {
    API.get(`/compliance/reviews/${hash}`)
      .then((res) => {
        const { Account, ExternalVerificationId } = res.data;
        if (Account) {
          const { Phone, Hash, FullName } = Account;
          setPhone(Phone);
          setAccountHash(Hash);
          setFullName(FullName);
        }
        if (ExternalVerificationId) {
          setExternalId(ExternalVerificationId);
        }
      })
      .catch((error) => {
        ToastError(error);
      });
  }, []);

  const generateFormTitle = () => {
    switch (state.type) {
      case 5:
        return "Tier 2 Review";
      case 6:
        return "Over 55 Review";
      case 7:
        return "Plaid Lightning";
      default:
        return "Review";
    }
  };

  return (
    <GenericForm
      endpoint={`compliance/reviews/actions/${hash}/review`}
      postReq={true}
      setValues={{
        Narrative: "",
        Approved: undefined,
      }}
      formTitle={generateFormTitle()}
      goBack={{
        show: true,
        setHistory: history,
        setDefaultPath: `/compliance/reviews`,
        setDefaultName: "Pending Accounts",
      }}
      toastMsg="Review has been submitted"
      customValidations={(values) => {
        let errors = {};
        if (!values.Narrative) {
          errors.Narrative = "is required";
        }
        if (values.Approved === undefined) {
          errors.Approved = "could not be defined";
        }
        return errors;
      }}
      overrideSubmit
    >
      <FormikContext.Consumer>
        {({ errors, touched, setFieldValue }) => (
          <div className="columns">
            <div className="column is-5">
              <h2 className="form-title">Account Details</h2>
              <div className="columns">
                <div className="column is-4">
                  <p className="item-label">Full Name</p>
                  <Link to={`/accounts/${accountHash}`}>{fullName}</Link>
                </div>
                <div className="column is-4">
                  <p className="item-label">Phone Number</p>
                  <p>{phone}</p>
                </div>
                {externalId && (
                  <div className="column is-4">
                    <p className="item-label">External Link</p>
                    <Link
                      to={{
                        pathname: `https://idv-dashboard.plaid.com/flow/sessions/${externalId}`,
                      }}
                      target="_blank"
                    >
                      View on Plaid
                    </Link>
                  </div>
                )}
              </div>
              <div className="columns">
                <div className="column">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    label="Narrative"
                    name="Narrative"
                    component="textarea"
                    placeholder="Enter Narrative..."
                    type="text"
                  />
                </div>
              </div>
              <div className="columns is-vcentered">
                <div className="column is-narrow">
                  <button
                    type="submit"
                    className="btn primary-btn"
                    onClick={() => setFieldValue("Approved", true)}
                  >
                    Approve
                  </button>
                </div>
                <div className="column is-narrow">
                  <button
                    type="submit"
                    className="btn red"
                    onClick={() => setFieldValue("Approved", false)}
                  >
                    Deny
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

export default AntiFraudReview;
