export const TxCancelReason = [
  { value: 0, label: "Changed Mind" },
  { value: 1, label: "Required Info Not Available" },
  { value: 2, label: "Customer Questionable" },
  { value: 3, label: "Signing Out" },
  { value: 5, label: "Timeout" },
  { value: 6, label: "Failed To Process" },
  { value: 7, label: "OFAC Match" },
  { value: 8, label: "ID Info Mismatch" },
  { value: 9, label: "User Disabled" },
  { value: 10, label: "Training" },
  { value: 11, label: "Larger Purchases Desired" },
  { value: 14, label: "Fraud Detection" },
];

// Note: the following value has been removed as it interferes with ACH
//  { value: 4, label: "Other" },
