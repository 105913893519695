import React from 'react';
import { Formik } from 'formik';
// Modal Components
import ContactForms from './ContactForms';
// Components
import { GenericModal } from '../../../Utilities/Components';

const ContactModal = ({
  isOpen,
  putAccount,
  account,
  templates,
  closeModal,
}) => {
  const notifyType = [
    {
      name: 'Email',
      hash: 'email',
    },
    {
      name: 'SMS Template',
      hash: 'sms',
    },
    {
      name: 'Custom SMS',
      hash: 'custom',
    },
  ];

  const findHash = values => {
    return templates.filter(t => t.Message === values.smsMsg)[0].Hash;
  };

  return (
    <Formik
      initialValues={{
        type: 'email',
        subject: '',
        smsMsg: '',
        emailMsg: '',
        freeformMsg: '',
        emailFrom: '',
      }}
      validate={values => {
        let errors = {};
        if (!values.type) {
          errors.type = 'is required';
        }
        if (values.type === 'email' && !values.subject) {
          errors.subject = 'is required';
        }
        if (values.type === 'email' && !values.emailMsg) {
          errors.emailMsg = 'is required';
        }
        if (values.type === 'email' && !values.emailFrom) {
          errors.emailFrom = 'is required';
        }
        if (values.type === 'sms' && !values.smsMsg) {
          errors.smsMsg = 'is required';
        }
        if (values.type === 'custom' && !values.freeformMsg) {
          errors.freeformMsg = 'is required';
        }
        if (
          values.type === 'custom' &&
          values.freeformMsg &&
          values.freeformMsg.split('').length >= 148
        ) {
          errors.freeformMsg = `can't exceed 147 characters`;
        }
        return errors;
      }}
      onSubmit={async (values, actions) => {
        if (values.type === 'sms') {
          values.smsMsg = await findHash(values);
        }
        await putAccount(values, actions);
      }}
      children={({ values, touched, errors, handleSubmit, isSubmitting }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={() => {
            closeModal('isContactOpen');
          }}
          viewMsg={account}
          headerMsg="Send  Notification"
          btnText="Submit"
          btnAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <ContactForms
            values={values}
            types={notifyType}
            templates={templates}
            errors={errors}
            touched={touched}
          />
        </GenericModal>
      )}
    />
  );
};

export default ContactModal;
