// Modules
import React, { useState } from 'react';

// Components
import {
  GenericModal,
  ToastError,
  ToastSuccess,
} from '../../../Utilities/Components';
// Configs
import { API } from '../../../Config/cfg';

const DeleteCommissionModal = ({ isOpen, commission, closeModal }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const deleteCommission = () => {
    setSubmitting(true);
    API.delete(`/group-commissions/${commission.Hash}`)
      .then(() => {
        closeModal();
        ToastSuccess('Commission has been retired');
      })
      .catch(error => {
        ToastError(error);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={closeModal}
      headerMsg="Warning: Retiring Commission"
      viewMsg="This action RETIRES this commission from the group. Please ensure you selected the correct commission."
      btnText="Retire"
      btnAction={async () => {
        await deleteCommission();
      }}
      isSubmitting={isSubmitting}
    />
  );
};

export default DeleteCommissionModal;
