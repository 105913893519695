/* eslint no-console: ["error", { allow: [ "error"] }] */

// Modules
import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// Settings Components
import ChangePassword from './Forms/ChangePassword';
import AddAddress from './Forms/AddAddress';
import PreferredAddress from './Forms/PreferredAddress';
import PreferredPhone from './Forms/PreferredPhone';
import AddPhoneNumber from './Forms/AddPhoneNumber';
// Components
import { Loading } from '../../Utilities/Components';
// Configs
import { API } from '../../Config/cfg.js';
import TwoFactorConfiguration from './2FA/TwoFactorConfiguration';
import { AccountContext } from '../../Authentication/AccountContext';

class AccountSettings extends Component {
  state = {
    data: {},
    loading: true,
    errors: {},
  };

  componentDidMount() {
    this.getAuth();
  }

  getAuth = () => {
    API.get('/auth')
      .then(res => {
        this.setState({
          loading: false,
          data: res.data,
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ loading: false, errors: error.response.data });
      });
  };

  render() {
    const { loading, errors } = this.state;

    if (loading) {
      return <Loading errors={errors} />;
    }

    return (
      <AccountContext.Consumer>
        {({ account, setAccount }) => (
          <Tabs className="tabs-container">
            <TabList className="columns tab-list">
              <Tab className="column tab">Security</Tab>
              <Tab className="column tab disabled" disabled>
                Address
              </Tab>
              <Tab className="column tab disabled" disabled>
                Phone
              </Tab>
            </TabList>
            <TabPanel>
              <div className="security">
                <ChangePassword hash={account.Hash} />
                <br />
                <TwoFactorConfiguration
                  account={account}
                  setAccount={setAccount}
                />
              </div>
            </TabPanel>

            <TabPanel>
              <div className="columns">
                <div className="column">
                  <AddAddress />
                </div>
                <div className="column">
                  <PreferredAddress addresses={account.Addresses} />
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div>
                <div className="column">
                  <AddPhoneNumber hash={account.Hash} />
                </div>
                <div className="column">
                  <PreferredPhone phoneNumbers={account.PhoneNumbers} />
                </div>
              </div>
            </TabPanel>
          </Tabs>
        )}
      </AccountContext.Consumer>
    );
  }
}

export default AccountSettings;
