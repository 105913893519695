import { useState } from 'react';

export default function (callback, time = 0) {
  const [throttleTimer, setThrottleTimer] = useState(false);

  const throttle = () => {
    if (throttleTimer) return;

    setThrottleTimer(true);

    setTimeout(() => {
      callback();

      setThrottleTimer(false);
    }, time);
  };

  return throttle;
}
