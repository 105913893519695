/* COMPONENT OVERVIEW
- GETs a single account's data from API
- Renders account's data
  - Passes data to children and Renders it in tabular layout
- POST updates to single account's data
*/
import React, { useEffect, useState } from 'react';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
import Addresses from './Addresses';
import AccountActions from './AccountActions';
import DefaultWallet from './DefaultWallet';
import { AccountBan, AccountHold, AccountApprovalTier } from './AccountPills';
import Limits from './Limits';
import Asset from './../../Asset/Asset';
import Phones from './Phones';
import Overview from './Overview';
import Compliance from './Compliance';
import ListIDs from './ListIDs';
import Settings from './Settings';
import TabsContainer from './TabsContainer';
import SingleServiceAccount from './SingleServiceAccount';
import {
  GoBack,
  Loading,
  Header,
  ToastError,
  ToastSuccess,
} from '../../Utilities/Components';
import { getDefaultTab, setDefaultTab } from '../../Utilities/Formatters';
import { ICONS } from '../../Icons/IconsPath';
import barcode from './../../images/barcode.svg';
import { API } from '../../Config/cfg.js';

export default function SingleAccount({ location, match, history }) {
  const [data, setData] = useState({
    Account: null,
    IDs: null,
    InheritedSettings: null,
    PurchaseLimit: null,
  });
  const [isEditing, setIsEditingObject] = useState({
    editingAddress: false,
    editingOverview: false,
    editingCompliance: false,
    editingIDs: false,
    editingPhones: false,
    editingWallet: false,
  });
  const [loadingError, setLoadingError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getAccount();
  }, [location.pathname]);

  const setIsEditing = editing => {
    setIsEditingObject({ ...isEditing, ...editing });
  };

  const toggleEdit = editing => {
    setIsEditingObject({ ...isEditing, [editing]: !isEditing[editing] });
  };

  const getAccount = async () => {
    setIsLoading(true);

    let response;
    try {
      response = await API.get(`/accounts/${match.params.hash}`);

      setData(response.data);
    } catch (error) {
      setLoadingError(error?.response?.data || { Code: response.status });
    } finally {
      setIsLoading(false);
    }
  };

  const postAccount = async (
    endpoint,
    Hash,
    params,
    data,
    editing,
    actions
  ) => {
    setIsLoading(true);

    const url = params
      ? `${endpoint}/${Hash}/${params}`
      : `${endpoint}/${Hash}`;

    try {
      await API.post(url, data);
      // TODO: is account not returned?
      await getAccount();

      ToastSuccess(`You successfully updated this account`);
    } catch (error) {
      ToastError(error);
    } finally {
      setIsLoading(false);
      setIsEditing({ [editing]: false });

      if (actions) {
        actions.setSubmitting(false);
      }
    }
  };

  const postPreferredAddress = async Hash => {
    setIsLoading(true);

    try {
      await API.post(`/addresses/${Hash}`, { Preferred: true });
      // TODO: is account not returned?
      await getAccount();

      ToastSuccess('Address is now listed as Primary');
    } catch (error) {
      ToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const putDefaultWallet = async (Hash, WalletAddress, editing, actions) => {
    setIsLoading(true);

    try {
      await API.put(`/accounts/${Hash}/default-wallets`, {
        WalletAddress,
        ProductSymbol: 'BTCUSD',
      });
      // TODO: is account not returned?
      await getAccount();

      ToastSuccess('Default Wallet is now set');
    } catch (error) {
      ToastError(error);
    } finally {
      setIsLoading(false);
      setIsEditing({ [editing]: false });
      if (actions) {
        actions.setSubmitting(false);
      }
    }
  };

  const deleteDefaultWallet = async (DefaultWalletHash, editing, actions) => {
    setIsLoading(true);

    try {
      await API.delete(`/default-wallets`, { data: { DefaultWalletHash } });
      // TODO: is account not returned?
      await getAccount();

      ToastSuccess('Default Wallet removed successfully');
    } catch (error) {
      ToastError(error);
    } finally {
      setIsLoading(false);
      setIsEditing({ [editing]: false });

      if (actions) {
        actions.setSubmitting(false);
      }
    }
  };

  const checkBarcodeID = data => {
    return (data.IDs && data.IDs[0]?.FirstURL) || data;
  };

  const mapImages = () => {
    return (data.IDs || []).reduce((carousel, image) => {
      carousel.push({ image: image.FirstURL });

      if (image.SecondURL) {
        carousel.push({ image: image.SecondURL });
      }

      return carousel;
    }, []);
  };

  if (isLoading || loadingError) {
    return <Loading errors={loadingError} />;
  } else if (data.Account?.ServiceAccount) {
    return <SingleServiceAccount data={data} history={history} />;
  } else {
    return (
      <>
        <GoBack
          history={history}
          defaultPath="/accounts"
          defaultName="Accounts"
        />
        <div className="columns is-vcentered is-multiline">
          <div className="column is-narrow">
            <Header
              icon={ICONS.USER}
              title={`${data.Account.FirstName} ${data.Account.LastName}`}
            />
          </div>
          <AccountBan Account={data.Account} />
          <AccountApprovalTier Account={data.Account} />
          <AccountHold Account={data.Account} />
        </div>
        <div className="columns">
          <div className="column single-page-actions">
            <Asset
              images={mapImages()}
              placeholder={checkBarcodeID(barcode)}
              scale={checkBarcodeID('50%')}
              info={
                data.IDs[0]?.FirstURL
                  ? 'Click to view full image'
                  : 'No Image available'
              }
            />
            <AccountActions
              history={history}
              Account={data.Account}
              data={data}
              isSubmitting={isSubmitting}
              toggleSubmitting={() => setIsSubmitting(!isSubmitting)}
              getAccount={getAccount}
            />
          </div>
          <Tabs
            className="column is-7 tabs-container nested-tabs"
            defaultIndex={getDefaultTab('accountOverview')}
            onSelect={index => setDefaultTab('accountOverview', index)}
          >
            <TabList className="columns tab-list">
              <Tab className="column tab">Overview</Tab>
              <Tab className="column tab">Compliance</Tab>
              <Tab className="column tab">IDs</Tab>
              <Tab className="column tab">Limits</Tab>
              <Tab className="column tab">Settings</Tab>
            </TabList>

            <TabPanel>
              <Overview
                editing={isEditing.editingOverview}
                data={data.Account}
                postAccount={postAccount}
                toggleEdit={toggleEdit}
                isSubmitting={isSubmitting}
              />
              <Phones
                data={data.Account}
                editing={isEditing.editingPhones}
                postAccount={postAccount}
                toggleEdit={toggleEdit}
              />

              <Addresses
                accountHash={data.Account.Hash}
                data={data.Account.Addresses || []}
                editing={isEditing.editingAddress}
                postAccount={postAccount}
                toggleEdit={toggleEdit}
                postPreferredAddress={postPreferredAddress}
                isSubmitting={isSubmitting}
              />
              <DefaultWallet
                data={data.Account}
                editing={isEditing.editingWallet}
                putDefaultWallet={putDefaultWallet}
                deleteDefaultWallet={deleteDefaultWallet}
                toggleEdit={toggleEdit}
                isSubmitting={isSubmitting}
              />
            </TabPanel>
            <TabPanel>
              <Compliance
                data={data.Account}
                editing={isEditing.editingCompliance}
                postAccount={postAccount}
                toggleEdit={toggleEdit}
                isSubmitting={isSubmitting}
              />
            </TabPanel>
            <TabPanel>
              <ListIDs
                data={data}
                editing={isEditing.editingIDs}
                postAccount={postAccount}
                toggleEdit={toggleEdit}
                hash={data.Account.Hash}
              />
            </TabPanel>

            <TabPanel>
              <Limits data={data.Account.Limits} />
            </TabPanel>
            <TabPanel>
              <Settings
                data={data.Account}
                settings={data.InheritedSettings}
                limit={data.PurchaseLimit}
              />
            </TabPanel>
          </Tabs>
        </div>
        <TabsContainer data={data} history={history} />
      </>
    );
  }
}
