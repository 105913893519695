// Modules
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import Table from '../../../Table/Table';
import { SeverityPill } from '../../../Utilities/Components/TablePills';
// Helpers - Formatters
import { capitalize } from 'lodash';
import { formatDateTime } from '../../../Utilities/Formatters';
import { reqStrProp } from '../../../Utilities/PropTypes';

const LocationEvents = ({ hash, history }) => {
  return (
    <Table
      columns={columnBuilder}
      endpoint={`locations/${hash}/events`}
      nested={true}
      tablename="Events"
      filter={true}
      history={history}
    />
  );
};

LocationEvents.propTypes = {
  hash: reqStrProp,
};

export default LocationEvents;

const columnBuilder = [
  {
    Header: 'Type',
    accessor: 'Type',
    Cell: ({ row: { original } }) => capitalize(original.Type),
  },
  {
    Header: 'Severity',
    accessor: 'Severity',
    Cell: ({ row: { original } }) => SeverityPill(original.Severity),
  },
  {
    Header: 'Event',
    accessor: 'Text',
  },
  {
    Header: 'Date',
    accessor: 'Timestamp',
    Cell: ({ row: { original } }) => formatDateTime(original.Timestamp),
  },
  {
    Header: 'Platform',
    accessor: 'Platform',
    Cell: ({ row: { original } }) => {
      return original.Platform ? (
        <Link
          className="table-pill"
          to={`/platforms/${original.Platform.Hash}`}
        >
          {original.Platform.Name}
        </Link>
      ) : (
        '-'
      );
    },
    sortMethod: 'Platform',
    default: true,
  },
  {
    Header: 'Note',
    accessor: 'Note',
    Cell: ({ row: { original } }) => (original.Note ? original.Note : '-'),
  },
];
