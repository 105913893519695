// Modules
import React from 'react';
import { Link } from 'react-router-dom';
import { formatPrice, formatDateTime } from '../Utilities/Formatters';

const columnBuilder = [
  {
    Header: 'Short Code',
    accessor: 'ShortCode',
  },
  {
    Header: 'Amount',
    accessor: 'Amount',
    Cell: ({ row: { original } }) => formatPrice(original.Amount),
  },
  {
    Header: 'Account',
    accessor: 'Account.Hash',
    style: { textAlign: 'left' },
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/accounts/${original.Account.Hash}`}>
        {original.Account.FullName}
      </Link>
    ),
  },
  {
    Header: 'Purchased',
    accessor: 'Voucher.CreatedAt',
    Cell: ({ row: { original } }) => formatDateTime(original.CreatedAt),
  },
  {
    Header: 'Redemption Date',
    accessor: 'RedemptionDate',
    Cell: ({ row: { original } }) => formatDateTime(original.RedemptionDate),
  },
  {
    Header: 'Transaction',
    accessor: 'Transaction',
    Cell: ({ row: { original } }) =>
      original.Transaction?.Hash ? (
        <Link
          className="table-pill"
          to={`/transactions/${original.Transaction.Hash}`}
        >
          {original.Transaction.InvoiceCode}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    Header: 'Location',
    accessor: 'Location.Hash',
    Cell: ({ row: { original } }) =>
      original.Location ? (
        <Link
          className="table-pill"
          to={`/locations/${original.Location.Hash}`}
        >
          {`${original.Location.Address.StateCode},
            ${original.Location.Address.City}, ${original.Location.Address.Street}`}
        </Link>
      ) : (
        '-'
      ),
    sortMethod: 'CityState',
  },
  {
    Header: 'Group',
    accessor: 'Group.Hash',
    Cell: ({ row: { original } }) =>
      original.Group ? (
        <Link className="table-pill" to={`/groups/${original.Group.Hash}`}>
          {original.Group.Name}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    Header: 'Platform',
    accessor: 'Plaltform.Hash',
    Cell: ({ row: { original } }) =>
      original.Group ? (
        <Link
          className="table-pill"
          to={`/platforms/${original.Platform.Hash}`}
        >
          {original.Platform.DeviceIdentifier}
        </Link>
      ) : (
        '-'
      ),
  },
];

export default columnBuilder;
