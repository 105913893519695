const eddKeys = {
  Activation: {
    Label: 'Start Date',
    Type: 'date',
  },
  'Account.Hash': {
    Label: 'Account Hash',
    Type: 'string',
  },
  Code: {
    Label: 'Code',
    Type: 'string',
  },
  'Country.Code': {
    Label: 'Country',
    Type: 'string',
    Options: [
      {
        Display: 'United States',
        Value: 'US',
      },
      {
        Display: 'Canada',
        Value: 'CA',
      },
    ],
  },
  DueDate: {
    Label: 'Due Date',
    Type: 'date',
  },
  'Account.FirstName': {
    Label: 'First Name',
    Type: 'string',
  },
  'Account.LastName': {
    Label: 'Last Name',
    Type: 'string',
  },
  DecisionText: {
    Label: 'Decision',
    Type: 'string',
    Options: [
      {
        Display: 'None',
        Value: 'null',
      },
      {
        Display: 'Maintain Risk',
        Value: 0,
      },
      {
        Display: 'Risk set to 1 Month',
        Value: 1,
      },
      {
        Display: 'Risk set to 3 Months',
        Value: 2,
      },
      {
        Display: 'Risk set to 6 Months',
        Value: 3,
      },
      {
        Display: 'Remove',
        Value: 4,
      },
    ],
  },
  ReviewPhaseText: {
    Label: 'Review Phase',
    Type: 'string',
  },
};
export default eddKeys;
