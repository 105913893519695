import React from "react";
import { Link } from "react-router-dom";
// Components
import { EditBtn } from "../../Utilities/Components";
// Helpers - Formatters
import {
  formatPrice,
  formatPercent,
  formatDateTime,
} from "../../Utilities/Formatters";

const Settings = ({ data, settings, limit }) => {
  const {
    Hash,
    CreatedAt,
    EnrollmentLocation,
    MostRecentActivity,
    MostRecentLocation,
    TotalCashIn,
    TotalCashOut,
  } = data;

  const { MarkdownRate, MarkupRate } = settings;

  return (
    <React.Fragment>
      <EditBtn
        editing={null}
        triggerEdit={null}
        sectionTitle="Settings Overview"
      />
      <div className="form-outline inline-edit-form">
        <div className="columns">
          <div className="column form-row">
            <label className="item-label">Date Created: </label>
            <p>{formatDateTime(CreatedAt)}</p>
          </div>
          <div className="column form-row">
            <label className="item-label">Last Seen: </label>
            <p>
              {MostRecentActivity ? formatDateTime(MostRecentActivity) : "-"}
            </p>
          </div>
        </div>

        <div className="columns">
          <div className="column form-row">
            <label className="item-label">Enrollment Location: </label>
            <p>
              {EnrollmentLocation ? (
                <Link to={`/locations/${EnrollmentLocation.Hash}`}>
                  {EnrollmentLocation.BusinessName}
                </Link>
              ) : (
                "-"
              )}
            </p>
          </div>
          <div className="column form-row">
            <label className="item-label">Most Recent Location: </label>
            <p>{MostRecentLocation ? MostRecentLocation.BusinessName : "-"}</p>
          </div>
        </div>

        <div className="columns">
          <div className="column form-row">
            <label className="item-label">Total Cash In: </label>
            <p>{formatPrice(TotalCashIn)}</p>
          </div>
          <div className="column form-row">
            <label className="item-label">Total Cash Out: </label>
            <p>{formatPrice(TotalCashOut)}</p>
          </div>
        </div>

        <div className="columns">
          <div className="column form-row">
            <label className="item-label">Purchase Limits:</label>
            <p>{limit ? formatPrice(limit) : "-"}</p>
          </div>
          <div className="column form-row">
            <label className="item-label">Markdown: </label>
            <p>{formatPercent(MarkdownRate)}</p>
          </div>
        </div>

        <div className="columns">
          <div className="column form-row">
            <label className="item-label">Markup: </label>
            <p>{formatPercent(MarkupRate)}</p>
          </div>
          <div className="column form-row">
            <label className="item-label">Chainalysis: </label>
            <p>
              <a href={`https://kyt.chainalysis.com/users/${Hash}`}>
                Chainalysis Profile
              </a>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Settings;
