// Modules
import React from 'react';
// Components
import InfiniteLoader from '../../../Utilities/Components/InfiniteLoader';
import Image from './Image';

const Images = ({ hash }) => {
  return (
    <InfiniteLoader
      url={`/accounts/${hash}/images`}
      emptyMessage="No photos avaliable"
      limitBy={8}
    >
      {data => data.map(image => <Image key={image.Hash} image={image} />)}
    </InfiniteLoader>
  );
};

export default Images;
