import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
// Components
import {
  GenericModal,
  ToastError,
  ToastSuccess,
} from '../../Utilities/Components';
import { FormSelect } from '../../Forms/FormInputs';
// Config
import { API } from '../../Config/cfg';

const AssignEmployeeModal = ({
  title,
  endpoint,
  hash,
  account,
  isOpen,
  closeModal,
  assignee,
}) => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    // get employees
    const extension = endpoint.replace('/', '_');
    API.get(`/compliance/employees/assign-to/${extension}`)
      .then(res => setEmployees(res.data))
      .catch(err => {
        ToastError(err.response.data.Message);
      });
  }, [endpoint]);

  const postEmployee = async values => {
    const { AssignedTo } = values;
    const req = {
      AssignedTo,
    };
    return await API.post(`/${endpoint}/${hash}/assign-to`, req)
      .then(res => {
        if (!res.data.AssignedTo) {
          ToastSuccess(`${title} has been unassigned`);
        } else {
          const { FullName } = res.data.AssignedTo;
          ToastSuccess(`${title} has been assigned to ${FullName}`);
        }
        closeModal();
      })
      .catch(err => {
        ToastError(err.response.data.Message);
      });
  };

  return (
    <Formik
      initialValues={{
        AssignedTo: '',
      }}
      onSubmit={values => postEmployee(values)}
      children={({ touched, errors, handleSubmit, isSubmitting }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={() => {
            closeModal('isInviteOpen');
          }}
          viewMsg={account}
          headerMsg="Assign Employee"
          btnText="Submit"
          btnAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <Form className="columns form-outline is-centered">
            <div className="column is-11">
              {assignee && (
                <div>
                  <p className="item-label">Currently Assigned To:</p>
                  <p>{assignee.FullName}</p>
                </div>
              )}
              <FormSelect
                errors={errors}
                touched={touched}
                label="Employee"
                name="AssignedTo"
                placeholder={!assignee ? 'Select An Employee' : false}
                options={
                  <>
                    {assignee && (
                      <option key="" value="">
                        Unassign
                      </option>
                    )}
                    {employees.map(item => (
                      <option key={item.AccountHash} value={item.AccountHash}>
                        {item.FullName}
                      </option>
                    ))}
                  </>
                }
              />
            </div>
          </Form>
        </GenericModal>
      )}
    />
  );
};

export default AssignEmployeeModal;
