// Modules
import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// Helpers - Formatters
import { formatDateTime } from '../../Utilities/Formatters';

const columnBuilder = [
  {
    Header: 'Name',
    accessor: 'Account.FullName',
    style: { textAlign: 'left ' },
    Cell: ({ row: { original } }) =>
      original.Account ? (
        <Link className="table-pill" to={`/accounts/${original.Account.Hash}`}>
          {original.Account.FullName}
        </Link>
      ) : null,
  },
  {
    Header: 'Account Phone',
    accessor: 'Account.PreferredPhoneNumber.Phone',
  },
  {
    Header: 'Banned By',
    accessor: 'AuthorAccount.FullName',
  },
  {
    Header: 'Ban Reason',
    accessor: 'Reason',
    width: 250,
    Cell: ({ row: { original } }) => (
      <div
        className="asset-container"
        data-tooltip-content={original.Reason}
        data-tooltip-id="reason"
      >
        <span className="table-pill status-banned">{original.Reason}</span>
        <ReactTooltip id="reason" place="bottom" effect="solid" />
      </div>
    ),
  },
  {
    Header: 'Narrative',
    accessor: 'Narrative',
    width: 250,
    style: {
      whiteSpace: 'unset',
      textAlign: 'left',
      fontSize: '11px',
    },
  },
  {
    Header: 'Ban Date',
    Cell: ({ row: { original } }) => formatDateTime(original.CreatedAt),
  },
  {
    Header: 'Country of Origin',
    accessor: 'Country.Code',
  },
];
export default columnBuilder;
