import React from 'react';
// Components
import { EditableForm } from '../../Forms/EditableForm';
import {
  EditableInput,
  EditableSelect,
  EditableGroupSelect,
} from '../../Forms/EditableInputs';
import { OrButton } from '../../Utilities/Components';
// Helpers - Formatters
import {
  formatPrice,
  formatServiceFrequency,
} from '../../Utilities/Formatters';
import {
  locationProps,
  reqArrProp,
  reqBoolProp,
  reqFuncProp,
} from '../../Utilities/PropTypes';
// Permissions
import { LOCATIONS_EDIT } from '../../Permissions/Permissions';

const CashManagement = props => {
  const {
    data,
    editing,
    toggleEdit,
    postLocation,
    creditAccounts,
    debitAccounts,
    cashCouriers,
    isSubmitting,
  } = props;

  const frequencyTypes = [
    { value: 1, Hash: 'Twice a week', Name: 'Twice a week' },
    { value: 2, Hash: 'Every other week', Name: 'Every other week' },
    { value: 3, Hash: 'Weekly', Name: 'Weekly' },
    { value: 4, Hash: 'Monthly', Name: 'Monthly' },
    { value: 5, Hash: 'On Demand', Name: 'On Demand' },
  ];

  const callPostLocation = (values, actions) => {
    let { cashCollectProperty, cashCollectHash } = formatCashCollect(values);
    const ServiceFrequency = formatServiceFrequency(
      values.ServiceFrequency
    ).value;
    let req = {
      CreditAccountHash: values.CreditAccount,
      [cashCollectProperty]: cashCollectHash,
      ACHPull: JSON.parse(values.ACHPull),
      ServiceFrequency,
    };
    postLocation('locations', data.Hash, req, 'editingCashMgmt', actions);
  };

  const formatCashCollect = data => {
    // Ugly but allows us to have one dropdown for CashCourier/DebitAccountHash
    let cashCollectProperty, cashCollectHash;
    let type = data.CashCourier ? data.CashCourier : data.DebitAccount;

    if (type.includes('CashCourierHash')) {
      cashCollectProperty = 'CashCourierHash';
      cashCollectHash = type.replace(cashCollectProperty, '');
    } else if (type.includes('DebitAccountHash')) {
      cashCollectProperty = 'DebitAccountHash';
      cashCollectHash = type.replace(cashCollectProperty, '');
    } else {
      cashCollectProperty = null;
      cashCollectHash = null;
    }
    return {
      cashCollectProperty,
      cashCollectHash,
    };
  };

  const setCashCollect = (cashCourier, debitAccount) => {
    // Another Hack for setting CashCourier/DebitAccountHash
    let cashCollectName, cashCollectType, cashCollectValue;
    if (cashCourier) {
      cashCollectType = 'CashCourier';
      cashCollectValue = `CashCourierHash${cashCourier.Hash}`;
      cashCollectName = cashCourier ? cashCourier.Name : '-';
    } else {
      cashCollectType = 'DebitAccount';
      cashCollectValue = debitAccount
        ? `DebitAccountHash${debitAccount.Hash}`
        : '';
      cashCollectName = debitAccount ? `${debitAccount.Name}` : '-';
    }
    return { cashCollectType, cashCollectValue, cashCollectName };
  };

  const {
    CreditAccount,
    DebitAccount,
    CashCourier,
    ACHPull,
    CashAtLocation,
    ServiceFrequency,
  } = data;

  let { cashCollectType, cashCollectValue, cashCollectName } = setCashCollect(
    CashCourier,
    DebitAccount
  );
  return (
    <EditableForm
      callBack={callPostLocation}
      setValues={{
        [cashCollectType]: cashCollectValue,
        ACHPull,
        ServiceFrequency: formatServiceFrequency(ServiceFrequency).Hash,
        CreditAccount: CreditAccount && CreditAccount.Hash,
        CashAtLocation: CashAtLocation ? formatPrice(CashAtLocation) : '-',
      }}
      customValidations={values => {
        let errors = {};

        return errors;
      }}
      editing={editing}
      permission={LOCATIONS_EDIT}
      triggerEdit={toggleEdit}
      editType="editingCashMgmt"
      sectionTitle="Cash Management"
      isSubmitting={isSubmitting}
    >
      <div className="columns">
        <div className="column">
          <EditableSelect
            name="CreditAccount"
            label="Credit Account"
            placeholder="Please select an Account"
            currentValue={
              CreditAccount
                ? `${CreditAccount.Name} * ${CreditAccount.AccountNumber}`
                : '-'
            }
            data={creditAccounts ? creditAccounts : '-'}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableGroupSelect
            name={cashCollectType ? cashCollectType : '-'}
            label="Cash Collection"
            optLabel="CashCourierHash"
            optLabelTwo="DebitAccountHash"
            placeholder="Please select a Group"
            currentValue={cashCollectName ? cashCollectName : '-'}
            options={cashCouriers ? cashCouriers : '-'}
            optionsTwo={debitAccounts ? debitAccounts : '-'}
            editing={editing}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <EditableSelect
            name="ACHPull"
            label="Transfered By"
            currentValue={ACHPull ? 'DigitalMint' : 'Location Partners'}
            data={[
              {
                Hash: 'true',
                Name: 'DigitalMint',
                value: true,
              },
              {
                Hash: 'false',
                Name: 'Location Partners',
                value: false,
              },
            ]}
            editing={editing}
          />
        </div>
        <div className="column">
          <EditableInput
            name="CashAtLocation"
            label="Cash In"
            currentValue={CashAtLocation ? formatPrice(CashAtLocation) : '-'}
            type="text"
            editing={editing}
            disabled
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <EditableSelect
            name="ServiceFrequency"
            label="Frequency"
            placeholder="Please select collection frequency"
            currentValue={formatServiceFrequency(ServiceFrequency).Hash}
            data={frequencyTypes}
            editing={editing}
          />
        </div>
      </div>
      {editing && (
        <OrButton
          path={{
            pathname: '/locations/add-debit-account',
            state: {
              ACHPull,
              CreditAccountHash: CreditAccount && CreditAccount.Hash,
              endpoint: `/locations/${data.Hash}`,
            },
          }}
          name="Add Debit Account"
          btnColor="secondary-btn-outline"
        />
      )}
    </EditableForm>
  );
};

CashManagement.propTypes = {
  creditAccounts: reqArrProp,
  cashCouriers: reqArrProp,
  debitAccounts: reqArrProp,
  editing: reqBoolProp,
  postLocation: reqFuncProp,
  toggleEdit: reqFuncProp,
  data: locationProps,
};

export default CashManagement;
