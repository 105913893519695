import Data from './Data';

export default class ComplianceOperator extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      Name: { type: 'string' },
    };
  }
}
