import React from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
// Endpoints
import ProductEnpoints from "./ProductEndpoints";
import EventEndpoints from "./EventEndpoints";
// Components
import Images from "./Images";
import TabCan from "../../Permissions/TabCan";
//
// Helpers - Formatters
import { getDefaultTab, setDefaultTab } from "../../Utilities/Formatters";
// Permissions
import { ACCOUNT_VIEW } from "../../Permissions/Permissions";
import { PermissionsContext } from "../../Permissions/PermissionContext";

const TabsContainer = (props) => {
  const { data } = props;
  return (
    <PermissionsContext.Consumer>
      {(userPermiss) => (
        <Tabs
          className="tabs-container"
          defaultIndex={getDefaultTab("platform")}
          onSelect={(index) => setDefaultTab("platform", index)}
        >
          <TabList className="columns tab-list">
            <Tab className="column tab">Products</Tab>
            {TabCan(userPermiss, ACCOUNT_VIEW) && (
              <Tab className="column tab">Photos</Tab>
            )}
            <Tab className="column tab">Events</Tab>
          </TabList>

          <TabPanel>
            <ProductEnpoints data={data} />
          </TabPanel>

          {TabCan(userPermiss, ACCOUNT_VIEW) && (
            <TabPanel>
              <Images hash={data.Hash} />
            </TabPanel>
          )}

          <TabPanel>
            <EventEndpoints hash={data.Hash} />
          </TabPanel>
        </Tabs>
      )}
    </PermissionsContext.Consumer>
  );
};

export default TabsContainer;
