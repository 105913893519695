// Modules
import React from "react";
import columnBuilder from "./columnBuilder";
// Components
import Table from "../../Table/Table";
import { ACCOUNTING_COMMISS_CREATE } from "../../Permissions/Permissions";

const ACHAccounts = (props) => {
  return (
    <Table
      history={props.history}
      columns={columnBuilder}
      endpoint="accounting/ach"
      filter={true}
      tablename="ACH"
      downloadable={false}
      actions={[
        {
          name: "Create ACH Account",
          link: "/accounting/add-debit-account",
          permission: ACCOUNTING_COMMISS_CREATE,
        },
      ]}
    />
  );
};

export default ACHAccounts;
