import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ToastError, ToastSuccess } from 'src/Utilities/Components';
import { useApi } from 'src/Services/Api';

const CSVDownload = ({ params }) => {
  const filterValue = params.currentFilters
    ? decodeURIComponent(params.currentFilters)
    : '';

  const [handleDownload, isLoading] = useApi({
    method: 'get',
    url: params.endpoint,
    onTimeout: () => {
      ToastSuccess(
        'Your request is processing and will be emailed to you shortly'
      );
    },
    onError: message => ToastError(message),
    onSuccess: data => {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: 'text/csv;charset=utf-8;' })
      );

      const link = document.createElement('a');

      link.href = url;

      link.setAttribute('download', `${params.endpoint}.csv`);
      link.setAttribute('data-testid', 'link');

      document.body.appendChild(link);

      link.click();
    },
  });

  return (
    // CSV download link w/pageSize & filters
    <div
      data-tooltip-content="Downloads Table Data"
      data-tooltip-id="download"
      className="column is-narrow is-desktop is-hidden-mobile"
    >
      {/* "Download CSV" Hidden on mobile for now. Would stack if shown. */}
      <button
        className="btn blue"
        data-for="download"
        data-testid="button"
        disabled={isLoading}
        onClick={() =>
          handleDownload(null, {
            csv: true,
            f: filterValue,
          })
        }
      >
        {isLoading && (
          <i className="fa fa-sync fa-spin" style={{ marginRight: '5px' }} />
        )}
        {isLoading ? 'Generating' : 'Download CSV'}
      </button>
      <ReactTooltip id="download" place="top" effect="solid" />
    </div>
  );
};

export default CSVDownload;
