import React from 'react';
// Helpers - Formatters
import { formatAccountPillStatus } from '../../Utilities/Formatters';

export const AccountBan = ({ Account }) => {
  return Account.Banned ? (
    <p className="pill ban">{`Banned - ${Account.BanReasonText}`}</p>
  ) : null;
};

export const AccountHold = ({ Account }) => {
  return Account.Holds && !Account.Banned
    ? Account.Holds.map((hold, index) => (
        <div key={index} className="column is-narrow">
          {hold.Blocking && (
            <div className="pill blocking">
              {hold.HoldText} - {hold.ReasonText}
            </div>
          )}
        </div>
      ))
    : null;
};

export const AccountApprovalTier = ({ Account }) => {
  // Returns the CSS className & text desc for Status Pills
  const { text, attr } = formatAccountPillStatus(Account.ApprovalTier);
  return !Account.Banned && text ? (
    <div className="column is-narrow">
      <p className={`pill ${attr}`}>Approved - {text}</p>
    </div>
  ) : null;
};
