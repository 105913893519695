// Modules
import React from "react";
// Components
import { GenericForm, FormikContext } from "../Forms/Form";
import { FormInput } from "../Forms/FormInputs";
// Assets
import AltLogo from "../images/dm.svg";

const ForgotPassword = (props) => {
  return (
    <div className="security">
      <img
        className="logo"
        src={AltLogo}
        alt="logo"
        style={{ marginBottom: "30px" }}
      />
      <GenericForm
        endpoint="account/password/token"
        postReq={true}
        setValues={{
          Account: "",
        }}
        formTitle={null}
        subTitle="Get password reset instructions"
        goBack={{
          show: true,
          setHistory: props.history,
          setDefaultPath: "/accounts",
          setDefaultName: "Login",
        }}
        toastMsg="A password reset token has been sent to your email"
      >
        <FormikContext.Consumer>
          {(props) => (
            <FormInput
              errors={props.errors}
              touched={props.touched}
              name="Account"
              type="email"
              label="Email"
              placeholder="example@digitalmint.io"
            />
          )}
        </FormikContext.Consumer>
      </GenericForm>
      <br />
      <small>
        Forgot one-time password? Contact us at{" "}
        <a href="mailto:contact@digitalmint.io">contact@digitalmint.io</a>.
      </small>
    </div>
  );
};

export default ForgotPassword;
