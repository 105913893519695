// Modules
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import { formatDateTime } from '../Utilities/Formatters';
import InfiniteLoader from '../Utilities/Components/InfiniteLoader';

const PlatformScreenshots = () => {
  return (
    <InfiniteLoader
      url={'/platforms/screenshots'}
      emptyMessage="No photos avaliable"
      limitBy={16}
    >
      {data =>
        data.map((image, i) => (
          <div key={i} className="column is-3 test">
            <div className="card">
              <div className="card-image">
                <a
                  href={image.ImageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={image.ImageUrl} alt="" />
                </a>
              </div>
              <div className="content">
                <span>
                  Platform:{' '}
                  <Link to={`/platforms/${image.PlatformHash}`}>
                    {image.PlatformDeviceID}
                  </Link>{' '}
                </span>
                <p>{formatDateTime(image.CreatedAt)}</p>
              </div>
            </div>
          </div>
        ))
      }
    </InfiniteLoader>
  );
};

export default PlatformScreenshots;
