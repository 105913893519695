// Modules
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// Components
import { FormInput, FormSelect } from '../Forms/FormInputs';
import { LoadingButton } from '../Utilities/Components';
// Helpers - Formatters
import { formatDate } from '../Utilities/Formatters';
import { reqFuncProp, objProp } from '../Utilities/PropTypes';
// Data
import GenderOptions from '../Utilities/Data/GenderOptions';
import StateLists from '../Utilities/Data/StatesList';

const typeOfID = [
  { Name: "Driver's License", Hash: 0 },
  { Name: 'State/Provincial ID', Hash: 1 },
  { Name: 'Passport', Hash: 2 },
];

const UploadIDForm = ({ data, putAccountImages }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        DocumentSerial: '',
        FirstName: data ? data.Account.FirstName : undefined,
        LastName: data ? data.Account.LastName : undefined,
        Middle: '', // optional
        Suffix: '', // optional
        Gender: '',
        DOB: data ? formatDate(data.Account.DOB, 'YYYY-MM-DD', true, '') : '',
        Expiry:
          data.IDs.length >= 1
            ? formatDate(data.IDs[0].Expiry, 'YYYY-MM-DD', true, '')
            : '',
        Issue:
          data.IDs.length >= 1
            ? formatDate(data.IDs[0].Issue, 'YYYY-MM-DD', true, '')
            : '',
        Lifetime: false, // optional
        Street: '',
        Street2: '', // optional
        City: '',
        Zip: '',
        StateCode: '',
        CountryCode: 'US',
        Type: '',
      }}
      validate={values => {
        let errors = {};
        for (let [key, value] of Object.entries(values)) {
          if (
            !value &&
            key !== 'Street2' &&
            key !== 'Lifetime' &&
            key !== 'Middle' &&
            key !== 'Suffix'
          ) {
            errors[key] = 'is required';
          }
        }
        // remove validation on expiry if Lifetime is true
        if (values.Lifetime) {
          delete errors.Expiry;
        }
        return errors;
      }}
      onSubmit={(values, actions) => {
        putAccountImages(values, actions);
      }}
      children={({ errors, touched, isSubmitting, values }) => (
        <>
          <Form className="form-outline">
            <h2 className="form-title">Add ID Data</h2>
            <section className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="First Name"
                  name="FirstName"
                  type="text"
                  placeholder="Enter First Name"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Middle Name"
                  name="Middle"
                  type="text"
                  placeholder="Enter Middle Name (optional)"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Last Name"
                  name="LastName"
                  type="text"
                  placeholder="Enter Last Name"
                />
              </div>
              <div className="column is-2">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Suffix"
                  name="Suffix"
                  placeholder="Suffix (optional)"
                  type="text"
                />
              </div>
              <ReactTooltip place="bottom" effect="solid" />
            </section>

            <section className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Date of Birth"
                  name="DOB"
                  type="date"
                />
              </div>
              <div className="column">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="Gender"
                  name="Gender"
                  placeholder="Select a Gender"
                  options={
                    <>
                      {GenderOptions.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>
            </section>
            <section className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Street Address"
                  name="Street"
                  type="text"
                  placeholder="Enter Street Address"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Street Address Line 2"
                  name="Street2"
                  type="text"
                  placeholder="Apt, Suite, etc"
                />
              </div>
            </section>

            <section className="columns">
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="City"
                  name="City"
                  type="text"
                  placeholder="Enter City"
                />
              </div>
              <div className="column">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="State"
                  name="StateCode"
                  placeholder="Select State"
                  options={StateLists.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Postal Code"
                  name="Zip"
                  type="text"
                  placeholder="Enter Postal Code"
                />
              </div>
            </section>

            <section className="columns">
              <div className="column">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="ID Type"
                  name="Type"
                  placeholder="Type of ID"
                  options={typeOfID.map(item => (
                    <option key={item.Hash} value={item.Hash}>
                      {item.Name}
                    </option>
                  ))}
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Document Serial"
                  name="DocumentSerial"
                  type="text"
                  placeholder="Enter Document Serial"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="ID Issue Date"
                  name="Issue"
                  type="date"
                />
              </div>
              <div className="column">
                <FormInput
                  errors={errors}
                  touched={touched}
                  label="ID Expiration Date"
                  name="Expiry"
                  type="date"
                  disabled={values.Lifetime}
                />
              </div>
              <div className="column is-1 has-text-centered">
                <label className="item-label">Lifetime</label>
                <Field name="Lifetime" type="checkbox" />
              </div>
            </section>
            <LoadingButton loading={isSubmitting} />
          </Form>
        </>
      )}
    />
  );
};

UploadIDForm.propTypes = {
  data: objProp,
  putAccountImages: reqFuncProp,
};

export default UploadIDForm;
