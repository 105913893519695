// Modules
import React, { useEffect, useState } from 'react';
import Table from '../../Table/Table';
import { LOCATIONS_EDIT } from '../../Permissions/Permissions';
import NotificationModal from './Modals/NotificationModal';
// Components
import { ToastError, ToastSuccess } from '../../Utilities/Components';
// Config
import { API } from '../../Config/cfg';

const GroupNotifications = ({ hash }) => {
  const [selectedRow, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [triggerAPI, setTrigger] = useState(false);

  useEffect(() => {
    if (triggerAPI) {
      setTrigger(false);
    }
  }, [triggerAPI]);

  const notifications = [
    { Name: 'Maintenance Mode Entered', Value: 0 },
    { Name: 'Maintenance Mode Exited', Value: 1 },
    { Name: 'Cash Collected', Value: 2 },
    { Name: 'Kiosk Locked', Value: 3 },
    { Name: 'Powered Off', Value: 4 },
    { Name: 'Powered On', Value: 5 },
    { Name: 'Rebooted', Value: 6 },
    { Name: 'Unavailable', Value: 7 },
    { Name: 'Available', Value: 8 },
    { Name: 'Cash Deposited', Value: 9 },
    { Name: 'Cash Cassette Removed', Value: 10 },
    { Name: 'Cash Cassette Inserted', Value: 11 },
    { Name: 'Transaction Initiated', Value: 12 },
    { Name: 'Transaction Executed', Value: 13 },
    { Name: 'Created', Value: 14 },
    { Name: 'Activated', Value: 15 },
    { Name: 'Dectivated', Value: 16 },
    { Name: 'Installed', Value: 17 },
    { Name: 'Screen Capture', Value: 18 },
  ];

  const closeModal = () => {
    setShowModal(false);
  };

  // put up form data against an existing array of user's notifications...
  const sortSubscriptions = async formData => {
    let queue = [];
    let valueQueue = [];

    // find new subs
    const newSubs = formData.Notifications.filter(
      i => !selectedRow.Types.includes(i)
    );

    // find removed subs
    const removedSubs = selectedRow.Types.filter(
      i => !formData.Notifications.includes(i)
    );

    newSubs.forEach(sub => {
      const newNotifs = findNotification(notifications, sub);
      queue = [...queue, postNotification];
      valueQueue = [...valueQueue, newNotifs.Value];
    });

    removedSubs.forEach(sub => {
      const removed = findNotification(notifications, sub);
      queue = [...queue, deleteNotification];
      valueQueue = [...valueQueue, removed.Value];
    });

    for (let i = 0; i <= queue.length; i++) {
      let result = await queue[i](valueQueue[i]);
      if (result.status !== 200) {
        ToastError(result);
        break;
      }
      if (i + 1 === queue.length) {
        ToastSuccess('Notifications have been updated');
        setTrigger(true);
      }
      setShowModal(false);
    }
  };

  const findNotification = (list, subscription) => {
    return list.find(notif => {
      return notif.Name === subscription;
    });
  };

  // add new entries
  const postNotification = notif =>
    API.post(`/groups/${hash}/notifications`, {
      Action: 0,
      Email: selectedRow.Email,
      Name: selectedRow.Name,
      Type: notif,
    }).catch(err => err);

  // remove missing entries
  const deleteNotification = notif =>
    API.post(`/groups/${hash}/notifications`, {
      Action: 1,
      Email: selectedRow.Email,
      Type: notif,
    }).catch(err => err);

  return (
    <>
      <Table
        columns={columnBuilder(setSelected, setShowModal)}
        endpoint={`groups/${hash}/notifications`}
        tablename="Notifications"
        nested={true}
        actions={[
          {
            name: `Add New Email`,
            link: '/groups/add-group-notification',
            params: hash,
            permission: LOCATIONS_EDIT,
          },
        ]}
        triggerAPI={triggerAPI}
      />
      <NotificationModal
        submitValues={sortSubscriptions}
        isOpen={showModal}
        user={selectedRow}
        closeModal={closeModal}
      />
    </>
  );
};

const columnBuilder = (setSelected, setShowModal) => [
  {
    Header: 'Email',
    accessor: 'Email',
    minWidth: 200,
    Cell: ({ row: { original } }) => original.Email || '-',
  },
  {
    Header: 'Notification',
    accessor: 'Types',
    Cell: ({ row: { original } }) => original.Types.join(', '),
  },
  {
    Header: 'Edit',
    accessor: 'Edit',
    Cell: ({ row: { original } }) => (
      <div>
        <button
          className="column-btn create"
          onClick={() => {
            setSelected(original);
            setShowModal(true);
          }}
        >
          Edit
        </button>
      </div>
    ),
  },
];

export default GroupNotifications;
