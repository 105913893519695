import React, { useState } from 'react';
import { ButtonAction } from '../../Utilities/Components';
import { ICONS } from '../../Icons/IconsPath';
import { DecommissionModal, TextBlastModal } from './../Modals';
import { LOCATIONS_EDIT } from '../../Permissions/Permissions';

function LocationActions({ decommissionLocation, data, history }) {
  const [decommissionModalActive, setDecommission] = useState(false);
  const [textBlastModalActive, setTextBlastModalActive] = useState(false);
  const { Address, Hash, PhoneNumber } = data;
  return (
    <>
      <DecommissionModal
        businessName={data.BusinessName}
        isOpen={decommissionModalActive}
        postDecommission={() => decommissionLocation(setDecommission)}
        closeModal={setDecommission}
      />
      <div className="column">
        <div className="columns is-centered btn-icons is-mobile is-multiline">
          {!data.Decommissioned && (
            <ButtonAction
              icon={ICONS.WARNING}
              toolTip="Decommission Location"
              className="secondary-btn-outline"
              onClick={() => setDecommission(true)}
              permission={LOCATIONS_EDIT}
            />
          )}
        </div>
      </div>
      <DecommissionModal
        businessName={data.BusinessName}
        isOpen={decommissionModalActive}
        postDecommission={() => decommissionLocation(setDecommission)}
        closeModal={setDecommission}
      />
      <TextBlastModal
        address={Address}
        hash={Hash}
        phoneNumber={PhoneNumber}
        isOpen={textBlastModalActive}
        closeModal={() => setTextBlastModalActive(false)}
        history={history}
      />
    </>
  );
}

export default LocationActions;
