import React, { Component } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// Components
import { ButtonAction } from '../../Utilities/Components';
// Icons
import { ICONS } from '../../Icons/IconsPath';
// Modals
import {
  TxCancelModal,
  TxFreezeModal,
  TxUnFreezeModal,
  TxForceSettleModal,
  TxReconcileModal,
  SMSReceiptModal,
} from './TransactionModal';
import { BanAddressModal } from './../../Accounts/SinglePage/Modals/index';
// Permissions
import {
  TX_FORCE_SETTLE,
  TX_FREEZE,
  TX_CANCEL,
  TX_EDIT,
  ACCOUNT_CONTACT,
  COMPLIANCE_BAN,
} from './../../Permissions/Permissions';

class TransactionActions extends Component {
  state = {
    isFreezeOpen: false,
    isUnFreezeOpen: false,
    isCancelOpen: false,
    isForceSettleOpen: false,
    isSMSReceiptOpen: false,
    isReconcileOpen: false,
    isBanAddressOpen: false,
  };

  openModal = name => {
    this.setState({ [name]: true });
  };

  closeModal = name => {
    this.setState({ [name]: false });
  };

  render() {
    const {
      isFreezeOpen,
      isUnFreezeOpen,
      isCancelOpen,
      isForceSettleOpen,
      isSMSReceiptOpen,
      isReconcileOpen,
      isBanAddressOpen,
    } = this.state;

    const { data, postTransaction, putCompliance } = this.props;

    // YOU CAN NOT DO ANYTHING ON TX
    const TERMINAL_STATE =
      data.ReconiliationDate || (data.State >= 307 && data.State !== 560);

    // UNFREEZE OR CANCEL TX
    const FROZEN_STATE = !data.ReconiliationDate && data.State === 560;

    // RECONCILE TX
    const RECONCILIATION_STATE =
      !(
        data.State === 305 ||
        data.State === 307 ||
        data.State === 310 ||
        data.State === 350 ||
        data.State === 410
      ) &&
      data.State >= 420 &&
      !data.ReconiliationDate;

    return (
      <React.Fragment>
        <TxCancelModal
          isOpen={isCancelOpen}
          closeModal={this.closeModal}
          postTransaction={postTransaction}
        />
        <TxFreezeModal
          isOpen={isFreezeOpen}
          closeModal={this.closeModal}
          postTransaction={postTransaction}
        />
        <TxUnFreezeModal
          isOpen={isUnFreezeOpen}
          closeModal={this.closeModal}
          postTransaction={postTransaction}
        />
        <TxForceSettleModal
          isOpen={isForceSettleOpen}
          closeModal={this.closeModal}
          postTransaction={postTransaction}
        />
        <TxReconcileModal
          isOpen={isReconcileOpen}
          closeModal={this.closeModal}
          cashCollected={data.Collected}
          postTransaction={postTransaction}
        />
        <SMSReceiptModal
          isOpen={isSMSReceiptOpen}
          closeModal={this.closeModal}
          postTransaction={postTransaction}
          phone={
            data.Account.PreferredPhoneNumber &&
            data.Account.PreferredPhoneNumber.Phone
          }
        />
        <BanAddressModal
          isOpen={isBanAddressOpen}
          closeModal={this.closeModal}
          hash={data.Hash}
          isTransaction={true}
          txAddress={data.WithdrawalAddress}
          putAccount={putCompliance}
        />
        <div className="column">
          <div className="columns is-centered btn-icons is-mobile is-multiline">
            {!TERMINAL_STATE && (
              <>
                {FROZEN_STATE && (
                  <ButtonAction
                    icon={ICONS.UNLOCK}
                    toolTip="UnFreeze Transaction"
                    className="secondary-btn-outline"
                    onClick={() => this.openModal('isUnFreezeOpen')}
                    permission={TX_FREEZE}
                  />
                )}
                {!FROZEN_STATE && (
                  <React.Fragment>
                    <ButtonAction
                      icon={ICONS.LOCK}
                      toolTip="Freeze Transaction"
                      className="primary-btn-outline"
                      onClick={() => this.openModal('isFreezeOpen')}
                      permission={TX_FREEZE}
                    />
                    <ButtonAction
                      icon={ICONS.FORCE_SETTLE}
                      toolTip="Force Settle Transaction"
                      className="secondary-btn-outline"
                      onClick={() => this.openModal('isForceSettleOpen')}
                      permission={TX_FORCE_SETTLE}
                    />
                  </React.Fragment>
                )}
                <ButtonAction
                  icon={ICONS.WARNING}
                  toolTip="Cancel Transaction"
                  className="red-btn-outline"
                  onClick={() => this.openModal('isCancelOpen')}
                  permission={TX_CANCEL}
                />
              </>
            )}
            {TERMINAL_STATE && (
              <ButtonAction
                icon={ICONS.RECEIPT}
                toolTip="Send Receipt to Customer"
                className="primary-btn-outline"
                onClick={() => this.openModal('isSMSReceiptOpen')}
                permission={ACCOUNT_CONTACT}
              />
            )}
            {RECONCILIATION_STATE && (
              <ButtonAction
                icon={ICONS.UNDO}
                toolTip="Manually reconcile a transaction"
                className="red-btn-outline"
                onClick={() => this.openModal('isReconcileOpen')}
                permission={TX_EDIT}
              />
            )}
            <ButtonAction
              icon={ICONS.BAN_ADDRESS}
              toolTip="Ban Wallet Address"
              size={23}
              className="red-btn-outline"
              onClick={() => this.openModal('isBanAddressOpen')}
              permission={COMPLIANCE_BAN}
            />
          </div>
          <ReactTooltip place="bottom" effect="solid" />
        </div>
      </React.Fragment>
    );
  }
}

export default TransactionActions;
