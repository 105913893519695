// Modules
import React, { Component } from 'react';
import { Formik } from 'formik';
// Components
import Table from '../../../Table/Table';
import {
  GenericModal,
  ToastError,
  ToastSuccess,
} from '../../../Utilities/Components';
// PropTypes
import { arrProp } from '../../../Utilities/PropTypes';
// Permissions
import Can from '../../../Permissions/Can';
import { ACCOUNTING_REPORTS_EDIT } from '../../../Permissions/Permissions';
// Configs
import { API } from '../../../Config/cfg.js';

class EmailRecipients extends Component {
  state = {
    callAPI: false,
    isOpen: false,
    hash: '',
    email: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.callAPI === true) {
      this.setState({ callAPI: false });
    }
  }

  openModal = (hash, email) => {
    this.setState({ isOpen: true, hash, email });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  deleteEmails(uuid, req) {
    const { apiEndpoint } = this.props;
    const { email } = this.state;
    API.delete(`/${apiEndpoint}/${uuid}`, req)
      .then(res => {
        ToastSuccess(`Email ${email} has been removed`);
        this.setState({
          callAPI: true,
          isOpen: false,
          hash: '',
        });
      })
      .catch(error => {
        ToastError(error);
      });
  }

  render() {
    const { name, apiEndpoint, uuid } = this.props;
    const { callAPI, isOpen, hash, email } = this.state;
    return (
      <React.Fragment>
        <Table
          columns={columnBuilder(this.openModal)}
          endpoint={`groups/${uuid}/${apiEndpoint}`}
          nested={true}
          tablename={`${name} Emails`}
          triggerAPI={callAPI}
          actions={[
            {
              name: `Add ${name} Email`,
              link: `/groups/add-${apiEndpoint}`,
              params: uuid,
              permission: ACCOUNTING_REPORTS_EDIT,
            },
          ]}
        />
        <Formik
          initialValues={{
            Email: email,
          }}
          onSubmit={values => this.deleteEmails(hash, values)}
          children={({ handleSubmit, isSubmitting }) => (
            <GenericModal
              isSubmitting={isSubmitting}
              modalIsOpen={isOpen}
              headerMsg={`Remove ${name} Email`}
              viewMsg={`Email: ${email}`}
              btnMsg={`By clicking 'Remove Email' the current email ${email} on this group will be removed`}
              btnText="Remove Email"
              btnAction={handleSubmit}
              closeModal={() => this.closeModal()}
            />
          )}
        />
      </React.Fragment>
    );
  }
}

EmailRecipients.propTypes = {
  data: arrProp,
};

export default EmailRecipients;

const columnBuilder = openModal => [
  {
    Header: 'Email',
    accessor: 'Email',
  },
  {
    Header: 'Action',
    Cell: ({ row: { original } }) => (
      <Can do={ACCOUNTING_REPORTS_EDIT}>
        <button
          className="column-btn remove"
          onClick={() => openModal(original.Hash, original.Email)}
        >
          Remove Email
        </button>
      </Can>
    ),
  },
];
