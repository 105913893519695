/* eslint no-console: ["error", { allow: [ "error"] }] */

// Modules
import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
// Components
import { FormInput, FormSelect } from '../Forms/FormInputs';
import {
  Header,
  ToastSuccess,
  ToastError,
  Loading,
} from './../Utilities/Components';
import { toast } from 'react-toastify';
import TextBlastRecipient from '../Utilities/Components/TextBlastReceipient';
import { GenericModal } from '../Utilities/Components';
import MessageModal from './MessageModal';
// Helpers
import { API } from '../Config/cfg.js';
import { isStringDate } from '../Utilities/Funcs';
// Styles
import './TextBlast.scss';

const FILTER_LIST = [
  { key: 'created_before', label: 'Account created before', date: true },
  { key: 'created_after', label: 'Account created after', date: true },
  { key: 'born_after', label: 'Customer born after', date: true },
  { key: 'born_before', label: 'Customer born before', date: true },
  {
    key: 'last_transaction_date',
    label: 'Has not transacted since',
    date: true,
  },
  { key: 'volume_less', label: 'Lifetime volume less than' },
  { key: 'volume_more', label: 'Lifetime volume more than' },
  { key: 'transactions_less', label: 'Completed transactions less than' },
  { key: 'transactions_more', label: 'Completed transactions more than' },
  { key: 'area', label: 'Phone area code' },
  { key: 'city', label: 'Transacted in city' },
  { key: 'state', label: 'Transacted in state' },
  { key: 'zip', label: 'Transacted in zip code' },
];

const TextBlast = ({ history }) => {
  const [activeFilters, setActiveFilters] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [indexStartRange, setIndexStartRange] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams();

    for (let k in activeFilters) {
      params.append(activeFilters[k].key, activeFilters[k].value);
    }

    if (activeFilters.length > 0) {
      API.get('textblast', { params })
        .then(res => {
          setAccounts(res.data);
        })
        .catch(e => ToastError(e))
        .finally(() => setLoading(false));
    } else {
      setAccounts([]);
      setLoading(false);
    }
  }, [activeFilters]);

  useEffect(() => {
    if (history.location.state) {
      const pastFilters = FILTER_LIST.map(({ key, label }) => {
        if (history.location.state[key]) {
          return {
            label,
            key,
            value: history.location.state[key],
          };
        } else return false;
      }).filter(fMap => fMap);

      setActiveFilters(pastFilters);
    }
  }, [history]);

  useEffect(() => {
    if (!!message) {
      setIsMessageModalOpen(false);
      setIsConfirmModalOpen(true);
    }
  }, [message]);

  const onConfirmMessage = async () => {
    setIsSubmitting(true);
    try {
      const req = {
        Msg: message,
        Recipients: accounts.map(acct => acct.PhoneNumber),
      };

      const res = await API.post('/textblast/notify', req);
      if (res.status === 200) {
        setActiveFilters([]);
        setAccounts([]);
        setMessage('');
        setIndexStartRange(0);
        setIsConfirmModalOpen(false);

        if (
          res.data['Failed Numbers'] &&
          res.data['Failed Numbers'].length > 1
        ) {
          if (res.data['Failed Numbers'].length === accounts.length) {
            throw Error('There was a problem with the notification service');
          } else {
            throw Error(
              `Could not contact all recipients. ${res.data['Failed Numbers'].length} accounts excluded.`
            );
          }
        } else {
          ToastSuccess('SMS Message Sent!');
        }
      }
    } catch (error) {
      if (error?.response?.data) {
        ToastError(error);
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onAddFilter = form => {
    // formik value are inserting "\", so we need to handle it here
    const { filter, value } = form;
    const formattedFilter = JSON.parse(filter.replace(/\\/, ''));
    const found = activeFilters.find(
      element => element.key === formattedFilter.key
    );
    if (!found) {
      const { label, key, date } = formattedFilter;
      let formattedValue = value;
      if (formattedFilter?.date && isStringDate(formattedValue)) {
        const valueDate = new Date(value);
        formattedValue = valueDate.toISOString();
      }
      setActiveFilters([
        ...activeFilters,
        { label, key, date, value: formattedValue },
      ]);
    }
  };

  const onRemoveFilter = value => {
    setIndexStartRange(0);
    setActiveFilters(
      activeFilters.filter(element => element.filer !== value.filter)
    );
  };

  const paginate = modifier => {
    setIndexStartRange(indexStartRange + modifier);
  };

  return (
    <div id="textBlast" className="form-outline form-create">
      <Header title="Text Blast" />
      <Formik
        enableReinitialize
        initialValues={{
          filter: '',
          value: '',
        }}
        children={({ values, errors, touched, handleReset }) => (
          <>
            <h2 className="form-title">Criteria</h2>
            <Form className="columns is-left is-vcentered">
              <div className="column is-4">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="Filter"
                  name="filter"
                  placecholder="Select a Filter"
                  className="column"
                  options={
                    <>
                      <option value="" disabled>
                        Select a Filter
                      </option>
                      {FILTER_LIST.map(item => {
                        const value = JSON.stringify(item);
                        return (
                          <option key={item.key} value={value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              </div>
              {values.filter && (
                <>
                  <div className="column is-4">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Value"
                      name="value"
                      disabled={!values.filter}
                      type={JSON.parse(values.filter).date ? 'date' : ''}
                      placeholder={
                        JSON.parse(values.filter).date ? 'mm/dd/yyyy' : ''
                      }
                      pattern={
                        JSON.parse(values.filter).date
                          ? 'dd[- /.]dd[- /.]dddd'
                          : ''
                      }
                    />
                  </div>
                  <div className="column is-narrow mt-5">
                    <button
                      onClick={() => {
                        onAddFilter(values);
                        handleReset();
                      }}
                      className="btn primary-btn"
                    >
                      Add Filter
                    </button>
                  </div>
                </>
              )}
            </Form>
          </>
        )}
      ></Formik>

      {/* render selected filter keys */}
      <div className="columns is-multiline is-vcentered">
        {activeFilters.map(filter => {
          return (
            <div className="column is-4" key={filter.key}>
              <p
                key={filter.key}
                onClick={() => onRemoveFilter(filter)}
                className="selected-filter"
                data-key={filter.key}
                data-text={filter.label}
              >
                {filter.label} -{' '}
                {isStringDate(filter.value)
                  ? filter.value.split('T')[0]
                  : filter.value}
              </p>
            </div>
          );
        })}
      </div>
      {loading && <Loading />}
      {accounts.length > 0 && !loading && (
        <div>
          <div className="columns is-gapless is-marginless">
            <p className="column is-3">Name</p>
            <p className="column is-2">Phone Number</p>
            <p className="column is-2">DOB</p>
            <p className="column is-2">Created At</p>
          </div>
          {accounts
            .slice(indexStartRange, indexStartRange + 10)
            .map(details => (
              <TextBlastRecipient {...details} />
            ))}
          <div className="columns is-centered is-vcentered mt-2">
            <button
              className="btn primary-btn-outline column is-narrow"
              onClick={() => paginate(-10)}
              disabled={indexStartRange === 0}
            >
              Prev
            </button>
            <p className="column is-narrow">
              {indexStartRange + 1 >= 1 ? indexStartRange + 1 : 1} -{' '}
              {indexStartRange + 10 > accounts.length - 1
                ? accounts.length
                : indexStartRange + 10}{' '}
              of {accounts.length}
            </p>
            <button
              className="btn primary-btn-outline column is-narrow"
              onClick={() => paginate(10)}
              disabled={indexStartRange + 10 > accounts.length - 1}
            >
              Next
            </button>
          </div>
          <div className="columns">
            <div className="column is-11" />
            <button
              className="column btn primary-btn"
              onClick={() => setIsMessageModalOpen(true)}
              disabled={accounts.length < 1}
            >
              Notify
            </button>
          </div>
        </div>
      )}

      <MessageModal
        isOpen={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        onSubmit={setMessage}
      />
      <GenericModal
        modalIsOpen={isConfirmModalOpen}
        headerMsg="Confirm Message"
        btnAction={onConfirmMessage}
        btnText="Confirm"
        viewMsg={
          <p>
            Confirming will send the following message to{' '}
            <b>{accounts.length} accounts</b> via SMS. Are you sure you want to
            send this? <b>This cannot be undone.</b>
          </p>
        }
        isSubmitting={isSubmitting}
        closeModal={e => {
          e.preventDefault();
          setMessage('');
          setIsConfirmModalOpen(false);
        }}
      >
        <p className="has-text-left ml-5">{message}</p>
      </GenericModal>
    </div>
  );
};

export default TextBlast;
