// Modules
import React, { Component } from "react";
import DualListBox from "react-dual-listbox";
import { Formik, Form } from "formik";
// Components
import {
  GoBack,
  Header,
  Loading,
  LoadingButton,
  ToastError,
} from "../Utilities/Components";
import { FormInput, FormSelect } from "../Forms/FormInputs";
import { RatesOptions, ToastSuccess } from "../Utilities/Components";
// Helpers - Formatters
import { formatOptions } from "../Utilities/Formatters";
// Config
import { API } from "../Config/cfg.js";

class CreateGroup extends Component {
  state = {
    groupList: [],
    loading: true,
    hidden: true,
    permissions: [],
    permissionHashes: [],
    errors: {},
  };

  componentDidMount() {
    this.getGroups();
  }

  getGroups = () => {
    API.get("/groups?all=true")
      .then((res) => {
        this.setState({
          groupList: res.data.Data,
        });
        this.getPermissions();
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  };

  putGroup = (values, actions) => {
    const { permissionHashes } = this.state;
    let req = {
      Name: values.newGroup,
      ParentHash: values.parentGroup,
      PermissionHashes: permissionHashes,
    };
    API.put("/groups", req)
      .then((res) => {
        ToastSuccess(`You successfully created the ${res.data.Name} group`);
        this.setState({ permissionHashes: [] });
        this.props.history.push("/groups");
        actions.resetForm();
      })
      .catch((error) => {
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  getPermissions = () => {
    const url = `/permissions?categories=true`;
    API.get(url)
      .then((res) => {
        this.setState({ permissions: res.data, loading: false });
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  };

  render() {
    const rates = <RatesOptions from={4} to={40} />;
    let options = [];
    const { groupList, loading, permissions, permissionHashes, errors } =
      this.state;

    if (loading) {
      return <Loading errors={errors} />;
    }

    const {
      accounting,
      accounts,
      compliance,
      groups,
      platforms,
      keys,
      locations,
      tx,
    } = permissions;

    if (!loading) {
      options = [
        {
          label: "Accounting",
          options: formatOptions(accounting),
        },
        {
          label: "Accounts",
          options: formatOptions(accounts),
        },
        {
          label: "Compliance",
          options: formatOptions(compliance),
        },
        {
          label: "Groups",
          options: formatOptions(groups),
        },
        {
          label: "Keys",
          options: formatOptions(keys),
        },
        {
          label: "Locations",
          options: formatOptions(locations),
        },
        {
          label: "Platforms",
          options: formatOptions(platforms),
        },
        {
          label: "Transactions",
          options: formatOptions(tx),
        },
      ];
    }

    return (
      <React.Fragment>
        <GoBack
          history={this.props.history}
          defaultPath="/groups"
          defaultName="Groups"
        />
        <div id="formContainer">
          <Header title="Create Group" />
          <div className="form-outline form-create">
            <Formik
              initialValues={{
                parentGroup: "",
                newGroup: "",
                MarkupRate: "",
                MarkdownRate: "",
              }}
              validate={(values) => {
                let errors = {};
                if (!values.parentGroup) {
                  errors.parentGroup = "is required";
                }
                if (!values.newGroup) {
                  errors.newGroup = "is required";
                }
                return errors;
              }}
              onSubmit={(values, actions) => {
                this.putGroup(values, actions);
              }}
              children={({ errors, touched, isSubmitting }) => (
                <Form>
                  <h2 className="form-title">Select Group</h2>
                  <div className="columns">
                    <div className="column">
                      <FormSelect
                        errors={errors}
                        touched={touched}
                        label="Parent Group"
                        name="parentGroup"
                        placeholder="Select a Parent Group"
                        options={groupList.map((item) => (
                          <option key={item.Hash} value={item.Hash}>
                            {item.Name}
                          </option>
                        ))}
                      />
                    </div>
                    <div className="column">
                      <FormInput
                        errors={errors}
                        touched={touched}
                        label="New Group"
                        name="newGroup"
                        type="text"
                        placeholder="Enter Group Name"
                      />
                    </div>
                  </div>

                  <div className="columns is-multiline">
                    <div className="column">
                      <FormSelect
                        errors={errors}
                        touched={touched}
                        label="Markup Rate"
                        name="MarkupRate"
                        placeholder="Select Markup Rate"
                        options={rates}
                      />
                    </div>
                    <div className="column">
                      <FormSelect
                        errors={errors}
                        touched={touched}
                        label="Markdown Rate"
                        name="MarkdownRate"
                        placeholder="Select Markdown Rate"
                        options={rates}
                      />
                    </div>
                  </div>
                  <h2 className="form-title">Permissions</h2>
                  <div id="listBox">
                    <DualListBox
                      options={options}
                      selected={permissionHashes}
                      onChange={(permissionHashes) => {
                        this.setState({ permissionHashes });
                      }}
                    />
                  </div>
                  <div>
                    <LoadingButton loading={isSubmitting} />
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateGroup;
