import React from "react";

const BrokenLeaf = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="error-logo">
      <g>
        <g>
          <path
            className="cls-1"
            d="M23.79.23A27.51,27.51,0,0,0,.15,24.07C0,25.17,0,40.35,0,40.35V54.69H6.83L10,50.89c4.46-5.37,12.31-14.27,23-26.14,10.88-12,16.63-18.53,20.49-23.19L54.65.23l-1.22,1c-2.06,1.7-15.49,13.48-23,20.2C21.6,29.24,7.92,41.48,6.92,42.4l-.73.69V33.45S6.29,22.12,8.32,18A21.52,21.52,0,0,1,21.49,6.9l2-.82,10.16.23H43.76l3.11-2.84C48.58,2,50.17.62,50.41.38,50.83,0,50.5,0,38,0,31,0,24.58.12,23.79.23Z"
          />
          <path
            className="cls-2"
            d="M51.58,8.45l-3,3.44V20.6c-.07,6.87-.14,9.1-.4,10.47a21,21,0,0,1-3,7.69l-.83.88.22-11.53-.12-11.74L41.37,20l-3.15,3.61.22,10.9v11.1l-1.62.72-1.69.73.22-9.94-.11-10-3,3.47L29.16,34V48.5H26.07V43.18L26,37.7,23,41.17l-3.07,3.47V48.5H16.39l-2.32,2.76c-1.29,1.46-2.46,2.85-2.63,3.09-.31.45-.29.45,9.83.36,9.39-.09,10.27-.11,12.08-.55A27.86,27.86,0,0,0,54.1,33.41c.44-1.83.48-2.63.55-13.54,0-6.39.07-12.33,0-13.24L54.61,5Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default BrokenLeaf;
