import React from 'react';
// Components
import { GenericModal } from '../../Utilities/Components';

const DecommissionModal = ({
  isOpen,
  businessName,
  postDecommission,
  hash,
  isSubmitting,
  closeModal,
}) => {
  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={() => {
        closeModal(false);
      }}
      headerMsg="Decommissioning Location"
      viewMsg={`You are shutting down ${businessName} Location. This location will no longer be able to transact. This action cannot be undone. Are you sure?`}
      btnText="Confirm"
      btnAction={async () => {
        await postDecommission(hash);

        closeModal(false);
      }}
      isSubmitting={isSubmitting}
    />
  );
};

export default DecommissionModal;
