import React from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
// Components
import GroupLocations from "./GroupLocations";
import GroupTransactions from "./GroupTransactions";
import GroupPlatforms from "./GroupPlatforms";
import GroupCommissions from "./GroupCommissions";
import GroupMembers from "./GroupMembers";
import GroupNotifications from "./GroupNotifications";
import Documents from "./Tabs/Documents";
import EmailRecipients from "./Tabs/EmailRecipients";
import CommEmailRecipients from "./Tabs/CommEmailRecipients";
import { reqObjProp } from "./../../Utilities/PropTypes";
// Helpers - Formatters
import { getDefaultTab, setDefaultTab } from "../../Utilities/Formatters";
// Permissions
import { PermissionsContext } from "../../Permissions/PermissionContext";
import TabCan from "../../Permissions/TabCan";
import {
  ACCOUNTING_COMMISS_VIEW,
  GROUP_DOCUMENTS_VIEW,
} from "../../Permissions/Permissions";

const TabsContainer = ({ data }) => {
  return (
    <PermissionsContext.Consumer>
      {(userPermiss) => {
        return (
          <Tabs
            className="tabs-container"
            defaultIndex={getDefaultTab("groupCommissions")}
            onSelect={(index) => setDefaultTab("groupCommissions", index)}
          >
            <TabList className="columns tab-list">
              <Tab className="column tab">Locations</Tab>
              <Tab className="column tab">Transactions</Tab>
              <Tab className="column tab">Platforms</Tab>
              {TabCan(userPermiss, ACCOUNTING_COMMISS_VIEW) ? (
                <Tab className="column tab">Commissions</Tab>
              ) : null}
              <Tab className="column tab">Comm. Emails</Tab>
              <Tab className="column tab">Acct. Emails</Tab>
              <Tab className="column tab">Members</Tab>
              <Tab className="column tab">Notifications</Tab>

              {TabCan(userPermiss, GROUP_DOCUMENTS_VIEW) ? (
                <Tab className="column tab">Documents</Tab>
              ) : null}
            </TabList>
            <TabPanel>
              <GroupLocations hash={data.Hash} />
            </TabPanel>

            <TabPanel>
              <GroupTransactions hash={data.Hash} />
            </TabPanel>

            <TabPanel>
              <GroupPlatforms hash={data.Hash} />
            </TabPanel>

            {TabCan(userPermiss, ACCOUNTING_COMMISS_VIEW) && (
              <TabPanel>
                <GroupCommissions hash={data.Hash} name={data.Name} />
              </TabPanel>
            )}

            <TabPanel>
              <CommEmailRecipients
                data={data}
                uuid={data.Hash}
                name="Commission"
                apiEndpoint="commission-emails"
              />
            </TabPanel>
            <TabPanel>
              <EmailRecipients
                uuid={data.Hash}
                name="Accounting"
                apiEndpoint="accounting-emails"
              />
            </TabPanel>
            <TabPanel>
              <GroupMembers hash={data.Hash} />
            </TabPanel>
            <TabPanel>
              <GroupNotifications hash={data.Hash} />
            </TabPanel>
            {TabCan(userPermiss, GROUP_DOCUMENTS_VIEW) && (
              <TabPanel>
                <Documents hash={data.Hash} />
              </TabPanel>
            )}
          </Tabs>
        );
      }}
    </PermissionsContext.Consumer>
  );
};

TabsContainer.propTypes = {
  data: reqObjProp,
};

export default TabsContainer;
