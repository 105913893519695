/* COMPONENT OVERVIEW
- Builds routing for entire app
*/
// Modules
import React from 'react';
import { Route, Switch } from 'react-router-dom';
// Components - Model Index
import MonthlyEarnings from '../Accounting/Commissions/MonthlyEarnings';
import GroupMonthlyEarnings from '../Accounting/Commissions/GroupMonthlyEarnings';
import CashLocations from '../Accounting/CashLocations/CashLocations';
import Accounts from '../Accounts/Accounts';
import PendingAccounts from '../Accounts/PendingAccounts';
import Bans from '../Compliance/Bans/Bans';
import BanWalletAddress from '../Compliance/Bans/BanWalletAddress';
import Alerts from '../Compliance/Alerts/Alerts';
import Investigations from '../Compliance/Investigations/Investigations';
import CTRs from '../Compliance/CTRs/CTRs';
import EDDs from '../Compliance/EDDs/EDDs';
import Subpoenas from '../Compliance/Subpoenas/Subpoenas';
import PEPs from '../Compliance/PEPs/PEPs';
import Groups from '../Groups/Groups';
import Locations from '../Locations/Locations';
import Platforms from '../Platforms/Platforms';
import Transactions from '../Transactions/Transactions';
import Vouchers from '../Vouchers/Vouchers';
// Components - Single Pages
import SingleAccount from '../Accounts/SinglePage/SingleAccount';
import SingleACH from '../Accounting/ACH/SinglePage/SingleACH';
import SingleServiceAccount from './../Accounts/SinglePage/SingleServiceAccount';
import SingleAlert from '../Compliance/Alerts/SingleAlert';
import SingleEDDs from '../Compliance/EDDs/SingleEDDs';
import SingleInvestigation from '../Compliance/Investigations/SingleInvestigation';
import SingleLocation from '../Locations/SinglePage/SingleLocation';
import SingleTransaction from '../Transactions/SinglePage/SingleTransaction';
import SinglePlatform from '../Platforms/SinglePage/SinglePlatform';
import SingleProduct from '../Platforms/SinglePage/SingleProduct';
import SingleGroup from '../Groups/SinglePage/SingleGroup';
import SingleSubpoena from '../Compliance/Subpoenas/SingleSubpoena';
import SinglePEP from '../Compliance/PEPs/SinglePEP';
// Components - Search
import AccountResultsTable from '../Search/AccountResultsTable';
import TransactionResultsTable from '../Search/TransactionResultsTable';
import LocationResultsTable from '../Search/LocationResultsTable';
import PlatformResultsTable from '../Search/PlatformResultsTable';
import GroupResultsTable from '../Search/GroupResultsTable';
import SubpoenaLinkedAccountsTable from '../Search/SubpoenaLinkedAccountsTable';
import LinkAccountsTable from '../Search/LinkAccountsTable';
// Components - CRUD
import EmployeeInvite from '../Accounts/EmployeeInvite';
import CustomerInvite from '../Accounts/CustomerInvite';
import AddLocationCommission from '../Locations/AddLocationCommission';
import ServiceAccount from './../Accounts/ServiceAccount';
import GenerateAPIKeys from './../Accounts/GenerateAPIKeys';
import AddCommissionPayment from '../Groups/SinglePage/Tabs/AddCommissionPayment';
import AddGroupCommission from '../Groups/SinglePage/Commissions/AddGroupCommission';
import UploadID from './../Uploads/UploadID';
import DocUpload from '../Uploads/DocUpload';
import AddDebitAccount from './../Locations/Forms/AddDebitAccount';
import AddACHAccount from './../Accounting/ACH/AddACHAccount';
import CreateNotification from './../Groups/SinglePage/CreateNotification';
// import AccountPin from "./../Accounts/AccountPin";
import AddPlatform from '../Locations/AddPlatform';
import DenyID from './../Accounts/DenyID';
import CreateGroup from '../Groups/CreateGroup';
import CreateLocation from '../Locations/CreateLocation';
import CreateLocationNote from '../Notes/CreateLocationNote';
import CreateInvestigationNote from '../Compliance/Investigations/Tabs/CreateInvestigationNote';
import CreateNote from '../Notes/CreateNote';
import CreatePlatform from '../Platforms/CreatePlatform';
import CreateProducts from '../Platforms/CreateProducts';
import PlatformScreenshots from '../Platforms/PlatformScreenshots';
import AddAddress from '../Settings/Account/Forms/AddAddress';
import AddPhoneNumber from '../Settings/Account/Forms/AddPhoneNumber';
import AddDefaultWallet from './../Accounts/SinglePage/AddDefaultWallet';
import AddToGroup from '../Accounts/AddToGroup';
import IDReview from '../Uploads/IDReview';
import AntiFraudReview from '../Accounts/AntiFraudReview';
import AddEmailRecipient from '../Groups/SinglePage/Tabs/AddEmailRecipient';
import CreateSubpoena from '../Compliance/Subpoenas/CreateSubpoena';
import CreatePEP from '../Compliance/PEPs/CreatePEP';
// Components - Funding
import FundingWallet from '../Funding/FundingWallet';
import FundingHistory from '../Funding/FundingHistory';
// Components - Cash Collection
import CashCollection from '../Accounting/Collection/CashCollection';
// Components - ACH Accounts
import ACHAccounts from '../Accounting/ACH/ACHAccounts';
// Components - Settings
import AccountSettings from '../Settings/Account/AccountSettings';
import BusinessSettings from './../Settings/BusinessSettings';
import ComplianceSettings from './../Settings/ComplianceSettings';
import Documents from '../Settings/Documents';
import TermsOfService from './../Settings/TermsOfService';
import CustomerRefunds from './../Settings/Refunds/CustomerRefunds';
import CreateRefund from './../Settings/Refunds/CreateRefund';
import SingleRefund from './../Settings/Refunds/SingleRefund';
// Components - Text Blast
import TextBlast from '../TextBlast/TextBlast';
// Components - Routes
import {
  ErrorBoundary,
  NotFoundError,
  NotPermissioned,
} from './../Utilities/Components';
// Permissions
import PermissionedRoute from '../Permissions/PermissionedRoute';

import {
  ACCOUNTING_GROSS_COMMISS,
  ACCOUNTING_REPORTS_EDIT,
  ACCOUNTING_COMMISS_CREATE,
  ACCOUNTING_VIEW,
  ACCOUNT_CONTACT_FREEFORM,
  ACCOUNT_VIEW,
  ACCOUNT_INVITE,
  ACCOUNT_EDIT,
  COMPLIANCE_ALERTS_VIEW,
  COMPLIANCE_INVEST_VIEW,
  COMPLIANCE_INVEST_ACTION,
  COMPLIANCE_EDDS_VIEW,
  COMPLIANCE_CTR_UPLOAD,
  COMPLIANCE_PEP_VIEW,
  COMPLIANCE_PEP_ACTION,
  COMPLIANCE_VIEW,
  COMPLIANCE_SUBPOENA_VIEW,
  COMPLIANCE_SUBPOENA_ACTION,
  COMPLIANCE_APPROVE,
  FUNDING_VIEW,
  GROUPS_VIEW,
  GROUPS_EDIT,
  GROUPS_CREATE,
  KEY_EDIT,
  LOCATIONS_VIEW,
  LOCATIONS_CREATE,
  LOCATIONS_EDIT,
  PLATFORMS_VIEW,
  PLATFORMS_EDIT,
  PLATFORMS_CREATE,
  SETTINGS_BUSINESS_EDIT,
  SETTINGS_BUSINESS_VIEW,
  SETTINGS_COMPLIANCE_EDIT,
  TX_VIEW,
  TX_EDIT,
  VOUCHERS_VIEW,
  COMPLIANCE_BAN,
} from '../Permissions/Permissions';

// class Routes extends Component {
export const Routes = ({ searchResults, searchQuery }) => (
  <Switch>
    <PermissionedRoute
      exact
      path="/"
      component={Transactions}
      permission={TX_VIEW}
    />
    {/* Transactions */}
    <PermissionedRoute
      exact
      path="/transactions"
      component={Transactions}
      permission={TX_VIEW}
    />
    <PermissionedRoute
      path={`/transactions/:hash`}
      component={SingleTransaction}
      permission={TX_VIEW}
    />

    {/* Locations */}
    <PermissionedRoute
      exact
      path="/locations"
      component={Locations}
      permission={LOCATIONS_VIEW}
      search="f=Decommissioned=false"
    />
    <PermissionedRoute
      path="/locations/create-location"
      component={CreateLocation}
      permission={LOCATIONS_CREATE}
    />
    <PermissionedRoute
      path="/locations/add-platform"
      component={AddPlatform}
      permission={LOCATIONS_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path="/locations/add-commissions"
      component={AddLocationCommission}
      permission={ACCOUNTING_COMMISS_CREATE}
      hash={true}
    />
    <PermissionedRoute
      path="/locations/add-debit-account"
      component={AddDebitAccount}
      permission={LOCATIONS_CREATE}
      hash={true}
    />
    <PermissionedRoute
      exact
      path="/locations/document-upload"
      component={DocUpload}
      permission={LOCATIONS_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path={`/locations/:hash`}
      component={SingleLocation}
      permission={LOCATIONS_VIEW}
    />
    {/* Platforms */}
    <PermissionedRoute
      exact
      path="/platforms/screenshots"
      component={PlatformScreenshots}
      permission={PLATFORMS_VIEW}
    />
    <PermissionedRoute
      path={`/platforms/:hash`}
      component={SinglePlatform}
      permission={PLATFORMS_VIEW}
    />
    <PermissionedRoute
      path={`/platform/:hash/products`}
      component={SingleProduct}
      permission={PLATFORMS_VIEW}
    />
    <PermissionedRoute
      path="/products/create"
      component={CreateProducts}
      permission={PLATFORMS_EDIT}
      hash={true}
    />
    {/* Accounting */}
    <PermissionedRoute
      exact
      path="/accounting/commissions"
      component={MonthlyEarnings}
      permission={ACCOUNTING_GROSS_COMMISS}
    />
    <PermissionedRoute
      path="/accounting/reports/commissions"
      component={GroupMonthlyEarnings}
      permission={ACCOUNTING_GROSS_COMMISS}
    />
    <PermissionedRoute
      path="/accounting/add-debit-account"
      component={AddACHAccount}
      permission={LOCATIONS_CREATE}
    />
    <Route
      path={`/accounting/ach/:hash`}
      component={SingleACH}
      permission={ACCOUNT_VIEW}
    />
    <PermissionedRoute
      exact
      path="/accounting/funding"
      component={FundingWallet}
      permission={SETTINGS_BUSINESS_VIEW}
    />
    <PermissionedRoute
      exact
      path="/accounting/funding/history/:hash"
      component={FundingHistory}
      permission={FUNDING_VIEW}
    />
    <PermissionedRoute
      exact
      path="/accounting/cash"
      component={CashCollection}
      permission={ACCOUNTING_VIEW}
    />
    <PermissionedRoute
      exact
      path="/accounting/ach"
      component={ACHAccounts}
      permission={SETTINGS_BUSINESS_VIEW}
    />
    <PermissionedRoute
      exact
      path="/accounting/cash-locations"
      component={CashLocations}
      permission={ACCOUNTING_VIEW}
    />
    {/* Accounts */}
    <PermissionedRoute
      exact
      path="/accounts"
      component={Accounts}
      permission={ACCOUNT_VIEW}
    />
    {/* <Route
      exact
      path={"/accounts/create-pin"}
      component={AccountPin}
    /> */}
    <PermissionedRoute
      path="/accounts/deny-id"
      component={DenyID}
      permission={COMPLIANCE_APPROVE}
      hash={true}
    />
    <PermissionedRoute
      path="/accounts/add-to-group"
      component={AddToGroup}
      permission={ACCOUNT_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path="/accounts/create-phone"
      component={AddPhoneNumber}
      permission={ACCOUNT_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path="/accounts/add-default-wallet"
      component={AddDefaultWallet}
      permission={ACCOUNT_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path="/accounts/create-address"
      component={AddAddress}
      permission={ACCOUNT_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path="/accounts/upload-image"
      component={UploadID}
      permission={COMPLIANCE_APPROVE}
      hash={true}
    />
    <PermissionedRoute
      path="/accounts/review-id"
      component={IDReview}
      permission={COMPLIANCE_APPROVE}
      hash={true}
    />
    <PermissionedRoute
      path="/accounts/anti-fraud-review"
      component={AntiFraudReview}
      permission={COMPLIANCE_APPROVE}
      hash={true}
    />
    <PermissionedRoute
      path="/accounts/generate-key"
      component={GenerateAPIKeys}
      permission={KEY_EDIT}
    />
    <PermissionedRoute
      path="/accounts/employee-invite"
      component={EmployeeInvite}
      permission={ACCOUNT_INVITE}
    />
    <PermissionedRoute
      path="/accounts/customer-invite"
      component={CustomerInvite}
      permission={ACCOUNT_INVITE}
    />
    <PermissionedRoute
      path="/accounts/service"
      component={ServiceAccount}
      permission={ACCOUNT_INVITE}
    />
    <PermissionedRoute
      path="/accounts/document-upload"
      component={DocUpload}
      permission={ACCOUNT_EDIT}
      hash={true}
    />
    <PermissionedRoute
      exact
      path={'/compliance/pending-reviews'}
      component={PendingAccounts}
      permission={ACCOUNT_VIEW}
    />
    <PermissionedRoute
      path={`/service-accounts/:hash`}
      component={SingleServiceAccount}
      permission={ACCOUNT_VIEW}
    />
    <PermissionedRoute
      path="/create/account-note"
      component={CreateNote}
      permission={ACCOUNT_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path="/create/tx-note"
      component={CreateNote}
      permission={TX_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path="/create/location-note"
      component={CreateLocationNote}
      permission={LOCATIONS_EDIT}
      hash={true}
    />
    {/* Platforms */}
    <PermissionedRoute
      exact
      path="/platforms"
      component={Platforms}
      permission={PLATFORMS_VIEW}
      search="f=Active=true"
    />
    <PermissionedRoute
      path="/platform/create"
      component={CreatePlatform}
      permission={PLATFORMS_CREATE}
      hash={true}
    />
    {/* Compliance */}
    <PermissionedRoute
      path="/compliance"
      component={Bans}
      permission={COMPLIANCE_VIEW}
      exact
    />
    <PermissionedRoute
      path="/compliance/bans"
      component={Bans}
      permission={COMPLIANCE_VIEW}
      exact
    />
    <PermissionedRoute
      path="/compliance/wallet-bans"
      component={BanWalletAddress}
      permission={COMPLIANCE_BAN}
    />
    <PermissionedRoute
      path="/compliance/alerts"
      component={Alerts}
      permission={COMPLIANCE_VIEW}
      exact
    />
    <PermissionedRoute
      path="/compliance/investigations"
      component={Investigations}
      permission={COMPLIANCE_VIEW}
      exact
      search="f=ReviewedAndApproved=false"
    />
    <PermissionedRoute
      path="/create/investigation-note"
      component={CreateInvestigationNote}
      permission={COMPLIANCE_INVEST_ACTION}
      hash={true}
    />
    <PermissionedRoute
      path="/compliance/ctrs"
      component={CTRs}
      permission={COMPLIANCE_VIEW}
      exact
    />
    <PermissionedRoute
      path="/compliance/edds"
      component={EDDs}
      permission={COMPLIANCE_EDDS_VIEW}
      exact
      search="f=DecisionText=null"
    />
    <PermissionedRoute
      path="/compliance/subpoenas"
      component={Subpoenas}
      permission={COMPLIANCE_SUBPOENA_VIEW}
      exact
    />
    <PermissionedRoute
      path="/compliance/peps"
      component={PEPs}
      permission={COMPLIANCE_PEP_VIEW}
      exact
    />
    <PermissionedRoute
      path="/compliance/create-subpoena"
      component={CreateSubpoena}
      permission={COMPLIANCE_SUBPOENA_ACTION}
      exact
    />
    <PermissionedRoute
      path="/compliance/document-upload"
      component={DocUpload}
      permission={COMPLIANCE_CTR_UPLOAD}
      hash={true}
    />
    <PermissionedRoute
      path="/compliance/alerts/:hash"
      component={SingleAlert}
      permission={COMPLIANCE_ALERTS_VIEW}
    />
    <PermissionedRoute
      path="/compliance/edds/:hash"
      component={SingleEDDs}
      permission={COMPLIANCE_EDDS_VIEW}
    />
    <PermissionedRoute
      path="/compliance/investigations/:hash"
      component={SingleInvestigation}
      permission={COMPLIANCE_INVEST_VIEW}
    />
    <PermissionedRoute
      path="/compliance/subpoenas/:hash"
      component={SingleSubpoena}
      permission={COMPLIANCE_SUBPOENA_VIEW}
      exact
    />
    <PermissionedRoute
      path="/compliance/peps/:hash"
      component={SinglePEP}
      permission={COMPLIANCE_PEP_VIEW}
    />
    <PermissionedRoute
      path="/compliance/create-pep"
      component={CreatePEP}
      permission={COMPLIANCE_PEP_ACTION}
      exact
    />
    <PermissionedRoute
      exact
      path="/subpoenas/linked-accounts"
      component={SubpoenaLinkedAccountsTable}
      permission={COMPLIANCE_SUBPOENA_VIEW}
    />

    {/* Groups */}
    <PermissionedRoute
      path="/groups/create-group"
      component={CreateGroup}
      permission={GROUPS_CREATE}
    />
    <PermissionedRoute
      path="/groups/add-payment"
      component={AddCommissionPayment}
      permission={GROUPS_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path="/groups/add-group-commissions"
      component={AddGroupCommission}
      permission={GROUPS_EDIT}
      hash={true}
    />
    <PermissionedRoute
      exact
      path="/groups"
      component={Groups}
      permission={GROUPS_VIEW}
    />
    <PermissionedRoute
      path="/groups/add-group-notification"
      component={CreateNotification}
      permission={GROUPS_EDIT}
      hash
    />
    <PermissionedRoute
      exact
      path="/groups/document-upload"
      component={DocUpload}
      permission={LOCATIONS_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path="/groups/add-commission-emails"
      component={AddEmailRecipient}
      hash={true}
      permission={ACCOUNTING_REPORTS_EDIT}
      endpoint="commission-emails"
    />
    <PermissionedRoute
      path="/groups/add-accounting-emails"
      component={AddEmailRecipient}
      hash={true}
      permission={ACCOUNTING_REPORTS_EDIT}
      endpoint="accounting-emails"
    />
    <PermissionedRoute
      path="/groups/create-address"
      component={AddAddress}
      permission={GROUPS_EDIT}
      hash={true}
    />
    <PermissionedRoute
      path={`/groups/:hash`}
      component={SingleGroup}
      permission={GROUPS_VIEW}
    />
    {/* Settings */}
    <PermissionedRoute
      exact
      path="/business-settings"
      component={BusinessSettings}
      permission={SETTINGS_BUSINESS_EDIT}
    />
    <PermissionedRoute
      exact
      path="/terms-of-service"
      component={TermsOfService}
      permission={GROUPS_VIEW}
    />
    <PermissionedRoute
      exact
      path="/compliance-settings"
      component={ComplianceSettings}
      permission={SETTINGS_COMPLIANCE_EDIT}
    />
    <PermissionedRoute
      exact
      path="/documents"
      component={Documents}
      permission={ACCOUNT_VIEW}
    />
    <PermissionedRoute
      exact
      path="/customer-refunds/create-refund"
      component={CreateRefund}
      permission={ACCOUNTING_VIEW}
    />
    <PermissionedRoute
      path="/customer-refunds/:hash"
      component={SingleRefund}
      permission={ACCOUNTING_VIEW}
    />
    <PermissionedRoute
      exact
      path="/customer-refunds"
      component={CustomerRefunds}
      permission={ACCOUNTING_VIEW}
    />
    {/* Search */}
    <PermissionedRoute
      exact
      path="/search-results/accounts"
      component={props => (
        <AccountResultsTable
          {...props}
          searchData={searchResults}
          searchQuery={searchQuery}
        />
      )}
      permission={ACCOUNT_VIEW}
    />
    <PermissionedRoute
      exact
      path="/search-results/locations"
      component={props => (
        <LocationResultsTable
          {...props}
          searchData={searchResults}
          searchQuery={searchQuery}
        />
      )}
      permission={LOCATIONS_VIEW}
    />
    <PermissionedRoute
      exact
      path="/search-results/platforms"
      component={props => (
        <PlatformResultsTable
          {...props}
          searchData={searchResults}
          searchQuery={searchQuery}
        />
      )}
      permission={LOCATIONS_VIEW}
    />
    <PermissionedRoute
      exact
      path="/search-results/transactions"
      component={props => (
        <TransactionResultsTable
          {...props}
          searchData={searchResults}
          searchQuery={searchQuery}
        />
      )}
      permission={TX_VIEW}
    />
    <PermissionedRoute
      exact
      path="/search-results/groups"
      component={props => (
        <GroupResultsTable
          {...props}
          searchData={searchResults}
          searchQuery={searchQuery}
        />
      )}
      permission={GROUPS_VIEW}
    />
    <PermissionedRoute
      exact
      path="/investigations/linked-accounts"
      component={LinkAccountsTable}
      permission={COMPLIANCE_INVEST_VIEW}
    />
    <PermissionedRoute
      exact
      path="/text-blast"
      component={TextBlast}
      permission={ACCOUNT_CONTACT_FREEFORM}
    />
    <PermissionedRoute
      exact
      path="/vouchers"
      component={Vouchers}
      permission={VOUCHERS_VIEW}
    />

    <Route exact path="/settings" component={AccountSettings} />

    {/* TODO ErrorBoundary prevents other routes from render unless at the bottom here, figure out why */}
    <ErrorBoundary>
      <Route path={`/accounts/:hash`} component={SingleAccount} />
    </ErrorBoundary>

    {/* Not Permissioned */}
    <Route path="/not-permissioned" component={NotPermissioned} />
    {/* 404 */}
    <Route path="*" component={NotFoundError} />
  </Switch>
);

export default Routes;
