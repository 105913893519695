import React, { Component } from 'react';
import { FormInput, FormSelect } from '../Forms/FormInputs';
// Components
import { GenericForm } from '../Forms/Form';
import { Loading } from './../Utilities/Components';
// Data
import TimeZones from '../Utilities/Data/TimeZones';
// Configs
import { API } from '../Config/cfg.js';

class ComplianceSettings extends Component {
  state = {
    loading: true,
    settings: {},
    errors: {},
  };

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    API.get('/settings')
      .then(res => {
        this.setState({
          settings: res.data,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          errors: error.response.data,
        });
      });
  };

  checkForInts = (values, intKeys) => {
    let errors = {};
    intKeys.forEach(key => {
      if (!Number.isInteger(Number(values[key]))) {
        return (errors[key] = 'must be an integer');
      }
    });
    return errors;
  };

  render() {
    const { loading, errors, settings } = this.state;
    const {
      ComplianceEmail,
      CompliancePhoneNumber,
      Timezone,
      ExcessiveDailyDeposits,
      ExcessiveMonthlyInstances,
      MonthToMonthSpikeFlagPercentIncrease,
      MonthToMonthSpikeFlagThreshold,
      MonthToMonthSpikeSpan,
      StructuringThreshold,
      SharedAddressFlagThreshold,
      FirstTimeTransactionFreezeLimit,
      Aggregate90DayActivity,
    } = settings;

    if (loading) {
      return <Loading errors={errors} />;
    }

    return (
      <GenericForm
        endpoint="settings"
        postReq={true}
        getData={this.getSettings}
        setValues={{
          ComplianceEmail,
          CompliancePhoneNumber,
          Timezone,
          ExcessiveDailyDeposits,
          ExcessiveMonthlyInstances,
          MonthToMonthSpikeFlagPercentIncrease,
          MonthToMonthSpikeFlagThreshold,
          MonthToMonthSpikeSpan,
          StructuringThreshold,
          SharedAddressFlagThreshold,
          FirstTimeTransactionFreezeLimit,
          Aggregate90DayActivity,
        }}
        customValidations={values => {
          const mustBeInt = [
            'ExcessiveDailyDeposits',
            'ExcessiveMonthlyInstances',
            'MonthToMonthSpikeSpan',
          ];
          const errors = this.checkForInts(values, mustBeInt);
          return errors;
        }}
        goBack={{
          show: false,
          setHistory: null,
        }}
        formTitle="Compliance Settings"
        subTitle={null}
        toastMsg="Compliance settings has been updated"
      >
        <h2>General Settings</h2>
        <div className="columns">
          <div className="column">
            <FormInput
              label="Compliance Email"
              name="ComplianceEmail"
              type="email"
              placeholder="Email"
            />
          </div>
          <div className="column">
            <FormInput
              label="Compliance Phone"
              name="CompliancePhoneNumber"
              type="phone"
              placeholder="1234567890"
            />
          </div>
          <div className="column">
            <FormSelect
              label="Time Zone"
              name="Timezone"
              placeholder="Choose a time zone"
              options={
                <React.Fragment>
                  {TimeZones.map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </React.Fragment>
              }
            />
          </div>
          <div className="column">
            <FormInput
              label="First Time Transaction Freeze Limit"
              name="FirstTimeTransactionFreezeLimit"
              type="number"
            />
          </div>
        </div>

        <h2>Excessive Alerts</h2>
        <div className="columns">
          <div className="column">
            <FormInput
              label="Excessive Daily Deposits"
              name="ExcessiveDailyDeposits"
              type="number"
            />
          </div>
          <div className="column">
            <FormInput
              label="Excessive Monthly Instances"
              name="ExcessiveMonthlyInstances"
              type="number"
            />
          </div>
        </div>

        <h2>Spike Alerts</h2>
        <div className="columns">
          <div className="column">
            <FormInput
              label="Month-to-month Spike Flag Threshold"
              name="MonthToMonthSpikeFlagThreshold"
              type="number"
            />
          </div>
          <div className="column">
            <FormInput
              label="Month-to-month Spike Flag Percent Increase"
              name="MonthToMonthSpikeFlagPercentIncrease"
              type="number"
            />
          </div>
          <div className="column">
            <FormInput
              label="Month-to-month Spike Span"
              name="MonthToMonthSpikeSpan"
              type="number"
            />
          </div>
        </div>

        <h2>Volume Alerts</h2>
        <div className="columns">
          <div className="column is-4">
            <FormInput
              label="Aggregate 90-Day Activity"
              name="Aggregate90DayActivity"
              type="number"
            />
          </div>
          <div className="column"></div>
        </div>

        <h2>Structuring Alerts</h2>
        <div className="columns">
          <div className="column is-4">
            <FormInput
              label="Structuring Threshold"
              name="StructuringThreshold"
              type="number"
            />
          </div>
          <div className="column"></div>
        </div>
      </GenericForm>
    );
  }
}

export default ComplianceSettings;
