// Modules
import React from 'react';
// Icons
import Icon from '../../Icons/Icon';
import { ICONS } from '../../Icons/IconsPath';

const GoBack = ({ history, defaultPath, defaultName, forcePush }) => {
  const handleGoBack = () => {
    if (forcePush) {
      history.push(defaultPath);
      return;
    }
    // Solution for no goBack history
    if (history.action === 'PUSH') {
      history.goBack();
    } else {
      history.push(defaultPath);
    }
  };

  const setLinkText = () => {
    let text = 'Go back';
    if (history.action !== 'PUSH' || forcePush) {
      text = `Go back to ${defaultName}`;
    }
    return text;
  };

  return (
    <div className="go-back-link">
      <div>
        <Icon icon={ICONS.LEFTARROW} color="#009245" size="15" />
        <button onClick={handleGoBack}>{setLinkText()}</button>
      </div>
    </div>
  );
};

export default GoBack;
