import React from 'react';
import { Link } from 'react-router-dom';
import { ICONS } from '../Icons/IconsPath';
import Icon from '../Icons/Icon';
import AltLogo from '../images/dm-alternative.svg';
import HiddenNavLinks from './HiddenNavLinks';

const HiddenNavbar = ({ routes, navComponent, toggle, openSidebar }) => {
  return (
    <aside id="hiddenSidebar">
      <button onClick={toggle} className="hiddenMenu">
        <Icon icon={ICONS.RIGHTARROW} color="#ffffff" size="20" />
      </button>
      <Link to="/">
        <img src={AltLogo} className="hiddenLogo" alt="brand-logo" />
      </Link>
      <HiddenNavLinks
        routes={routes}
        navComponent={navComponent}
        openSidebar={openSidebar}
      />
    </aside>
  );
};

export default HiddenNavbar;
