import React, { Component } from "react";
class ListGroup extends Component {
  render() {
    const { children } = this.props;
    const ListGroupItems = React.Children.map(children, (child) =>
      React.cloneElement(child)
    );

    return (
      <div className="column">
        <div className="table-container">
          <div className="table-head">
            <h3>{this.props.title}</h3>
          </div>
          <div className="table">
            <div className="table-body">{ListGroupItems}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ListGroup;
