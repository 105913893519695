import React from 'react';
import { Formik, Form, Field } from 'formik';
// Components
import { GenericModal } from './../../Utilities/Components';
import { FormSelect } from './../../Forms/FormInputs';
// Icons
import Icon from '../../Icons/Icon';
import { ICONS } from '../../Icons/IconsPath';
// Data
import { TxCancelReason } from './../../Utilities/Data/TxCancelReason';

export const TxCancelModal = ({ isOpen, closeModal, postTransaction }) => {
  return (
    <Formik
      initialValues={{
        CancelReason: '',
      }}
      validate={values => {
        let errors = {};
        if (!values.CancelReason) {
          errors.CancelReason = 'is required';
        }
        return errors;
      }}
      onSubmit={values => {
        postTransaction(
          {
            CancelReason: parseInt(values.CancelReason),
          },
          'Transaction has been cancelled'
        );
        closeModal('isCancelOpen');
      }}
      children={({ touched, errors, handleSubmit, setErrors }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={() => {
            closeModal('isCancelOpen');
            setErrors({});
          }}
          headerMsg="Warning: Canceling TX"
          viewMsg="This action CANCELS the transaction. Please select the reason why you're canceling it below. Make sure the customer is aware why this action is being taken"
          btnText="Cancel"
          btnAction={handleSubmit}
        >
          <Form className="columns form-outline is-centered">
            <div className="column is-11">
              <FormSelect
                errors={errors}
                touched={touched}
                label="Cancel Reason"
                name="CancelReason"
                placeholder="Select Cancel Reason"
                options={TxCancelReason.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              />
            </div>
          </Form>
        </GenericModal>
      )}
    />
  );
};

export const TxFreezeModal = ({ isOpen, closeModal, postTransaction }) => {
  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={() => {
        closeModal('isFreezeOpen');
      }}
      headerMsg="Warning: Freezing TX"
      viewMsg="This action FREEZES this transaction. The transaction will not settle until it is unfrozen. Please verify the cash was collected before unfreezing the transaction."
      btnText="Freeze"
      btnAction={() => {
        postTransaction({ Freeze: true }, 'Transaction has been frozen');
        closeModal('isFreezeOpen');
      }}
    />
  );
};

export const TxUnFreezeModal = ({ isOpen, closeModal, postTransaction }) => {
  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={() => {
        closeModal('isUnFreezeOpen');
      }}
      headerMsg="Warning: UnFreezing TX"
      viewMsg="This action UNFREEZES the transaction. It will allow it to be processed. Make sure the customer is aware why this action is being taken"
      btnText="UnFreeze"
      btnAction={() => {
        postTransaction({ Freeze: false }, 'Transaction has been Unfrozen');
        closeModal('isUnFreezeOpen');
      }}
    />
  );
};

export const TxForceSettleModal = ({ isOpen, closeModal, postTransaction }) => {
  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={() => {
        closeModal('isForceSettleOpen');
      }}
      headerMsg="Warning: Force Settling TX"
      viewMsg="This action FORCE SETTLES the transaction. The transaction will be processed regardless of any other actions taken afterwards. Make sure the customer is aware why this action is being taken"
      btnText="Force Settle"
      btnAction={() => {
        postTransaction(
          { ForceSettle: true },
          'Transaction has been force settled'
        );
        closeModal('isForceSettleOpen');
      }}
    ></GenericModal>
  );
};

export const TxReconcileModal = ({
  isOpen,
  closeModal,
  postTransaction,
  cashCollected,
}) => {
  return (
    <Formik
      initialValues={{
        ManualReconciliationAmount: cashCollected.toString(),
      }}
      validate={values => {
        let errors = {};
        if (!values.ManualReconciliationAmount) {
          errors.ManualReconciliationAmount = 'is required';
        }
        return errors;
      }}
      onSubmit={values => {
        postTransaction(
          {
            ManualReconciliationAmount:
              values.ManualReconciliationAmount.toString(),
          },
          'Transaction has been reconciled'
        );
        closeModal('isReconcileOpen');
      }}
      children={({
        touched,
        errors,
        handleSubmit,
        setErrors,
        setValues,
        values,
      }) => {
        const error =
          errors &&
          errors['ManualReconciliationAmount'] !== 0 &&
          errors['ManualReconciliationAmount'] &&
          touched &&
          touched['ManualReconciliationAmount']
            ? 'icon-input-error'
            : '';
        return (
          <GenericModal
            modalIsOpen={isOpen}
            closeModal={() => {
              closeModal('isReconcileOpen');
              setValues({ ManualReconciliationAmount: cashCollected });
              setErrors({});
            }}
            headerMsg="Warning: Reconciling Transaction"
            viewMsg={
              <>
                This action RECONCILES the transaction. The transaction will be
                processed regardless of any other actions taken afterwards. Make
                sure the reconcilation amount is entered correctly before
                submitting.
                <br />
                <strong>
                  ATTENTION: Once reconciliation is complete you MUST email
                  accounting@digitalmint.io to document and notify them of the
                  reconciliation.
                </strong>
              </>
            }
            btnText="Reconcile"
            btnAction={handleSubmit}
          >
            <Form className="columns form-outline is-centered">
              <div className="column is-11">
                <label className={`item-label , ${error}`}>
                  Manual Reconciliation Amount
                </label>
                <div
                  className={`icon-input ,
                 ${error}`}
                >
                  <div className="icon">
                    <Icon icon={ICONS.USD} color="#fff" size="18" />
                  </div>
                  <Field
                    name="ManualReconciliationAmount"
                    style={{ width: '90%', border: 'none' }}
                    placeholder="0.00"
                    data-testid="ManualReconciliationAmount"
                  />
                </div>
              </div>
            </Form>
          </GenericModal>
        );
      }}
    />
  );
};

export const SMSReceiptModal = ({
  isOpen,
  closeModal,
  postTransaction,
  phone,
}) => {
  return (
    <GenericModal
      modalIsOpen={isOpen}
      closeModal={() => {
        closeModal('isSMSReceiptOpen');
      }}
      headerMsg="Warning: Send SMS Receipt"
      viewMsg={`This action will send an SMS message to ${
        !!phone ? phone : '<PhoneNumber>'
      } confirming this transaction has been settled.`}
      btnText="Send Receipt"
      btnAction={() => {
        postTransaction({ Receipt: true }, 'Receipt has been sent');
        closeModal('isSMSReceiptOpen');
      }}
    />
  );
};
