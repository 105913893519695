import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Table from '../../../Table/Table';
import {
  GenericModal,
  ToastError,
  ToastSuccess,
} from '../../../Utilities/Components';
import Icon from '../../../Icons/Icon';
import { ICONS } from '../../../Icons/IconsPath';
import Can from '../../../Permissions/Can';
import { ACCOUNTING_REPORTS_EDIT } from '../../../Permissions/Permissions';
import { API } from '../../../Config/cfg.js';
import PropTypes from 'prop-types';

const columnBuilder = (openModal, openActiveModal) => [
  {
    Header: 'Email',
    accessor: 'Email',
  },

  {
    Header: 'Active',
    accessor: 'Active',
    Cell: ({ row: { original } }) =>
      original.Active ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
    maxWidth: 85,
  },
  {
    Header: 'Change Status',
    Cell: ({ row: { original } }) => (
      <Can do={ACCOUNTING_REPORTS_EDIT}>
        <button
          className="column-btn create"
          onClick={() =>
            openActiveModal(original.Hash, original.Email, original.Active)
          }
        >
          Edit
        </button>
      </Can>
    ),
  },
  {
    Header: 'Remove Recipient',
    Cell: ({ row: { original } }) => (
      <Can do={ACCOUNTING_REPORTS_EDIT}>
        <button
          className="column-btn remove"
          onClick={() => openModal(original.Hash, original.Email)}
        >
          Remove Email
        </button>
      </Can>
    ),
  },
];

const CommEmailRecipients = ({ apiEndpoint, data, name }) => {
  const [active, setActive] = useState('');
  const [callAPI, setCallAPI] = useState(false);
  const [email, setEmail] = useState('');
  const [hash, setHash] = useState('');
  const [isActiveOpen, setIsActiveOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // TODO: This doesn't make sense
    // from the componentDidUpdate
    // if (prevState.callAPI === true) {
    //   this.setState({ callAPI: false });
    // }
  }, []);

  const openModal = (hash, email, active) => {
    setActive(active);
    setEmail(email);
    setHash(hash);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openActiveModal = (hash, email, active) => {
    setIsActiveOpen(true);
    setHash(hash);
    setEmail(email);
    setActive(active);
  };

  const closeActiveModal = () => {
    setIsActiveOpen(false);
  };

  const deleteEmails = async (uuid, req) => {
    try {
      await API.delete(`/${apiEndpoint}/${uuid}`, req);

      setCallAPI(true);
      setIsOpen(false);
      setHash('');

      ToastSuccess(`Email ${email} has been removed`);
    } catch (error) {
      ToastError(error);
    }
  };

  const updateActive = async () => {
    try {
      await API.post(`/${apiEndpoint}/${hash}`, {
        Email: email,
        Active: !active,
      });

      setActive('');
      setCallAPI(true);
      setEmail('');
      setHash('');
      setIsActiveOpen(false);

      ToastSuccess(`${email} active status has been updated`);
    } catch (error) {
      ToastError(error);
    }
  };

  return (
    <>
      <Table
        columns={columnBuilder(openModal, openActiveModal, active)}
        endpoint={`groups/${data.Hash}/${apiEndpoint}`}
        nested={true}
        tablename={`${name} Emails`}
        triggerAPI={callAPI}
        actions={[
          {
            name: `Add ${name} Email`,
            link: `/groups/add-${apiEndpoint}`,
            params: data.Hash,
            permission: ACCOUNTING_REPORTS_EDIT,
          },
        ]}
      />
      <Formik
        initialValues={{
          Email: email,
          Active: active,
        }}
        onSubmit={values => deleteEmails(hash, values)}
        children={({ handleSubmit, isSubmitting }) => (
          <GenericModal
            isSubmitting={isSubmitting}
            modalIsOpen={isOpen}
            headerMsg={`Remove ${name} Email`}
            viewMsg={`Email: ${email}`}
            btnMsg={`By clicking 'Remove Email' the current email ${email} on this group will be removed`}
            btnText="Remove Email"
            btnAction={handleSubmit}
            closeModal={event => {
              event.preventDefault();

              closeModal();
            }}
          />
        )}
      />
      <Formik
        initialValues={{
          Email: email,
          Active: active,
        }}
        onSubmit={values => updateActive(hash, values)}
        children={({ handleSubmit, isSubmitting }) => (
          <GenericModal
            isSubmitting={isSubmitting}
            modalIsOpen={isActiveOpen}
            headerMsg={`Update Active Status`}
            viewMsg={
              <>
                <p>
                  <strong>Email:</strong> {email}
                </p>{' '}
                <p>
                  <strong>Active:</strong> {active ? 'true' : 'false'}
                </p>
              </>
            }
            btnMsg={`By clicking 'Update' this email's active status will change to ${
              active ? 'false' : 'true'
            }.`}
            btnText="Update"
            btnAction={handleSubmit}
            closeModal={event => {
              event.preventDefault();

              closeActiveModal();
            }}
          />
        )}
      />
    </>
  );
};

CommEmailRecipients.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default CommEmailRecipients;
