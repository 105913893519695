import { ICONS } from 'src/Icons/IconsPath';

export default {
  LTCUSD: {
    icon: ICONS.LTCCIRCLE,
    color: '#BEBEBE',
  },
  BTCUSD: {
    icon: ICONS.BTCCIRCLE,
    color: '#FF9900',
  },
  TBTCUSD: {
    icon: ICONS.BTCCIRCLE,
    color: '#00BFFF',
  },
  ETHUSD: {
    icon: ICONS.ETH,
    color: '#000000',
  },
};
