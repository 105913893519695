import React from 'react';
import { Formik, Form } from 'formik';
// Components
import { FormSelect } from './../../Forms/FormInputs';
import { GenericModal } from '../../Utilities/Components';

const TextBlastModal = ({
  address,
  hash,
  phoneNumber,
  isOpen,
  closeModal,
  history,
}) => {
  const area = phoneNumber.replace(/\s/g, '').replace(/\+/g, '').substr(1, 3);

  const { City, ZIP } = address;

  return (
    <Formik
      initialValues={{
        template: '',
      }}
      onSubmit={values => {
        let state = values;

        if (values.template !== 'Area Code') {
          delete state.area;
        }

        history.push({
          pathname: '/text-blast',
          state: {
            area,
            city: City,
            hash,
            zip: ZIP,
          },
        });
      }}
      children={({ touched, errors, handleSubmit, isSubmitting }) => {
        return (
          <GenericModal
            modalIsOpen={isOpen}
            closeModal={closeModal}
            headerMsg="Text Blast Location"
            viewMsg="You are about to contact customers via SMS"
            btnText="Confirm"
            btnAction={handleSubmit}
            isSubmitting={isSubmitting}
          >
            <Form className="columns form-outline is-centered">
              <div className="column is-11">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="Template"
                  name="template"
                  placecholder="Select a Template"
                  options={
                    <>
                      <option value="" disabled>
                        Select a Template
                      </option>
                      {['Area Code', 'Past Customers'].map(item => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              </div>
            </Form>
          </GenericModal>
        );
      }}
    />
  );
};

export default TextBlastModal;
