/* eslint no-console: ["error", { allow: [ "error"] }] */

import React, { Component } from 'react';
// Comppnents
import { GenericForm, FormikContext } from '../Forms/Form';
import { FormInput, FormSelect } from '../Forms/FormInputs';
import { GenericModal } from '../Utilities/Components';
// Config
import { API } from '../Config/cfg.js';

class GenerateAPIKeys extends Component {
  state = {
    platforms: [],
    modalIsOpen: false,
    Name: '',
    Key: '',
    Secret: '',
  };

  openModal = res => {
    this.setState({
      modalIsOpen: true,
      Name: res.data.Name,
      Key: res.data.Key,
      Secret: res.data.Secret,
    });
  };

  closeModal = () => {
    const { history } = this.props;
    this.setState({ modalIsOpen: false });
    // When routes are copied and paste into a new tab this prevents the app from sending them to a blank screen
    if (history && history.location.key) {
      history.goBack();
    } else {
      history.push(history.pathname);
    }
  };

  componentDidMount = () => {
    this.getPlatforms();
  };

  getPlatforms = () => {
    API.get('/platform-types')
      .then(res => {
        this.setState({
          platforms: res.data,
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const { history } = this.props;
    const { platforms, Name, Key, Secret, modalIsOpen } = this.state;
    const hash = history.location.hash.slice(1);

    return (
      <GenericForm
        endpoint={`accounts/${hash}/keys`}
        setValues={{
          Name: '',
          PlatformTypeHash: '',
        }}
        formTitle="Generate API Key"
        subTitle={null}
        goBack={{
          show: true,
          setHistory: history,
          setDefaultPath: '/accounts',
          setDefaultName: 'Accounts',
        }}
        showModal={this.openModal}
        toastMsg="Account Key Created"
      >
        <FormikContext.Consumer>
          {props => (
            <div className="columns">
              <div className="column is-5">
                <GenericModal
                  modalIsOpen={modalIsOpen}
                  closeModal={this.closeModal}
                  headerMsg="ATTENTION: Save Secret & Key"
                  viewMsg="API Keys are for private usage ONLY and
                    should be stored safely. Exposing API Keys publicly
                    can result in unauthorized third parties gaining access to private data."
                  importantData={[
                    { label: 'Name', value: Name },
                    { label: 'Key', value: Key },
                    { label: 'Secret', value: Secret },
                  ]}
                  btnMsg="By clicking 'Exit' you are confirming you have securely saved your API Name, Secret and Key."
                  btnText="Exit"
                  btnAction={this.closeModal}
                />
                <FormInput
                  name="Name"
                  type="text"
                  label="Key Name"
                  placeholder="Enter Unique Key Name"
                  errors={props.errors}
                  touched={props.touched}
                />
                <FormSelect
                  label="Platform"
                  name="PlatformTypeHash"
                  errors={props.errors}
                  touched={props.touched}
                  placeholder="Select Platform"
                  options={
                    <React.Fragment>
                      {platforms.map(item => (
                        <option key={item.Hash} value={item.Hash}>
                          {item.Name} {item.Model ? `- ${item.Model}` : null}
                        </option>
                      ))}
                    </React.Fragment>
                  }
                />
              </div>
            </div>
          )}
        </FormikContext.Consumer>
      </GenericForm>
    );
  }
}

export default GenerateAPIKeys;
