import React from "react";
import Linkify from "react-linkify";
// Components
import Table from "./../../../Table/Table";
// Helpers - Formatters
import { formatDateTime } from "./../../../Utilities/Formatters";
// Permissions
import { COMPLIANCE_INVEST_ACTION } from "./../../../Permissions/Permissions";

const Notes = ({ hash, history }) => {
  return (
    <Table
      table="Investigation Notes"
      history={history}
      columns={columnBuilder}
      nested={true}
      tablename="Investigation Notes"
      endpoint={`compliance/investigations/${hash}/notes`}
      actions={[
        {
          name: "Create Investigation Note",
          link: "/create/investigation-note",
          params: hash,
          permission: COMPLIANCE_INVEST_ACTION,
        },
      ]}
    />
  );
};

const columnBuilder = [
  {
    Header: "Date",
    width: 150,
    Cell: ({ row: { original } }) => formatDateTime(original.CreatedAt),
    accessor: "CreatedAt",
  },
  {
    Header: "Author",
    width: 150,
    Cell: ({ row: { original } }) => original.Author,
    accessor: "Author",
  },
  {
    minWidth: 360,
    Header: "Note",
    Cell: ({ row: { original } }) => <Linkify>{original.Note}</Linkify>,
    accessor: "Note",
  },
];

export default Notes;
