import React from "react";
// Components
import { strProp } from "../PropTypes";

const styles = {
  textAlign: "center",
};

const EmptyState = ({ msg }) => {
  return <div style={styles}>{msg}</div>;
};

EmptyState.propTypes = {
  msg: strProp,
};

export default EmptyState;
