import React, { useState } from 'react';
import { strProp } from '../Utilities/PropTypes';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import NoID from './NoID';

const IDImage = ({ url, label, uploadError }) => {
  const [loading, setLoading] = useState(true);
  const [noImage, setNoImage] = useState(false);

  if ((url && noImage) || uploadError) {
    return <NoID />;
  } else if (!url) {
    return null;
  }

  return (
    <div className="columns is-centered">
      <section className="column is-narrow">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img
            alt="front of id"
            src={url}
            className="upload"
            data-tip="Click to view full image"
            onLoad={() => setLoading(false)}
            onError={() => setNoImage(true)}
          />
        </a>
        {loading ? (
          <div className="item-label has-text-centered">
            <i className="fa fa-sync fa-spin" />
            <p>Loading Image</p>
          </div>
        ) : (
          <p className="item-label has-text-centered">{label}</p>
        )}
      </section>

      <ReactTooltip place="bottom" effect="solid" />
    </div>
  );
};

export default IDImage;

IDImage.propTypes = {
  url: strProp,
};
