// Modules
import React from "react";
import { Link } from "react-router-dom";
// Components
import Table from "./../../../Table/Table";
import { TradeTypePill } from "./../../../Utilities/Components/TablePills";
// Helpers - Formatters
import { formatPrice } from "../../../Utilities/Formatters";

const WalletAddresses = ({ hash }) => {
  return (
    <Table
      columns={columnBuilder}
      endpoint={`accounts/${hash}/coin-addresses`}
      nested={true}
      downloadable={true}
      tablename="Wallet Addresses"
    />
  );
};

export default WalletAddresses;

const columnBuilder = [
  {
    Header: "Wallet Address",
    accessor: "CoinAddress",
    Cell: ({ row: { original } }) => (
      <Link
        to={{
          pathname: "/transactions",
          search: `?p=0&l=25&s=&o=&f=WithdrawalAddress:${original.CoinAddress}`,
        }}
      >
        {original.CoinAddress}
      </Link>
    ),
    width: 240,
    sortMethod: "CoinAddress",
  },
  {
    Header: "Symbol",
    accessor: "Symbol",
    Cell: ({ row: { original } }) => TradeTypePill(original.Symbol),
    sortMethod: "Symbol",
  },
  {
    Header: "Chainalysis",
    accessor: "CoinAddress Trace",
    Cell: ({ row: { original } }) => (
      <a
        href={`https://reactor.chainalysis.com/graphs/list/cluster/${original.Symbol.replace(
          "USD",
          ""
        )}/${original.CoinAddress}`}
        rel="noreferrer noopener"
        target="_blank"
      >
        View in Chainalysis
      </a>
    ),
  },
  {
    Header: (
      <span>
        Total <br /> Cash In
      </span>
    ),
    accessor: "TotalCashIn",
    Cell: ({ row: { original } }) => formatPrice(original.TotalCashIn),
    sortMethod: "TotalCashIn",
    width: 80,
  },
  {
    Header: (
      <span>
        Total <br /> Coins In
      </span>
    ),
    accessor: "BaseQuantityIn",
    Cell: ({ row: { original } }) => original.BaseQuantityIn,
    sortMethod: "BaseQuantityIn",
  },
  {
    Header: (
      <span>
        Global <br /> Cash In
      </span>
    ),
    accessor: "GlobalCashIn",
    Cell: ({ row: { original } }) => formatPrice(original.GlobalCashIn),
    sortMethod: "GlobalCashIn",
  },
  {
    Header: (
      <span>
        Customers <br />
        Used By In
      </span>
    ),
    accessor: "TotalCustomersUsedByIn",
    Cell: ({ row: { original } }) => original.TotalCustomersUsedByIn,
    sortMethod: "TotalCustomersUsedByIn",
  },
];
