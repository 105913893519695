import React, { useState } from 'react';
import PropTypes from 'prop-types';

const NoteIndicator = ({ capacity, noteCapacity, noteCount }) => {
  const [showPercentage, setShowPercentage] = useState(false);

  const changeColor = () => {
    if (capacity < 50) {
      return '#009245';
    } else if (capacity < 75) {
      return '#F8C226';
    } else if (capacity <= 100) {
      return 'red';
    }
  };

  const percentageText = () => {
    if (showPercentage) {
      return `${noteCount} / ${noteCapacity}`;
    } else {
      return `${capacity.toFixed(2)}%`;
    }
  };

  return (
    <button
      className="clean-btn"
      style={{
        cursor: 'pointer',
        transform: 'scale(1.1)',
        userSelect: 'none',
      }}
      onClick={() => setShowPercentage(!showPercentage)}
      data-testid="NoteIndicator"
    >
      <span
        style={{
          display: 'block',
          backgroundColor: 'rgba(0,0,0,.15)',
          boxShadow: 'inset 0px 0px 2px 0px rgba(0,0,0,0.15)',
          height: '14px',
          width: '100%',
        }}
      >
        <span
          style={{
            display: 'block',
            background: changeColor(),
            height: 'inherit',
            width: `${capacity.toFixed(1)}%`,
          }}
        />
      </span>
      <span
        style={{
          display: 'block',
          color: '#fff',
          fontSize: '10px',
          fontWeight: '700',
          marginTop: '-14px',
          textShadow: '0px 0px 3px rgba(0,0,0,.8)',
        }}
        data-testid="NoteIndicator-Text"
      >
        {percentageText()}
      </span>
    </button>
  );
};

NoteIndicator.defaultProps = {
  capacity: 0,
  noteCapacity: 0,
  noteCount: 0,
};

NoteIndicator.propTypes = {
  capacity: PropTypes.number.isRequired,
  noteCapacity: PropTypes.number.isRequired,
  noteCount: PropTypes.number.isRequired,
};

export default NoteIndicator;
