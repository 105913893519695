import React from "react";
// Components
import { EditBtn } from "../../Utilities/Components";
// Helpers - Formatters
import { formatPrice } from "../../Utilities/Formatters";

const Limits = ({ data }) => {
  const {
    RemainingToHitMonthlyLimit,
    RemainingToHitDailyLimit,
    After7DaysCanPurchase,
    After14DaysCanPurchase,
    NextDayCanPurchase,
    NextDayCanPurchaseAmount,
  } = data;
  return (
    <React.Fragment>
      <EditBtn
        editing={null}
        triggerEdit={null}
        sectionTitle="Limits Overview"
      />
      <div className="form-outline inline-edit-form">
        <div className="columns">
          <div className="column form-row">
            <label className="item-label expand">
              Remaining To Hit Monthly Limit:{" "}
            </label>
            <p className="expand">
              {RemainingToHitMonthlyLimit
                ? formatPrice(RemainingToHitMonthlyLimit)
                : "-"}
            </p>
          </div>
          <div className="column form-row">
            <label className="item-label expand">
              Remaining To Hit Daily Limit:{" "}
            </label>
            <p className="expand">
              {RemainingToHitDailyLimit
                ? formatPrice(RemainingToHitDailyLimit)
                : "-"}
            </p>
          </div>
        </div>

        <div className="columns">
          <div className="column form-row">
            <label className="item-label expand">
              After 14 Days Can Purchase:{" "}
            </label>
            <p className="expand">
              {After14DaysCanPurchase
                ? formatPrice(After14DaysCanPurchase)
                : "-"}
            </p>
          </div>
          <div className="column form-row">
            <label className="item-label expand">
              After 7 Days Can Purchase:{" "}
            </label>
            <p className="expand">
              {After7DaysCanPurchase ? formatPrice(After7DaysCanPurchase) : "-"}
            </p>
          </div>
        </div>

        <div className="columns">
          <div className="column form-row">
            <label className="item-label expand">Next Day Can Purchase: </label>
            <p className="expand">
              {NextDayCanPurchase ? NextDayCanPurchase : "-"}
            </p>
          </div>
          <div className="column form-row ">
            <label className="item-label expand">
              Next Day Can Purchase Amount:{" "}
            </label>
            <p className="expand">
              {NextDayCanPurchaseAmount
                ? formatPrice(NextDayCanPurchaseAmount)
                : "-"}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Limits;
