//Modules
import React from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
// Components
import { ListGroup, ListGroupItem } from "../../../Utilities/Components";
// Helpers - Formatters
import { formatDate } from "../../../Utilities/Formatters";
// PropTypes
import { accountProps } from "../../../Utilities/PropTypes";

const AccountDetails = ({ data }) => {
  let { Addresses, DOB, FullName, CreatedAt, Hash } = data;
  return (
    <ListGroup title="Account Details">
      <ListGroupItem
        name="Name"
        value={<Link to={`/accounts/${Hash}`}>{FullName}</Link>}
      />
      <ListGroupItem name="DOB" value={formatDate(DOB, undefined, true)} />

      <>
        {!isEmpty(Addresses) && (
          <>
            <ListGroupItem name="Address" value={Addresses[0]?.Street} />
            <ListGroupItem name="City" value={Addresses[0]?.City} />
            <ListGroupItem name="State" value={Addresses[0]?.StateCode} />
            <ListGroupItem name="Zipcode" value={Addresses[0]?.ZIP} />
            <ListGroupItem
              name="Account Opened"
              value={formatDate(CreatedAt)}
            />
          </>
        )}
      </>
    </ListGroup>
  );
};

AccountDetails.propTypes = {
  data: accountProps,
};

export default AccountDetails;
