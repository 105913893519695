// Components
import React from 'react';
// Icons
import Icon from '../Icons/Icon';
import { ICONS } from '../Icons/IconsPath';
// Styles
import './Notes.css';

export const NoteIcon = (icon, type, pill) => (
  <span className={`note-pill ${pill}`}>
    <Icon icon={icon} size="12" color="#fff" rightMargin={7} /> {type}
  </span>
);

export const setMsgType = data => {
  let type = '';
  switch (data) {
    case 0:
      type = NoteIcon(ICONS.FILE, 'Internal Note', 'note-internal');
      break;
    case 1:
      type = NoteIcon(ICONS.FILE, 'System Note', 'note-system');
      break;
    case 2:
      type = NoteIcon(ICONS.SMS, 'SMS', 'note-sms');
      break;
    case 3:
      type = NoteIcon(ICONS.MESSAGE, 'Email Message', 'note-message');
      break;
    case 4:
      type = NoteIcon(ICONS.PHONE, 'Inbound Call', 'note-inbound-call');
      break;
    case 5:
      type = NoteIcon(ICONS.PHONE, 'Outbound Call', 'note-outbound-call');
      break;
    case 6:
      type = NoteIcon(ICONS.SMS, 'Outbound Text', 'note-outbound-text');
      break;
    case 7:
      type = NoteIcon(ICONS.SMS, 'Inbound Text', 'note-inbound-text');
      break;
    case 8:
      type = NoteIcon(ICONS.MESSAGE, 'Inbound Email', 'note-inbound-email');
      break;
    case 9:
      type = NoteIcon(ICONS.MESSAGE, 'Outbound Email', 'note-outbound-email');
      break;
    case 10:
      type = NoteIcon(
        ICONS.GROUPS,
        'In-Person Meeting',
        'note-inperson-meeting'
      );
      break;
    case 11:
      type = NoteIcon(ICONS.PHONE, 'Left Voicemail', 'note-left-voicemail');
      break;
    case 13:
      type = NoteIcon(ICONS.FILE, 'Zendesk Note', 'zendesk-note');
      break;
    case 15:
      type = NoteIcon(ICONS.FILE, 'Case Opened', 'note-internal');
      break;
    default:
      type = NoteIcon(ICONS.FILE, 'Internal Note', 'note-internal');
      break;
  }
  return type;
};
