import moment from "moment";

export const formatDateTime = (
  datetime,
  format = "MM/DD/YYYY hh:mm:ss A",
  utc = false
) => {
  if (datetime) {
    return moment(datetime, "YYYY-MM-DDThh:mm:ssZ").utc(!utc).format(format);
  } else {
    return "-";
  }
};

export default formatDateTime;
