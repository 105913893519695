// Modules
import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
// Components
import { ToastError, GenericModal } from '../../Utilities/Components';
import { FormInput, FormSelect } from '../../Forms/FormInputs';
// Configs
import { API } from '../../Config/cfg.js';

const ActivityForm = props => {
  const { postSubpoena, data, editing, isSubmitting, setEditing } = props;
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    getEmployees();
    // eslint-disable-next-line
  }, []);

  const getEmployees = async () => {
    try {
      const res = await API.get(
        '/compliance/employees/assign-to/compliance_investigations'
      );

      setEmployees(res.data);
    } catch (err) {
      ToastError(err.response?.data?.Message || 'An unknown error occurred.');
    }
  };

  return (
    <Formik
      initialValues={{
        Completed: data.Completed,
        OpenInvestigation: data.ComplianceInvestigation ? true : false,
        QCReviewAccountHash: data.QCReviewAccount.Hash,
        InvestigationHash: data.ComplianceInvestigation
          ? data.ComplianceInvestigation.Hash
          : '',
      }}
      onSubmit={async values => await postSubpoena(values)}
      validate={values => {
        let errors = {};
        if (values.OpenInvestigation && !values.InvestigationHash) {
          errors.InvestigationHash = 'is required';
        }
        return errors;
      }}
      children={({ values, touched, errors, handleSubmit }) => (
        <GenericModal
          modalIsOpen={editing}
          headerMsg="Edit Activity"
          btnAction={handleSubmit}
          btnText="Submit"
          isSubmitting={isSubmitting}
          closeModal={() => setEditing(false)}
        >
          <Form className="form-outline">
            <div className="columns is-vcentered">
              <div className="column is-7">
                <FormSelect
                  errors={errors}
                  touched={touched}
                  label="QC Reviewer"
                  name="QCReviewAccountHash"
                  placeholder="Select an Employee"
                  options={
                    <>
                      {employees.map(item => (
                        <option key={item.AccountHash} value={item.AccountHash}>
                          {item.FullName}
                        </option>
                      ))}
                    </>
                  }
                  testID="QAReviewAccountHash"
                />
              </div>
            </div>
            <div className="columns is-vcentered">
              <div className="column is-narrow has-text-centered">
                <label className="item-label">Completed</label>
                <Field
                  name="Completed"
                  type="checkbox"
                  htmlFor="Completed"
                  data-testid="Completed"
                />
              </div>
              <div className="column is-narrow has-text-centered">
                <label className="item-label" htmlFor="OpenInvestigation">
                  Open Compliance Investigation
                </label>
                <Field
                  name="OpenInvestigation"
                  type="checkbox"
                  data-testid="OpenInvestigation"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-7">
                {values.OpenInvestigation ? (
                  <FormInput
                    errors={errors}
                    touched={touched}
                    label="Investigation Hash"
                    name="InvestigationHash"
                    type="text"
                    testID="InvestigationHash"
                  />
                ) : null}
              </div>
            </div>
          </Form>
        </GenericModal>
      )}
    />
  );
};

export default ActivityForm;
