// Modules
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import { ICONS } from '../Icons/IconsPath';
import {
  PlatformPill,
  TradeTypePill,
} from './../Utilities/Components/TablePills';
import NoteIndicator from './../Utilities/Components/NoteIndicator';
import Icon from '../Icons/Icon';
// Helpers - Formatters
import {
  formatDateTime,
  formatPlatformsCashCourierCell,
  formatPrice,
} from '../Utilities/Formatters';

const columnBuilder = [
  {
    Header: 'Slug',
    accessor: 'Slug',
    width: 75,
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/platforms/${original.Hash}`}>
        {original.Slug}
      </Link>
    ),
    sortMethod: 'Slug',
    default: true,
  },
  {
    Header: 'Active',
    accessor: 'Active',
    width: 50,
    Cell: ({ row: { original } }) =>
      original.Active ? (
        <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
      ) : (
        <Icon icon={ICONS.INACTIVE} size="16" color="#ff3560" rightMargin="0" />
      ),
    sortMethod: 'Active',
    default: true,
  },
  {
    Header: 'Online',
    accessor: 'Available',
    width: 50,
    Cell: ({ row: { original } }) => (
      <Icon
        icon={ICONS.BROADCAST}
        size="15"
        color={original.Available ? '#006994' : '#ff3560'}
        rightMargin="0"
      />
    ),
    sortMethod: 'Available',
    default: true,
  },
  {
    Header: 'Group',
    accessor: 'Group',
    Cell: ({ row: { original } }) =>
      original.Group ? (
        <Link className="table-pill" to={`/groups/${original.Group.Hash}`}>
          {original.Group.Name}
        </Link>
      ) : (
        '-'
      ),
    sortMethod: 'Group',
    default: true,
  },
  {
    Header: 'Location',
    accessor: 'Location.Address.StateCode',
    minWidth: 210,
    Cell: ({ row: { original } }) =>
      original.Location ? (
        <Link
          className="table-pill"
          to={`/locations/${original.Location.Hash}`}
        >
          {original.Location.Address.StateCode},{' '}
          {original.Location.Address.City}, {original.Location.Address.Street}
        </Link>
      ) : (
        '-'
      ),
    sortMethod: 'CityState',
    default: true,
  },
  {
    Header: 'Platform',
    accessor: 'Platform.Name',
    Cell: ({ row: { original } }) => (
      <Link className="link" to={`/platforms/${original.Hash}`}>
        {PlatformPill(
          `${original.PlatformType.Name} ${original.PlatformType.Model}`
        )}
      </Link>
    ),
    width: 150,
    sortMethod: 'Platform',
    default: true,
  },
  {
    Header: 'Last Activity',
    accessor: 'LastEvent',
    Cell: ({ row: { original } }) =>
      formatDateTime(original.LastEvent.Timestamp),
    maxWidth: 100,
    sortMethod: 'LastActivity',
    default: true,
  },
  {
    Header: 'Cassette Removed',
    accessor: 'LastCashCassetteRemoval.Timestamp',
    Cell: ({ row: { original } }) =>
      formatDateTime(original?.LastCashCassetteRemoval?.Timestamp),
    sortMethod: 'LastCashCassetteRemoval.Timestamp',
    maxWidth: 115,
    default: true,
  },
  {
    Header: 'Cash In / Capacity',
    accessor: 'NoteCapacityRatio',
    Cell: ({ row: { original } }) => {
      if (original.PlatformType.Name === 'Kiosk') {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {formatPrice(original.CashIn)}
            <NoteIndicator
              capacity={original.NoteCapacityRatio * 100}
              noteCapacity={original.NoteCapacity}
              noteCount={original.NoteCount}
            />
          </div>
        );
      } else {
        return '-';
      }
    },
    sortMethod: 'CashIn',
    default: true,
  },
  {
    Header: 'Cash Collection',
    accessor: 'Location.CashCourier.Name',
    sortMethod: 'Cash Courier',
    Cell: ({ row }) => formatPlatformsCashCourierCell(row),
    default: true,
  },
  {
    Header: 'Products',
    accessor: 'Products',
    Cell: ({ row: { original } }) =>
      original.Products
        ? original.Products.split(', ').map(product => TradeTypePill(product))
        : '-',
    sortMethod: 'Products',
  },
  {
    Header: 'Name',
    accessor: 'Name',
  },
  {
    Header: 'Device Identifier',
    accessor: 'DeviceIdentifier',
  },
];

export default columnBuilder;
