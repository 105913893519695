import React from "react";

const Icon = (props) => {
  const styles = {
    svg: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: props.rightMargin + "px" || "10px",
      marginLeft: props.leftMargin + "px" || "0px",
      fill: props.color,
    },
  };

  return (
    <svg
      style={styles.svg}
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox="0 0 1024 1024"
    >
      <path className={props.className} d={props.icon} />
    </svg>
  );
};

Icon.defaultProps = {
  size: 16,
};

export default Icon;
