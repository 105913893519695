// Modules;
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import Table from '../Table/Table';
import {
  ApprovalPill,
  UrgentApproval,
} from '../Utilities/Components/TablePills';
// Helpers - Formatters
import { formatDateTime } from '../Utilities/Formatters';
// Permissions
import { ACCOUNT_INVITE } from '../Permissions/Permissions';

const PendingAccounts = props => {
  return (
    <Table
      history={props.history}
      columns={columnBuilder}
      endpoint="compliance/pending-reviews" // shows only non-closed reviews
      filter={true}
      tablename="Pending Accounts"
      actions={[
        {
          name: 'Create Customer Invite',
          link: '/accounts/customer-invite',
          permission: ACCOUNT_INVITE,
        },
      ]}
    />
  );
};

export default PendingAccounts;

const columnBuilder = [
  {
    Header: 'Priority',
    accessor: 'Type',
    maxWidth: 80,
    Cell: ({ row: { original } }) => UrgentApproval(original.Type),
  },
  {
    Header: 'State',
    accessor: 'TypeText',
    Cell: ({ row: { original } }) =>
      ApprovalPill(original.Type, original.TypeText),
  },
  {
    Header: 'Created At',
    maxWidth: 85,
    Cell: ({ row: { original } }) => formatDateTime(original.CreatedAt),
  },
  {
    Header: 'Phone',
    accessor: 'Account.PreferredPhoneNumber.Phone',
    minWidth: 150,
  },
  {
    Header: 'Actions',
    accessor: 'Hash',
    Cell: ({ row: { original } }) =>
      original.Account ? (
        <Link
          className="table-pill"
          to={{
            //image or type 5 = Review HASH, NO image = Account HASH and redirect pathname to accounts/ACCOUNT-HASH
            pathname: setPathname(original),
            hash: setRouteHash(original),
            state: {
              TypeText: original.TypeText,
              type: original.Type,
            },
          }}
        >
          View Details
        </Link>
      ) : null,
  },
];

const setRouteHash = data => {
  const { Type, Hash, Account } = data;
  let routeHash;
  if (Type === 1 || Type === 5 || Type === 6 || Type === 7) {
    routeHash = Hash;
  } else {
    routeHash = Account.Hash;
  }
  return routeHash;
};

const setPathname = data => {
  const { Account, Type } = data;

  switch (Type) {
    case 1:
      return '/accounts/review-id';
    case 3:
    case 4:
    case 8:
      return `/accounts/${Account.Hash}`;
    case 5:
    case 6:
    case 7:
      return `/accounts/anti-fraud-review`;
    default:
      return '/accounts/upload-image';
  }
};
