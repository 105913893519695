import React from 'react';
import { parseToHyperLink } from '../Formatters';
import PropTypes from 'prop-types';

const Contacts = ({ contact, testID }) => {
  if (!contact) return false;

  contact = parseToHyperLink(contact);

  return (
    <span data-testid={testID}>
      <p style={{ fontSize: 'inherit' }}>{contact[contact.length - 1]}</p>

      {contact.map(({ href, contact }, index) => {
        return (
          <div key={index}>
            <a href={href}>{contact}</a>
          </div>
        );
      })}
    </span>
  );
};

Contacts.defaultProps = {
  testID: 'xContacts',
};

Contacts.propTypes = {
  contact: PropTypes.string,
  testID: PropTypes.string,
};

export default Contacts;
