import React from "react";
import { Link } from "react-router-dom";

const OrButton = ({ path, name, btnColor }) => (
  <React.Fragment>
    <Link to={path} className={`btn ${btnColor}`}>
      {name}
    </Link>
    <span style={{ marginLeft: "10px", marginRight: "10px" }}>or</span>
  </React.Fragment>
);

export default OrButton;
