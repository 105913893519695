import Data from './Data';

export default class PlatformTypeData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      Model: { type: 'string' },
      Name: { type: 'string' },
      ThirdParty: { type: 'boolean' },
    };
  }
}
