import React from 'react';
import { FormSelect, FormInput } from '../../../Forms/FormInputs';
// Data
import StateLists from '../../../Utilities/Data/StatesList';

const CheckInputs = ({ touched, errors }) => (
  <>
    <div className="columns">
      <div className="column is-4">
        <FormInput
          label="Payable To"
          name="PayableTo"
          type="text"
          placeholder="John Doe"
          errors={errors}
          touched={touched}
          testID="PayableTo"
        />
      </div>
      <div className="column is-4">
        <FormInput
          label="Street Address"
          name="Street"
          type="text"
          placeholder="Enter Address Street"
          errors={errors}
          touched={touched}
          testID="Street"
        />
      </div>
      <div className="column is-4">
        <FormInput
          label="Street Address 2"
          name="Street2"
          type="text"
          placeholder="Enter Address Street (optional)"
          testID="Street2"
        />
      </div>
    </div>
    <div className="columns">
      <div className="column is-4">
        <FormInput
          label="City"
          name="City"
          type="text"
          placeholder="Enter City"
          errors={errors}
          touched={touched}
          testID="City"
        />
      </div>
      <div className="column is-4">
        <FormSelect
          label="State"
          name="StateCode"
          errors={errors}
          touched={touched}
          placeholder="State"
          options={StateLists.map(item => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
          testID="StateCode"
        />
      </div>
      <div className="column is-4">
        <FormInput
          label="Postal Code"
          name="Zip"
          type="text"
          placeholder="00000"
          errors={errors}
          touched={touched}
          testID="Zip"
        />
      </div>
    </div>
  </>
);

export default CheckInputs;
