// Modules
import React from 'react';
import PropTypes from 'prop-types';
// Components
import { GenericForm, FormikContext } from '../../../Forms/Form';
import { FormInput } from '../../../Forms/FormInputs';

const AddPhoneNumber = ({ hash, history }) => {
  return (
    <GenericForm
      endpoint="phones"
      setValues={{
        PhoneNumber: '',
        Preferred: true,
        AccountHash: hash,
      }}
      customValidations={values => {
        let errors = {};
        if (!values.PhoneNumber) {
          errors.PhoneNumber = 'is required';
        }
        return errors;
      }}
      formTitle={null}
      subTitle="Add New Phone Number"
      goBack={{
        show: history ? true : false,
        setHistory: history ? history : null,
        setDefaultPath: history ? '/accounts' : null,
        setDefaultName: history ? 'Accounts' : null,
      }}
      toastMsg="New Phone Number successfully added"
    >
      <FormikContext.Consumer>
        {props => (
          <div className="columns no-padding">
            <div className="column is-3">
              <FormInput
                errors={props.errors}
                touched={props.touched}
                name="PhoneNumber"
                label="Phone Number"
                type="text"
                placeholder="1234567890"
              />
            </div>
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

AddPhoneNumber.propTypes = {
  hash: PropTypes.string.isRequired,
};

export default AddPhoneNumber;
