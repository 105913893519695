import React from 'react';
import { Form, Formik } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// Components
import { FormInput, FormSelect } from '../../../Forms/FormInputs';
import { GenericModal } from '../../../Utilities/Components';

const InviteEmployeeModal = ({
  isOpen,
  putAccount,
  account,
  closeModal,
  groups,
  hash,
  email,
}) => {
  return (
    <Formik
      initialValues={{
        Email: email ? email : '',
        GroupHash: '',
        PortalAccess: true,
      }}
      validate={values => {
        let errors = {};
        if (!values.Email) {
          errors.Email = 'is required';
        }
        if (!values.GroupHash) {
          errors.GroupHash = 'is required';
        }
        return errors;
      }}
      onSubmit={async values => {
        await putAccount({
          AccountHash: hash,
          Email: values.Email,
          GroupHash: values.GroupHash,
          PortalAccess: values.PortalAccess,
        });
        values.GroupHash = '';
      }}
      children={({ touched, errors, handleSubmit, isSubmitting }) => (
        <GenericModal
          modalIsOpen={isOpen}
          closeModal={() => {
            closeModal('isInviteOpen');
          }}
          viewMsg={account}
          headerMsg="Invite Employee"
          btnText="Submit"
          btnAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          <Form className="columns form-outline is-centered">
            <div className="column is-11">
              <FormInput
                errors={errors}
                touched={touched}
                label="Email"
                name="Email"
                placeholder="Enter email..."
              />
              <FormSelect
                errors={errors}
                touched={touched}
                label="Group"
                name="GroupHash"
                placeholder="Select a Group"
                options={
                  <React.Fragment>
                    {groups.map(item => (
                      <option key={item.Hash} value={item.Hash}>
                        {item.Name}
                      </option>
                    ))}
                  </React.Fragment>
                }
              />
              <div
                data-tip="Grants portal login access & e-mails a new account invitation"
                data-for="portalaccess"
              >
                <FormInput
                  label="Portal Login Access"
                  name="PortalAccess"
                  type="checkbox"
                  errors={errors}
                  touched={touched}
                />
                <ReactTooltip id="portalaccess" place="bottom" effect="solid" />
              </div>
            </div>
          </Form>
        </GenericModal>
      )}
    />
  );
};

export default InviteEmployeeModal;
