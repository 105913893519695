/* COMPONENT OVERVIEW
- Takes props from parent 
- Renders Account wallet data
- Calls putDefaultWallet function prop & sends request to parent
*/
// Modules
import React from "react";
import { Formik, Form } from "formik";
// Components
import { EditBtn } from "../../Utilities/Components";
import { EditableInput } from "../../Forms/EditableInputs";
import { LoadingButton } from "../../Utilities/Components";
import {
  accountProps,
  reqBoolProp,
  reqFuncProp,
} from "../../Utilities/PropTypes";
// Permissions
import { ACCOUNT_EDIT } from "../../Permissions/Permissions";

const DefaultWallet = ({
  data,
  editing,
  toggleEdit,
  deleteDefaultWallet,
  putDefaultWallet,
}) => {
  const { DefaultWallets } = data;

  const triggerEdit = (e) => {
    e.preventDefault();
    toggleEdit("editingWallet");
  };

  const callPutDefaultWallet = (payload, actions) => {
    const { Wallet } = payload;
    // Sends payload to POST call in parent
    putDefaultWallet(data.Hash, Wallet, "editingWallet", actions);
  };

  const handleRemoval = (e, walletHash, actions) => {
    // Prevents reload refresh from a tag on click
    e.preventDefault();
    // Sends payload to POST call in parent
    deleteDefaultWallet(walletHash, "editingWallet", actions);
  };

  const setCSS = () => {
    let css;
    if (editing) {
      css =
        "column is-8 is-align-content-center is-align-items-center is-justify-content-space-evenly form-row";
    } else {
      css = "column is-10";
    }
    return css;
  };

  return (
    <React.Fragment>
      <EditBtn
        editing={editing}
        triggerEdit={triggerEdit}
        sectionTitle="Default Wallets"
        permission={ACCOUNT_EDIT}
        link={{
          pathname: "/accounts/add-default-wallet",
          hash: data.Hash,
          state: { account: data },
        }}
      />
      {DefaultWallets ? (
        DefaultWallets.map((wallet) => {
          return (
            <Formik
              key={wallet.WithdrawalAddress}
              enableReinitialize
              initialValues={{
                Wallet: wallet.WithdrawalAddress,
              }}
              onSubmit={(values, actions) => {
                callPutDefaultWallet(values, actions);
              }}
              children={({ isSubmitting }) => (
                <Form className="form-outline inline-edit-form">
                  <div className="columns is-multiline is-mobile pl-1">
                    <div className={setCSS()}>
                      <EditableInput
                        name="Wallet"
                        label={`${wallet.Product.Symbol} Wallet`}
                        type="text"
                        currentValue={wallet.WithdrawalAddress}
                        editing={editing}
                        styles="form-row is-align-content-center is-align-items-center"
                      />
                    </div>
                  </div>
                  {editing && (
                    <div className="is-flex-direction-row is-align-content-center is-align-items-center is-justify-content-space-evenly">
                      <LoadingButton loading={isSubmitting} btnText="Update" />
                      <span className="ml-4">or</span>
                      <a
                        href="/"
                        onClick={(e) => handleRemoval(e, wallet.Hash)}
                        className="btn red ml-4"
                      >
                        Remove
                      </a>
                    </div>
                  )}
                </Form>
              )}
            />
          );
        })
      ) : (
        <p className="form-outline inline-edit-form">No Default Wallet Set</p>
      )}
    </React.Fragment>
  );
};

DefaultWallet.propTypes = {
  putDefaultWallet: reqFuncProp,
  deleteDefaultWallet: reqFuncProp,
  toggleEdit: reqFuncProp,
  editing: reqBoolProp,
  data: accountProps,
};

export default DefaultWallet;
