/* COMPONENT OVERVIEW
- Renders login form
- Triggers login POST to server
*/
import React from 'react';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { FormInput } from '../Forms/FormInputs';
import { LoadingButton } from '../Utilities/Components';
import PropTypes from 'prop-types';

const Login = ({ login, OTPEnabled }) => {
  return (
    <Formik
      initialValues={{
        login: '',
        password: '',
        otp: '',
      }}
      enableReinitialize={true}
      validate={values => {
        const errors = {};

        if (!OTPEnabled && !values.login) {
          errors.login = 'is required';
        }
        if (!OTPEnabled && !values.password) {
          errors.password = 'is required';
        }
        if (OTPEnabled && !values.otp) {
          errors.otp = 'is required';
        }
        if (OTPEnabled && values.otp.length < 6) {
          errors.otp = 'must be 6 numbers';
        }
        if (OTPEnabled && values.otp.length >= 7) {
          errors.otp = "can't exceed 6 numbers";
        }
        if (OTPEnabled && !/^\d+$/.test(values.otp)) {
          errors.otp = 'must be all numbers';
        }

        return errors;
      }}
      onSubmit={(values, actions) => {
        login(values.login, values.password, values.otp, actions);
      }}
      children={({ errors, touched, isSubmitting }) => (
        <Form className="form-outline mt-4">
          {!OTPEnabled ? (
            <div className="columns">
              <div className="column is-7 is-three-quarters-mobile centered-container">
                <div className="mb-3">
                  <FormInput
                    errors={errors}
                    touched={touched}
                    label="Login"
                    name="login"
                    type="text"
                    placeholder="example@digitalmint.io"
                    testID="login"
                  />
                </div>

                <FormInput
                  errors={errors}
                  touched={touched}
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  testID="password"
                />
              </div>
            </div>
          ) : (
            <div className="column is-7 is-three-quarters-mobile centered-container animated animateOTP">
              <FormInput
                errors={errors}
                touched={touched}
                label="Two-Factor Authentication"
                name="otp"
                type="text"
                placeholder="Enter One-Time Passcode..."
                autoFocus={true}
              />
            </div>
          )}

          <div
            className={`column ${
              OTPEnabled && 'is-7'
            } column is-7 is-three-quarters-mobile centered-container pt-0 pl-2 pr-2`}
          >
            <LoadingButton
              loading={isSubmitting}
              btnText="Login"
              loadingText="Logging In"
              style={{ width: '100%' }}
              testID="submit"
            />

            <p className="mt-2 has-text-centered">
              <Link to="/forgot-password">Forgot Password?</Link>
              <a
                href="https://www.digitalmint.io/terms-of-service/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="ml-3">Privacy Policy</span>
              </a>
            </p>
            <p className="mt-1 has-text-centered">
              <small>
                Version: {process.env.REACT_APP_APP_VERSION || '0.0.0'}
              </small>
            </p>
          </div>
        </Form>
      )}
    />
  );
};

Login.defaultProps = {
  OTPEnabled: false,
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  OTPEnabled: PropTypes.bool.isRequired,
};

export default Login;
