// Modules
import React, { useState, useEffect } from 'react';
// Components
import { DocumentPill } from '../../../Utilities/Components/TablePills';
import Table from '../../../Table/Table';
import {
  GenericModal,
  ToastError,
  ToastSuccess,
} from '../../../Utilities/Components';
// Conifig
import { API } from '../../../Config/cfg.js';
// Permissions
import { LOCATIONS_EDIT } from '../../../Permissions/Permissions';

const Documents = ({ hash }) => {
  const [docHash, setDocHash] = useState('');
  const [docName, setDocName] = useState('');
  const [callAPI, setCallAPI] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (callAPI) {
      setCallAPI(false);
    }
  }, [callAPI]);

  const openModal = (hash, name) => {
    setModalIsOpen(true);
    setDocHash(hash);
    setDocName(name);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const removeDocument = () => {
    setSubmitting(true);
    let req = {
      DocumentHash: docHash,
    };
    API.delete(`/locations/${hash}/documents`, { data: req })
      .then(() => {
        setDocHash('');
        setDocName('');
        setCallAPI(true);
        setModalIsOpen(false);
        ToastSuccess(`Document has been removed from location`);
      })
      .catch(error => {
        ToastError(error);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Table
        columns={columnBuilder(openModal)}
        endpoint={`locations/${hash}/documents`}
        tablename="Location Documents"
        triggerAPI={callAPI}
        actions={[
          {
            name: 'Upload Document',
            link: '/locations/document-upload',
            params: hash,
            state: { fromLocation: 'Locations' },
            permission: LOCATIONS_EDIT,
          },
        ]}
        nested
      />
      <GenericModal
        modalIsOpen={modalIsOpen}
        headerMsg="ATTENTION: Removing Document"
        viewMsg={`This action will remove ${docName} from this location.`}
        btnMsg={`By clicking 'Remove Document' ${docName} will be removed from this location`}
        btnText="Remove Document"
        btnAction={removeDocument}
        closeModal={closeModal}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const columnBuilder = openModal => [
  {
    Header: 'Name',
    accessor: 'Name',
    Cell: ({ row: { original } }) => (
      <a href={original.URL} target="_blank" rel="noopener noreferrer">
        {original.Name}
      </a>
    ),
  },
  {
    Header: 'Type',
    accessor: 'Type',
    Cell: ({ row: { original } }) =>
      DocumentPill(original.Type, original.TypeText),
  },
  {
    Header: 'Remove Document',
    accessor: 'Hash',
    Cell: ({ row: { original } }) => (
      <button
        className="column-btn remove"
        onClick={() => openModal(original.Hash, original.Name)}
      >
        Delete
      </button>
    ),
  },
];

export default Documents;
