//Modules
import React from "react";
import { Link } from "react-router-dom";

const CancelLink = () => {
  return (
    <Link to="/locations" className="cancel-link">
      Cancel
    </Link>
  );
};

export default CancelLink;
