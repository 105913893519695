import React, { useState, useEffect } from 'react';
import { LoadingSVG } from './Loading/Loading';
import EmptyState from './EmptyState';
import ToastError from './ToastError';
import LoadingButton from './LoadingButton';
import { useThrottle } from 'src/Hooks';
import { useApi } from 'src/Services/Api';
import PropTypes from 'prop-types';

export default function InfiniteLoader({
  url,
  children,
  emptyMessage,
  limitBy,
}) {
  const [limit, setLimit] = useState(limitBy);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState(null);

  const [handleImages, isLoading] = useApi({
    url: `${url}?l=${limit}`,
    onError: error => ToastError(error),
    onSuccess: data => {
      if (!data) {
        setHasMore(false);
      } else if (data?.TotalResults) {
        // TODO remove this when nested Data array is removed
        if (data?.Data.length === 0) {
          setHasMore(false);
        } else if (data?.Data.length >= limit) {
          setHasMore(true);
          setLimit(limit + limitBy);
        } else {
          setHasMore(false);
        }

        setData(data.Data);
      } else {
        if (data?.length === 0) {
          setHasMore(false);
        } else if (data?.length >= limit) {
          setHasMore(true);
          setLimit(limit + limitBy);
        } else {
          setHasMore(false);
        }

        setData(data);
      }

      setIsLoaded(true);
    },
  });

  const handleNext = useThrottle(async () => {
    if (!hasMore) return;

    await handleImages();
  }, 300);

  useEffect(() => {
    handleNext();
  }, []);

  const render = () => {
    if (!isLoaded) {
      return (
        <div data-testid="infinite-loader-loading" className="column">
          <LoadingSVG />
        </div>
      );
    } else if (data?.length > 0) {
      return (
        <>
          <div className="columns is-multiline">{children(data)}</div>
          <div style={{ textAlign: 'center', padding: 20 }}>
            <LoadingButton
              loading={isLoading}
              disabled={isLoading || !hasMore}
              btnAction={handleNext}
              btnText={hasMore ? 'Load More' : 'All Loaded'}
              testID="infinite-loader-button"
            />
          </div>
        </>
      );
    } else {
      return (
        <div data-testid="infinite-loader-empty-message" className="column">
          <EmptyState msg={emptyMessage} />;
        </div>
      );
    }
  };

  return <div data-testid="infinite-scroll-component">{render()}</div>;
}

InfiniteLoader.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  limitBy: PropTypes.number,
};

InfiniteLoader.defaultProps = {
  limitBy: 4,
};
