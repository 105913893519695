// ACCOUNTING
export const ACCOUNTING_VIEW = 'accounting.view';
export const ACCOUNTING_REPORTS_EDIT = 'accounting.reports.edit';
export const ACCOUNTING_COMMISS_MANAGE = 'accounting.commissions.*';
export const ACCOUNTING_COMMISS_VIEW = 'accounting.commissions.view';
export const ACCOUNTING_COMMISS_CREATE = 'accounting.commissions.create';
export const ACCOUNTING_COMMISS_EDIT = 'accounting.commissions.edit';
export const ACCOUNTING_COMMISS_PAYMENT =
  'accounting.commissions.immediate-payment';
export const ACCOUNTING_GROSS_COMMISS = 'accounting.commissions.gross.view';
export const ACCOUNTING_ACCOUNTS = 'accounting.accounts.*';

// ACCOUNTS
export const ACCOUNT_VIEW = 'accounts.view';
export const ACCOUNT_INVITE = 'accounts.invite';
export const ACCOUNT_EDIT = 'accounts.edit';
export const ACCOUNT_CONTACT = 'accounts.contact';
export const ACCOUNT_CONTACT_FREEFORM = 'accounts.contact.freeform';
export const ACCOUNT_MANAGE = 'accounts.manage';
export const ACCOUNTS_DOCUMENTS_VIEW = 'accounts.documents.view';

// COMPLIANCE
export const COMPLIANCE_BAN_REVIEW = 'compliance.ban.review';
export const COMPLIANCE_ALERTS_VIEW = 'compliance.alerts.view';
export const COMPLIANCE_ALERTS_ACTION = 'compliance.alerts.action';
export const COMPLIANCE_INVEST_VIEW = 'compliance.investigations.view';
export const COMPLIANCE_INVEST_ACTION = 'compliance.investigations.action';
export const COMPLIANCE_INVEST_REVIEW = 'compliance.investigations.review';
export const COMPLIANCE_CTR_VIEW = 'compliance.ctrs.view';
export const COMPLIANCE_CTR_UPLOAD = 'compliance.ctrs.upload';
export const COMPLIANCE_VIEW = 'compliance.view';
export const COMPLIANCE_APPROVE = 'compliance.approve';
export const COMPLIANCE_HOLD_REVIEW = 'compliance.hold.review';
export const COMPLIANCE_OPEN_INVEST = 'compliance.investigations.open';
export const COMPLIANCE_BAN = 'compliance.ban';
export const COMPLIANCE_EDDS_VIEW = 'compliance.edds.view';
export const COMPLIANCE_EDDS_ACTION = 'compliance.edds.action';
export const COMPLIANCE_SUBPOENA_VIEW = 'compliance.subpoena.view';
export const COMPLIANCE_SUBPOENA_ACTION = 'compliance.subpoena.action';
export const COMPLIANCE_SUBPOENA_FULL = 'compliance.subpoena.*';
export const COMPLIANCE_PEP_VIEW = 'compliance.pep.view';
export const COMPLIANCE_PEP_ACTION = 'compliance.pep.action';
export const COMPLIANCE_PEP_FULL = 'compliance.pep.*';

// GROUPS
export const GROUPS_VIEW = 'groups.view';
export const GROUPS_CREATE = 'groups.create';
export const GROUPS_EDIT = 'groups.edit';
export const GROUP_DOCUMENTS_VIEW = 'groups.documents.view';

// FUNDING
export const FUNDING_VIEW = 'funding.view';

// KEYS
export const KEY_EDIT = 'keys.create.*';

// LOCATIONS
export const LOCATIONS_VIEW = 'locations.view';
export const LOCATIONS_CREATE = 'locations.create';
export const LOCATIONS_EDIT = 'locations.edit';
export const LOCATIONS_DOCUMENTS_VIEW = 'locations.documents.view';

// PLATFORMS
export const PLATFORMS_VIEW = 'platforms.view';
export const PLATFORMS_EDIT = 'platforms.edit';
export const PLATFORMS_CREATE = 'platforms.create';

// SETTINGS
export const SETTINGS = 'settings';
export const SETTINGS_EDIT = 'settings.edit';
export const SETTINGS_BUSINESS_EDIT = 'settings.business.edit';
export const SETTINGS_BUSINESS_VIEW = 'settings.business.view';
export const SETTINGS_COMPLIANCE_EDIT = 'settings.compliance.edit';

// TRANSACTIONS
export const TX_VIEW = 'tx.view';
export const TX_EDIT = 'tx.edit';
export const TX_FORCE_SETTLE = 'tx.force-settle';
export const TX_FREEZE = 'tx.freeze';
export const TX_EXECUTE = 'tx.execute';
export const TX_CANCEL = 'tx.cancel';

// VOUCHERS
// PLATFORMS
export const VOUCHERS_VIEW = 'vouchers.view';
export const VOUCHERS_EDIT = 'vouchers.edit';
export const VOUCHERS_CREATE = 'vouchers.create';
export const VOUCHERS_VOID = 'vouchers.void';
