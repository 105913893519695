/* eslint no-console: ["error", { allow: [ "error"] }] */

import React, { Component } from 'react';
// Components
import {
  BanAccountModal,
  BanAddressModal,
  ContactModal,
  InviteEmployeeModal,
  RefreshPinModal,
  MergeAccountModal,
  ResetPinModal,
  RemoveBanModal,
  OpenInvestigationModal,
  TwoFactorModal,
  EDDModal,
  NewEDDSurveyModal,
  PEPModal,
} from '../SinglePage/Modals';
import OverturnHoldModal from '../SinglePage/OverturnHoldModal';
import {
  ButtonAction,
  ToastError,
  ToastSuccess,
} from '../../Utilities/Components';
// Icons
import { ICONS } from '../../Icons/IconsPath';
// Permissions
import {
  COMPLIANCE_OPEN_INVEST,
  COMPLIANCE_BAN,
  COMPLIANCE_APPROVE,
  COMPLIANCE_HOLD_REVIEW,
  COMPLIANCE_EDDS_VIEW,
  COMPLIANCE_PEP_VIEW,
  ACCOUNT_EDIT,
  ACCOUNT_MANAGE,
  ACCOUNT_CONTACT,
  GROUPS_EDIT,
} from '../../Permissions/Permissions';
// Configs
import { API } from '../../Config/cfg.js';

class AccountActions extends Component {
  state = {
    newPin: '',
    username: '',
    isRefreshOpen: false,
    isResetOpen: false,
    isAccountBanOpen: false,
    isMergeAccountOpen: false,
    isRemoveBanOpen: false,
    isOverturnHoldOpen: false,
    isPEPOpen: false,
    isInvestigationOpen: false,
    isContactOpen: false,
    isBanAddressOpen: false,
    isInviteOpen: false,
    isRemoveTwoFactorOpen: false,
    initializeEDD: false,
    isNewEDDSurveyOpen: false,
    groups: [],
    templates: [],
  };

  componentDidMount() {
    this.getSMSTemplates();
    this.getGroups();
  }

  openModal = state => {
    this.setState({ [state]: true });
  };

  closeModal = state => {
    this.setState({ [state]: false, newPin: '' });
  };

  getSMSTemplates = () => {
    const { Hash } = this.props.Account;
    API.get(`/accounts/${Hash}/sms-templates`)
      .then(res => {
        this.setState({ templates: res.data });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getGroups = () => {
    API.get('/groups?all=true')
      .then(res => {
        this.setState({
          groups: res.data.Data,
          loading: false,
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  postResetPin = async hash => {
    this.props.toggleSubmitting();
    await API.post(`/accounts/${hash}/pin/reset`, { PIN: '' })
      .then(res => {
        ToastSuccess(`PIN has been reset`);
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => {
        this.props.toggleSubmitting();
      });
    this.closeModal('isResetOpen');
  };

  postRefreshPin = async hash => {
    this.props.toggleSubmitting();
    await API.post(`/accounts/${hash}/pin/refresh`, { PIN: '' })
      .then(res => {
        const { data } = res;
        this.setState({ newPin: data.PIN, username: data.Username });
        ToastSuccess(`PIN has been refreshed`);
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => this.props.toggleSubmitting());
  };

  putAccountBan = async values => {
    const { Hash } = this.props.Account;
    await API.put(`accounts/${Hash}/ban`, values)
      .then(res => {
        this.props.getAccount();
        ToastSuccess('Account has been banned successfully');
      })
      .catch(err => {
        ToastError(err);
      });
    this.closeModal('isAccountBanOpen');
  };

  postMergeAccount = async values => {
    await API.post(`/account/merge`, values)
      .then(res => {
        this.props.getAccount();
        ToastSuccess('Accounts have been merged successfully');
      })
      .catch(err => {
        ToastError(err);
      });
    this.closeModal('isMergeAccountOpen');
  };

  putComplianceEDD = async values => {
    const { Hash } = this.props.Account;
    await API.put(`/accounts/${Hash}/compliance-edd`, values)
      .then(res => {
        this.props.getAccount();
        ToastSuccess('Enhanced Due Diligence has been intialized');
      })
      .catch(err => {
        ToastError(err);
      });
    this.closeModal('initializeEDD');
  };

  putNewEDDSurvey = async () => {
    this.props.toggleSubmitting();
    const { Hash } = this.props.Account;
    await API.put(`/accounts/${Hash}/edd-survey`)
      .then(res => {
        this.props.getAccount();
        ToastSuccess('A new EDD Survey has been sent');
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => {
        this.props.toggleSubmitting();
      });
    this.closeModal('isNewEDDSurveyOpen');
  };

  putNotification = async (values, actions) => {
    let req = {
      Type: values.type === 'custom' ? 'sms' : values.type,
      Subject: null,
    };

    if (values.emailFrom) {
      req.From = values.emailFrom;
    }

    if (values.type === 'sms') {
      req.TemplateHash = values.smsMsg;
    } else if (values.type === 'email') {
      req.Subject = values.subject;
      req.Message = values.emailMsg;
    } else if (values.type === 'custom') {
      req.Message = values.freeformMsg;
      req.From = 'support@digitalmint.io';
    }

    const {
      Account: { Hash },
    } = this.props;
    await API.put(`/accounts/${Hash}/notify`, req)
      .then(() => {
        ToastSuccess('Notification successfully sent');
        actions.resetForm();
        this.setState({ isContactOpen: false });
      })
      .catch(error => {
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  postRemoveAccountBan = async values => {
    const { BanHash } = this.props.Account;
    await API.delete(`compliance/bans/${BanHash}`, { data: values })
      .then(res => {
        this.props.getAccount();
        ToastSuccess('Account ban has been removed successfully');
      })
      .catch(err => {
        ToastError(err);
      });
    this.closeModal('isRemoveBanOpen');
  };

  putAccountInvestigation = async values => {
    await API.put(`compliance/investigations`, values)
      .then(res => {
        ToastSuccess('A new Investigation has been opened');
      })
      .catch(err => {
        ToastError(err);
      });
    this.closeModal('isInvestigationOpen');
  };

  putComplianceWalletBan = async values => {
    await API.put(`/compliance/wallet-bans`, values)
      .then(res => {
        ToastSuccess('Wallet Address Has Been Banned');
      })
      .catch(err => {
        ToastError(err);
      });
    this.closeModal('isBanAddressOpen');
  };

  postOverturnHold = async values => {
    const { Hash } = this.props.Account;
    await API.post(`accounts/${Hash}/overturn-hold`, values)
      .then(res => {
        this.props.getAccount();
        ToastSuccess('Account hold has been overturned successfully');
      })
      .catch(err => {
        ToastError(err);
      });
    this.closeModal('isOverturnHoldOpen');
  };

  putEmployeeInvitation = async values => {
    await API.put(`/invitations/employee`, values)
      .then(res => {
        ToastSuccess('Invitation has been sent');
      })
      .catch(err => {
        ToastError(err);
      });
    this.closeModal('isInviteOpen');
  };

  putPEP = async values => {
    const { toggleSubmitting } = this.props;
    const {
      Account: { Hash, FirstName, LastName },
    } = this.props;
    toggleSubmitting();
    await API.put('/compliance/peps', {
      FirstName,
      LastName,
      AccountHash: Hash,
    })
      .then(res => {
        ToastSuccess('PEP has been created');
      })
      .catch(err => {
        ToastError(err);
      })
      .finally(() => {
        toggleSubmitting();
      });
    this.closeModal('isPEPOpen');
  };

  removeTwoFactor = async () => {
    const { Hash } = this.props.Account;
    await API.post(`/accounts/${Hash}/otp`)
      .then(() => {
        this.props.getAccount();
        ToastSuccess('Two-Factor Authentication has been removed');
      })
      .catch(err => {
        ToastError(err);
      });
    this.closeModal('isRemoveTwoFactorOpen');
  };

  render() {
    const { data, Account, isSubmitting } = this.props;
    const {
      newPin,
      username,
      templates,
      groups,
      isRefreshOpen,
      isResetOpen,
      isAccountBanOpen,
      isMergeAccountOpen,
      isRemoveBanOpen,
      isOverturnHoldOpen,
      isPEPOpen,
      isInvestigationOpen,
      isContactOpen,
      isBanAddressOpen,
      isInviteOpen,
      isRemoveTwoFactorOpen,
      initializeEDD,
      isNewEDDSurveyOpen,
    } = this.state;

    return (
      <>
        <RefreshPinModal
          newPin={newPin}
          username={username}
          isOpen={isRefreshOpen}
          postRefreshPin={this.postRefreshPin}
          hash={Account.Hash}
          isSubmitting={isSubmitting}
          closeModal={this.closeModal}
        />
        <ResetPinModal
          isOpen={isResetOpen}
          postResetPin={this.postResetPin}
          hash={Account.Hash}
          isSubmitting={isSubmitting}
          closeModal={this.closeModal}
        />
        <BanAccountModal
          isOpen={isAccountBanOpen}
          account={`${Account.FirstName} ${Account.LastName}`}
          putAccount={this.putAccountBan}
          hash={Account.Hash}
          closeModal={this.closeModal}
        />
        <MergeAccountModal
          isOpen={isMergeAccountOpen}
          account={`${Account.FirstName} ${Account.LastName}`}
          putAccount={this.postMergeAccount}
          hash={Account.Hash}
          closeModal={this.closeModal}
        />
        <RemoveBanModal
          isOpen={isRemoveBanOpen}
          account={`${Account.FirstName} ${Account.LastName}`}
          putAccount={this.postRemoveAccountBan}
          hash={Account.Hash}
          closeModal={this.closeModal}
        />
        <OpenInvestigationModal
          isOpen={isInvestigationOpen}
          account={`${Account.FirstName} ${Account.LastName}`}
          putAccount={this.putAccountInvestigation}
          hash={Account.Hash}
          closeModal={this.closeModal}
        />
        <OverturnHoldModal
          isOpen={isOverturnHoldOpen}
          holds={Account.Holds}
          account={`${Account.FirstName} ${Account.LastName}`}
          hash={Account.Hash}
          putAccount={this.postOverturnHold}
          closeModal={this.closeModal}
        />
        <ContactModal
          isOpen={isContactOpen}
          hash={Account.Hash}
          templates={templates}
          putAccount={this.putNotification}
          closeModal={this.closeModal}
        />

        <BanAddressModal
          isOpen={isBanAddressOpen}
          closeModal={this.closeModal}
          hash={Account.Hash}
          putAccount={this.putComplianceWalletBan}
        />
        <EDDModal
          isOpen={initializeEDD}
          closeModal={this.closeModal}
          hash={Account.Hash}
          putAccount={this.putComplianceEDD}
        />
        <PEPModal
          isOpen={isPEPOpen}
          closeModal={this.closeModal}
          putPEP={this.putPEP}
          isSubmitting={isSubmitting}
        />
        <NewEDDSurveyModal
          isOpen={isNewEDDSurveyOpen}
          closeModal={this.closeModal}
          hash={Account.Hash}
          putNewEDDSurvey={this.putNewEDDSurvey}
          isSubmitting={isSubmitting}
        />
        <InviteEmployeeModal
          isOpen={isInviteOpen}
          closeModal={this.closeModal}
          hash={Account.Hash}
          groups={groups}
          email={Account.Email}
          putAccount={this.putEmployeeInvitation}
        />
        <TwoFactorModal
          isOpen={isRemoveTwoFactorOpen}
          closeModal={this.closeModal}
          hash={Account.Hash}
          postAccount={this.removeTwoFactor}
        />
        <div className="columns is-multiline is-centered btn-icons is-mobile">
          {Account.Banned ? (
            <ButtonAction
              icon={ICONS.USER}
              toolTip="Remove Account Ban"
              className="primary-btn-outline"
              onClick={() => this.openModal('isRemoveBanOpen')}
              permission={COMPLIANCE_BAN}
            />
          ) : (
            <ButtonAction
              icon={ICONS.WARNING}
              toolTip="Ban Account"
              className="red-btn-outline"
              onClick={() => this.openModal('isAccountBanOpen')}
              permission={COMPLIANCE_BAN}
            />
          )}

          <ButtonAction
            icon={ICONS.GROUPS}
            toolTip="Merge Account"
            className="orange-btn-outline"
            onClick={() => this.openModal('isMergeAccountOpen')}
            permission={ACCOUNT_MANAGE}
          />

          <ButtonAction
            className="primary-btn-outline"
            toolTip="ID Upload"
            icon={ICONS.UPLOAD}
            permission={COMPLIANCE_APPROVE}
            onClick={() => {
              const { history } = this.props;
              history.push({
                pathname: '/accounts/upload-image',
                hash: Account.Hash,
                state: { account: data },
              });
            }}
          />

          <ButtonAction
            icon={ICONS.FILE}
            toolTip="Open Compliance Investigation"
            className="orange-btn-outline"
            onClick={() => this.openModal('isInvestigationOpen')}
            permission={COMPLIANCE_OPEN_INVEST}
          />

          <ButtonAction
            icon={ICONS.PIN}
            toolTip="Reset Pin"
            className="red-btn-outline"
            onClick={() => this.openModal('isResetOpen')}
            permission={ACCOUNT_EDIT}
          />
          <ButtonAction
            icon={ICONS.MESSAGE}
            toolTip="Contact"
            className="primary-btn-outline"
            onClick={() => this.openModal('isContactOpen')}
            permission={ACCOUNT_CONTACT}
          />
          <ButtonAction
            icon={ICONS.REFRESH}
            toolTip="Refresh Pin"
            className="red-btn-outline"
            onClick={() => this.openModal('isRefreshOpen')}
            permission={ACCOUNT_EDIT}
          />
        </div>
        <div className="columns is-multiline is-centered btn-icons is-mobile">
          <ButtonAction
            icon={ICONS.MAGNIFY}
            toolTip="Initiate EDD Review"
            className="orange-btn-outline"
            onClick={() => this.openModal('initializeEDD')}
            permission={COMPLIANCE_EDDS_VIEW}
          />
          <ButtonAction
            icon={ICONS.BAN_ADDRESS}
            toolTip="Ban Wallet Address"
            className="red-btn-outline"
            onClick={() => this.openModal('isBanAddressOpen')}
            permission={COMPLIANCE_BAN}
          />

          {Account.Holds && !Account.Banned && (
            <ButtonAction
              icon={ICONS.TRANSACTION}
              toolTip="Overturn Hold"
              className="primary-btn-outline"
              onClick={() => this.openModal('isOverturnHoldOpen')}
              permission={COMPLIANCE_HOLD_REVIEW}
            />
          )}

          <ButtonAction
            icon={ICONS.MESSAGE}
            toolTip="Send EDD Survey"
            className="orange-btn-outline"
            onClick={() => this.openModal('isNewEDDSurveyOpen')}
            permission={COMPLIANCE_EDDS_VIEW}
          />

          <ButtonAction
            icon={ICONS.USER}
            toolTip="Create PEP"
            className="red-btn-outline"
            onClick={() => this.openModal('isPEPOpen')}
            putPEP
            permission={COMPLIANCE_PEP_VIEW}
          />

          {(Account.Status !== 12 ||
            !Account.HasPassword ||
            Account.Email === null) && (
            <ButtonAction
              icon={ICONS.USER}
              toolTip="Invite Employee to Group"
              className="primary-btn-outline"
              onClick={() => this.openModal('isInviteOpen')}
              permission={GROUPS_EDIT}
            />
          )}
          {Account.OTPEnabled && (
            <ButtonAction
              icon={ICONS.LOCK}
              toolTip="Remove Two-Factor Authentication"
              className="red-btn-outline"
              onClick={() => this.openModal('isRemoveTwoFactorOpen')}
              permission={ACCOUNT_MANAGE}
            />
          )}
        </div>
      </>
    );
  }
}

export default AccountActions;
