// Modules
import React from "react";
import columnBuilder from "../../Platforms/columnBuilder";
// Components
import Table from "./../../Table/Table";

const GroupPlatforms = ({ hash }) => {
  return (
    <Table
      columns={columnBuilder}
      downloadable={true}
      endpoint={`groups/${hash}/platforms`}
      tablename="Platforms"
      nested={true}
    />
  );
};

export default GroupPlatforms;
