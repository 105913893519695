// Modules
import React from "react";
// Components
import Table from "./../../../Table/Table";
import { TxState } from "./../../../Utilities/Components/TablePills";
// Helpers - Formatters
import { formatDateTime } from "../../../Utilities/Formatters";
import { reqStrProp } from "./../../../Utilities/PropTypes";

const TransactionHistory = ({ hash, callAPI }) => {
  return (
    <Table
      columns={columnBuilder}
      endpoint={`transactions/${hash}/history`}
      triggerAPI={callAPI}
      nested={true}
      tablename="Transaction History"
    />
  );
};

TransactionHistory.propTypes = {
  hash: reqStrProp,
};

export default TransactionHistory;

const columnBuilder = [
  {
    Header: "Status",
    accessor: "StateDesc",
    Cell: ({ row: { original } }) =>
      TxState(original.State, original.StateDesc),
  },
  {
    Header: "Device ID",
    accessor: "DeviceID",
    width: 200,
  },
  {
    Header: "IP",
    accessor: "IP",
    width: 110,
  },
  {
    Header: "Event",
    accessor: "EventDesc",
    Cell: ({ row: { original } }) =>
      original.Note ? original.Note : original.EventDesc,
  },
  {
    Header: "Updated At",
    accessor: "UpdatedAt",
    Cell: ({ row: { original } }) => formatDateTime(original.UpdatedAt),
    width: 110,
  },
];
