import React from "react";
import { Link } from "react-router-dom";
// Helpers - Formatters
import { formatDateTime } from "../../Utilities/Formatters";

const columnBuilder = [
  {
    Header: "PEP #",
    accessor: "Code",
    Cell: ({ row: { original } }) =>
      original.Hash ? (
        <Link className="table-pill" to={`/compliance/peps/${original.Hash}`}>
          {original.Code}
        </Link>
      ) : null,
  },
  {
    Header: "First Name",
    accessor: "FirstName",
  },
  {
    Header: "Last Name",
    accessor: "LastName",
  },
  {
    Header: "Created At",
    accessor: "CreatedAt",
    Cell: ({ row: { original } }) =>
      original.CreatedAt
        ? formatDateTime(original.CreatedAt, "MM/DD/YYYY hh:mm:ss A", true)
        : "-",
  },
  {
    Header: "Narrative",
    accessor: "Narrative",
    Cell: ({ row: { original } }) => {
      if (original.Narrative.length >= 80) {
        return original.Narrative.substring(0, 76) + "...";
      }
      return original.Narrative;
    },
  },
  {
    Header: "Decision",
    accessor: "Decision",
    Cell: ({ row: { original } }) => {
      if (original.Decision) {
        return original.Decision ? "Confirmed" : "False Positive";
      }
      return "-";
    },
    sortMethod: "Decision",
  },
  {
    Header: "Account",
    accessor: "Account.Hash",
    style: { textAlign: "left" },
    Cell: ({ row: { original } }) => (
      <Link className="table-pill" to={`/accounts/${original.Account.Hash}`}>
        {original.Account.FullName}
      </Link>
    ),
    sortMethod: "LastName",
    default: true,
  },
  {
    Header: "Country of Origin",
    accessor: "Country.Code",
  },
];

export default columnBuilder;
