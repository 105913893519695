/* COMPONENT OVERVIEW
- Builds object structure for react-table
- Renders Account's transaction table
*/
// Modules
import React, { Component } from 'react';
// Components
import Table from '../../../Table/Table';
import { GenericModal } from '../../../Utilities/Components';
import { PlatformPill } from './../../../Utilities/Components/TablePills';
// Helpers - Formatters
import { formatDateTime } from '../../../Utilities/Formatters';
import { ToastError, ToastSuccess } from '../../../Utilities/Components';
// Icons
import { ICONS } from './../../../Icons/IconsPath';
import Icon from './../../../Icons/Icon';
// Permissions
import { KEY_EDIT } from './../../../Permissions/Permissions';
// Configs
import { API } from './../../../Config/cfg.js';

class AccountAPIKeys extends Component {
  state = {
    apiKey: '',
    apiHash: '',
    callAPI: false,
    data: [],
    modalIsOpen: false,
    errors: {},
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.callAPI === true) {
      this.setState({ callAPI: false });
    }
  }

  revokeAPIKey = () => {
    const { apiHash } = this.state;
    API.delete(`/keys/${apiHash}`)
      .then(res => {
        this.setState({
          modalIsOpen: false,
          callAPI: true,
        });
        ToastSuccess(`Account Key has been revoked`);
      })
      .catch(error => {
        ToastError(error);
      });
  };

  openModal = (apiKey, apiHash) => {
    this.setState({ modalIsOpen: true, apiKey, apiHash });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { apiKey, callAPI, modalIsOpen } = this.state;
    const { accountHash } = this.props;

    return (
      <React.Fragment>
        <GenericModal
          modalIsOpen={modalIsOpen}
          closeModal={this.closeModal}
          headerMsg="ATTENTION: Removing API Key"
          viewMsg="This action will REMOVE all access to DigitalMint's API for this API Key."
          importantData={[{ label: 'Key', value: apiKey }]}
          btnMsg="By clicking 'Remove API Key' the current API KEY will no longer work"
          btnText="Remove API Key"
          btnAction={this.revokeAPIKey}
        />
        <Table
          columns={columnBuilder(this.openModal)}
          endpoint={`accounts/${accountHash}/keys`}
          nested={true}
          tablename="Account API Keys"
          triggerAPI={callAPI}
          actions={[
            {
              name: 'Generate API Key',
              link: '/accounts/generate-key',
              params: accountHash,
              permission: KEY_EDIT,
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

export default AccountAPIKeys;

const columnBuilder = func => {
  return [
    {
      Header: 'Key Name',
      accessor: 'Name',
    },
    {
      Header: 'Key UUID',
      style: { textAlign: 'left ' },
      accessor: 'Key',
    },
    {
      Header: 'Date Created',
      Cell: ({ row: { original } }) => formatDateTime(original.CreatedAt),
    },
    {
      Header: 'Platform',
      accessor: 'Platform.Name',
      Cell: ({ row: { original } }) =>
        original.PlatformType
          ? PlatformPill(
              `${original.PlatformType.Name} ${original.PlatformType.Model}`
            )
          : null,
    },
    {
      Header: 'Active',
      accessor: 'Active',
      Cell: ({ row: { original } }) =>
        original.Active ? (
          <Icon icon={ICONS.ACTIVE} size="16" color="#009245" rightMargin="0" />
        ) : (
          <Icon
            icon={ICONS.INACTIVE}
            size="16"
            color="#ff3560"
            rightMargin="0"
          />
        ),
      maxWidth: 85,
    },
    {
      Header: 'Remove',
      Cell: ({ row: { original } }) => (
        <button
          className="column-btn remove"
          onClick={() => func(original.Key, original.Hash)}
        >
          Revoke
        </button>
      ),
    },
  ];
};
