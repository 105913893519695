// Modules
import React from "react";
// Components
import { FormInput } from "../../Forms/FormInputs";
import { GenericForm, FormikContext } from "../../Forms/Form";

const DefaultWalletForm = ({ hash, history }) => {
  return (
    <GenericForm
      endpoint={`accounts/${hash}/default-wallets`}
      setValues={{
        WalletAddress: "",
        ProductSymbol: "BTCUSD", // TO DO: Make dynamic when new coins are available
      }}
      customValidations={(values) => {
        let errors = {};
        if (!values.WalletAddress) {
          errors.WalletAddress = "is required";
        }
        return errors;
      }}
      formTitle="Add Default Wallet"
      goBack={{
        show: true,
        setHistory: history,
        setDefaultPath: "/accounts",
        setDefaultName: "Accounts",
      }}
      toastMsg="A new default wallet has been created"
    >
      <FormikContext.Consumer>
        {(props) => (
          <div>
            <div className="columns">
              <div className="column">
                <FormInput
                  label="Wallet Address"
                  name="WalletAddress"
                  type="text"
                  placeholder="3FZbgi29cpjq2GjdwV8eyHuJJnkLtktZc5"
                  errors={props.errors}
                  touched={props.touched}
                />
              </div>
              <div className="column"></div>
            </div>
          </div>
        )}
      </FormikContext.Consumer>
    </GenericForm>
  );
};

export default DefaultWalletForm;
