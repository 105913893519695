// Modules
import React from "react";
import columnBuilder from "./columnBuilder";
// Components
import Table from "./../Table/Table";

const Vouchers = (props) => {
  return (
    <Table
      history={props.history}
      columns={columnBuilder}
      endpoint="vouchers"
      filter={true}
      tablename="Vouchers"
      downloadable={true}
      customize
    />
  );
};

export default Vouchers;
