import React from 'react';
import { Link } from 'react-router-dom';
// Sidebar Nav Components
import NavLinks from './NavLinks';
// Icons
import { ICONS } from '../Icons/IconsPath';
import Icon from '../Icons/Icon';
import AltLogo from '../images/dm_white.svg';

const SideNavbar = ({ routes, navComponent, toggle }) => {
  return (
    <aside id="sidebarNav">
      <div className="columns is-vcentered">
        <div className="column">
          <Link to="/">
            <img src={AltLogo} className="logo" alt="brand-logo" />
          </Link>
        </div>
        <button onClick={toggle} className="menu column is-narrow">
          <Icon icon={ICONS.LEFTARROW} color="#ffffff" size="20" />
        </button>
      </div>
      <NavLinks routes={routes} navComponent={navComponent} />
    </aside>
  );
};

export default SideNavbar;
