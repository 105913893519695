import React from 'react';
import DualListBox from 'react-dual-listbox';
import { EmptyState } from '../../Utilities/Components';
import PropTypes from 'prop-types';

const EditableListBox = ({
  currentValue,
  editing,
  emptyMsg,
  id,
  onChange,
  options,
  selected,
  testID,
}) => {
  return (
    <>
      {editing ? (
        <div id="listBox" data-testid={testID}>
          <DualListBox
            id={id}
            options={options}
            selected={selected}
            onChange={onChange}
          />
        </div>
      ) : (
        <>
          {currentValue ? (
            currentValue.map((per, index) => {
              return (
                <div
                  data-testid={`${testID}Column${index}`}
                  className="columns"
                  key={index}
                >
                  <div className="column">{per.Name}</div>
                </div>
              );
            })
          ) : (
            <EmptyState msg={emptyMsg} />
          )}
        </>
      )}
    </>
  );
};

EditableListBox.defaultProps = {
  currentValue: [],
  editing: false,
  id: 'List',
  onChange: () => {},
  options: [],
  selected: [],
  testID: 'xEditableListBox',
};

EditableListBox.propTypes = {
  currentValue: PropTypes.array,
  editing: PropTypes.bool,
  emptyMsg: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.array,
  testID: PropTypes.string,
};

export default EditableListBox;
