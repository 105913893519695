import React from 'react';
import { Field, ErrorMessage } from 'formik';
import Icon from '../../Icons/Icon';
import { ICONS } from '../../Icons/IconsPath';
import { formatLabel } from './utils';
import PropTypes from 'prop-types';

const EditableCheckbox = ({
  currentValue,
  disabled,
  editing,
  id,
  label,
  name,
}) => {
  return (
    <div className="form-row">
      {editing ? (
        <>
          <ErrorMessage name={name} component="div" className="form-error" />
          <label htmlFor={id || name} className="item-label">
            {formatLabel(label)}
          </label>
          <Field
            id={id || name}
            name={name}
            type="checkbox"
            component="input"
            disabled={disabled}
          />
        </>
      ) : (
        <>
          <label className="item-label">{formatLabel(label)}:</label>

          <p className="justify-icon">
            {name === 'Available' ? (
              <Icon
                color={currentValue ? '#006994' : '#ff3560'}
                icon={ICONS.BROADCAST}
                rightMargin="0"
                size="15"
              />
            ) : (
              <Icon
                color={currentValue ? '#009245' : '#e71d36'}
                icon={currentValue ? ICONS.ACTIVE : ICONS.INACTIVE}
                rightMargin="5"
                size="15"
              />
            )}
          </p>
        </>
      )}
    </div>
  );
};

EditableCheckbox.defaultProps = {
  disabled: false,
  editing: false,
};

EditableCheckbox.propTypes = {
  currentValue: PropTypes.bool,
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default EditableCheckbox;
