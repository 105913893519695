/* eslint no-console: ["error", { allow: [ "error"] }] */

// Modules
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import qs from 'query-string';
// Doc Components
import UploadIDForm from './UploadIDForm';
// Components
import { GoBack, Loading, Header, ToastSuccess } from '../Utilities/Components';
// Icons
import Icon from '../Icons/Icon';
import { ICONS } from '../Icons/IconsPath';
// Helpers - Formatters
import { formatDate } from '../Utilities/Formatters';
import { ToastError } from '../Utilities/Components';
// Config
import { API } from '../Config/cfg.js';
// Styles
import './upload.scss';

class UploadID extends Component {
  state = {
    data: [],
    query: {},
    loading: true,
    frontPhoto: undefined,
    backPhoto: undefined,
    uploadLoading: false,
    error: undefined,
    parsedHash: '',
  };

  componentDidMount() {
    this.getAccount();
  }

  getAccount = () => {
    const { hash } = this.props;
    const passedString = hash.split('?');
    const parsedHash = passedString[0];
    const query = qs.parse(passedString[1]);
    this.setState({ query, parsedHash });
    API.get(`/accounts/${parsedHash}`)
      .then(res => {
        this.setState({ data: res.data, loading: false });
      })
      .catch(error => {
        console.error(error);
      });
  };

  onDropFront = frontPhoto => {
    this.parseToBase64(frontPhoto[0], 'frontPhoto');
  };

  onDropBack = backPhoto => {
    this.parseToBase64(backPhoto[0], 'backPhoto');
  };

  parseToBase64 = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
    reader.onerror = error => console.error(error);
  };

  putAccountImages = (values, actions) => {
    const { parsedHash } = this.state;
    const { history } = this.props;
    const { frontPhoto, backPhoto } = this.state;
    this.setState({ error: undefined, uploadLoading: true });
    let req = {
      FirstName: values.FirstName,
      LastName: values.LastName,
      Middle: values.Middle,
      Suffix: values.Suffix,
      DOB: formatDate(values.DOB, 'YYYY-MM-DD', true),
      Expiry: formatDate(values.Expiry, 'YYYY-MM-DD', true),
      Issue: formatDate(values.Issue, 'YYYY-MM-DD', true),
      DocumentSerial: values.DocumentSerial,
      FirstData: frontPhoto,
      SecondData: backPhoto,
      Gender: values.Gender,
      Lifetime: values.Lifetime,
      Type: parseInt(values.Type),
      Street: values.Street,
      Street2: values.Street2,
      City: values.City,
      StateCode: values.StateCode,
      CountryCode: 'US',
      ZIP: values.Zip,
    };

    API.put(`/accounts/${parsedHash}/ids`, req)
      .then(res => {
        if (history && history.location.key) {
          history.push(`/accounts/${parsedHash}`);
        }
        ToastSuccess(`Image Uploaded to Account`);
        this.setState({ uploadLoading: false });
        history.push(`/accounts/${parsedHash}`);
      })
      .catch(error => {
        this.setState({ error: error.response.data, uploadLoading: false });
        actions.setSubmitting(false);
        // Check to see if file is too large
        if (error.response.status === 413) {
          ToastError(error.response.statusText);
          return;
        }
        ToastError(error);
      });
  };

  render() {
    const {
      data,
      query,
      backPhoto,
      frontPhoto,
      loading,
      uploadLoading,
      error,
    } = this.state;

    const frontThumbnail = (
      <React.Fragment>
        <img alt="" src={frontPhoto} className="dropzone-img" />
      </React.Fragment>
    );

    const backThumbnail = (
      <React.Fragment>
        <img alt="" src={backPhoto} className="dropzone-img" />
      </React.Fragment>
    );

    if (loading) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        <GoBack
          history={this.props.history}
          defaultPath="/accounts"
          defaultName="Accounts"
        />
        <Header
          code="Upload IDs"
          title={
            query.TypeText ? (
              `- ${query.TypeText}`
            ) : (
              <Link to={`/accounts/${data.Account.Hash}`}>
                - {data.Account.FullName}
              </Link>
            )
          }
        />
        <div className="form-outline form-create">
          <div className="columns ">
            <section className="column">
              <Dropzone
                accept="image/*"
                className="dropzone"
                onDrop={this.onDropFront}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone-container" {...getRootProps()}>
                    <input className="dropzone-input" {...getInputProps()} />
                    <div className="display">
                      <div hidden={frontPhoto}>
                        <Icon
                          icon={ICONS.UPLOAD}
                          size="30"
                          color="#009245"
                          rightMargin="0"
                        />
                        <p>Upload Front ID</p>
                        <p>
                          <small>File limit 5 MB (.jpg, .png)</small>
                        </p>
                      </div>

                      {uploadLoading && !error && (
                        <div className="lds-dual-ring" />
                      )}
                      <span
                        className={classnames('thumbnails', {
                          'disable-dropzone': uploadLoading && !error,
                        })}
                      >
                        {frontThumbnail}
                      </span>
                    </div>
                  </div>
                )}
              </Dropzone>

              {frontPhoto && (
                <p className="has-text-centered item-label">Front View</p>
              )}
            </section>
            <br />
            <section className="column">
              <Dropzone
                accept="image/*"
                className="dropzone"
                onDrop={this.onDropBack}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone-container " {...getRootProps()}>
                    <input className="dropzone-input" {...getInputProps()} />
                    <div className="display">
                      <div hidden={backPhoto} className="center">
                        <Icon
                          icon={ICONS.UPLOAD}
                          size="30"
                          color="#009245"
                          rightMargin="0"
                        />
                        <p>Upload Back ID</p>
                        <p>
                          <small>File limit 5 MB (.jpg, .png)</small>
                        </p>
                      </div>

                      {uploadLoading && !error && (
                        <div className="lds-dual-ring" />
                      )}
                      <span
                        className={classnames('thumbnails', {
                          'disable-dropzone': uploadLoading && !error,
                        })}
                      >
                        {backThumbnail}
                      </span>
                    </div>
                  </div>
                )}
              </Dropzone>
              {backPhoto && (
                <p className="has-text-centered item-label">Back View</p>
              )}
            </section>
          </div>
          <UploadIDForm putAccountImages={this.putAccountImages} data={data} />
        </div>
      </React.Fragment>
    );
  }
}

export default UploadID;
