import React, { Component } from "react";
// Components
import { TwoFactorModal } from "./TwoFactorModal";
import {
  ButtonAction,
  ToastError,
  ToastSuccess,
} from "../../../Utilities/Components";
// Icons
import Icon from "../../../Icons/Icon";
import { ICONS } from "../../../Icons/IconsPath";
// Configs
import { API } from "../../../Config/cfg";

class TwoFactorConfiguration extends Component {
  state = {
    openConfirm2FA: false,
    openRemove2FA: false,
    qrCodeData: undefined,
    enabled: false,
  };

  openConfirm = () => {
    this.getOneTimePassword();
    this.setState({ openConfirm2FA: true });
  };

  removeModal = () => {
    this.setState({ openRemove2FA: true });
  };

  closeModal = (name) => {
    this.setState({ [name]: false });
  };

  postAccountOTP = async (values) => {
    const { account, setAccount } = this.props;

    await API.post(`/account/otp`, values)
      .then((res) => {
        ToastSuccess(res.data.Message);
        this.setState({ openConfirm2FA: false, openRemove2FA: false });
        if (values.Remove) {
          account.OTPEnabled = false;
        } else {
          account.OTPEnabled = true;
        }
        setAccount(account);
      })
      .catch((error) => {
        ToastError(error);
      });
  };

  getOneTimePassword = async () => {
    await API.put(`/account/otp`, {})
      .then((res) => {
        this.setState({ enabled: true, qrCodeData: res.data.QRCode });
      })
      .catch((err) => {
        this.setState({ enabled: false });
        ToastError(err);
      });
  };

  render() {
    const { openConfirm2FA, openRemove2FA, qrCodeData } = this.state;
    const { account } = this.props;
    return (
      <>
        <TwoFactorModal
          isOpen={openConfirm2FA}
          header="Enable 2FA"
          isEnabled={true}
          closeModal={() => this.closeModal("openConfirm2FA")}
          postAccount={this.postAccountOTP}
          message="To enable 2FA download a multi-factor authentication app and scan the QR code shown above. Then enter the one-time passcode generated on your device."
          qrCode={qrCodeData}
        />
        <TwoFactorModal
          isOpen={openRemove2FA}
          isEnabled={false}
          header="Disable 2FA"
          message="Enter the 6-digit one-time passcode to remove your 2FA
          Authenticator"
          closeModal={() => this.closeModal("openRemove2FA")}
          postAccount={this.postAccountOTP}
        />

        <div className="form-outline form-create">
          <div className="columns">
            <div className="column">
              <div className="pills">
                <h2>Two-Factor Authentication</h2>
                {account.OTPEnabled ? (
                  <div className="form-pill enabled">
                    <Icon
                      icon={ICONS.LOCK}
                      rightMargin={7}
                      color="#388640"
                      size="14"
                    />
                    <small className="enabled">Enabled</small>
                  </div>
                ) : (
                  <div className="form-pill disabled">
                    <Icon
                      icon={ICONS.UNLOCK}
                      rightMargin={7}
                      color="#e71d36"
                      size="14"
                    />
                    <small className="disabled">Disabled</small>
                  </div>
                )}
              </div>
            </div>
          </div>
          <br />
          <div className="columns">
            <div className="column is-3">
              {account.OTPEnabled ? (
                <ButtonAction
                  text="Remove Authenticator"
                  toolTip="Remove Authenticator"
                  className="red"
                  onClick={this.removeModal}
                />
              ) : (
                <ButtonAction
                  text="Enable 2FA Authentication"
                  toolTip="Enable 2FA Authentication"
                  className="primary-btn"
                  onClick={this.openConfirm}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TwoFactorConfiguration;
