import React from "react";

export default function NoID() {
  return (
    <div>
      <div className="columns">
        <div className="upload no-img">
          <i className="far fa-times-circle fa-3x"></i>
        </div>
      </div>
      <p className="item-label has-text-centered">No ID Image Found</p>
    </div>
  );
}
