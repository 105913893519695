import React from 'react';
import { Formik, Form } from 'formik';
import { FormInput, FormSelect } from 'src/Forms/FormInputs';
import {
  LoadingButton,
  ToastError,
  ToastSuccess,
} from 'src/Utilities/Components';
import { useApi } from 'src/Services/Api';

const AddDebitAccount = ({ history }) => {
  const {
    location: { state },
  } = history;

  const { endpoint, ACHPull, CreditAccountHash } = state;

  const [handleDebit, isDebitSubmitting] = useApi({
    method: 'post',
    url: endpoint,
    onSuccess: () => {
      ToastSuccess('Debit Account has been added');
      history.goBack();
    },
    onError: message => {
      ToastError(message);
    },
  });

  return (
    <Formik
      initialValues={{
        Name: '',
        PayorName: '',
        AccountNumber: '',
        RoutingNumber: '',
        TransactionACHCode: 1,
        TransitNumber: '',
        FinancialInstitutionNumber: '',
      }}
      validate={values => {
        let errors = {};
        if (!values.Name) {
          errors.Name = 'is required';
        }
        if (!values.PayorName) {
          errors.PayorName = 'is required';
        }
        if (values.RoutingNumber.length > 9) {
          errors.RoutingNumber = "can't exceed 9 characters";
        }
        if (!values.RoutingNumber) {
          errors.RoutingNumber = 'is required';
        }
        if (!values.AccountNumber) {
          errors.AccountNumber = 'is required';
        }
        if (!values.TransactionACHCode) {
          errors.TransactionACHCode = 'is required';
        }
        if (!values.TransitNumber) {
          errors.TransitNumber = 'is required';
        }

        return errors;
      }}
      onSubmit={values => {
        // Work around for backend restrictions
        values.TransitNumber.toString();
        const body = {
          ...values,
          ACHPull,
          CreditAccountHash,
        };

        handleDebit(body);
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form className="form-outline form-create">
          <h2 className="form-title">Add Debit Account</h2>
          <div className="columns">
            <div className="column is-4">
              <FormInput
                label="Name"
                name="Name"
                type="text"
                placeholder="Debit Account Name"
                errors={errors}
                touched={touched}
                testID="Name"
              />
            </div>
            <div className="column is-4">
              <FormInput
                label="Payor Name"
                name="PayorName"
                type="text"
                placeholder="Company Name"
                errors={errors}
                touched={touched}
                testID="PayorName"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column is-4">
              <FormInput
                label="Routing Number"
                name="RoutingNumber"
                type="text"
                placeholder="011103093"
                errors={errors}
                touched={touched}
                testID="RoutingNumber"
              />
            </div>
            <div className="column is-4">
              <FormInput
                label="Account Number"
                name="AccountNumber"
                type="text"
                component="input"
                placeholder="091000022"
                errors={errors}
                touched={touched}
                testID="AccountNumber"
              />
            </div>
            <div className="column is-2">
              <FormSelect
                errors={errors}
                touched={touched}
                testID="ACHCode"
                label="Account Type"
                name="TransactionACHCode"
                placeholder="Select a Group"
                onChange={e =>
                  setFieldValue('TransactionACHCode', parseInt(e.target.value))
                }
                options={
                  <>
                    {[
                      { Hash: 1, Name: 'Checking' },
                      { Hash: 2, Name: 'Savings' },
                    ].map(item => (
                      <option key={item.Hash} value={item.Hash}>
                        {item.Name}
                      </option>
                    ))}
                  </>
                }
              />
            </div>
          </div>
          <div className="columns">
            <div className="column is-4">
              <FormInput
                label="Transit Number"
                name="TransitNumber"
                type="text"
                component="input"
                placeholder="Transit Number"
                errors={errors}
                touched={touched}
                testID="TransitNumber"
                onChange={e => {
                  let num = e.target.value;
                  setFieldValue('TransitNumber', num.toString());
                }}
              />
            </div>
            <div className="column is-4">
              <FormInput
                label="Financial Institution Number"
                name="FinancialInstitutionNumber"
                type="text"
                component="input"
                placeholder="Financial Institution Number"
                errors={errors}
                touched={touched}
                testID="FIN"
              />
            </div>
          </div>
          <LoadingButton loading={isDebitSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default AddDebitAccount;
