// Modules
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
// Components
import {
  GoBack,
  Header,
  LoadingButton,
  ToastError,
} from "../../Utilities/Components";
import { FormInput, FormSelect } from "../../Forms/FormInputs";
import { ToastSuccess } from "../../Utilities/Components";
// Config
import { API } from "../../Config/cfg.js";

const CreateSubpoena = ({ history }) => {
  const [employees, setEmployees] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getEmployee();
    getCountries();
  }, []);

  const getEmployee = () => {
    API.get(`/compliance/employees/assign-to/compliance_investigations`)
      .then((res) => setEmployees(res.data))
      .catch((err) => {
        ToastError(err.response.data.Message);
      });
  };

  const getCountries = () => {
    API.get("/countries")
      .then((res) => setCountries(res.data.Data))
      .catch((err) => {
        ToastError(err.response.data.Message);
      });
  };

  const putSubpoena = (values, actions) => {
    API.put("/compliance/subpoenas", values)
      .then((res) => {
        ToastSuccess(`You successfully created a new Subpoena`);
        history.push("/compliance/subpoenas");
        actions.resetForm();
      })
      .catch((error) => {
        ToastError(error);
        actions.setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      <GoBack
        history={history}
        defaultPath="/compliance/subpoenas"
        defaultName="Subpoenas"
      />
      <div id="formContainer">
        <Header title="Create Subpoena" />
        <div className="form-outline form-create">
          <Formik
            initialValues={{
              Completed: false,
              ContactName: "",
              ContactEmail: "",
              ContactPhone: "",
              CountryHash: "",
              DateReceived: "",
              DueDate: "",
              InvestigationOpen: "",
              InvestigationHash: null,
              RequestingAgency: "",
              SubpoenaNumber: "",
              QCReviewAccountHash: "",
              WorkedByAccountHash: "",
            }}
            validate={(values) => {
              let errors = {};
              if (!values.SubpoenaNumber) {
                errors.SubpoenaNumber = "is required";
              }
              if (!values.ContactName) {
                errors.ContactName = "is required";
              }
              if (!values.ContactEmail) {
                errors.ContactEmail = "is required";
              }
              if (!values.ContactPhone) {
                errors.ContactPhone = "is required";
              }
              if (!values.CountryHash) {
                errors.CountryHash = "is required";
              }
              if (!values.RequestingAgency) {
                errors.RequestingAgency = "is required";
              }
              if (!values.DateReceived) {
                errors.DateReceived = "is required";
              }
              if (!values.DueDate) {
                errors.DueDate = "is required";
              }
              if (!values.QCReviewAccountHash) {
                errors.QCReviewAccountHash = "is required";
              }
              if (!values.WorkedByAccountHash) {
                errors.WorkedByAccountHash = "is required";
              }
              return errors;
            }}
            onSubmit={(values, actions) => {
              putSubpoena(values, actions);
            }}
            children={({ errors, touched, isSubmitting, values }) => (
              <Form>
                <div className="columns">
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Subpoena Number"
                      name="SubpoenaNumber"
                      type="text"
                      placeholder="Enter Subpoena Number"
                    />
                  </div>
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Contact Name"
                      name="ContactName"
                      type="text"
                      placeholder="Enter Contact Name"
                    />
                  </div>
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Contact Email"
                      name="ContactEmail"
                      type="text"
                      placeholder="Enter Contact Email"
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Contact Phone"
                      name="ContactPhone"
                      type="text"
                      placeholder="Enter Contact Phone"
                    />
                  </div>
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Requesting Agency"
                      name="RequestingAgency"
                      type="text"
                      placeholder="Enter Requesting Agency"
                    />
                  </div>
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Date Received"
                      name="DateReceived"
                      type="date"
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <FormInput
                      errors={errors}
                      touched={touched}
                      label="Due Date"
                      name="DueDate"
                      type="date"
                    />
                  </div>
                  <div className="column">
                    <FormSelect
                      errors={errors}
                      touched={touched}
                      label="QC Reviewer"
                      name="QCReviewAccountHash"
                      placeholder="Select An Employee"
                      options={
                        <>
                          {employees.map((item) => (
                            <option
                              key={item.AccountHash}
                              value={item.AccountHash}
                            >
                              {item.FullName}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </div>
                  <div className="column">
                    <FormSelect
                      errors={errors}
                      touched={touched}
                      label="Worked By"
                      name="WorkedByAccountHash"
                      placeholder="Select An Employee"
                      options={
                        <>
                          {employees.map((item) => (
                            <option
                              key={item.AccountHash}
                              value={item.AccountHash}
                            >
                              {item.FullName}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </div>
                  <div className="column">
                    <FormSelect
                      errors={errors}
                      touched={touched}
                      label="Country of Origin"
                      name="CountryHash"
                      placeholder="Select A Country"
                      options={
                        <>
                          {countries.map((item) => (
                            <option key={item.Hash} value={item.Hash}>
                              {item.Name}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-narrow">
                    <FormInput
                      label="Completed"
                      name="Completed"
                      type="checkbox"
                    />
                  </div>
                  <div className="column is-narrow">
                    <FormInput
                      label="Investigation Open"
                      name="InvestigationOpen"
                      type="checkbox"
                    />
                  </div>
                  {values.InvestigationOpen === true && (
                    <div className="column is-4">
                      <FormInput
                        errors={errors}
                        touched={touched}
                        label="Investigation Hash"
                        name="InvestigationHash"
                        type="text"
                      />
                    </div>
                  )}
                </div>
                <div>
                  <LoadingButton loading={isSubmitting} />
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateSubpoena;
