import React from 'react';
import { Form, Formik } from 'formik';
// Components
import { FormInput, FormSelect } from '../../../Forms/FormInputs';
import { GenericModal } from '../../../Utilities/Components';
// Data
import { BanReasonList } from '../../../Utilities/Data/BanReasonList';

const BanAddressModal = ({
  isOpen,
  closeModal,
  hash,
  putAccount,
  txAddress,
  isTransaction,
}) => {
  const symbols = [{ name: 'BTCUSD', value: 'BTCUSD' }];
  return (
    <Formik
      initialValues={{
        Reason: '',
        Symbol: 'BTCUSD',
        CoinAddress: txAddress ? txAddress : '',
      }}
      onSubmit={async values => {
        let req = {
          CoinAddress: values.CoinAddress,
          Reason: parseInt(values.Reason),
          Symbol: values.Symbol,
        };

        if (!isTransaction) {
          req.Symbol = values.Symbol;
          req.AccountHash = hash;
        } else {
          req.TransactionHash = hash;
        }

        await putAccount(req);
        values.Reason = '';
        values.CoinAddress = txAddress ? txAddress : '';
        values.Symbol = 'BTCUSD';
        isTransaction && closeModal('isBanAddressOpen');
      }}
      validate={values => {
        let errors = {};
        if (!values.CoinAddress) {
          errors.CoinAddress = 'is required';
        }
        if (!values.Reason) {
          errors.Reason = 'is required';
        }
        if (!values.Symbol && !isTransaction) {
          errors.Symbol = 'is required';
        }
        return errors;
      }}
      children={({ touched, errors, handleSubmit, isSubmitting }) => (
        <GenericModal
          modalIsOpen={isOpen}
          headerMsg="Ban Wallet Address"
          btnAction={handleSubmit}
          btnText="Submit"
          isSubmitting={isSubmitting}
          closeModal={() => {
            closeModal('isBanAddressOpen');
          }}
        >
          <Form className="columns form-outline is-centered">
            <div className="column is-11">
              <FormInput
                errors={errors}
                touched={touched}
                label="Wallet Address"
                name="CoinAddress"
                placeholder="1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2"
              />
              <FormSelect
                label="Reason"
                name="Reason"
                type="text"
                errors={errors}
                touched={touched}
                placeholder="Select Reason for Banning Address"
                options={
                  <React.Fragment>
                    {BanReasonList.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </React.Fragment>
                }
              />
              {!isTransaction && (
                <FormSelect
                  label="Coin Type"
                  name="Symbol"
                  type="text"
                  errors={errors}
                  touched={touched}
                  placeholder="Symbol"
                  disabled
                  options={
                    <React.Fragment>
                      {symbols.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </React.Fragment>
                  }
                />
              )}
            </div>
          </Form>
        </GenericModal>
      )}
    ></Formik>
  );
};

export default BanAddressModal;
