import React from "react";
import columnBuilder from "./../../../Locations/columnBuilder";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import Table from "./../../../Table/Table";

const TabsContainer = ({ hash }) => {
  return (
    <Tabs className="tabs-container">
      <TabList className="columns tab-list">
        <Tab className="column is-3 tab">Locations</Tab>
      </TabList>

      <TabPanel>
        <Table
          columns={columnBuilder}
          endpoint={`accounting/ach/${hash}/locations`}
          nested={true}
          tablename="Locations"
        />
      </TabPanel>
    </Tabs>
  );
};

export default TabsContainer;
