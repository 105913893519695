/* COMPONENT OVERVIEW
- GETs a single group's data from API
- Renders group's data
  - Passes data to children and Renders it in tabular layout
- POST updates to single group's data
*/
// Modules
import React, { Component } from 'react';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
// Group Components
import GroupContact from './GroupContact';
import GroupMetrics from './GroupMetrics';
import GroupSettings from './GroupSettings';
import GroupPermissions from './GroupPermissions';
import ChildrenGroup from './ChildrenGroups';
import Asset from './../../Asset/Asset';
import TabsContainer from './TabsContainer';
// Components
import Addresses from './Addresses';
import {
  GoBack,
  Header,
  Loading,
  ToastError,
  ToastSuccess,
} from '../../Utilities/Components';
import OperatingStates from './OperatingStates';
// Configs
import { API } from '../../Config/cfg.js';
import { ICONS } from '../../Icons/IconsPath';

class SingleGroup extends Component {
  state = {
    data: {},
    countries: {},
    editingAddress: false,
    editingContact: false,
    editingPermissions: false,
    editingSettings: false,
    editingStates: false,
    hash: null,
    loading: true,
    errors: {},
    isSubmitting: false,
  };

  componentDidMount() {
    this.getGroup();
  }

  componentDidUpdate(prevProps) {
    // checks for route change, then grabs new location's data
    let { pathname } = this.props.location;
    if (prevProps.location.pathname !== pathname) {
      this.getGroup();
    }
  }

  getGroup = () => {
    const { hash } = this.props.match.params;
    API.get(`/groups/${hash}`)
      .then(res => {
        this.setState({
          data: res.data,
          loading: false,
          hash: res.data.Hash,
        });
      })
      .catch(error => {
        //this.setState({ errors: error.response.data });
      });
  };

  getCountries = () => {
    API.get(`/countries`)
      .then(res => {
        this.setState({
          countries: res.data.Data,
        });
      })
      .catch(error => {
        this.setState({ errors: error.response.data });
      });
  };

  postGroup = (endpoint, hash, params, data, editing, actions) => {
    this.setState({ isSubmitting: true });
    const url = params
      ? `/${endpoint}/${hash}/${params}`
      : `/${endpoint}/${hash}`;

    API.post(url, data)
      .then(res => {
        this.setState({ [editing]: false });
        ToastSuccess(`You successfully updated this group`);
        this.getGroup();
        actions && actions.setSubmitting(false);
      })
      .catch(error => {
        this.setState({ [editing]: true });
        ToastError(error);
        actions && actions.setSubmitting(false);
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  toggleEdit = type => {
    this.setState({ [type]: !this.state[type] });
  };

  render() {
    const {
      data,
      countries,
      hash,
      editingAddress,
      editingContact,
      editingPermissions,
      editingSettings,
      editingStates,
      loading,
      errors,
      isSubmitting,
    } = this.state;

    if (loading) {
      return <Loading errors={errors} />;
    }

    return (
      <React.Fragment>
        <GoBack
          history={this.props.history}
          defaultPath="/groups"
          defaultName="Groups"
        />
        <Header icon={ICONS.GROUPS} title={data.Name} />
        <div className="columns">
          <div className="column">
            <Asset />
            {!!data?.GroupTransactionStatistics && (
              <GroupMetrics stats={data.GroupTransactionStatistics} />
            )}
            {data.OperatingStates && (
              <>
                <p>Active States: </p>
                {data.OperatingStates.map((state, i, arr) => (
                  <React.Fragment key={state.StateCode}>
                    {state.StateCode}
                    {i + 1 === arr.length ? '' : ', '}
                  </React.Fragment>
                ))}
              </>
            )}
          </div>

          <div className="column is-7">
            <Tabs className="tabs-container nested-tabs">
              <TabList className="columns tab-list">
                <Tab className="column tab">Settings</Tab>
                <Tab className="column tab">Limits</Tab>
                <Tab className="column tab">Permissions</Tab>
                <Tab className="column tab">Groups</Tab>
                <Tab className="column tab">Active States</Tab>
              </TabList>

              <TabPanel>
                <GroupContact
                  editing={editingContact}
                  postGroup={this.postGroup}
                  data={data}
                  hash={hash}
                  toggleEdit={this.toggleEdit}
                />

                <Addresses
                  address={data.Address || {}}
                  showCountries={true}
                  countries={countries}
                  editing={editingAddress}
                  handleAddress={this.postGroup}
                  parentHash={hash}
                  toggleEdit={this.toggleEdit}
                />
              </TabPanel>

              <TabPanel>
                <GroupSettings
                  editing={editingSettings}
                  postGroup={this.postGroup}
                  data={data}
                  toggleEdit={this.toggleEdit}
                />
              </TabPanel>

              <TabPanel>
                <GroupPermissions
                  editing={editingPermissions}
                  postGroup={this.postGroup}
                  data={data}
                  toggleEdit={this.toggleEdit}
                  isSubmitting={isSubmitting}
                />
              </TabPanel>

              <TabPanel>
                <ChildrenGroup data={data.Children} />
              </TabPanel>
              <TabPanel>
                <OperatingStates
                  states={data.OperatingStates}
                  data={data}
                  editing={editingStates}
                  postGroup={this.postGroup}
                  toggleEdit={this.toggleEdit}
                  isSubmitting={isSubmitting}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <TabsContainer data={data} />
      </React.Fragment>
    );
  }
}

export default SingleGroup;
