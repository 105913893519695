import React from "react";
// Helpers - Formatters
import { formatPrice } from "../../Utilities/Formatters";

const CashCollectionMetaData = ({ data }) => {
  return (
    <div className="columns is-centered is-vcentered metadata-container">
      <div className="column">
        <div className="form-outline form-create">
          {data.GrossCashCollectedAverage ? formatPrice(data.GrossCashCollectedAverage) : "-"}
          <h4 className="item-label">Cash Pickups Average</h4>
        </div>
      </div>
      <div className="column">
        <div className="form-outline form-create">
          {data.TotalResults ? data.TotalResults : "-"}
          <h4 className="item-label">Cash Pickups Count</h4>
        </div>
      </div>
      <div className="column">
        <div className="form-outline form-create">
          {formatPrice(data.GrossCashCollected)}
          <h4 className="item-label">Gross Cash Collected</h4>
        </div>
      </div>
    </div>
  );
};

export default CashCollectionMetaData;
